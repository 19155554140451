<mat-card class="mat-elevation-z2 si-card si-card--dataviewer si-impact">
  <mat-card-header class="si-card__h si-normal">
    <mat-card-title class="si-card__title">Meta Tags</mat-card-title>
    <span class="si-expanse"></span>
  </mat-card-header>
  <mat-card-content class="si-card__c">
    <ng-container
      *ngIf="
        !industries.length && !topics.length && !types.length && !affiliations.length && !authors.length;
        else tags
      "
    >
      <div class="si-data si-data--meta">No tags available</div>
    </ng-container>
    <ng-template #tags>
      <div class="si-data si-data--meta">
        <si-data-output *ngIf="industries.length" class="si-data__set si-wd-full">
          <si-seo-chiplist
            class="si-component"
            ariaLabel="Industries"
            [isLong]="true"
            [isMinor]="true"
            [isDescriptor]="true"
            [chips]="industries"
            (chipClicked)="chipClicked.emit({ type: 'INDUSTRY', value: $event.label })"
          ></si-seo-chiplist>
        </si-data-output>

        <si-data-output *ngIf="topics.length" class="si-data__set si-wd-full">
          <si-seo-chiplist
            class="si-component"
            ariaLabel="Topics"
            [isLong]="true"
            [isMinor]="true"
            [isTopic]="true"
            [chips]="topics"
            (chipClicked)="chipClicked.emit({ type: 'TOPIC', value: $event.label })"
          ></si-seo-chiplist>
        </si-data-output>

        <si-data-output *ngIf="types.length" class="si-data__set si-wd-full">
          <si-seo-chiplist
            class="si-component"
            ariaLabel="Types"
            [isLong]="true"
            [isMinor]="true"
            [isRole]="true"
            [chips]="types"
            (chipClicked)="chipClicked.emit({ type: 'TYPE', value: $event.label })"
          ></si-seo-chiplist>
        </si-data-output>

        <si-data-output *ngIf="affiliations.length" class="si-data__set si-wd-full">
          <si-seo-chiplist
            class="si-component"
            ariaLabel="Affiliation"
            [isLong]="true"
            [isMinor]="true"
            [chips]="affiliations"
            (chipClicked)="chipClicked.emit({ type: 'AFFILIATION', value: $event.label })"
          ></si-seo-chiplist>
        </si-data-output>

        <si-data-output *ngIf="authors.length" class="si-data__set si-wd-full">
          <si-seo-chiplist
            class="si-component"
            ariaLabel="Authored or Co-Authored"
            [isLong]="true"
            [isMinor]="true"
            [chips]="authors"
            (chipClicked)="chipClicked.emit({ type: 'AUTHOR', value: $event.label })"
          ></si-seo-chiplist>
        </si-data-output>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
