<div class="si-fieldset__c" [formGroup]="getRowAsFormGroup()">
  <div class="si-control si-wd-control si-visible--beyond-mobile">
    <button
      type="button"
      mat-icon-button
      class="si-button si-button--icon si-button--delete"
      title="Remove Row"
      (click)="removeRow()"
    >
      <mat-icon aria-hidden="true">remove_circle</mat-icon>
    </button>
  </div>

  <!-- Facet Dropdown -->
  <mat-form-field class="si-field si-wd-midtype">
    <mat-select class="si-select" aria-label="Type" [value]="" formControlName="fieldName" libSetAutofocus>
      <mat-option *ngFor="let opt of facets" [value]="opt.name" [title]="opt.label">{{ opt.label }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Free Text Facets-->
  <ng-container *ngIf="!currentSelectionOpts.length && !isDateType && !isHierarchicalType && !isNumericType">
    <mat-form-field class="si-field si-wd-condition">
      <mat-select
        class="si-select"
        aria-label="Condition"
        value="contains"
        formControlName="condition"
        #fieldConditionAll
        value="contains"
      >
        <mat-option *ngFor="let opt of optsCondition" [value]="opt.value" [title]="opt.label">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="si-field si-wd-phrase">
      <input matInput aria-label="Text" class="si-input" formControlName="value" [value]="" />
    </mat-form-field>
  </ng-container>

  <!-- Keyword Facets -->
  <ng-container *ngIf="currentSelectionOpts.length && !isDateType && !isHierarchicalType && !isNumericType">
    <mat-form-field class="si-field si-wd-condition">
      <mat-select class="si-select" aria-label="Condition" formControlName="condition">
        <mat-option *ngFor="let opt of optsBoolean" [value]="opt.value" [title]="opt.label">{{ opt.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container>
      <mat-form-field class="si-field si-wd-phrase" *ngIf="autocompleteKeyword">
        <input type="text" matInput formControlName="value" [matAutocomplete]="keywordAutoComplete" />
        <mat-autocomplete #keywordAutoComplete="matAutocomplete">
          <mat-option *ngFor="let opt of filteredSelectionOpts$ | async" [value]="opt.value" [title]="opt.value">
            {{ opt.label }}
          </mat-option>
        </mat-autocomplete>
        <mat-error class="si-field__error">Please pick an item from the dropdown</mat-error>
      </mat-form-field>
      <mat-form-field class="si-field si-wd-phrase" *ngIf="!autocompleteKeyword">
        <mat-select class="si-select" [aria-label]="currentSelection" formControlName="value" value="">
          <mat-option *ngFor="let opt of currentSelectionOpts" [value]="opt.value" [title]="opt.value">
            {{ opt.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isDateType">
    <mat-form-field class="si-field si-wd-condition">
      <mat-select class="si-select" aria-label="Condition" formControlName="condition">
        <mat-option *ngFor="let opt of optsDate" [value]="opt.value" [title]="opt.label">{{ opt.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="si-field si-wd-date">
      <input
        matInput
        class="si-input si-input--datepicker"
        formControlName="value"
        [matDatepicker]="picker"
        aria-label="Date"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isNumericType">
    <mat-form-field class="si-field si-wd-condition">
      <mat-select class="si-select" aria-label="Condition" formControlName="condition">
        <mat-option *ngFor="let opt of optsNumeric" [value]="opt.value" [title]="opt.label">{{ opt.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="si-field si-wd-date">
      <input matInput type="number" class="si-input" formControlName="value" aria-label="Number Input" />
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isHierarchicalType">
    <mat-form-field class="si-field si-wd-condition">
      <mat-select class="si-select" aria-label="Condition" formControlName="condition">
        <mat-option *ngFor="let opt of optsBoolean" [value]="opt.value" [title]="opt.label">{{ opt.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="si-field si-wd-longphrase">
      <mat-chip-list #chipList class="si-chiplist" *ngIf="selectedHItem" required>
        <mat-chip class="si-chip si-chip--minor si-chip--single" matTooltip="Clear">
          {{ selectedHItem }}
          <mat-icon matChipRemove title="Clear" (click)="removeSelectedHItem()">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>

      <input
        class="si-input si-input--autocomplete"
        type="text"
        matInput
        formControlName="value"
        [matAutocomplete]="auto"
        [matAutocompleteDisabled]="!useHierarchyAutoComplete"
        [hidden]="selectedHItem !== ''"
        #lookupField
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        class="si-autocomplete"
        (optionSelected)="selectHItem($event.option.value)"
      >
        <mat-option
          *ngFor="let option of hFilteredOptions | async; let i = index"
          [value]="option"
          class="si-option si-option--complex"
        >
          <si-basic-card [title]="option"></si-basic-card>
        </mat-option>
      </mat-autocomplete>
      <button
        matSuffix
        class="si-button si-button--icon si-suffix--icon"
        matTooltip="Browse Hierarchy Tree"
        (click)="$event.stopPropagation(); openHierarchyModal(currentSelectionOpts)"
      >
        <mat-icon aria-hidden="true">call_made</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <span class="si-expanse si-visible--beyond-mobile"></span>

  <button
    type="button"
    mat-icon-button
    class="si-button si-button--icon si-button--add si-visible--beyond-mobile"
    title="Add Row"
    (click)="addRow()"
  >
    <mat-icon aria-hidden="true">add_circle</mat-icon>
  </button>

  <button
    type="button"
    *ngIf="showButton"
    mat-icon-button
    class="si-button si-button--icon si-icon--circle si-button--add si-visible--beyond-mobile"
    title="Add Group"
    (click)="addGroup()"
  >
    <mat-icon aria-hidden="true">more_horiz</mat-icon>
  </button>
</div>

<button
  type="button"
  mat-icon-button
  [matMenuTriggerFor]="optsMore"
  class="si-icon si-icon--more si-visible--only-mobile"
  color="primary"
  title="Options"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #optsMore="matMenu" class="si-menu">
  <button type="button" mat-menu-item class="si-button--detailed" (click)="removeRow()">
    <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">remove_circle</mat-icon>
    <span>Remove Row</span>
  </button>
  <button type="button" mat-menu-item class="si-button--detailed" (click)="addRow()">
    <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">add_circle</mat-icon>
    <span>Add Row</span>
  </button>
  <button type="button" mat-menu-item class="si-button--detailed" *ngIf="showButton" (click)="addGroup()">
    <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">more_horiz</mat-icon>
    <span>Add Group</span>
  </button>
</mat-menu>
