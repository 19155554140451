import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  transform(array: Array<any>, value: string): boolean {
    return array?.includes(value);
  }
}
