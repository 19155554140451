import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { MoreOption } from '@sae/models';

@Component({
  selector: 'si-basic-data-viewer',
  templateUrl: './basic-data-viewer.component.html',
})
export class BasicDataViewerComponent {
  @Input() addButtonToolTip = 'Add';
  @Input() altFilterOptions = false;
  @Input() altOptions: MoreOption[] = [];
  @Input() dataTitle = 'Item';
  @Input() defaultSearch = '';
  @Input() filterDividers: number[] = [];
  @Input() filterList: string[] = [];
  @Input() filterNavActive: string = null; // A null value prevents filter chip nav from displaying, any value causes a filter chip nav to display with this text as its content
  @Input() filterOptions: string[] = [];
  @Input() filterOptionsOverride = false; // Special override that causes the regular filter list to bind with filter chip nav behavior
  @Input() hideSearchBoxTooltip = true;
  @Input() infoButton = false;
  @Input() isAddButton = false;
  @Input() loading = false;
  @Input() moreOptions: MoreOption[] = [];
  @Input() noneText = 'No Results Found';
  @Input() noResults = false;
  @Input() pageNumber = 0;
  @Input() pageSize = 50;
  @Input() pageSizeOptions = [5, 10, 25, 50, 100];
  @Input() paginator = true;
  @Input() search = false;
  @Input() searchDebounceTime = 400;
  @Input() searchInputSuggestionTooltip = 'Filter Results';
  @Input() showHeader = true;
  @Input() showTotal = true;
  @Input() singleFilter: boolean;
  @Input() singleSortMenu = true;
  @Input() sortBy!: string;
  @Input() sortDir!: SortDirection;
  @Input() sortOptions: string[] = [];
  @Input() total = 500;
  @Input() tight = false; // If true applies a si-tight class to prevent any top/bottom margin/padding
  @Input() multiselect = false; // If true, provides space for multi-select-toolbar to expose the select/unselect all button when it is in minifiedMode
  @Input() useSeoPaginator = false; // If true, uses the SeoPaginatorComponent for pagination controls
  @Input() seoPaginatorBaseURL = ''; // Used in conjunction with useSeoPaginator:true, Represents the base url which will serve as the path to direct to for pagination with /{pageIndex} added
  @Input() seoPaginatorHashURL: string; // Used in conjunction with useSeoPaginator:true, optional, if supplied the provided url will be appended during routing as a hash argument
  @Input() pageSizeAllOption: boolean; // Used in conjunction with useSeoPaginator:true, if true, adds a special "All" option at the bottom of the page sizes, which uses a hidden 1,000,000 page size number
  @Input() hideCount: boolean; // If true forces the count to be hidden in the header
  @Input() noMargin = false; // Similar to tight, however only removes margin (keeps padding)
  @Input() auxButton: false; // If true, exposes a button to the far right of the header
  @Input() auxButtonLabel: string; // Used with auxButton, represents the tooltip and aria label
  @Input() auxButtonIcon: string; // Used with auxButton, represents the material icon to use
  @Input() disableShadow = false; // When true removes the drop shadow around the card for the data viewer
  @Input() hidePaginator = false; // When true hides any pagination control

  // Optional Analytics Tags
  @Input() analyticsIdSearchFilter: string;
  @Input() analyticsIdSearchBox: string;
  @Input() analyticsIdSearchOptions: string;

  @Output() selectedOption = new EventEmitter<string>();
  @Output() pageChanged = new EventEmitter<PageEvent>();
  @Output() sortChanged = new EventEmitter<Sort>();
  @Output() filterChanged = new EventEmitter(); // TODO: add type args for these other event emitters
  @Output() searchChanged = new EventEmitter();
  @Output() addButtonClicked = new EventEmitter();
  @Output() infoButtonClicked = new EventEmitter<void>();
  @Output() filterChipNavChipClicked = new EventEmitter();
  @Output() filterChipNavCancelCancelClicked = new EventEmitter();
  @Output() filterChipNavBackClicked = new EventEmitter();
  @Output() filterChipNavNextClicked = new EventEmitter();
  @Output() filterChipNavChange = new EventEmitter<string>();
  @Output() auxButtonClicked = new EventEmitter<void>();

  activeOption: string;

  public optionSelected(sel: string): void {
    this.activeOption = sel;
    this.selectedOption.emit(sel);
  }

  public filterChange(chip: string): void {
    if (this.filterOptionsOverride) {
      this.filterChipNavChange.emit(chip);
    } else {
      if (chip === 'All') {
        this.filterList = [];
      } else if (!this.filterList.includes(chip)) {
        this.singleFilter ? (this.filterList = [chip]) : this.filterList.push(chip);
      } else {
        this.singleFilter ? (this.filterList = []) : this.filterList.splice(this.filterList.indexOf(chip), 1);
      }
      this.filterChanged.emit([...this.filterList]);
    }
  }

  public changeSearch(key: string): void {
    this.searchChanged.emit(key);
  }

  public changePage(event: PageEvent): void {
    this.pageChanged.emit(event);
  }

  public changeSort(sort: Sort): void {
    this.sortChanged.emit(sort);
  }

  public addButtonClick(): void {
    this.addButtonClicked.emit();
  }

  public infoButtonClick(): void {
    this.infoButtonClicked.emit();
  }

  public onFilterChipNavChipClicked(): void {
    this.filterChipNavChipClicked.emit();
  }

  public onFilterChipNavCancelCancelClicked(): void {
    this.filterChipNavCancelCancelClicked.emit();
  }

  public onFilterChipNavBackClicked(): void {
    this.filterChipNavBackClicked.emit();
  }

  public onFilterChipNavNextClicked(): void {
    this.filterChipNavNextClicked.emit();
  }

  public auxButtonClick(): void {
    this.auxButtonClicked.emit();
  }
}
