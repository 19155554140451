<button
  class="si-button--trigger"
  [matMenuTriggerFor]="optionsMenu"
  [attr.aria-label]="ariaLabel ?? null"
  [attr.title]="tooltip"
  [class.si-state--opened]="menuOpen"
  (menuOpened)="menuStateChange(true); setMinWidth()"
  (menuClosed)="menuStateChange(false)"
  #menuTriggerButton
>
  <fs-part-button
    *ngIf="simpleTrigger"
    [classes]="'si-button--simpletrigger ' + (simpleTrigger.classes ? simpleTrigger.classes : '')"
    [fauxButton]="true"
    [label]="simpleTrigger.label"
    [type]="'plain'"
    [suffixIcon]="simpleTrigger.suffixIcon ? simpleTrigger.suffixIcon : 'expand_more'"
  ></fs-part-button>
  <ng-content select="[trigger]"></ng-content>
</button>

<mat-menu #optionsMenu="matMenu" class="si-menu si-menu--util" [overlapTrigger]="overlapTrigger">
  <fs-part-util-listbox
    class="si-listbox--util"
    label="Options"
    [role]="null"
    childRole="menuitem"
    orientation="vertical"
    [menuOpen]="menuOpen"
    [selectedIndex]="selectedIndex"
    [style.minWidth.px]="triggerClientWidth"
  >
    <ng-content select="[menu]"></ng-content>
  </fs-part-util-listbox>
</mat-menu>
