<ng-template #template>
  <ng-container
    *ngIf="{
      content: content$ | async,
      routeLoading: routeLoading$ | async,
      journalBrxDetail: journalBrxDetail$ | async
    } as _"
  >
    <mat-list class="si-list si-list--wrap si-list--master">
      <mat-list-item class="si-list__item">
        <ng-container [ngTemplateOutlet]="_.routeLoading ? loading : journalpanel"></ng-container>
        <ng-template #journalpanel>
          <a
            [routerLink]="_.content?.journalSeoUri"
            [fragment]="
              _.content?.relatedArticle?.volume && _.content?.relatedArticle?.issue
                ? 'browse?volume=' + _.content?.relatedArticle?.volume + '&issue=' + _.content?.relatedArticle?.issue
                : 'browse'
            "
            class="si-a--unusual si-button si-button--inline"
            mat-button
            title="Browse {{ _.content?.relatedArticle?.journalTitle }} Content"
          >
            <mat-card class="si-card si-card--magazine" *ngIf="_.content?.relatedArticle?.journalTitle">
              <mat-card-header class="si-card__h">
                <mat-card-title class="si-card__title">{{ _.content?.relatedArticle?.journalTitle }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="si-card__c">
                <img
                  *ngIf="!!_.journalBrxDetail?.journalImage?.length"
                  [src]="_.journalBrxDetail?.journalImage[1] ?? _.journalBrxDetail?.journalImage[0]"
                  alt="{{ _.content?.relatedArticle?.journalTitle }} Image"
                />

                <si-seo-chiplist
                  class="si-component"
                  ariaLabel="Article"
                  [isLong]="true"
                  [isRole]="true"
                  [isMedium]="true"
                  [chips]="[
                    {
                      label:
                        'Volume ' +
                        (_.content?.relatedArticle?.volume ?? '') +
                        ', Issue ' +
                        (_.content?.relatedArticle?.issue ?? ''),
                    }
                  ]"
                ></si-seo-chiplist>
              </mat-card-content>
            </mat-card>
          </a>
        </ng-template>

        <ng-template #loading>
          <div class="si-spinner__container">
            <mat-spinner class="si-spinner"></mat-spinner>
          </div>
        </ng-template>
      </mat-list-item>
    </mat-list>
  </ng-container>
</ng-template>
