<fs-comp-defined-data class="si-dataitem {{ classes }}" [data]="data">
  <ng-container headingStart>
    <ng-content select="[headingStart]"></ng-content>
  </ng-container>
  <ng-container bodyEnd>
    <ng-content select="[start]"></ng-content>
    <fs-ui-util-mixed-components
      *ngIf="data?.mixed"
      [config]="mixedConfig"
      [content]="data.mixed"
      (buttonClick)="onButtonClick($event)"
      (chipClick)="onChipClick($event)"
      (removeChipClick)="onRemoveChipClick($event)"
    >
      <ng-container mixedStart>
        <ng-content select="[mixedStart]"></ng-content>
      </ng-container>
      <ng-container mixedEnd>
        <ng-content select="[mixedEnd]"></ng-content>
      </ng-container>
    </fs-ui-util-mixed-components>
    <ng-content select="[end]"></ng-content>
  </ng-container>
</fs-comp-defined-data>
