import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, filter, from, map, of, switchMap, tap } from 'rxjs';
import { AuthActions } from '.';
import { LoginLocation } from '../../shared/models/common-interfaces';
import { AuthService } from '../../shared/state/auth.service';
import { SubscriptionsService } from '../../shared/state/subscriptions.service';
import { DownloadDocumentActions } from '../download-document';

@Injectable()
export class AuthEffects {
  private authService: AuthService | undefined;

  logInToSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logInToSubscription),
      switchMap((action) =>
        this.subscriptionsService.login(action.encodedLogin, action.rememberMe).pipe(
          map(() => AuthActions.logInToSubscriptionSucceeded({ loginFrom: action?.loginFrom })),
          catchError((error) => of(AuthActions.logInToSubscriptionFailed({ error })))
        )
      )
    )
  );

  isLoginFromDownloadDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logInToSubscriptionSucceeded),
      switchMap((action) => {
        if (action.loginFrom === LoginLocation.DownLoadDialog) {
          return of(DownloadDocumentActions.verifyAccess());
        } else {
          window.location.reload();
          return EMPTY;
        }
      })
    )
  );

  logOut$ = createEffect(() => {
    let redirectUri;
    return this.actions$.pipe(
      ofType(AuthActions.logOut),
      switchMap((action) => {
        this.authService = this.injector.get(AuthService);
        redirectUri = action.redirectUri;
        if (
          this.subscriptionsService?.getSubscriptions() &&
          this.subscriptionsService?.getSubscriptions()?.length > 0
        ) {
          return this.subscriptionsService.logout().pipe(
            tap(() => {
              if (!this.authService.isLoggedIn()) {
                window.location.reload();
              }
            }),
            catchError(() => of(null))
          );
        } else {
          return of(null);
        }
      }),
      filter(() => this.authService.isLoggedIn()),
      switchMap(() => from(this.authService.logout(redirectUri))),
      map(() => AuthActions.logOutSucceeded()),
      catchError((error) => of(AuthActions.logInToSubscriptionFailed({ error })))
    );
  });

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService,
    private injector: Injector,
    private store: Store
  ) {}
}
