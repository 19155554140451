<ng-container
  *ngIf="{
    showMobileNavMenu: showMobileNavMenu$ | async,
    isTakeoverActive: isTakeoverActive$ | async
  } as _"
>
<!-- TODO: add <div libDetectBreakPointWidth> to each column. -->
  <main class="si-columns si-columns--crossover" [class.si-state--open]="_.showMobileNavMenu" [class.si-state--closed]="!_.showMobileNavMenu" [hidden]="_.isTakeoverActive">
    <header class="si-crossover">
      <ng-template [cdkPortalOutlet]="masterHeaderContentPortal" *ngIf="projectedContent?.masterHeaderContent"></ng-template>
    </header>
    <div class="si-col si-col--master" role="navigation">
      <div class="si-col__set">
        <div class="si-col__c">
          <ng-template [cdkPortalOutlet]="masterContentPortal" *ngIf="projectedContent?.masterContent"></ng-template>
        </div>
      </div>
    </div>
    <div class="si-col si-col--detail">
      <button class="si-button si-button--cover" (click)="setShowMobileNavMenu(false)" aria-hidden="true" matTooltip="Collapse Panel" aria-label="Collapse Panel"></button>
      <div libDetectBreakpointWidth>
        <ng-template [cdkPortalOutlet]="detailContentPortal" *ngIf="projectedContent?.detailContent"></ng-template>
      </div>
    </div>
  </main>
</ng-container>
<!-- example of cross over master detail implementation -->
<!--
<div class="si-col__set">
  <div class="si-col__c">

    <app-none class="si-component" content="Contents"></app-none>
  </div>
</div>

<header class="si-content__h">
  <h1 class="si-content__title">Detail Panel Header</h1>
</header>
<div class="si-content__c">
  <div class="si-content__detail">
    <p>Contents</p>
  </div>
</div> -->
