import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PartButtonComponent, ButtonItem } from '../../parts/part-button/part-button.component';
import { PartCompoundComponent } from '../../parts/part-compound/part-compound.component';
import { PartContainerComponent } from '../../parts/part-container/part-container.component';
import { PartIconComponent } from '../../parts/part-icon/part-icon.component';
import { PartTextComponent, TextContent } from '../../parts/part-text/part-text.component';
import { PartTitleComponent } from '../../parts/part-title/part-title.component';
import { CompAvatarComponent, AvatarContent } from '../../compounds/comp-avatar/comp-avatar.component';
import { ChipItem } from '../../compounds/comp-chip/comp-chip.component';
import { CompRowTextComponent, RowTextContent } from '../../compounds/comp-row-text/comp-row-text.component';
import {
  CompUtilHeadingComponent,
  HeadingContent,
} from '../../compounds/comp-util-heading/comp-util-heading.component';
import { UIDataGridComponent, DataGridContent } from '../ui-data-grid/ui-data-grid.component';
import {
  UIUtilMixedComponentsComponent,
  MixedComponentsContent,
} from '../ui-util-mixed-components/ui-util-mixed-components.component';
import { CommonModule } from '@angular/common';
import { overrideInputs, randomUUID } from '@sae/base';

export interface ExpansionPanelContent {
  avatar?: AvatarContent;
  expanded?: boolean;
  contentMixed?: MixedComponentsContent[];
  contentDataGrid?: DataGridContent;
  heading?: TextContent;
  headingClasses?: string;
  headingContent?: HeadingContent;
  headingCount?: number;
  headingRow?: RowTextContent;
  hTag?: number;
  normalFormat?: boolean;
  uniqueId?: number;
  shadow?: boolean;
  stackMobile?: boolean;
  sticky?: boolean;
  tight?: boolean;
}

@Component({
  selector: 'fs-ui-expansion-panel',
  styleUrls: ['./ui-expansion-panel.component.scss'],
  templateUrl: './ui-expansion-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    PartButtonComponent,
    PartCompoundComponent,
    PartContainerComponent,
    PartIconComponent,
    PartTextComponent,
    PartTitleComponent,
    CompAvatarComponent,
    CompRowTextComponent,
    CompUtilHeadingComponent,
    UIDataGridComponent,
    UIUtilMixedComponentsComponent,
  ],
})
export class UIExpansionPanelComponent implements AfterContentChecked, AfterContentInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: ExpansionPanelContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() avatar: AvatarContent;
  @Input() expanded = true;
  @Input() contentMixed: MixedComponentsContent[];
  @Input() contentDataGrid: DataGridContent;
  @Input() heading: TextContent;
  @Input() headingClasses: string;
  @Input() headingContent: HeadingContent; // Optional, overrides other heading inputs when provided
  @Input() headingCount = -1; // Optional, adds a count to the heading
  @Input() headingRow: RowTextContent;
  @Input() hTag = 0; // if between 1 and 6, then the expansion panel uses an h tag.
  @Input() normalFormat = true; // if true, adds display settings for a normal display (such as the class si-h4)
  @Input() uniqueId: string | number = randomUUID(); // Unique ID
  @Input() shadow = false; // If true, adds a shadow to the component
  @Input() stackMobile = true; // If true, then the title and its possible control (action button) will be stacked
  @Input() sticky = false; // If true, makes header sticky for scrolling through content
  @Input() tight = false; // If true, the component will have tight padding in the content
  @Input() content: ExpansionPanelContent; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  @Output() chipClick = new EventEmitter<ChipItem>(); // Event emitted when component is in button mode (url is null)
  @Output() chipRemoveClick = new EventEmitter<ChipItem>(); // Event emitted when component remove chip is clicked
  @Output() buttonClick = new EventEmitter<ButtonItem>(); // Event emitted when component is in button mode (url is null)

  @ViewChild('controlContainer') controlContainer: ElementRef;
  @ViewChild('expanelBody') expanelBody: ElementRef;

  _controlContainerOffsetWidth = 0;
  _expanelBodyHeight = 0;
  stable = true; // If true, the component is not animating

  @HostListener('window:resize', ['$event']) onResize(): void {
    this._controlContainerOffsetWidth = 0;
    this._expanelBodyHeight = 0;
    this.checkBodyHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }

    if (this.normalFormat) {
      // eslint-disable-next-line @nx/workspace-no-reassign-inputs
      this.headingClasses = this.headingClasses ? this.headingClasses + ' si-h4' : 'si-h4';
    }
  }

  ngAfterContentChecked(): void {
    if (this.controlContainer) {
      this._controlContainerOffsetWidth = this.controlContainer.nativeElement.offsetWidth ?? 0;
    }
  }

  ngAfterContentInit(): void {
    this.checkBodyHeight();
  }

  expandedChange(): void {
    this.stable = false;
    setTimeout(() => {
      // need a delay so that the browser will set hard values for the height before its transition
      this.checkBodyHeight();
    }, 1);
  }

  checkBodyHeight(): void {
    window.requestAnimationFrame(() => {
      this._expanelBodyHeight = this.expanded ? this.expanelBody.nativeElement.offsetHeight ?? 0 : 0;
      setTimeout(() => {
        this.stable = true;
      }, 750); // 750ms is the duration of the transition
    });
  }

  onMixedButtonClick(button: ButtonItem): void {
    this.buttonClick.emit(button);
  }

  onMixedRemoveChipClick(item: ChipItem): void {
    this.chipRemoveClick.emit(item);
  }

  chipClickFunc(item: ChipItem): void {
    this.chipClick.emit(item);
  }
}
