/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import {
  ActiveState,
  applyTransaction,
  arrayAdd,
  arrayRemove,
  arrayUpsert,
  EntityState,
  EntityStore,
  getEntityType,
  getIDType,
  QueryEntity,
  StoreConfigOptions,
  transaction,
} from '@datorama/akita';
import {
  HttpGetConfig,
  HttpMethod,
  NgEntityService,
  NgEntityServiceParams,
} from '@datorama/akita-ng-entity-service';
import { Pagination, SaeHttpResponse } from '@sae/models';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';

export function initializeFiltering<S extends BaseState>(
  query: QueryEntity<S>,
  fetchEntities: () => void
): void {
  query
    .select((s) => s.filter)
    .pipe(
      distinctUntilChanged((prev, current) => prev === current),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((_ignored) => {
        if (!query.getValue().loading) {
          fetchEntities();
        }
      })
    )
    .subscribe();
  query
    .select((s) => s.searchTerm)
    .pipe(
      distinctUntilChanged((prev, current) => prev === current),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((_ignored) => {
        if (!query.getValue().loading) {
          fetchEntities();
        }
      })
    )
    .subscribe();
}

export function initializeSorting<S extends BaseState>(
  query: QueryEntity<S>,
  fetchEntities: () => void
): void {
  query
    .select((s) => s.sortField)
    .pipe(
      distinctUntilChanged((prev, current) => prev === current),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((_ignored) => {
        if (!query.getValue().loading) {
          fetchEntities();
        }
      })
    )
    .subscribe();
  query
    .select((s) => s.sortDir)
    .pipe(
      distinctUntilChanged((prev, current) => prev === current),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((_ignored) => {
        if (!query.getValue().loading) {
          fetchEntities();
        }
      })
    )
    .subscribe();
}

export function initializePaging<S extends BaseState>(
  query: BaseEntityQuery<S>,
  fetchEntities: (append?: boolean) => void
): void {
  query
    .select((s) => s?.pagination?.limit)
    .pipe(
      distinctUntilChanged((prev, current) => prev === current),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((_ignored) => {
        if (!query.getValue().loading) {
          fetchEntities();
        }
      })
    )
    .subscribe();

  query
    .select((s) => s?.pagination?.offset)
    .pipe(
      distinctUntilChanged((prev, current) => prev === current),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tap((_ignored) => {
        if (!query.getValue().loading) {
          fetchEntities(true);
        }
      })
    )
    .subscribe();
}

export type CustomParams = Array<{ paramName: string; value: string }>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface BaseState<E = any> extends EntityState<E>, ActiveState {
  pagination: Pagination;
  sortField: string;
  sortDir: SortDirection;
  filter: string | undefined;
  searchTerm: string | undefined;
  customParams: CustomParams;
  isInitialized: boolean;
  mostRecentResponse: SaeHttpResponse | undefined;
}

export abstract class BaseEntityStore<
  S extends BaseState = any,
  _EntityType = getEntityType<S>,
  _IDType = getIDType<S>
> extends EntityStore<S> {
  constructor(
    config?: Partial<StoreConfigOptions>,
    initialState: S = BaseEntityStore.createInitialState() as S
  ) {
    super(initialState, config);
  }
  static createInitialState(): BaseState {
    return {
      active: null,
      pagination: { offset: 0, limit: 200, total: 0 },
      sortField: 'createdDate',
      sortDir: 'desc',
      filter: undefined,
      searchTerm: undefined,
      customParams: [],
      loading: false,
      isInitialized: false,
      mostRecentResponse: undefined,
    };
  }
}

export abstract class BaseEntityQuery<
  S extends BaseState,
  _EntityType = getEntityType<S>,
  _IDType = getIDType<S>
> extends QueryEntity<S> { }

export abstract class BaseEntityService<
  T extends BaseState
> extends NgEntityService<T> {
  public all$ = this.query.selectAll();
  private _error = new BehaviorSubject(undefined);
  public error$ = this._error.asObservable();

  private _customUrl: string | undefined = undefined;
  private _offsetParamName = 'offset';
  private _limitParamName = 'limit';
  private _sortFieldParamName = 'sortCol';
  private _sortDirParamName = 'sortDir';
  private _filterParamName = 'filter';
  private _searchTermParamName = 'searchTerm';

  constructor(
    public readonly store: BaseEntityStore<T>,
    public readonly query: BaseEntityQuery<T>,
    config: NgEntityServiceParams
  ) {
    super(store, config);
    initializePaging<T>(this.query, (append) =>
      this.processFetchEntities(append)
    );
    initializeSorting<T>(this.query, () => this.processFetchEntities());
    initializeFiltering<T>(this.query, () => this.processFetchEntities());
  }

  public initialize(): void {
    this.store.update((s) => {
      return { ...s, isInitialized: true };
    });
  }

  @transaction()
  public initializeAndFetch(): void {
    this.initialize();
    this.processFetchEntities();
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected preFetchEntities(): void { }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected postFetchEntities(): void { }

  /**
   * Overrides the default underlying ngentityservice's this.api with the custom url provided.
   * @param url string
   */
  protected set customUrl(url: string) {
    this._customUrl = url;
  }

  /**
   * Sets any additional / custom http request params to be used on GET many calls (does not affect GET one by id calls).
   * @param customParams
   */
  protected set customParams(customParams: CustomParams) {
    this.store.update((s) => {
      return { ...s, customParams };
    });
  }

  protected addCustomParam(paramName: string, value: string): void {
    const existing = this.query
      .getValue()
      .customParams?.find((x) => x.paramName === paramName);
    if (!existing) {
      this.store.update((s) => {
        return {
          ...s,
          customParams: arrayAdd(s.customParams, { paramName, value }),
        };
      });
    } else {
      this.store.update((s) => {
        return {
          ...s,
          customParams: arrayUpsert(s.customParams, paramName, {
            paramName,
            value,
          }),
        };
      });
    }
  }

  protected removeCustomParam(paramName: string): void {
    this.store.update((s) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return {
        ...s,
        customParams: arrayRemove(
          s.customParams,
          (x) => x.paramName === paramName
        ),
      };
    });
  }

  /**
   * Only use this if you need to override the default "offset" param
   * @param offsetParamName the new param name for offset
   */
  protected set offsetParamName(offsetParamName: string) {
    this._offsetParamName = offsetParamName;
  }

  /**
   * Only use this if you need to override the default "limit" param
   * @param limitParamName the new param name for limit
   */
  protected set limitParamName(limitParamName: string) {
    this._limitParamName = limitParamName;
  }

  /**
   * Only use this if you need to override the default "sortField" param
   * @param sortFieldParamName the new param name for sort column / field name
   */
  protected set sortFieldParamName(sortFieldParamName: string) {
    this._sortFieldParamName = sortFieldParamName;
  }

  /**
   * Only use this if you need to override the default "sortDir" param
   * @param sortDirParamName the new param name for sort direction
   */
  protected set sortDirParamName(sortDirParamName: string) {
    this._sortDirParamName = sortDirParamName;
  }

  /**
   * Only use this if you need to override the default "filter" param
   * @param filterParamName the new param name for filter
   */
  protected set filterParamName(filterParamName: string) {
    this._filterParamName = filterParamName;
  }

  /**
   * Only use this if you need to override the default "searchTerm" param
   * @param searchTermParamName the new param name for searchTerm
   */
  protected set searchTermParamName(searchTermParamName: string) {
    this._searchTermParamName = searchTermParamName;
  }

  protected set sortField(sortField: string) {
    this.store.update((s) => {
      return { ...s, sortField };
    });
  }

  protected set sortDir(sortDir: SortDirection) {
    this.store.update((s) => {
      return { ...s, sortDir };
    });
  }

  protected set pagination(pagination: Pagination) {
    this.store.update((s) => {
      return { ...s, pagination };
    });
  }

  public processFetchEntities(append?: boolean): void {
    this.preFetchEntities();
    this.fetchEntities(append);
    this.postFetchEntities();
  }

  public fetchEntity(
    id: getIDType<T>,
    params: HttpParams = new HttpParams(),
    mapFn: (res: SaeHttpResponse) => getEntityType<T>
  ): void {
    const state = this.query.getValue();
    if (!state.isInitialized) {
      return;
    }
    let url: string | undefined = undefined;
    if (this._customUrl) {
      url = this._customUrl;
    }
    this.store.update((s) => {
      return { ...s, loading: true };
    });
    this.get(id, {
      url,
      params,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      mapResponseFn: (res: SaeHttpResponse) => mapFn(res),
    })
      .pipe(
        catchError((e) => {
          this._error.next(e);
          return EMPTY;
        })
      )
      .subscribe();
  }

  protected fetchEntitiesGetData(
    config: HttpGetConfig
  ): Observable<SaeHttpResponse> {
    // Akita's MapResponseFn not only tells akita how to map the response to the store, but
    // it also maps the return value of the call to get() (which makes sense for most cases,
    // but here we actually want to maintain the original response to return to fetchEntities).
    let response: SaeHttpResponse;
    return this.get({
      ...config,
      mapResponseFn: (res: SaeHttpResponse) => {
        response = res;
        return res.results;
      },
    }).pipe(map(() => response));
  }

  private fetchEntities(append = false): void {
    const state = this.query.getValue();
    if (!state.isInitialized || !state.pagination) {
      return;
    }
    let params = new HttpParams()
      .set(this._offsetParamName, state.pagination.offset)
      .set(this._limitParamName, state.pagination.limit)
      .set(this._sortFieldParamName, state.sortField)
      .set(this._sortDirParamName, state.sortDir.toString());

    if (state.filter) {
      params = params.set(this._filterParamName, state.filter);
    }

    if (state.searchTerm) {
      params = params.set(this._searchTermParamName, state.searchTerm);
    }

    if (state.customParams && state.customParams.length > 0) {
      state.customParams.forEach(
        (param) => (params = params.set(param.paramName, param.value))
      );
    }
    let url = this.api;
    if (this._customUrl) {
      url = this._customUrl;
    }
    this.store.update((s) => {
      return { ...s, loading: true };
    });

    this.fetchEntitiesGetData({ url, params, append })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          if (e.status === 404) {
            this.store.update((s) => {
              return {
                ...s,
                entities: [],
                ids: [],
                pagination: { ...s.pagination, total: 0 },
                mostRecentResponse: e,
                loading: false,
              };
            });
          }
          return EMPTY;
        }),
        catchError((e) => {
          console.error(e);
          this._error.next(e);
          return EMPTY;
        }),
        tap((res) => {
          applyTransaction(() => {
            this.store.setLoading(false);
            if (res) {
              this.store.update((s) => {
                return {
                  ...s,
                  pagination: res.pagination,
                  mostRecentResponse: res,
                };
              });
            }
          });
        })
      )
      .subscribe();
  }

  protected createEntity(entity: getEntityType<T>): void {
    let url = this.api;
    if (this._customUrl) {
      url = this._customUrl;
    }
    this.add(entity, {
      url,
      mapResponseFn: (res: SaeHttpResponse) => {
        if (res.result) {
          return res.result;
        }
        if (res.results) {
          return res.results;
        }
        return res;
      },
    })
      .pipe(
        catchError((e) => {
          this._error.next(e);
          return EMPTY;
        })
      )
      .subscribe();
  }

  protected updateEntity(
    id: getIDType<T>,
    entity: Partial<getEntityType<T>>,
    method: HttpMethod.PUT | HttpMethod.PATCH
  ): void {
    let url = this.api;
    if (this._customUrl) {
      url = this._customUrl;
    }
    this.update(id, entity, {
      method,
      url,
      mapResponseFn: (res: SaeHttpResponse) => {
        if (res.result) {
          return res.result;
        }
        if (res.results) {
          return res.results;
        }
        return res;
      },
    })
      .pipe(
        catchError((e) => {
          this._error.next(e);
          return EMPTY;
        })
      )
      .subscribe();
  }
  protected deleteEntity(id: getIDType<T>): void {
    let url = `${this.api}/${id}`;
    if (this._customUrl) {
      url = `${this._customUrl}/${id}`;
    }
    this.delete(id, {
      url,
      mapResponseFn: (res: SaeHttpResponse) => {
        if (res?.result) {
          return res.result;
        }
        if (res?.results) {
          return res.results;
        }
        return res;
      },
    })
      .pipe(
        catchError((e) => {
          this._error.next(e);
          return EMPTY;
        })
      )
      .subscribe();
  }
}
