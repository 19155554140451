import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, ViewChild, ViewContainerRef } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, tap } from 'rxjs';
import { NO_RESULTS_TEXT } from '../../../../shared/models/search-result.model';
import { ContentService } from '../../../../shared/state/content.service';
import { SubscriptionsService } from '../../../../shared/state/subscriptions.service';
import { BrowseActions, BrowseSelectors } from '../../../../store/browse';
import { selectDetailDescription, selectDetailTitle } from '../../../../store/browse/browse.selectors';
import { selectFragment } from '../../../../store/root.selectors';
import { FragmentService } from '../../../../shared/services/fragment.service';
import { MetaTagChipEvent } from '../../../../shared/components/meta-tags/meta-tags.component';
import { BrowseJournal } from '../../../../store/browse/browse.models';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'mobi-browse-by-detail',
  templateUrl: './browse-by-detail.component.html',
})
export class BrowseByDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('template', { static: true }) template;

  public fragment$ = this.store.select(selectFragment);
  public title$ = this.store.select(selectDetailTitle);
  public description$ = this.store.select(selectDetailDescription);
  public results$ = this.store.select(BrowseSelectors.selectResults);
  public loading$ = this.store.select(BrowseSelectors.selectResultsLoading);
  public defaultSearchTerm$ = this.store.select(BrowseSelectors.selectSearchTerm);
  public error$ = this.store.select(BrowseSelectors.selectResultsError);

  // Table Properties
  public pageSizeOptions = [25, 50, 100];
  public noResultsText = NO_RESULTS_TEXT;
  public total$ = this.store.select(BrowseSelectors.selectTotal);
  public sortOptions$ = this.store.select(BrowseSelectors.selectSortOptions);
  public sortDir$ = this.store.select(BrowseSelectors.selectSortDir);
  public sortBy$ = this.store.select(BrowseSelectors.selectSortBy);
  public pageSize$ = this.store.select(BrowseSelectors.selectPageSize);
  public pageNumber$ = this.store.select(BrowseSelectors.selectPageNumber);
  public subSessions$ = this.subService.subscriptions$;

  public facets$ = this.store.select(BrowseSelectors.selectFacets);
  public filters$ = this.store.select(BrowseSelectors.selectFilters);
  public isPubDateFilterApplied$ = this.store.select(BrowseSelectors.selectIsPubDateApplied);
  public pubDateFrom$ = this.store.select(BrowseSelectors.selectPubDateFrom);
  public pubDateTo$ = this.store.select(BrowseSelectors.selectPubDateTo);
  public customFilters$ = this.store.select(BrowseSelectors.selectCustomFilters);

  public journals$ = this.store.select(BrowseSelectors.selectBrowseJournal);
  public journalsLoading$ = this.store.select(BrowseSelectors.selectJournalsLoading);
  public journalOptions$ = this.store.select(BrowseSelectors.selectJournalOptions);
  public contentSubGroup$ = this.store.select(BrowseSelectors.selectContentSubgroup);

  public itemsFragment$ = combineLatest([
    this.filters$,
    this.customFilters$,
    this.pubDateFrom$,
    this.pubDateTo$,
    this.isPubDateFilterApplied$,
    this.defaultSearchTerm$,
  ]).pipe(
    map(([filters, customFilters, pubDateFrom, pubDateTo, isPubDateApplied, searchTerm]) => {
      const fragment = this.fragmentService.buildFragment2({
        queryTerm: searchTerm,
        filters,
        customFilters,
        pubDate: isPubDateApplied
          ? {
              from: pubDateFrom,
              to: pubDateTo,
            }
          : undefined,
      });
      return fragment !== '' ? fragment : undefined;
    })
  );

  constructor(
    private viewContainerRef: ViewContainerRef,
    private store: Store,
    private contentService: ContentService,
    private router: Router,
    private subService: SubscriptionsService,
    private fragmentService: FragmentService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.store
          .select(BrowseSelectors.selectScrollTop)
          .pipe(
            first(),
            tap((scrollTop) => {
              document.getElementsByClassName('si-col si-col--detail')[0].scrollTop = scrollTop;
            })
          )
          .subscribe();
      });
    }
  }

  pageChanged(event: PageEvent): void {
    this.store.dispatch(BrowseActions.changePagination({ event }));
  }

  sortChanged(event: Sort): void {
    this.store.dispatch(BrowseActions.changeSortParams({ sortParams: event }));
  }

  searchChanged(term: string): void {
    this.store.dispatch(BrowseActions.changeSearchTerm({ searchTerm: term }));
  }

  setReturnUrl(): void {
    const scrollTop = document.getElementsByClassName('si-col si-col--detail')[0].scrollTop;
    this.store.dispatch(
      BrowseActions.saveScrollPosition({
        scrollTop,
      })
    );
    this.contentService.setReturnUrl(this.router.url, 'Browse');
  }

  removeChip(fieldName: string, value: string): void {
    this.store.dispatch(BrowseActions.removeFilter({ fieldName, value }));
  }
  removeCustomFilterChip(fieldName: string, value: string): void {
    this.store.dispatch(BrowseActions.removeCustomFilter({ fieldName, value }));
  }

  removePublishedChip(): void {
    this.store.dispatch(BrowseActions.removePubDateFilter());
  }

  retrySearch(): void {
    this.store.dispatch(BrowseActions.search({ context: 'main_search' }));
  }

  metaTagChipClicked(event: MetaTagChipEvent): void {
    if (event.type === 'AFFILIATION') {
      this.store.dispatch(BrowseActions.applyFilter({ fieldName: 'affiliations', value: event.value }));
    } else if (event.type === 'AUTHOR') {
      this.store.dispatch(BrowseActions.applyFilter({ fieldName: 'authors', value: event.value }));
    } else if (event.type === 'INDUSTRY') {
      this.store.dispatch(BrowseActions.applyFilter({ fieldName: 'industrysectors_name', value: event.value }));
    } else if (event.type === 'TOPIC') {
      this.store.dispatch(BrowseActions.applyFilter({ fieldName: 'topics', value: event.value }));
    } else if (event.type === 'TYPE') {
      this.store.dispatch(BrowseActions.applyFilter({ fieldName: 'sub_group', value: event.value }));
    }
  }

  journalOptionSelected(journal: BrowseJournal, option: string): void {
    if (option === 'View Details') {
      this.router.navigate([journal.url]);
    } else if (option === 'Browse Journal') {
      this.router.navigate([journal.url], { fragment: 'browse' });
    }
  }
}
