import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'si-effects-directing-scrim',
  templateUrl: './directing-scrim.component.html',
  styleUrls: ['./directing-scrim.component.scss'],
})
export class EffectsDirectingScrimComponent implements OnInit, OnDestroy {
  @Output() scrimClosed = new EventEmitter();

  _closeThread: number = null;
  _closeDelay = 5000;

  ngOnInit(): void {
    this._closeThread = window.setTimeout(() => {
      this.scrimClosed.emit();
    }, this._closeDelay);

    document.addEventListener('keydown', this.onKeyDown.bind(this), false);
  }

  ngOnDestroy(): void {
    if (this._closeThread) {
      clearTimeout(this._closeThread);
    }

    document.removeEventListener('keydown', this.onKeyDown.bind(this), false);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.scrimClosed.emit();
    }
  }

  onOverlayClick(): void {
    this.scrimClosed.emit();
  }
}
