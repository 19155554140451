import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { Sort } from '@angular/material/sort';
import {
  FilterEvent,
  FilterGroup,
  FilterItem,
  FilterParams,
  ItemGroup,
  MenuData,
  MenuItem,
  SortEvent,
  SortGroup,
} from '@sae/models';

@Component({
  selector: 'si-overflow-menu-inner',
  templateUrl: './overflow-menu-inner.component.html',
})
export class OverflowMenuInnerComponent {
  @ViewChild('menu', { static: true }) menu!: MatMenu; // required for recursion

  @Input() menuData!: MenuData;
  @Input() filterParams: FilterParams[] = [];

  @Output() itemClicked = new EventEmitter<MenuItem>();
  @Output() sortChanged = new EventEmitter<SortEvent>();
  @Output() filterChanged = new EventEmitter<FilterEvent>();
  @Output() filterAll = new EventEmitter<null>();

  getMenuItem(
    x: MenuItem | ItemGroup | SortGroup | FilterGroup | MenuData
  ): MenuItem {
    return x as MenuItem;
  }

  getItemGroup(
    x: MenuItem | ItemGroup | SortGroup | FilterGroup | MenuData
  ): ItemGroup {
    return x as ItemGroup;
  }
  getSortGroup(
    x: MenuItem | ItemGroup | SortGroup | FilterGroup | MenuData
  ): SortGroup {
    return x as SortGroup;
  }
  getFilterGroup(
    x: MenuItem | ItemGroup | SortGroup | FilterGroup | MenuData
  ): FilterGroup {
    return x as FilterGroup;
  }

  getMenuData(
    x: MenuItem | ItemGroup | SortGroup | FilterGroup | MenuData
  ): MenuData {
    return x as MenuData;
  }

  onItemClicked(mi: MenuItem): void {
    this.itemClicked.next(mi);
  }

  onSortChanged(key: string, currentSort: Sort): void {
    this.sortChanged.next({ key, currentSort });
  }

  onFilterChanged(item: FilterItem, group: FilterGroup): void {
    this.filterChanged.next({ item, group });
  }

  onNestedSortChanged(e: SortEvent): void {
    this.sortChanged.next(e);
  }

  onNestedFilterChanged(e: FilterEvent): void {
    this.filterChanged.next(e);
  }

  onFilterAll(): void {
    this.filterAll.next(null);
  }
}
