<div class="si-breadcrumb">
  <ul class="si-card__breadcrumb si-enhanced">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <li class="si-card__breadcrumb__item">
        <ng-container *ngIf="!last">
          <ng-container *ngIf="breadcrumb.url && !breadcrumb.buttonMode">
            <a [routerLink]="breadcrumb.url" onclick="null">{{ breadcrumb.title }}</a>
          </ng-container>
          <ng-container *ngIf="!breadcrumb.url && !breadcrumb.buttonMode">
            <span>{{ breadcrumb.title }}</span>
          </ng-container>
          <ng-container *ngIf="breadcrumb.buttonMode">
            <a (click)="$event.stopPropagation(); crumbClicked(breadcrumb)">{{ breadcrumb.title }}</a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="last">
          <ng-container *ngIf="breadcrumb.url">
            <a [routerLink]="breadcrumb.url">
              <strong>{{ breadcrumb.title }}</strong>
            </a>
          </ng-container>
          <ng-container *ngIf="!breadcrumb.url">
            <strong>{{ breadcrumb.title }}</strong>
          </ng-container>
        </ng-container>
      </li>
      <li class="si-divider" *ngIf="!last">
        <mat-icon class="si-tool-icon" aria-hidden="true">keyboard_arrow_right</mat-icon>
      </li>
    </ng-container>
  </ul>
</div>
