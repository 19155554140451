<div #detailContent>
  <article class="si-content">
    <header class="si-section si-section--header">
      <h1
        class="si-header si-part--header si-header--basic"
        style="font-size: clamp(1.0625rem, 0.9375rem + 1vw, 1.75rem); font-weight: 700"
      >
        Page Not Found
      </h1>
    </header>
    <section class="si-section">
      <div class="si-textzone">
        <p style="margin-top: 0rem">We cannot find the page you are looking for, but you can search for it below.</p>
      </div>
      <h6
        class="si-title"
        style="margin-bottom: 1rem; font-size: clamp(0.75rem, 0.625rem + 1vw, 0.9375rem); font-weight: 700"
      >
        Search
      </h6>
      <div class="si-searchbox" style="margin-bottom: 1.25rem">
        <form class="si-form" onSubmit="return false;">
          <mat-form-field class="si-field si-wd si-wd-full" appearance="legacy">
            <mat-icon matPrefix>search</mat-icon>
            <label for="404-search" class="si-visible--sr-only">Search</label>
            <input
              siIncrementalSearch
              (searchChange)="emitSearch($event)"
              [debounceTime]="debounceTime"
              [minChars]="minChars"
              matInput
              class="si-input si-input--search"
              type="text"
              #searchInput
              id="404-search"
              data-analytics="search-box-hero-input"
              [value]="defaultSearch"
              [matAutocomplete]="auto"
              (keyup)="keyChecked($event)"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let suggestion of suggestions"
                [value]="suggestion"
                (click)="performSearch(suggestion)"
              >
                {{ suggestion }}
              </mat-option>
            </mat-autocomplete>
            <span data-testid="suggestionText" class="si-field__placeholder" *ngIf="!searchInput.value">
              {{ suggestionText }}
            </span>
            <button
              *ngIf="searchInput.value"
              data-testid="clear"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              type="button"
              class="si-button si-button--cancel"
              (click)="clearSearch()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>
      <h6
        class="si-title"
        style="margin-bottom: 1rem; font-size: clamp(0.75rem, 0.625rem + 1vw, 0.9375rem); font-weight: 700"
      >
        Or
      </h6>
      <p>
        <a routerLink="/browse">Browse all pages,</a>
        where you can apply filters.
      </p>
    </section>
  </article>
</div>
