<label
  [attr.for]="forId"
  [attr.title]="eclipsed ? label.text + (tooltip ? ' ' + tooltip : '') : tooltip"
  class="si-labelv {{ classes }}"
  [class.si-eclipsed]="eclipsed"
  [class.si-state--disabled]="disabled"
>
  <fs-part-text *ngIf="label" [text]="label"></fs-part-text>
  <span *ngIf="required" class="si-labelv__required">{{ ' *' }}</span>
  <ng-content></ng-content>
  <fs-part-icon *ngIf="error && !disabled" class="si-labelv__error">warning</fs-part-icon>
</label>
