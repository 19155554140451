import { isPlatformServer } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';

// Sets the global vh CSS variable to be 1% of the screen height; additionally adds a special class to the element enabling iOS Safari platform CSS overrides;
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[globalVhVarUpdate]',
})
export class GlobalVhVarUpdateDirective implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: string, private elementRef: ElementRef) {}

  @HostListener('window:resize') windowResize(): void {
    this.setGlobalVhUnitToOnePercentOfWindowInnerHeight();
  }

  ngOnInit(): void {
    this.setGlobalVhUnitToOnePercentOfWindowInnerHeight();
  }

  setGlobalVhUnitToOnePercentOfWindowInnerHeight(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const isIos = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    const vh = window.innerHeight * 0.01;
    const element = this.elementRef.nativeElement as HTMLElement;
    const iosSafariClass = 'si-ios-safari';

    if (isIos && isSafari && !element.classList.contains(iosSafariClass)) {
      document.documentElement.classList.add(iosSafariClass);
    }

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
