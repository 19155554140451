<ng-container
  *ngIf="{
    showMobileNavMenu: showMobileNavMenu$ | async,
    isTakeoverActive: isTakeoverActive$ | async,
    masterHeadFilter: masterHeadFilter$ | async
  } as _"
>
  <main class="si-columns" [class.si-state--open]="_.showMobileNavMenu" [class.si-state--closed]="!_.showMobileNavMenu" [hidden]="_.isTakeoverActive">
    <div class="si-col si-col--master" role="navigation">
      <div class="si-col__set">
        <div class="si-col__h" [class.si-state--chiplisted]="_.masterHeadFilter">
          <ng-template [cdkPortalOutlet]="masterHeaderContentPortal" *ngIf="projectedContent?.masterHeaderContent"></ng-template>
        </div>
        <div class="si-col__c">
          <ng-template [cdkPortalOutlet]="masterContentPortal" *ngIf="projectedContent?.masterContent"></ng-template>
        </div>
      </div>
    </div>
    <div class="si-col si-col--detail">
      <button class="si-button si-button--cover" (click)="setShowMobileNavMenu(false)" aria-hidden="true" matTooltip="Collapse Panel" aria-label="Collapse Panel"></button>
      <div libDetectBreakpointWidth>
        <ng-template [cdkPortalOutlet]="detailContentPortal" *ngIf="projectedContent?.detailContent"></ng-template>
      </div>
    </div>
    <ng-content></ng-content>
  </main>
</ng-container>
