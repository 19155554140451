<ng-container
  *ngIf="{
    subSessions: subSessions$ | async,
    hasBeenDownloaded: hasBeenDownloaded$ | async,
    isLoggedIn: isLoggedIn$ | async,
    fileFormats: fileFormats$ | async,
    loadingAssets: loadingAssets$ | async,
    subLoggedIn: subLoggedIn$ | async
  } as _"
>
  <div class="si-card__wrap" [class.si-card--autoscale]="autoScale">
    <a
      (click)="setReturnUrl()"
      [routerLink]="pubDocument?.mobilus_platform_uri?.raw"
      class="si-a--unusual si-button si-button--inline"
      mat-button
      [class.si-card--autoscale]="autoScale"
      [fragment]="_.subLoggedIn && featureAnnotate ? 'view' : null"
    >
      <mat-card
        class="si-card si-card--content si-content-features"
        [ngClass]="{ 'mat-elevation-z': !cardShading, 'mat-elevation-z2': cardShading }"
        [class.si-card--autoscale]="autoScale"
      >
        <mat-card-header class="si-card__h">
          <div mat-card-avatar class="si-avatar si-avatar--icon">
            <ng-container *ngIf="pubDocument?.accessProperties?.openAccess; else restricted">
              <mat-icon aria-label="Open Access" matTooltip="Open Access">lock_open</mat-icon>
            </ng-container>

            <ng-template #restricted>
              <mat-icon color="primary" *ngIf="pubDocument.accessProperties?.access">verified_user</mat-icon>
              <mat-icon class="si-state--locked" *ngIf="!pubDocument?.accessProperties?.access">lock</mat-icon>
            </ng-template>
          </div>
          <mat-card-title
            class="si-card__title si-state--chiplisted"
            [innerHTML]="
              pubDocument?.title?.snippet ? (pubDocument?.title?.snippet | emToMark) : pubDocument?.title?.raw
            "
          ></mat-card-title>
          <mat-card-subtitle class="si-card__subtitle" [class.si-contains--image]="false">
            <span class="si-product-features">
              <span
                class="si-prod-code"
                [class.si-major]="pubDocument?.group?.raw === 'Standards'"
                [innerHTML]="
                  pubDocument?.revision_status?.raw && pubDocument?.group?.raw === 'Standards'
                    ? (pubDocument?.product_code?.snippet
                        ? (pubDocument.product_code.snippet | emToMark)
                        : pubDocument?.product_code?.raw) +
                      ' (' +
                      pubDocument?.revision_status?.raw +
                      ')'
                    : pubDocument?.product_code?.snippet
                    ? (pubDocument.product_code.snippet | emToMark)
                    : pubDocument?.product_code?.raw
                "
              ></span>

              <span class="si-features__container si-visible--beyond-mobile">
                <span *ngIf="featureAnnotate && !simple">
                  <a
                    [routerLink]="pubDocument?.mobilus_platform_uri?.raw"
                    fragment="view&mode=annotation"
                    class="si-button si-button--icon"
                    [attr.data-analytics]="'pub-document-card-annotate-btn'"
                    (click)="setReturnUrl()"
                  >
                    <mat-icon
                      class="si-icon"
                      [class.si-state--activated]="pubDocument?.hasAnnotation"
                      [class.si-state--inaccessible]="!pubDocument?.accessProperties?.access"
                      [class.si-for--primary]="pubDocument?.accessProperties?.access"
                      aria-hidden="true"
                      matTooltip="Annotate"
                    >
                      create
                    </mat-icon>
                  </a>
                </span>
                <span *ngIf="feature2d3d && !simple">
                  <a
                    [routerLink]="pubDocument?.mobilus_platform_uri?.raw"
                    fragment="2d-3d"
                    class="si-button si-button--icon"
                    [attr.data-analytics]="'pub-document-card-2d3d-btn'"
                    (click)="setReturnUrl()"
                  >
                    <mat-icon
                      class="si-icon"
                      aria-hidden="true"
                      [class.si-state--inaccessible]="!pubDocument?.accessProperties?.access"
                      [class.si-for--primary]="pubDocument?.accessProperties?.access"
                      matTooltip="2D/3D"
                    >
                      360
                    </mat-icon>
                  </a>
                </span>
                <span *ngIf="featureDataSets && !simple">
                  <a
                    [routerLink]="pubDocument?.mobilus_platform_uri?.raw"
                    fragment="data-sets"
                    class="si-button si-button--icon"
                    [attr.data-analytics]="'pub-document-card-data-sets-btn'"
                    (click)="setReturnUrl()"
                  >
                    <mat-icon
                      class="si-icon"
                      aria-hidden="true"
                      [class.si-state--inaccessible]="!pubDocument?.accessProperties?.access"
                      [class.si-for--primary]="pubDocument?.accessProperties?.access"
                      matTooltip="Dataset"
                    >
                      equalizer
                    </mat-icon>
                  </a>
                </span>
                <span *ngIf="featureRedline && !simple">
                  <a
                    (click)="$event.stopPropagation()"
                    [routerLink]="pubDocument?.mobilus_platform_uri?.raw"
                    class="si-button si-button--icon"
                    [attr.data-analytics]="'pub-document-card-redline-btn'"
                  >
                    <mat-icon
                      class="si-icon"
                      aria-hidden="true"
                      [class.si-state--inaccessible]="!pubDocument?.accessProperties?.access"
                      [class.si-for--primary]="pubDocument?.accessProperties?.access"
                      matTooltip="Redlined"
                    >
                      compare_arrows
                    </mat-icon>
                  </a>
                </span>
                <span *ngIf="featureDigitalStandard && !simple">
                  <a
                    (click)="$event.stopPropagation()"
                    [routerLink]="pubDocument?.mobilus_platform_uri?.raw"
                    class="si-button si-button--icon"
                    [attr.data-analytics]="'pub-document-card-digital-standard-btn'"
                  >
                    <mat-icon
                      class="si-icon"
                      aria-hidden="true"
                      [class.si-state--inaccessible]="!pubDocument?.accessProperties?.access"
                      [class.si-for--primary]="pubDocument?.accessProperties?.access"
                      matTooltip="Digital Standard"
                    >
                      memory
                    </mat-icon>
                  </a>
                </span>
              </span>
            </span>
            <span class="si-date" [innerHTML]="pubDocument?.pub_date?.raw | formatDateToTextPipe: 'MM/dd/yyyy'"></span>
            <span class="si-features__container si-visible--only-mobile si-wd-full"></span>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="si-card__c" *ngIf="showSummary">
          <!-- <div class="si-card__image" *ngIf="false">
            <img src="{{ false }}" alt="Cover Image" class="si-image" />
          </div> -->
          <!-- Using directive -->
          <ng-container
            *ngIf="pubDocument?.abstract_clean?.snippet?.length > 0 || pubDocument?.abstract_clean?.raw?.length > 0"
          >
            <div *siEllipsis class="si-card--abstract__ellipsed">
              <span
                [innerHTML]="
                  pubDocument?.abstract_clean?.snippet
                    ? (pubDocument.abstract_clean?.snippet | emToMark)
                    : pubDocument?.abstract_clean?.raw
                "
              ></span>
            </div>
          </ng-container>
          <!-- Using class -->
          <!-- <span
            class="si-ellipsed-multiline"
            [innerHTML]="
              pubDocument?.abstract_clean?.snippet
                ? (pubDocument.abstract_clean?.snippet | emToMark)
                : pubDocument?.abstract_clean?.raw
            "
          ></span> -->
        </mat-card-content>
        <mat-card-content class="si-card__c si-card__c--authors" *ngIf="showSummary && !simple">
          <mat-card-subtitle>
            <ng-container *ngIf="pubDocument?.authors?.raw as authors">
              <span *ngFor="let author of authors; let i = index">
                <span
                  [innerHTML]="
                    (pubDocument.authors?.snippet | removeHtmlTags) === author
                      ? (pubDocument.authors?.snippet | emToMark)
                      : author
                  "
                >
                  <span></span>
                </span>
                <span *ngIf="i < authors?.length - 1">,&nbsp;</span>
              </span>
            </ng-container>
          </mat-card-subtitle>
        </mat-card-content>
      </mat-card>
    </a>
    <div class="si-card--toolbar">
      <si-seo-chiplist
        *ngIf="pubDocument?.sub_group?.raw"
        #chipList
        [attr.aria-label]="'Browse ' + pubDocument?.sub_group?.raw + ' Content'"
        class="si-chiplist si-ellipsed"
      >
        <mat-chip
          class="si-chip si-chip--minor si-chip--role"
          (click)="searchBySubGroup(pubDocument?.sub_group?.raw)"
          [title]="'Browse ' + pubDocument?.sub_group?.raw + ' Content'"
        >
          <a #link class="si-ellipsed" tabindex="-1">
            {{ pubDocument?.sub_group?.raw }}
          </a>
        </mat-chip>
      </si-seo-chiplist>
      <span class="si-expanse"></span>
      <div class="si-icons">
        <button
          mat-icon-button
          *ngIf="_.hasBeenDownloaded"
          class="si-icon si-state--activated"
          matTooltip="Download"
          aria-label="Download"
          [matMenuTriggerFor]="downloadMenu?.menu"
          [attr.data-analytics]="'pub-document-card-download-btn'"
        >
          <mat-icon>file_download</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="inMyLibrary"
          class="si-icon si-state--activated"
          matTooltip="In My Library"
          aria-label="In My Library"
          (click)="$event.stopPropagation(); openAddToMyLibraryDialog()"
          [attr.data-analytics]="'pub-document-card-in-library-btn'"
        >
          <mat-icon>library_add_check</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="pubDocument?.group?.raw === 'Standards' && pubDocument?.alertId"
          class="si-icon si-state--activated"
          matTooltip="Alert Set"
          aria-label="Alert Set"
          (click)="$event.stopPropagation(); removeAlert()"
          [attr.data-analytics]="'pub-document-card-alert-btn'"
        >
          <mat-icon>notifications</mat-icon>
        </button>
        <button
          mat-icon-button
          [matMenuTriggerFor]="viewToolbarOpts"
          class="si-icon--more"
          matTooltip="Options"
          aria-label="Options"
          (click)="$event.stopPropagation(); getDownloadFileFormat()"
          [attr.data-analytics]="'pub-document-card-options-btn'"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #viewToolbarOpts="matMenu" class="si-menu">
          <button
            mat-menu-item
            class="si-button--detailed"
            (click)="openAddToMyLibraryDialog()"
            matTooltip="{{ inMyLibrary ? 'In My Library' : 'Add to My Library' }}"
            attr.aria-label="{{ inMyLibrary ? 'In My Library' : 'Add to My Library' }}"
            [attr.data-analytics]="'pub-document-card-options-btn-library'"
          >
            <mat-icon class="si-tool-icon" color="primary" aria-hidden="true" [class.si-state--activated]="inMyLibrary">
              library_add_check
            </mat-icon>
            <span>{{ inMyLibrary ? 'Added to My Library' : 'Add to My Library' }}</span>
          </button>
          <button
            *ngIf="pubDocument?.group?.raw === 'Standards' && !pubDocument?.alertId"
            mat-menu-item
            class="si-button--detailed"
            (click)="addAlert()"
            [attr.data-analytics]="'pub-document-card-options-btn-alert'"
          >
            <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">notifications_none</mat-icon>
            <span>Set Alert</span>
          </button>
          <button
            mat-menu-item
            class="si-button--detailed"
            (click)="openAddToMyLibraryDialog(true)"
            matTooltip="Add to Shared Library"
            attr.aria-label="Add to Shared Library"
            [attr.data-analytics]="'pub-document-card-options-btn-add-shared'"
          >
            <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">group</mat-icon>
            <span>Add to Shared</span>
          </button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="viewShare"
            class="si-button--detailed"
            (click)="$event.stopPropagation()"
            [attr.data-analytics]="'pub-document-card-options-btn-share'"
          >
            <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">share</mat-icon>
            <span>Share</span>
          </button>
          <mat-divider class="si-divider"></mat-divider>
          <button
            *ngIf="canDirectlyDownload && isDownloadable"
            mat-menu-item
            [matMenuTriggerFor]="downloadMenu?.menu"
            class="si-button--detailed"
            [attr.data-analytics]="'pub-document-card-options-btn-download'"
          >
            <mat-icon
              class="si-tool-icon"
              [class.si-state--activated]="_.hasBeenDownloaded"
              color="primary"
              aria-hidden="true"
            >
              file_download
            </mat-icon>
            <span>Download</span>
          </button>
          <button
            *ngIf="!canDirectlyDownload && isDownloadable"
            mat-menu-item
            class="si-button--detailed"
            (click)="openDownloadDialog(_.subSessions)"
            [attr.data-analytics]="'pub-document-card-options-btn-file-download'"
          >
            <mat-icon class="si-tool-icon" aria-hidden="true">file_download</mat-icon>
            <span>Download</span>
          </button>
          <button
            *ngIf="false"
            mat-menu-item
            class="si-button--detailed"
            (click)="openLibraryDialog(card.library, 'addTo', 'Add To')"
          >
            <mat-icon class="si-tool-icon si-state--activated" color="primary" aria-hidden="true">
              library_add_check
            </mat-icon>
            <span>In My Library</span>
          </button>
          <button
            *ngIf="pubDocument?.group?.raw === 'Standards' && pubDocument?.alertId"
            mat-menu-item
            class="si-button--detailed"
            (click)="removeAlert()"
            [attr.data-analytics]="'pub-document-card-options-btn-remove-alert'"
          >
            <mat-icon class="si-tool-icon si-state--activated" aria-hidden="true">notifications</mat-icon>
            <span>Alert Set</span>
          </button>
          <button
            mat-menu-item
            class="si-button--detailed"
            (click)="setReturnUrl()"
            [routerLink]="pubDocument?.mobilus_platform_uri?.raw"
            [attr.data-analytics]="'pub-document-card-options-btn-view-details'"
            [fragment]="_.subLoggedIn && featureAnnotate ? 'view' : null"
          >
            <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">view_headline</mat-icon>
            <span>View Details</span>
          </button>
        </mat-menu>
        <mat-menu #viewShare="matMenu" class="si-menu">
          <button
            (click)="openMailApp()"
            mat-menu-item
            class="si-button--detailed"
            [attr.data-analytics]="'pub-document-card-options-btn-share-email'"
          >
            <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">email</mat-icon>
            <span>Email</span>
          </button>
          <button
            (click)="openClipboardSnackbar()"
            mat-menu-item
            [cdkCopyToClipboard]="getShareUrl()"
            [attr.data-analytics]="'pub-document-card-options-btn-share-url'"
          >
            <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">content_copy</mat-icon>
            <span>Copy URL</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <mobi-download-menu
    #downloadMenu="downloadMenu"
    [assets]="_.fileFormats"
    [loading]="_.loadingAssets"
    [hasAccess]="pubDocument?.accessProperties?.access"
    (retry)="retryGettingAssets(pubDocument?.cid?.raw)"
    (fileDownloaded)="fileDownloaded(pubDocument?.cid?.raw)"
  ></mobi-download-menu>
</ng-container>
