import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonItem, PartButtonComponent } from '../part-button/part-button.component';
import { DividerItem, PartDividerComponent } from '../part-divider/part-divider.component';
import { TextContent, PartTextComponent } from '../part-text/part-text.component';
import { overrideInputs } from '@sae/base';

export interface MixedPartsContent {
  button?: ButtonItem;
  divider?: DividerItem;
  textContent?: TextContent;
}

export interface PartUtilMixedPartsConfig {
  content?: MixedPartsContent[];
  disableLinks?: boolean;
}

@Component({
  selector: 'fs-part-util-mixed-parts',
  styleUrls: ['part-util-mixed-parts.component.scss'],
  templateUrl: './part-util-mixed-parts.component.html',
  standalone: true,
  imports: [CommonModule, PartButtonComponent, PartDividerComponent, PartTextComponent],
})
export class PartUtilMixedPartsComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartUtilMixedPartsConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() content: MixedPartsContent[];
  @Input() disableLinks: boolean; // Set to true if it is contained within a surrounding link already

  @Output() buttonClick = new EventEmitter<ButtonItem>(); // Event emitted when component is in button mode (url is null)

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }

  onButtonClick(button: ButtonItem): void {
    this.buttonClick.emit(button);
  }

  // _content: MixedContent[];

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.content) {
  //     if (this.disableLinks) {
  //       const newContent: MixedContent[] = [];

  //       for (const content of this.content) {
  //         if (content.contentHtml) {
  //           let contentHtml: string = content.contentHtml.toString();
  //           contentHtml = contentHtml.replace(/<a/g, '<span');
  //           contentHtml = contentHtml.replace(/<\/a/g, '</span');

  //           newContent.push({ ...content, contentHtml });
  //         } else {
  //           newContent.push({ ...content });
  //         }
  //       }

  //       this._content = newContent;
  //     } else {
  //       this._content = this.content;
  //     }
  //   }
  // }
}
