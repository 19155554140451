import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EnterpriseSearchFilters } from '@sae/models';
import {
  HashIds,
  HashStructure,
  getHashUrlNonDestructively,
  convertEnterpriseSearchFilters,
  getHashParamNumVal,
  findPageNumInSeoPaginationUrl,
} from './url-parameters.helper';

@Injectable({
  providedIn: 'root',
})
export class UrlParametersService {
  private _url$ = new BehaviorSubject<string>(null);
  private _baseUrl$ = new BehaviorSubject<string>(null); // the current relative URL without any query params or fragment
  private _queryParams$ = new BehaviorSubject<Params>(null); // just the query params (as a Params object)
  private _hash$ = new BehaviorSubject<string>(''); // just the fragment component of the URI (as a string, without the leading "#")
  private _urlChangeSubject$ = new BehaviorSubject<string>('');

  // specific parameters that this service will manage:
  private _searchTerm$ = new BehaviorSubject<string>(''); // ?q=foo
  private _offset$ = new BehaviorSubject<number>(null); // zero-based offset driven by page num url segment (e.g. "/2")
  private _limit$ = new BehaviorSubject<number>(null); // #size=5
  private _filters$ = new BehaviorSubject<EnterpriseSearchFilters>({}); // filterAll=language:English;delivery_type:eLearning+Instructor%20Led&filterNone=category:Retired%20Courses

  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {
    // set initial state
    this._urlChangeSubject$.next(this.location.path(true));
    combineLatest([this._urlChangeSubject$, this.route.queryParams, this.route.fragment])
      .pipe(debounceTime(0))
      .subscribe(([url, queryParams, fragment]) => {
        this.updateUrlSubjects(url);
        this.updateQueryParamSubjects(queryParams);
        this.updateHashSubjects(fragment);
      });

    this.location.onUrlChange((url) => {
      this._urlChangeSubject$.next(url);
    });
  }

  /**
   * The complete relative URL with params and fragment
   * (everything after the first '/')
   */
  get url$(): Observable<string> {
    return this._url$.asObservable().pipe(
      filter((url) => url !== null), // avoid emitting until url is known
      distinctUntilChanged()
    );
  }

  get urlNoHash$(): Observable<string> {
    return this.url$.pipe(
      map((url) => url.split('#')[0]),
      distinctUntilChanged()
    );
  }
  /**
   * The relative URL without query or hash params
   */
  get baseUrl$(): Observable<string> {
    return this._baseUrl$.asObservable().pipe(
      filter((url) => url !== null), // avoid emitting until url is known
      distinctUntilChanged()
    );
  }
  /**
   * Does not include the leading "#"
   */
  get hash$(): Observable<string> {
    return this._hash$.asObservable().pipe(distinctUntilChanged());
  }
  get queryParams$(): Observable<Params> {
    return this._queryParams$
      .asObservable()
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
  }
  get searchTerm$(): Observable<string> {
    return this._searchTerm$.asObservable().pipe(distinctUntilChanged());
  }
  get offset$(): Observable<number> {
    return this._offset$.asObservable().pipe(distinctUntilChanged());
  }
  get limit$(): Observable<number> {
    return this._limit$.asObservable().pipe(distinctUntilChanged());
  }
  get filters$(): Observable<EnterpriseSearchFilters> {
    return this._filters$.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * Generates relative URL with updated hash parameters, preserving existing query and hash parameters.
   * To remove specific hash parameters, pass their hash ids into options.clearHash.
   * @param {string} hash
   * @param {?{ multiValues?: boolean; toggleValue?: boolean; clearHash?: string[] }} [options]
   * @returns {string}
   */
  getNewHashUrl(
    hash: string,
    options?: { multiValues?: boolean; toggleValue?: boolean; clearHash?: string[] }
  ): string {
    return getHashUrlNonDestructively(
      this.currentBaseUrl,
      this.currentQueryParamsAsString,
      this.currentFragment,
      hash,
      options
    );
  }

  /**
   * Uses Angular Router to update query parameters non-destructively.
   */
  async setQueryParams(
    queryParams: Params,
    queryParamsHandling: QueryParamsHandling = 'merge',
    preserveFragment = true
  ): Promise<void> {
    await this.router.navigate([], {
      queryParams,
      queryParamsHandling,
      preserveFragment,
    });
  }

  /**
   * Directly sets the hash in the browser address bar using window.location.hash
   * WARNING!: calling this method will overwrite any existing hash values.
   * Use getNewHashUrl first, in order to preserve any other hash parameters that may also be applied.
   * To prevent the hash change from being registered in browser history, set replaceState true.
   */
  setHash(hash: string, replaceState = false): void {
    if (!replaceState) {
      window.location.hash = hash;
    } else {
      this.router.navigate([this._baseUrl$.value], {
        replaceUrl: true,
        queryParamsHandling: 'preserve',
        fragment: hash,
      });
    }
  }

  removeQueryParams(paramNames: string[]): void {
    const queryParams: Params = paramNames.reduce((a: { [key: string]: string }, b) => ((a[b] = null), a), {});
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  // TODO: removeHashParams()

  setSearchTerm(term: string): void {
    this.setQueryParams({ q: term });
  }

  setPaginationLimit(limit: number): void {
    if (!limit) return; // values of 0 are not valid
    const hash = this.getNewHashUrl(HashIds.limit + HashStructure.assign + limit);
    this.setHash(hash.split('#')[1]);
  }
  
  /**
   * Uses Angular Router to navigate to the current base URL with the page number segment added.
   * Preserves existing query params and hash fragment.
   * Will not navigate unless the provided offset value is different than the current one.
   */
  setPaginationOffset(offset: number): void {
    if (this._offset$.value !== offset) {
      const num = offset + 1;
      const url = num === 1 ? this.currentBaseUrl : this.currentBaseUrl + '/' + num;
      this.router.navigate([url], { preserveFragment: true, queryParamsHandling: 'preserve' });
    }
  }

  setFilters(filters: EnterpriseSearchFilters): void {
    const filtersAsHashParams = convertEnterpriseSearchFilters(filters);
    let fragment = '';
    if (filtersAsHashParams) {
      fragment = this.getNewHashUrl(filtersAsHashParams).split('#')[1];
    } else {
      fragment = this.getNewHashUrl(null, {
        clearHash: [HashIds.filterAll],
      }).split('#')[1];
    }
    this.setHash(fragment ?? '');
  }

  private updateUrlSubjects(url: string): void {
    if (this._url$.value !== url) this._url$.next(url ?? '');
    let baseUrl = url.split('#')[0].split('?')[0] ?? '';
    const pageNum = findPageNumInSeoPaginationUrl(baseUrl);
    if (findPageNumInSeoPaginationUrl(baseUrl) !== null) {
      // remove page num url segment from baseUrl
      // TODO: a more reliable way to do this would be nice
      baseUrl = baseUrl.split('/' + pageNum)[0];
      if (pageNum && this._offset$.value !== pageNum - 1) this._offset$.next(pageNum - 1);
    } else if (this._offset$.value !== 0) {
      // there will be no page num segment after navigating back to the first page
      this._offset$.next(0);
    }
    if (this._baseUrl$.value !== baseUrl) this._baseUrl$.next(baseUrl);
  }

  private updateHashSubjects(fragment: string): void {
    if (this._hash$.value !== fragment) this._hash$.next(fragment ?? '');
    const limit = getHashParamNumVal(fragment, HashIds.limit);
    if (limit !== null) {
      if (typeof limit === 'number' && this._limit$.value !== limit) {
        this._limit$.next(limit);
      }
    }
    const filters = convertEnterpriseSearchFilters(fragment);
    this._filters$.next(filters);
  }

  private updateQueryParamSubjects(params: Params): void {
    this._queryParams$.next(params);
    this._searchTerm$.next(params['q'] ?? '');
  }

  private get currentBaseUrl(): string {
    return this._baseUrl$.value;
  }

  /**
   * Does not include the leading "#"
   */
  private get currentFragment(): string {
    return this._hash$.value;
  }

  private get currentQueryParams(): Params {
    return this._queryParams$.value;
  }

  /**
   * Does not include the leading "?"
   */
  private get currentQueryParamsAsString(): string {
    return new HttpParams({ fromObject: this.currentQueryParams }).toString();
  }
}
