import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

// Used by Feature Bar to know if any items have overflowed beyond the viewport.

@Directive({
  selector: '[siFeatureBar]',
})
export class FeatureBarDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('window:resize') windowResize(): void {
    //  Fire the main function again if the window resizes
    this.main();
  }

  main(): void {
    const parent = this.el.nativeElement;
    const child = parent.children[0];

    if (child.offsetHeight < 50) {
      this.renderer.addClass(parent, 'si-state--overflow-not');
    } else {
      this.renderer.removeClass(parent, 'si-state--overflow-not');
    }
  }

  ngAfterViewInit() {
    this.main();
  }
}
