import { ContentGroup } from '@sae/models';
import { Bookmark } from '../../modules/detail/models/content.model';
import { CriteriaGroup } from './criterion.model';
import { Category, Citation, References, RelatedArticle } from './publication.model';
import { Application } from './search-request.model';
import { AccessControlResult, AppSearchSearchRequest, DocumentAsset } from './api-models';
import { Criteria } from '../../modules/search/components/advanced-search/advanced-search.component';

export const NO_RESULTS_TEXT = `It looks like there aren't great matches for your search. Try changing the search terms, or removing any filters that you might have selected.`;

export interface BibliographyEntry {
  label: number;
  publicationType: string;
  mixedCitation: string;
}

export interface Pagination {
  limit: number;
  offset: number;
  total: number;
}

export interface Affiliation {
  type: string;
  name: string;
  uriText?: string;
}

export interface Keyword {
  index: number;
  name: string;
  uriText: string;
}

export interface Contributor {
  type: string;
  authorId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  lnameFname?: string;
  lnameInitial?: string;
  affiliation?: Affiliation;
  collab?: string;
  orcid?: string;
  uriText?: string;
}
export interface ContributorGroup {
  contrib: Array<Contributor>;
  affiliation?: Affiliation;
}

export interface AuthorCard {
  author: Contributor;
  affiliation: Affiliation;
}

export interface FileFormat {
  priority: number;
  type: string;
  fileExt: string;
  exists: string;
  srchKey: string;
  fileName: string;
  fileSize: string;
  lastModified: string;
  uri: string;
  webUri: string;
  host: string;
  content: string;
}

export interface Publisher {
  rank: number;
  pubId: string;
  name: string;
  location: string;
  address: Address;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  stateProvince: string;
  countryShortName: string;
  countryIsoCode: string;
  countryName: string;
  postalCode: string;
}

export interface Conference {
  acronym: string;
  num: number;
  name: string;
  location: string;
  date: string;
  sponsor: string;
  theme: string;
  address: Address;
}

export interface Taxonomy {
  reviewStatus: string;
  approved: {
    assignedTerms: Array<Term>;
    terms: Array<Array<Term>>;
  };
  suggested: Array<{
    _id: string;
    runId: string;
    confidence: number;
    term: Term;
  }>;
}

export interface Term {
  _id: number;
  confidence: number;
  parentId: number;
  text: string;
  uriText: string;
  assigned: boolean;
  level: number;
  assignType: string;
}

export interface CollectionEntry {
  _id: string;
  name: string;
  title: string;
  collectionCategory: Array<string>;
  collectionVisibility: string;
  collectionStatus: string;
  collectionDocumentStatus: string;
  alert: string;
  uri: string;
  addedDate: string;
  id: string;
}

export interface ContentType {
  rdbmsType: string;
  rootType: string;
  selectedLevel: string;
  selectedType: string;
  officialType: string;
}

export interface Issn {
  publicationFormat: string;
  content: string;
}

export interface Doi {
  issueNumber: string;
  registrationAgency: string;
  uri: string;
  verified: boolean;
}

export interface Sector {
  techNum: number;
  sectorCode: string;
  name: string;
}

export interface Language {
  isoCode: string;
  name: string;
}

export interface Isbn {
  filetStat: string;
  avlStat: string;
  pubType: string;
  content: string;
}

export interface Reference {
  code: string;
  title: string;
  type: string;
  cid: number;
  doiUri: string;
  doiVerified: boolean;
}

export interface Revisions {
  revision: Array<Revision>;
}

export interface Revision {
  selfReference: boolean;
  code: string;
  productStatus: string;
  revisionStatus: string;
  title: string;
  pubDate: string;
  cid: number;
  doiUri: string;
  doiVerified: boolean;
  status: string;
}
export interface RevisionHistory {
  revision: Array<RHRevision>;
}

export interface RHRevision {
  date: string;
  type: string;
}

export interface StandardProperties {
  revisionHistory: RevisionHistory;
  revisions: Revisions;
  status: string;
}

export interface DataSet {
  index: number;
  label: string;
  caption?: string;
}

export interface VolumeIssue {
  issueNumber: number;
  issuePubDate: string;
}

export interface License {
  _id?: string;
  description: string;
  name: string;
  type: string;
}

export interface AccessDetails {
  userId?: string;
  subscriptionId: string;
  collectionId: string;
  collectionName: string;
}

export interface MetaData {
  cid: number;
  rationale: string;
  rootCode: string;
  categories: Array<Category>;
  code: string;
  pubDate: string;
  language?: Language;
  enteredDate: string;
  prodStatus: string;
  groupCode: string;
  webSwitch: boolean;
  doi?: Doi;
  industrySectorsReviewStatus: string;
  industrySectors: Array<Sector>;
  formatCode: string;
  title: string;
  citation: Citation;
  citations?: Citation[];
  documentAbstract: string;
  contribGroup: Array<ContributorGroup>;
  isbn: Array<Isbn>;
  fileFormat: Array<FileFormat>;
  license: License;
  publishers: Array<Publisher>;
  publicationId?: string;
  keywords: Keyword[];
  searchFormat: Array<{
    name: string;
    lastModified: string;
    type: string;
  }>;
  // contentType: ContentType;
  contentGroup: ContentGroup;
  contentType: ContentType;
  externalFile: {
    type: string;
    pageCount: number;
  };
  pageCount: number;
  references: References[];
  issn: Array<Issn>;
  relatedArticle: RelatedArticle;
  conferences: Array<Conference>;
  standardProperties: StandardProperties;
  videoLength: number;
  bibliography?: BibliographyEntry[];
  dataSets: Array<DataSet>;
  volume: number;
  volumeIssues: Array<VolumeIssue>;
  magCode: number;
  subTitle: string;
  copyrightHolder: string;
  copyrightStatement: string;
  useExtendedCode: boolean;
  extendedCode: string;
}
export interface SearchResultEntry {
  id: number;
  meta: MetaData;
  taxonomy: Taxonomy;
  fullText: {
    usedPdfText: string;
    content: string;
  };
  collections: {
    collection: Array<CollectionEntry>;
  };
  documentStatus: {
    name: string;
    uriText: string;
    updatedDate: Date;
    statusId: string;
  };
  ingestionAudit: {
    workflowStatus: string;
    audit: Array<{
      type: string;
      lastRunDate: string;
      utc: string;
    }>;
  };
  accessProperties: AccessControlResult;
  alertId: string;
  highlight?: {
    meta?: {
      title?: string;
      documentAbstract?: string;
    };
  };
  bookmarks?: Bookmark[];
  updateDateTime: string;
  version: string;
  syncProperties: string;
  _id: number;
  responseIndex?: number;
  documentUris?: {
    basicUris?: {
      seoUri: string;
      canonicalUrl: string;
    };
    platformUris?: Array<{
      platformName: string;
      uri: string;
      legacyUri: string;
    }>;
  };
  documentAssets: DocumentAsset[];
}

interface RequestInfoUrl {
  referrer: string;
  domain: string;
  full: string;
  original: string;
}

interface RequestInfo {
  url: RequestInfoUrl;
}

interface Trace {
  id: string;
}

interface User {
  userId: string;
  name: string;
  customerNumber: string;
  limitToAccessOnly: boolean;
}

interface AutomatedExecutionProperties {
  autoExecute: boolean;
  autoCleanUp: boolean;
  resultAlertFlag: boolean;
  targetCollections: AutomatedExecutionProperties[];
}

interface CriteriaDetailsRevisionHistory {
  _id: string;
}

interface StatsShards {
  total: number;
  successful: number;
  skipped: number;
  failed: number;
}

interface Stats {
  shards: StatsShards;
  took: number;
  timedOut: boolean;
  _shards: StatsShards;
}

// export interface CriteriaDetails {
//   id?: string;
//   pagination: Pagination;
//   criteriaLoggedId: string;
//   criteria: CriteriaGroup[];
//   isDeleted: boolean;
//   status: 'SAVED' | 'DELETED' | 'REVISION';
//   createDate: string;
//   updatedDate: string;
//   criteriaOperation: 'QUERY' | 'SAVE' | 'FETCH';
//   application: Application;
//   sessionId: string;
//   isSearchSaved: boolean;
//   clientIp: string;
//   limitToAccessOnly: boolean;
//   schemaVersion: number;
//   traceId: string;
//   trace: Trace;
//   requestInfo: RequestInfo;
//   jwtId: string;
//   revisionHistoryLimit: number;
//   name: string;
//   user: User;
//   automatedExecutionProperties: AutomatedExecutionProperties;
//   revisionHistory: CriteriaDetailsRevisionHistory[];
//   createdBy: string;
//   updatedBy: string;
//   stats: Stats;
// }
export interface CriteriaDetails {
  id?: string;
  appCode: string;
  engineName: string;
  searchName?: string;
  appsearchRequest: AppSearchSearchRequest;
  createdBy?: string;
  updatedBy?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  searchType?: string;
  advancedSearchCriteriaList?: Criteria[];
}
export interface saveSearchResponse {
  statusCode: number;
  statusMessage: string;
  pagination: Pagination;
  results: Array<CriteriaDetails>;
  result: Array<CriteriaDetails>;
  errorMessages: string[];
  messages: Array<string>;
}
export interface searchHistoryResponse {
  id?: string;
  criteria: CriteriaDetails;
  createdBy?: string;
  updatedBy?: string;
  createdOn?: string;
  updatedOn?: string;
  sessionId?: string;
  userId?: string;
  resultCount?: number;
  name?: string;
  category?: string;
  searchType?: string;
  advancedSearchCriteriaList?: Criteria[];
}
export interface SearchResult {
  statusCode: number;
  statusMessage: string;
  pagination: Pagination;
  results: Array<SearchResultEntry>;
  errorMessages: string[];
  criteriaDetails: CriteriaDetails;
  aggregations: Array<Aggregation>;
  messages: Array<string>;
}

export interface Aggregation {
  displayName: string;
  fieldName: string;
  values: Array<AggregationValue>;
}

export interface BrowseByField {
  fieldName: string;
  uriValue: string;
}

export interface AggregationValue {
  name: string;
  count: number;
}

export const FACETS_ORDER = {
  Only: 1,
  Collections: 2,
  Industries: 3,
  'Content Types': 4,
  Published: 5,
  Topics: 6,
  Authors: 7,
  Affiliations: 8,
  Committees: 9,
  'Publisher IDs': 10,
  Publishers: 10,
  Events: 11,
  Series: 12,
  'Search Formats': 13,
  'File Formats': 13,
  Categories: 14,
};

export enum SearchResultCardActions {
  Download = 'Download',
  Add_Alert = 'Add_Alert',
  Remove_Alert = 'Remove_Alert',
  View_Details = 'View_Details',
  Add_To_Library = 'Add_To_Library',
}

// export interface UIFacetItem {
//   value: string;
//   count: string;
//   checked: boolean;
// }

// export interface UIFacetGroup {
//   title: string;
//   fieldName: string;
//   results: UIFacetItem[];
// }

// export interface UISelectedFacet {
//   fieldName: string;
//   value: string;
//   checked: boolean;
// }

// export interface APIQueryableObject {
//   searchType: string;
//   field: {
//     collectionName: string;
//   };
//   values: string[];
// }

// export interface APICriterion {
//   groupType: string;
//   operator: string;
//   query: APIQueryableObject[];
// }

// export interface APIApplication {
//   context: string;
//   name: string;
//   resultSetId: string;
// }

// export interface APISearchRequest {
//   application: APIApplication;
//   criteria: APICriterion[];
//   limit: number;
//   offset: number;
//   order: SortDirection;
//   sortBy: string;
// }
