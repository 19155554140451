import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { Anchor, DisplayModified, MenuData, MenuItem, MoreOption, Resource } from '@sae/models';

@Component({
  selector: 'si-resource-manager-ui',
  templateUrl: './resource-manager-ui.component.html',
})
export class ResourceManagerUIComponent {
  @Input() currentAnchor: Resource;
  @Input() breadcrumbs: Anchor[];
  @Input() childResources: Resource[];
  @Input() anchorLoading: boolean;
  @Input() resourcesLoading: boolean;
  @Input() mainMenu: MenuData;
  @Input() addMenu: MenuData;
  @Input() appliedDisplaySettings: DisplayModified;
  @Input() shareCount: number;
  @Input() sortBy: string;
  @Input() sortDirection: SortDirection;
  @Input() disableDownload: boolean;
  @Input() enableSharing: boolean;
  @Input() enableSort: boolean = true;
  @Input() expanded: boolean;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() error: boolean;
  @Input() errorMessage: string;
  @Input() emptyMessage = 'No results found.';
  @Input() enableCopyLink = false;
  @Input() enableCopyName = false;
  @Input() filterChip: string = null; // If specified, adds a chip with the specified text that when clicked emits filterChipClicked
  @Input() paginator = false; // Whether or not to expose pagination controls
  @Input() length = 0; // The length of the total number of items that are being paginated. Defaulted to 0.
  @Input() pageSize = 50; // Number of items to display on a page. By default set to 50.
  @Input() pageIndex = 0; // The zero-based page index of the displayed list of items. Defaulted to 0.
  @Input() pageSizeOptions: number[]; // The set of provided page size options to display to the user.
  @Input() selectionEnabled = false; // If true, enables functionality to provide select/multi-select of resources
  @Input() selectionMinifiedMode = false;
  @Input() selectionAllToggled = false;
  @Input() selectionActionOptions: MoreOption[] = [];
  @Input() morePagination = false; // If true, uses a special load "more" style pagination where additional items are appended in batches according to the morePaginationCount
  @Input() enableCopyUrlMenuItem = false; // Determines whether the "Copy URL" verlipsis menu item displays for files
  @Input() enableCopyFolderUrlMenuItem = false; // Determines whether the "Copy URL" verlipsis menu item displays for folders

  @Output() addFile = new EventEmitter<void>();
  @Output() addFolder = new EventEmitter<void>();
  @Output() addLink = new EventEmitter<void>();
  @Output() delete = new EventEmitter<Resource>();
  @Output() download = new EventEmitter<Resource>();
  @Output() copyLink = new EventEmitter<Resource>();
  @Output() copyName = new EventEmitter<Resource>();
  @Output() editLink = new EventEmitter<Resource>();
  @Output() menuItemClick = new EventEmitter<MenuItem>();
  @Output() navigate = new EventEmitter<string>();
  @Output() refresh = new EventEmitter<void>();
  @Output() rename = new EventEmitter<Resource>();
  @Output() resourceClick = new EventEmitter<Resource>();
  @Output() shareDialogLaunch = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() menuIsOpen = new EventEmitter<boolean>();
  @Output() filterChipClicked = new EventEmitter();
  @Output() page = new EventEmitter<PageEvent>(); // Event triggered by any action which triggers a pagination change
  @Output() selectAllChanged = new EventEmitter<boolean>();
  @Output() actionSelected = new EventEmitter<string>();
  @Output() toggleCard = new EventEmitter<{ toggled: boolean; item: MoreOption }>(); // Event emitted if a card is toggled

  morePages = 1;
  morePaginationCount = 200;

  onAddFile(): void {
    this.addFile.emit();
  }
  onAddFolder(): void {
    this.addFolder.emit();
  }
  onAddLink(): void {
    this.addLink.emit();
  }
  onDelete(r: Resource): void {
    this.delete.emit(r);
  }
  onDownload(r: Resource): void {
    this.download.emit(r);
  }
  onCopy(r: Resource): void {
    this.copyLink.emit(r);

  }
  onCopyName(r: Resource): void {
    this.copyName.emit(r);
  }
  onEditLink(r: Resource): void {
    this.editLink.emit(r);
  }
  onMenuItemClick(item: MenuItem): void {
    this.menuItemClick.emit(item);
  }
  onNavigate(event: unknown): void {
    // unknown because it comes from the si-breadcrumbs comp, which is agnostic and gives back what you pass into it
    // we know it is an anchor in this case
    const anchor = event as Anchor;
    this.navigate.emit(anchor.resourceId);
  }
  onRefresh(): void {
    this.refresh.emit();
  }
  onRename(r: Resource, newName: string): void {
    newName = newName.trim();
    if (newName === '' || newName === r.name) {
      return;
    }
    const editedResource = { ...r, name: newName };
    this.rename.emit(editedResource);
  }
  onResourceClick(r: Resource): void {
    this.resourceClick.emit(r);
  }
  onShareDialog(): void {
    this.shareDialogLaunch.emit();
  }
  onSortChange(sort: Sort): void {
    this.sortChange.emit(sort);
  }
  onMenu(is: boolean): void {
    this.menuIsOpen.emit(is);
  }
  onPage(page: PageEvent) {
    this.page.emit(page);
  }
  onSelectAllChanged(event: boolean) {
    this.selectAllChanged.emit(event);
  }
  onActionSelected(event: string) {
    this.actionSelected.emit(event);
  }
  onToggleCard(toggled: boolean, item: MoreOption) {
    this.toggleCard.emit({ toggled, item });
  }
}
