import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  DATE_FACET_TYPE,
  Facet,
  FacetOption,
  HierarchicalFacetOption,
  HIERARCHICAL_FACET_TYPE,
  NUMERIC_FACET_TYPE,
  OPTIONS_CONDITION,
  OPTIONS_DATE,
  OPTIONS_BOOLEAN,
  OPTIONS_NUMERIC,
  FacetType,
  AdvSearchConditionConfig,
} from '@sae/models';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { HierarchyDialogComponent } from './../hierarchy-dialog/hierarchy-dialog.component';

const fieldNameFieldName = 'fieldName';
const conditionFieldName = 'condition';
const valueFieldName = 'value';
const valueLabelFieldName = 'valueLabel';
const typeFieldName = 'type';
const labelFieldName = 'label';

interface KeywordOption {
  value: string;
  label: string;
}

@Component({
  selector: 'si-criteria-row',
  templateUrl: './criteria-row.component.html',
  styleUrls: ['./criteria-row.component.scss'],
})
export class CriteriaRowComponent implements OnInit {
  @Input() level!: number;
  @Input() indexNum!: number;
  @Input() facets!: Array<Facet>;
  @Input() row!: AbstractControl;
  @Input() useHierarchyAutoComplete = false;
  @Input() showButton: boolean;
  @Input() advSearchConditionConfig: AdvSearchConditionConfig;
  @Input() autocompleteKeyword = false;

  @Output() rowAdded: EventEmitter<number> = new EventEmitter();
  @Output() rowRemoved: EventEmitter<number> = new EventEmitter();
  @Output() groupAdded: EventEmitter<number> = new EventEmitter();
  @Output() groupRemoved: EventEmitter<number> = new EventEmitter();

  currentSelection = '';
  isDateType = false;
  isHierarchicalType = false;
  isNumericType = false;
  selectedHItem = '';
  conditionField = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentSelectionOpts: any[] = [];
  filteredSelectionOpts$: Observable<Array<KeywordOption>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentSelectionValues: any[] = [];
  hLookUpOptions = ['Active Safety Systems', 'Airframes', 'Bearings', 'Canards', 'Wings'];
  hFilteredOptions: Observable<string[]>;
  rowTitle = '';

  optsCondition = OPTIONS_CONDITION;
  optsDate = OPTIONS_DATE;
  optsBoolean = OPTIONS_BOOLEAN;
  optsNumeric = OPTIONS_NUMERIC;

  facetType: FacetType;

  dialogRef: MatDialogRef<HierarchyDialogComponent>;

  facetTypeDefaultConditions: Partial<Record<FacetType, string>> = {
    string: 'TEXT',
    keyword: 'KEYWORD',
    numeric: 'RANGE_EQ',
    date: 'RANGE_EQ',
  };

  // this is needed because removing a validator requires a reference to the
  // function that was added in the first place
  requireAutocompleteValidator = this.requireAutocompleteMatch.bind(this);

  @ViewChild('lookupField') lookupField: ElementRef<HTMLInputElement>;

  constructor(public dialog: MatDialog) {}

  getRowAsFormGroup(): FormGroup {
    return this.row as FormGroup;
  }
  getRowValueControl(): AbstractControl {
    return this.row.get(valueFieldName);
  }
  getRowIdControl(): AbstractControl {
    return this.row.get('id');
  }
  getRowValueLabelControl(): AbstractControl {
    return this.row.get(valueLabelFieldName);
  }
  getRowConditionControl(): AbstractControl {
    return this.row.get(conditionFieldName);
  }
  getRowTypeControl(): AbstractControl {
    return this.row.get(typeFieldName);
  }
  getRowFieldNameControl(): AbstractControl {
    return this.row.get(fieldNameFieldName);
  }
  getRowLabelControl(): AbstractControl {
    return this.row.get(labelFieldName);
  }

  ////////////////////////////////////////////////////////////////////
  ngOnInit(): void {
    if (this.row.value && this.row.value.value) {
      const field = this.row.value.fieldName;
      this.setupRow(field);
      this.conditionField = this.row.value.condition;
    }

    this.getRowFieldNameControl()
      .valueChanges.pipe(filter((r) => !!r))
      .subscribe((res) => {
        this.setupRow(res);
        this.initConditionAndValue();
      });

    this.getRowConditionControl()
      .valueChanges.pipe(filter((r) => !!r))
      .subscribe((res) => {
        this.handleConditionChange(res);
      });

    this.getRowValueControl()
      .valueChanges.pipe(filter((r) => !!r && !this.isHierarchicalType))
      .subscribe((res) => {
        const option = this.getOptionByValue(res);
        const id = this.getOptionIdByValue(res);
        this.getRowValueLabelControl().setValue(option ? option.label : res);
        if (id) {
          this.getRowIdControl().setValue(id.name == option.value ? id.key : id.label);
        }
      });

    // Temporary options
    this.hFilteredOptions = this.getRowValueControl().valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  /**
   * call when the search condition changes to reset value field
   * @param newCond new value of the condition
   */
  private handleConditionChange(newCond: string) {
    this.conditionField = newCond;
    this.getRowValueControl().patchValue('');
    const rowFormGroup = this.getRowAsFormGroup().get('value');
    if (rowFormGroup.touched && this.isHierarchicalType) this.removeSelectedHItem();
    this.setValidation();
    this.setFilteredOptions();
  }

  /////////////////////////////

  /**
   * Restricts field to require a match from the autocomplete options
   */
  requireAutocompleteMatch(control: AbstractControl): ValidationErrors | null {
    const selection = control.value as string;
    if (
      this.currentSelectionOpts &&
      !(this.currentSelectionOpts as KeywordOption[]).find((opt) => opt.value == selection)
    ) {
      return { requireMatch: true };
    }
    return null;
  }

  /**
   * When search field name changes, update conditions and value fields
   */
  private initConditionAndValue(): void {
    this.removeSelectedHItem();
    this.getRowConditionControl().patchValue('');
    this.conditionField = '';
    this.getRowValueControl().patchValue('');
    this.setDefaultConditionValue();
  }

  /**
   * Set row condition by priority:
   * 1. If a default for a field is specified in config input, use that
   * 2. If a default for a field type is specified in config input, use that
   * 3. If config says to use standard defaults, use that
   */
  private setDefaultConditionValue() {
    let valueToSet: string;

    if (this.advSearchConditionConfig?.fieldNameDefaultConditions?.[this.currentSelection]) {
      valueToSet = this.advSearchConditionConfig.fieldNameDefaultConditions[this.currentSelection];
    } else if (this.advSearchConditionConfig?.facetTypeDefaultConditions?.[this.facetType]) {
      valueToSet = this.advSearchConditionConfig.facetTypeDefaultConditions[this.facetType];
    } else if (
      this.advSearchConditionConfig?.useStandardConditionDefaults &&
      this.facetTypeDefaultConditions[this.facetType]
    ) {
      valueToSet = this.facetTypeDefaultConditions[this.facetType];
    }

    if (valueToSet) {
      this.getRowConditionControl().setValue(valueToSet);
    }
  }

  setupRow(facetName: string): void {
    this.currentSelection = facetName;
    const facet = this.getFacetByName(facetName);
    this.isDateType = facet.type === DATE_FACET_TYPE;
    this.isHierarchicalType = facet.type === HIERARCHICAL_FACET_TYPE;
    this.isNumericType = facet.type === NUMERIC_FACET_TYPE;
    this.getRowTypeControl().setValue(facet.type.toLowerCase());
    this.getRowLabelControl().setValue(facet.label);
    this.rowTitle = facet.label;
    this.currentSelectionOpts = 'options' in facet ? facet.options : [];
    this.currentSelectionValues = 'values' in facet ? facet.values : [];
    this.facetType = facet.type;
    if (this.facetType === 'string' && this.currentSelectionOpts.length) {
      this.facetType = 'keyword';
    }
    this.setValidation();
    this.setFilteredOptions();
  }

  private setValidation(): void {
    this.getRowValueControl().removeValidators(this.requireAutocompleteValidator);
    if (this.facetType === 'keyword' && this.autocompleteKeyword) {
      this.getRowValueControl().addValidators(this.requireAutocompleteValidator);
    }
    this.getRowValueControl().updateValueAndValidity();
  }

  private setFilteredOptions(): void {
    if (this.facetType === 'keyword' && this.autocompleteKeyword) {
      this.filteredSelectionOpts$ = this.getRowValueLabelControl().valueChanges.pipe(
        startWith(''),
        map((value) => {
          return (this.currentSelectionOpts as Array<KeywordOption>).filter(
            (o) => o.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
          );
        })
      );
    }
  }

  getFacetByName(facetName: string): Facet {
    const result = this.facets.find((item: Facet) => item.name === facetName);
    if (!result) {
      throw new Error(`Could not find facet with name: ${facetName}`);
    }
    return result;
  }

  getOptionByValue(optionValue: string): FacetOption {
    const result = this.currentSelectionOpts?.find((option: FacetOption) => option.value === optionValue);
    return result;
  }

  getOptionIdByValue(optionValue: string): FacetOption {
    const result = this.currentSelectionValues?.find((option: FacetOption) => option.name === optionValue);
    return result;
  }

  addRow(): void {
    this.rowAdded.emit(this.indexNum + 1);
  }

  removeRow(): void {
    this.rowRemoved.emit(this.indexNum);
  }

  addGroup(): void {
    this.groupAdded.emit(this.indexNum + 1);
  }

  openHierarchyModal(optionsTree: Array<HierarchicalFacetOption>): void {
    this.dialogRef = this.dialog.open(HierarchyDialogComponent, {
      width: '600px',
      data: {
        title: this.rowTitle,
        optionsTree,
      },
    });

    this.dialogRef.afterClosed().subscribe((r) => {
      if (r) {
        this.getRowValueControl().patchValue(r.value);
        this.selectHItem(r.label);
        this.getRowValueLabelControl().setValue(r.label);
        this.getRowIdControl().setValue(r.id);
      }
    });
  }

  removeSelectedHItem(): void {
    this.selectedHItem = '';
    this.getRowValueControl().patchValue('');
    if (this.lookupField && this.lookupField.nativeElement) {
      this.lookupField.nativeElement.value = '';
    }
  }

  selectHItem(option: string): void {
    this.selectedHItem = option;
  }

  // TODO: remove
  private _filter(value: string): Array<string> {
    const filterValue = value.toLowerCase();
    return this.hLookUpOptions.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
