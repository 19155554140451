import { HttpErrorResponse } from '@angular/common/http';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { AppSearchSearchRequest, MobilusPubDocument, MobilusSearchResponse } from '../../shared/models/api-models';
import { UISearchFilters } from '../../shared/models/common-interfaces';
import { Criteria } from '../../modules/search/components/advanced-search/advanced-search.component';

export const search = createAction('[Search Page] Search', props<{ context: 'main_search' | 'search' }>());
export const searchSuccess = createAction(
  '[Search Page] Search Success',
  props<{ resp: MobilusSearchResponse; req: AppSearchSearchRequest }>()
);
export const searchFail = createAction('[Search Page] Search Fail', props<{ error: HttpErrorResponse }>());

export const setFilters = createAction(
  '[Search Page] Set Filters',
  props<{ filters: UISearchFilters; customFilters: UISearchFilters }>()
);
export const applyFilter = createAction('[Search Page] Apply Filter', props<{ fieldName: string; value: string }>());
export const removeFilter = createAction('[Search Page] Remove Filter', props<{ fieldName: string; value: string }>());

export const changePagination = createAction('[Search Page] Change Pagination', props<{ event: PageEvent }>());
export const changeSearchTerm = createAction('[Search Page] Change Search Term', props<{ searchTerm: string }>());
export const changeSortParams = createAction('[Search Page] Change Sort Params', props<{ sortParams: Sort }>());
export const saveScrollPosition = createAction('[Search Page] Save Scroll Position', props<{ scrollTop: number }>());

export const getSearchSuggestions = createAction(
  '[Advanced Search] Get Search Suggestions',
  props<{ searchTerm: string }>()
);
export const getSearchSuggestionsSuccess = createAction(
  '[Advanced Search] Successfully Retrieved Search Suggestions',
  props<{ suggestions: string[] }>()
);
export const getSearchSuggestionsFailure = createAction(
  '[Advanced Search] Failed To Retrieve Search Suggestions',
  props<{ error }>()
);
export const getDocumentSuggestions = createAction(
  '[Search Bar] Get Document Suggestions',
  props<{ searchTerm: string }>()
);
export const getDocumentSuggestionsSuccess = createAction(
  '[Search Bar] Successfully Retrieved Document Suggestions',
  props<{ suggestions: MobilusPubDocument[] }>()
);
export const getDocumentSuggestionsFailure = createAction(
  '[Search Bar] Failed To Retrieve Document Suggestions',
  props<{ error }>()
);
export const clearSearch = createAction('[Advanced Search] Clear Search');
export const resetSearch = createAction('[Advanced Search] Reset Search');
export const showMoreValues = createAction('[Search Page] Show More Values For Facet', props<{ fieldName: string }>());
export const expandAllFacets = createAction('[Search Page] Expand All Facets');
export const collapseAllFacets = createAction('[Search Page] Collapse All Facets');
export const clearPreservedFacet = createAction('[Search Page] Clear Preserved Facet');
export const initialSearch = createAction(
  '[Search Page] Initial Search',
  props<{
    advancedSearchCriteria?: Criteria[];
    searchTerm: string;
    filters: UISearchFilters;
    customFilters: UISearchFilters;
    pubDate: { to?: Date; from?: Date };
  }>()
);
export const applyPubDateFilter = createAction(
  '[Search Page] Apply Pub Date Filter',
  props<{ to: Date; from: Date }>()
);
export const applyCustomFilter = createAction(
  '[Search Page] Apply Customes Filter',
  props<{ fieldName: string; value: string }>()
);
export const removeCustomFilter = createAction(
  '[Search Page] Remove Customes Filter',
  props<{ fieldName: string; value: string }>()
);
export const removePubDateFilter = createAction('[Search Page] Remove Pub Date Filter');

export const setAlert = createAction('[Search Page] Set Alert', props<{ rootCode: string; alertId: string }>());
export const removeAlert = createAction('[Search Page] Remove Alert', props<{ alertId: string }>());

export const applyAdvancedSearchQuery = createAction(
  '[Advanced Search] Apply Advanced Search',
  props<{ criteria: Criteria[] }>()
);
export const disableAdvancedSearchQuery = createAction('[Advanced Search] Disable Advanced Search');
