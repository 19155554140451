<ng-template #template>
  <ng-container
    *ngIf="{
      fragment: fragment$ | async,
      title: title$ | async,
      description: description$ | async,
      appliedFacets: appliedFacets$ | async,
      results: results$ | async,
      defaultSearchTerm: defaultSearchTerm$ | async,
      total: total$ | async,
      sortOptions: sortOptions$ | async,
      sortBy: sortBy$ | async,
      sortDir: sortDir$ | async,
      pageSize: pageSize$ | async,
      pageNumber: pageNumber$ | async,
      loading: loading$ | async,
      itemsFragment: itemsFragment$ | async,
      subSessions: subSessions$ | async,
      error: error$ | async,
      filters: filters$ | async | keyvalue,
      customFilters: customFilters$ | async | keyvalue,
      pubDateFrom: pubDateFrom$ | async,
      pubDateTo: pubDateTo$ | async,
      isPubDateFilterApplied: isPubDateFilterApplied$ | async,
      journals: journals$ | async,
      journalsLoading: journalsLoading$ | async,
      journalOptions: journalOptions$ | async,
      contentSubGroup: contentSubGroup$ | async
    } as _"
  >
    <article class="si-content">
      <div class="si-content__h">
        <h1 class="si-content__title si-impact">{{ _.title }}</h1>
        <p>
          <span class="si-content__subtitle">
            {{ _.description }}
          </span>
        </p>
        <si-seo-chiplist aria-label="Only Filter" class="si-chiplist si-chiplist--filter si-offset--h">
          <ng-container *ngFor="let customFilter of _.customFilters">
            <ng-container *ngFor="let item of customFilter.value; let i = index">
              <mat-chip class="si-chip si-chip--filter si-chip--minor si-chip--long">
                <span>{{ item }}</span>
                <mat-icon
                  matChipRemove
                  title="Remove"
                  matTooltip="Remove"
                  (click)="removeCustomFilterChip(customFilter.key, item)"
                >
                  cancel
                </mat-icon>
              </mat-chip>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let filter of _.filters">
            <ng-container *ngFor="let item of filter.value; let i = index">
              <mat-chip class="si-chip si-chip--filter si-chip--minor si-chip--long">
                <span>{{ item }}</span>
                <mat-icon matChipRemove title="Remove" matTooltip="Remove" (click)="removeChip(filter.key, item)">
                  cancel
                </mat-icon>
              </mat-chip>
            </ng-container>
          </ng-container>
          <mat-chip *ngIf="_.isPubDateFilterApplied" class="si-chip si-chip--filter si-chip--minor si-chip--long">
            <span>Published from {{ _.pubDateFrom | date }} to {{ _.pubDateTo | date }}</span>
            <mat-icon matChipRemove title="Remove" matTooltip="Remove" (click)="removePublishedChip()">cancel</mat-icon>
          </mat-chip>
        </si-seo-chiplist>
        <span class="si-expanse"></span>
        <nav mat-tab-nav-bar class="si-tab si-offset--h">
          <a
            mat-tab-link
            [routerLink]="['./']"
            [fragment]="_.itemsFragment"
            [active]="_.fragment === _.itemsFragment || !_.fragment"
          >
            Items
          </a>
          <a
            mat-tab-link
            [routerLink]="['./']"
            fragment="tags"
            [active]="_.fragment === 'tags'"
            data-analytics="browse-page-meta-tags-tab"
          >
            Meta Tags
          </a>
        </nav>
      </div>
      <div class="si-content__c">
        <si-basic-data-viewer
          *ngIf="_.fragment === _.itemsFragment || !_.fragment"
          class="si-component"
          dataTitle="Items"
          [defaultSearch]="_.defaultSearchTerm"
          [total]="_.total"
          [loading]="_.loading || _.journalsLoading"
          [noneText]="noResultsText"
          [search]="true"
          [hideSearchBoxTooltip]="false"
          [noResults]="!_.results?.length && !_.journals?.length && !_.error"
          [pageSizeOptions]="pageSizeOptions"
          [sortOptions]="_.sortOptions"
          [sortDir]="_.sortDir"
          [sortBy]="_.sortBy"
          [pageSize]="_.pageSize"
          [pageNumber]="_.pageNumber - 1"
          (pageChanged)="pageChanged($event)"
          (sortChanged)="sortChanged($event)"
          (searchChanged)="searchChanged($event)"
          [paginator]="!_.error"
          [singleSortMenu]="!_.error"
        >
          <ng-container *ngIf="_.contentSubGroup !== 'Journal'; else journalList">
            <ng-container *ngFor="let card of _.results">
              <mobi-publication-document-card
                [pubDocument]="card"
                [returnUrlTitle]="_.title"
                pageName="browsePage"
              ></mobi-publication-document-card>
            </ng-container>
          </ng-container>

          <ng-template #journalList>
            <ng-container *ngFor="let journal of _.journals">
              <mobi-category-card
                class="si-component"
                preTitleText="SAE International Journal of"
                [titleText]="journal.label"
                [seoUri]="journal.url"
                [types]="[{ name: 'Journal', URL: '/journals', classes: 'si-chip--role' }]"
                [image]="journal.image"
                [options]="_.journalOptions"
                (optionSelected)="journalOptionSelected(journal, $event)"
              ></mobi-category-card>
            </ng-container>
          </ng-template>

          <si-none-actionable-card *ngIf="_.error" content="There was an issue retrieving your search results.">
            <button mat-flat-button class="si-button si-button--primary" (click)="retrySearch()">Retry</button>
          </si-none-actionable-card>
        </si-basic-data-viewer>
        <mobi-meta-tags
          *ngIf="_.fragment === 'tags'"
          [publicationDocuments]="_.results"
          (chipClicked)="metaTagChipClicked($event)"
        ></mobi-meta-tags>
      </div>
    </article>
  </ng-container>
</ng-template>
