import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map, merge } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: 'input[siIncrementalSearch]',
  standalone: true,
})
export class IncrementalSearchDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  @Input() debounceTime = 400;
  @Input() minChars = 2;

  @Output() searchChange = new EventEmitter<string>();

  ngAfterViewInit(): void {
    const element: HTMLElement = this.elementRef.nativeElement;

    if (element) {
      merge(
        fromEvent(element, 'keyup').pipe(
          map((e) => e as KeyboardEvent),
          filter((e: KeyboardEvent) => e.key !== 'Enter'),
          debounceTime(this.debounceTime),
          map((event: KeyboardEvent) => {
            const target = event.target as HTMLInputElement;
            const filterString = target.value;
            return !filterString || filterString.length < this.minChars ? '' : filterString;
          })
        ),
        fromEvent(element, 'keyup').pipe(
          map((e) => e as KeyboardEvent),
          filter((e: KeyboardEvent) => e.key === 'Enter'),
          map((event: KeyboardEvent) => {
            const target = event.target as HTMLInputElement;
            return target.value;
          })
        ),
        fromEvent(element, 'change').pipe(
          debounceTime(this.debounceTime),
          map((event) => {
            const target = event.target as HTMLInputElement;
            const filterString = target.value;
            return !filterString || filterString.length < this.minChars ? '' : filterString;
          })
        )
      )
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe((value: string) => {
          this.searchChange.emit(value);
        });
    }
  }
}
