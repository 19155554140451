<dl
  class="si-defined {{ classes }}"
  [class.si-defined--headless]="!overridePresentation"
  [class.si-defined--inline]="inline"
  [class.si-major]="major"
  [class.si-tight]="tight"
  [class.si-tight--horz]="tightHorz"
  [attr.role]="overridePresentation ? 'presentation' : presentation ? 'presentation' : null"
>
  <dt class="si-defined__h {{ headingClasses }}" #headingZone>
    <ng-content select="[headingStart]"></ng-content>
    <fs-comp-util-heading
      *ngIf="heading || label"
      [classes]="headingClasses"
      [heading]="heading"
      [label]="label"
      [hTag]="hTag"
    ></fs-comp-util-heading>
    <ng-content select="[headingEnd]"></ng-content>
  </dt>
  <dd class="si-defined__c {{ bodyClasses }}">
    <ng-content select="[bodyStart]"></ng-content>
    <fs-part-text *ngIf="bodyText" [text]="bodyText" class="si-parttext__wrap"></fs-part-text>
    <fs-comp-row-text *ngFor="let row of bodyRowText" [rowText]="row"></fs-comp-row-text>
    <ng-content select="[bodyEnd]"></ng-content>
  </dd>
</dl>
