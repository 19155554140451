<div class="si-chiplist--navfilter">
  <button mat-icon-button [matTooltip]="previousTooltip" color="primary" class="si-icon" (click)="onBackClick()">
    <mat-icon aria-hidden="true">arrow_back_ios_new</mat-icon>
  </button>
  <mat-chip-list aria-label="Filters" class="si-chiplist">
    <mat-chip class="si-chip" (click)="onChipClick()">
      <span>{{ chipText }}</span>
      <mat-icon matChipRemove [title]="cancelTooltip" (click)="onCancelClick()">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <button mat-icon-button [matTooltip]="nextTooltip" color="primary" class="si-icon" (click)="onNextClick()">
    <mat-icon aria-hidden="true">arrow_forward_ios_new</mat-icon>
  </button>
</div>
