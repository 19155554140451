<div class="si-dialog">
  <div class="si-dialog__h si-major">
    <h2 class="si-dialog__title">
      <ng-container *ngIf="dialogData.mode === 'edit'; else addTitle">
        <ng-container *ngIf="allAnnotations.length > 1">
          Annotation {{ currentAnnotationIndex }} of {{ allAnnotations.length }}
        </ng-container>
        <ng-container *ngIf="allAnnotations.length === 1">Edit Annotation</ng-container>
      </ng-container>
      <ng-template #addTitle>Add Annotation</ng-template>
    </h2>
    <span class="si-expanse"></span>
    <ng-container *ngIf="dialogData.mode === 'edit' && allAnnotations.length > 1">
      <button
        mat-icon-button
        matTooltip="Previous"
        aria-label="Previous"
        class="si-icon"
        color="primary"
        (click)="previousAnnotation()"
      >
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Next"
        aria-label="Next"
        class="si-icon"
        color="primary"
        (click)="nextAnnotation()"
      >
        <mat-icon>navigate_next</mat-icon>
      </button>
    </ng-container>
    <button mat-icon-button matTooltip="Close" aria-label="Close" class="si-icon si-button--close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="submitting === false" class="si-dialog si-col">
    <div class="si-col--main si-wd si-wd-half si-tight--vert">
      <div class="si-dialog__c si-exempt">
        <ng-container>
          <form class="si-form" [formGroup]="editAnnotationForm">
            <div class="si-form__b">
              <mat-form-field class="si-field si-wd si-wd-full" appearance="outline">
                <mat-label class="si-label">Comment</mat-label>
                <textarea
                  matInput
                  cdkTextareaAutosize
                  class="si-textarea si-textarea--extensive"
                  cdkFocusInitial
                  formControlName="text"
                  required
                ></textarea>
                <mat-error class="si-field__error">Comment is required</mat-error>
              </mat-form-field>
            </div>
            <div class="si-form__a si-state--unusual si-tight--vert">
              <button
                *ngIf="dialogData.mode === 'edit'"
                mat-icon-button
                matTooltip="Delete"
                aria-label="Delete"
                class="si-button si-icon"
                color="primary"
                (click)="deleteAnnotation()"
              >
                <mat-icon aria-hidden="true">delete</mat-icon>
              </button>
              <ng-container *ngIf="dialogData.mode === 'edit'">
                <button
                  mat-flat-button
                  class="si-button si-button--primary"
                  [class.si-state--disabled]="editAnnotationForm.invalid || !editAnnotationForm.dirty"
                  [disabled]="editAnnotationForm.invalid || !editAnnotationForm.dirty"
                  (click)="editAnnotation()"
                >
                  Save Changes
                </button>
              </ng-container>
              <ng-container *ngIf="dialogData.mode === 'add'">
                <button
                  *ngIf="!submitting"
                  mat-flat-button
                  class="si-button si-button--primary"
                  [class.si-state--disabled]="editAnnotationForm.invalid"
                  [disabled]="editAnnotationForm.invalid"
                  (click)="createAnnotation()"
                >
                  Save Annotation
                </button>
              </ng-container>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
    <div class="si-col--aux si-wd si-wd-half si-exempt si-tight--vert">
      <div class="si-form">
        <div class="si-form__b">
          <div class="si-mark--annotation si-wd si-wd--full si-dialog__text">
            {{ currentAnnotation.annotation.quote }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="submitting === true" class="si-spinner__container">
    <mat-spinner class="si-spinner"></mat-spinner>
  </div>
</div>
