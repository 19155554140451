import { SortDirection } from '@angular/material/sort';
import { Hash } from '@sae/models';
import { AppSearchResponseFacet, AppSearchResponseGenericFacet, RangeFacetData, ValueFacetData } from './api-models';

export interface Breadcrumbs {
  title: string;
  url: string;
  id?: string;
}

export interface BasicCard {
  customIcon?: string;
  materialIcon?: string;
  title?: string;
  subTitle?: string;
  subTitlePrimary?: string;
  subTitleStatus?: string;
  action?: string;
  actionAppLink?: string;
  actionAppHref?: string;
  appLink?: string;
  hrefLink?: string;
  ext?: string;
  isActive?: boolean;
  analyticsId?: string;
}

export interface BasicChip {
  name: string;
  href: string;
}

export interface Link {
  label: string;
  url: string;
  selected?: boolean;
}

export interface Comment {
  id: string;
  author: string;
  authorAvatar?: string;
  role?: string;
  title?: string;
  createdDate: string;
  createdTime: string;
  text?: string;
  suggested?: string;
  replyCount?: number;
  replies?: Comment[];
  reported?: boolean;
}

export interface FacetItem {
  active?: boolean;
  displayName: string;
  fieldName: string;
  defaultNumberOfFacets: number;
  values: FacetValue[];
}

export interface FacetValue {
  id?: string;
  name: string;
  icon?: string;
  count: number | string;
  active: boolean;
  url?: string;
}

export interface FeatureFlagItem {
  ff_flag?: string;
}

export interface TableParams {
  searchTerm?: string;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortDir: SortDirection;
  total: number;
}

export interface UISearchFacet<TData> extends AppSearchResponseGenericFacet<TData> {
  displayName: string;
  expanded: boolean;
  numValuesShown: number;
  subHeader?: string;
}

export interface UISearchFilters {
  [key: string]: string[];
}

export interface CommonFacets {
  collection_title: UISearchFacet<ValueFacetData>;
  industrysectors_name: UISearchFacet<ValueFacetData>;
  sub_group: UISearchFacet<ValueFacetData>;
  pub_date: UISearchFacet<RangeFacetData>;
  topics: UISearchFacet<ValueFacetData>;
  authors: UISearchFacet<ValueFacetData>;
  affiliations: UISearchFacet<ValueFacetData>;
  committee_name: UISearchFacet<ValueFacetData>;
  publisher_name: UISearchFacet<ValueFacetData>;
  conference_name: UISearchFacet<ValueFacetData>;
  full_text_formats: UISearchFacet<ValueFacetData>;
  categories: UISearchFacet<ValueFacetData>;
  features: UISearchFacet<ValueFacetData>;
}
export interface BatteryDBFacets {
  // model: UISearchFacet<ValueFacetData>;
  supplierName: UISearchFacet<ValueFacetData>;
  cellFormat: UISearchFacet<ValueFacetData>;
  length: UISearchFacet<ValueFacetData>;
  height: UISearchFacet<ValueFacetData>;
  width: UISearchFacet<ValueFacetData>;
  // cylindrical_designation: UISearchFacet<ValueFacetData>;
  // chemistry: UISearchFacet<ValueFacetData>;
  // nominal_capacity: UISearchFacet<ValueFacetData>;
  // last_updated_date: UISearchFacet<RangeFacetData>;
  // voltage_minimum: UISearchFacet<ValueFacetData>;
  // voltage_maximum: UISearchFacet<ValueFacetData>;
  // voltage_nominal: UISearchFacet<ValueFacetData>;
  // weight: UISearchFacet<ValueFacetData>;
  // diameter: UISearchFacet<ValueFacetData>;
  // specific_energy_density: UISearchFacet<ValueFacetData>;
  // gravimetric_energy_density: UISearchFacet<ValueFacetData>;
  // depth_of_discharge: UISearchFacet<ValueFacetData>;
  // standard_charge_current_c_rate: UISearchFacet<ValueFacetData>;
  // standard_charge_current_amps: UISearchFacet<ValueFacetData>;
  // max_charge_current_c_rate: UISearchFacet<ValueFacetData>;
  // max_charge_current_amps: UISearchFacet<ValueFacetData>;
  // standard_discharge_current_c_rate: UISearchFacet<ValueFacetData>;
  // standard_discharge_current_amps: UISearchFacet<ValueFacetData>;
  // max_discharge_current_c_rate: UISearchFacet<ValueFacetData>;
  // max_discharge_current_amps: UISearchFacet<ValueFacetData>;
  // max_discharge_duration: UISearchFacet<ValueFacetData>;
  // max_continuous_discharge_current: UISearchFacet<ValueFacetData>;
  // charge_temperature_minimum: UISearchFacet<ValueFacetData>;
  // charge_temperature_maximum: UISearchFacet<ValueFacetData>;
  // discharge_temperature_minimum: UISearchFacet<ValueFacetData>;
  // discharge_temperature_maximum: UISearchFacet<ValueFacetData>;
  // storage_temperature_minimum: UISearchFacet<ValueFacetData>;
  // storage_temperature_maximum: UISearchFacet<ValueFacetData>;
  // certifications: UISearchFacet<ValueFacetData>;
  // third_party_test_data: UISearchFacet<ValueFacetData>;
}

export const CommonFacetsDefaultValues: CommonFacets = {
  collection_title: {
    displayName: 'Collections',
    numValuesShown: 10,
    expanded: false,
  },
  industrysectors_name: {
    displayName: 'Industries',
    numValuesShown: 5,
    expanded: true,
  },
  sub_group: {
    displayName: 'Content Types',
    numValuesShown: 10,
    expanded: true,
  },
  pub_date: {
    type: 'range',
    name: 'pub_date',
    displayName: 'Published',
    numValuesShown: 1,
    expanded: true,
  },
  topics: {
    displayName: 'Topics',
    numValuesShown: 15,
    expanded: true,
  },
  authors: {
    displayName: 'Authors',
    numValuesShown: 10,
    expanded: false,
  },
  affiliations: {
    displayName: 'Affiliations',
    numValuesShown: 10,
    expanded: false,
  },
  committee_name: {
    displayName: 'Committees',
    numValuesShown: 10,
    expanded: false,
  },
  publisher_name: {
    displayName: 'Publishers',
    numValuesShown: 10,
    expanded: false,
  },
  conference_name: {
    displayName: 'Events',
    numValuesShown: 10,
    expanded: false,
  },
  full_text_formats: {
    displayName: 'Search Formats',
    numValuesShown: 10,
    expanded: false,
  },
  categories: {
    displayName: 'Categories',
    numValuesShown: 10,
    expanded: false,
  },
  features: {
    displayName: 'Features',
    numValuesShown: 10,
    expanded: false,
  },
};

export const BatteryDBFacetsDefaultValues: BatteryDBFacets = {
  supplierName: {
    displayName: 'Supplier Name',
    numValuesShown: 5,
    expanded: true,
  },
  cellFormat: {
    displayName: 'Cell Format',
    numValuesShown: 5,
    expanded: true,
  },
  length: {
    type: 'range',
    name: 'length',
    displayName: 'Length',
    numValuesShown: 1,
    expanded: true,
    subHeader: 'mm',
  },
  height: {
    type: 'range',
    name: 'height',
    displayName: 'Height',
    numValuesShown: 1,
    expanded: true,
    subHeader: 'mm',
  },
  width: {
    type: 'range',
    name: 'width',
    displayName: 'Width',
    numValuesShown: 1,
    expanded: true,
    subHeader: 'mm',
  },
};

interface AssetUser {
  id: string;
  username: string;
  emailAddress: string;
  nickname: string;
  givenName: string;
  familyName: string;
}

export interface Asset {
  id: string;
  contentId: number;
  ingestionId: string;
  filename: string;
  path: string;
  searchType: string;
  size: number;
  hashes: Hash[];
  fileType: string;
  mimeType: string;
  revisionNo: number;
  priority: number;
  formatPubType: string;
  code: string;
  folder: string;
  fileDate: Date;
  exemptWatermark: boolean;
  title: string;
  description: string;
  createdBy: AssetUser;
  createdDate: Date;
  modifiedBy: AssetUser;
  modifiedDate: Date;
}

export interface AssetQuery {
  contentId: string;
  datasetIndex?: number;
  ingestionId?: string;
  searchType?: string;
  fileType?: string;
  filename?: string;
  offset?: number;
  limit?: number;
}

export enum LoginLocation {
  ToolBar = 'TOOLBAR',
  DownLoadDialog = 'DOWNLOAD_DIALOG',
}
