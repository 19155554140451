import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '@sae/models';

@Pipe({
  name: 'resourceUser',
})
export class ResourceUserPipe implements PipeTransform {
  transform(resource: Resource): string {
    let firstName = '';
    let lastName = '';
    let userString = '';
    if (resource.modifiedByUser) {
      firstName = resource.modifiedByUser.firstName ? resource.modifiedByUser.firstName : '';
      lastName = resource.modifiedByUser.lastName ? resource.modifiedByUser.lastName : '';
    } else if (resource.createdByUser) {
      firstName = resource.createdByUser.firstName ? resource.createdByUser.firstName : '';
      lastName = resource.createdByUser.lastName ? resource.createdByUser.lastName : '';
    }
    userString = `${firstName} ${lastName}`.trim();
    if (userString) {
      userString = ' by ' + userString;
    }
    return userString;
  }
}
