import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonItem } from '../../parts/part-button/part-button.component';
import { PartCompoundComponent, CompoundConfig } from '../../parts/part-compound/part-compound.component';
import { ChipItem } from '../../compounds/comp-chip/comp-chip.component';
import { UIChipGroupComponent } from '../ui-chip-group/ui-chip-group.component';
import {
  MixedCompoundsContent,
  CompUtilMixedCompoundsComponent,
} from '../../compounds/comp-util-mixed-compounds/comp-util-mixed-compounds.component';
import { overrideInputs } from '@sae/base';

export interface MixedComponentsContent extends MixedCompoundsContent {
  chips?: ChipItem[];
}

export interface MixedComponentsConfig {
  compoundWrap?: CompoundConfig;
}

@Component({
  selector: 'fs-ui-util-mixed-components',
  styleUrls: ['ui-util-mixed-components.component.scss'],
  templateUrl: './ui-util-mixed-components.component.html',
  standalone: true,
  imports: [CommonModule, PartCompoundComponent, CompUtilMixedCompoundsComponent, UIChipGroupComponent],
})
export class UIUtilMixedComponentsComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: MixedComponentsConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() compoundWrap: CompoundConfig; // Optional, provides a compound wrapper around the body
  @Input() content: MixedComponentsContent[];
  @Input() config: MixedComponentsConfig; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  @Output() chipClick = new EventEmitter<ChipItem>(); // Event emitted when component is in button mode (url is null)
  @Output() removeChipClick = new EventEmitter<ChipItem>(); // Event emitted when component remove chip is clicked
  @Output() buttonClick = new EventEmitter<ButtonItem>(); // Event emitted when component is in button mode (url is null)

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['config']) {
      overrideInputs(this, this.config);
    }
  }

  onButtonClick(button: ButtonItem): void {
    this.buttonClick.emit(button);
  }

  onChipClick(chip: ChipItem): void {
    this.chipClick.emit(chip);
  }

  onRemoveChipClick(chip: ChipItem): void {
    this.removeChipClick.emit(chip);
  }
}
