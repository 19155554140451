import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { UtilitiesService } from '../utilities.service';

@Directive({
  selector: 'a[siSanitizeUrl]',
})
export class SanitizeUrlDirective implements OnInit {
  @Input()
  @HostBinding('href')
  href?: string;

  @HostBinding('rel')
  rel = 'noopener'; // new window gets separate process and no link to opening window

  @HostBinding('target')
  target = '_blank';

  private badUrl = false;

  constructor(private utils: UtilitiesService) {}

  ngOnInit() {
    if (this.href && !this.utils.isValidUrl(this.href)) {
      this.href = '#';
      this.badUrl = true;
    }
  }

  @HostListener('click', ['$event']) clickHandler(event: Event): void {
    if (this.badUrl) {
      event.preventDefault();
    }
  }

}
