import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { overrideInputs } from '@sae/base';

export interface PartDelimiterConfig {
  delimiter?: string;
}

@Component({
  selector: 'fs-part-delimiter',
  styleUrls: ['part-delimiter.component.scss'],
  templateUrl: './part-delimiter.component.html',
  standalone: true,
})
export class PartDelimiterComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartDelimiterConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() delimiter = '&#183;'; // Use another HTML character for the delimiter, enter 'space' for a space

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }
}
