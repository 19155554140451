import { StoresRegistry } from '@sae/services';

export const Stores: StoresRegistry = {
  remoteConfig: 'RemoteConfig',
  auth: 'Auth',
  session: 'Session',
  comments: 'Comments',
  featureFlags: 'FeatureFlags',
  notifications: 'Notifications',
  digitalStandards: 'DigitalStandards',
  user: 'User',
  //  ballots: 'Ballots',
  //  committees: 'Committees',
  //  documents: 'Documents',
  //  projectRequest: 'ProjectRequests',
  //  resources: 'Resources',
  //  roleMapping: 'Roles',
  //  rosters: 'Committee Rosters',
  //  users: 'Users',
};
