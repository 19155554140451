
<ng-container
*ngIf="{
  sortDir: sortDir$ | async
} as _"
>
<si-basic-data-viewer
  dataTitle="Data Sets - Support Documents"
  [sortOptions]="['Type', 'Title', 'Description']"
  [sortBy]="defaultSortBy"
  [sortDir]="_.sortDir"
  [pageSizeOptions]="[25, 50, 100]"
  [total]="pagination?.total"
  (pageChanged)="onPageChange($event)"
  (sortChanged)="onSortChange($event)"
  *ngIf="!!dataSets?.length"
>
  <ng-container *ngFor="let asset of dataSets">
    <mat-divider class="si-divider"></mat-divider>
    <div class="si-wrap--control">
      <button class="si-component si-button--wrap" mat-button>
        <mat-card class="si-card si-card--basic si-card--file mat-elevation-z">
          <mat-card-header class="si-card__h">
            <span mat-card-avatar class="si-file-icon">
              <a
                *ngIf="!accessProperties?.access"
                (click)="openDownloadDialog(asset)"
                class="si-icon__wrapper si-icon--file si-icon--file--xls"
              >
                <mat-icon aria-hidden="true">insert_drive_file</mat-icon>
                <div class="si-dec">XLS</div>
              </a>
              <a
                *ngIf="accessProperties?.access"
                [downloadFile]="getDownloadLink(asset)"
                [href]="getDownloadLink(asset)"
                fileName="{{ asset?.filename ?? asset?.fileName }}"
                class="si-icon__wrapper si-icon--file si-icon--file--xls"
              >
                <mat-icon aria-hidden="true">insert_drive_file</mat-icon>
                <div class="si-dec">XLS</div>
              </a>
            </span>
            <mat-card-title class="si-card__title">
              <a
                *ngIf="accessProperties?.access"
                [downloadFile]="getDownloadLink(asset)"
                [href]="getDownloadLink(asset)"
                fileName="{{ asset?.filename ?? asset?.fileName }}"
              >
                {{ asset.title ?? asset.filename ?? asset.fileName ?? 'Unnamed Dataset' }}
              </a>
              <a *ngIf="!accessProperties?.access" (click)="openDownloadDialog(asset)">
                {{ asset.title ?? asset.filename ?? asset.fileName ?? 'Unnamed Dataset' }}
              </a>
            </mat-card-title>
            <mat-card-subtitle class="si-card__subtitle" *ngIf="asset.description">
              {{ asset.description }}
              <span class="si-expanse"></span>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </button>
    </div>
  </ng-container>
  <mat-divider class="si-divider"></mat-divider>
</si-basic-data-viewer>
</ng-container>


