<ng-container
  *ngIf="{
    masterWidth: masterWidth$,
    activeViewTemplate: activeViewTemplate$ | async,
    showMobileNavMenu: showMobileNavMenu$ | async,
    showRegister: showRegister$ | async,
    isLoggedIn: isLoggedIn$ | async,
    loggedInUser: loggedInUser$ | async,
    personType: personType$ | async,
    showCustomToolbar: showCustomToolbar$ | async
  } as _"
>
  <div #customToolbar>
    <ng-content select="[customToolbarContent]" *ngIf="_.showCustomToolbar"></ng-content>
  </div>
  <mat-toolbar
    *ngIf="!_.showCustomToolbar"
    class="si-toolbar-top si-narrow--top"
    [class.si-wide--master]="_.masterWidth.toString() === 'master'"
    [class.si-template--d]="_.activeViewTemplate === ViewTemplate.Detail || _.activeViewTemplate === ViewTemplate.DetailTakeover"
    [class.si-template--d-t]="_.activeViewTemplate === ViewTemplate.DetailTool || _.activeViewTemplate === ViewTemplate.DetailToolTakeover"
    [class.si-template--m-d]="_.activeViewTemplate === ViewTemplate.MasterDetail"
    [class.si-template--m-d-t]="_.activeViewTemplate === ViewTemplate.MasterDetailTool"
    [class.si-template--c-m-d]="_.activeViewTemplate === ViewTemplate.CrossoverMasterDetail"
  >
    <mat-toolbar-row>
      <button
        mat-icon-button
        data-testid="showMobileNavMenu"
        role="button"
        aria-label="Menu"
        class="si-button--menu"
        (click)="toggleMobileNavMenuClick(_.showMobileNavMenu)"
        [class.si-state--selected]="_.showMobileNavMenu"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <si-enterprise-menu></si-enterprise-menu>
      <span class="si-expanse"></span>
      <si-notifications
        class="si-alert__wrap"
        *ngIf="_.isLoggedIn"
        [hideNotificationOptions]="hideNotificationOptions"
      ></si-notifications>
      <button class="si-button si-button--user" mat-icon-button [matMenuTriggerFor]="userMenu" *ngIf="_.isLoggedIn">
        <div class="si-avatar mat-card-avatar">{{ getPersonTagInfo(_.loggedInUser) | initials }}</div>
      </button>
      <mat-menu #userMenu="matMenu" class="si-menu">
        <div class="si-menu__component">
          <si-person-card-tag
            [personType]="_.personType"
            [personTagInfo]="getPersonTagInfo(_.loggedInUser)"
            class="si-tag si-tag--person"
          ></si-person-card-tag>
        </div>
        <button mat-menu-item class="si-button--detailed" data-testid="manage" (click)="manage()">
          <mat-icon color="primary" aria-hidden="true">account_circle</mat-icon>
          <span>Manage My Profile</span>
        </button>
        <mat-divider class="si-divider"></mat-divider>
        <button mat-menu-item data-testid="logout" (click)="logout()" class="si-button--detailed">
          <mat-icon color="primary">exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
      <button
        mat-button
        data-testid="login"
        class="si-button"
        *ngIf="!_.isLoggedIn && !_.showRegister"
        (click)="login()"
      >
        Login
      </button>
      <a routerLink="/register" mat-button class="si-button" *ngIf="!_.isLoggedIn && _.showRegister">
        Login / Register
      </a>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-container>
