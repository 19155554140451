import { Component, Input, OnInit } from '@angular/core';
import { catchError, finalize, switchMap } from 'rxjs';
import { DetailsHelperService } from '../../../../services/details-helper.service';
import { ContentService } from '../../../../../../shared/state/content.service';

@Component({
  selector: 'mobi-document-view',
  templateUrl: './document-view.component.html',
})
export class DocumentViewComponent implements OnInit {
  @Input() contentId: number;

  public loading = false;
  public error = false;

  constructor(private contentService: ContentService, private detailsHelperService: DetailsHelperService) {}

  ngOnInit(): void {
    this.getHTML();
  }

  getHTML(): void {
    const getHTML = this.contentService
      .getHTMLView(this.contentId)
      .pipe(switchMap((html) => this.detailsHelperService.buildAndReplaceHTML(html, 'document-view', this.contentId)));

    this.error = false;
    this.loading = true;
    getHTML
      .pipe(
        catchError((e) => {
          this.error = true;
          return e;
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  retryAfterError(): void {
    this.getHTML();
  }
}
