import { Component } from '@angular/core';
import { ContentService } from '../../../../../shared/state/content.service';
@Component({
  selector: 'mobi-detail-instructors',
  templateUrl: './detail-instructors.component.html',
})
export class DetailInstructorsComponent {
  public content$ = this.contentService.content$;
  constructor(private contentService: ContentService) {}
}
