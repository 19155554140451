import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { overrideInputs } from '@sae/base';

export interface CompListContainerConfig {
  hasChildren?: boolean;
}

@Component({
  selector: 'fs-comp-list-container',
  styleUrls: ['comp-list-container.component.scss'],
  templateUrl: './comp-list-container.component.html',
  standalone: true,
})
export class CompListContainerComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: CompListContainerConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() hasChildren: boolean; // set to true if this list has children for proper accessibility

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }
}
