import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ContentService } from '../../../../../shared/state/content.service';

@Component({
  selector: 'mobi-journal-article-panel',
  templateUrl: './journal-article-panel.component.html',
})
export class JournalArticlePanelComponent implements OnInit {
  @ViewChild('template', { static: true }) template;
  public content$ = this.contentService.content$;
  public journalBrxDetail$ = this.contentService.journalBrxDetail$;
  public routeLoading$ = this.contentService.routeLoading$;

  constructor(private viewContainerRef: ViewContainerRef, private contentService: ContentService) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
}
