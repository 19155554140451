/**
 * Generates a random UUIDv4 string using Math.random()
 * @returns UUID string
 */
export function randomUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const num = (Math.random() * 16) | 0;
    return (char == 'x' ? num : (num & 0x3) | 0x8).toString(16);
  });
}
