import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, map, of, tap } from 'rxjs';
import { DISCLAIMER, LINEBREAK } from '../../../shared/string-files/common-strings';
import { RootActions } from '../../../store';
import { DownloadDocumentActions, DownloadDocumentSelectors } from '../../../store/download-document';
import { SearchActions } from '../../../store/search';
import { MobilusPubDocument } from '../../models/api-models';
import { Contributor, ContributorGroup } from '../../models/search-result.model';
import { BuildDetailsLinkPipe } from '../../pipes/build-details-link.pipe';
import { AuthService } from '../../state/auth.service';
import { ContentService } from '../../state/content.service';
import { SessionService } from '../../state/session.service';
import { SubscriptionSession, SubscriptionsService } from '../../state/subscriptions.service';
import { AddToDialogComponent } from '../add-to-dialog/add-to-dialog.component';
import { UtilityService } from '../../services/utility.service';
import { AlertsUiService } from '../../services/alerts-ui.service';
import { SnackbarDialogService } from '../../services/snackbar-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert } from '../../models/alerts.model';
import { BrowseActions } from '../../../store/browse';
import { isFuture } from 'date-fns';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'mobi-publication-document-card',
  templateUrl: './publication-document-card.component.html',
})
export class PublicationDocumentCardComponent implements OnInit {
  @Input() pubDocument: MobilusPubDocument;
  @Input() returnUrlTitle = 'Back';
  @Input() showSummary = true;
  @Input() cardShading = false;
  @Input() simple = false;
  @Input() detailCid? = null;
  @Input() autoScale = false;
  @Input() pageName = null;

  featureDataSets: boolean;
  featureAnnotate: boolean;
  feature2d3d: boolean;
  featureRedline: boolean;
  featureDigitalStandard: boolean;
  public inMyLibrary = false;

  public loadingAssets$ = this.store.select(DownloadDocumentSelectors.selectLoadingAssets);
  public fileFormats$;
  public canDirectlyDownload: boolean;
  public isDownloadable: boolean;

  public hasBeenDownloaded$ = combineLatest([this.sessionService.downloadedCids$]).pipe(
    map(([cids]) => cids.includes(+this.pubDocument.cid.raw))
  );
  public subSessions$ = this.subService.subscriptions$;
  public isLoggedIn$ = this.authService.isLoggedIn$;
  public subLoggedIn$ = this.subService.subscriptions$.pipe(map((subscriptions) => subscriptions?.length > 0));
  constructor(
    private snackbar: MatSnackBar,
    private buildDetailsLinkPipe: BuildDetailsLinkPipe,
    private store: Store,
    private authService: AuthService,
    private dialog: MatDialog,
    private contentService: ContentService,
    private subService: SubscriptionsService,
    private sessionService: SessionService,
    private router: Router,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private alertService: AlertsUiService,
    private snackbarDialogService: SnackbarDialogService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit(): void {
    this.inMyLibrary = this.pubDocument.bookmarks?.some((bookmark) => bookmark.folderName === 'My Favorites');
    this.featureAnnotate = this.pubDocument?.features?.raw?.includes('Annotate');
    this.isDownloadable =
      !!this.pubDocument?.full_text_formats?.raw?.some((format) => format !== 'HTML' && format !== 'Video') &&
      !isFuture(new Date(this.pubDocument?.pub_date?.raw).getTime());
    this.canDirectlyDownload =
      this.pubDocument.accessProperties.access &&
      (this.pubDocument.accessProperties?.meterDetails.metered === false ||
        (!this.pubDocument?.accessProperties.meterDetails?.requestContentRestricted &&
          this.pubDocument?.accessProperties.meterDetails?.quantity === 0));

    this.featureDataSets = this.pubDocument?.features?.raw?.includes('Data Sets');
    this.feature2d3d = this.pubDocument?.features?.raw?.includes('2D/3D');
    this.featureRedline = this.pubDocument?.features?.raw?.includes('Redlined');
    this.featureDigitalStandard = this.pubDocument?.features?.raw?.includes('Digital Standards');
  }

  openClipboardSnackbar(): void {
    this.snackbar.open('URL copied to clipboard', 'DISMISS', { duration: 4500 });
    this.shareDocumentUsageEvent();
  }

  shareDocumentUsageEvent(): void {
    this.store.dispatch(
      RootActions.usageTrackingEvent({
        event: { eventType: 'share_publication', contentIds: [+this.pubDocument?.cid?.raw] },
      })
    );
  }

  getShareUrl(): string {
    // AU TODO: Refactor this to include the actual URL
    if (isPlatformServer(this.platformId)) {
      return '';
    }
    return window.location.origin + this.pubDocument?.mobilus_platform_uri?.raw;
  }

  openMailApp(): void {
    const bodyFirstLine = `I saw this information from SAE International and I think you will find it very useful.\r\n`;
    const bodySecondLine = 'Title:' + ' ' + this.pubDocument?.product_code?.raw + ' ' + this.pubDocument?.title?.raw;
    const itemUrl = 'URL:' + ' ' + this.getShareUrl();
    const body = bodyFirstLine + bodySecondLine + LINEBREAK + itemUrl + ' ' + LINEBREAK + DISCLAIMER;
    const encodedBody = encodeURIComponent(body);
    const mailText = `mailto:?subject=FYI - Sharing an interesting article I came across on SAE Mobilus&body=${encodedBody}`;
    window.location.href = mailText;
    this.shareDocumentUsageEvent();
  }

  formAuthorsArray(contribGroup: Array<ContributorGroup>): Array<string> {
    const authors: Array<string> = contribGroup.reduce(
      (final: Array<string>, current: ContributorGroup): Array<string> => {
        if (!!current?.contrib && current?.contrib?.length > 0) {
          const res: Array<string> = current.contrib.reduce((accum: Array<string>, cur: Contributor): Array<string> => {
            return cur.fullName ? [...accum, cur.fullName] : accum;
          }, []) as Array<string>;
          return [...final, ...res];
        }
      },
      []
    );
    return authors;
  }

  searchBySubGroup(subGroup: string): void {
    const route = this.utilityService.subGroupToBrowseRoute(subGroup);
    if (!this.router.url.includes(route)) {
      if (this.router.url.startsWith('/search') && route.startsWith('/search')) {
        this.store.dispatch(SearchActions.applyFilter({ fieldName: 'sub_group', value: subGroup }));
      } else {
        this.router.navigateByUrl(this.utilityService.subGroupToBrowseRoute(subGroup));
      }
    }
  }

  openAddToMyLibraryDialog(isAddToShared = false): void {
    const loggedIn = this.authService.isLoggedIn();
    if (loggedIn) {
      const ref = this.dialog.open<AddToDialogComponent>(AddToDialogComponent, {
        data: { contentId: this.pubDocument?.cid?.raw, isAddToShared },
      });
      const librarySub = ref.componentInstance.inMyLibraryChanged
        .pipe(tap((inMyLibrary) => (this.inMyLibrary = inMyLibrary)))
        .subscribe();
      ref.afterClosed().subscribe(() => librarySub.unsubscribe());
    } else {
      this.authService.login();
    }
  }

  openDownloadDialog(subSessions: SubscriptionSession[]): void {
    this.store.dispatch(
      DownloadDocumentActions.openFromDetailPage({
        contentId: +this.pubDocument?.cid?.raw,
        accessProperties: this.pubDocument?.accessProperties,
        subSessions,
        purchaseLink:
          // this.pubDocument?.documentUris?.platformUris?.find((uri) => uri?.platformName === 'SAE-WEBSITE')?.legacyUri ??
          'https://sae.org',
        detailCid: this.detailCid,
        title: this.pubDocument?.title?.raw,
        code: this.pubDocument?.product_code?.raw,
        itemUrl: window.location.href.split('/').slice(0, 3).join('/') + this.pubDocument.mobilus_platform_uri.raw,
      })
    );
  }
  fileDownloaded(cid: number): void {
    this.sessionService.addDownloadedCid(cid);
  }
  retryGettingAssets(contentId: number): void {
    this.fileFormats$ = this.contentService.getFileFormats(contentId);
  }

  getDownloadFileFormat(): void {
    this.fileFormats$ = this.contentService.getFileFormats(+this.pubDocument?.cid?.raw);
  }

  setReturnUrl(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    // AU TODO: Potentially refactor this
    const scrollTop = document.getElementsByClassName('si-col si-col--detail')[0].scrollTop;
    this.store.dispatch(
      SearchActions.saveScrollPosition({
        scrollTop,
      })
    );
    this.contentService.setReturnUrl(this.router.url, this.returnUrlTitle);
  }

  addAlert(): void {
    const loggedIn = this.authService.isLoggedIn();
    if (loggedIn) {
      this.alertService
        .createAlertForPublication(this.pubDocument)
        .pipe(
          map((alert: Alert) => {
            this.snackbarDialogService.openSnackbar('Alert has been set', 'DISMISS', { duration: 3000 });
            const rootCode = alert?.meta?.rootCode as string;
            const alertId = alert?.id;
            if (this.pageName === 'searchPage') {
              this.store.dispatch(SearchActions.setAlert({ rootCode, alertId }));
            } else if (this.pageName === 'browsePage') {
              this.store.dispatch(BrowseActions.setAlert({ rootCode, alertId }));
            } else {
              this.pubDocument = { ...this.pubDocument, alertId: alert?.id };
            }
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbarDialogService.openSnackbar('Error adding alert', 'DISMISS', { duration: 3000 });
            return of(error);
          })
        )
        .subscribe();
    } else {
      this.authService.login();
    }
  }

  removeAlert(): void {
    this.alertService
      .deleteAlert(this.pubDocument?.alertId)
      .pipe(
        map(() => {
          this.snackbarDialogService.openSnackbar('Alert has been removed', 'DISMISS', { duration: 3000 });
          if (this.pageName === 'searchPage') {
            this.store.dispatch(SearchActions.removeAlert({ alertId: this.pubDocument?.alertId }));
          } else if (this.pageName === 'browsePage') {
            this.store.dispatch(BrowseActions.removeAlert({ alertId: this.pubDocument?.alertId }));
          } else {
            this.pubDocument = { ...this.pubDocument, alertId: null };
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.snackbarDialogService.openSnackbar('Error removing alert', 'DISMISS', { duration: 3000 });
          return of(error);
        })
      )
      .subscribe();
  }
}
