import { SaeHttpResponse } from './http-response.model';
import { Pagination } from './pagination.model';

export const APP_ONQUE = 'digital-standards';

export interface AppEntry {
  name: string;
  key: string;
}
export interface AppsMap {
  [key: string]: string;
}

export interface NotificationsResponse extends SaeHttpResponse {
  results: SaeNotification[];
  error?: any;
  facets: NotificationFacet[];
}

export interface NotificationFacet {
  name: string;
  values: {
    label: string;
    count: number;
  }[];
}

export enum NotificationAction {
  MarkRead = 'markread',
  MarkUnread = 'markunread',
  Delete = 'delete',
  Access = 'access',
  Delete_Unsubscribe = 'delete_unsubscribe',
}

export interface NotificationActionEvent {
  action: NotificationAction;
  notification: SaeNotification;
}

export function createNotification(params: Partial<SaeNotification>) {
  return {} as SaeNotification;
}

export interface Value {
  count: number;
  label: string;
}

export interface MessagesFacet {
  name: string;
  values: Value[];
}

export interface BccRecipient {
  emailAddress: string;
  groupId: string;
  userId: string;
}

export interface Template {
  id: string;
  templateData: unknown;
}

export interface Body {
  html: string;
  template: Template;
  text: string;
}

export interface CcRecipient {
  emailAddress: string;
  groupId: string;
  userId: string;
}

export interface DeliveryMethod {
  emailAddress: string;
  emailSent: boolean;
  messageSent: boolean;
  userId: string;
}

export interface ToRecipient {
  emailAddress: string;
  groupId: string;
  userId: string;
}

export interface SaeNotification {
  applicationCode: string;
  bccRecipients: BccRecipient[];
  body: Body;
  ccRecipients: CcRecipient[];
  createdByUserId: string;
  createdDate: Date;
  deliveryMethods: DeliveryMethod[];
  deliveryStatus: string;
  fromUser: string;
  fromUserDisplay: string;
  id: string;
  isRead: boolean;
  metadata: {
    alertId: string;
    [key: string]: unknown;
  };
  modifiedByUserId: string;
  modifiedDate: Date;
  sentDate: Date;
  splitMessageDelivery: boolean;
  subject: string;
  tags: string[];
  toRecipients: ToRecipient[];
  toSendDate: Date;
  url?: string;
}

export interface MessagesResponse {
  errorMessages: string[];
  facets: MessagesFacet[];
  messages: string[];
  pagination: Pagination;
  results: SaeNotification[];
  statusCode: number;
  statusMessage: string;
}
