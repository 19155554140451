<ng-container *ngIf="{} as _">
  <mat-expansion-panel
    [expanded]="facet?.expanded"
    class="si-expanel si-expanel--form si-facet si-impact mat-elevation-z"
    [class.si-state--active]="panelExpand"
    (opened)="expandThisAggregation(_.isExpandedAggregation)"
    (closed)="collapseThisAggregation(!_.isExpandedAggregation)"
  >
    <mat-expansion-panel-header class="si-expanel__h">
      <mat-panel-title class="si-expanel__title">
        <span>{{ facet.displayName }}</span>
        <mat-icon class="si-icon si-facet__icon" aria-hidden="true" *ngIf="filters?.length > 0">done</mat-icon>
        <span class="si-expanse"></span>
        <mat-icon
          class="si-icon si-facet__icon"
          *ngIf="isPreservedFacet"
          matTooltip="Refresh {{ facet.displayName }}"
          aria-label="Refresh {{ facet.displayName }}"
          aria-hidden="true"
          (click)="$event.stopPropagation(); clearPreservedFacet()"
        >
          refresh
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="si-expanel__c si-expanel__c--form" *ngIf="facet?.data?.length">
      <div class="si-field si-wd-full">
        <div class="mat-form-field-wrapper">
          <div class="si-input si-input--clickstack si-facet__list">
            <mat-checkbox
              *ngFor="let item of facet.data | slice: 0:facet.numValuesShown; let i = index"
              class="si-checkbox"
              [value]="item.value"
              (click)="$event.stopPropagation()"
              (change)="toggleSelection($event, item.value)"
              [checked]="filters | includes: item.value"
              [attr.data-analytics]="'filter-panel-facet-' + item.value"
            >
              {{ item.value }}
              <ng-container *ngIf="item?.count">({{ item.count | number: '1.0':'en-US' }})</ng-container>
            </mat-checkbox>
          </div>
        </div>
        <mat-card-actions class="si-card__a si-card__a--more" *ngIf="facet.numValuesShown < facet?.data?.length">
          <button mat-button color="primary" class="si-button si-button--more" (click)="showMoreValues()">
            View More ({{ facet.data.length - facet.numValuesShown }})
          </button>
        </mat-card-actions>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
