import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonItem, PartButtonComponent } from '../../parts/part-button/part-button.component';
import { DividerItem, PartDividerComponent } from '../../parts/part-divider/part-divider.component';
import { LabelContent, PartLabelComponent } from '../../parts/part-label/part-label.component';
import { PartUtilMenuComponent } from '../../parts/part-util-menu/part-util-menu.component';
import { MenuOption, CompMenuOptionComponent } from '../../compounds/comp-menu-option/comp-menu-option.component';
import { PartTextComponent } from '../../parts/part-text/part-text.component';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { PartUtilListboxComponent } from '../../parts/part-util-listbox/part-util-listbox.component';
import { PartIconComponent } from '../../parts/part-icon/part-icon.component';
import { overrideInputs } from '@sae/base';

export interface MenuContent {
  divider?: DividerItem;
  label?: LabelContent;
  options?: MenuOption[];
}

export interface UIMenuConfig {
  content?: MenuContent[];
  simpleTrigger?: ButtonItem;
  triggerButton?: ButtonItem;
  triggerLabel?: string;
  triggerSuffixIcon?: string;
  triggerTooltip?: string;
}

@Component({
  selector: 'fs-ui-menu',
  styleUrls: ['ui-menu.component.scss'],
  templateUrl: './ui-menu.component.html',
  imports: [
    CommonModule,
    RouterModule,
    PartButtonComponent,
    PartDividerComponent,
    PartIconComponent,
    PartLabelComponent,
    PartTextComponent,
    PartUtilMenuComponent,
    CompMenuOptionComponent,
    MatMenuModule,
    PartUtilListboxComponent,
  ],
  standalone: true,
})
export class UIMenuComponent implements OnChanges {
  constructor(private cdr: ChangeDetectorRef) {}

  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: UIMenuConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() content: MenuContent[];
  @Input() triggerButton: ButtonItem;
  @Input() simpleTrigger: ButtonItem;

  // May be used to display a simple trigger button, consistent with CompSelectMenu's triggerButton
  @Input() triggerLabel: string;
  @Input() triggerSuffixIcon: string;
  @Input() triggerTooltip: string;

  @Output() optionClicked = new EventEmitter<MenuOption>(); // Event emitted when component is in button mode (url is null)

  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }

    if (this.triggerButton) {
      this.triggerButton.fauxButton = true;
      this.triggerButton.tooltip = this.triggerButton.tooltip ?? 'Options';
    }
  }

  onButtonClick(item: MenuOption): void {
    this.optionClicked.emit(item);
  }

  onOpenSubMenu(optionOffset: number): void {
    let subMenuIndex = 0;

    for (let i = 0; i < optionOffset; i++) {
      const options = this.content[i]?.options;

      if (options) {
        for (const option of options) {
          if (option.subMenu) {
            subMenuIndex++;
          }
        }
      }
    }

    this.trigger.get(subMenuIndex)?.openMenu();
  }

  public detectChanges(): void {
    const newContent = [...this.content];
    // eslint-disable-next-line @nx/workspace-no-reassign-inputs
    this.content = [];
    this.cdr.detectChanges();
    // eslint-disable-next-line @nx/workspace-no-reassign-inputs
    this.content = newContent;
  }
}
