import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { SaeHttpResponse } from '@sae/models';
import { Observable, map } from 'rxjs';
import {
  AccessControlResult,
  AggregatedSearchRequestFacets,
  AppSearchCombinedFilters,
  AppSearchSearchRequest,
  DocumentCounts,
  Invitation,
  MobilusSearchResponse,
  Resource,
  UsageEvent,
  UserFolderAccess,
} from '../models/api-models';
import { Asset } from '../models/common-interfaces';
import { CriteriaGroup } from '../models/criterion.model';
import { Term } from '../models/publication.model';
import {
  APPLICATION_NAME,
  Query,
  SearchContext,
  fromFacet,
  fromQuery,
  fromSearchTerm,
} from '../models/search-request.model';
import { Aggregation, FACETS_ORDER, SearchResult } from '../models/search-result.model';
import { MobilusApiService, SearchHttpOptions } from './mobilus-api.service';

@Injectable({
  providedIn: 'root',
})
export class MobilusUiService {
  constructor(private mobilusApiService: MobilusApiService) {}

  public searchUsingAppliedFacets(
    appliedFacets: Query[],
    rootType: string,
    subGroup: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDir: SortDirection,
    searchContext: SearchContext,
    resultSetId: string,
    searchTerm?: string,
    published?: [Date, Date],
    topic?: Term,
    journalTitleName?: string
  ): Observable<SearchResult> {
    const cg = this.constructCriteriaGroups(
      appliedFacets,
      rootType,
      subGroup,
      searchTerm,
      published,
      topic,
      journalTitleName
    );
    const offset = pageSize * (pageNumber - 1);
    const limit = pageSize;
    const order = sortDir;
    const sort = sortBy === 'Title' ? 'title' : sortBy === 'Relevance' ? 'relevance' : 'pubDate';
    return this.mobilusApiService.search({
      criteria: cg,
      application: {
        resultSetId,
        name: APPLICATION_NAME,
        context: searchContext,
      },
      limit,
      offset,
      order,
      sortBy: sort,
    });
  }

  public search2(searchReq: AppSearchSearchRequest, options?: SearchHttpOptions): Observable<MobilusSearchResponse> {
    return this.mobilusApiService.searchV2(searchReq, options);
  }

  public advancedSearch(
    searchReq: AppSearchSearchRequest,
    options?: SearchHttpOptions
  ): Observable<MobilusSearchResponse> {
    return this.mobilusApiService.postAdvancedSearch(searchReq, options);
  }

  public prioritizeAggregations(aggregations: Aggregation[]): Aggregation[] {
    return aggregations
      .sort((a, b) => FACETS_ORDER[a.displayName] - FACETS_ORDER[b.displayName])
      .filter((agg) => Object.keys(FACETS_ORDER).includes(agg.displayName));
  }

  private constructCriteriaGroups(
    facets: Query[],
    group: string,
    subGroup: string,
    searchTerm?: string,
    published?: [Date, Date],
    topic?: Term,
    journalTitleName?: string
  ): Array<CriteriaGroup> {
    const criteriaGroups = new Array<CriteriaGroup>();

    if (searchTerm) {
      const cg = fromSearchTerm(searchTerm, 'AND');
      criteriaGroups.push(cg);
    }

    if (group) {
      const cg = fromFacet(group, 'contentGroup', 'AND');
      criteriaGroups.push(cg);
    }

    if (subGroup) {
      const cg = fromFacet(subGroup, 'contentSubGroup', 'AND');
      criteriaGroups.push(cg);
    }

    if (topic) {
      const cg = fromFacet(topic.text, 'assignedTaxonomyTermText', 'AND');
      criteriaGroups.push(cg);
    }
    if (journalTitleName) {
      const cg = fromFacet(journalTitleName, 'journalTitle', 'AND');
      criteriaGroups.push(cg);
    }

    if (!!published?.[0] && !!published?.[1]) {
      const cg: CriteriaGroup = {
        groupType: 'FACET',
        operator: 'AND',
        query: [
          {
            searchType: 'RANGE',
            rangeOperator: 'WITHIN',
            field: {
              name: 'publicationDate',
            },
            values: [published[0].toISOString(), published[1].toISOString()],
          },
        ],
      };
      criteriaGroups.push(cg);
    }

    facets?.forEach((facet) => {
      const fcg = fromQuery(facet, 'AND');
      criteriaGroups.push(fcg);
    });
    return criteriaGroups;
  }

  public getFolders(): Observable<Resource[]> {
    return this.mobilusApiService.getMobilusFolders().pipe(map((response) => response.results));
  }

  public getMobilusFolderItems(
    id: string,
    order: string,
    sort: string,
    limit: number,
    offset: number
  ): Observable<{ folders: Resource[]; total: number }> {
    return this.mobilusApiService.getMobilusFolderItems(id, order, sort, limit, offset).pipe(
      map((response) => {
        return { folders: response?.results, total: response?.pagination?.total };
      })
    );
  }

  public addItemToFolder(contentId: number, folderId: string): Observable<Resource> {
    return this.mobilusApiService
      .postMobilusFolderItems(contentId, folderId)
      .pipe(map((response) => response?.results?.[0]));
  }

  public removeItemFromFolder(contentId: number, folderId: string): Observable<Resource> {
    return this.mobilusApiService
      .deleteMobilusFolderItems(contentId, folderId)
      .pipe(map((response) => response?.results?.[0]));
  }

  public removeItemFromAllFolders(contentId: number, folderIds: string[]): Observable<object> {
    return this.mobilusApiService.deleteMobilusFolders({ cid: contentId, folderIds });
  }

  public createFolder(name: string, parentFolderId?: string): Observable<Resource> {
    return this.mobilusApiService
      .postMobilusFolders({ name, parentFolderId })
      .pipe(map((response) => response.results?.[0]));
  }
  public deleteFolder(folderId: string): Observable<object> {
    return this.mobilusApiService.deleteMobilusFolder(folderId);
  }
  public renameFolder(folder: Resource): Observable<Resource> {
    return this.mobilusApiService.renameMobilusFolder(folder).pipe(map((response) => response?.results?.[0]));
  }

  public getContentDataSets(
    contentId: number,
    dataSetQuery: {
      datasetIndex?: number;
      ingestionId?: string;
      searchType?: string;
      fileType?: string;
      filename?: string;
      offset?: number;
      limit?: number;
      sort?: string;
      order?: string;
    }
  ): Observable<SaeHttpResponse<Asset>> {
    const dataSetParams = { ...dataSetQuery, searchType: 'Dataset' };
    delete dataSetParams['type'];
    delete dataSetParams['contentId'];
    const params = new HttpParams({ fromObject: dataSetParams });

    return this.mobilusApiService.getAssets(contentId, params);
  }

  public usageTrackingEvent(event: UsageEvent): Observable<SaeHttpResponse<UsageEvent>> {
    return this.mobilusApiService.postUsageTracking(event);
  }

  public getAccessProperties(contentIds: number[]): Observable<AccessControlResult> {
    const params = new HttpParams().set('contentIds', contentIds.toString());
    return this.mobilusApiService.getAccessProperties(params).pipe(map((resp) => resp.results[0]));
  }

  public getHomepageCounts(): Observable<DocumentCounts> {
    return this.mobilusApiService.getHomepageCounts();
  }

  public getRecentEDGEResearchReports(): Observable<MobilusSearchResponse> {
    const headers = new HttpHeaders({ 'search-context': 'component' });
    const options = { headers };
    return this.mobilusApiService.searchV2(
      {
        query: '',
        page: { size: 10, current: 1 },
        filters: { all: [{ sub_group: ['Research Report'] }] },
        sort: [{ pub_date: 'desc' }],
        facets: {
          total: [
            {
              type: 'value',
              name: 'total',
              size: 200,
            },
          ],
        },
      },
      options
    );
  }

  public getRecentBooks(): Observable<MobilusSearchResponse> {
    const headers = new HttpHeaders({ 'search-context': 'component' });
    const options = { headers };
    return this.mobilusApiService.searchV2(
      {
        query: '',
        page: { size: 10, current: 1 },
        filters: { all: [{ group: ['Books'] }] },
        sort: [{ pub_date: 'desc' }],
        facets: {
          total: [
            {
              type: 'value',
              name: 'total',
              size: 200,
            },
          ],
        },
      },
      options
    );
  }
  public getRecentPublishedItems(
    offset: number,
    sortBy: string,
    sortDir: SortDirection,
    from: string,
    to: string
  ): Observable<MobilusSearchResponse> {
    const sort = sortBy ? [{ [sortBy]: sortDir }] : [];
    const headers = new HttpHeaders({ 'search-context': 'component' });
    const options = { headers };

    return this.mobilusApiService.searchV2(
      {
        query: '',
        page: { size: 25 * offset, current: 1 },
        sort,
        filters: { all: [{ pub_date: { from, to } as any }] },
        facets: {
          total: [
            {
              type: 'value',
              name: 'total',
              size: 200,
            },
          ],
        },
      },
      options
    );
  }

  public getEFirstArticles(
    params: {
      magCode: number;
      query?: string;
      sort?: string;
      order?: string;
      size?: number;
      current?: number;
    },
    options?: SearchHttpOptions
  ): Observable<MobilusSearchResponse> {
    return this.mobilusApiService.getEFirstArticles(params, options);
  }

  public getTopThreeDocuments(term: string): Observable<MobilusSearchResponse> {
    const req: AppSearchSearchRequest = {
      query: term,
      search_fields: {
        product_code: {},
      },
      result_fields: {
        mobilus_platform_uri: {
          raw: {},
        },
        product_code: {
          raw: {},
        },
        title: {
          raw: {},
        },
        sub_group: {
          raw: {},
        },
        cid: {
          raw: {},
        },
        collection_ids: {
          raw: {},
        },
        codes: {
          raw: {},
        },
        group: {
          raw: {},
        },
        revision_status: {
          raw: {},
        },
        features: {
          raw: {},
        },
      },
      page: {
        size: 3,
      },
    };
    return this.mobilusApiService.searchV2(req, { headers: new HttpHeaders().set('Search-Context', 'component') });
  }

  public getBrowseJournalItems(
    size: number,
    current: number,
    sortBy: string,
    sortDir: SortDirection,
    from: string,
    to: string,
    query: string,
    searchContext = 'main_search'
  ): Observable<MobilusSearchResponse> {
    const sort = sortBy === 'Most Recent' ? 'pub_date' : sortBy === 'Title' ? 'title' : undefined;
    const headers = new HttpHeaders({ 'search-context': searchContext });
    const options = { headers };

    const filters: AppSearchCombinedFilters = {
      all: [{ sub_group: ['Journal'] }, { publisher_name: ['SAE International'] }],
    };
    if (from && to) {
      filters.all.push({ pub_date: { from, to } as any });
    }
    return this.mobilusApiService.searchV2(
      {
        query,
        page: { size, current },
        sort: sort
          ? [
              {
                [sort]: sortDir,
              },
            ]
          : undefined,
        filters,
        facets: AggregatedSearchRequestFacets,
      },
      options
    );
  }

  public getUsersForFolder(req: {
    anchorId: string;
    sort?: string;
    order?: string;
    limit?: number;
    offset?: number;
  }): Observable<SaeHttpResponse<UserFolderAccess>> {
    return this.mobilusApiService.getUsersForFolder(req);
  }
  public sendInvitationToAddUserToFolder(
    anchorId: string,
    recipientEmail: string,
    folderRole: string
  ): Observable<SaeHttpResponse<Invitation>> {
    return this.mobilusApiService.sendInvitationToAddUserToFolder(anchorId, recipientEmail, folderRole);
  }

  public getSubscriptions(params: {
    offset?: number;
    limit?: number;
    order?: SortDirection;
    sort?: 'subscriptionTitle' | 'subscriptionEndDate';
    status?: 'ACTIVE' | 'INACTIVE';
  }) {
    return this.mobilusApiService.getSubscriptions(params);
  }

  public modifyUserFolderPermissions(
    folderId: string,
    userId: string,
    role: 'ALL' | 'READ_ONLY'
  ): Observable<UserFolderAccess> {
    return this.mobilusApiService
      .modifyUserFolderPermissions(folderId, userId, role)
      .pipe(map((resp) => resp.results[0]));
  }

  public removeUserFromFolder(folderId: string, userId: string) {
    return this.mobilusApiService.deleteUserFromFolder(folderId, userId);
  }
}
