<ng-container *ngIf="appLink || hrefLink; else noLinkage">
  <ng-container *ngIf="appLink; else hrefLinkage">
    <a
      [routerLink]="appLink"
      [fragment]="fragment"
      class="si-a--unusual si-component si-button si-button--inline"
      mat-button
    >
      <ng-container *ngTemplateOutlet="basicCardContents"></ng-container>
    </a>
  </ng-container>
  <ng-template #hrefLinkage>
    <a
      [href]="hrefLink"
      rel="noopener"
      target="_blank"
      class="si-a--unusual si-component si-button si-button--inline"
      mat-button
    >
      <ng-container *ngTemplateOutlet="basicCardContents"></ng-container>
    </a>
  </ng-template>
</ng-container>

<ng-template #noLinkage>
  <ng-container *ngTemplateOutlet="basicCardContents"></ng-container>
</ng-template>

<ng-template #basicCardContents>
  <mat-card
    class="si-card si-card--basic"
    [class.si-state--disabled]="locked"
    [class.si-card--tight]="tight"
    [class.si-state--selected]="selected"
    [class.si-simple]="simple"
    [class.mat-elevation-z2]="shadow"
    [class.mat-elevation-z]="!shadow"
  >
    <mat-card-header class="si-card__h">
      <div mat-card-avatar class="si-avatar" *ngIf="materialIcon || customIcon" aria-hidden="true">
        <mat-icon
          class="si-icon"
          color="primary"
          *ngIf="materialIcon"
          [class.si-state--negative]="negative"
          [class.si-state--positive]="positive"
          [class.si-state--activated]="activated"
          [class.si-state--highlight]="highlight"
          [class.si-state--inaccessible]="inaccessible"
          [class.si-state--locked]="locked"
        >
          {{ materialIcon }}
        </mat-icon>
      </div>
      <span mat-card-avatar class="si-file-icon" *ngIf="file">
        <span class="si-icon__wrapper si-icon--file si-icon--file--{{ fileType | lowercase }}">
          <mat-icon aria-hidden="true">insert_drive_file</mat-icon>
          <div class="si-dec">{{ fileType }}</div>
        </span>
      </span>
      <mat-card-subtitle
        class="si-breadcrumb si-ellipsed"
        *ngIf="breadcrumbs"
        [innerHTML]="breadcrumbs"
      ></mat-card-subtitle>
      <mat-card-title
        [class.si-ellipsed]="titleEllipsed"
        class="si-card__title"
        [class.si-prevent--overflow]="preventOverflow"
        *ngIf="title"
      >
        <ng-container *ngIf="!strongTitle">
          {{ title }}
        </ng-container>
        <ng-container *ngIf="strongTitle">
          <strong>{{ title }}</strong>
        </ng-container>
        <ng-container *ngIf="titleSecondary">
          <span class="si-additional"></span>
          <span class="si-card__title-secondary">{{ titleSecondary }}</span>
        </ng-container>
        <span class="si-for--fileext" *ngIf="ext">{{ ext }}</span>
      </mat-card-title>
      <mat-card-subtitle
        class="si-card__subtitle"
        *ngIf="subTitle"
        [class.si-ellipsed]="subTitleEllipsed"
        [class.si-prevent--overflow]="preventOverflow"
      >
        {{ subTitle }}
      </mat-card-subtitle>
      <mat-card-subtitle
        class="si-card__subtitle"
        *ngIf="subTitleFooter"
        [class.si-ellipsed]="subTitleEllipsed"
        [class.si-prevent--overflow]="preventOverflow"
      >
        {{ subTitleFooter }}
      </mat-card-subtitle>
      <mat-card-subtitle
        class="si-card__subtitle"
        *ngIf="subTitlePrimary"
        [class.si-prevent--overflow]="preventOverflow"
      >
        <span class="si-for--primary">{{ subTitlePrimary }}</span>
      </mat-card-subtitle>
      <mat-card-subtitle
        class="si-card__subtitle"
        *ngIf="subTitleStatus"
        [class.si-prevent--overflow]="preventOverflow"
      >
        <em class="si-for--status">{{ subTitleStatus }}</em>
      </mat-card-subtitle>
      <mat-card-subtitle class="si-card__subtitle" *ngIf="actionAppHref && action">
        <a href="{{ actionAppHref }}">{{ action }}</a>
      </mat-card-subtitle>
      <mat-card-subtitle class="si-card__subtitle" *ngIf="actionAppLink && action">
        <a routerLink="{{ actionAppLink }}">{{ action }}</a>
      </mat-card-subtitle>
    </mat-card-header>
    <span class="si-expanse"></span>
    <button
      mat-icon-button
      class="si-icon si-icon--status"
      *ngIf="otherIcon"
      [attr.aria-label]="otherIconDesc"
      [matTooltip]="otherIconDesc"
    >
      <mat-icon>{{ otherIcon }}</mat-icon>
    </button>
    <si-more-menu *ngIf="options.length" [options]="options" (optionSelected)="optionSelected($event)"></si-more-menu>
  </mat-card>
</ng-template>
