import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../../../../shared/state/content.service';
import { Revision } from '../../../models/content.model';
import { DetailsHelperService } from '../../../services/details-helper.service';
import { filter, first } from 'rxjs';

@Component({
  selector: 'mobi-standard-panel',
  templateUrl: './standard-panel.component.html',
})
export class StandardPanelComponent implements OnInit {
  @ViewChild('template', { static: true }) template;
  public content$ = this.contentService.content$;

  constructor(
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private contentService: ContentService,
    private router: Router,
    private detailsHelperService: DetailsHelperService
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.content$
      .pipe(
        filter((v) => !!v),
        first()
      )
      .subscribe((content) => {
        content.standardAttributes?.revisions?.forEach((revision) => {
          if (!revision.seoUri) {
            console.warn(`Missing seoUri for revision: ${revision.code}. Contact CSP/Mobilus API developers.`);
          }
        });
      });
  }

  redline(e: MouseEvent, revision: Revision): void {
    e.stopPropagation();
    this.router.navigateByUrl(this.router.url.split('#')[0] + '#view&mode=redline&compare=' + revision.cid);
  }
}
