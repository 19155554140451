import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../../store/auth';

@Component({
  selector: 'mobi-logout-dialog',
  templateUrl: './logout-dialog.component.html',
})
export class LogoutDialogComponent {
  constructor(private store: Store, private router: Router) {}

  logOut(): void {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    let redirectUri = parsedUrl.href;
    if (
      this.router.url.includes('/mylibrary') ||
      this.router.url.includes('/user-profile') ||
      this.router.url.includes('/battery-cell')
    ) {
      redirectUri = baseUrl;
    }
    this.store.dispatch(AuthActions.logOut({ redirectUri }));
  }
}
