<ng-container *ngIf="!onlyRenderOptions">
  <button
    mat-icon-button
    [matMenuTriggerFor]="optMore"
    class="si-icon si-icon--more"
    [class.si-icon--audit]="isAudit"
    [class.si-icon--header]="isHeader"
    color="primary"
    matTooltip="Options"
    aria-label="Options"
  >
    <mat-icon>{{ menuIcon }}</mat-icon>
  </button>
  <mat-menu #optMore="matMenu" class="si-menu">
    <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
  </mat-menu>
</ng-container>

<ng-container *ngIf="onlyRenderOptions">
  <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
</ng-container>

<ng-template #optionsTemplate>
  <mat-label *ngIf="label" class="si-label">{{ label }}</mat-label>
  <ng-container *ngFor="let option of _options">
    <ng-container *ngIf="!option.projectedContent; else projectedContentTemplate">
      <mat-label class="si-label" *ngIf="option.label">{{ option.label }}</mat-label>
      <ng-container *ngIf="!option.subtitle">
        <ng-container *ngIf="option.name">
          <a
            *ngIf="option.url && !option.external"
            (click)="selectedOption(option, $event)"
            [disabled]="option.disabled ?? false"
            [routerLink]="option.url"
            class="si-button--detailed"
            mat-menu-item
          >
            <ng-container *ngTemplateOutlet="linkedOption"></ng-container>
          </a>
          <a
            *ngIf="option.url && option.external"
            (click)="selectedOption(option, $event)"
            [disabled]="option.disabled ?? false"
            [href]="option.url"
            rel="noopener"
            target="_blank"
            class="si-button--detailed"
            mat-menu-item
          >
            <ng-container *ngTemplateOutlet="linkedOption"></ng-container>
          </a>

          <ng-template #linkedOption>
            <mat-icon [color]="option.color ?? 'primary'" [class]="option.iconClass" aria-hidden="true">
              {{ option.icon }}
            </mat-icon>
            <span>{{ option.name }}</span>
          </ng-template>

          <button
            mat-menu-item
            class="si-button--detailed"
            (click)="selectedOption(option, $event)"
            [disabled]="option.disabled ?? false"
            *ngIf="!option.url"
          >
            <mat-icon [color]="option.color ?? 'primary'" [class]="option.iconClass" aria-hidden="true">
              {{ option.icon }}
            </mat-icon>
            <span>{{ option.name }}</span>
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="option.subtitle">
        <ng-container *ngIf="!option.disabled">
          <a class="si-a--unusual si-component si-button si-button--inline" mat-button>
            <si-basic-card
              (click)="selectedOption(option, $event)"
              class="si-component si-card--basic__menu"
              [materialIcon]="option.icon"
              [title]="option.name"
              [subTitle]="option.subtitle"
              [shadow]="false"
            ></si-basic-card>
          </a>
        </ng-container>
        <ng-container *ngIf="option.disabled">
          <si-basic-card
            (click)="$event.stopPropagation(); $event.preventDefault()"
            class="si-component si-card--basic__menu si-state--disabled"
            [materialIcon]="option.icon"
            [title]="option.name"
            [subTitle]="option.subtitle"
            [shadow]="false"
          ></si-basic-card>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #projectedContentTemplate>
      <ng-content></ng-content>
    </ng-template>
    <mat-divider class="si-divider" *ngIf="option.divider"></mat-divider>
  </ng-container>
</ng-template>
