import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, catchError, map, tap, throwError } from 'rxjs';
import { ContentService } from '../../../shared/state/content.service';
import { ContentApiResponse, ContentView } from '../models/content.model';
import { DetailsHelperService } from './details-helper.service';
import { LoggerService } from '@sae/services';

@Injectable({
  providedIn: 'root',
})
export class DetailResolver {
  constructor(
    private contentService: ContentService,
    private detailsHelperService: DetailsHelperService,
    private logger: LoggerService,
    private router: Router
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContentView> {
    if (!isNaN(route.params['id'])) {
      return this.contentService.getById(route.params['id']).pipe(
        map((car: ContentApiResponse) => this.detailsHelperService.buildContentView(car)),
        catchError((err) => {
          if (err?.status === 404) {
            this.logger.error(
              `The API could not find a document with this CID: ${route.params['id']}. This is a data issue; even though this CID was returned from the /search endpoint, the API could not find data associated with it from the /content endpoint.`
            );
          } else {
            this.logger.error(`An unhandled error occurred, please check the network tab for more information.`);
            this.logger.error(JSON.stringify(err, undefined, 2));
          }
          this.router.navigateByUrl('/404');
          return EMPTY;
        })
      );
    } else {
      return this.contentService.getBySeoURI(state['url'].split('#')[0].split('?')[0]).pipe(
        map((car: ContentApiResponse) => this.detailsHelperService.buildContentView(car)),
        catchError((err) => {
          const currentNav = this.router.getCurrentNavigation();
          if (currentNav) {
            this.logger.error('Previous route:');
            this.logger.error(currentNav.previousNavigation?.finalUrl?.toString());
          }
          if (err?.status === 404) {
            this.logger.error(`The API could not find a document with this SEO URI:`);
            this.logger.error(state['url'].split('#')[0]);
            this.logger.error(
              `This is a data issue; even though this CID was returned from the /search endpoint, the API could not find data associated with it from the /content endpoint.`
            );
          } else {
            this.logger.error(`An unhandled error occurred, please check the network tab for more information.`);
            this.logger.error(JSON.stringify(err, undefined, 2));
          }
          this.router.navigateByUrl('/404');
          return EMPTY;
        })
      );
    }
  }
}
