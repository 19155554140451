<div class="si-component" #templateRef>
  <si-resource-manager-ui
    [error]="error && elemTouched && !elemFocused && !suppressErrors"
    [required]="required"
    [errorMessage]="errorMessage"
    [emptyMessage]="emptyMessage"
    [expanded]="expanded"
    [breadcrumbs]="breadcrumbs"
    [mainMenu]="mainMenu"
    [addMenu]="addMenu"
    [sortBy]="sortBy"
    [sortDirection]="sortDirection"
    [anchorLoading]="anchorLoading"
    [appliedDisplaySettings]="appliedDisplaySettings"
    [enableSharing]="enableSharing"
    [enableSort]="enableSort"
    [shareCount]="shareCount"
    [resourcesLoading]="resourcesLoading"
    [currentAnchor]="currentAnchor"
    [childResources]="childResources"
    [readonly]="readonly"
    [disableDownload]="disableDownload"
    [enableCopyLink]="enableCopyLink"
    [enableCopyName]="enableCopyName"
    [morePagination]="morePagination"
    (addFile)="addFile()"
    (addFolder)="addFolder()"
    (addLink)="addLink()"
    (delete)="delete($event)"
    (download)="download($event)"
    (copyLink)="copyLink($event)"
    (copyName)="copyName($event)"
    (editLink)="editLink($event)"
    (menuItemClick)="menuItemClick($event)"
    (navigate)="navigate($event)"
    (refresh)="refresh()"
    (rename)="rename($event)"
    (resourceClick)="resourceClick($event)"
    (shareDialogLaunch)="launchShareDialog()"
    (sortChange)="sortChange($event)"
    (menuIsOpen)="onMenu($event)"
  ></si-resource-manager-ui>
</div>
