import { AfterViewInit, Directive, ElementRef } from '@angular/core';

// Can be used to set autofocus on a field.

@Directive({
  selector: '[libSetAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 10);
  }

}
