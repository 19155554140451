import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BrowseActions, BrowseSelectors } from '../../../../store/browse';
import { BrowseState } from '../../../../store/browse/browse.state';
import { map } from 'rxjs';
import { UISearchFacet } from '../../../../shared/models/common-interfaces';
import { AppSearchResponseFacet, ValueFacetData } from '../../../../shared/models/api-models';
import { SubscriptionsService } from '../../../../shared/state/subscriptions.service';

@Component({
  selector: 'mobi-browse-by-master-panel-content',
  templateUrl: './browse-by-master-panel-content.component.html',
})
export class BrowseByMasterPanelContentComponent implements OnInit {
  @ViewChild('template', { static: true }) template;

  public config$ = this.store.select(BrowseSelectors.selectConfig);
  public loading$ = this.store.select(BrowseSelectors.selectResultsLoading);

  // Panel Properties
  public facets$ = this.store.select(BrowseSelectors.selectFacets).pipe(map((facets) => Object.values(facets)));
  public filters$ = this.store.select(BrowseSelectors.selectFilters);
  public customFilters$ = this.store.select(BrowseSelectors.selectCustomFilters);
  public preservedFacet$ = this.store.select(BrowseSelectors.selectPreservedFacet);
  public panelInitialized$ = this.store.select(BrowseSelectors.selectPanelInitialized);
  public isPubDateFilterApplied$ = this.store.select(BrowseSelectors.selectIsPubDateApplied);
  public pubDateTo$ = this.store.select(BrowseSelectors.selectPubDateTo);
  public pubDateFrom$ = this.store.select(BrowseSelectors.selectPubDateFrom);
  public onlyFacet: UISearchFacet<ValueFacetData>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private store: Store<BrowseState>,
    private subscriptionsService: SubscriptionsService
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);

    this.onlyFacet = {
      displayName: 'Only',
      numValuesShown: 10,
      expanded: false,
      type: 'value',
      name: 'only',
      data: [
        { value: 'New Content', count: 0 },
        { value: 'Full Text Content', count: 0 },
      ] as any,
    };
    const subscriptions = this.subscriptionsService.getSubscriptions();
    if (subscriptions?.length > 0) {
      this.onlyFacet.data = [
        { value: 'New Content', count: 0 },
        { value: 'My Subscription', count: 0 },
        { value: 'Full Text Content', count: 0 },
      ] as any;
    }
  }

  showMoreValues(facet: UISearchFacet<unknown>): void {
    this.store.dispatch(BrowseActions.showMoreValues({ fieldName: facet.name }));
  }

  applyFacet([facet, value]: [UISearchFacet<unknown>, string]): void {
    this.store.dispatch(BrowseActions.applyFilter({ fieldName: facet.name, value }));
  }

  removeFacet([facet, value]: [UISearchFacet<unknown>, string]): void {
    this.store.dispatch(BrowseActions.removeFilter({ fieldName: facet.name, value }));
  }

  expandAll() {
    this.store.dispatch(BrowseActions.expandAllFacets());
  }

  collapseAll() {
    this.store.dispatch(BrowseActions.collapseAllFacets());
  }

  clearPreservedFacet() {
    this.store.dispatch(BrowseActions.clearPreservedFacet());
  }

  clearFilters() {
    this.store.dispatch(BrowseActions.setFilters({ filters: {}, customFilters: {} }));
  }

  applyPubDateFilter([facet, value]: [AppSearchResponseFacet, [Date, Date]]) {
    this.store.dispatch(BrowseActions.applyPubDateFilter({ from: value[0], to: value[1] }));
  }

  removePubDateFilter() {
    this.store.dispatch(BrowseActions.removePubDateFilter());
  }

  applyCustomFilter([facet, value]: [UISearchFacet<unknown>, string]): void {
    this.store.dispatch(BrowseActions.applyCustomFilter({ fieldName: facet.name, value }));
  }

  removeCustomFilter([facet, value]: [UISearchFacet<unknown>, string]): void {
    this.store.dispatch(BrowseActions.removeCustomFilter({ fieldName: facet.name, value }));
  }
}
