<span
  class="si-compound {{ classes }} {{ stackMaxResolution ? 'si-stackmax--' + stackMaxResolution : '' }}"
  [class.si-full]="full"
  [class.si-flipped]="flipped"
  [class.si-scheme--basic]="!spaced"
  [class.si-scheme--spaced]="spaced"
  [class.si-scheme--tightvertical]="suppressVerticalGap"
  [class.si-tight]="tight"
  [class.si-unusual--vertical]="unusualVertical"
>
  <ng-content></ng-content>
</span>
