import {
  inject,
  Inject,
  Injectable,
  InjectionToken,
  ProviderToken,
} from '@angular/core';
import { ActiveState, applyTransaction } from '@datorama/akita';
import {
  NgEntityServiceGlobalConfig,
  NG_ENTITY_SERVICE_CONFIG,
} from '@datorama/akita-ng-entity-service';
import { Pagination, SaeSurveyVersion, SurveyVersionHttpResponse, SurveyVersionStatusFilter } from '@sae/models';
import { Observable } from 'rxjs';
import {
  ApiRegistry,
  ApiToken,
  StoresRegistry,
  StoresToken,
} from './../api-registry';
import {
  BaseEntityQuery,
  BaseEntityService,
  BaseEntityStore,
  BaseState,
} from './base.service';
import { SortDirection } from '@angular/material/sort';

export interface ISurveyVersionsService {
  all$: Observable<Array<SaeSurveyVersion>>;
  activeSurveyVersion$: Observable<SaeSurveyVersion | undefined>;
  loading$: Observable<boolean>;
  setLoading(loading: boolean): void;
  setActive(id: string): void;
  initialize(): void;
  hasResponses(id: string): boolean;
  getActive(): SaeSurveyVersion | undefined;
  updateActive(updatedSurveyVersion: SaeSurveyVersion): void;
  fetchById(surveyVersionId: string): void;
}

export interface SurveyVersionsCoreState
  extends BaseState<SaeSurveyVersion>,
  ActiveState {
  pagination: Pagination;
  sortColumn: string;
  sortDir: SortDirection;
  searchTerm: string;
  statusFilter: SurveyVersionStatusFilter;
  selectedElementName: string;
}

function createInitialState(): SurveyVersionsCoreState {
  return {
    loading: false,
    active: null,
    pagination: null,
    sortColumn: 'version',
    sortField: 'version',
    sortDir: 'desc',
    searchTerm: '',
    filter: null,
    customParams: null,
    statusFilter: SurveyVersionStatusFilter.all,
    selectedElementName: '',
    isInitialized: false,
    mostRecentResponse: null
  };
}


@Injectable({ providedIn: 'root' })
export class SurveyVersionsCoreStore extends BaseEntityStore<SurveyVersionsCoreState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super({ name: storesRegistry.surveyVersions, idKey: '_id' }, createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class SurveyVersionsCoreQuery extends BaseEntityQuery<SurveyVersionsCoreState> {
  constructor(protected store: SurveyVersionsCoreStore) {
    super(store);
  }
}

export class SurveyVersionsCoreService
  extends BaseEntityService<SurveyVersionsCoreState>
  implements ISurveyVersionsService {
  all$ = this.query.selectAll();
  activeSurveyVersion$ = this.query.selectActive();
  loading$ = this.query.selectLoading();

  constructor(
    public readonly store: SurveyVersionsCoreStore,
    public readonly query: SurveyVersionsCoreQuery,
    @Inject(NG_ENTITY_SERVICE_CONFIG)
    ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) apiReg: ApiRegistry
  ) {
    super(store, query, {
      baseUrl: ngEntityServiceGlobalConfig.baseUrl,
      resourceName: apiReg?.surveyVersions?.url,
    });
  }

  protected preFetchEntities(): void {
    this.sortFieldParamName = 'sort';
    this.sortField = 'createdDate';
    this.sortDirParamName = 'order';
  }

  setLoading(loading: boolean): void {
    this.store.update({ loading });
  }

  setActive(id: string): void {
    this.store.setActive(id);
  }

  hasResponses(id: string): boolean {
    return false;
  }

  getActive(): SaeSurveyVersion | undefined {
    return this.query.getActive();
  }

  updateActive(updatedSurveyVersion: SaeSurveyVersion): void {
    // this.store.updateActive(updatedSurveyVersion);
    this.setLoading(true);
    applyTransaction(() => {
      this.update(updatedSurveyVersion._id, updatedSurveyVersion, {
        mapResponseFn: (res: SurveyVersionHttpResponse) => {
          this.setLoading(false);
          return res.results[0];
        },
      }).subscribe();
    });
  }

  fetchById(surveyVersionId: string): void {
    this.fetchEntity(
      surveyVersionId,
      undefined,
      (res: SurveyVersionHttpResponse) => {
        this.setLoading(false);
        return res.results[0];
      }
    );
  }
}

export const SURVEY_VERSIONS_TOKEN = new InjectionToken<ISurveyVersionsService>(
  'SurveyVersions Service',
  {
    providedIn: 'root',
    factory: () =>
      new SurveyVersionsCoreService(
        inject(SurveyVersionsCoreStore),
        inject(SurveyVersionsCoreQuery),
        inject(NG_ENTITY_SERVICE_CONFIG),
        inject(ApiToken as ProviderToken<ApiRegistry>)
      ),
  }
);
