import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  NgEntityServiceConfig,
  NgEntityServiceGlobalConfig,
  NG_ENTITY_SERVICE_CONFIG,
} from '@datorama/akita-ng-entity-service';
import { SaeHttpResponse } from '@sae/models';
import { ApiRegistry, ApiToken, CommentsCoreQuery, CommentsCoreService, CommentsCoreStore } from '@sae/services';
import { Observable } from 'rxjs';
import { Api } from '../../../api';

export const feedbackAnchor = 'some-uuid';

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({ resourceName: Api.comments.url })
export class CommentsService extends CommentsCoreService {
  constructor(
    store: CommentsCoreStore,
    query: CommentsCoreQuery,
    @Inject(NG_ENTITY_SERVICE_CONFIG) ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) apiReg: ApiRegistry
  ) {
    super(store, query, ngEntityServiceGlobalConfig, apiReg);
  }

  public deleteUsersCommentsOnAnchor(userId: string, anchorId: string): Observable<SaeHttpResponse> {
    const params = new HttpParams().set('anchor', anchorId).set('userId', userId);
    return super.getHttp().delete<SaeHttpResponse>(`${this.baseUrl}/${Api.comments.url}`, { params });
  }
}
