import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, NgZone, importProvidersFrom } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { APM, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import {
  AUTH_TOKEN,
  ApiToken,
  ApiVersionInterceptor,
  COMMENTS_TOKEN,
  EnvironmentConfigService,
  FEATURE_FLAG_TOKEN,
  REMOTE_CONFIG_TOKEN,
  SESSION_TOKEN,
  StoresToken,
} from '@sae/services';
import { KeycloakAngularModule } from 'keycloak-angular';
import { CookieModule, CookieService } from 'ngx-cookie';
import { Api } from '../api';
import { Stores } from '../stores';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material/material.module';
import { StartSessionModule } from './modules/start-session/start-session.module';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { HttpErrorInterceptor } from './shared/http-error.interceptor';
import { MockHttpInterceptor } from './shared/mock.interceptor';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './shared/state/auth.service';
import { CommentsService } from './shared/state/comments.service';
import { SessionService } from './shared/state/session.service';
import { SubscriptionsService } from './shared/state/subscriptions.service';
import { SubTokenInterceptor } from './shared/sub-token.interceptor';
import { RootStoreModule } from './store';
import { saeAppConfig } from './sae-app.config';
import { APP_CONFIG_TOKEN } from '@sae/base';

export function initAppFactory(ais: AppInitService) {
  return (): Promise<unknown> => ais.initialize();
}

export const AppConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      SharedModule,
      HttpClientModule,
      KeycloakAngularModule,
      AkitaNgRouterStoreModule,
      AkitaNgDevtools.forRoot(),
      ApmModule,
      KeycloakAngularModule,
      RouterModule,
      MaterialModule,
      CookieModule.withOptions(),
      RootStoreModule,
      MatSnackBarModule,
      StartSessionModule,
    ]),
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFactory,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [CookieService, SubscriptionsService, HttpClient, AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: ApmService,
      useClass: ApmService,
      deps: [APM, Router, NgZone],
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: {
        baseUrl: 'foo',
      },
    },
    { provide: ApiToken, useValue: Api },
    { provide: StoresToken, useValue: Stores },
    { provide: REMOTE_CONFIG_TOKEN, useClass: EnvironmentConfigService },
    { provide: ApiToken, useValue: Api },
    { provide: AUTH_TOKEN, useExisting: AuthService },
    { provide: SESSION_TOKEN, useExisting: SessionService },
    { provide: COMMENTS_TOKEN, useExisting: CommentsService },
    { provide: APP_CONFIG_TOKEN, useValue: saeAppConfig },
  ],
};
