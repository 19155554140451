import { Pipe, PipeTransform } from '@angular/core';
import {
  differenceInMinutes,
  format,
  formatDistanceStrict,
  isFuture,
  isThisYear,
  isToday,
  isValid,
  isYesterday,
} from 'date-fns';
@Pipe({
  name: 'formatDateToTextPipe',
})
export class FormatDateToTextPipe implements PipeTransform {
  transform(value: string, dateFormat = 'MM/dd/yyyy'): string {
    const dateUpdated: number = new Date(value).getTime();
    const now: number = Date.now();
    if (!isValid(dateUpdated) || dateUpdated === 0) {
      return '';
    }
    if (isFuture(dateUpdated)) {
      return `To be published on ${format(dateUpdated, dateFormat)}`;
    } else if (isThisYear(dateUpdated) && dateFormat !== 'MM/dd/yyyy') {
      if (isToday(dateUpdated)) {
        if (differenceInMinutes(now, dateUpdated) >= 59) {
          return formatDistanceStrict(now, dateUpdated, { unit: 'hour' }) + ' ago';
        } else if (differenceInMinutes(now, dateUpdated) < 1) {
          return 'Just now';
        } else {
          return formatDistanceStrict(now, dateUpdated, { unit: 'minute' }) + ' ago';
        }
      } else if (isYesterday(dateUpdated)) {
        return 'Yesterday';
      } else {
        return format(dateUpdated, 'MMM dd');
      }
    } else {
      return format(dateUpdated, dateFormat);
    }
  }
}
