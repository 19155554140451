<ng-container *ngIf="processedData as data">
  <button
    *ngIf="showTrigger"
    type="button"
    (click)="$event.stopPropagation()"
    mat-icon-button
    class="si-state--unusual si-icon--more"
    [matMenuTriggerFor]="mainMenu.menu"
    [matTooltip]="data.tooltip"
    [attr.aria-label]="ariaLabel"
    (menuOpened)="menuOpened(true)"
    (menuClosed)="menuOpened(false)"
  >
    <mat-icon *ngIf="data.icon" aria-hidden="true">{{ data.icon }}</mat-icon>
    <span *ngIf="data.label">{{ data.label }}</span>
  </button>
  <si-overflow-menu-inner
    #mainMenu
    [menuData]="data"
    [filterParams]="filterParams"
    (itemClicked)="onItemClicked($event)"
    (sortChanged)="onSortChanged($event)"
    (filterChanged)="onFilterChanged($event)"
    (filterAll)="onFilterAll()"
  ></si-overflow-menu-inner>
</ng-container>
