import { Injectable } from '@angular/core';
import { ApmBase } from '@elastic/apm-rum';
import { ApmService } from '@elastic/apm-rum-angular';

@Injectable({
  providedIn: 'root',
})
export class ApmRumService {
  public apm: ApmBase;
  constructor(private readonly apmService: ApmService) {
    this.apm = this.apmService.apm;
  }
  //NOTE: any return type because Transaction isn't exported?? - JJV
  public startUserInteractionTransaction(name: string): any {
    return this.apm.startTransaction(name, 'user-interaction');
  }
}
