import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { Sort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FilterGroup,
  FilterItem,
  FilterParams,
  ItemGroup,
  MenuData,
  MenuItem,
  SortGroup,
} from '@sae/models';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'si-overflow-menu',
  templateUrl: './overflow-menu.component.html',
})
export class OverflowMenuComponent implements OnInit, OnChanges {
  @ViewChild('mainMenu', { static: true }) mainMenu: MatMenu; // required for recursion

  @Input() menuData: MenuData; // apps can choose whether to pass in a MenuData object...
  @Input() menuData$: Observable<MenuData>; // ...or an observable of one. makes no difference.

  @Output() sortChanged = new EventEmitter<Sort>();
  @Output() filterChanged = new EventEmitter<FilterParams[]>();
  @Output() menuIsOpen = new EventEmitter<boolean>();
  @Output() menuItemClicked = new EventEmitter<MenuItem>();

  /** @deprecated please use menuItemClicked instead */
  @Output() itemClicked = new EventEmitter<string>();

  public filterParams: FilterParams[] = [];
  public processedData: MenuData; // referenced by the template
  public ariaLabel: string;
  public showTrigger: boolean;

  ngOnInit(): void {
    if (this.menuData$) {
      this.menuData$.pipe(untilDestroyed(this)).subscribe((data) => {
        if (data) {
          this.processMenuData(data);
        }
      });
    } else if (this.menuData) {
      this.processMenuData(this.menuData);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('menuData')) {
      this.processMenuData(this.menuData);
    }
  }

  onItemClicked(mi: MenuItem): void {
    this.menuItemClicked.emit(mi);
    // DEPRECATED:
    const identifier = mi.name ? mi.name : mi.label ? mi.label : null;
    this.itemClicked.emit(identifier);
  }

  onSortChanged(output: { key: string; currentSort: Sort }): void {
    const newSort = { ...output.currentSort, active: output.key };
    if (output.currentSort.active === output.key) {
      newSort.direction = output.currentSort.direction === 'asc' ? 'desc' : 'asc';
    }
    this.sortChanged.emit(newSort);
  }

  onFilterChanged(output: { item: FilterItem; group: FilterGroup }): void {
    let params: FilterParams[] = [...this.filterParams];
    const found = params.find((p) => p.key === output.group.filterKey && p.value === output.item.name);
    if (found) {
      // remove it
      params = params.filter((p) => p.value !== output.item.name);
    } else {
      params.push({ key: output.group.filterKey, value: output.item.name });
      if (output.item.groupExclusive || output.group.singleSelect) {
        // remove all others from same group
        params = params.filter((p) => p.key !== output.group.filterKey || p.value === output.item.name);
      }
      if (output.item.selects) {
        output.item.selects.forEach((s) => {
          // auto select certain others in same group
          const selected = params.find((p) => p.key === output.group.filterKey && p.value === s);
          if (!selected) {
            params.push({ key: output.group.filterKey, value: s });
          }
        });
      }
      if (output.item.deselects) {
        // deselect certain others in same group
        output.item.deselects.forEach((ds) => {
          params = params.filter((p) => p.value !== ds);
        });
      }
    }
    // emit updated params array
    this.filterChanged.emit(params);
  }

  onFilterAll(): void {
    // unselect all filters
    this.filterChanged.emit([]);
  }

  menuOpened(open: boolean): void {
    this.menuIsOpen.emit(open);
  }

  private processMenuData(data: MenuData): void {
    this.showTrigger = false; // set to true if/when any valid, non-hidden menu items are found
    if (!data) {
      return;
    }
    const processedData: MenuData = { ...data };
    processedData.icon = data.icon ? data.icon : 'more_vert'; // verllipsis (default)
    processedData.tooltip = data.tooltip ? data.tooltip : !data.label ? 'Options' : null;
    let tooltip: string;
    if (data.tooltip) {
      tooltip = data.tooltip;
    } else if (!data.label) {
      // For a11y purposes, ensure there is a tooltip if there is no label to display
      tooltip = data.name ? data.name : 'Options';
    }
    processedData.tooltip = tooltip;

    // top level properties (not used in nested menus)
    this.ariaLabel = data.label ? null : data.tooltip; // ensure we have an aria label if there is no display label (never both)
    this.filterParams = data.filterParams ? data.filterParams : [];

    processedData.content.forEach((c) => this.processContent(c));
    this.processedData = processedData;
  }

  private processContent(c: FilterGroup | ItemGroup | SortGroup | MenuData | MenuItem): void {
    if ('filterKey' in c) {
      // content is a FilterGroup
      this.processFilterGroup(c);
    } else if ('items' in c) {
      // content is an ItemGroup or SortGroup
      this.processItems(c.items);
    } else if ('content' in c) {
      // content is a MenuData (nested submenu)
      this.processNestedMenu(c);
    } else {
      // content is a single MenuItem
      this.applyIconColor(c);
      if (!c.hidden) {
        this.showTrigger = true;
      }
    }
  }

  private processFilterGroup(fg: FilterGroup): void {
    if (fg.items && fg.items.length) {
      this.showTrigger = true;
      fg.items.forEach((i) => {
        this.applyIconColor(i);
        if (this.filterParams.some((x) => x.key === fg.filterKey && x.value === i.name)) {
          i.active = true;
        } else {
          i.active = false;
        }
      });
    }
  }
  private processItems(items: MenuItem[]): void {
    items.forEach((i) => {
      this.applyIconColor(i);
      if (!i.hidden) {
        this.showTrigger = true;
      }
    });
  }
  private processNestedMenu(menuData: MenuData): void {
    // recurse through subitems
    menuData.content.forEach((c) => this.processContent(c));
    // menus themselves can have icons and be disabled...
    this.applyIconColor(menuData);
  }

  private applyIconColor(item: MenuItem | MenuData): void {
    item.iconColor = !item.iconColor && !item.disabled ? 'primary' : item.iconColor && !item.disabled ? item.iconColor : null;
  }
}
