<ng-container *ngIf="{} as _">
  <mat-expansion-panel
    [expanded]="facet?.expanded"
    class="si-expanel si-expanel--form si-facet si-impact mat-elevation-z"
    [class.si-state--active]="panelExpand"
    (opened)="expandThisAggregation()"
    (closed)="collapseThisAggregation()"
  >
    <mat-expansion-panel-header class="si-expanel__h">
      <mat-panel-title class="si-expanel__title">
        <span>{{ facet?.displayName }}</span>
        <mat-icon class="si-icon si-facet__icon" aria-hidden="true" *ngIf="(filters | keyvalue)?.length > 0">
          done
        </mat-icon>
        <span class="si-expanse"></span>
        <mat-icon
          class="si-icon si-facet__icon"
          *ngIf="isPreservedFacet"
          matTooltip="Refresh {{ facet?.displayName }}"
          aria-label="Refresh {{ facet?.displayName }}"
          aria-hidden="true"
          (click)="$event.stopPropagation(); clearPreservedFacet()"
        >
          refresh
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="si-expanel__c si-expanel__c--form">
      <div class="si-field si-wd-full">
        <div class="mat-form-field-wrapper">
          <div class="si-input si-input--clickstack si-facet__list">
            <mat-checkbox
              class="si-checkbox si-checkbox--date"
              value="dateRange"
              (click)="$event.stopPropagation()"
              (change)="toggleSelection($event.checked)"
              [checked]="isFilterApplied"
            >
              <span class="si-wd-full">
                {{ facet?.displayName }}{{ facet?.subHeader ? ' (' + facet?.subHeader + ')' : '' }}
              </span>
              <mat-form-field class="si-wd si-wd-half">
                <mat-label>Min</mat-label>
                <input matInput [value]="minControl.value" [formControl]="minControl" aria-label="Min" />
              </mat-form-field>
              <mat-form-field class="si-wd si-wd-half si-field--to">
                <mat-label>Max</mat-label>
                <input matInput [value]="maxControl.value" [formControl]="maxControl" aria-label="Max" />
              </mat-form-field>
              <mat-slider class="si-wd si-wd-full" [max]="maxValue" [min]="minValue" [step]="1" [discrete]="true">
                <input type="range" [value]="minControl.value" [formControl]="minControl" matSliderStartThumb />
                <input type="range" [value]="maxControl.value" [formControl]="maxControl" matSliderEndThumb />
              </mat-slider>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
