<mat-card class="si-card si-card--ledger mat-elevation-z2" [class.si-receipt--card]="state === 'complete'">
  <ng-container *ngTemplateOutlet="lineItemTemplate; context: { lineItem: item }"></ng-container>
  <ng-container *ngIf="item.items?.length > 0">
    <ng-container *ngFor="let lineItem of item.items">
      <ng-container *ngTemplateOutlet="lineItemTemplate; context: { lineItem: lineItem }"></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="item.discounts?.length > 0">
    <div
      *ngFor="let discount of item.discounts; let first = first"
      class="si-cell si-cell--discount si-wd-full"
      [class.si-receipt]="state === 'complete'"
    >
      <em class="si-for--minor">{{ discount.label }}</em>
      <span class="si-for--positive">
        <ng-container *ngTemplateOutlet="currencyTemplate; context: { value: discount.price }"></ng-container>
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="item.discounts?.length > 0 || item.items?.length > 1">
    <div class="si-cellgroup si-wd-full">
      <div class="si-cell si-wd-minmax si-visible--beyond-mobile"></div>
      <div class="si-cell si-wd-full"><mat-divider class="si-divider"></mat-divider></div>
    </div>
    <div class="si-cell si-cell--subtotal si-wd-full" [class.si-receipt]="state === 'complete'">
      <span class="si-for--minor">
        <ng-container *ngIf="item.items?.length > 1; else discountOnlyTemplate">
          Subtotal ({{ item.items?.length }} Items)
        </ng-container>
        <ng-template #discountOnlyTemplate>Subtotal</ng-template>
      </span>
      <span class="si-for--subtotal">
        <ng-container *ngTemplateOutlet="currencyTemplate; context: { value: item.price }"></ng-container>
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="state === 'complete' && item.suggestions?.length > 0">
    <div *ngFor="let suggestion of item.suggestions" class="si-cellgroup si-wd-full">
      <div class="si-cell si-wd-minmax si-visible--beyond-mobile"></div>
      <div class="si-cell si-wd-full">
        <div class="si-cell__c">
          <a
            mat-button
            class="si-button--link si-align--icon"
            [href]="suggestion.href"
            matTooltip="Opens in New Window"
            aria-label="Opens in New Window"
            target="_blank"
          >
            <mat-icon class="si-icon si-visible--beyond-mobile" color="primary" aria-hidden="true">
              {{ suggestion.icon }}
            </mat-icon>
            <span class="si-for--primary">
              {{ suggestion.label }}
              <mat-icon class="si-icon" color="primary" aria-hidden="true">open_in_new</mat-icon>
            </span>
          </a>
        </div>
      </div>
      <div class="si-cell si-wd-money si-receipt"></div>
    </div>
  </ng-container>
</mat-card>

<ng-template #lineItemTemplate let-lineItem="lineItem">
  <div class="si-cellgroup si-wd-full">
    <div *ngIf="state !== 'complete'" class="si-cell si-wd-minmax si-visible--beyond-mobile">
      <div *ngIf="lineItem.quantity" class="si-cell__c si-qty si-editable">
        <mat-form-field class="si-field si-wd-expanse">
          <mat-select
            #quantitySelect
            class="si-select"
            aria-label="Quantity"
            [value]="'' + lineItem.quantity"
            matTooltip="Quantity"
          >
            <mat-option
              *ngFor="let option of _quantityOptions"
              [value]="option"
              (click)="onQuantityChange(option, '' + lineItem.quantity)"
            >
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="si-cell si-wd-full">
      <div class="si-cell__c">
        <ng-container *ngIf="lineItem.context?.length > 0">
          <ng-container *ngFor="let context of lineItem.context; let first = first">
            <span
              *ngIf="state !== 'complete' || (state === 'complete' && context.hideOnReceipt !== true)"
              [class.si-additional]="!first"
              [class.si-for--negative]="context.warning"
            >
              <ng-container *ngIf="context.italic">
                <em>{{ context.label }}</em>
              </ng-container>
              <ng-container *ngIf="!context.italic">{{ context.label }}</ng-container>
            </span>
          </ng-container>
        </ng-container>
        <div class="si-card__subject si-for--minor">
          <span *ngIf="lineItem.description">{{ lineItem.description }}</span>
          <span *ngIf="state === 'complete' && lineItem.sku" [class.si-additional]="lineItem.description">
            SKU {{ lineItem.sku }}
          </span>
        </div>
      </div>
    </div>
    <div class="si-cell si-wd-money">
      <div
        *ngIf="!lineItem.items || lineItem.items?.length === 0"
        class="si-cell__c si-money"
        [class.si-receipt]="state === 'complete'"
      >
        <ng-container *ngTemplateOutlet="currencyTemplate; context: { value: lineItem.price }"></ng-container>
      </div>
    </div>
    <div *ngIf="state !== 'complete'" class="si-cell si-wd-icon">
      <div *ngIf="!lineItem.items || lineItem.items?.length === 0" class="si-cell__a si-tight--vert">
        <si-more-menu
          [options]="lineItem.menuOptions"
          [additionalOptions]="_additionalOptions"
          (optionSelected)="onMenuOptionSelected($event, lineItem)"
        ></si-more-menu>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #currencyTemplate let-value="value">
  {{ value | currency: currencyCode:currencyDisplay:currencyDigitsInfo:currencyLocale }}
</ng-template>
