<div *ngIf="treeNodes && treeNodes.length > 0" class="si-tree__container">
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="si-tree si-tree--master si-tree--enhanced"
    role="tree"
  >
    <mat-tree-node
      *matTreeNodeDef="let node"
      (click)="nodeClicked(node)"
      matTreeNodePadding
      role="treeitem"
      class="si-tree__node"
      [class.si-state--selected]="node.isSelected"
      [matTreeNodePaddingIndent]="16"
    >
      <span class="si-tree__control mat-icon-button"></span>
      <ng-container *ngIf="!linkMode || !node.url">
        <a class="si-tree__title si-button--link" mat-button>{{ node.name }}</a>
      </ng-container>
      <ng-container *ngIf="linkMode && node.url">
        <ng-container *ngIf="node.url.indexOf('#') === -1">
          <a [routerLink]="node.url" class="si-tree__title si-button--link" mat-button>{{ node.name }}</a>
        </ng-container>
        <ng-container *ngIf="node.url.indexOf('#') !== -1">
          <a [href]="node.url" class="si-tree__title si-button--link" mat-button>{{ node.name }}</a>
        </ng-container>
      </ng-container>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      role="group"
      class="si-tree__node"
      (click)="$event.stopPropagation(); nodeClicked(node)"
      [class.si-state--selected]="node.isSelected"
      [matTreeNodePaddingIndent]="16"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        class="si-tree__control"
        role="button"
        (click)="nodeToggled(node)"
        [attr.aria-label]="treeControl.isExpanded(node) ? 'Contract Node' : 'Expand Node'"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <ng-container *ngIf="!linkMode || !node.url">
        <a class="si-tree__title si-button--link" mat-button>{{ node.name }}</a>
      </ng-container>
      <ng-container *ngIf="linkMode && node.url">
        <ng-container *ngIf="node.url.indexOf('#') === -1">
          <a [routerLink]="node.url" class="si-tree__title si-button--link" mat-button>{{ node.name }}</a>
        </ng-container>
        <ng-container *ngIf="node.url.indexOf('#') !== -1">
          <a [href]="node.url" class="si-tree__title si-button--link" mat-button>{{ node.name }}</a>
        </ng-container>
      </ng-container>
    </mat-tree-node>
  </mat-tree>
</div>
