<ng-template #template>
  <ng-container
    *ngIf="{
      config: config$ | async,
      loading: loading$ | async,
      panelInitialized: panelInitialized$ | async,
      isPubDateFilterApplied: isPubDateFilterApplied$ | async,
      pubDateTo: pubDateTo$ | async,
      pubDateFrom: pubDateFrom$ | async
    } as _"
  >
    <div class="si-list__container">
      <mat-list
        role="list"
        class="si-list si-list--basic si-list--master"
        *ngIf="_.config?.masterPanelBrowseCategories?.length > 0 || _.config?.masterPanelPreviousCategory?.url"
      >
        <mat-list-item *ngIf="_.config?.masterPanelPreviousCategory?.url" role="listitem" class="si-list__item">
          <a
            mat-button
            class="si-button--link si-impact si-major"
            [routerLink]="_.config?.masterPanelPreviousCategory?.url"
            [state]="{ context: 'main_search' }"
          >
            <mat-icon>arrow_back</mat-icon>
            {{ _.config?.masterPanelPreviousCategory?.label }}
          </a>
        </mat-list-item>
        <ng-container *ngFor="let list of _.config?.masterPanelBrowseCategories">
          <mat-list-item role="listitem" class="si-list__item si-list__title" *ngIf="list.list && list.items?.length">
            <span class="si-expanel__title si-impact__title si-major">
              <strong>{{ list.list }}</strong>
            </span>
          </mat-list-item>
          <mat-list-item *ngFor="let item of list.items" role="listitem" class="si-list__item">
            <a
              mat-button
              class="si-button--link si-impact si-major"
              [routerLink]="item.url"
              [fragment]="item?.fragment"
              [state]="{ context: 'main_search' }"
              [attr.data-analytics]="item?.analyticsId ?? null"
            >
              {{ item.label }}
            </a>
          </mat-list-item>
        </ng-container>
      </mat-list>
      <mobi-filter-panel
        [loading]="_.loading"
        [panelInitialized]="_.panelInitialized"
        [context]="context"
        [startExpanded]="!_.config?.masterPanelBrowseCategories?.length"
        [facets$]="facets$"
        [filters$]="filters$"
        [onlyFacet]="onlyFacet"
        [customFilters$]="customFilters$"
        [preservedFacet$]="preservedFacet$"
        [isPubDateFilterApplied]="_.isPubDateFilterApplied"
        [pubDateTo]="_.pubDateTo"
        [pubDateFrom]="_.pubDateFrom"
        (showMoreValuesClicked)="showMoreValues($event)"
        (applyFacetClicked)="applyFacet($event)"
        (removeFacetClicked)="removeFacet($event)"
        (expandAllClicked)="expandAll()"
        (collapseAllClicked)="collapseAll()"
        (clearPreservedFacetClicked)="clearPreservedFacet()"
        (clearFiltersClicked)="clearFilters()"
        (applyPubDateFilterClicked)="applyPubDateFilter($event)"
        (removePubDateFilterClicked)="removePubDateFilter()"
        (applyCustomFilterClicked)="applyCustomFilter($event)"
        (removeCustomFilterClicked)="removeCustomFilter($event)"
      ></mobi-filter-panel>
    </div>
  </ng-container>
</ng-template>
