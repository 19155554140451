import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { DocumentAsset } from '../../models/api-models';
import { Asset } from '../../models/common-interfaces';
import { DownloadService } from '../../services/download.service';

@Component({
  selector: 'mobi-download-menu',
  templateUrl: './download-menu.component.html',
  exportAs: 'downloadMenu',
})
export class DownloadMenuComponent {
  @Input() assets: Asset[] = [];
  @Input() hasAccess = false;
  @Input() loading = false;
  @Output() retry = new EventEmitter();
  @Output() fileDownloaded = new EventEmitter<Asset>();
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  constructor(private downloadService: DownloadService) {}

  getDownloadLink(asset: Asset & DocumentAsset): string {
    return this.downloadService.getDownloadLinkForAsset(asset);
  }
}
