import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@sae/components';
import { SharedFeaturesModule } from '@sae/features';
import { MaterialModule } from '@sae/material';
import { SharedUtilitiesModule } from '@sae/utilities';
import { AddToDialogComponent } from './components/add-to-dialog/add-to-dialog.component';
import { BasicCardComponent } from './components/card/basic-card/basic-card.component';
import { DownloadDialogComponent } from './components/download-dialog/download-dialog.component';
import { RequestAccessDialogComponent } from './components/request-access-dialog/request-access-dialog.component';
import { DownloadMenuComponent } from './components/download-menu/download-menu.component';
import { ExportMetaDialogComponent } from './components/export-meta-dialog/export-meta-dialog.component';
import { FacetExpanelComponent } from './components/facet-expanel/facet-expanel.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { SearchBoxComponent } from './components/form/form-field/search-box/search-box.component';
import { LibraryCardComponent } from './components/library-card/library-card.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { MobilusContentCardComponent } from './components/mobilus-content-card/mobilus-content-card.component';
import { PubdateFacetExpanelComponent } from './components/pubdate-facet-expanel/pubdate-facet-expanel.component';
import { SaveSearchDialogComponent } from './components/save-search-dialog/save-search-dialog.component';
import { SortMenuComponent } from './components/sort-menu/sort-menu.component';
import { SubscriptionLoginDialogComponent } from './components/subscription-login-dialog/subscription-login-dialog.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { DownloadFileDirective } from './directives/download-file.directive';
import { MathModule } from './modules/math.module';
import { AssetFileTypePipe } from './pipes/asset-file-type.pipe';
import { BuildDetailsLinkPipe } from './pipes/build-details-link.pipe';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FormatDateToTextPipe } from './pipes/format-date-to-text.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { SubscriptionLoginFormComponent } from './components/subscription-login-form/subscription-login-form.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { GroupCardComponent } from './components/group-card/group-card.component';
import { PublicationDocumentCardComponent } from './components/publication-document-card/publication-document-card.component';
import { IncludesPipe } from './pipes/includes.pipe';
import { MetaTagsComponent } from './components/meta-tags/meta-tags.component';
import { EmToMarkPipe } from './pipes/em-to-mark.pipe';
import { RemoveHtmlTagsPipe } from './pipes/remove-html-tags.pipe';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { MobiProcessCardComponent } from './components/mobi-process-card/mobi-process-card.component';
import { MobiCategoryCardComponent } from './components/mobi-category-card/mobi-category-card.component';
import { MobiLatestJournalIssueCardComponent } from './components/mobi-latest-journal-issue-card/mobi-latest-journal-issue-card.component';
import { EncodeURIComponentPipe } from './pipes/encode-uri-component.pipe';
import { SliderFacetExpanelComponent } from './components/slider-facet-expanel/slider-facet-expanel.component';

@NgModule({
  declarations: [
    EnumToStringPipe,
    UserInitialsPipe,
    FeedbackComponent,
    FormatDateToTextPipe,
    BuildDetailsLinkPipe,
    SafeHtmlPipe,
    BasicCardComponent,
    SearchBoxComponent,
    SortMenuComponent,
    LibraryCardComponent,
    FacetExpanelComponent,
    SubscriptionLoginDialogComponent,
    SubscriptionLoginFormComponent,
    ToolbarComponent,
    MobilusContentCardComponent,
    LogoutDialogComponent,
    FilterPanelComponent,
    AddToDialogComponent,
    SaveSearchDialogComponent,
    PubdateFacetExpanelComponent,
    ExportMetaDialogComponent,
    DownloadMenuComponent,
    DownloadDialogComponent,
    DownloadFileDirective,
    AssetFileTypePipe,
    FilterPipe,
    ConfirmationDialogComponent,
    GroupCardComponent,
    PublicationDocumentCardComponent,
    IncludesPipe,
    MetaTagsComponent,
    EmToMarkPipe,
    RemoveHtmlTagsPipe,
    BottomSheetComponent,
    MobiProcessCardComponent,
    MobiCategoryCardComponent,
    MobiLatestJournalIssueCardComponent,
    RequestAccessDialogComponent,
    EncodeURIComponentPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    SharedComponentsModule,
    SharedFeaturesModule,
    SharedUtilitiesModule,
    MathModule,
    SliderFacetExpanelComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    EnumToStringPipe,
    UserInitialsPipe,
    FormatDateToTextPipe,
    BuildDetailsLinkPipe,
    SafeHtmlPipe,
    BasicCardComponent,
    SearchBoxComponent,
    LibraryCardComponent,
    FacetExpanelComponent,
    SharedComponentsModule,
    SharedFeaturesModule,
    SharedUtilitiesModule,
    SubscriptionLoginDialogComponent,
    ToolbarComponent,
    MobilusContentCardComponent,
    MathModule,
    FilterPanelComponent,
    AddToDialogComponent,
    SaveSearchDialogComponent,
    PubdateFacetExpanelComponent,
    ExportMetaDialogComponent,
    DownloadMenuComponent,
    DownloadDialogComponent,
    DownloadFileDirective,
    AssetFileTypePipe,
    FilterPipe,
    ConfirmationDialogComponent,
    GroupCardComponent,
    PublicationDocumentCardComponent,
    IncludesPipe,
    MetaTagsComponent,
    EmToMarkPipe,
    RemoveHtmlTagsPipe,
    MobiProcessCardComponent,
    MobiCategoryCardComponent,
    MobiLatestJournalIssueCardComponent,
    RequestAccessDialogComponent,
    EncodeURIComponentPipe,
  ],
  providers: [BuildDetailsLinkPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
