import { DomPortal } from '@angular/cdk/portal';
import { Component, Inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DetailToolTakeoverView, ViewTemplate } from '@sae/models';
import { ISessionService, SESSION_TOKEN } from '@sae/services';
import { BaseTemplateComponent } from '../base-template.component';

@Component({
  selector: 'si-detail-tool-takeover-template',
  templateUrl: './detail-tool-takeover-template.component.html',
})
export class DetailToolTakeoverTemplateComponent extends BaseTemplateComponent implements OnChanges {
  @Input() projectedContent!: DetailToolTakeoverView;
  showScrim$ = this.sessionService.showScrim$;

  headerContentPortal: DomPortal<TemplateRef<unknown>>;
  detailContentPortal: DomPortal<TemplateRef<unknown>>;
  toolContentPortal: DomPortal<TemplateRef<unknown>>;

  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    super(sessionService);
  }

  onScrimClosed(): void {
    this.sessionService.setShowScrim(false);
  }

  ngOnChanges(s: SimpleChanges): void {
    if (s?.projectedContent?.currentValue) {
      this.headerContentPortal = new DomPortal(this.projectedContent.headerContent);
      this.detailContentPortal = new DomPortal(this.projectedContent.detailContent);
      this.toolContentPortal = new DomPortal(this.projectedContent.toolContent);
    }
  }
}

@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DetailToolTakeoverViewBase implements DetailToolTakeoverView {
  @ViewChild('headerContent') headerContent: TemplateRef<unknown>;
  @ViewChild('detailContent') detailContent: TemplateRef<unknown>;
  @ViewChild('toolContent') toolContent: TemplateRef<unknown>;
  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    this.sessionService.setActiveViewTemplate(ViewTemplate.DetailToolTakeover);
  }
}
