/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpErrorResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { ActiveState } from '@datorama/akita';
import {
  Aggregation,
  Application,
  CriteriaGroup,
  Operators,
  Pagination,
  RangeOperator,
  SaeHttpResponse,
  SearchBody,
  SelectedFacet
} from '@sae/models';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StoresRegistry, StoresToken } from './../api-registry';
import { BaseEntityQuery, BaseEntityService, BaseEntityStore, BaseState } from './base.service';

export interface SearchCoreState<E = any> extends BaseState<E>, ActiveState {
  facets: Array<Aggregation>;
  appliedFacets: Array<SelectedFacet>;
  searchTerm: string;
}

export abstract class SearchCoreStore<TState extends SearchCoreState> extends BaseEntityStore<TState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry,  derivedState?: TState, @Inject('storeName') storeName?: string) {
    super({ name: storeName ? storeName : storesRegistry.search }, derivedState);
  }
}

export abstract class SearchsCoreQuery<TState extends SearchCoreState> extends BaseEntityQuery<TState> {
  constructor(protected store: SearchCoreStore<TState>) {
    super(store);
  }
}

export abstract class SearchCoreService extends BaseEntityService<SearchCoreState> {
  public loading$ = this.query.selectLoading();
  public active$ = this.query.selectActive();
  public searchTerm$ = this.query.select((s) => s.searchTerm);
  public appliedFacets$ = this.query.select((s) => s.appliedFacets);
  public facets$ = this.query.select((s) => s.facets);
  public results$ = this.query.selectAll();
  public total$ = this.query.select((s) => s.pagination.total);
  public sortField$ = this.query.select((s) => s.sortField);
  public sortDir$ = this.query.select((s) => s.sortDir);
  public pagination$ = this.query.select((s) => s.pagination);

  executeSearch(pageSize: number, page: number, sortBy: string, sortAsc: boolean, searchTerm: string): Observable<SaeHttpResponse> {
    const searchBody = this.constructSearchBody(this.constructCriteriaGroups(searchTerm, this.query.getValue().appliedFacets, []), pageSize, page, sortBy, sortAsc);

    this.store.update({ loading: true });
    return this.getHttp()
      .post<SaeHttpResponse>(this.api, searchBody)
      .pipe(
        tap((r: SaeHttpResponse) => {
          this.store.update({
            facets: r.aggregations,
            pagination: r.pagination,
            loading: false,
          });
        }),
        tap(() => {
          this.store.update({ loading: false });
        }),
        catchError((e: HttpErrorResponse) => {
          if (e.status === 404) {
            this.store.update((s) => {
              return {
                ...s,
                entities: [],
                ids: [],
                pagination: { ...s.pagination, total: 0 },
                mostRecentResponse: e,
                loading: false,
              };
            });
          }
          return EMPTY;
        }),
        catchError((e) => {
          console.error(e);
          return EMPTY;
        })
      );
  }

  protected constructSearchBody(
    criteriaGroups: Array<CriteriaGroup>,
    pageSize: number,
    page: number,
    sortCol: string,
    sortAsc: boolean,
    applicationMeta?: Application
  ): SearchBody {
    const offset = (page - 1) * pageSize;

    const order = sortAsc ? 'asc' : 'desc';
    const searchBody: SearchBody = {
      application: applicationMeta,
      criteria: criteriaGroups,
      limit: pageSize,
      offset,
      sort: sortCol,
      order,
    };
    return searchBody;
  }

  constructCriteriaGroups(searchTerm: string, facets: SelectedFacet[], appCriteriaGroup: CriteriaGroup[]): CriteriaGroup[] {
    const criteriaGroups = appCriteriaGroup ?? new Array<CriteriaGroup>();

    if (searchTerm) {
      criteriaGroups.push(this.getSearchTermCriteria(searchTerm, 'AND'));
    }

    facets?.forEach((facet) => {
      const criteria = this.getFacetCriteria(facet, 'AND');
      if(criteria){
        criteriaGroups.push(criteria);
      }
    });

    return criteriaGroups;
  }

  public addDropdownFacetToSelectedFacets(facetName: string, facetDisplayName: string, value: string, name: string): void {
    this.removeDropdownFacetFromSelectedFacets(facetName);
    this.addFacetToSelectedFacets(facetName, facetDisplayName, value, name);
  }

  public removeDropdownFacetFromSelectedFacets(fieldName: string): void {
    const appliedFacets = this.query.getValue().appliedFacets.concat();
    const index = appliedFacets.findIndex((q) => q.facetName === fieldName);
    appliedFacets.splice(index, 1);
    this.store.update({ appliedFacets, page: 1 });
  }

  public addDateFacetToSelectedFacets(facet: SelectedFacet): void {
    const appliedFacets = this.query.getValue().appliedFacets.concat();
    const selectedFacetIndex = appliedFacets.findIndex(q => q.facetName === facet.facetName);
    selectedFacetIndex > -1 && appliedFacets.splice(selectedFacetIndex, 1);
    const facets: SelectedFacet[] = appliedFacets.filter((q) => q.facetName !== facet.facetName || q.rangeOperator !== facet.rangeOperator);
    facets.push(facet);
    this.store.update({ appliedFacets: facets, page: 1 });
  }

  public removeDateFacetFromSelectedFacets(fieldName: string, rangeOperator: RangeOperator): void {
    const appliedFacets = this.query.getValue().appliedFacets.concat();
    const index = appliedFacets.findIndex((q) => q.facetName === fieldName && q.rangeOperator === rangeOperator);
    appliedFacets.splice(index, 1);
    this.store.update({ appliedFacets, page: 1 });
  }

  public addFacetToSelectedFacets(facetName: string, facetDisplayName: string, value: string, name: string): void {
    const appliedFacets = this.query.getValue().appliedFacets.concat();
    const selectedFacet: SelectedFacet = { searchType: 'KEYWORD', facetName, facetDisplayName, values: [value], name };
    if (!appliedFacets.find((q) => q.facetName === selectedFacet.facetName && q.values.includes(selectedFacet.values[0]))) {
      appliedFacets.push(selectedFacet);
      this.store.update({ appliedFacets, page: 1 });
    }
  }

  public addArrayValueFacetToSelectedFacets(facetName: string, facetDisplayName: string, values: string[], name: string): void {
    const appliedFacets = this.query.getValue().appliedFacets.concat();
    const selectedFacet: SelectedFacet = { searchType: 'KEYWORD', facetName, facetDisplayName, values, name };
    const index = appliedFacets.findIndex((q) => q.facetName === selectedFacet.facetName);

    if (index > -1) {
      appliedFacets.splice(index, 1);
    }

    appliedFacets.push(selectedFacet);
    this.store.update({ appliedFacets, page: 1 });
  }

  public removeFacetFromSelectedFacets(fieldName: string, value: string): void {
    const appliedFacets = this.query.getValue().appliedFacets.concat();
    const index = appliedFacets.findIndex((q) => q.facetName === fieldName && q.values.includes(value));
    appliedFacets.splice(index, 1);
    this.store.update({ appliedFacets, page: 1 });
  }

  public removeCompleteFacetFromSelectedFacets(facetName: string): void {
    const appliedFacets = this.query.getValue().appliedFacets.concat();
    const index = appliedFacets.findIndex((q) => q.facetName === facetName);
    if (index > -1) {
      appliedFacets.splice(index, 1);
      this.store.update({ appliedFacets, page: 1 });
    }
  }

  public isDateFacetApplied$(facetName: string, rangeOperator: RangeOperator): Observable<boolean> {
    return this.appliedFacets$.pipe(map((facets: SelectedFacet[]) => facets.some((f) => f.facetName === facetName && f.rangeOperator === rangeOperator)));
  }

  /**
   * Check if a spcific value for the facet is applied
   */
  public isFacetApplied$(facetName: string, value: string): Observable<boolean> {
    return this.appliedFacets$.pipe(map((facets: SelectedFacet[]) => facets.some((f) => f.facetName === facetName && f.values.includes(value))));
  }

  /**
   * Check if facet has any values applied
   */
  public hasFacetApplied(facetName: string): Observable<boolean> {
    return this.appliedFacets$.pipe(map((facets: SelectedFacet[]) => facets.some((f) => f.facetName === facetName)));
  }

  public clearAppliedFacts(): void {
    this.store.update({ appliedFacets: [], page: 1 });
  }

  public concatSearchTerms(current: string, newAppend: string): string {
    const currentTerms = current?.split('|');
    if (!current) {
      return `${newAppend}`;
    } else if (current.toString() !== newAppend.toString() && !currentTerms.includes(newAppend)) {
      return `${current}|${newAppend}`;
    }
    return `${current}`;
  }

  public getSearchTermCriteria(term: string, operator: Operators): CriteriaGroup {
    return { groupType: 'ADV', operator, query: [{ searchType: 'TEXT', field: { name: 'fulltext' }, values: [term] }] };
  }

  public getFacetCriteria(facet: SelectedFacet | undefined, operator: Operators): CriteriaGroup | undefined {
    if(!facet){
      return undefined;
    }
    return {
      groupType: facet.groupType,
      operator,
      query: [
        {
          searchType: facet.searchType,
          rangeOperator: facet.rangeOperator,
          field: { name: facet.facetName, label: facet.name },
          values: facet.values,
        },
      ],
    };
  }

  public changeSort(sort: string): void {
    if (this.query.getValue().sortField !== sort) {
      this.store.update({ sortField: sort, sortDir: 'asc' });
    } else {
      this.toggleSortDir();
    }
  }

  private toggleSortDir(): void {
    const newSortDir = this.query.getValue().sortDir === 'asc' ? 'desc' : 'asc';
    this.store.update({ sortField: this.query.getValue().sortField, sortDir: newSortDir });
  }

  public setPagination(pagination: Pagination): void {
    this.store.update({ pagination });
  }

  public getPagination(): Pagination {
    return this.query.getValue().pagination;
  }

  public updateSearchTerm(searchTerm: string): void {
    this.store.update({ searchTerm });
  }

  public getSearchTerm(): string {
    return this.query.getValue().searchTerm;
  }

  public getAppliedFacets(): SelectedFacet[] {
    return this.query.getValue().appliedFacets;
  }

  public setActive(id: string): void {
    this.store.setActive(id);
  }
}
