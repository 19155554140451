import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MoreOption } from '@sae/models';

@Component({
  selector: 'si-multi-select-toolbar',
  templateUrl: './multi-select-toolbar.component.html',
})
export class MultiSelectToolbarComponent {
  @Input() actionOptions: MoreOption[];
  @Input() selectAllChecked = false;
  @Input() moreMenu = true;
  @Input() negativeMargin = false;
  @Input() minifiedMode = false;

  @Output() selectAllChanged = new EventEmitter<boolean>();
  @Output() actionSelected = new EventEmitter<string>();

  selectAction(action: string): void {
    this.actionSelected.emit(action);
  }

  selectAll(): void {
    this.selectAllChanged.emit(true);
  }

  unselectAll(): void {
    this.selectAllChanged.emit(false);
  }
}
