/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
// It seems we cannot declare typings for a non-typed module within a Nx library (works in apps, not libs).
// Works if we add the d.ts to both the lib and the app src, but I don't want to do that for every app.
// So this is a last resort measure, until this service is deleted in favor of a node service.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toRange, fromRange } from 'xpath-range';
import * as Rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-textrange';
import { randomUUID } from '@sae/base';

export interface AnnotationZoneComment {
  id?: string;
  text?: string;
  annotation: AnnotationZoneAnnotation;
}

export interface AnnotationZoneAnnotation {
  quote: string;
  ranges: AnnotationZoneRanges;
}

export interface AnnotationZoneRanges {
  end: string;
  endOffset: number;
  start: string;
  startOffset: number;
}

export interface AnnotationZoneBookmark {
  label?: string;
  id?: string;
  isGroup?: boolean;
  groupBookmarks?: AnnotationZoneBookmark[];
  quote?: string;
  text?: string;
  offset?: string;
}

export interface AnnotationZoneLoadAnnotationData {
  currentAnnotation: AnnotationZoneComment;
  allAnnotations: AnnotationZoneComment[];
}

@Component({
  selector: 'si-annotation-zone',
  templateUrl: './annotation-zone.component.html',
})
export class AnnotationZoneComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  /* An array of annotations to apply over the projected content. Note that changes to this input should be in the
     form of a new array reference for change detection to occur (ie: [...newArray]). */
  @Input() annotations: AnnotationZoneComment[] = [];

  /* When true (recommended), this component copies the html of the initially projected content and restores
     it when unapplying annotations to preserve the cleanest dom state. Caveat is this will cause any angular
     event bindings within the projected content to not work, although plain inline vanilla javascript bindings
     will work just fine. When false, the component attempts to sugically remove manipulations but due to
     limitations (bugs) in Rangy library complex nested annotated documents may start to look a bit out of whack
     as it can sometimes leave remnants behind when removing state. */
  @Input() htmlBuffer = true;

  /* Option to set the maximum size of the tooltip on bookmarks for non-grouped annotations. This is to prevent
     annotations with very large comments blocks to have its tooltips too large. Any setting <= 0 disables this
     and will show the full comment text in the tooltip. */
  @Input() maxTooltipSize = 100;

  /* When true this informs the component that projected content will be wrapped in a card such that it uses
     appropriate additional css styles to add padding to the card and modifies the position of annotation
     bookmarks such that they are within the card's border and inside a larger right margin of the card. */
  @Input() cardWrappedContent = false;

  /* Specifies the tooltip text to show when hovering on the add annotation pencil button. */
  @Input() addAnnotationTooltip = 'Add Annotation';

  /* Determines the default expanded state of the annotations preview panel. */
  @Input() expandPreviewPanel = false;

  /* Event triggered by the user selecting text and indicating a new annotation is desired to be created. */
  @Output() createAnnotation = new EventEmitter<AnnotationZoneComment>();

  /* Event triggered by the user clicking on an existing annotation to load its details. The reason this event
     emits both the current and all annotations is for the case that the consumer handling the edit function
     in a dialog may need to know this components ordering of the annotations, which is by actual display order
     and not necessarily the order it was given as direct input. If a dialog needs to provide navigation within
     annotations by display order this additional information is required.
  */
  @Output() loadAnnotation = new EventEmitter<AnnotationZoneLoadAnnotationData>();

  /* Event triggered by the user clicking on the button to print annotations (which spawns the print window)
     in order for consumers to perform any necessary additoinal processing of the command (such as registering
     that something has been printed for records, etc). */
  @Output() printAnnotation = new EventEmitter();

  // Generates distinct ids for elements requiring dynamic manipulations such that this instance doesn't mutate others
  annotationInstanceId = randomUUID();
  annotationContentId = `si-annotation-content__${this.annotationInstanceId}`;
  annotationBufferId = `si-annotation-buffer__${this.annotationInstanceId}`;
  annotationButtonId = `si-annotation-button__${this.annotationInstanceId}`;
  annotationTagName = 'si-annotation-mark';
  annotationBookmarkIdBase = 'si-annotation-bookmark__';

  rangy = Rangy as any;
  highlighter: any;
  selectionInProgress = false;
  initialized = false;
  buildBookmarksThrottleThread: any = null;
  bookmarks: AnnotationZoneBookmark[] = [];
  showingBookmarkId: string = null;
  sortedAnnotations: AnnotationZoneComment[] = null;

  ngOnInit() {
    document.addEventListener('selectionchange', this.onSelectionChange.bind(this), false);
    window.addEventListener('resize', this.buildBookmarksThrottle.bind(this), false);
  }

  ngOnDestroy() {
    document.removeEventListener('selectionchange', this.onSelectionChange.bind(this), false);
    window.removeEventListener('resize', this.buildBookmarksThrottle.bind(this), false);
  }

  ngAfterViewInit() {
    this.rangy.init();
    this.highlighter = this.rangy.createHighlighter();
    this.annotateText();
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized && changes.annotations) {
      this.unannotateText(true);
    }
  }

  private onSelectionChange() {
    if (document.getSelection().toString().trim().length === 0) {
      this.hideAnnotationButton();
    }
  }

  public onSelectStart() {
    this.selectionInProgress = true;
    this.hideAnnotationButton();
  }

  public onSelectEnd() {
    // Timeout required as we need to invoke processing on next macro thread interval so the DOM can properly processes selection clearing from a click
    setTimeout(() => {
      this.selectionInProgress = false;

      if (document.getSelection().toString().trim().length > 0) {
        this.showAnnotationButton();
      } else {
        this.hideAnnotationButton();
      }
    });
  }

  public onSelectCancel() {
    if (this.selectionInProgress) {
      this.selectionInProgress = false;
      document.getSelection().removeAllRanges();
    }
  }

  private normalizeRange(baseElement: any, excludeTagName?: string) {
    function normalizeRangePath(
      rangeContainer: any,
      xpath: string,
      excludeTagName: string = null,
      offset: number = null,
      previousSibling?: any,
      parentNode?: any
    ): { offset: number; xpath: string } {
      if (offset === null) {
        const newXpath = xpath.split('/');
        newXpath.pop();

        return normalizeRangePath(
          rangeContainer,
          newXpath.join('/'),
          excludeTagName,
          0,
          rangeContainer.previousSibling,
          rangeContainer.parentNode
        );
      } else if (previousSibling) {
        return normalizeRangePath(
          rangeContainer,
          xpath,
          excludeTagName,
          offset + (previousSibling.textContent?.length ?? 0),
          previousSibling.previousSibling,
          previousSibling.parentNode
        );
      } else {
        if (excludeTagName && xpath.includes(excludeTagName)) {
          const newXpath = xpath.split('/');
          newXpath.pop();

          return normalizeRangePath(
            rangeContainer,
            newXpath.join('/'),
            excludeTagName,
            offset,
            parentNode.previousSibling,
            parentNode.parentNode
          );
        } else {
          return {
            offset,
            xpath,
          };
        }
      }
    }

    const range = window.getSelection().getRangeAt(0);
    const start = normalizeRangePath(range.startContainer, fromRange(range, baseElement).start, excludeTagName);
    const end = normalizeRangePath(range.endContainer, fromRange(range, baseElement).end, excludeTagName);

    return {
      ranges: {
        start: start.xpath,
        startOffset: start.offset + range.startOffset,
        end: end.xpath,
        endOffset: end.offset + range.endOffset,
      },
    };
  }

  private onCreateAnnotation() {
    // Pushing to out of process to allow normalizeRange to work on updated dom state after the rendering microthread tick
    setTimeout(() => {
      const newAnnotation: AnnotationZoneComment = {
        id: null,
        text: null,
        annotation: {
          quote: document.getSelection().toString(),
          ...this.normalizeRange(document.getElementById(this.annotationContentId), this.annotationTagName),
        },
      };

      this.createAnnotation.emit(newAnnotation);
    });
  }

  private showAnnotationButton() {
    this.hideAnnotationButton();

    const annotationButton = document.createElement('span');
    annotationButton.id = this.annotationButtonId;
    annotationButton.className = 'si-button--annotation__create';
    annotationButton.innerHTML = `<button title="${
      this.addAnnotationTooltip ?? 'Add Annotation'
    }" mat-mini-fab="" color="primary" class="mat-focus-indicator mat-mini-fab mat-button-base mat-primary" ng-reflect-color="primary"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">create</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="false"></span><span class="mat-button-focus-overlay"></span></button>`;
    annotationButton.addEventListener('click', this.onCreateAnnotation.bind(this), false);

    const selectionRange = document.getSelection().getRangeAt(0).cloneRange();
    selectionRange.collapse(false);
    selectionRange.insertNode(annotationButton);
  }

  private hideAnnotationButton() {
    const annotationButton = document.getElementById(this.annotationButtonId);

    if (annotationButton) {
      annotationButton.removeEventListener('click', this.onCreateAnnotation, false);
      annotationButton.parentElement.removeChild(annotationButton);
    }
  }

  private stopBubble(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  private annotateText() {
    const sortedAnnotations = [...this.annotations];

    // Annotations need to be applied in the order they occur within text for nesting to properly trigger their respective events
    sortedAnnotations.sort((a, b) => {
      if (a.annotation.ranges.start > b.annotation.ranges.start) {
        return 1;
      } else if (a.annotation.ranges.start < b.annotation.ranges.start) {
        return -1;
      } else {
        if (a.annotation.ranges.startOffset > b.annotation.ranges.startOffset) {
          return 1;
        } else if (a.annotation.ranges.startOffset < b.annotation.ranges.startOffset) {
          return -1;
        }
      }

      return 0;
    });

    for (const comment of sortedAnnotations) {
      // Ignore annotations that don't have an identifier set as we required it for various functinality
      if (comment.id) {
        try {
          const start = comment.annotation.ranges.start;
          const startOffset = comment.annotation.ranges.startOffset;
          const end = comment.annotation.ranges.end;
          const endOffset = comment.annotation.ranges.endOffset;
          const range = toRange(start, startOffset, end, endOffset, document.getElementById(this.annotationContentId));

          this.highlighter.addClassApplier(
            this.rangy.createClassApplier(`si-mark--term__${comment.id}`, {
              elementTagName: this.annotationTagName,
              elementProperties: {
                onmouseup: (event: MouseEvent) => {
                  // Ensures that a click is not a result of nested selection inside this annotation
                  if (document.getSelection().toString().trim().length === 0) {
                    this.stopBubble(event);
                    this.loadAnnotation.emit({ currentAnnotation: comment, allAnnotations: this.sortedAnnotations });
                  }
                },
                onmouseover: (event: MouseEvent) => {
                  this.stopBubble(event);
                  this.toggleHoverState(comment.id, true);
                },
                onmouseout: (event: MouseEvent) => {
                  this.stopBubble(event);
                  this.toggleHoverState(comment.id, false);
                },
              },
            })
          );
          this.highlighter.highlightRanges(`si-mark--term__${comment.id}`, [range], {
            containerElement: document.getElementById(this.annotationContentId),
            exclusive: false,
          });
        } catch (e) {
          console.error('Error occured attempting to apply annotation object.', comment, e);
        }
      } else {
        console.warn(`Cound not apply annotation as 'id' property is invalid.`, comment);
      }
    }

    // Build the bookmarks on a short delay as the dom needs to be fully settled by the annotation rendering logic before we can parse correct offsetTop values
    setTimeout(() => {
      this.buildBookmarks();
    }, 500);
  }

  private unannotateText(triggerAnnotate = false) {
    // If using the htmlBuffer option, simply replace content with what was copied during the initial snapshot of unaltered content
    if (this.htmlBuffer) {
      if (
        document.getElementById(this.annotationContentId).innerText.length > 0 &&
        document.getElementById(this.annotationBufferId).innerText.length === 0
      ) {
        document.getElementById(this.annotationBufferId).innerHTML = document.getElementById(
          this.annotationContentId
        ).innerHTML;
      } else {
        document.getElementById(this.annotationContentId).innerHTML = document.getElementById(
          this.annotationBufferId
        ).innerHTML;
      }
    }
    // If not using htmlBuffer, being by surgically removing any selected classes to injected annotation tags
    else {
      this.removeAllSelectedAnnotations();
    }

    // Pushing removeAllHighlights to next macrothread is required to process cleanup of removeAllSelectedAnnotations first
    setTimeout(() => {
      if (triggerAnnotate) {
        // If using the htmlBuffer option we can just clear out the higlights as we purged them from the dom
        if (this.htmlBuffer) {
          this.highlighter.highlights = [];
        }
        // If not using the htmlBuffer option, request that Rangy remove all its injected annotation tags from the dom, ideally leaving a clean state that looks like original projection, but we know it can have issues sometimes and leave remnants (hence the addition of our htmlBuffer option)
        else {
          this.highlighter.removeAllHighlights();
        }

        // Pushing annotateText to next macrothread is required to process cleanup of removeAllHighlights first
        setTimeout(() => {
          this.annotateText();
        });
      }
    });
  }

  private addSelectedAnnotation(element: Element, isBookmark = false) {
    if (!isBookmark && !element.classList.contains('si-mark--annotation')) {
      element.classList.add('si-mark--annotation');
    }
    if (!element.classList.contains('si-state--selected')) {
      element.classList.add('si-state--selected');
    }
  }

  private removeSelectedAnnotation(element: Element, isBookmark = false) {
    if (!isBookmark && element.classList.contains('si-mark--annotation')) {
      element.classList.remove('si-mark--annotation');
    }
    if (element.classList.contains('si-state--selected')) {
      element.classList.remove('si-state--selected');
    }
  }

  private removeAllSelectedAnnotations() {
    for (const comment of this.annotations) {
      const annotationElements = document.getElementsByClassName(`si-mark--term__${comment.id}`);

      for (let i = 0; i < annotationElements.length; i++) {
        this.removeSelectedAnnotation(annotationElements[i]);
      }
    }
  }

  public onShowBookmark(bookmark: AnnotationZoneBookmark, isGroupMenuEvent = false) {
    if (!bookmark.isGroup && bookmark.id) {
      this.showingBookmarkId = bookmark.id;
      this.toggleHoverState(bookmark.id, true, isGroupMenuEvent);
    }
  }

  public onHideBookmark(bookmark: AnnotationZoneBookmark, isGroupMenuEvent = false) {
    if (!bookmark.isGroup && bookmark.id) {
      this.showingBookmarkId = null;
      this.toggleHoverState(bookmark.id, false, isGroupMenuEvent);
    }
  }

  public onClickBookmark(bookmark: AnnotationZoneBookmark) {
    if (!bookmark.isGroup && bookmark.id) {
      for (const comment of this.annotations) {
        if (comment.id === bookmark.id) {
          this.loadAnnotation.emit({ currentAnnotation: comment, allAnnotations: this.sortedAnnotations });
          break;
        }
      }
    }
  }

  public onShowGroupBookmark(bookmark: AnnotationZoneBookmark) {
    for (const groupBookmark of bookmark.groupBookmarks) {
      this.toggleHoverState(groupBookmark.id, true);
    }
  }

  public onHideGroupBookmark(bookmark: AnnotationZoneBookmark) {
    for (const groupBookmark of bookmark.groupBookmarks) {
      this.toggleHoverState(groupBookmark.id, false);
    }
  }

  private toggleHoverState(id: string, hover: boolean, isGroupMenuEvent = false) {
    const bookmarkElement = document.getElementById(this.annotationBookmarkIdBase + id);
    const annotationElements = document.getElementsByClassName(`si-mark--term__${id}`);

    // Suppress highlighting the bookmark group button if the event was triggered from a bookmark group menu
    if (!isGroupMenuEvent) {
      if (bookmarkElement?.childNodes[0]) {
        if (hover) {
          this.addSelectedAnnotation(bookmarkElement.childNodes[0] as Element, true);
        } else {
          this.removeSelectedAnnotation(bookmarkElement.childNodes[0] as Element, true);
        }
      } else {
        // Condition for resolving the parent bookmark for an annotation group and toggling it indirectly
        for (const bookmark of this.bookmarks) {
          let parentBookmarkId = null;

          if (bookmark.groupBookmarks) {
            for (const groupBookmark of bookmark.groupBookmarks) {
              if (groupBookmark.id === id) {
                parentBookmarkId = bookmark.id;
                break;
              }
            }

            if (parentBookmarkId) {
              const parentBookmarkElement = document.getElementById(this.annotationBookmarkIdBase + parentBookmarkId);

              if (parentBookmarkElement?.childNodes[0]) {
                if (hover) {
                  this.addSelectedAnnotation(parentBookmarkElement.childNodes[0] as Element, true);
                } else {
                  this.removeSelectedAnnotation(parentBookmarkElement.childNodes[0] as Element, true);
                }
              }

              break;
            }
          }
        }
      }
    }

    for (let i = 0; i < annotationElements.length; i++) {
      if (hover) {
        this.addSelectedAnnotation(annotationElements[i]);
      } else {
        this.removeSelectedAnnotation(annotationElements[i]);
      }
    }
  }

  private buildBookmarksThrottle() {
    // Handler for window resize event, we only trigger the rebuild upon sufficient debouce to prevent unnecessary processing
    if (this.buildBookmarksThrottleThread) {
      clearTimeout(this.buildBookmarksThrottleThread);
    }

    this.buildBookmarksThrottleThread = setTimeout(() => {
      this.buildBookmarks();
    }, 250);
  }

  private getOffsetTop(element: HTMLElement, offsetTop = 0): number {
    if (element.offsetParent.className.includes('si-annotated')) {
      return offsetTop;
    } else {
      return this.getOffsetTop(element.offsetParent as HTMLElement, offsetTop + element.offsetTop);
    }
  }

  private buildBookmarks() {
    const newBookmarks: AnnotationZoneBookmark[] = [];
    const bookmarkPositions = [];

    let bookmarkIndex = 1;

    // Generate a list of all annotations with their actual top/left offsets and snapped top position
    for (const comment of this.annotations) {
      const annotationElements = document.getElementsByClassName(`si-mark--term__${comment.id}`);

      if (annotationElements?.length > 0) {
        const offsetTop = this.getOffsetTop(annotationElements[0] as HTMLElement); // We need to calculate actual offsetTop recursively from this element up until we get to the offsetParent containing the 'si-annotated' class
        const offsetLeft = (annotationElements[0] as HTMLElement).offsetLeft;
        const top = Math.round(offsetTop / 32) * 32;

        bookmarkPositions.push({
          id: comment.id,
          text: comment.text,
          quote: comment.annotation.quote,
          top,
          offsetTop,
          offsetLeft,
        });
      }
    }

    // Sort the list of annotations by actual ascending top offset position and secondarily by left offset
    bookmarkPositions.sort((a, b) => {
      if (a.offsetTop > b.offsetTop) {
        return 1;
      } else if (a.offsetTop < b.offsetTop) {
        return -1;
      } else {
        if (a.offsetLeft > b.offsetLeft) {
          return 1;
        } else if (a.offsetLeft < b.offsetLeft) {
          return -1;
        }
      }
      return 0;
    });

    // Store a sorted annotations list for use in the loadAnnotation event
    this.sortedAnnotations = [];

    for (const bookmark of bookmarkPositions) {
      for (const annotation of this.annotations) {
        if (bookmark.id === annotation.id) {
          this.sortedAnnotations.push({ ...annotation });
          break;
        }
      }
    }

    // Build a new bookmarks array, grouping any annotations that share the same snapped top position
    for (const bookmark of bookmarkPositions) {
      const groupBookmarks = [];

      for (const groupBookmark of bookmarkPositions) {
        if (bookmark.top === groupBookmark.top) {
          groupBookmarks.push({
            id: groupBookmark.id,
            text: groupBookmark.text,
            quote: groupBookmark.quote,
          });
        }
      }

      if (groupBookmarks.length >= 2) {
        if (groupBookmarks[0].id === bookmark.id) {
          newBookmarks.push({
            id: randomUUID(), // Generate a unique id for group bookmarks so we can resolve it for highlighting effects
            isGroup: true,
            offset: `${bookmark.top}px`,
            groupBookmarks,
          });
        }
      } else {
        newBookmarks.push({
          id: bookmark.id,
          text: bookmark.text,
          quote: bookmark.quote,
          offset: `${bookmark.top}px`,
        });
      }
    }

    // Stamp bookmark index labels by their actual display order
    for (const bookmark of newBookmarks) {
      if (bookmark.isGroup) {
        let minLabel = null;
        let maxLabel = null;

        for (const groupBookmark of bookmark.groupBookmarks) {
          groupBookmark.label = `${bookmarkIndex++}`;

          if (!minLabel || groupBookmark.label < minLabel) {
            minLabel = groupBookmark.label;
          }
          if (!maxLabel || groupBookmark.label > minLabel) {
            maxLabel = groupBookmark.label;
          }
        }

        bookmark.label = `Annotation ${minLabel} - ${maxLabel}`;
      } else {
        bookmark.label = `${bookmarkIndex++}`;
      }
    }

    this.bookmarks = [...newBookmarks];
  }

  printAnnotations() {
    function printAnnotationDivider() {
      return `
        <tr>
          <td colspan="3" style="border-bottom: 1px solid #000000"></td>
        </tr>`;
    }

    function printAnnotationLine(annotation: AnnotationZoneBookmark) {
      return `
        <tr style="vertical-align: top">
          <td style="padding: 10px">${annotation.label}</td>
          <td style="padding: 10px; width: 50%">${annotation.text}</td>
          <td style="padding: 10px; width: 50%">${annotation.quote}</td>
        </tr>
        ${printAnnotationDivider()}`;
    }

    const printWindow = window.open('', '', 'height=640, width=480');

    printWindow.document.write(`
      <html>
        <body style="font-family: Roboto, 'Helvetica Neue', sans-serif; font-size: 16px;">
          <table style="width: 100%;">
            <tr>
              <th>#</th>
              <th>Comment</th>
              <th>Annotated Text</th>
            </tr>
            ${printAnnotationDivider()}`);

    for (const bookmark of this.bookmarks) {
      if (!bookmark.isGroup) {
        printWindow.document.write(printAnnotationLine(bookmark));
      } else {
        for (const groupBookmark of bookmark.groupBookmarks) {
          printWindow.document.write(printAnnotationLine(groupBookmark));
        }
      }
    }

    printWindow.document.write(`
          </table>
        </body>
      </html>`);

    printWindow.print();
    printWindow.window.close();

    this.printAnnotation.emit();
  }
}
