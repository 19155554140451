import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PartButtonComponent } from '../../parts/part-button/part-button.component';
import { overrideInputs } from '@sae/base';

export type ChipType =
  | 'regular'
  | 'basic'
  | 'basicparent'
  | 'closed'
  | 'content'
  | 'industry'
  | 'open'
  | 'plain'
  | 'ref'
  | 'reference'
  | 'role'
  | 'searchterm'
  | 'sector'
  | 'source'
  | 'status'
  | 'subtype'
  | 'tool'
  | 'topic'
  | 'queue';
export type ChipSize = 'normal' | 'medium' | 'minor' | 'tiny';

export interface ChipItem {
  attachChip?: ChipItem;
  buttonId?: string;
  classes?: string;
  hashUrl?: string;
  label?: string;
  materialIcon?: string;
  selected?: boolean;
  size?: ChipSize;
  suffixIcon?: string;
  type: ChipType;
  tooltip?: string;
  url?: string;
  removeable?: boolean;
  removeIcon?: string;
  removeTooltip?: string;
  removeUrl?: string;
}

@Component({
  selector: 'fs-comp-chip',
  templateUrl: './comp-chip.component.html',
  styleUrls: ['./comp-chip.component.scss'],
  imports: [CommonModule, RouterModule, PartButtonComponent],
  standalone: true,
})
export class CompChipComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: ChipItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() inList: boolean; // set to true if this chip is in a list for proper accessibility
  @Input() url: string | null;
  @Input() materialIcon: string;
  @Input() suffixIcon: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() type: ChipType = 'basic';
  @Input() classes = '';
  @Input() size: ChipSize = 'normal';
  @Input() selected: boolean;
  @Input() hashUrl: string | null;
  @Input() buttonId: string | null;
  @Input() removeable: boolean;
  @Input() removeIcon = 'cancel';
  @Input() removeTooltip = 'Remove';
  @Input() removeUrl = '';

  @Output() buttonClick = new EventEmitter<string>(); // Event emitted when component is in button mode (url is null)
  @Output() removeClick = new EventEmitter<string>(); // Event emitted when component is in button mode (url is null)

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }

  onButtonClick(label: string): void {
    this.buttonClick.emit(label);
  }

  onRemoveClick(label: string): void {
    this.removeClick.emit(label + ' ' + this.label);
  }
}
