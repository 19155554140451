import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ContentView } from '../../modules/detail/models/content.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SeoUtilityService {
  private canonicalLink: HTMLLinkElement;
  constructor(
    private title: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private dom: Document,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  setTitle(title: string): void {
    this.title.setTitle(title);
  }
  setMetaTags(title: string, description: string, keywords?: string): void {
    this.title.setTitle(title);
    if (keywords) {
      this.metaService.updateTag({ name: 'keywords', content: keywords });
    } else {
      this.metaService.removeTag('keywords');
    }
    this.metaService.updateTag({ name: 'title', content: title });
    this.metaService.updateTag({ name: 'description', content: description });
  }
  setCanonicalURL(content: ContentView): void {
    if (content) {
      let canonicalURL: string;
      if (
        content?.group === 'Standards' ||
        content?.subGroup === 'Technical Paper' ||
        content?.subGroup === 'Journal Article'
      ) {
        canonicalURL = content?.documentUris.platformUris.find((uri) => uri.platformName === 'SAE-MOBILUS')?.uri;
      } else if (content?.subGroup === 'Research Report' || content?.subGroup === 'Best Practice') {
        canonicalURL = content?.documentUris.platformUris.find((uri) => uri.platformName === 'SAE-WEBSITE')?.legacyUri;
      } else if (
        content?.group === 'Media' ||
        content?.group === 'Patents' ||
        content?.group === 'Reports' ||
        content?.subGroup === 'Magazine Article' ||
        content?.subGroup === 'Journal' ||
        content?.subGroup === 'Pre-Print Article'
      ) {
        canonicalURL = null;
      } else {
        canonicalURL = content?.documentUris.platformUris.find((uri) => uri.platformName === 'SAE-WEBSITE')?.legacyUri;
      }

      if (canonicalURL) {
        this.canonicalLink = this.dom.createElement('link');

        this.canonicalLink.setAttribute('rel', 'canonical');
        this.canonicalLink.setAttribute('href', canonicalURL);
        this.dom.head.appendChild(this.canonicalLink);
      }
    }
  }
  removeCanonicalURL(): void {
    // Remove Canonical Link from the DOM
    this.canonicalLink?.remove();
  }

  googleScholarize(tags: { name: string; value?: string }[]): void {
    tags.forEach((tag) => this.metaService.addTag({ name: tag.name, content: tag.value ?? undefined }, true));
  }

  clearAllSEOTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.removeCanonicalURL();
      const metaTags = Array.from(document.getElementsByTagName('meta')).filter((e) => e.name?.includes('citation_'));
      for (let i = 0; i < metaTags.length; i++) {
        this.metaService.removeTag(`name="${metaTags[i].name}"`);
      }
    }
  }
}
