import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { Store } from '@ngrx/store';
import { Comment, SaeHttpResponse } from '@sae/models';
import { EMPTY, catchError, finalize, switchMap, tap, throwError } from 'rxjs';
import { DetailsHelperService } from '../../../../services/details-helper.service';
import { AuthService } from '../../../../../../shared/state/auth.service';
import { CommentsService } from '../../../../../../shared/state/comments.service';
import { ContentService } from '../../../../../../shared/state/content.service';
import { RootActions } from '../../../../../../store';
import { AnnotationDialogComponent } from '@sae/components';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'mobi-annotation-view',
  templateUrl: './annotation-view.component.html',
})
export class AnnotationViewComponent implements OnInit {
  @Input() public contentId: number;
  @Output() public annotationsChanged = new EventEmitter<Partial<Comment>[]>();

  public loading = false;
  public error = false;

  public annotations: Partial<Comment>[] = [];
  public anchorId: string;

  constructor(
    private authService: AuthService,
    private commentsService: CommentsService,
    private contentService: ContentService,
    private detailsHelperService: DetailsHelperService,
    private dialog: MatDialog,
    private store: Store,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getAnnotations();
  }

  getAnnotations(): void {
    this.loading = true;
    this.error = false;
    this.anchorId = this.contentId
      .toString(16)
      .padStart(32, '0')
      .replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');

    const params = new HttpParams().set('userId', this.authService.getUserId()).set('anchor', this.anchorId);
    const getComments = this.commentsService.get({ params });
    const getAnnotations = this.contentService.getHTMLView(this.contentId).pipe(
      switchMap((html) => this.detailsHelperService.buildAndReplaceHTML(html, 'annotation-view', this.contentId)),
      switchMap(() => {
        return getComments.pipe(
          tap((resp: SaeHttpResponse<Comment>) => {
            this.annotations = resp.results.map((comment) => ({
              ...comment,
              annotation: {
                ...comment?.annotation,
                ranges: {
                  ...comment?.annotation?.ranges,
                  start: `/div[1]/mat-card[1]/div[1]/div[1]/div[1]${comment?.annotation?.ranges?.start}`,
                  end: `/div[1]/mat-card[1]/div[1]/div[1]/div[1]${comment?.annotation?.ranges?.end}`,
                },
              },
            }));
            this.annotationsChanged.emit(this.annotations);
          }),
          tap(() => this.store.dispatch(RootActions.usageTrackingEvent({ event: { eventType: 'view_annotations' } })))
        );
      })
    );

    getAnnotations
      .pipe(
        catchError((e) => {
          this.error = true;
          return throwError(() => e);
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  retryAfterError(): void {
    this.getAnnotations();
  }

  onCreateAnnotation(annotation: Comment): void {
    const dialogRef = this.dialog.open<AnnotationDialogComponent>(AnnotationDialogComponent, {
      data: {
        currentAnnotation: annotation,
        mode: 'add',
      },
    });
    const createSubmitted = dialogRef.componentInstance.createSubmitted
      .pipe(
        tap(() => (dialogRef.componentInstance.submitting = true)),
        switchMap((comment) =>
          this.commentsService
            .add({
              anchor: this.anchorId,
              text: comment.text,
              annotation: {
                ...comment?.annotation,
                ranges: {
                  ...comment?.annotation?.ranges,
                  start: comment?.annotation?.ranges?.start?.substring(40),
                  end: comment?.annotation?.ranges?.end?.substring(40),
                },
              },
              type: 'MOBILUS Annotation',
              applicationCode: 'mobilus',
            })
            .pipe(
              tap((resp: Comment) => {
                this.annotations = [
                  ...this.annotations,
                  {
                    ...resp,
                    annotation: {
                      ...resp?.annotation,
                      ranges: {
                        ...resp?.annotation?.ranges,
                        start: `/div[1]/mat-card[1]/div[1]/div[1]/div[1]${resp?.annotation?.ranges?.start}`,
                        end: `/div[1]/mat-card[1]/div[1]/div[1]/div[1]${resp?.annotation?.ranges?.end}`,
                      },
                    },
                  },
                ];
                this.annotationsChanged.emit(this.annotations);
                this.store.dispatch(
                  RootActions.usageTrackingEvent({
                    event: { eventType: 'create_annotation', contentIds: [this.contentId] },
                  })
                );
                dialogRef.close();
              }),
              catchError(() => {
                this.snackbar.open('Failed to create annotation', undefined, { duration: 4500 });
                dialogRef.componentInstance.submitting = false;
                return EMPTY;
              })
            )
        )
      )
      .subscribe();
    dialogRef.afterClosed().subscribe(() => {
      createSubmitted.unsubscribe();
    });
  }

  onLoadAnnotation(data): void {
    const dialogRef = this.dialog.open<AnnotationDialogComponent>(AnnotationDialogComponent, {
      data: { ...data, mode: 'edit' },
    });
    const editSubmitted = dialogRef.componentInstance.editSubmitted
      .pipe(
        tap(() => (dialogRef.componentInstance.submitting = true)),
        switchMap((comment) =>
          this.commentsService.update(comment.id, { text: comment.text }, { method: HttpMethod.PATCH }).pipe(
            tap(() => {
              const newAnnotations = [];
              for (const annotation of this.annotations) {
                if (annotation.id !== comment.id) {
                  newAnnotations.push(annotation);
                } else {
                  newAnnotations.push(comment);
                }
              }
              this.annotations = [...newAnnotations];
              this.annotationsChanged.emit(this.annotations);
              dialogRef.close();
            }),
            catchError(() => {
              this.snackbar.open('Failed to edit annotation', undefined, { duration: 4500 });
              dialogRef.componentInstance.submitting = false;
              return EMPTY;
            })
          )
        )
      )
      .subscribe();
    const deleteSubmitted = dialogRef.componentInstance.deleteSubmitted
      .pipe(
        tap(() => (dialogRef.componentInstance.submitting = true)),
        switchMap((comment) =>
          this.commentsService.delete(comment.id).pipe(
            tap(() => {
              const newAnnotations = [];

              for (const annotation of this.annotations) {
                if (annotation.id !== comment.id) {
                  newAnnotations.push(annotation);
                }
              }
              this.annotations = newAnnotations;
              this.annotationsChanged.emit(this.annotations);
              this.store.dispatch(
                RootActions.usageTrackingEvent({
                  event: { eventType: 'delete_annotation', contentIds: [this.contentId] },
                })
              );
              dialogRef.close();
            }),
            catchError(() => {
              this.snackbar.open('Failed to delete annotation', undefined, { duration: 4500 });
              dialogRef.componentInstance.submitting = false;
              return EMPTY;
            })
          )
        )
      )
      .subscribe();
    dialogRef.afterClosed().subscribe(() => {
      editSubmitted.unsubscribe();
      deleteSubmitted.unsubscribe();
    });
  }

  printAnnotation(): void {
    this.store.dispatch(
      RootActions.usageTrackingEvent({
        event: { eventType: 'print_annotation', contentIds: [this.contentId] },
      })
    );
  }

  clearAnnotations(): void {
    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData>(
      ConfirmationDialogComponent,
      {
        data: { message: 'Clear annotations?', title: 'Clear Annotations', icon: 'cancel' },
      }
    );

    const onNegativeAction = dialogRef.componentInstance.negativeAction.subscribe(() => {
      dialogRef.close();
    });

    const onPositiveAction = dialogRef.componentInstance.positiveAction
      .pipe(
        switchMap(() => this.commentsService.deleteUsersCommentsOnAnchor(this.authService.getUserId(), this.anchorId)),
        tap(() => {
          this.snackbar.open('Successfully cleared annotations', undefined, { duration: 4500 });
          dialogRef.close();
          this.annotations = [];
        }),
        catchError(() => {
          this.snackbar.open('Failed to clear annotations', undefined, { duration: 4500 });
          dialogRef.close();
          return EMPTY;
        })
      )
      .subscribe();

    dialogRef.afterClosed().subscribe(() => {
      onPositiveAction.unsubscribe();
      onNegativeAction.unsubscribe();
    });
  }
}
