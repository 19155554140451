import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { overrideInputs } from '@sae/base';

export interface ContainerContent {
  classes?: string;
  printNoShadow?: boolean;
  printTight?: boolean;
  relative?: boolean;
  shadow?: boolean;
  tight?: boolean;
  tightHorz?: boolean;
  tightVert?: boolean;
}

@Component({
  selector: 'fs-part-container',
  styleUrls: ['part-container.component.scss'],
  templateUrl: './part-container.component.html',
  standalone: true,
})
export class PartContainerComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: ContainerContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string;
  @Input() printNoShadow: boolean; // if shadow, don't print shadow when true
  @Input() printTight: boolean;
  @Input() relative = true; // if true, position is relative
  @Input() shadow: boolean;
  @Input() tight: boolean;
  @Input() tightHorz: boolean;
  @Input() tightVert: boolean;
  @Input() content: ContainerContent; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }
  }
}
