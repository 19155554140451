import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { withTransaction } from '@datorama/akita';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, catchError, finalize, first, forkJoin, map, Observable, of, Subject } from 'rxjs';
import { MathService } from '../../../shared/services/math.service';
import { SeoUtilityService } from '../../../shared/services/seo-utility.service';
import { AuthService } from '../../../shared/state/auth.service';
import { ContentService } from '../../../shared/state/content.service';
import { DigitalStandardsService } from '../../../shared/state/digital-standards.service';
import { ContentApiResponse, ContentView, Volume } from '../models/content.model';
import { AccessControlResult, DocumentAsset } from '../../../shared/models/api-models';
import { Asset } from '../../../shared/models/common-interfaces';
import { Store } from '@ngrx/store';
import { DownloadDocumentActions } from '../../../store/download-document';
import { addHours, isFuture } from 'date-fns';
import { EnhancedTreeNode } from '@sae/components';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { IEnvironmentConfigService } from '@sae/base';
import { MobilusProteusConfig } from '../../../env.config';

export type AccessLevel = 'FULL' | 'REQUEST' | 'METERED' | 'SUB_LOGIN' | 'EXHAUSTED' | 'NO_ADMINS' | 'USER_LOGIN';

@Injectable({
  providedIn: 'root',
})
export class DetailsHelperService {
  public redlineView$ = new Subject<string>();
  public viewMode$ = new BehaviorSubject<'Document' | 'Redlining' | 'Annotation'>('Document');
  public viewLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected contentService: ContentService,
    protected digitalStandardsService: DigitalStandardsService,
    @Inject(REMOTE_CONFIG_TOKEN) protected envConfigService: IEnvironmentConfigService,
    protected seoUtilityService: SeoUtilityService,
    protected router: Router,
    protected authService: AuthService,
    protected cookieService: CookieService,
    protected mathService: MathService,
    private store: Store
  ) {}

  buildAuthorsArray(
    contentApiResponse: ContentApiResponse
  ): { name: string; lnameFname: string; affiliation: string }[] {
    const authors = [];
    contentApiResponse.meta?.contribGroup?.forEach((cg) => {
      cg.contrib.forEach((c) => {
        authors.push({
          name: c?.fullName,
          lnameFname: c?.lnameFname,
          affiliation: c?.affiliation ?? cg.affiliation?.name,
        });
      });
    });
    return authors.filter((a) => !!a?.name);
  }

  getAdditionalDetails(cv: ContentView): Observable<ContentView> {
    this.contentService.setRouteLoading(true);
    const crossReferencesReq = this.contentService.getCrossReferences(cv.id);
    const recommendationsReq = this.contentService.getRecommendations(cv.id);
    const digitalStandardsReq = this.digitalStandardsService
      .get({
        params: new HttpParams().set('code', cv?.code),
      })
      .pipe(catchError(() => of([])));
    let title = cv.title;
    if (cv.subGroup === 'Journal Article') {
      title = cv?.relatedArticle?.journalTitle;
    }
    const journalBrxDataReq = title ? this.contentService.getJournalBrxData(title) : of([]);
    return forkJoin([
      crossReferencesReq,
      recommendationsReq,
      cv.group === 'Standard' ? digitalStandardsReq : of([]),
      cv.subGroup === 'Journal' || cv.subGroup === 'Journal Article' ? journalBrxDataReq : of([]),
    ]).pipe(
      withTransaction(([, , ds, journalBrxData]) => {
        this.contentService.setDigitalStandard(ds?.length ? ds[0] : null);
        const baseHref = this.envConfigService
          .envConfig<MobilusProteusConfig>()
          .enterpriseMenu.apps.find((app) => app?.name === 'SAE OnQue')?.href;
        let onQueLink = '';
        if (ds?.[0]) {
          onQueLink = `${baseHref}${ds[0]?.type === 'PART' ? 'parts/' : 'materials/'}${ds?.[0]?.code}`;
        }
        this.contentService.updateDetailsConfiguration({ detail_onque_link: onQueLink });

        this.contentService.setInMyLibrary(cv.bookmarks?.some((bookmark) => bookmark.folderName === 'My Favorites'));
      }),
      map(() => cv),
      finalize(() => this.contentService.setRouteLoading(false))
    );
  }

  buildContentView(contentApiResponse: ContentApiResponse): ContentView {
    if (!contentApiResponse) {
      throw Error('No API data');
    }
    this.buildContentFileFormat(contentApiResponse?.documentAssets);
    return {
      id: contentApiResponse?.meta?.cid,
      publicationId: contentApiResponse?.meta?.publicationId,
      alertId: contentApiResponse?.alertId,
      rootCode: contentApiResponse?.meta.rootCode,
      hasAccess: contentApiResponse?.accessProperties?.access,
      industryChips: contentApiResponse?.meta?.industrySectors?.map((sector) => sector?.name),
      subGroup: contentApiResponse?.meta?.contentGroup?.subGroup,
      // officialType: contentApiResponse?.meta?.contentType?.officialType,
      group: contentApiResponse?.meta?.contentGroup?.group,
      title: contentApiResponse?.meta?.title,
      code:
        contentApiResponse?.meta?.useExtendedCode === true && !!contentApiResponse?.meta?.extendedCode
          ? contentApiResponse?.meta?.extendedCode
          : contentApiResponse?.meta?.code,
      pubDate: contentApiResponse?.meta?.pubDate,
      dataSets: contentApiResponse?.documentAssets?.filter((asset) => asset.searchType === 'Dataset'),
      event: contentApiResponse?.meta?.conferences?.[0]?.name,
      abstract: contentApiResponse?.meta?.documentAbstract,
      topics: contentApiResponse?.taxonomy?.approved?.assignedTerms?.map((term) => term?.text),
      searchTerms: [],
      doiUri: contentApiResponse?.meta?.doi?.uri,
      doiVerified: contentApiResponse?.meta?.doi?.verified,
      issueNumber: contentApiResponse?.meta?.doi?.issueNumber,
      pageCount: contentApiResponse?.meta?.externalFile?.pageCount ?? 0,
      citation: contentApiResponse?.meta?.citations?.find((citation) => citation.style === 'Chicago')?.content,
      tableOfContents: [],
      citedBy: [],
      publisher: contentApiResponse?.meta?.publishers?.[0]?.name,
      publisherId: contentApiResponse?.meta?.publishers?.[0]?.pubId,
      language: contentApiResponse?.meta?.language?.name,
      references: contentApiResponse?.meta?.bibliography?.map((ref) => ref?.mixedCitation),
      contribGroups: contentApiResponse?.meta?.contribGroup,
      rationale: contentApiResponse?.meta?.rationale,
      standardAttributes: contentApiResponse?.standardAttributes,
      authors: this.buildAuthorsArray(contentApiResponse),
      affiliations: contentApiResponse?.meta?.contribGroup
        ?.map((cg) => cg?.affiliation?.name)
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((a) => !!a),
      standardStatus: contentApiResponse?.meta?.standardProperties?.status,
      meterDetails: contentApiResponse?.accessProperties?.meterDetails,
      relatedArticle: contentApiResponse?.meta?.relatedArticle?.[0],
      country: contentApiResponse?.meta?.publishers?.[0]?.address?.countryName,
      feature2d3d: contentApiResponse?.meta?.fileFormat?.some((file) => file.type === '3d' && file.exists === 'true'),
      featureAnnotate:
        contentApiResponse?.meta?.fileFormat?.some((ff) => ff?.type === 'xpub') &&
        contentApiResponse.accessProperties?.access,
      featureRedlined:
        contentApiResponse?.standardAttributes?.revisions?.length &&
        contentApiResponse.standardAttributes?.revisions.find(
          (revision) => revision.cid === contentApiResponse.meta.cid
        )?.hasXml, // needs to be implemented
      featureDataSets: !!contentApiResponse?.documentAssets?.filter((asset) => asset.searchType === 'Dataset')?.length,
      bookmarks: contentApiResponse?.bookmarks ?? [],
      documentAssets: contentApiResponse?.documentAssets ?? [],
      documentUris: contentApiResponse?.documentUris,
      accessProperties: contentApiResponse?.accessProperties,
      hasAnnotation: contentApiResponse?.hasAnnotation,
      isbn: contentApiResponse?.meta?.isbn?.find((isbn) => isbn.pubType === 'apub' && isbn.avlStat === 'Y')?.content,
      videoLength: contentApiResponse?.meta?.videoLength
        ? this.formatTime(contentApiResponse.meta.videoLength, true)
        : null,
      categories: contentApiResponse.meta.categories,
      magCode: contentApiResponse.meta.magCode,
      fulltextMediaUrl: contentApiResponse?.fulltextMediaUrl,
      previewMediaUrl: contentApiResponse?.previewMediaUrl,
      learningObjectives: contentApiResponse?.courseAttributes?.learningObjectives,
      audience: contentApiResponse?.courseAttributes?.audience,
      testimonials: contentApiResponse?.courseAttributes?.testimonials,
      prerequisites: contentApiResponse?.courseAttributes?.prerequisites,
      averageTimeSecs: contentApiResponse?.courseAttributes?.averageTimeSecs
        ? this.formatTime(contentApiResponse?.courseAttributes?.averageTimeSecs, false)
        : null,
      creditsCeus: contentApiResponse?.courseAttributes?.creditsCeus,
      outline: contentApiResponse?.courseAttributes?.outline,
      materials: contentApiResponse?.courseAttributes?.materials,
      requirements: contentApiResponse?.courseAttributes?.requirements,
      instructorBiography: contentApiResponse?.courseAttributes?.instructorBiography,
      partsConfiguratorUrl: contentApiResponse?.partsConfiguratorUrl,
      volumes: contentApiResponse?.volumes,
      journalSeoUri: contentApiResponse?.journalSeoUri,
      issn: contentApiResponse?.meta?.issn,
      conferences: contentApiResponse?.meta?.conferences,
      formatCode: contentApiResponse?.meta?.formatCode,
      collections: contentApiResponse?.collections,
    };
  }

  getRouteFromContentView(cv: ContentView): string {
    if (cv.group === 'Standard') {
      return `/standards/${cv.id}`;
    } else if (cv.subGroup === 'Technical Paper') {
      return `/technical-papers/${cv.id}`;
    } else if (cv.subGroup === 'Journal Article') {
      return `/journal-articles/${cv.id}`;
    } else if (cv.subGroup === 'Research Report') {
      return `/reports/${cv.id}`;
    } else if (cv.subGroup === 'Magazine Article') {
      return `/magazine-articles/${cv.id}`;
    } else if (cv.subGroup === 'Book') {
      return `/books/${cv.id}`;
    } else {
      return `/search`;
    }
  }

  establishRedlining(cid1: number, cid2: number): void {
    this.viewMode$.next('Redlining');
    this.router.navigate([this.router.url.split('#')[0]], { fragment: 'view', state: { test: 'Hello' } });
    this.viewLoading$.next(true);
    this.contentService
      .getRedlinedView(cid1, cid2)
      .pipe(
        first(),
        finalize(() => this.viewLoading$.next(false))
      )
      .subscribe((redlineHTML) => this.redlineView$.next(redlineHTML));
  }

  async makeImageRequest(src: string, contentId: number): Promise<Blob> {
    const pieces = src.split('/');
    const fileName = pieces[pieces.length - 1];
    const url = src.includes('sae_logo.png')
      ? 'https://wcm14.sae.org/site/binaries/content/gallery/brx-ng-template/logo/sae.svg'
      : `${
          this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
        }/v2/mobilus/content/${contentId}/images/${fileName}`;
    return fetch(url, {
      headers: {
        'X-Auth-Token': this.cookieService.get('X-Auth-Token'),
      },
    }).then((res) => res.blob());
  }

  async buildAndReplaceHTML(
    html: string,
    docId: string,
    contentId: number,
    compareAgainstId?: number
  ): Promise<unknown> {
    if (!html || html === 'Error retrieving document') {
      return;
    }
    const template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    const htmlviewWrapper = template.content.firstChild as HTMLDivElement;

    const imagesCollection = htmlviewWrapper.getElementsByTagName('img');

    const logo = Array.from(imagesCollection).find((image) => image.className.includes('logo'));
    if (logo) {
      logo.src = 'https://wcm14.sae.org/site/binaries/content/gallery/brx-ng-template/logo/sae.svg';
    }

    const imagesArray = Array.from(imagesCollection).filter((image) => !image.className.includes('logo'));
    return Promise.all(
      imagesArray.map((image) =>
        this.makeImageRequest(image.src, this.getImageContentId(image, contentId, compareAgainstId))
      )
    ).then((blobs) => {
      blobs.map((blob, i) => (imagesArray[i].src = URL.createObjectURL(blob)));
      const contentWrapper = document.getElementById(docId);
      this.mathService.render(contentWrapper, htmlviewWrapper.outerHTML);
    });
  }

  getImageContentId(image: HTMLImageElement, contentId: number, compareAgainstId: number): number {
    if (image.getAttribute('data-delta-revision')) {
      if (image.getAttribute('data-delta-revision') === 'B') {
        return compareAgainstId;
      }
    }
    return contentId;
  }

  getAccessLevel(accessProperties: AccessControlResult): AccessLevel {
    const hasAccess = accessProperties.access;
    const isMetered = accessProperties.meterDetails.metered;
    const mustRequestAccess = accessProperties.meterDetails.requestContentRestricted;
    const isAdmin = accessProperties.meterDetails.requestContentAdmin;
    const quantityRemaining = accessProperties.meterDetails.quantityRemaining;
    const quantity = accessProperties.meterDetails.quantity;

    if (!hasAccess) {
      return 'SUB_LOGIN';
    }

    const unmeteredWithAccess = hasAccess && !isMetered;
    const meteredWithAccess = hasAccess && isMetered && quantity === 0;

    if (unmeteredWithAccess || meteredWithAccess) {
      return 'FULL';
    }

    if (mustRequestAccess && !isAdmin) {
      return 'REQUEST';
    }

    const meterAvailable = quantity > 0 && quantityRemaining >= quantity;
    const meteredAdmin = isMetered && meterAvailable && mustRequestAccess && isAdmin;

    if (meterAvailable || meteredAdmin) {
      return 'METERED';
    }

    if (!meterAvailable) {
      return 'EXHAUSTED';
    }

    return 'SUB_LOGIN';
  }
  buildContentFileFormat(documentAssets: DocumentAsset[]): void {
    const fileFormatSearchTypes = new Map([
      ['Full Text PDF', 'PDF'],
      ['Full Text PRC', 'PRC'],
      ['Full Text EPUB', 'EPUB'],
      ['Compressed File ZIP', 'ZIP'],
      ['Word DOC', 'DOC'],
      ['Word DOCX', 'DOCX'],
      ['Excel XLS', 'XLS'],
      ['Excel XLSX', 'XLSX'],
    ]);

    const fileFormats = [];
    documentAssets?.forEach((asset) => {
      if (!fileFormatSearchTypes.get(asset.searchType)) return false;
      fileFormats.push({
        ...asset,
        id: asset.assetId,
        fileType: fileFormatSearchTypes.get(asset.searchType),
        filename: asset.fileName,
      });
    });
    this.contentService.setFileFormats(fileFormats as unknown as Asset[]);
    this.store.dispatch(DownloadDocumentActions.getAssetsSucceeded({ assets: fileFormats }));
  }

  formatTime(lengthInSecond: number, includeSeconds: boolean): string {
    if (includeSeconds) {
      return `${Math.floor(lengthInSecond / 3600)
        .toString()
        .padStart(2, '0')}:${Math.floor((lengthInSecond % 3600) / 60)
        .toString()
        .padStart(2, '0')}:${Math.floor((lengthInSecond % 3600) % 60)
        .toString()
        .padStart(2, '0')}`;
    } else {
      return `${Math.floor(lengthInSecond / 3600)
        .toString()
        .padStart(2, '0')}:${Math.floor((lengthInSecond % 3600) / 60)
        .toString()
        .padStart(2, '0')}`;
    }
  }

  mapJournalVolumeIssues(volumes: Volume[]): Volume[] {
    let journalVolumeIssueNodes: EnhancedTreeNode[];
    if (volumes) {
      const reversedVolumes = [...volumes];
      reversedVolumes.reverse();
      journalVolumeIssueNodes = reversedVolumes
        .map((volume: Volume) => {
          if (volume?.issues?.every((issue) => isFuture(new Date(issue.publicationDate).getTime()))) {
            return false;
          }
          const volumeYear = volume?.issues[0]
            ? `${addHours(new Date(volume?.issues[0]?.publicationDate), 12).getFullYear()}: `
            : '';
          return {
            name: `${volumeYear} Volume ${volume.volume}`,
            volume: volume.volume,
            id: `volume${volume.volume}`,
            children: volume?.issues
              .filter((issue) => !isFuture(new Date(issue.publicationDate).getTime()))
              .map((issue) => ({
                name: `Issue ${issue.issue}`,
                id: `volume${volume.volume}issue${issue.issue}`,
                issue: issue.issue,
              })),
          };
        })
        .filter((volume) => volume !== false) as EnhancedTreeNode[];
    }
    if (journalVolumeIssueNodes?.length) {
      this.contentService.addVolumeIssueNodes(journalVolumeIssueNodes);
    }
    return volumes;
  }
}
