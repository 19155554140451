<ng-container
  *ngIf="{
    content: content$ | async,
    config: config$ | async,
    fileFormats: fileFormats$ | async,
    loadingAssets: loadingAssets$ | async,
    canDirectlyDownload: canDirectlyDownload$ | async,
    subSessions: subSessions$ | async,
    hasBeenDownloaded: hasBeenDownloaded$ | async,
    isLoggedIn: isLoggedIn$ | async,
    inMyLibrary: inMyLibrary$ | async,
    journalBrxDetail: journalBrxDetail$ | async,
    isDownloadable: isDownloadable$ | async
  } as _"
>
  <header class="si-masthead si-masthead--record">
    <div class="si-masthead__m">
      <si-seo-chiplist
        class="si-component"
        [isMedium]="true"
        [isLong]="true"
        aria-label="Type"
        [chips]="chips$ | async"
      >
        <ng-container beforeChipsContent>
          <button
            mat-icon-button
            class="si-icon"
            [class.si-state--locked]="!_.content?.hasAccess"
            [matMenuTriggerFor]="standardOptions"
            color="primary"
            aria-label="Access"
            matTooltip="Access"
          >
            <mat-icon [attr.aria-hidden]="true">
              {{
                !_.content?.hasAccess
                  ? 'lock'
                  : _.content?.accessProperties?.openAccess === true
                  ? 'lock_open'
                  : 'verified_user'
              }}
            </mat-icon>
          </button>
          <mat-menu #standardOptions="matMenu" class="si-menu">
            <button
              *ngIf="_.canDirectlyDownload && _.isDownloadable"
              mat-menu-item
              [matMenuTriggerFor]="downloadMenu?.menu"
              class="si-button--detailed"
              (click)="getDownloadFileFormat(_.content?.id)"
            >
              <mat-icon
                class="si-icon"
                [color]="_.content?.hasAccess ? 'primary' : null"
                [class.si-state--locked]="!_.content?.hasAccess"
                [class.si-state--activated]="_.hasBeenDownloaded"
                aria-hidden="true"
              >
                download
              </mat-icon>
              <span>Download</span>
            </button>
            <button
              *ngIf="!_.canDirectlyDownload && _.isDownloadable"
              mat-menu-item
              class="si-button--detailed"
              (click)="openDownloadDialog(_.content, _.subSessions)"
            >
              <mat-icon
                class="si-icon"
                [color]="_.content?.hasAccess ? 'primary' : null"
                [class.si-state--locked]="!_.content?.hasAccess"
                aria-hidden="true"
              >
                download
              </mat-icon>
              <span>Download</span>
            </button>
            <button mat-menu-item class="si-button--detailed" [routerLink]="['./']" fragment="view">
              <mat-icon
                class="si-icon"
                [class.si-state--locked]="!_.content?.hasAccess"
                [class.si-state--activated]="_.content?.hasAccess"
                aria-hidden="true"
              >
                description
              </mat-icon>
              <span>View</span>
            </button>
          </mat-menu>
        </ng-container>
      </si-seo-chiplist>
      <span class="si-expanse"></span>
      <div class="si-masthead__a">
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </div>
    </div>
    <div *ngIf="_.config?.detail_masthead_title_with_code === true" class="si-masthead__h">
      <h1 class="si-title si-impact">
        {{ _.content?.code }}
        <span class="si-title__details" [innerHTML]="_.content?.title"></span>
      </h1>
    </div>
    <div *ngIf="_.config?.detail_masthead_title_with_code === false" class="si-masthead__h">
      <h1 class="si-title si-impact" [innerHTML]="_.content?.title"></h1>
    </div>
    <div *ngIf="_.config?.detail_masthead_subheading_type === 'status'" class="si-masthead__b">
      <h2 class="si-masthead__b__item si-subtitle" *ngIf="_.content?.standardAttributes?.revisionStatus">
        {{ _.content?.standardAttributes?.revisionStatus }}
      </h2>

      <span class="si-expanse"></span>
      <h2 class="si-masthead__b__item si-published">
        {{ _.content?.pubDate | formatDateToTextPipe: 'MM/dd/yyyy' }}
      </h2>
    </div>
    <div *ngIf="_.config?.detail_masthead_subheading_type === 'journal'" class="si-masthead__b">
      <mat-chip-list
        #chipList
        aria-label="Type"
        class="si-chiplist si-masthead__b__item"
        *ngIf="_.content?.relatedArticle?.journalTitle"
      >
        <mat-chip
          class="si-chip si-chip--role si-chip--medium si-chip--long"
          [title]="'Browse ' + _.content?.relatedArticle?.journalTitle + ' Content'"
        >
          <a [routerLink]="_.content?.journalSeoUri" [fragment]="'browse'">
            {{ _.content?.relatedArticle?.journalTitle }}
          </a>
        </mat-chip>
      </mat-chip-list>
      <h2 class="si-masthead__b__item si-subtitle">{{ _.content?.code }}</h2>
      <span class="si-expanse"></span>
      <h2 class="si-masthead__b__item si-published">{{ _.content?.pubDate | formatDateToTextPipe: 'MM/dd/yyyy' }}</h2>
    </div>
    <div *ngIf="_.config?.detail_masthead_subheading_type === 'code'" class="si-masthead__b">
      <h2 class="si-masthead__b__item si-subtitle">{{ _.content?.code }}</h2>
      <span class="si-expanse"></span>
      <h2 class="si-masthead__b__item si-published">{{ _.content?.pubDate | formatDateToTextPipe: 'MM/dd/yyyy' }}</h2>
    </div>
  </header>
  <ng-template #buttons>
    <button
      *ngIf="_.canDirectlyDownload && _.isDownloadable"
      mat-icon-button
      class="si-button si-icon"
      color="primary"
      matTooltip="Download"
      aria-label="Download"
      [matMenuTriggerFor]="downloadMenu?.menu"
      id="itemDnlBtn"
      (click)="getDownloadFileFormat(_.content?.id)"
      data-analytics="detail-page-masthead-download-btn"
    >
      <mat-icon aria-hidden="true" [class.si-state--activated]="_.hasBeenDownloaded">file_download</mat-icon>
    </button>
    <button
      *ngIf="!_.canDirectlyDownload && _.isDownloadable"
      mat-icon-button
      class="si-button si-icon si-state--locked"
      color="primary"
      matTooltip="Download"
      aria-label="Download"
      (click)="openDownloadDialog(_.content, _.subSessions)"
      id="itemDnlBtn"
      data-analytics="detail-page-masthead-download-btn"
    >
      <mat-icon aria-hidden="true">file_download</mat-icon>
    </button>
    <button
      mat-icon-button
      class="si-button si-icon"
      [class.si-state--activated]="_.inMyLibrary"
      [class.si-state--locked]="!_.isLoggedIn"
      [class.mat-elevation-z2]="_.inMyLibrary"
      color="primary"
      matTooltip="{{ _.inMyLibrary ? 'In My Library' : 'Add to My Library' }}"
      [attr.aria-label]="_.inMyLibrary ? 'In My Library' : 'Add to My Library'"
      (click)="openAddToLibraryDialog(_.content?.id)"
      id="itemAddLibraryBtn"
      data-analytics="detail-page-masthead-add-to-my-library-btn"
    >
      <mat-icon aria-hidden="true">{{ _.inMyLibrary ? 'library_add_check' : 'library_add' }}</mat-icon>
    </button>
    <button
      *ngIf="_.config?.detail_show_alert"
      mat-icon-button
      class="si-button si-icon"
      [class.si-state--activated]="!!_.content?.alertId"
      [class.mat-elevation-z2]="!!_.content?.alertId"
      color="primary"
      matTooltip="Set Alert"
      aria-label="Set Alert"
      (click)="toggleAlert(content, _.content?.alertId)"
      id="itemAlertBtn"
      data-analytics="detail-page-masthead-set-alert-btn"
    >
      <mat-icon aria-hidden="true">{{ !!content?.alertId ? 'notifications' : 'notifications_none' }}</mat-icon>
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="shareToolbarOpts"
      class="si-button si-button--more"
      matTooltip="Share"
      aria-label="Share"
      color="primary"
      (click)="$event.stopPropagation()"
      id="itemShareBtn"
      data-analytics="detail-page-masthead-share-btn"
    >
      <mat-icon aria-hidden="true">share</mat-icon>
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="viewToolbarOpts"
      class="si-button si-icon--more"
      matTooltip="Options"
      aria-label="Options"
      (click)="$event.stopPropagation(); getDownloadFileFormat(_.content?.id)"
      id="itemOptionBtn"
      data-analytics="detail-page-masthead-options-btn"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-template>

  <mat-menu #shareToolbarOpts="matMenu" class="si-menu">
    <a
      mat-menu-item
      (click)="openMailApp(content?.contentType, content?.code, content?.title)"
      class="si-button--detailed"
      data-analytics="item-detail-verlipsis-option-share-email"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">email</mat-icon>
      <span>Email</span>
    </a>
    <button
      (click)="openClipboardSnackbar()"
      mat-menu-item
      [cdkCopyToClipboard]="getCurrentUrl()"
      class="si-button--detailed"
      data-analytics="item-detail-verlipsis-option-share-url"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">content_copy</mat-icon>
      <span>Copy URL</span>
    </button>
  </mat-menu>

  <mat-menu #viewToolbarOpts="matMenu" class="si-menu">
    <button
      mat-menu-item
      class="si-button--detailed"
      [matMenuTriggerFor]="exportDownloadMenu"
      data-analytics="item-detail-verlipsis-option-export"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">exit_to_app</mat-icon>
      <span>Export</span>
    </button>
    <button
      mat-menu-item
      class="si-button--detailed"
      (click)="print(_.content?.id)"
      data-analytics="item-detail-verlipsis-option-print"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">print</mat-icon>
      <span>Print</span>
    </button>
    <button
      mat-menu-item
      class="si-button--detailed"
      (click)="openAddToLibraryDialog(_.content?.id, true)"
      matTooltip="Add to Shared Library"
      attr.aria-label="Add to Shared Library"
      data-analytics="item-detail-verlipsis-option-add-shared"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">group</mat-icon>
      <span>Add to shared</span>
    </button>
    <button
      mat-menu-item
      class="si-button--detailed"
      (click)="openAllEvent$.next()"
      data-analytics="item-detail-verlipsis-option-expand"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">unfold_more</mat-icon>
      <span>Expand All</span>
    </button>
    <button
      mat-menu-item
      class="si-button--detailed"
      (click)="closeAllEvent$.next()"
      data-analytics="item-detail-verlipsis-option-collapse"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">unfold_less</mat-icon>
      <span>Collapse All</span>
    </button>
    <mat-divider class="si-divider"></mat-divider>
    <button
      *ngIf="_.canDirectlyDownload && _.isDownloadable"
      mat-menu-item
      [matMenuTriggerFor]="downloadMenu?.menu"
      class="si-button--detailed"
      (click)="getDownloadFileFormat(_.content?.id)"
      data-analytics="item-detail-verlipsis-option-download"
    >
      <mat-icon
        class="si-tool-icon"
        [class.si-state--activated]="_.hasBeenDownloaded"
        color="primary"
        aria-hidden="true"
      >
        file_download
      </mat-icon>
      <span>Download</span>
    </button>
    <button
      *ngIf="!_.canDirectlyDownload && _.isDownloadable"
      mat-menu-item
      class="si-button--detailed"
      (click)="openDownloadDialog(_.content, _.subSessions)"
      data-analytics="item-detail-verlipsis-option-download"
    >
      <mat-icon class="si-tool-icon" aria-hidden="true">file_download</mat-icon>
      <span>Download</span>
    </button>
    <button
      mat-menu-item
      class="si-button--detailed"
      (click)="openAddToLibraryDialog(_.content?.id)"
      matTooltip="{{ _.inMyLibrary ? 'In My Library' : 'Add to My Library' }}"
      aria-label="{{ _.inMyLibrary ? 'In My Library' : 'Add to My Library' }}"
      data-analytics="item-detail-verlipsis-option-add-library"
    >
      <mat-icon class="si-tool-icon" color="primary" [class.si-state--activated]="_.inMyLibrary" aria-hidden="true">
        {{ _.inMyLibrary ? 'library_add_check' : 'library_add' }}
      </mat-icon>
      <span>{{ _.inMyLibrary ? 'Added to My Library' : 'Add to My Library' }}</span>
    </button>
    <ng-container *ngIf="_.config?.detail_show_alert">
      <button
        mat-menu-item
        class="si-button--detailed"
        *ngIf="!_.content?.alertId; else removeAlert"
        (click)="toggleAlert(content, null)"
        data-analytics="item-detail-verlipsis-option-toggle-alert"
      >
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">notifications_none</mat-icon>
        <span>Set Alert</span>
      </button>
      <ng-template #removeAlert>
        <button
          mat-menu-item
          class="si-button--detailed"
          (click)="toggleAlert(content, _.content?.alertId)"
          data-analytics="item-detail-verlipsis-option-toggle-alert"
        >
          <mat-icon
            class="si-tool-icon"
            [class.si-state--activated]="!!_.content?.alertId"
            color="primary"
            aria-hidden="true"
          >
            notifications
          </mat-icon>
          <span>Alert Set</span>
        </button>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="_.config?.detail_show_submit_manuscript && _.journalBrxDetail?.manuscriptUrl">
      <a [href]="_.journalBrxDetail?.manuscriptUrl" mat-menu-item class="si-button--detailed" target="_blank">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">send</mat-icon>
        <span>Submit Manuscript</span>
      </a>
    </ng-container>

    <button
      mat-menu-item
      class="si-button--detailed"
      [matMenuTriggerFor]="shareToolbarOpts"
      data-analytics="item-detail-verlipsis-option-share"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">share</mat-icon>
      <span>Share</span>
    </button>
  </mat-menu>
  <mat-menu #exportDownloadMenu="matMenu" class="si-menu">
    <mat-label class="si-label">Citation</mat-label>
    <ng-container *ngFor="let option of citationOpts">
      <mat-label class="si-label" *ngIf="option.label">{{ option.label }}</mat-label>
      <button
        mat-menu-item
        class="si-button--detailed"
        (click)="selectedOption(option)"
        [disabled]="option.disabled ?? false"
        [attr.data-analytics]="option?.key ? 'item-detail-verlipsis-option-export-' + option.key : null"
      >
        <mat-icon [color]="option.color ?? 'primary'" aria-hidden="true">{{ option.icon }}</mat-icon>
        <span>{{ option.name }}</span>
      </button>
      <mat-divider class="si-divider" *ngIf="option.divider"></mat-divider>
    </ng-container>
    <button
      mat-menu-item
      class="si-button--detailed"
      (click)="openDialog('', 'downloadMetadata', 'Export Metadata')"
      data-analytics="item-detail-verlipsis-option-export-xlsx"
    >
      <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">exit_to_app</mat-icon>
      <span>Export Metadata (XLSX)</span>
    </button>
  </mat-menu>

  <mobi-download-menu
    #downloadMenu="downloadMenu"
    [assets]="_.fileFormats"
    [loading]="_.loadingAssets"
    [hasAccess]="_.content?.hasAccess"
    (retry)="retryGettingAssets(_.content?.id)"
    (fileDownloaded)="fileDownloaded(_.content?.id)"
  ></mobi-download-menu>
</ng-container>
