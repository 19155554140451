import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CRITERIA_CONDITION_MAP,
  DATE_RANGE_MAP,
  GroupFormModel,
  RowFormModel,
  TransformedGroup,
  TransformedRow,
} from '@sae/models';

@Injectable({
  providedIn: 'root',
})
export class CriteriaWizardService {
  constructor(private fb: FormBuilder) {}

  createCriteriaRow(): FormGroup {
    const rowModel: RowFormModel = {
      fieldName: '',
      label: '',
      condition: '',
      type: '',
      value: null,
      valueLabel: null,
      id: '',
    };
    return this.fb.group({
      fieldName: [rowModel.fieldName, Validators.required],
      condition: [rowModel.condition, Validators.required],
      type: [rowModel.type],
      label: [rowModel.label],
      value: [rowModel.value, Validators.required],
      valueLabel: [rowModel.valueLabel],
      id: [rowModel.id],
    });
  }

  createCriteriaGroup(createDefaultRow: boolean): FormGroup {
    const groupModel: GroupFormModel = {
      condition: 'all',
      rows: this.fb.array(createDefaultRow ? [this.createCriteriaRow()] : []),
    };
    return this.fb.group(groupModel);
  }
}

function getRangeType(condition: string): string {
  return DATE_RANGE_MAP[condition];
}
function getSearchType(condition: string): string {
  return condition.includes('RANGE') ? 'RANGE' : condition;
}

function negatedGroup(r: RowFormModel): TransformedGroup {
  return {
    groupType: 'ADV',
    operator: 'NOT',
    query: [
      {
        field: { name: r.fieldName },
        label: r.label,
        searchType: r.condition.includes('KEYWORD') ? 'KEYWORD' : 'TEXT',
        values: [r.value],
        valueLabels: [r.valueLabel],
        id: r.id,
      },
    ],
  };
}

function isNegativeCondition(condition: string): boolean {
  return condition.includes('NOT');
}

function transformRow(child: RowFormModel): TransformedRow | TransformedGroup {
  if (isNegativeCondition(child.condition)) {
    return negatedGroup(child);
  }

  const transformedRow: TransformedRow = {
    field: { name: child.fieldName },
    label: child.label,
    searchType: getSearchType(child.condition),
    values: Array.isArray(child.value) ? child.value : [child.value],
    valueLabels: Array.isArray(child.valueLabel) ? child.valueLabel : [child.valueLabel],
    id: child.id,
  };

  if (transformedRow.searchType === 'RANGE') {
    transformedRow.rangeOperator = getRangeType(child.condition);
  }

  return transformedRow;
}

export function transformGroup(group: GroupFormModel): TransformedGroup | undefined {
  if (!group) {
    return undefined;
  }

  const transformedGroup: TransformedGroup = {
    groupType: 'ADV',
    operator: group.condition ? CRITERIA_CONDITION_MAP[group.condition] : '',
    query: [],
  };

  // NOTE: this cast to any is required or FormArray wil complain about not being iterable.
  // console.log(group.rows) to confirm it is an array of Rows or Group form models before the cast.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const children = group.rows as any;
  for (const rowOrGroupControl of children) {
    if (childIsRow(rowOrGroupControl)) {
      const rowFormModel = rowOrGroupControl as RowFormModel;
      const transformedRow = transformRow(rowFormModel);
      transformedGroup.query.push(transformedRow);
    } else {
      const groupFormModel: GroupFormModel = rowOrGroupControl as GroupFormModel;
      const transformedChildGroup = transformGroup(groupFormModel);
      if (transformedChildGroup) {
        transformedGroup.query.push(transformedChildGroup);
      }
    }
  }

  return transformedGroup;
}

function childIsRow(child: RowFormModel | GroupFormModel): boolean {
  return 'fieldName' in child;
}
