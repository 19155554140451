<div
  #listbox
  (click)="onClick($event)"
  (focus)="onFocus()"
  class="si-listbox"
  [attr.aria-disabled]="hasChildren && role ? false : null"
  [attr.aria-label]="hasChildren && role ? label : null"
  [attr.aria-invalid]="hasChildren && role ? false : null"
  [attr.aria-multiselectable]="multiSelectable"
  [attr.aria-orientation]="orientation"
  [attr.id]="id"
  [attr.role]="hasChildren ? role : null"
  [attr.tabindex]="role ? (hasChildren && role ? '0' : '-1') : null"
  [attr.title]="tooltip"
>
  <ng-content></ng-content>
</div>
