import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ShoppingCartDetailsItem } from '../../../entities/cart/shopping-cart/shopping-cart.component';
import { MoreOption } from '@sae/models';

export interface CartCardDeleteItemEvent {
  item: ShoppingCartDetailsItem;
  rootItem: ShoppingCartDetailsItem;
}

export interface CartCardQuantityChangeEvent {
  item: ShoppingCartDetailsItem;
  newQuantity: number;
}

export interface CartCardOverQuantityRequestedEvent {
  item: ShoppingCartDetailsItem;
}

export interface CartCardMenuOptionSelectedEvent {
  item: ShoppingCartDetailsItem;
  option: string;
}

@Component({
  selector: 'si-cart-card',
  templateUrl: './cart-card.component.html',
})
export class CartCardComponent implements OnInit {
  @Input() item: ShoppingCartDetailsItem; // The main configuration state of the item being displayed in the card
  @Input() state: 'summary' | 'checkout' | 'complete' = 'summary'; // Specifies the main display state of the panel
  @Input() maxQuantity = 5; // Sets the maximum selectable quantity for all items in the cart, however an individual cart item can specify a lower number (for cases when there is less than this in stock)
  @Input() zeroQuantityMessage: string | null = '0 (Delete)'; // Specifies the label for the 0 quantity option, however if null simply uses the 0 number
  @Input() overQuantityMessage: string | null = '6 + (Contact Sales)'; // If not null, adds a final option to the quantity selection with the provided label, when selected triggers an event which can be used for handling

  // The inputs below are useful for localizing the currency display, defaults represent pipe default for en-US region
  @Input() currencyCode = 'USD'; // currencyCode value for the CurrencyPipe
  @Input() currencyDisplay = 'symbol'; // display value for the CurrencyPipe
  @Input() currencyDigitsInfo = '1.2-2'; // digitsInfo value for the CurrencyPipe
  @Input() currencyLocale = 'en-US'; // locale value for the CurrencyPipe (NOTE: If this is changed, ensure the locale data for the LOCALE_ID is imported into the app.)

  @Output() deleteItem = new EventEmitter<CartCardDeleteItemEvent>(); // Event triggered when an item's quantity is desired to be deleted
  @Output() quantityChange = new EventEmitter<CartCardQuantityChangeEvent>(); // Event triggered when an item's quantity has changed and is not 0 nor over quantity
  @Output() overQuantityRequested = new EventEmitter<CartCardOverQuantityRequestedEvent>(); // Event triggered when the item's quantity has changed to the optionally specified over quantity option
  @Output() menuOptionSelected = new EventEmitter<CartCardMenuOptionSelectedEvent>(); // Event triggered when the item's quantity has changed to the optionally specified over quantity option

  @ViewChild('quantitySelect') quantitySelect: MatSelect;

  _quantityOptions: string[] = [];
  _additionalOptions: MoreOption[] = [];

  ngOnInit() {
    this._quantityOptions.push(this.zeroQuantityMessage ?? '0');

    for (let i = 1; i <= this.maxQuantity; i++) {
      this._quantityOptions.push(`${i}`);
    }

    if (this.overQuantityMessage) {
      this._quantityOptions.push(this.overQuantityMessage);
    }

    this._additionalOptions.push({
      name: 'Delete',
      icon: 'delete',
    });
  }

  onQuantityChange(option: string, previous: string) {
    // Set the visual state back to the previous value as we want consumers to react to quantity events and update inputs as a response
    if (this.quantitySelect) {
      this.quantitySelect.value = previous;
    }

    if ((this.zeroQuantityMessage && option === this.zeroQuantityMessage) || option === '0') {
      this.deleteItem.emit({ item: this.item, rootItem: this.item });
    } else if (this.overQuantityMessage && option === this.overQuantityMessage) {
      this.overQuantityRequested.emit({ item: this.item });
    } else {
      this.quantityChange.emit({ item: this.item, newQuantity: +option });
    }
  }

  onMenuOptionSelected(option: string, item: ShoppingCartDetailsItem) {
    if (option === 'Delete') {
      this.deleteItem.emit({ item, rootItem: this.item });
    } else {
      this.menuOptionSelected.emit({ item: this.item, option });
    }
  }
}
