import { HttpErrorResponse } from '@angular/common/http';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Alert } from '../../shared/models/alerts.model';
import { MobilusSearchResponse } from '../../shared/models/api-models';
import { UISearchFilters } from '../../shared/models/common-interfaces';
import { Term } from '../../shared/models/publication.model';
import { SearchResultEntry } from '../../shared/models/search-result.model';
import { FragmentData } from '../../shared/services/fragment.service';
import { BrowseByConfig, BrowseJournal } from './browse.models';

export const search = createAction('[Browse Page] Search', props<{ context: 'main_search' | 'search' }>());
export const searchSuccess = createAction('[Browse Page] Search Success', props<{ resp: MobilusSearchResponse }>());
export const searchFail = createAction('[Browse Page] Search Fail', props<{ error: HttpErrorResponse }>());

export const setBrowseConfig = createAction(
  '[Browse Page] Set Config',
  props<{
    config: BrowseByConfig;
    fragmentData: FragmentData;
    preserve?: boolean;
    context?: 'search' | 'main_search';
  }>()
);
export const changePagination = createAction('[Browse Page] Change Pagination', props<{ event: PageEvent }>());
export const changeSearchTerm = createAction('[Browse Page] Change Search Term', props<{ searchTerm: string }>());
export const changeSortParams = createAction('[Browse Page] Change Sort Params', props<{ sortParams: Sort }>());
export const saveScrollPosition = createAction('[Browse Page] Save Scroll Position', props<{ scrollTop: number }>());
// export const setAlert = createAction('[Browse Page] Create Alert', props<{ searchResult: SearchResultEntry }>());
// export const setAlertSuccess = createAction(
//   '[Alerts API] Create Alert Succeeded',
//   props<{ alert: Alert; searchResult: SearchResultEntry }>()
// );
// export const setAlertFailure = createAction('[Alerts API] Create Alert Failed', props<{ error: HttpErrorResponse }>());
// export const removeAlert = createAction('[Browse Page] Remove Alert', props<{ searchResult: SearchResultEntry }>());
// export const removeAlertSuccess = createAction(
//   '[Alerts API] Remove Alert Succeeded',
//   props<{ searchResult: SearchResultEntry }>()
// );
// export const removeAlertFailed = createAction(
//   '[Alerts API] Remove Alert Failed',
//   props<{ error: HttpErrorResponse }>()
// );
export const getTopics = createAction(
  '[Browse Page] Get Topics',
  props<{
    depth?: number;
    uri?: string;
    config: BrowseByConfig;
    preserve: boolean;
    fragmentData: FragmentData;
  }>()
);
export const getTopicsSuccess = createAction(
  '[Classification API] Get Topics Succeeded',
  props<{ topics: Term[]; config: BrowseByConfig; preserve: boolean; fragmentData: FragmentData }>()
);
export const getTopicsFailure = createAction('[Classification API] Get Topics Failed');

export const getJournals = createAction(
  '[Browse Page] Get Journals',
  props<{
    config: BrowseByConfig;
    preserve: boolean;
    fragmentData: FragmentData;
  }>()
);
export const getJournalsSuccess = createAction(
  '[Journal API] Get Journals Succeeded',
  props<{
    journals: BrowseJournal[];

    resp: MobilusSearchResponse;
  }>()
);
export const getJournalsFailure = createAction(
  '[Journal API] Get Journals Failed',
  props<{ error: HttpErrorResponse }>()
);
export const getJournalBrxDataSuccess = createAction(
  '[Journal API] Get Journals Brx Data Succeeded',
  props<{ journals: BrowseJournal[] }>()
);

export const getSpecificTopics = createAction(
  '[Browse Page] Get Specific Topics',
  props<{ uriText: string; config: BrowseByConfig; preserve: boolean; fragmentData: FragmentData }>()
);
export const getSpecificTopicsSuccess = createAction(
  '[Classification API] Get Specific Topics Succeeded',
  props<{
    topics: Term[];
    parentTerm: Term;
    currentTerm: Term;
    config: BrowseByConfig;
    preserve: boolean;
    fragmentData: FragmentData;
  }>()
);
export const getSpecificTopicsFailure = createAction('[Classification API] Get Specific Topics Failed');

export const subscriptionChanged = createAction('[Browse Page] Subscription Changed');

export const initialSearch = createAction(
  '[Browse Page] Initial Search',
  props<{ searchTerm: string; filters: UISearchFilters; customFilters: UISearchFilters }>()
);

export const setFragmentData = createAction(
  '[Browse Page] Set Fragment Data',
  props<{ searchTerm: string; filters: UISearchFilters }>()
);

export const setFilters = createAction(
  '[Browse Page] Set Filters',
  props<{ filters: UISearchFilters; customFilters: UISearchFilters }>()
);
export const applyFilter = createAction('[Browse Page] Apply Filter', props<{ fieldName: string; value: string }>());
export const applyPubDateFilter = createAction(
  '[Browse Page] Apply Pub Date Filter',
  props<{ to: Date; from: Date }>()
);
export const removeFilter = createAction('[Browse Page] Remove Filter', props<{ fieldName: string; value: string }>());
export const removePubDateFilter = createAction('[Browse Page] Remove Pub Date Filter');
export const expandAllFacets = createAction('[Browse Page] Expand All Facets');
export const collapseAllFacets = createAction('[Browse Page] Collapse All Facets');
export const clearPreservedFacet = createAction('[Browse Page] Clear Preserved Facet');
export const showMoreValues = createAction('[Browse Page] Show More Values For Facet', props<{ fieldName: string }>());

export const applyCustomFilter = createAction(
  '[Browse Page] Apply Customes Filter',
  props<{ fieldName: string; value: string }>()
);
export const removeCustomFilter = createAction(
  '[Browse Page] Remove Customes Filter',
  props<{ fieldName: string; value: string }>()
);

export const setAlert = createAction('[Browse Page] Set Alert', props<{ rootCode: string; alertId: string }>());
export const removeAlert = createAction('[Browse Page] Remove Alert', props<{ alertId: string }>());
