import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface ConfirmationDialogData {
  message: string;
  title?: string;
  icon?: string;
  positiveText?: string;
  negativeText?: string;
}

@Component({
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  @Output() positiveAction = new EventEmitter();
  @Output() negativeAction = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}
}
