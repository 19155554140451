import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { map, Observable } from 'rxjs';

const urlsToError = [];

export class MockHttpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<object>) => {
        if (event instanceof HttpResponse && event.body && urlsToError.some((url) => url === event.url)) {
          throw new HttpErrorResponse({
            error: 'your error',
            headers: event.headers,
            status: 500,
            statusText: 'Warning',
            url: event.url,
          });
        }
        return event;
      })
    );
  }
}
