/* eslint-disable @nx/workspace-no-reassign-inputs */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PartButtonComponent } from '../../parts/part-button/part-button.component';
import { PartIconComponent } from '../../parts/part-icon/part-icon.component';
import { PartUtilMenuComponent } from '../../parts/part-util-menu/part-util-menu.component';
import { AvatarContent } from '../comp-avatar/comp-avatar.component';
import { overrideInputs } from '@sae/base';

export type MenuOptionType = '' | 'detail' | 'option' | 'selector';

export interface MenuOption {
  avatar?: AvatarContent;
  buttonId?: string;
  classes?: string;
  disabled?: boolean;
  hide?: boolean;
  label?: string;
  materialIcon?: string;
  selected?: boolean;
  subMenu?: MenuOption[];
  stopPropagation?: boolean;
  tooltip?: string;
  type?: MenuOptionType;
  url?: string;
  value?: string | number;
}

@Component({
  selector: 'fs-comp-menu-option',
  styleUrls: ['comp-menu-option.component.scss'],
  templateUrl: './comp-menu-option.component.html',
  imports: [CommonModule, RouterModule, PartButtonComponent, PartIconComponent, PartUtilMenuComponent],
  standalone: true,
})
export class CompMenuOptionComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: MenuOption;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() avatar: AvatarContent;
  @Input() buttonId: string;
  @Input() classes = '';
  @Input() disabled = false;
  @Input() label = '';
  @Input() materialIcon = '';
  @Input() selected = false;
  @Input() stopPropagation = false;
  @Input() type: MenuOptionType = 'detail';
  @Input() tooltip = '';
  @Input() url = '';
  @Input() option: MenuOption; // [DEPRECATED - Will be removed] Used instead of other inputs
  @Input() inMenu = true; // Disable to represent an example outside of a menu
  @Input() overrideSelected: boolean; // Used to override the selected state of the option
  @Input() overrideType: MenuOptionType; // Used to override the type of the option

  @Output() buttonClick = new EventEmitter<string>(); // Event emitted when component is in button mode (url is null)

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['option']) {
      overrideInputs(this, this.option);
    }

    this.updateOptions();
  }

  public updateOptions(): void {
    if (this.overrideType) {
      this.type = this.overrideType;
    }

    if (this.overrideSelected) {
      this.selected = this.overrideSelected;
    }

    if (this.type === 'selector') {
      this.classes += ' si-selector__option';
      this.materialIcon = 'check';
      this.tooltip = (this.selected ? 'Selected ' : 'Not Selected ') + this.tooltip;
    }
  }

  public onButtonClick(label: string): void {
    this.buttonClick.emit(label);
  }
}
