import { CriteriaGroup } from './criterion.model';

export const APPLICATION_NAME = 'mobilus';

export declare type SearchContext = 'main_search' | 'other_search';

export declare type Operators = '' | 'OR' | 'AND' | 'NOT';

export interface SearchFacet {
  displayName: string;
  fieldName: string;
  values: FacetValue[];
}

export interface FacetValue {
  name: string;
  count: string;
}

export interface Query {
  searchType: 'TEXT' | 'KEYWORD' | 'RANGE' | 'SPECIAL' | 'PREFIX';
  rangeOperator?: 'WITHIN' | 'GTE' | 'LTE' | 'GT' | 'LT';
  field: Field;
  values: Array<string>;
}

export interface Field {
  id?: string;
  name: string;
  dataType?: string;
}

export interface Application {
  context?: string;
  name?: string;
  resultSetId: string;
}

export interface SearchBody {
  application: Application;
  criteria: CriteriaGroup[];
  limit: number;
  offset: number;
  order: string;
  sortBy: string;
}

export enum BrowseByOptions {
  GV = 'GV',
  AS = 'AS',
}

export function fromSearchTerm(term: string, operator: Operators): CriteriaGroup {
  return {
    groupType: 'ADV',
    operator,
    query: [{ searchType: 'TEXT', field: { name: 'fulltextPlusMeta' }, values: [term] }],
  };
}

export function fromSearchCode(code: string, operator: Operators): CriteriaGroup {
  return { groupType: 'ADV', operator, query: [{ searchType: 'KEYWORD', field: { name: 'code' }, values: [code] }] };
}

export function formBrowseCategoryURI(value: string, operator: Operators, fieldName: string): CriteriaGroup {
  return {
    groupType: 'ADV',
    operator,
    query: [{ searchType: 'KEYWORD', field: { name: fieldName }, values: [value] }],
  };
}

export function fromQuery(query: Query, operator: Operators): CriteriaGroup {
  return {
    groupType: 'FACET',
    operator,
    query: [query],
  };
}

export function fromFacet(term: string, fieldName: string, operator: Operators): CriteriaGroup {
  return {
    groupType: 'FACET',
    operator,
    query: [{ searchType: 'KEYWORD', field: { name: fieldName }, values: [term] }],
  };
}

export const MobilusDefaultFacet: CriteriaGroup = {
  groupType: 'ADV',
  operator: 'AND',
  query: [
    {
      searchType: 'KEYWORD',
      field: {
        name: 'collectionName',
      },
      values: ['COLL-WF-MOBILUS'],
    },
  ],
};
