<ng-container *ngIf="{ loading: loading$ | async, error: error$ | async } as _">
<div class="si-dialog">
  <div class="si-dialog__h">
    <mat-icon
      aria-hidden="true"
      *ngIf="dialogIcon"
      [class.si-icon]="dialogIcon !== 'not_interested'"
    >
      {{ dialogIcon }}
    </mat-icon>

    <h2 class="si-dialog__title">
      {{ dialogTitle }}
    </h2>
    <span class="si-expanse"></span>
    <button mat-icon-button matTooltip="Close" aria-label="Close" class="si-icon si-button--close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="si-dialog__b">
    <si-process-card
    *ngIf="_.error"
    class="si-component"
    type="error"
    [titleText]="_.error"
    action="Retry"
    actionIcon="refresh"
    (actionSelected)="processAction($event)"
    (optionSelected)="processAction($event)"
    [options]="[]"
  ></si-process-card>
    <div mat-dialog-content class="si-dialog__c">
      <div class="si-spinner__container" *ngIf="_.loading">
        <mat-spinner class="si-spinner"></mat-spinner>
      </div>
      <form class="si-form" *ngIf="!_.loading" [formGroup]="exportMetaForm">
        <div class="si-form__b">
          <si-read-only-field class="si-wd si-wd-full si-contains--input" label="Include Fields">
            <div class="si-input si-input--clickgroup">
              <mat-checkbox class="si-checkbox si-wd si-wd-fourtenth" #chBox (change)="item.value.value=!item.value.value" *ngFor="let item of exportMetaForm.controls" [checked]="item.value.value">
                {{ item.value.label }}
              </mat-checkbox>
            </div>
          </si-read-only-field>
        </div>
      </form>
    </div>
    <div class="si-form__a si-form__a--unusual" *ngIf="!_.loading">
      <button mat-flat-button class="si-button si-button--primary" (click)="this.processAction()">Download Metadata (XLS)</button>
    </div>
  </div>
</div>
</ng-container>
