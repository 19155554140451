import { ApiBaseUrls } from '../models/app-config.model';
import { SaeEnvironment } from '../models/environment-config.model';

export function getApiBaseUrlForEnvironment(env: SaeEnvironment, baseUrls: ApiBaseUrls): string | null {
  switch (env) {
    case SaeEnvironment.localhost:
    case SaeEnvironment.dev:
      return baseUrls.dev;
    case SaeEnvironment.test:
      return baseUrls.test;
    case SaeEnvironment.prod:
      return baseUrls.prod;
    default:
      return null;
  }
}

/**
 * For CMS-user generated links, in cases where we do not know if the user intended to create an internal or external link.
 * @param link - A string that may be a relative (internal) route path, or an externaL url.
 * @returns true if the string does not start with '/'.
 */
export function isExternalLink(link: string): boolean {
  return !link.startsWith('/');
}
