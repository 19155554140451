import { Injectable } from '@angular/core';
import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { SaeHttpResponse } from '@sae/models';
import { Observable } from 'rxjs';
import { Api } from '../../../api';

export interface DigitalStandard {
  id: string;
  type: string;
  code: string;
  title: string;
  status: string;
  publicationDate: Date;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DigitalStandardsState {}

function createInitialState(): DigitalStandardsState {
  return {} as DigitalStandardsState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'digitalStandards' })
export class DigitalStandardsStore extends EntityStore<DigitalStandardsState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class DigitalStandardsQuery extends QueryEntity<DigitalStandardsState> {
  constructor(protected store: DigitalStandardsStore) {
    super(store);
  }
}

@NgEntityServiceConfig({
  resourceName: Api.digitalStandards.url,
})
@Injectable({ providedIn: 'root' })
export class DigitalStandardsService extends NgEntityService<DigitalStandardsState> {
  constructor(protected store: DigitalStandardsStore, private query: DigitalStandardsQuery) {
    super(store);
  }

  get(config): Observable<DigitalStandard[]> {
    return super.get({
      ...config,
      mapResponseFn: (saeHttpResponse: SaeHttpResponse) => {
        return saeHttpResponse?.results;
      },
    });
  }
}
