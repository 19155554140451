<div class="siv-carousel">
  <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
  <div
    #carousel
    [id]="id"
    class="siv-carousel--tabs"
    [class.siv-carousel--tabs__autoscale]="autoScale"
    (touchstart)="onSwipe($event, 'start')"
    (touchend)="onSwipe($event, 'end')"
    (mousedown)="onDrag($event, 'start')"
    (mouseup)="onDrag($event, 'end')"
  >
    <div class="siv-carousel--content">
      <div #contentWrapper class="siv-carousel--wraper" [ngStyle]="{ left: left + 'px' }">
        <div class="siv-carousel--wraper__container">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="dialNavigation">
    <ng-container *ngTemplateOutlet="dialTemplate"></ng-container>
  </ng-container>
</div>

<ng-template #buttonsTemplate>
  <div class="siv-carousel--buttons">
    <div
      *ngIf="pages.length > 1 && (pageWrap || left < 0)"
      class="siv-carousel--buttons__arrow siv-carousel--buttons__previous"
      [class.siv-carousel--buttons__top]="navPosition === 'top'"
      [class.siv-carousel--buttons__offset]="navPosition === 'offset'"
    >
      <fs-part-button
        type="icon"
        materialIcon="chevron_left"
        tooltip="Previous Set"
        (buttonClick)="onNavBack()"
      ></fs-part-button>
    </div>
    <div
      *ngIf="pages.length > 1 && (pageWrap || left > (scrollWidth - offsetWidth) * -1)"
      class="siv-carousel--buttons__arrow siv-carousel--buttons__next"
      [class.siv-carousel--buttons__top]="navPosition === 'top'"
      [class.siv-carousel--buttons__offset]="navPosition === 'offset'"
    >
      <fs-part-button
        type="icon"
        materialIcon="chevron_right"
        tooltip="Next Set"
        (buttonClick)="onNavNext()"
      ></fs-part-button>
    </div>
  </div>
</ng-template>

<ng-template #dialTemplate>
  <div *ngIf="pages.length > 1" class="siv-carousel--navigation">
    <div class="siv-carousel--navigation__wrapper">
      <ng-container *ngFor="let page of pages; let index = index; let first = first">
        <fs-part-button
          type="icon"
          [class.siv-carousel--navigation__unchecked]="activeIndex + 1 !== page.pageNumber"
          [materialIcon]="activeIndex + 1 === page.pageNumber ? 'circle' : 'radio_button_unchecked'"
          [tooltip]="index + 1 + ' of ' + pages.length"
          (buttonClick)="onLoadIndex(index)"
        ></fs-part-button>
      </ng-container>
    </div>
  </div>
</ng-template>
