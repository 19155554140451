import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NG_ENTITY_SERVICE_CONFIG, NgEntityServiceGlobalConfig } from '@datorama/akita-ng-entity-service';
import { SaeHttpResponse } from '@sae/models';
import { BehaviorSubject, Observable, Subject, map, switchMap } from 'rxjs';
import { ApiRegistry, ApiToken } from './api-registry';

export interface GainSightDetails {
  user: {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    signUpDate: number;
    userHash: string;
    company: {
      companyId: string;
      companyName: string;
    };
    employer: {
      customerNumber: number;
    };
  };
  account: {
    id: string;
    name: string;
  };
}

declare global {
  interface Window {
    dataLayer: unknown[];
    aptrinsic: (field: string, user: object, account: object) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GainSightService {
  private baseUrl: string;

  gainSightDetails$ = new BehaviorSubject<GainSightDetails>(null);
  isCookieAccepted$ = new BehaviorSubject<boolean>(null);
  activeGroups$ = new Subject<string>();

  constructor(
    private readonly http: HttpClient,
    @Inject(NG_ENTITY_SERVICE_CONFIG)
    ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) apiReg: ApiRegistry,
    @Inject(PLATFORM_ID) protected platformId: object
  ) {
    this.baseUrl = `${ngEntityServiceGlobalConfig.baseUrl}/${apiReg.customerSuccess.url}`;
    this.activeGroups$
      .pipe(
        switchMap((val) => {
          this.isCookieAccepted$.next(val?.includes('C0002'));
          return this.loadGainsightUser(this.isCookieAccepted);
        })
      )
      .subscribe(() => this.aptrinsic());
  }

  get gainSightDetails(): GainSightDetails {
    return this.gainSightDetails$.value;
  }

  get isCookieAccepted(): boolean {
    return this.isCookieAccepted$.value;
  }

  aptrinsic(): void {
    if (window.aptrinsic) {
      window.aptrinsic('identify', this.gainSightDetails.user, this.gainSightDetails.account);
    }
  }

  loadGainsightUser(isCookieAccepted: boolean): Observable<GainSightDetails> {
    return this.http
      .get<SaeHttpResponse>(`${this.baseUrl}/gainsight/users/me`, {
        params: { trackingAccepted: isCookieAccepted },
      })
      .pipe(
        map((res) => {
          this.gainSightDetails$.next(res.results[0]);
          return this.gainSightDetails;
        })
      );
  }

  setGTMDataLayer(env: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    // enables firing GTM tags conditionally based on env
    window.dataLayer = window.dataLayer || [];

    // override dataLayer.push to add listener to array update without breaking GTM behavior
    const oldPush = window.dataLayer.push;
    window.dataLayer.push = (...params) => {
      // eslint-disable-next-line prefer-rest-params
      const states = [].slice.call(params, 0);
      if (states[0].event === 'OneTrustGroupsUpdated') {
        this.activeGroups$.next(states[0].OnetrustActiveGroups);
      }

      return oldPush.apply(window.dataLayer, states);
    };

    window.dataLayer.push(
      {
        environment: env,
      },
      { event: 'EnvUpdated' }
    );
  }
}
