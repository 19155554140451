import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { mergeMap, tap, withLatestFrom } from 'rxjs';
import { RootActions } from '.';
import { MobilusUiService } from '../shared/services/mobilus-ui.service';
import { SessionService } from '../shared/state/session.service';
import { selectRouteData } from './root.selectors';

@Injectable()
export class RootEffects {
  changeNavIcon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        withLatestFrom(this.sessionService.activeNavIcon$, this.store.select(selectRouteData)),
        tap(([, currActiveIcon, data]) => {
          if (!data?.activeIcon && currActiveIcon === 'Home') {
            this.sessionService.setActiveNavIcon('Browse');
          } else if (data?.activeIcon) {
            this.sessionService.setActiveNavIcon(data?.activeIcon);
          }
        })
      ),
    { dispatch: false }
  );

  usageTrackingEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.usageTrackingEvent),
        mergeMap((action) => {
          const mobiService = this.injector.get(MobilusUiService);
          return mobiService.usageTrackingEvent(action.event);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private sessionService: SessionService,
    private store: Store,
    private injector: Injector
  ) {}
}
