import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface SessionExpiryDialogData {
  secondsRemaining: number;
}

@UntilDestroy()
@Component({
  templateUrl: './session-expiry-dialog.component.html',
})
export class SessionExpiryDialogComponent implements OnInit {
  secondsRemaining: number;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SessionExpiryDialogData,
    private readonly dialogRef: MatDialogRef<SessionExpiryDialogComponent>
  ) {
    this.secondsRemaining = data.secondsRemaining;
  }

  ngOnInit(): void {
    interval(1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.secondsRemaining--);
  }

  noClick(): void {
    this.dialogRef.close(false);
  }

  yesClick(): void {
    this.dialogRef.close(true);
  }
}
