import { Component } from '@angular/core';
import { ContentService } from '../../../../../shared/state/content.service';

@Component({
  selector: 'mobi-detail-course-outline',
  templateUrl: './detail-course-outline.component.html',
})
export class DetailCourseOutlineComponent {
  public content$ = this.contentService.content$;
  constructor(private contentService: ContentService) {}
}
