<mat-menu #menu class="si-menu">
  <ng-container *ngIf="menuData.allOption">
    <button mat-menu-item [class.si-state--selected-not]="filterParams && filterParams.length" (click)="onFilterAll()">
      <mat-icon class="si-icon" aria-label="All">check</mat-icon>
      <span>All</span>
    </button>
    <mat-divider class="si-divider"></mat-divider>
  </ng-container>

  <ng-container *ngFor="let c of menuData.content">
    <ng-container *ngIf="{ x: getMenuData(c) } as nestedMenuData">
      <ng-container *ngIf="nestedMenuData.x.content; else normal">
        <button mat-menu-item [matMenuTriggerFor]="nestedMenu.menu" class="si-state--unusual si-icon--more">
          <mat-icon *ngIf="nestedMenuData.x.icon" [color]="nestedMenuData.x.iconColor" aria-hidden="true">
            {{ nestedMenuData.x.icon }}
          </mat-icon>
          <span *ngIf="nestedMenuData.x.label">{{ nestedMenuData.x.label }}</span>
        </button>
        <si-overflow-menu-inner
          #nestedMenu
          [menuData]="nestedMenuData.x"
          (itemClicked)="onItemClicked($event)"
          (sortChanged)="onNestedSortChanged($event)"
          (filterChanged)="onNestedFilterChanged($event)"
        ></si-overflow-menu-inner>
      </ng-container>
    </ng-container>
    <ng-template #normal>
      <ng-container
        *ngIf="{
          itemGroup: getItemGroup(c),
          sortGroup: getSortGroup(c),
          filterGroup: getFilterGroup(c),
          menuItem: getMenuItem(c)
        } as normalItems"
      >
        <ng-container *ngIf="normalItems.itemGroup.items; else SingleItem">
          <!-- content is some type of ItemGroup -->

          <label class="si-label" *ngIf="normalItems.itemGroup.label">{{ normalItems.itemGroup.label }}</label>

          <ng-container *ngFor="let i of normalItems.itemGroup.items">
            <ng-container *ngIf="!i.hidden">
              <span *ngIf="normalItems.sortGroup.sort" [matTooltip]="i.tooltip">
                <!-- content is a SortGroup -->
                <button
                  [class.si-state--selected-not]="normalItems.sortGroup.sort.active !== i.name"
                  mat-menu-item
                  (click)="onSortChanged(i.name, normalItems.sortGroup.sort)"
                  [disabled]="i.disabled"
                >
                  <mat-icon
                    class="si-icon"
                    *ngIf="normalItems.sortGroup.sort.direction === 'asc'"
                    aria-label="Sort by Ascending"
                  >
                    arrow_upward
                  </mat-icon>
                  <mat-icon
                    class="si-icon"
                    *ngIf="normalItems.sortGroup.sort.direction === 'desc'"
                    aria-label="Sort by Descending"
                  >
                    arrow_downward
                  </mat-icon>
                  <span>{{ i.label }}</span>
                </button>
              </span>

              <span *ngIf="normalItems.filterGroup.filterKey" [matTooltip]="i.tooltip">
                <!-- content is a FilterGroup -->
                <button
                  *ngIf="normalItems.filterGroup.filterKey"
                  [class.si-state--selected-not]="!i.active"
                  mat-menu-item
                  [id]="normalItems.menuItem.id || normalItems.menuItem.name || ''"
                  (click)="onFilterChanged(i, $any(c))"
                  [disabled]="i.disabled"
                >
                  <ng-container>
                    <mat-icon class="si-icon" attr.aria-label="Filter by {{ i.label }}">check</mat-icon>
                  </ng-container>
                  <span>{{ i.label }}</span>
                </button>
              </span>

              <span *ngIf="!normalItems.sortGroup.sort && !normalItems.filterGroup.filterKey" [matTooltip]="i.tooltip">
                <!-- content is a standard ItemGroup -->
                <button
                  *ngIf="!normalItems.sortGroup.sort && !normalItems.filterGroup.filterKey"
                  mat-menu-item
                  [id]="normalItems.menuItem.id || normalItems.menuItem.name || ''"
                  (click)="onItemClicked(i)"
                  [disabled]="i.disabled"
                >
                  <ng-container *ngIf="i.selected; else useIcon">
                    <mat-icon class="si-icon" attr.aria-label="{{ i.label }}">check</mat-icon>
                  </ng-container>
                  <ng-template #useIcon>
                    <mat-icon class="si-icon" [color]="i.iconColor" aria-hidden="true">{{ i.icon }}</mat-icon>
                  </ng-template>
                  <span>{{ i.label }}</span>
                </button>
              </span>

              <mat-divider *ngIf="i.divider" class="si-divider"></mat-divider>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #SingleItem>
          <!-- content is just a standalone MenuItem -->
          <span [matTooltip]="normalItems.menuItem.tooltip">
            <button
              *ngIf="!normalItems.menuItem.hidden"
              mat-menu-item
              (click)="onItemClicked(c)"
              [id]="normalItems.menuItem.id || normalItems.menuItem.name || ''"
              [disabled]="normalItems.menuItem.disabled"
              [matTooltip]="normalItems.menuItem.tooltip"
            >
              <mat-icon [color]="normalItems.menuItem.iconColor" aria-hidden="true">
                {{ normalItems.menuItem.icon }}
              </mat-icon>
              <span>{{ normalItems.menuItem.label }}</span>
            </button>
          </span>
        </ng-template>
        <mat-divider
          *ngIf="normalItems.menuItem.divider && !normalItems.menuItem.hidden"
          class="si-divider"
        ></mat-divider>
      </ng-container>
    </ng-template>
  </ng-container>
</mat-menu>
