import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, of } from 'rxjs';
import {
  APP_CONFIG_TOKEN,
  EnterpriseMenu,
  IEnvironmentConfigService,
  ProteusApiUrl,
  ProteusConfigurationResult,
  ProteusConfigurationsQuery,
  AppConfig,
  SaeEnvironment,
  SaeEnvironmentConfig,
  SaeApplicationCode,
  SaeHttpResponse,
} from '@sae/base';

/**
 * The EnvironmentConfigService is responsible for fetching configurations from the Proteus (backend) service.
 * It will eventually replace the RemoteConfigService.
 * Apps that need to decouple from Firebase can use this service instead.
 * https://fullsight.atlassian.net/wiki/spaces/DR/pages/327876627/Proteus+Onboarding+for+Angular+Apps
 * @export
 * @class EnvironmentConfigService
 * @typedef {EnvironmentConfigService}
 * @implements {IEnvironmentConfigService}
 */
@Injectable()
export class EnvironmentConfigService implements IEnvironmentConfigService {
  private proteusApiUrl: string;
  private _currentEnv$: BehaviorSubject<SaeEnvironment> = new BehaviorSubject(null);
  private _envConfig$: BehaviorSubject<SaeEnvironmentConfig> = new BehaviorSubject(null);
  private _hostURL$: BehaviorSubject<string> = new BehaviorSubject(null);

  // for legacy RemoteConfig support
  private _enterpriseMenu$: BehaviorSubject<EnterpriseMenu> = new BehaviorSubject(null);
  config: SaeEnvironmentConfig;
  config$ = this._envConfig$.asObservable();
  enterpriseMenu: EnterpriseMenu;
  enterpriseMenu$ = this._enterpriseMenu$.asObservable();

  constructor(
    @Inject(APP_CONFIG_TOKEN) private readonly appConfig: AppConfig,
    private readonly httpClient: HttpClient
  ) {
    this.proteusApiUrl = this.appConfig.proteusApiUrl || ProteusApiUrl;
  }

  get currentEnv(): SaeEnvironment {
    return this._currentEnv$.value;
  }

  get currentEnv$(): Observable<SaeEnvironment> {
    return this._currentEnv$.asObservable();
  }

  get hostURL(): string {
    return this._hostURL$.value;
  }

  get hostURL$(): Observable<string> {
    return this._hostURL$.asObservable();
  }

  envConfig<TConfig extends SaeEnvironmentConfig = SaeEnvironmentConfig>(): TConfig {
    return this._envConfig$.value as TConfig;
  }

  envConfig$<TConfig extends SaeEnvironmentConfig = SaeEnvironmentConfig>(): Observable<TConfig> {
    return this._envConfig$.asObservable().pipe(filter((v) => !!v)) as Observable<TConfig>;
  }

  async initialize<TConfig extends SaeEnvironmentConfig = SaeEnvironmentConfig>(
    currentEnvironment: SaeEnvironment,
    hostURL?: string
  ): Promise<TConfig> {
    this._currentEnv$.next(currentEnvironment);
    this._hostURL$.next(hostURL);
    return new Promise((resolve, reject) => {
      const { applicationCode, defaultEnvironmentConfig } = this.appConfig;
      if (!defaultEnvironmentConfig) {
        console.warn('Missing `defaultEnvironmentConfig` in `PlatformAppConfig`. Please create one.');
      }
      if (!applicationCode) {
        reject('Missing `applicationCode` in `PlatformAppConfig`. Unable to request environment configurations.');
      } else {
        this.getConfigurationsForProduct(applicationCode)
          .pipe(
            catchError((err) => {
              console.error(err);
              // if the API call fails, we can still fall back on defaults, so return of(null) and continue...
              return of(null);
            })
          )
          .subscribe((res) => {
            if (res || defaultEnvironmentConfig) {
              const config = this.resolveConfigurationsResponse(res, currentEnvironment);
              if (config) {
                this.setPrivateSubjects(config);
                resolve(config as TConfig);
              } else if (defaultEnvironmentConfig) {
                console.warn('Using default configuration values.');
                this.setPrivateSubjects(defaultEnvironmentConfig);
                resolve(defaultEnvironmentConfig as TConfig);
              } else {
                reject('Environment configuration could not be resolved, and no default configuration was found.');
              }
            } else {
              reject('The environment configuration request failed, and no default configuration was found.');
            }
          });
      }
    });
  }

  private setPrivateSubjects(config: SaeEnvironmentConfig): void {
    this._envConfig$.next(config);
    this.config = config; // for legacy RemoteConfig support
    if (config.enterpriseMenu) {
      this._enterpriseMenu$.next(config.enterpriseMenu);
      this.enterpriseMenu = config.enterpriseMenu; // for legacy RemoteConfig support
    }
  }

  private resolveConfigurationsResponse(
    res: SaeHttpResponse<ProteusConfigurationResult>,
    currentEnv: SaeEnvironment
  ): SaeEnvironmentConfig {
    if (!res) {
      return null;
    }
    const configsForEnv = res?.results?.filter((c) => {
      return c.environment === currentEnv;
    });
    if (configsForEnv?.length === 1) {
      return JSON.parse(configsForEnv[0].value) as SaeEnvironmentConfig;
    } else {
      console.warn(
        'Invalid number of configurations (' +
          configsForEnv.length +
          ') found for current environment (' +
          currentEnv +
          ').'
      );
      return null;
    }
  }

  private getConfigurationsForProduct(
    applicationCode: SaeApplicationCode
  ): Observable<SaeHttpResponse<ProteusConfigurationResult>> {
    const params = new HttpParams().set('clientId', applicationCode);
    return this.queryConfigurations(params);
  }

  private queryConfigurations(
    params: ProteusConfigurationsQuery
  ): Observable<SaeHttpResponse<ProteusConfigurationResult>> {
    return this.httpClient.get<SaeHttpResponse<ProteusConfigurationResult>>(`${this.proteusApiUrl}/configurations`, {
      params,
    });
  }
}
