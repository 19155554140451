<mat-card class="si-card si-card--reader mat-elevation-z2">
  <div *ngIf="!error" class="si-doc">
    <div id="document-view"></div>
    <ng-container *ngIf="loading">
      <div class="si-spinner__container">
        <mat-spinner class="si-spinner"></mat-spinner>
      </div>
    </ng-container>
  </div>
  <div *ngIf="error" class="si-card--dataviewer__c">
    <p>There was a problem loading the document.</p>
    <div class="si-actions-row">
      <div class="si-actions-row__a">
        <button mat-flat-button class="si-button si-button--primary" (click)="retryAfterError()">Retry</button>
      </div>
    </div>
  </div>
</mat-card>
