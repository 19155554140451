<article #detailContent>
  <form
    [action]="ssoUrl"
    method="post"
    encType="application/x-www-form-urlencoded"
    name="ssologin"
    #ssoLoginForm
    ngNoForm
    onsubmit="return false;"
  >
    <input type="hidden" name="grantType" value="SSO" />
    <input type="hidden" name="targetUrl" value="" />
    <input type="hidden" name="entityId" value="" />
    <input type="hidden" name="state" value="" />
  </form>
</article>
