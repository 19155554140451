<ng-container
  *ngIf="{
    results: results$ | async,
    pageSize: pageSize$ | async,
    pageNum: pageNum$ | async,
    total: total$ | async,
    loading: loading$ | async,
    content: content$ | async,
    category: category$ | async,
    name: name$ | async,
    description: description$ | async,
    breadcrumbs: breadcrumbs$ | async
  } as _"
>
  <si-stub-card
    class="si-component"
    [headerType]="_.category !== 'NONE' ? 'breadcrumb' : null"
    [breadcrumbs]="_.breadcrumbs"
    (crumbClick)="onCrumbClicked($event)"
  >
    <div class="si-card__c" *ngIf="_.category !== 'NONE'">
      <mat-accordion class="si-expanel__container si-expanel--singleton" *ngIf="_.name || _.description">
        <mat-expansion-panel class="si-expanel si-expanel--impact mat-elevation-z" [expanded]="true">
          <mat-expansion-panel-header class="si-expanel__h">
            <mat-panel-title class="si-title si-impact__title">{{ _.name }}</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="si-expanel__c">
            <si-data-output class="si-data__set si-wd-full" [content]="_.description"></si-data-output>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <si-basic-data-viewer
      dataTitle="Articles"
      [search]="true"
      [singleSortMenu]="false"
      [pageSize]="_.pageSize"
      [pageNumber]="_.pageNum"
      [total]="_.total"
      [loading]="_.loading"
      [noneText]="!_.selectedPubVolumeDescription ? 'No Articles' : 'There are no Journals for the selected type'"
      [noResults]="_.results?.length === 0"
      [pageSizeOptions]="pageSizeOptions"
      [paginator]="_.results?.length > 0"
      (selectedOption)="selectedOption($event)"
      (pageChanged)="handlePage($event)"
      [hideSearchBoxTooltip]="false"
      (searchChanged)="handleQuery($event, _.category, _.breadcrumbs)"
    >
      <mobi-publication-document-card
        *ngFor="let doc of _.results"
        [pubDocument]="doc"
        [returnUrlTitle]="_.content?.title"
        [detailCid]="_.content?.id"
      ></mobi-publication-document-card>
    </si-basic-data-viewer>
  </si-stub-card>
</ng-container>
