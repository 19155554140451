import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AdvSearchConditionConfig, Facet } from '@sae/models';

@Component({
  selector: 'si-criteria-row-container',
  templateUrl: './criteria-row-container.component.html',
  styleUrls: ['./criteria-row-container.component.scss'],
})
export class CriteriaRowContainerComponent implements OnInit {
  @Input() level!: number;
  @Input() indexNum!: number;
  @Input() facets!: Array<Facet>;
  @Input() row!: AbstractControl;
  @Input() altClass!: boolean;
  @Input() showButton: boolean;
  @Input() advSearchConditionConfig: AdvSearchConditionConfig;
  @Input() autocompleteKeyword = false;

  @Output() rowAdded: EventEmitter<number> = new EventEmitter<number>();
  @Output() rowRemoved: EventEmitter<number> = new EventEmitter<number>();
  @Output() groupAdded: EventEmitter<number> = new EventEmitter<number>();

  isGroup = false;

  ngOnInit(): void {
    this.level += 1;
    if (!this.row.get('fieldName')) {
      this.isGroup = true;
    }
  }

  onAddRow(index: number): void {
    this.rowAdded.emit(index);
  }

  onRemoveRow(index: number): void {
    this.rowRemoved.emit(index);
  }

  onAddGroup(index: number): void {
    this.groupAdded.emit(index);
  }
}
