<fs-part-container
  classes="si-expanelv"
  class="si-expanelv__wrap"
  [class.si-allow--stack]="stackMobile"
  [class.si-contains--avatar]="avatar"
  [class.si-state--collapsed]="!expanded"
  [class.si-state--expanded]="expanded"
  [shadow]="shadow"
  [tight]="true"
>
  <div class="si-expanelv__h" [class.si-state--sticky]="sticky">
    <fs-part-button
      classes="si-expanelv__button"
      [ariaControls]="'panel' + uniqueId"
      [ariaExpanded]="expanded"
      [buttonId]="'control' + uniqueId"
      type="plain"
      (buttonClick)="expanded = !expanded; expandedChange()"
    >
      <div class="si-expanelv__h__body" [ngStyle]="{ 'paddingRight.px': _controlContainerOffsetWidth + 8 }">
        <fs-comp-avatar *ngIf="avatar" [avatar]="avatar" class="si-expanelv__avatar"></fs-comp-avatar>
        <ng-content select="[headingStart]"></ng-content>
        <fs-comp-util-heading
          [heading]="heading"
          [classes]="headingClasses"
          [content]="headingContent"
          [headingCount]="headingCount"
          [headingRow]="headingRow"
          [hTag]="hTag"
        ></fs-comp-util-heading>
        <ng-content select="[headingEnd]"></ng-content>
      </div>
      <span class="si-expanelv__indicator__wrap">
        <fs-part-icon class="si-expanelv__indicator">expand_less</fs-part-icon>
      </span>
    </fs-part-button>
  </div>
  <div class="si-expanelv__persist"><ng-content select="[persist]"></ng-content></div>
  <div class="si-expanelv__control" [class.si-state--sticky]="sticky" #controlContainer>
    <ng-content select="[control]"></ng-content>
  </div>
  <div class="si-expanelv__summary" *ngIf="!expanded">
    <div class="si-expanelv__body"><ng-content select="[summary]"></ng-content></div>
  </div>
  <div
    class="si-expanelv__c"
    [attr.id]="'panel' + uniqueId"
    [ngStyle]="!stable || _expanelBodyHeight === 0 ? { 'height.px': _expanelBodyHeight } : null"
    [class.si-state--notstable]="!stable"
    [attr.aria-labelledby]="'control' + uniqueId"
    role="region"
  >
    <div class="si-expanelv__body" #expanelBody [class.si-tight]="tight">
      <ng-content select="[bodyStart]"></ng-content>
      <fs-ui-data-grid
        *ngIf="contentDataGrid"
        [content]="contentDataGrid"
        (buttonClick)="onMixedButtonClick($event)"
        (chipClick)="chipClickFunc($event)"
        (chipRemoveClick)="onMixedRemoveChipClick($event)"
      ></fs-ui-data-grid>
      <fs-ui-util-mixed-components
        *ngIf="contentMixed"
        [content]="contentMixed"
        (buttonClick)="onMixedButtonClick($event)"
        (chipClick)="chipClickFunc($event)"
      ></fs-ui-util-mixed-components>
      <ng-content select="[body]"></ng-content>
      <ng-content select="[bodyEnd]"></ng-content>
    </div>
  </div>
</fs-part-container>
