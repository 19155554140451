import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartButtonComponent, ButtonItem } from '../../parts/part-button/part-button.component';
import { PartSignalComponent, SignalOption } from '../../parts/part-signal/part-signal.component';
import { ContainerContent, PartContainerComponent } from '../../parts/part-container/part-container.component';
import { CompAvatarComponent, AvatarContent } from '../../compounds/comp-avatar/comp-avatar.component';
import { CompChipComponent, ChipItem } from '../../compounds/comp-chip/comp-chip.component';
import {
  CompUtilHeadingComponent,
  HeadingContent,
} from '../../compounds/comp-util-heading/comp-util-heading.component';
import {
  UIUtilMixedComponentsComponent,
  MixedComponentsContent,
} from '../ui-util-mixed-components/ui-util-mixed-components.component';
import { overrideInputs } from '@sae/base';

export interface CardItem {
  avatar?: AvatarContent;
  classes?: string;
  container?: ContainerContent;
  content?: MixedComponentsContent[];
  context?: MixedComponentsContent[];
  heading?: HeadingContent;
  isButton?: boolean;
  outerClasses?: string;
  related?: MixedComponentsContent[];
  signal?: SignalOption;
  url?: string;
  width?: string;
}

@Component({
  selector: 'fs-ui-card',
  styleUrls: ['./ui-card.component.scss'],
  templateUrl: './ui-card.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    PartButtonComponent,
    PartContainerComponent,
    PartSignalComponent,
    CompAvatarComponent,
    CompChipComponent,
    CompUtilHeadingComponent,
    UIUtilMixedComponentsComponent,
  ],
  standalone: true,
})
export class UICardComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: CardItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() avatar: AvatarContent; // Optional, adds an avatar
  @Input() classes: string; // Optional, provides a CSS class for the output wrapper
  @Input() container: ContainerContent; // Optional, provides a container
  @Input() content: MixedComponentsContent[]; // Optional, content's content
  @Input() context: MixedComponentsContent[]; // Optional, context content
  @Input() displayContext: boolean; // Optional, displays context area for content projection, note that providing context content will display the area
  @Input() displayRelated: boolean; // Optional, displays related area for content projection, note that providing related content will display the area
  @Input() external: boolean; // Optional, provides a flag for external URL
  @Input() heading: HeadingContent; // Optional, adds a heading
  @Input() isButton: boolean; // Optional, provides a flag for button
  @Input() outerClasses: string; // Optional, provides a CSS class for the output wrapper
  @Input() related: MixedComponentsContent[]; // Optional, related content
  @Input() signal: SignalOption; // Optional, provides a signal
  @Input() url: string; // Optional, provides a URL for the card
  @Input() card: CardItem; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  @Output() buttonClick = new EventEmitter<ButtonItem>(); // Event emitted when button is clicked
  @Output() chipClick = new EventEmitter<ChipItem>(); // Event emitted when chip is clicked
  @Output() chipRemoveClick = new EventEmitter<ChipItem>(); // Event emitted when chip remove button is clicked

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['card']) {
      overrideInputs(this, this.card);
    }
  }

  onButtonClick(button: ButtonItem): void {
    this.buttonClick.emit(button);
  }

  onChipClick(chip: ChipItem): void {
    this.chipClick.emit(chip);
  }

  onRemoveChipClick(chip: ChipItem): void {
    this.chipRemoveClick.emit(chip);
  }
}
