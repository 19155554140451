import { createReducer, on } from '@ngrx/store';
import { BrowseActions } from '.';
import { initialBrowseState } from './browse.state';
import { CommonFacets } from '../../shared/models/common-interfaces';

export const browseReducer = createReducer(
  initialBrowseState,
  on(BrowseActions.setBrowseConfig, (state, { config, preserve, fragmentData }) => {
    if (!preserve) {
      return {
        ...initialBrowseState,
        ...config,
        searchTerm: fragmentData?.queryTerm ?? '',
        filters: fragmentData?.filters ?? {},
        customFilters: fragmentData?.customFilters ?? {},
        isPubDateApplied: !!fragmentData?.pubDate,
        pubDateFrom: fragmentData?.pubDate?.from,
        pubDateTo: fragmentData?.pubDate?.to,
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(BrowseActions.search, (state) => ({
    ...state,
    resultsLoading: true,
    resultsError: null,
  })),
  on(BrowseActions.searchSuccess, (state, { resp }) => {
    const updatedFacets: Partial<CommonFacets> = {};
    Object.values(resp.facets).forEach((responseFacetArray) => {
      updatedFacets[responseFacetArray[0].name] = {
        ...state.facets[responseFacetArray[0].name],
        ...responseFacetArray[0],
      };
    });
    return {
      ...state,
      results: resp.results,
      facets: {
        ...state.facets,
        ...updatedFacets,
      },
      total: resp.facets?.total?.[0]?.data?.[0]?.count,
      resultsLoading: false,
      resultsError: null,
      panelInitialized: true,
    };
  }),
  on(BrowseActions.searchFail, (state, { error }) => ({
    ...state,
    results: [],
    resultsLoading: false,
    resultsError: error,
  })),
  on(BrowseActions.changePagination, (state, { event }) => ({
    ...state,
    pageNumber: event.pageIndex + 1,
    pageSize: event.pageSize,
    resultsLoading: true,
  })),
  on(BrowseActions.changeSearchTerm, (state, { searchTerm }) => ({
    ...state,
    searchTerm,
    pageNumber: 1,
    preservedFacet: undefined,
  })),
  on(BrowseActions.changeSortParams, (state, { sortParams }) => ({
    ...state,
    sortBy: sortParams.active,
    sortDir: sortParams.direction,
    pageNumber: 1,
    resultsLoading: true,
  })),
  on(BrowseActions.saveScrollPosition, (state, { scrollTop }) => ({ ...state, scrollTop })),
  on(BrowseActions.getTopics, BrowseActions.getSpecificTopics, (state, { fragmentData }) => ({
    ...state,
    topicsLoading: true,
    searchTerm: fragmentData?.queryTerm ?? '',
    filters: fragmentData?.filters ?? {},
    isPubDateApplied: !!fragmentData?.pubDate,
    pubDateFrom: fragmentData?.pubDate?.from,
    pubDateTo: fragmentData?.pubDate?.to,
  })),
  on(BrowseActions.getTopicsSuccess, BrowseActions.getSpecificTopicsSuccess, (state) => ({
    ...state,
    topicsLoading: false,
  })),

  on(BrowseActions.getJournals, (state, { fragmentData, config }) => ({
    ...state,
    ...config,
    searchTerm: fragmentData?.queryTerm ?? '',
    filters: fragmentData?.filters ?? {},
    isPubDateApplied: !!fragmentData?.pubDate,
    pubDateFrom: fragmentData?.pubDate?.from,
    pubDateTo: fragmentData?.pubDate?.to,
    results: [],
    journalsDetailLoading: true,
    pageSize: 25,
  })),
  on(BrowseActions.getJournalsSuccess, (state, { journals, resp }) => {
    const sortedJournalLabel = [...journals];
    sortedJournalLabel?.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    const updatedFacets: Partial<CommonFacets> = {};
    Object.values(resp.facets).forEach((responseFacetArray) => {
      updatedFacets[responseFacetArray[0].name] = {
        ...state.facets[responseFacetArray[0].name],
        ...responseFacetArray[0],
      };
    });

    return {
      ...state,

      masterPanelBrowseCategories: [
        {
          list: 'Browse SAE International Journal Of',
          items: sortedJournalLabel,
        },
      ],
      journals,
      total: resp.facets?.total?.[0]?.data?.[0]?.count,
      resultsLoading: false,
      journalsDetailLoading: journals?.length ? true : false,
      facets: {
        ...state.facets,
        ...updatedFacets,
      },
    };
  }),
  on(BrowseActions.getJournalsFailure, (state, { error }) => ({
    ...state,
    resultsError: error,
    journals: [],
    journalsDetailLoading: false,
    resultsLoading: false,
  })),

  on(BrowseActions.getJournalBrxDataSuccess, (state, { journals }) => ({
    ...state,
    journals: journals,
    journalsDetailLoading: false,
    resultsLoading: false,
  })),
  on(BrowseActions.applyFilter, (state, { fieldName, value }) => {
    const filterValues = state.filters[fieldName]?.concat() ?? [];
    filterValues.push(value);
    return {
      ...state,
      filters: {
        ...state.filters,
        [fieldName]: filterValues,
      },
      preservedFacet: state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
    };
  }),
  on(BrowseActions.applyPubDateFilter, (state, { to, from }) => {
    return {
      ...state,
      pubDateFrom: from,
      pubDateTo: to,
      preservedFacet: undefined,
      isPubDateApplied: true,
    };
  }),
  on(BrowseActions.removeFilter, (state, { fieldName, value }) => {
    const filterValues = state.filters[fieldName]?.concat() ?? [];
    const idx = filterValues.indexOf(value);
    filterValues.splice(idx, 1);
    if (!filterValues?.length) {
      const { [fieldName]: value, ...cleanedFilters } = state.filters;
      return {
        ...state,
        filters: cleanedFilters,
        preservedFacet: undefined,
      };
    } else {
      return {
        ...state,
        filters: {
          ...state.filters,
          [fieldName]: filterValues,
        },
        preservedFacet:
          state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
      };
    }
  }),
  on(BrowseActions.removePubDateFilter, (state) => ({
    ...state,
    isPubDateApplied: false,
    preservedFacet: undefined,
  })),
  on(BrowseActions.setFilters, (state, { filters, customFilters }) => ({
    ...state,
    filters,
    customFilters,
    isPubDateApplied: false,
    preservedFacet: Object.keys(filters).length === 0 ? undefined : state.preservedFacet,
  })),
  on(BrowseActions.expandAllFacets, (state) => {
    const newFacets = { ...state.facets };
    Object.keys(newFacets).forEach((key) => {
      newFacets[key] = {
        ...newFacets[key],
        expanded: true,
      };
    });
    return {
      ...state,
      facets: newFacets,
    };
  }),
  on(BrowseActions.collapseAllFacets, (state) => {
    const newFacets = { ...state.facets };
    Object.keys(newFacets).forEach((key) => {
      newFacets[key] = {
        ...newFacets[key],
        expanded: false,
      };
    });
    return {
      ...state,
      facets: newFacets,
    };
  }),
  on(BrowseActions.clearPreservedFacet, (state) => ({
    ...state,
    preservedFacet: undefined,
  })),
  on(BrowseActions.setFragmentData, (state, { searchTerm, filters }) => ({
    ...state,
    searchTerm,
    filters,
  })),
  on(BrowseActions.showMoreValues, (state, { fieldName }) => ({
    ...state,
    facets: {
      ...state.facets,
      [fieldName]: {
        ...state.facets[fieldName],
        numValuesShown: state.facets[fieldName]?.numValuesShown + 50,
      },
    },
    preservedFacet: {
      ...state.preservedFacet,
      numValuesShown: !state.preservedFacet
        ? undefined
        : fieldName === state.preservedFacet?.name
        ? state.preservedFacet?.numValuesShown + 50
        : state.preservedFacet?.numValuesShown,
    },
  })),

  on(BrowseActions.applyCustomFilter, (state, { fieldName, value }) => {
    const filterValues = state.customFilters[fieldName]?.concat() ?? [];
    if (!filterValues.includes(value)) {
      filterValues.push(value);
    }
    return {
      ...state,
      customFilters: {
        ...state.customFilters,
        [fieldName]: filterValues,
      },
      preservedFacet: state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
    };
  }),
  on(BrowseActions.removeCustomFilter, (state, { fieldName, value }) => {
    const filterValues = state.customFilters[fieldName]?.concat() ?? [];
    const idx = filterValues.indexOf(value);
    filterValues.splice(idx, 1);
    if (!filterValues?.length) {
      const { [fieldName]: value, ...cleanedFilters } = state.customFilters;
      return {
        ...state,
        customFilters: cleanedFilters,
        preservedFacet: undefined,
      };
    } else {
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          [fieldName]: filterValues,
        },
        preservedFacet:
          state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
      };
    }
  }),

  on(BrowseActions.setAlert, (state, { rootCode, alertId }) => {
    let searchResults = [...state.results];

    searchResults = searchResults.map((result) => {
      if (result?.codes?.raw[result.codes.raw.length - 1] === rootCode) {
        return { ...result, alertId: alertId };
      }
      return result;
    });

    return {
      ...state,
      results: searchResults,
    };
  }),

  on(BrowseActions.removeAlert, (state, { alertId }) => {
    let searchResults = [...state.results];

    searchResults = searchResults.map((result) => {
      if (result?.alertId === alertId) {
        return { ...result, alertId: null };
      }
      return result;
    });

    return {
      ...state,
      results: searchResults,
    };
  })
);
