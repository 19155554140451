import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutocompleteOption } from '@sae/models';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'si-autocomplete-chip',
  templateUrl: './autocomplete-chip.component.html',
})
export class AutocompleteChipComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() addOnBlur = true; // If true, the custom value will be added when the input loses focus. Turn off if you're using autocomplete along with custom values
  @Input() addOptionLabel = 'Add New'; // Title for the add option if showAddOption is true
  @Input() customAllowDuplicates = true; // If customValue is true, determines whether the custom value can be added multiple times
  @Input() customValue: boolean; //If true, the user can enter a custom value that is not in the options list
  @Input() customValueValidatorFn: (value: string) => boolean; //If customValue is true, this function will be called to validate the custom value
  @Input() inputLabel = '';
  @Input() matHint = '';
  @Input() maxChips: number | null = null; // If null or 0, there is no limit to the number of chips that can be added, otherwise the chip count cannot exceed this value
  @Input() multidimensionalChip = false; // If true, uses the title for autocomplete but the two part chip is comprised of the data property, where the data.start is the left sector chip, and an optional data.end is the right role chip
  @Input() options: AutocompleteOption[] = [];
  @Input() personCard = false;
  @Input() readOnly: boolean;
  @Input() sectorChip = false;
  @Input() selectionCountVisible = true;
  @Input() showAddOption = false; // If true appends an Add option to the end of the options list
  @Input() showSelectListButton = false; // Determines whether to expose the 'Select List' button (but only if not in readOnly mode)
  @Input() showSubtitle: boolean;

  @Output() addOptionClick = new EventEmitter();
  @Output() chipRemoved = new EventEmitter<AutocompleteOption>();
  @Output() chipSelected = new EventEmitter<AutocompleteOption>();
  @Output() selectListClicked = new EventEmitter();

  @ViewChild('textInput', { static: false }) textInput: ElementRef<HTMLInputElement>;


  public chipListControl: FormControl;
  public filteredOptions$ = new BehaviorSubject<AutocompleteOption[]>([]);
  public isRequired: boolean;
  public selections: AutocompleteOption[] = [];
  public textInputControl: FormControl;
  private textInputValue: string;

  // CVA

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-empty-function, @typescript-eslint/explicit-function-return-type
  public onTouched = () => { };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, @typescript-eslint/explicit-function-return-type
  private onChange = (_value: AutocompleteOption[]) => { };

  // appearance="none" in the template to remove the underline from a mat-form-field is not officially supported by Material,
  // https://github.com/angular/components/issues/23118
  // It only works with strictTemplates disabled. Type cast the string as a workaround.
  /* eslint-disable @typescript-eslint/member-ordering */
  public noUnderline: MatFormFieldAppearance = 'none' as MatFormFieldAppearance; // this is dark magic
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(public parentControl: NgControl, private cdr: ChangeDetectorRef) {
    this.parentControl.valueAccessor = this;
    this.chipListControl = new FormControl([null]);
    this.textInputControl = new FormControl([null]);
  }

  ngOnInit(): void {
    this.setFormControlAttributes();
    this.updateFilteredOptions();
    this.textInputControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val: string | AutocompleteOption) => {
      if (typeof val === 'string') {
        // this type guard differentiates between actual user input and mat-autocomplete just doing its own thing
        this.textInputValue = val;
        this.updateFilteredOptions();
      }
    });
    this.parentControl.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      // in case validation changes, e.g. the field is conditionally required
      this.setFormControlAttributes();
    });
  }

  ngOnChanges(c: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (c.hasOwnProperty('readOnly')) {
      this.setFormControlAttributes();
    }
    // eslint-disable-next-line no-prototype-builtins
    if (c.hasOwnProperty('options')) {
      this.updateFilteredOptions();
    }
  }

  public onSelect(a: MatAutocompleteSelectedEvent): void {
    const chip = a.option.value as AutocompleteOption;
    if (chip) {
      this.selectById(chip.id);
      this.updateFilteredOptions();
    }
  }

  public onRemove(a: AutocompleteOption): void {
    this.deselectById(a.id);
    this.updateFilteredOptions();
  }

  public onAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (this.customValue && value) {
      if (
        (!this.customValueValidatorFn || this.customValueValidatorFn(value)) &&
        (!this.customValue || this.customAllowDuplicates || !this.selections.find((s) => s.title === value))
      ) {
        this.selections.push({
          id: value,
          title: value,
        });
        this.setSelections(this.selections);
        this.emitSelections();
        this.updateValidationErrors();

        event.chipInput?.clear();
        this.textInputValue = '';
        this.updateFilteredOptions();
      }
    }
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  private setFormControlAttributes(): void {
    this.readOnly ? this.textInputControl.disable() : this.textInputControl.enable();
    this.isRequired = this.hasRequiredField(this.parentControl.control);
  }

  public hasRequiredField(abstractControl: AbstractControl): boolean {
    // This is a workaround because Angular gives us no clean way to observe the validators on a formControl
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  private setSelections(selections: AutocompleteOption[]): void {
    this.selections = selections;
    this.chipListControl.setValue(selections);
    this.emitSelections();
    this.updateFilteredOptions();
    this.updateValidationErrors();
  }

  private selectById(id: string): void {
    const opt = this.options.find((a) => a.id === id);
    const selected = this.selections.find((a) => a.id === id);
    if (opt && !selected) {
      this.selections = [...this.selections, { ...opt }];
      this.chipListControl.setValue(this.selections);
      this.resetTextInput();
      this.emitSelections();
      this.chipSelected.next(opt);
      this.updateValidationErrors();
    }
  }

  private deselectById(id: string): void {
    const selected = this.selections.find((a) => a.id === id);
    if (selected) {
      const updatedSelections = this.selections.filter((s) => s.id !== id);
      this.selections = updatedSelections;
      this.cdr.detectChanges();
      this.chipListControl.setValue(this.selections);
      this.emitSelections();
      this.chipRemoved.next(selected);
      this.updateValidationErrors();
    }
  }

  private emitSelections(): void {
    this.onChange(this.selections); // updates the parent form
  }

  onSelectListClicked(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.selectListClicked.emit();
  }

  private updateValidationErrors(): void {
    if (this.parentControl && this.parentControl.control) {
      // we are getting validation from the parent form, so we apply any new validation errors after emitting the updated selections
      this.chipListControl.setErrors(this.parentControl.control.errors);
    } else {
      this.chipListControl.setErrors(null);
    }
  }

  private updateFilteredOptions(): void {
    if (!this.options || !this.options.length) {
      return;
    }
    let filteredOptions = [...this.options];
    if (this.selections && this.selections.length) {
      const selectedIds = this.selections.map((i) => i.id);
      filteredOptions = this.options.filter((i) => !selectedIds.includes(i.id));
    }
    if (this.textInputValue) {
      const t = this.textInputValue.toLowerCase();
      filteredOptions = filteredOptions.filter(
        (i) => i.title.toLowerCase().includes(t) || (this.showSubtitle && i.subtitle.toLowerCase().includes(t))
      );
    }
    this.filteredOptions$.next(filteredOptions);
  }

  private resetTextInput(): void {
    this.textInputControl.setValue('');
    if (this.textInput) {
      this.textInput.nativeElement.value = '';
    }
  }

  private clearSelections(): void {
    this.selections = [];
    this.cdr.detectChanges();
    this.chipListControl.setValue(this.selections);
    this.updateValidationErrors();
  }

  // BEGIN ControlValueAccessor implementation.
  public registerOnChange(onChange: never): void {
    this.onChange = onChange;
  }
  public registerOnTouched(onTouched: never): void {
    this.onTouched = onTouched;
  }
  public writeValue(values: AutocompleteOption[]): void {
    this.clearSelections();
    if (values && values.length) {
      this.setSelections(values);
    }
  }
  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.chipListControl.disable();
      this.textInputControl.disable();
    } else {
      this.chipListControl.enable();
      this.textInputControl.enable();
    }
  }
  // END ControlValueAccessor implementation

  onAddOptionClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.addOptionClick.emit();
  }
}
