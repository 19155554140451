<div class="si-datarow">
  <ng-content select="[start]"></ng-content>

  <ng-container *ngIf="text && !hideText">
    <fs-part-title *ngIf="hTag; else noneTitle" [hTag]="hTag" [classes]="headingClasses">
      <ng-container *ngTemplateOutlet="textContent"></ng-container>
      <ng-content select="[titleText]"></ng-content>
    </fs-part-title>
    <ng-template #noneTitle>
      <ng-container *ngTemplateOutlet="textContent"></ng-container>
      <ng-content select="[titleText]"></ng-content>
    </ng-template>
  </ng-container>
  <ng-content select="[middle]"></ng-content>

  <ng-container *ngIf="asideText">
    <span class="si-expanse si-visible--beyond-bp-12"></span>
    <fs-comp-delimit-text
      *ngIf="asideText.length > 1 && noDelimitAsideText; else default"
      [disableLinks]="disableLinks"
      [set]="asideText"
      [delimiter]="delimiter"
      [spaced]="spaced"
    ></fs-comp-delimit-text>
    <ng-template #default>
      <fs-part-text
        *ngFor="let asideTextItem of asideText"
        class="si-parttext__wrap"
        [disableLinks]="disableLinks"
        [text]="asideTextItem"
      ></fs-part-text>
    </ng-template>
  </ng-container>
  <ng-content select="[end]"></ng-content>
</div>

<ng-template #textContent>
  <fs-comp-delimit-text
    *ngIf="text.length > 1 && !noDelimitText; else default"
    [disableLinks]="disableLinks"
    [set]="text"
    [delimiter]="delimiter"
    [spaced]="spaced"
  ></fs-comp-delimit-text>
  <ng-template #default>
    <fs-part-text
      *ngFor="let textItem of text"
      class="si-parttext__wrap"
      [disableLinks]="disableLinks"
      [text]="textItem"
    ></fs-part-text>
  </ng-template>
</ng-template>
