<ng-container
  *ngIf="{
    loading: loading$ | async,
    error: error$ | async
  } as _"
>
  <div class="si-dialog__b">
    <form class="si-form">
      <div class="si-form__b">
        <div class="si-form__header">{{ loginHeaderText }}</div>
        <mat-form-field class="si-wd si-wd-full">
          <mat-label class="si-label">Login</mat-label>
          <input matInput [formControl]="usernameControl" class="si-input" (input)="clearError()" />
          <mat-error>Username is required</mat-error>
        </mat-form-field>
        <mat-form-field class="si-wd si-wd-full">
          <mat-label class="si-label">Password</mat-label>
          <input type="password" matInput [formControl]="passwordControl" class="si-input" (input)="clearError()" />
          <mat-error>Password is required</mat-error>
        </mat-form-field>
        <mat-error>{{ _.error }}</mat-error>
        <div class="si-action--row si-wd-full">
          <div
            class="mat-form-field mat-form-field-appearance-legacy mat-primary mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label"
          >
            <div class="mat-form-field-wrapper">
              <div class="si-input si-input--clickstack">
                <mat-checkbox class="si-checkbox" [formControl]="rememberMeControl">Remember Subscription</mat-checkbox>
              </div>
            </div>
          </div>
          <button
            mat-flat-button
            class="si-button si-button--primary"
            [class.si-state--disabled]="false"
            [disabled]="_.loading || usernameControl.invalid || passwordControl.invalid"
            (click)="login()"
          >
            <ng-container *ngIf="!_.loading">Login</ng-container>
            <mat-icon *ngIf="_.loading"><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>

        <div class="si-form__header">
          Institutional Login
          <button
            mat-icon-button
            class="si-icon"
            color="primary"
            matTooltip="If you have Institutional Access to SAE MOBILUS, using a predetermined organizational login utilizing single-sign-on (Shibboleth), please login below. To request institutional access to SAE MOBILUS, please complete the institutional subscription information form. Please contact your administrator if your institution is not listed."
          >
            <mat-icon aria-hidden="true">info</mat-icon>
          </button>
          <a
            routerLink="institutional-access-signup"
            mat-dialog-close
            mat-flat-button
            class="si-button--link si-button--secondary"
          >
            Sign-Up
          </a>
        </div>

        <div class="si-wd si-wd-full">
          <form
            [action]="loginUrl"
            method="post"
            encType="application/x-www-form-urlencoded"
            name="idplogin"
            #idploginForm
            ngNoForm
            onsubmit="return false;"
          >
            <input type="hidden" name="grantType" value="SSO" />
            <input type="hidden" name="targetUrl" value="" />
            <input type="hidden" name="entityId" value="" />
            <input type="hidden" name="state" value="" />
            <input type="hidden" name="xAuthToken" value="" />

            <mat-form-field class="si-wd si-wd-full">
              <mat-label class="si-label">Institution</mat-label>
              <input matInput [matAutocomplete]="identityProviderAuto" [formControl]="selectedProvider" />
              <mat-autocomplete
                #identityProviderAuto="matAutocomplete"
                [displayWith]="displayIDP"
                (optionSelected)="ssoLogin($event)"
              >
                <mat-option></mat-option>
                <ng-container *ngFor="let idp of filteredProviders | async">
                  <mat-option [value]="idp">{{ idp.name }}</mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      </div>
    </form>
  </div>
</ng-container>
