import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { RangeFacetData, ValueFacetData } from '../../models/api-models';
import { UISearchFacet } from '../../models/common-interfaces';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
  selector: 'mobi-slider-facet-expanel',
  templateUrl: './slider-facet-expanel.component.html',
  standalone: true,
  styleUrls: ['slider-facet-expanel.component.scss'],
  imports: [
    MatSliderModule,
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,

    ReactiveFormsModule,
  ],
})
export class SliderFacetExpanelComponent implements OnInit {
  private _filters: { to?: string; from?: string };
  @Input() facet: UISearchFacet<ValueFacetData>;
  @Input() set filters(filters: { to?: string; from?: string }) {
    this._filters = filters ?? {};
  }
  get filters(): { to?: string; from?: string } {
    return this._filters;
  }
  @Input() isPreservedFacet = false;
  @Input() isFilterApplied = false;
  @Output() applyFilterClicked = new EventEmitter();
  @Output() removeFilterClicked = new EventEmitter();
  @Output() clearPreservedFacetClicked = new EventEmitter();
  @Output() expandAggregationClicked = new EventEmitter();
  @Output() collapseAggregationClicked = new EventEmitter();
  @Output() dateChanged = new EventEmitter();
  @Output() dateFromChanged = new EventEmitter();
  @Output() dateToChanged = new EventEmitter();

  panelExpand = false;
  selectedCount = 0;
  debounceTime = 450;
  currentDate = new Date();
  minControl = new FormControl(0);
  maxControl = new FormControl();
  minValue = 0;
  maxValue = 0;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.facet?.data?.forEach((d) => {
      if (+d?.value > this.maxValue) {
        this.maxValue = +d?.value;
      }
    });
    this.maxControl.setValue(this.maxValue);
    this.maxControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          if (this.maxControl.invalid) {
            this.maxControl.markAsTouched();
          } else {
            this.toggleSelection(true);
          }
        })
      )
      .subscribe();

    this.minControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          if (this.minControl.invalid) {
            this.minControl.markAsTouched();
          } else {
            this.toggleSelection(true);
          }
        })
      )
      .subscribe();
  }

  toggleSelection(checked: boolean): void {
    if (this.minControl.valid && this.maxControl.valid) {
      if (checked) {
        this.applyFilterClicked.emit([this.minControl.value, this.maxControl.value]);
      } else if (checked !== undefined) {
        this.removeFilterClicked.emit();
      }
    }
  }

  clearPreservedFacet(): void {
    this.clearPreservedFacetClicked.emit();
  }

  expandThisAggregation(alreadyExpanded: boolean): void {
    if (!alreadyExpanded) {
      this.expandAggregationClicked.emit();
    }
  }

  collapseThisAggregation(alreadyCollapsed: boolean): void {
    if (!alreadyCollapsed) {
      this.collapseAggregationClicked.emit();
    }
  }
}
