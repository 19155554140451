<div [attr.aria-label]="ariaLabel" class="mat-chip-list si-chiplist si-chiplist--seo si-chiplist--tags">
  <div class="mat-chip-list-wrapper">
    <ng-content select="[beforeChipsContent]"></ng-content>
    <ng-container *ngFor="let chip of chips">
      <ng-container *ngIf="(!chipsMenu || chipsMenu.length === 0) && chip.clickEventEmitter !== true">
        <a
          [routerLink]="chip.href ?? null"
          [fragment]="chip.fragment ?? null"
          [matTooltip]="chip.tooltip ?? null"
          [attr.aria-label]="chip.tooltip ?? null"
          [ngClass]="chip.classObject"
          [attr.data-analytics]="chip.analyticsId ?? null"
        >
          <ng-container *ngTemplateOutlet="chipTemplate; context: { chip: chip }"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="(!chipsMenu || chipsMenu.length === 0) && chip.clickEventEmitter === true">
        <a
          (click)="onChipClicked(chip, $event)"
          [matTooltip]="chip.tooltip ?? null"
          [attr.aria-label]="chip.tooltip ?? null"
          [ngClass]="chip.classObject"
          [attr.data-analytics]="chip.analyticsId ?? null"
        >
          <ng-container *ngTemplateOutlet="chipTemplate; context: { chip: chip }"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="chipsMenu?.length > 0">
        <a
          [matMenuTriggerFor]="optionsMenu"
          [matTooltip]="chip.tooltip ?? null"
          [attr.aria-label]="chip.tooltip ?? null"
          [ngClass]="chip.classObject"
          [attr.data-analytics]="chip.analyticsId ?? null"
        >
          <ng-container *ngTemplateOutlet="chipTemplate; context: { chip: chip }"></ng-container>
        </a>
        <mat-menu #optionsMenu="matMenu" class="si-menu">
          <si-more-menu
            [options]="chipsMenu"
            [onlyRenderOptions]="true"
            (optionSelected)="onOptionSelected($event, chip.label)"
          ></si-more-menu>
        </mat-menu>
      </ng-container>
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>

<ng-template #chipTemplate let-chip="chip">
  <ng-container *ngIf="chip.icon && chip.iconPos === 'left'">
    <ng-container *ngTemplateOutlet="iconTemplate; context: { chip: chip }"></ng-container>
  </ng-container>
  <div
    [class.si-chiplist--label]="ellipsisOverflow"
    [class.si-chiplist--icon__left]="chip.icon && chip.iconPos === 'left'"
  >
    {{ chip.label }}
  </div>
  <ng-container *ngIf="chip.icon && chip.iconPos !== 'left'">
    <ng-container *ngTemplateOutlet="iconTemplate; context: { chip: chip }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #iconTemplate let-chip="chip">
  <mat-icon
    *ngIf="chip.icon"
    class="si-chiplist--icon"
    [class.si-chiplist--icon__right]="chip.iconPos !== 'left'"
    (click)="onIconClicked(chip, $event)"
  >
    {{ chip.icon }}
  </mat-icon>
</ng-template>

<!-- NOTE: This hidden div containing a mat-chip-list is vital to this component. It causes Material to load its base
     chip styles which we are depending on. If ommitted, this will cause display issues unless Material somehow already
     loaded its chip stylesheets dynamically elsewhere by virtue of seeing its own chip component being used. -->
<div hidden>
  <mat-chip-list aria-label="hidden">
    <mat-chip></mat-chip>
  </mat-chip-list>
</div>
