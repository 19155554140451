<ng-container
  *ngIf="{
    journalBrxDetail: journalBrxDetail$ | async
  } as _"
>
  <div class="si-content__detail">
    <div class="si-data si-data--grid si-wd-full">
      <mat-accordion class="si-expanel__container si-expanel--singleton" [multi]="true">
        <mat-expansion-panel class="si-expanel si-expanel--impact mat-elevation-z" [expanded]="true">
          <mat-expansion-panel-header class="si-expanel__h">
            <mat-panel-title class="si-title">
              <strong>Author Resources</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="si-expanel__c">
            <div class="si-data si-data--grid">
              <si-data-output
                class="si-data__set si-wd si-wd-full"
                [content]="_.journalBrxDetail?.authorResources"
              ></si-data-output>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-container>
