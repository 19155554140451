import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartButtonComponent } from '../../parts/part-button/part-button.component';
import { LabelContent, PartLabelComponent } from '../../parts/part-label/part-label.component';
import { TextContent, PartTextComponent } from '../../parts/part-text/part-text.component';
import { PartTitleComponent } from '../../parts/part-title/part-title.component';
import { RowTextContent, CompRowTextComponent } from '../comp-row-text/comp-row-text.component';
import { overrideInputs } from '@sae/base';

export interface ReturnButton {
  classes?: string;
  label: string;
  url?: string;
}

export interface HeadingContent {
  classes?: string;
  label?: LabelContent;
  heading?: TextContent;
  headingCount?: number;
  headingClasses?: string;
  headingRow?: RowTextContent;
  hTag?: number;
  returnButton?: ReturnButton;
}

@Component({
  selector: 'fs-comp-util-heading',
  styleUrls: ['comp-util-heading.component.scss'],
  templateUrl: './comp-util-heading.component.html',
  standalone: true,
  imports: [
    CommonModule,
    PartButtonComponent,
    PartLabelComponent,
    PartTextComponent,
    PartTitleComponent,
    CompRowTextComponent,
  ],
})
export class CompUtilHeadingComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: HeadingContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string; // Optional, provides a CSS class for the output wrapper
  @Input() label: LabelContent; // Optional, adds a label
  @Input() heading: TextContent; // Optional, adds a title header containing the specified text
  @Input() headingCount = -1; // Optional, adds a count to the heading
  @Input() headingClasses: string; // Optional, provides a CSS class for the heading
  @Input() headingRow: RowTextContent; // Optional, adds a row of text
  @Input() hTag = 0; // Optional (for heading only), 0 uses label, 1-6 uses title
  @Input() returnButton: ReturnButton; // Optional, adds a return button
  // @Input() headingUrl: string; // Optional, used in conjection with title, makes the title a clickable link
  @Input() content: HeadingContent; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }
  }
}
