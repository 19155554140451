<div class="si-dialog si-col">
  <div class="si-col--main si-wd si-wd-half">
    <div class="si-dialog__h">
      <mat-icon class="si-icon si-dialog__icon" aria-hidden="true">verified_user</mat-icon>
      <h2 class="si-dialog__title">Subscription Access</h2>
      <span class="si-expanse"></span>
      <button
        mat-icon-button
        matTooltip="Close"
        aria-label="Close"
        class="si-icon si-button--close si-visible--only-mobile"
        mat-dialog-close
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mobi-subscription-login-form
      [loginHeaderText]="loginHeaderText"
      [LoginFrom]="LoginFrom"
    ></mobi-subscription-login-form>
  </div>

  <div class="si-col--aux si-wd si-wd-half">
    <div class="si-dialog__h">
      <span class="si-expanse"></span>
      <button
        mat-icon-button
        matTooltip="Close"
        aria-label="Close"
        class="si-icon si-button--close si-visible--beyond-mobile"
        mat-dialog-close
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="si-dialog__b">
      <div class="si-form__header">Subscribe</div>
      <p>
        SAE Mobilus is the only engineering database that offers the mobility industry access to resources that help
        move the mobility industry forward. This platform is continuously evolving and features like intuitive search,
        annotations, redlining and customization help you find solutions faster.
      </p>

      <div mat-dialog-actions class="si-dialog__a">
        <button mat-flat-button class="si-button si-button--secondary" (click)="clickContactSales()">
          Contact Sales
        </button>
        <button mat-flat-button class="si-button si-button--primary" (click)="clickProductPortfolio()">
          Product Portfolio
        </button>
      </div>
    </div>
  </div>
</div>
