import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CriteriaDetails, saveSearchResponse } from '../models/search-result.model';
import { SearchManagerApiService } from './search-manager-api.service';

@Injectable({
  providedIn: 'root',
})
export class SearchManagerUiService {
  constructor(private service: SearchManagerApiService) {}

  public postCriteria(details: CriteriaDetails, searchName: string): Observable<saveSearchResponse> {
    return this.service.postCriteria({ ...details, searchName });
  }

  public getSuggestionsV2(searchTerm: string): Observable<string[]> {
    return this.service
      .getSuggestionsV2({
        query: searchTerm,
        size: 5,
        types: {
          documents: {
            fields: ['product_code', 'title', 'authors'],
          },
        },
      })
      .pipe(
        map((res) => res.results.documents),
        map((documents) => documents.map((documents) => documents?.suggestion))
      );
  }
}
