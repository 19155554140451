<div class="si-paginator--seo">
  <div class="si-paginator--seo__mat-paginator">
    <div>
      <mat-paginator
        class="si-paginator"
        [length]="_length"
        [pageSize]="_pageSize"
        [pageIndex]="_pageIndex"
        [pageSizeOptions]="pageSizeOptions"
        (page)="onPage($event)"
        (mouseup)="onMouseDown()"
      ></mat-paginator>
    </div>
    <div>
      <a
        (click)="onNavigate(_pageIndex)"
        [routerLink]="_pageIndex === 0 ? null : _pageIndex === 1 ? baseURL : baseURL + '/' + _pageIndex"
        [fragment]="hashURL ?? null"
        [title]="'Previous Page'"
        mat-icon-button
        color="primary"
        class="si-icon"
        [disabled]="_pageIndex === 0"
      >
        <mat-icon aria-hidden="true">arrow_back_ios_new</mat-icon>
      </a>
      <a
        (click)="onNavigate(_pageIndex + 2)"
        [routerLink]="_pageIndex === lastPage - 1 ? null : baseURL + '/' + (_pageIndex + 2)"
        [fragment]="hashURL ?? null"
        [title]="'Next Page'"
        mat-icon-button
        color="primary"
        class="si-icon"
        [disabled]="_pageIndex === lastPage - 1"
      >
        <mat-icon aria-hidden="true">arrow_forward_ios_new</mat-icon>
      </a>
    </div>
  </div>
  <div class="si-paginator--seo__pages">
    <div [attr.aria-label]="'Pages'" class="mat-chip-list si-chiplist si-chiplist--seo si-chiplist--tags">
      <div class="mat-chip-list-wrapper">
        <ng-container *ngFor="let page of pages; let index = index; let first = first">
          <ng-container *ngIf="!first && pages[index] - pages[index - 1] > 1">
            <span class="si-paginator--expanse">...</span>
          </ng-container>
          <a
            (click)="onNavigate(page)"
            [routerLink]="page === 1 ? baseURL : baseURL + '/' + page"
            [fragment]="hashURL ?? null"
            [matTooltip]="(_pageIndex + 1 === page ? 'Currently on ' : '') + 'Page #' + page"
            [attr.aria-label]="(_pageIndex + 1 === page ? 'Currently on ' : '') + 'Page #' + page"
            class="mat-chip mat-primary mat-standard-chip si-chip si-paginator--chip"
            [class.si-paginator--chip__selected]="_pageIndex + 1 === page"
          >
            <span>{{ page }}</span>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- NOTE: This hidden div containing a mat-chip-list is vital to this component. It causes Material to load its base
     chip styles which we are depending on. If ommitted, this will cause display issues unless Material somehow already
     loaded its chip stylesheets dynamically elsewhere by virtue of seeing its own chip component being used. -->
<div hidden>
  <mat-chip-list aria-label="hidden">
    <mat-chip></mat-chip>
  </mat-chip-list>
</div>
