import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartIconComponent } from '../part-icon/part-icon.component';
import { TextContent, PartTextComponent } from '../part-text/part-text.component';
import { overrideInputs } from '@sae/base';

export interface LabelContent {
  classes?: string;
  disabled?: boolean;
  eclipsed?: boolean;
  error?: boolean;
  forId?: string;
  label: TextContent;
  required?: boolean;
  tooltip?: string;
}

@Component({
  selector: 'fs-part-label',
  styleUrls: ['part-label.component.scss'],
  templateUrl: './part-label.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, PartIconComponent, PartTextComponent],
})
export class PartLabelComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: LabelContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string; // Optional, provides a CSS class for the heading
  @Input() disabled: boolean; // Optional, if true, the label will be disabled
  @Input() eclipsed = false; // Optional, if true, the label will be eclipsed with an ellipsis if it's too long
  @Input() error: boolean; // Optional, if true, the label will be styled as an error
  @Input() forId: string; // Optional, the ID of the element this label is for
  @Input() required = false; // Optional, if true, the label will have an asterisk
  @Input() label: TextContent;
  @Input() tooltip: string;
  @Input() content: LabelContent; // [DEPRECATED - Will be removed] Used instead of other inputs

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }
  }
}
