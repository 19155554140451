import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'si-autocomplete-hidden-value',
  templateUrl: './autocomplete-hidden-value.component.html',
})
export class AutocompleteHiddenValueComponent implements OnInit {
  @Input() label = 'Enter Code'; // Label to display over input field
  @Input() loadingMessage = 'Loading...'; // Text to display when a trigger to parse input occurs
  @Input() validMessage = `"123" is a valid code`; // Title text to display when parsed input is valid
  @Input() validActionPrompt = 'Press enter or click to apply code'; // Subtitle text to display when parsed input is valid
  @Input() invalidMessage = `"123" is not a valid code`; // Title text to display when parsed input is invalid
  @Input() invalidActionPrompt = 'Continue typing or try another'; // Subtitle text to display when parsed input is invalid
  @Input() removable = true; // Whether the chips can be removed
  @Input() chips: string[] = []; // A string array of chips for the widget
  @Input() maxItems = 0; // Maximum items to allow for selection (0 = unlimited)
  @Input() debounceTime = 450; // Time to wait for user input to settle before dispatching a searchChange event
  @Input() minCharsForSearch = 3; // Minimum number of characters required before dispatching a searchChange event
  @Input() showLoadingCard = false; // Whether to display the loading card state
  @Input() showResultsInvalidCard = false; // Whether to display the invalid results card state
  @Input() showResultsValidCard = false; // Whether to display the valid results card state
  @Input() applyADAFix = false; // When set to true, fixes wcag412 by adding a hidden chip to the chiplist, however this causes the mat floating label to float as it thinks there is content to trigger the auto behavior

  @Output() inputKeyDown = new EventEmitter<string>(); // Event that triggers immediately whenever the input field value is affected by a key press
  @Output() searchChange = new EventEmitter<string>(); // Event that triggers after debounceTime has settled, sufficient characters are present, and the string is not already within the chips array
  @Output() valueSelected = new EventEmitter<string>(); // Event that triggers when the user selects the results valid card, via click on it or pressing enter within the input field
  @Output() valueRemoved = new EventEmitter<string>(); // Event that triggers when the user removes a chip value

  @ViewChild('userInputField', { static: true }) userInputField: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    fromEvent<KeyboardEvent>(this.userInputField.nativeElement, 'keyup')
      .pipe(
        tap((event: KeyboardEvent) => {
          this.inputKeyDown.emit(this.userInputField.nativeElement.value);

          if (event.key === 'Enter' && this.showResultsValidCard) {
            this.selectValue();
          }
        })
      )
      .subscribe();
  }

  performSearch(term: string): void {
    if (this.chips.indexOf(this.userInputField.nativeElement.value) === -1) {
      this.searchChange.emit(term);
    }
  }

  selectValue() {
    this.valueSelected.emit(this.userInputField.nativeElement.value);

    this.userInputField.nativeElement.value = '';
    this.userInputField.nativeElement.focus();
  }

  removeChip(chip: string): void {
    this.valueRemoved.emit(chip);

    this.userInputField.nativeElement.focus();
  }
}
