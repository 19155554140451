<ng-container
  *ngIf="{
    isToolPanelCollapsed: isToolPanelCollapsed$ | async,
    showScrim: showScrim$ | async
  } as _"
>
  <!-- TODO: this should be refactored to include the column divs and <div libDetectBreakpointWidth> within each-->
    <ng-container *ngIf="_.showScrim">
      <si-effects-directing-scrim (scrimClosed)="onScrimClosed()"></si-effects-directing-scrim>
    </ng-container>  
  <div class="si-takeover">
    <header class="si-takeover__h">
      <ng-template [cdkPortalOutlet]="headerContentPortal" *ngIf="projectedContent?.headerContent"></ng-template>
    </header>
    <main class="si-takeover__c si-takeover__c--panels">
      <div
        class="si-columns si-contains--aux si-print--stack"
        [class.si-state--collapsed--tools]="_.isToolPanelCollapsed"
        [class.si-state--collapsed-not--tools]="!_.isToolPanelCollapsed"
      >
        <ng-template [cdkPortalOutlet]="detailContentPortal" *ngIf="projectedContent?.detailContent"></ng-template>
        <ng-template [cdkPortalOutlet]="toolContentPortal" *ngIf="projectedContent?.toolContent"></ng-template>
      </div>
    </main>
  </div>
</ng-container>

<!-- Example of a child component that implements Detail Tool template -->
<!-- <div #headerContent>
  <si-takeover-pagination title="Back to Results" [currentPage]="1" [totalPages]="4"></si-takeover-pagination>
</div>
  <div #detailContent class="si-col si-col--detail">
    <div libDetectBreakpointWidth>
      <div class="si-content">
        <header class="si-content__h">
          <h1 class="si-content__title">Detail Tool Detail Panel Header</h1>
        </header>
        <div class="si-content__c">
          <div class="si-content__detail">
          </div>
        </div>
      </div>
    </div>
</div>
<div #toolContent class="si-col si-col--aux" [class.si-visible--below-medium-tablet]="_.isToolPanelCollapsed">
    <div libDetectBreakpointWidth>
      <div class="si-col__set">
        <div class="si-col__h">
          <button mat-mini-fab class="si-button si-button--collapse" aria-hidden="true" matTooltip="Collapse Revisions Panel" tabindex="-1">
            <div class="si-icon">
              <span class="icon-arrow-collapse-right"></span>
              <span class="si-visible--sr-only">Collapse Revisions Panel</span>
            </div>
          </button>
          <mat-toolbar class="si-toolbar si-toolbar--tool">
            <div class="si-toolbar__set">
              <div class="si-toolbar__h">
                <h3 class="si-toolbar__title">Tool Example Title</h3>
              </div>
            </div>
          </mat-toolbar>
        </div>

        <div class="si-col__c">
          <mat-list class="si-list si-list--wrap si-list--master">
            <mat-list-item class="si-list__item">
              <si-basic-card title="Title" subTitle="subTitle"></si-basic-card>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
</div> -->
