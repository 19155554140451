<button class="si-button--wrap" mat-button>
  <mat-card class="si-card si-card--basic si-card--tidbit"
    [class.si-state--unread]="unread"
    [class.si-state--read]="!unread" 
    [class.si-state--selected]="selected"
    [class.mat-elevation-z]="wrapper !== 'Normal'"
  >
    <mat-card-header class="si-card__h">
      <div mat-card-avatar class="si-avatar">
        <mat-icon class="si-icon" color="primary" *ngIf="materialIcon">{{materialIcon}}</mat-icon>
      </div>
      <mat-card-title class="si-card__h si-card__title">
        <span [innerHTML]="titleText"></span>
        <span class="si-expanse si-visible--beyond-mobile"></span>
        <span class="si-card__date si-for--minor si-visible--beyond-mobile" *ngIf="when">{{when}}</span>
      </mat-card-title>
      <mat-card-subtitle class="si-card__subtitle si-visible--only-mobile" *ngIf="when">{{when}}</mat-card-subtitle>
      <mat-card-subtitle class="si-card__subtitle" *ngIf="subTitle" [innerHTML]="subTitle"></mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</button>