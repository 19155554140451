<ng-container
  *ngIf="{
    enterpriseMenu: enterpriseMenu$ | async,
    appLogoClass: appLogoClass$ | async,
    appName: appName$ | async,
    showHomeButton: showHomeButton$ | async
  } as _"
>
  <button
    [ngClass]="['si-button', 'si-button--enterprise', _.appLogoClass]"
    mat-button
    *ngIf="_.enterpriseMenu?.apps?.length < 1"
    (click)="goToHome()"
  >
    <img
      [class.si-visible--beyond-mobile]="_.enterpriseMenu?.images.appLogoMobile"
      [src]="_.enterpriseMenu?.images.appLogo"
      [alt]="_.appName"
    />
    <img
      *ngIf="_.enterpriseMenu?.images.appLogoMobile"
      class="si-visible--only-mobile"
      [src]="_.enterpriseMenu?.images.appLogoMobile"
      [alt]="_.appName"
    />
  </button>
  <button
    [ngClass]="['si-button', 'si-button--enterprise', _.appLogoClass]"
    mat-button
    [matMenuTriggerFor]="menu"
    *ngIf="_.enterpriseMenu?.apps?.length > 0"
  >
    <img
      class="si-svg"
      [class.si-visible--beyond-mobile]="_.enterpriseMenu?.images.appLogoMobile"
      [src]="_.enterpriseMenu?.images.appLogo"
      [alt]="_.appName"
    />
    <img
      *ngIf="_.enterpriseMenu?.images.appLogoMobile"
      class="si-visible--only-mobile si-svg"
      [src]="_.enterpriseMenu?.images.appLogoMobile"
      [alt]="_.appName"
    />
    <mat-icon class="si-icon" aria-hidden="true">expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="si-menu si-menu--enterprise">
    <ng-container *ngIf="_.showHomeButton">
      <button mat-menu-item data-testid="home" (click)="goToHome()">
        <mat-icon aria-hidden="true" color="primary">arrow_back</mat-icon>
        <span>Return to Homepage</span>
      </button>
    </ng-container>
    <mat-divider class="si-divider"></mat-divider>
    <ng-container *ngFor="let app of _.enterpriseMenu?.apps">
      <a [href]="app.href" mat-menu-item [class.si-state--selected-not]="!app.currentApp" *ngIf="app.show">
        <mat-icon aria-hidden="true" color="primary">check</mat-icon>
        <span>{{ app.label }}</span>
      </a>
    </ng-container>
    <mat-divider class="si-divider"></mat-divider>
    <div class="si-brandblock">
      <a class="si-brandblock__logo" [href]="_.enterpriseMenu?.links.saeOrg" target="_blank">
        <img
          [src]="_.enterpriseMenu?.images.saeLogo"
          [alt]="_.enterpriseMenu?.images.saeLogoAlt ?? 'SAE International'"
        />
      </a>
      <div class="si-brandblock__links">
        <div *ngIf="_.enterpriseMenu?.links.about">
          <a [href]="_.enterpriseMenu?.links.about" data-testid="about" target="_blank">About</a>
        </div>
        <div *ngIf="_.enterpriseMenu?.links.contact">
          <a [href]="_.enterpriseMenu?.links.contact" data-testid="contact" target="_blank">Contact</a>
        </div>
        <div *ngIf="_.enterpriseMenu?.links.legalPolicies">
          <a [href]="_.enterpriseMenu?.links.legalPolicies" data-testid="policies" target="_blank">
            Legal &amp; Policies
          </a>
        </div>
      </div>
    </div>
  </mat-menu>
</ng-container>
