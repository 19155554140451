import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'si-search-input',
  templateUrl: './search-input.component.html',
})
export class SearchInputComponent implements AfterViewInit {
  /** WARNING: Used in template to link label and input for ADA compliance, do not use!   */
  public uniqueId = new Date().getTime();
  @Input() minChars = 2;
  @Input() debounceTime = 400;
  @Input() type!: string;
  @Input() suggestion = 'Find';
  @Input() defaultSearch = '';
  @Input() toolTipText = '';
  @Input() hideTooltTip = true;
  @Output() blurred = new EventEmitter();
  @Output() changed = new EventEmitter<string>();
  @Output() keyUp = new EventEmitter<KeyboardEvent>();
  @ViewChild('searchInput') searchInput!: ElementRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
    this.cd.detectChanges();
  }

  public emitBlur(): void {
    this.blurred.emit();
  }

  public emitKeyUp(event: KeyboardEvent): void {
    this.keyUp.emit(event);
  }

  public emitSearch(value: string): void {
    this.changed.emit(value);
  }

  public clearSearch(): void {
    this.searchInput.nativeElement.value = '';
    const event = new Event('change');
    this.searchInput.nativeElement.dispatchEvent(event);
  }
}
