import { AfterViewInit, Component, Input } from '@angular/core';
import { ContentView } from '../../../models/content.model';

@Component({
  selector: 'mobi-doi-data-output',
  templateUrl: './doi-data-output.component.html',
})
export class DoiDataOutputComponent implements AfterViewInit {
  @Input() content: ContentView;
  @Input() title: string;

  ngAfterViewInit(): void {
    setTimeout(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__dimensions_embed.addBadges();
      } catch (ignored) {
        // Fallback
      }
    });
  }
}
