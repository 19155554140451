import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'si-filter-chip-navigation',
  templateUrl: './filter-chip-navigation.component.html',
})
export class FilterChipNavigationComponent {
  @Input() chipText = '';
  @Input() cancelTooltip = 'Remove';
  @Input() previousTooltip = 'Previous';
  @Input() nextTooltip = 'Next';

  @Output() chipClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();
  @Output() backClicked = new EventEmitter();
  @Output() nextClicked = new EventEmitter();

  onChipClick() {
    this.chipClicked.emit();
  }

  onCancelClick() {
    this.cancelClicked.emit();
  }

  onBackClick() {
    this.backClicked.emit();
  }

  onNextClick() {
    this.nextClicked.emit();
  }
}
