<ng-template #template>
  <ng-container
    *ngIf="{
      content: content$ | async,
      categoryNodes: categoryNodes$ | async
    } as _"
  >
    <si-enhanced-tree
      [treeNodes]="_.categoryNodes"
      [expandOnNodeClick]="true"
      [linkMode]="true"
      (nodeClick)="onPubVolumeClicked($event, _.categoryNodes, _.content)"
    ></si-enhanced-tree>
  </ng-container>
</ng-template>
