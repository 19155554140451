import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TextContent } from '../../parts/part-text/part-text.component';
import { CommonModule } from '@angular/common';
import { PartTextComponent } from '../../parts/part-text/part-text.component';
import { PartTitleComponent } from '../../parts/part-title/part-title.component';
import { CompDelimitTextComponent } from '../comp-delimit-text/comp-delimit-text.component';
import { overrideInputs } from '@sae/base';

export interface RowTextContent {
  text: TextContent[];
  asideText?: TextContent[];
  hTag?: number;
  headingClasses?: string;
  delimiter?: string;
  noDelimitText?: boolean;
  noDelimitAsideText?: boolean;
  spaced?: boolean;
}

@Component({
  selector: 'fs-comp-row-text',
  styleUrls: ['comp-row-text.component.scss'],
  templateUrl: './comp-row-text.component.html',
  standalone: true,
  imports: [CommonModule, PartTextComponent, PartTitleComponent, CompDelimitTextComponent],
})
export class CompRowTextComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: RowTextContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() disableLinks = false; // Allows forcibly disabling links to avoid ADA issues (for when inside of a link)
  @Input() text: TextContent[];
  @Input() asideText: TextContent[];
  @Input() hTag = 0; // Wraps title around text if between 1 and 6.
  @Input() headingClasses = ''; // Optional classes for heading
  @Input() delimiter = '&#183;'; // Use another HTML character for the delimiter
  @Input() noDelimitText = false; // Does not delimit the text
  @Input() noDelimitAsideText = false; // Does not delimit the asideText
  @Input() spaced = false; // Spaces the segments out
  @Input() hideText = false; // Hides the text, but not the asideText
  @Input() rowText: RowTextContent; // [DEPRECATED - Will be removed] If set, all other inputs are ignored (except delimiter)

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['rowText']) {
      overrideInputs(this, this.rowText);
    }
  }
}
