import { createReducer, on } from '@ngrx/store';
import { DetailActions } from '.';
import { initialDetailState } from './detail.state';

export const detailReducer = createReducer(
  initialDetailState,
  on(DetailActions.getAssets, (state) => ({ ...state, loadingAssets: true })),
  on(DetailActions.getAssetsSucceeded, (state) => ({ ...state, loadingAssets: false })),
  on(DetailActions.getAssetsFailed, (state) => ({ ...state, loadingAssets: false })),
  on(DetailActions.getContentDataSets, (state) => ({ ...state, loadingAssets: true })),
  on(DetailActions.getContentDataSetsSucceeded, (state, { results, pagination }) => ({
    ...state,
    dataSets: results,
    pagination,
    loadingAssets: false,
  })),
  on(DetailActions.changeSortDirection, (state, { sortDir }) => ({ ...state, sortDir: sortDir }))
);
