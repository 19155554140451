import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartButtonComponent } from '../../parts/part-button/part-button.component';
import { overrideInputs } from '@sae/base';

export interface CompCardSelectorConfig {
  selected?: boolean;
}

@Component({
  selector: 'fs-comp-card-selector',
  styleUrls: ['comp-card-selector.component.scss'],
  templateUrl: './comp-card-selector.component.html',
  imports: [CommonModule, PartButtonComponent],
  standalone: true,
})
export class CompCardSelectorComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: CompCardSelectorConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() selected = false;

  @Output() selectedChange = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }
}
