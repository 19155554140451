import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ISessionService, SESSION_TOKEN } from '@sae/services';
import { FeedbackBottomSheetComponent } from '../feedback-bottom-sheet/feedback-bottom-sheet.component';

interface ExternalUrl {
  url: string;
  label: string;
  linkButtonText: string;
  icon?: string;
}

export interface Feature {
  icon: string;
  name: string;
  tooltip?: string;
  url?: string;
  disabled?: boolean;
  externalUrl?: ExternalUrl;
  externalUrls?: Array<ExternalUrl>;
  id?: string;
  analyticsId?: string;
}

@Component({
  selector: 'si-seo-feature-toolbar',
  templateUrl: './seo-feature-toolbar.component.html',
})
export class SeoFeatureToolbarComponent {
  @Input() activeNavIcon = '';
  @Input() showMobileNavMenu = false;
  @Input() features: Feature[] = [];
  @Input() showHomeFeature = false;
  @Input() showSearchFeature = false;
  @Input() showUpdatesFeature = true;
  @Input() showHelpFeature = true;
  @Input() updatesRoute = '/';
  @Input() updatesLabel = 'Updates';

  @Output() featureClicked = new EventEmitter();
  active = false;

  feedbackConfig$ = this.sessionService.feedbackConfig$;

  constructor(
    @Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService,
    private readonly bs: MatBottomSheet
  ) {}

  setActiveNavIcon(activeNavIcon: string): void {
    this.featureClicked.emit(activeNavIcon);
  }

  showFeedbackBottomsheet(): void {
    this.bs.open(FeedbackBottomSheetComponent);
  }
}
