import { Sort } from '@angular/material/sort';
import { AutocompleteOption } from './autocomplete.model';
import { MenuItem } from './overflow-menu.model';
import { Pagination } from './pagination.model';
import { DisplayUser } from './user.model';
import { MoreOption } from './more-menu.model';

export interface Resource {
  id: string;
  anchorId: string;
  creationDate: string;
  deleted: boolean;
  modificationDate: string;
  name: string;
  resourceType: ResourceType;
  accessibility?: 'AVAILABLE' | 'ARCHIVE';
  applicationCodes?: string[];
  createdBy?: string;
  createdByUser?: DisplayUser;
  edited?: boolean;
  description?: string;
  file?: Blob;
  fileType?: string; // This will hold the file ext (from metadata) so Akita can sort on it
  metadata?: ResourceMetadata;
  modifiedBy?: string; // holds the lastname, firstname string so Akita can sort on it
  modifiedByUser?: DisplayUser;
  path?: Array<Anchor>; // Hold onto the anchorsItems from ResourceResponse
  resourceShares?: ResourceShare[];
  security?: Security;
  toggled?: boolean; // Used in multiselect enabled views to determine whether this resource is toggled on/off for action triggers
  menuOptions?: MoreOption[]; // Optional, if specified, uses these options to the more menu (and ignores the enable* inputs)
  replies?: string;
}

export function createResource(params: Partial<Resource>): Resource {
  return { ...params } as Resource;
}

export enum ResourcePermissionTypes {
  VIEW = 'FOLDER_CONTENT_VIEW',
  ADD = 'FOLDER_CONTENT_ADD',
  EDIT = 'FOLDER_CONTENT_MODIFY',
  DELETE = 'FOLDER_CONTENT_DELETE',
  DOWNLOAD = 'RESOURCES_DOWNLOAD', // Note, the backend doesn't actually seem to use this!
  SHARE = 'FOLDER_CONTENT_SHARE',
}

export enum ResourceType {
  FOLDER = 'FOLDER',
  FILE = 'FILE',
  LINK = 'LINK',
  TOPLEVEL = 'TOPLEVEL',
  PUBLICATION = 'PUBLICATION',
  SURVEY = 'SURVEY',
  DISCUSSION = 'DISCUSSION',
  TOPIC = 'TOPIC'
}

export interface ResourceMetadata {
  id?: string;
  fileId?: string;
  resourceExtension?: string;
  resourceId?: string;
  resourceLink?: string;
  productCode?: string;
  rootProductCode?: string;
  title?: string;
  hash?: Hash[];
  location?: string;
  committeeCode?: string;
  committeeName?: string;
  mimeType?: string;
  originalFileName?: string;
  status?: string;
  uploadedBy?: string;
}

export interface Hash {
  algorithm: string;
  checksum: string;
}

export interface Permission {
  uuid: string;
  code: string;
  name: string;
}

export interface Anchor {
  resourceId: string;
  name: string;
}

export interface ResourcesResult {
  resources: Resource[];
  path: Anchor[];
  pagination: Pagination;
}

export interface ResourceResult {
  resource: Resource;
  path: Anchor[];
}

export interface BaseResponse {
  statusCode: number;
  statusMessage: string;
  pagination: Pagination;
  anchorsItems: Array<Anchor>;
}

export interface ResourceResponse extends BaseResponse {
  // when getting apiUrl/{id}...
  results: Resource; // ...results will be a single Resource.
}

export interface ResourcesResponse extends BaseResponse {
  // but when getting apiUrl?anchorId={id}...
  results: Array<Resource>; // ...results will be an array.
}

export enum ResourceSortKey {
  fileType = 'resourceType',
  modificationDate = 'modificationDate',
  modifiedBy = 'modifiedBy',
  name = 'name',
}

export enum ResourceMenuItem {
  addFolder = 'addFolder',
  addFile = 'addFile',
  addLink = 'addLink',
  addSurvey = 'addSurvey',
  itemDelete = 'itemDelete',
  modifiedTime = 'modifiedTime',
  modifiedByUser = 'modifiedByUser',
  share = 'share',
}

export interface Security {
  roles: Array<unknown>;
  permissions: Array<Permission>;
}

export interface ResourceManagerData {
  currentAnchor: Resource;
  children: Array<Resource>;
  breadcrumbs: Array<Anchor>;
}

export interface ResourceSortEvent {
  sort: Sort;
  currentAnchorId: string;
}

export interface ResourceMenuItemEvent {
  // apps can add custom menu items which when clicked will emit the following
  menuItem: MenuItem;
  currentAnchor: Resource;
  childResources: Resource[];
}

export interface UploadSubmission {
  fileName: string;
  resourceName: string;
  file: File;
  anchorId?: string;
}

export interface FilePostBody {
  fileId: string;
  fileName: string;
}

export interface DisplayModified {
  time: boolean;
  byUser: boolean;
}

export interface ResourceShare {
  name: string;
  type: string;
  typeId: string;
  description?: string;
  displayName?: string;
  shareId?: string;
  createdByUser?: ResourceUser;
  creationDate?: string;
}

export interface ResourceUser {
  displayName: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface ResourceShareApiResponse {
  description: string;
  errorMessage: string;
  result: ResourceShareResponse;
  statusCode: number;
  statusMessage: string;
}

export interface ResourceShareResponse {
  resourceId: string;
  resourceShare: ResourceShare;
}

export interface ResourceShareDialogData {
  rootResourceId: string;
  shareOptions: AutocompleteOption[];
  shareType: string; // e.g. 'committee' or 'workgroup'
}

export interface ResourceShareDialogResult {
  previousShares: ResourceShare[];
  newShares: ResourceShare[];
  addedShares: ResourceShare[];
  removedShares: ResourceShare[];
}

// Survey type resources - subject to change, as API design is still TBD
export interface SurveyResourceMetadata extends ResourceMetadata {
  // not offically part of API yet but we will at least need to pass this data around on the frontend
  startDate: Date;
  endDate: Date;
  secret?: boolean;
}

export interface SurveyResource extends Resource {
  metadata?: SurveyResourceMetadata;
}

export interface SurveyResourceDialogData {
  submitButtonLabel?: string;
}
