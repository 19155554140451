import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SessionState } from '@sae/services';
import { Stores } from '../../../../src/stores';
import { ViewTemplate } from '@sae/models';

export type ActiveNavIcon =
  | 'Home'
  | 'Search'
  | 'Browse'
  | 'My Library'
  | 'Shared'
  | 'Subscription'
  | 'Messages'
  | 'Messages'
  | 'Battery Cell';

export interface AppSessionState extends SessionState {
  showMenu: boolean;
  logResponse: boolean;
  template: string;
  useMockData: false;
  showAdvancedSearch: boolean;
  routeLoading: boolean;
  activeFilterCategories: string[];
  activeNavIcon: ActiveNavIcon;
  downloadedCids: number[];
}

export function createInitialState(): AppSessionState {
  return {
    showMenu: true,
    logResponse: false,
    template: 'm-d',
    useMockData: false,
    mockDataDelayInMs: 300,
    showMobileNavMenu: true,
    activeNavIcon: 'Home',
    activeViewTemplate: ViewTemplate.MasterDetail,
    isTakeoverActive: false,
    isToolPanelCollapsed: false,
    isMasterPanelCollapsed: false,
    masterWidth: 'normal',
    isWideFeature: false,
    showHomeButtonInEnterpriseMenu: true,
    showSearchNavIcon: false,
    showRegisterLoginOption: false,
    appName: 'ChangeThis',
    appLogoClass: 'si-brand--sae',
    masterHeadFilter: false,
    showFeatures: true,
    showHomeNavIcon: false,
    showUpdatesNavIcon: true,
    showHelpNavIcon: true,
    feedbackConfig: null,
    showCustomToolbar: false,
    showAdvancedSearch: true,
    routeLoading: false,
    activeFilterCategories: [],
    downloadedCids: [],
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: Stores.session })
export class SessionStore extends Store<AppSessionState> {
  constructor() {
    super(createInitialState());
  }
}
