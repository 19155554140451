import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

export type ProcessCardType = 'error' | 'processing' | 'success' | 'inaccessible' | null;

export interface ProcessCardOptions {
  name: string; // Display text of the option
  action: string; // Action name which is used in the event triggered by clicking the option
  icon?: string; // Optional, material icon type to display with the option name
  type?: ProcessCardType; // Optional, dictates color of icon: "error" = red | "processing" = orange  | "success" = green | "inaccessible" = gray | null = default color
}

@Component({
  selector: 'si-process-card',
  templateUrl: './process-card.component.html',
})
export class ProcessCardComponent implements OnInit {
  @Input() titleText: string; // Primary title line text of the card
  @Input() subTitleText: string; // Optional, secondary line of text appearing under the title
  @Input() type: ProcessCardType = 'error'; // Optional, a ProcessCardType enum value, type of the card which dictates color of icon: "error" = red (default) | "processing" = orange  | "success" = green | "inaccessible" = gray
  @Input() materialIcon: string; // Optional, material icon type to display, if none supplied a matching icon based on the type will be used
  @Input() materialIconType: ProcessCardType; // Optional, a ProcessCardType enum value, defaults to the type specified in the "type" property, but can be supplied to give the icon of the card a different style
  @Input() action: string; // Optional, adds this text as part of a button appended on the right of the card
  @Input() actionIcon: string; // Optional, adds a material icon of this type as part of a button appended on the right of the card
  @Input() actionIconVisible = false; // Optional, if false the actionIcon is only shown in the menu, if true the icon is also shown next to the action text (if any) on the card
  @Input() actionType: ProcessCardType; // Optional, used in conjuction with actionIcon, a ProcessCardType enum value, type of the card which dictates color of icon: "error" = red (default) | "processing" = orange  | "success" = green | "inaccessible" = gray
  @Input() options: ProcessCardOptions[] = []; // Optional, an array of ProcessCardOptions objects, if specified displays a menu with the options specified when the card is clicked, when an option is clicked an "optionSelected" event is emitted. Note: if an action is defined, it is automatically added as an option at the end of the options list.

  @Output() actionSelected = new EventEmitter<void>(); // Event that is triggered when the action is clicked
  @Output() optionSelected = new EventEmitter<string>(); // Event that is triggered when an option in the options menu is clicked containing the action string

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  ngOnInit(): void {
    // Generates the default icon based on type if none was specified
    if (!this.materialIcon) {
      switch (this.type) {
        case 'error':
          this.materialIcon = 'error';
          break;
        case 'processing':
          this.materialIcon = 'watch_later';
          break;
        case 'success':
          this.materialIcon = 'check_circle';
          break;
      }
    }

    if (!this.materialIconType) {
      this.materialIconType = this.type;
    }
  }

  actionOnClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.actionSelected.emit();
  }

  optionOnClick(event: MouseEvent, action: string) {
    event.stopPropagation();
    event.preventDefault();
    this.menuTrigger.closeMenu();
    this.optionSelected.emit(action);
  }
}
