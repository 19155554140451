import { DomPortal } from '@angular/cdk/portal';
import { Component, Inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MasterDetailView, ViewTemplate } from '@sae/models';
import { ISessionService, SESSION_TOKEN } from '@sae/services';
import { BaseTemplateComponent } from '../base-template.component';

@Component({
  selector: 'si-crossover-master-detail-template',
  templateUrl: './crossover-master-detail-template.component.html',
})
export class CrossoverMasterDetailTemplateComponent extends BaseTemplateComponent implements OnChanges {
  @Input() projectedContent!: MasterDetailView;

  detailContentPortal: DomPortal<TemplateRef<unknown>>;
  masterHeaderContentPortal: DomPortal<TemplateRef<unknown>>;
  masterContentPortal: DomPortal<TemplateRef<unknown>>;

  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    super(sessionService);
  }
  ngOnChanges(s: SimpleChanges): void {
    if (s?.projectedContent?.currentValue) {
      this.detailContentPortal = new DomPortal(this.projectedContent.detailContent);
      this.masterHeaderContentPortal = new DomPortal(this.projectedContent.masterHeaderContent);
      this.masterContentPortal = new DomPortal(this.projectedContent.masterContent);
    }
  }
}

@Component({ template: '', host: { 'collision-id': 'CrossoverMasterDetailViewBase' } })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CrossoverMasterDetailViewBase implements MasterDetailView {
  @ViewChild('masterContent') masterContent: TemplateRef<unknown>;
  @ViewChild('masterHeaderContent') masterHeaderContent: TemplateRef<unknown>;
  @ViewChild('detailContent') detailContent: TemplateRef<unknown>;
  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    this.sessionService.setActiveViewTemplate(ViewTemplate.CrossoverMasterDetail);
  }
}
