<article
  class="si-cardv"
  [class.si-contains--avatar]="avatar"
  [class.si-contains--context]="displayContext || context"
  [class.si-contains--related]="displayRelated || related"
  [class.si-state--read]="signal === 'Read'"
  [class.si-state--unread]="signal === 'Unread'"
>
  <fs-part-container [content]="container" [relative]="true">
    <fs-part-signal *ngIf="signal" class="si-cardv__signal" [signal]="signal"></fs-part-signal>
    <div class="si-cardv__context" *ngIf="displayContext || context">
      <div class="si-cardv__body">
        <ng-content select="[contextStart]"></ng-content>
        <fs-ui-util-mixed-components
          *ngIf="context"
          [content]="context"
          (buttonClick)="onButtonClick($event)"
          (chipClick)="onChipClick($event)"
        ></fs-ui-util-mixed-components>
        <ng-content select="[contextEnd]"></ng-content>
      </div>
    </div>
    <main>
      <fs-part-button
        class="si-cardv__button"
        *ngIf="isButton || url; else noButton"
        [classes]="'si-cardv__main'"
        [type]="'plain'"
        [external]="external"
        [url]="url"
        (buttonClickItem)="onButtonClick($event)"
      >
        <ng-container *ngTemplateOutlet="cardMain"></ng-container>
      </fs-part-button>
      <ng-template #noButton>
        <ng-container *ngTemplateOutlet="cardMain"></ng-container>
      </ng-template>
    </main>
    <div class="si-cardv__related" *ngIf="displayRelated || related">
      <div class="si-cardv__body">
        <ng-content select="[relatedStart]"></ng-content>
        <fs-ui-util-mixed-components
          *ngIf="related"
          [content]="related"
          (buttonClick)="onButtonClick($event)"
          (chipClick)="onChipClick($event)"
        ></fs-ui-util-mixed-components>
        <ng-content select="[relatedEnd]"></ng-content>
      </div>
    </div>
  </fs-part-container>
</article>

<ng-template #cardMain>
  <div class="si-cardv__core">
    <div class="si-cardv__body">
      <header class="si-cardv__h">
        <ng-content select="[headingStart]"></ng-content>
        <fs-comp-avatar *ngIf="avatar" [avatar]="avatar" class="si-cardv__avatar"></fs-comp-avatar>
        <fs-comp-util-heading [content]="heading"></fs-comp-util-heading>
        <ng-content select="[headingEnd]"></ng-content>
        <ng-content select="[contentStart]"></ng-content>
      </header>
      <div class="si-cardv__c">
        <fs-ui-util-mixed-components
          *ngIf="content"
          [content]="content"
          (buttonClick)="onButtonClick($event)"
          (chipClick)="onChipClick($event)"
        ></fs-ui-util-mixed-components>
        <ng-content select="[contentEnd]"></ng-content>
      </div>
    </div>
  </div>
</ng-template>
