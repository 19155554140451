import * as fetchIntercept from 'fetch-intercept';
// NOTE: this routes all google firebase remote config api calls through SAE proxy to avoid issues with blocked google apis in certain countries or organizations.
export function proxyFirebaseRemoteConfigCalls(): void {
  fetchIntercept.register({
    request(urlRaw: { toString: () => any }, config: any) {
      let url = urlRaw.toString();
      console.warn('Checking fetch request: ', url);
      if (url.indexOf('firebaseremoteconfig.googleapis.com') !== -1) {
        url = url.replace(
          'https://firebaseremoteconfig.googleapis.com/',
          'https://gpfb.sae.org/fbc/'
        );
        console.warn('Redirecting google api call to SAE proxy: ', url);
      }
      if (url.indexOf('firebaseinstallations.googleapis.com') !== -1) {
        url = url.replace(
          'https://firebaseinstallations.googleapis.com/',
          'https://gpfb.sae.org/fbinst/'
        );
        console.warn('Redirecting google api call to SAE proxy: ', url);
      }
      if (url.indexOf('firebase.googleapis.com') !== -1) {
        url = url.replace(
          'https://firebase.googleapis.com/',
          'https://gpfb.sae.org/firebase/'
        );
        console.warn('Redirecting google api call to SAE proxy: ', url);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return [url, config];
    },
    requestError(error: any) {
      return Promise.reject(error);
    },
    response(response: any) {1
      return response;
    },
    responseError(error: any) {
      return Promise.reject(error);
    },
  });
}
