<ng-container
  *ngIf="{
    showMobileNavMenu: showMobileNavMenu$ | async,
    isTakeoverActive: isTakeoverActive$ | async,
    isToolPanelCollapsed: isToolPanelCollapsed$ | async
  } as _"
>
<!-- TODO: this should be refactored to include column divs + libDetectBreakpointWidth divs-->
  <main
    class="si-columns si-contains--aux"
    [class.si-state--open]="_.showMobileNavMenu"
    [class.si-state--closed]="!_.showMobileNavMenu"
    [hidden]="_.isTakeoverActive"
    [class.si-state--collapsed--tools]="_.isToolPanelCollapsed"
    [class.si-state--collapsed-not--tools]="!_.isToolPanelCollapsed"
  >
    <ng-template [cdkPortalOutlet]="masterContentPortal" *ngIf="projectedContent?.masterContent"></ng-template>
    <ng-template [cdkPortalOutlet]="detailContentPortal" *ngIf="projectedContent?.detailContent"></ng-template>
    <ng-template [cdkPortalOutlet]="toolContentPortal" *ngIf="projectedContent?.toolContent"></ng-template>
  </main>
</ng-container>
