<ng-container
  *ngIf="{
    notifications: notifications$ | async,
    loading: loading$ | async,
    unreadCount: unreadCount$ | async,
    enterpriseMenu: enterpriseMenu$ | async,
    selectedAppFilter: selectedAppFilter$ | async
  } as _"
>
  <button
    mat-icon-button
    color="primary"
    class="si-button si-alert si-button--top si-badge si-badge--above"
    matBadgePosition="above"
    matBadgeSize="medium"
    title="Alert Notifications"
    [class.si-state--unread]="_.unreadCount > 0"
    [matBadge]="_.unreadCount > 9 ? '9+' : _.unreadCount"
    [matBadgeHidden]="_.unreadCount === 0"
    [matMenuTriggerFor]="alertMenu"
  >
    <mat-icon>notifications</mat-icon>
  </button>
  <mat-menu #alertMenu="matMenu" class="si-menu si-menu--advanced">
    <mat-card class="mat-elevation-z si-card si-card--dataviewer" *ngIf="_.notifications">
      <mat-card-header class="si-card__h" (click)="$event.stopPropagation()">
        <mat-card-title class="si-card__title">Alert Notifications</mat-card-title>
        <span class="si-expanse"></span>
        <ng-container *ngIf="_.enterpriseMenu?.apps">
          <mat-chip-list aria-label="Filters" class="si-chiplist si-chiplist--filter" *ngIf="_.selectedAppFilter">
            <mat-chip class="si-chip si-chip--complex" (click)="selectFilter(_.selectedAppFilter)">
              {{ _.selectedAppFilter.label }}
              <mat-icon matChipRemove title="Clear" (click)="selectFilter(null)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <button mat-icon-button [matMenuTriggerFor]="filterMore" #filterTrigger class="si-icon" color="primary" title="Filter">
            <mat-icon>filter_list</mat-icon>
          </button>
          <mat-menu #filterMore="matMenu" class="si-menu" (click)="$event.stopPropagation()">
            <button mat-menu-item [class.si-state--selected-not]="_.selectedAppFilter" (click)="selectFilter(null)">
              <mat-icon>check</mat-icon>
              <span>All</span>
            </button>
            <mat-divider class="si-divider"></mat-divider>
            <button
              *ngFor="let app of _.enterpriseMenu.apps"
              mat-menu-item
              [class.si-state--selected-not]="!_.selectedAppFilter || _.selectedAppFilter.messagesKey !== app.messagesKey"
              (click)="selectFilter(app)"
            >
              <mat-icon>check</mat-icon>
              <span>{{ app.name }}</span>
            </button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="dataViewActions">
          <button mat-icon-button [matMenuTriggerFor]="alertMore" class="si-icon si-icon--more" color="primary" title="Options">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #alertMore="matMenu" class="si-menu">
            <button mat-menu-item class="si-button--detailed" *ngFor="let action of dataViewActions" (click)="onAction(action.name)">
              <mat-icon class="si-tool-icon" color="primary">{{ action.icon }}</mat-icon>
              <span>{{ action.title }}</span>
            </button>
          </mat-menu>
        </ng-container>
      </mat-card-header>
      <mat-card-content class="si-card__c">
        <ng-container *ngIf="!_.notifications || !_.notifications.length">
          <si-none-card content="No notifications." *ngIf="!_.loading && !_.selectedAppFilter"></si-none-card>
          <si-none-card content="No alert notifications match the filter criteria." *ngIf="!_.loading && _.selectedAppFilter"></si-none-card>
          <si-state-card [spinnerIcon]="true" *ngIf="_.loading"></si-state-card>
        </ng-container>

        <div
          *ngIf="_.notifications && _.notifications.length"
          class="scroll"
          #list
          infinite-scroll
          [infiniteScrollDistance]="1"
          [scrollWindow]="false"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        >
          <ng-container *ngFor="let notif of _.notifications; let i = index; trackBy: trackByFn">
            <mat-divider class="si-divider"></mat-divider>
            <div class="si-wrap">
              <si-tidbit-card
                class="si-component"
                [unread]="!notif.isRead"
                wrapper="None"
                [materialIcon]="getIcon(notif)"
                [titleText]="notif.subject"
                [when]="getTimeAgo(notif)"
                [subTitle]="getApplicationName(_.enterpriseMenu.apps, notif)"
              ></si-tidbit-card>
              <button
                mat-icon-button
                [matMenuTriggerFor]="itemMore"
                class="si-icon si-icon--more"
                color="primary"
                matTooltip="Options"
                aria-label="Options"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #itemMore="matMenu" class="si-menu">
                <button mat-menu-item class="si-button--detailed" *ngIf="notif.isRead" (click)="markUnread(notif)">
                  <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">markunread</mat-icon>
                  <span>Mark as Unread</span>
                </button>
                <button mat-menu-item class="si-button--detailed" *ngIf="!notif.isRead" (click)="markRead(notif)">
                  <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">drafts</mat-icon>
                  <span>Mark as Read</span>
                </button>
                <button mat-menu-item class="si-button--detailed" (click)="delete(notif)">
                  <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">delete</mat-icon>
                  <span>Delete Notification</span>
                </button>
                <button mat-menu-item class="si-button--detailed" (click)="removeAlertAndDeleteNotification(notif)" *ngIf="!hideNotificationOptions">
                  <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">notifications_off</mat-icon>
                  <span matTooltip="Remove Alert and Delete Notification" aria-label="Remove Alert and Delete Notification"> Remove Alert and Delete Notification </span>
                </button>
                <mat-divider class="si-divider"></mat-divider>
                <button mat-menu-item class="si-button--detailed" (click)="goToAlert(notif)" *ngIf="!hideNotificationOptions">
                  <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">notifications</mat-icon>
                  <span>Access Alert</span>
                </button>
              </mat-menu>
            </div>
          </ng-container>
          <si-state-card [spinnerIcon]="true" *ngIf="_.loading"></si-state-card>
        </div>
        <mat-divider class="si-divider"></mat-divider>
      </mat-card-content>
    </mat-card>
  </mat-menu>
</ng-container>
