import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import { UrlParametersService } from '@sae/utilities';

@Component({
  selector: 'si-seo-paginator',
  templateUrl: './seo-paginator.component.html',
})
export class SeoPaginatorComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() baseURL = ''; // Represents the base url which will serve as the path to direct to for pagination with /{pageIndex} added
  @Input() hashURL: string; // Optional, if supplied the provided url will be appended during routing as a hash argument
  @Input() length = 0; // The length of the total number of items that are being paginated. Defaulted to 0.
  @Input() pageSize = 50; // Number of items to display on a page. By default set to 50.
  @Input() pageIndex = 0; // The zero-based page index of the displayed list of items. Defaulted to 0.
  @Input() pageSizeOptions: number[]; // The set of provided page size options to display to the user.
  @Input() pageSizeAllOption: boolean; // If true, adds a special "All" option at the bottom of the page sizes, which uses a hidden 1,000,000 page size number

  @Output() page = new EventEmitter<PageEvent>(); // Event triggered by any action which triggers a pagination change

  constructor(public router: Router, private readonly urlParamsService: UrlParametersService) {}

  _length: number;
  _pageSize: number;
  _pageIndex: number;

  pages: number[] = [];
  lastPage = 0;

  ngOnInit() {
    if (this.pageSizeAllOption) {
      this.pageSizeOptions.push(1000000);
    }
  }

  ngOnChanges() {
    this.setLocalProperties();
    this.setDisplayState();
  }

  ngAfterViewInit(): void {
    const pageSizeLabels = document.getElementsByClassName('mat-paginator-page-size-label');

    for (let i = 0; i < pageSizeLabels.length; i++) {
      pageSizeLabels[i].innerHTML = 'Show:';
    }
  }

  setLocalProperties() {
    this._length = this.length;
    this._pageSize = this.pageSize;
    this._pageIndex = this.pageIndex;
  }

  setDisplayState() {
    const newPages = [];

    this.lastPage = Math.ceil(this._length / this._pageSize);

    newPages.push(1); // First
    newPages.push(this._pageIndex + 1 - 1); // Previous
    newPages.push(this._pageIndex + 1); // Current
    newPages.push(this._pageIndex + 1 + 1); // Next
    newPages.push(this.lastPage); // Last

    if (this._pageIndex === 0) {
      newPages.push(3);
    } else if (this._pageIndex + 1 === this.lastPage) {
      newPages.push(this.lastPage - 2);
    }

    newPages.sort((a, b) => {
      if (+a > +b) {
        return 1;
      } else {
        return -1;
      }
    });

    this.pages = [];

    // Construct a new pages list of only in range distinct pages
    for (const page of newPages) {
      if (page >= 1 && page <= this.lastPage && this.pages.indexOf(page) === -1) {
        this.pages.push(page);
      }
    }
  }

  onMouseDown() {
    // If we have injected a pseudo all option to mat paginator, this replaces the option text that is injected dynamically
    if (this.pageSizeAllOption) {
      setTimeout(() => {
        const matOptions = document.getElementsByClassName('mat-option-text');

        for (let i = 0; i < matOptions.length; i++) {
          if (matOptions[i].innerHTML.includes('1000000')) {
            matOptions[i].innerHTML = 'All';
            break;
          }
        }
      });
    }
  }

  onPage(page: PageEvent): void {
    // Page size changes are a navigation event if it caused the pageIndex to change
    // this can now be done through the URL params service
    this.urlParamsService.setPaginationOffset(page.pageIndex);

    this._length = page.length;
    this._pageSize = page.pageSize;
    this._pageIndex = page.pageIndex;

    this.setDisplayState();

    this.page.emit(page);
  }

  onNavigate(pageNumber: number) {
    if (pageNumber >= 1 && pageNumber <= this.lastPage) {
      this._pageIndex = pageNumber - 1;

      this.setDisplayState();

      this.page.emit({
        length: this._length,
        pageSize: this._pageSize,
        pageIndex: this._pageIndex,
      });
    }
  }
}
