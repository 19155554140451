import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailToolTakeoverViewBase } from '@sae/features';
import { Observable, switchMap, tap } from 'rxjs';
import { DetailsHelperService } from '../../services/details-helper.service';
import { SessionService } from '../../../../shared/state/session.service';
import { ContentService } from '../../../../shared/state/content.service';
import { SeoUtilityService } from '../../../../shared/services/seo-utility.service';
import { ContentView } from '../../models/content.model';
import { formatDate } from '@angular/common';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../../../env.config';

@UntilDestroy()
@Component({
  templateUrl: '../templates/detail.component.html',
  host: { 'collision-id': 'BookComponent' },
})
export class BookComponent extends DetailToolTakeoverViewBase implements OnInit, OnDestroy {
  public fragment$: Observable<string> = this.route.fragment;
  public routeLoading$: Observable<boolean> = this.contentService.routeLoading$;
  public isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  public config$ = this.contentService.config$;

  constructor(
    public sessionService: SessionService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private detailsHelperService: DetailsHelperService,
    private seoUtilityService: SeoUtilityService,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {
    super(sessionService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        untilDestroyed(this),
        tap((data) =>
          this.seoUtilityService.setMetaTags(
            `${data?.content?.code}: ${data?.content?.title} - ${data?.content?.subGroup}`,
            data?.content?.abstract
          )
        ),
        tap((data: { content: ContentView }) => {
          const tags: { name: string; value?: string }[] = [];

          if (data?.content?.title) {
            tags.push({ name: 'citation_title', value: data.content.title });
          }

          if (data?.content?.contribGroups) {
            for (const cg of data.content.contribGroups) {
              for (const author of cg.contrib) {
                if (author.fullName) {
                  tags.push({ name: 'citation_author', value: author.fullName });
                }
              }
              if (cg.affiliation?.name) {
                tags.push({ name: 'citation_author_institution', value: cg.affiliation.name });
              }
            }
          }

          if (data?.content?.pubDate) {
            const format = 'yyyy/MM/dd';
            const locale = 'en-US';
            const formattedDate = formatDate(data.content.pubDate, format, locale);
            tags.push({ name: 'citation_date', value: formattedDate });
          }

          if (data?.content?.issueNumber) {
            tags.push({ name: 'citation_doi', value: data.content.issueNumber });
          }

          if (data?.content?.language) {
            tags.push({ name: 'citation_language', value: data.content.language });
          }

          if (data?.content?.documentUris?.basicUris?.seoUri) {
            tags.push({
              name: 'citation_abstract_html_url',
              value:
                this.envConfigService.envConfig<MobilusProteusConfig>().baseURL +
                data.content.documentUris.basicUris.seoUri,
            });
          }

          if (data?.content?.id) {
            tags.push({
              name: 'citation_pdf_url',
              value:
                this.envConfigService.envConfig<MobilusProteusConfig>().services.apiRootUrl +
                '/v1/externalindexer/content/' +
                data.content.id +
                '/Full%20Text%20PDF',
            });
          }

          if (data?.content?.publisher) {
            tags.push({
              name: 'citation_publisher',
              value: data.content.publisher,
            });
          }

          if (data?.content?.isbn) {
            tags.push({
              name: 'citation_isbn',
              value: data.content.isbn,
            });
          }

          this.seoUtilityService.googleScholarize(tags);
        }),
        tap((data) => this.setConfigValue(data)),
        switchMap((data) => this.detailsHelperService.getAdditionalDetails(data?.content))
      )
      .subscribe((cv) => this.contentService.setContentView(cv));
  }

  setConfigValue(data): void {
    let customConfig = {};

    switch (data?.content?.subGroup) {
      case 'Book':
        customConfig = {
          panel_title: 'Book',
          header_aux_text: 'Book',
          panel_collapse_text: 'Collapse Book Panel',
        };
        break;
      case 'Special Publication':
      case 'Handbook Supplement':
        customConfig = {
          panel_enabled: false,
          detail_show_authors: false,
          detail_show_table_of_contents: false,
          detail_show_citation: false,
        };
        break;
      case 'Progress in Technology':
        customConfig = {
          panel_enabled: false,
          detail_show_authors: false,
          detail_show_table_of_contents: false,
          detail_show_citation: false,
          detail_show_references: false,
        };
        break;
      case 'Index':
        customConfig = {
          panel_enabled: false,
          detail_show_authors: false,
          detail_show_table_of_contents: false,
          detail_show_citation: false,
          detail_show_tags: false,
        };
        break;
      default:
        break;
    }

    this.updateDetailsConfig(customConfig);
  }
  updateDetailsConfig(customConfig): void {
    this.contentService.updateDetailsConfiguration({
      panel_enabled: true,
      detail_masthead_subheading_type: 'code',
      detail_description_summary_title: 'Abstract',
      detail_show_authors: true,
      detail_show_abstract: true,
      detail_show_tags: true,
      detail_show_details: true,
      detail_show_doi: true,
      detail_show_citations_badge: true,
      detail_show_isbn: true,
      detail_show_page_count: true,
      detail_show_citation: true,
      detail_show_table_of_contents: true,
      detail_show_additional_details: true,
      detail_show_publisher: true,
      detail_show_pub_date: true,
      detail_show_code: true,
      detail_show_selected_type: true,
      detail_show_language: true,
      detail_show_view: false,
      detail_show_references: true,
      detail_show_related: true,
      ...customConfig,
    });
  }
  ngOnDestroy(): void {
    this.contentService.resetStore();
    this.seoUtilityService.clearAllSEOTags();
  }
}
