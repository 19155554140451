import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthActions, AuthSelectors } from '../../../store/auth';
import { IdentityProvider } from '../../models/api-models';
import { LoginLocation } from '../../models/common-interfaces';
import { AuthService } from '../../state/auth.service';
import { SubscriptionsService } from '../../state/subscriptions.service';
import { Api } from '../../../../api';
import { Observable, map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../../env.config';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'mobi-subscription-login-form',
  templateUrl: './subscription-login-form.component.html',
})
export class SubscriptionLoginFormComponent implements OnInit {
  @Input() loginHeaderText: string;
  @Input() LoginFrom: LoginLocation;

  public usernameControl = new FormControl('', [Validators.required]);
  public passwordControl = new FormControl('', [Validators.required]);
  public rememberMeControl = new FormControl(false);
  public loading$ = this.store.select(AuthSelectors.selectLoadingLogIn);
  public error$ = this.store.select(AuthSelectors.selectLogInError);
  identityProviders: Array<IdentityProvider> = [];
  public loginUrl = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
    Api.subscriptions.url
  }/sessions`;

  public filteredProviders: Observable<IdentityProvider[]>;
  public selectedProvider = new FormControl('');

  @ViewChild('idploginForm') idploginForm!: ElementRef;

  constructor(
    private subscriptionsService: SubscriptionsService,
    private store: Store,
    private http: HttpClient,
    private authService: AuthService,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    const idpJSON = localStorage.getItem('ssoIDP');
    if (idpJSON && idpJSON !== 'undefined') {
      const parsedIdp = JSON.parse(idpJSON ?? '');
      this.selectedProvider.setValue(parsedIdp);
    }
    this.subscriptionsService.getIdentityProviders().subscribe((res: Array<IdentityProvider>) => {
      this.identityProviders = res;
      this.filteredProviders = this.selectedProvider.valueChanges.pipe(
        startWith(idpJSON ? JSON.parse(idpJSON) : ''),
        map((value) => this._filter(value || ''))
      );
    });
  }

  login(): void {
    this.store.dispatch(
      AuthActions.logInToSubscription({
        encodedLogin: window.btoa(`${this.usernameControl.value}:${this.passwordControl.value}`),
        rememberMe: this.rememberMeControl.value,
        loginFrom: this.LoginFrom,
      })
    );
  }

  ssoLogin(event: MatAutocompleteSelectedEvent): void {
    const parsedUrl = new URL(window.location.href);
    const redirectUri = parsedUrl.href;
    const entityId = event?.option?.value?.entityId;
    const uuid = this.generateUUID();
    localStorage.setItem('ssoState', uuid);
    if (!event?.option?.value) {
      localStorage.removeItem('ssoIDP');
    } else {
      localStorage.setItem('ssoIDP', JSON.stringify(event?.option?.value));
    }
    const token = this.cookieService.get('X-Auth-Token');
    if (entityId) {
      this.idploginForm.nativeElement.targetUrl.value = redirectUri;
      this.idploginForm.nativeElement.entityId.value = entityId;
      this.idploginForm.nativeElement.state.value = uuid;
      if (token) {
        this.idploginForm.nativeElement['xAuthToken'].value = token;
      } else {
        this.idploginForm.nativeElement['xAuthToken'].remove();
      }
      this.idploginForm.nativeElement.submit();
    }
  }

  generateUUID(): string {
    let d = new Date().getTime(),
      d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
  }

  clearError(): void {
    this.store.dispatch(AuthActions.setLogInToSubErrorMessage(null));
  }

  _filter(value: string | IdentityProvider): IdentityProvider[] {
    if (typeof value !== 'string') {
      const idp = this.identityProviders.find((_idp) => _idp.name === value?.name);
      if (idp) {
        return [idp];
      }
      return this.identityProviders;
    }
    const filterValue = value.toLowerCase();
    return this.identityProviders.filter((ip) => ip.name.toLowerCase().includes(filterValue));
  }

  displayIDP(idp: IdentityProvider): string {
    return idp && idp.name ? idp.name : '';
  }
}
