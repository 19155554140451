import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { concatMap, of, startWith, Subject, switchMap, timer } from 'rxjs';
import { SessionExpiryDialogComponent } from '../components/session-expiry-dialog/session-expiry-dialog.component';
import { SaeEnvironmentConfig as Config } from '@sae/base';
import { AuthEventType } from './auth.core';
import { AUTH_TOKEN, IAuthService } from './auth.core';

export interface ISessionExpiryService {
  initialize(config: Partial<Config>): void;
  getTimeoutWarningTime(): number;
}

//There are two observables exposed / used here:
//  - sessionExpiryWarning$ emits the number of seconds remaining before the refresh token expires.
//    The time is configured in remote config. A dialog pops up at this time asking if the user wants
//    to stay logged in.
//  - sessionExpired$ emits 0 when the refresh token expires (which means the user is no longer authenticated.)
//    The authService isLoggedIn is set to false and the user is logged out.

@Injectable({
  providedIn: 'root',
})
export class SessionExpiryCoreService implements ISessionExpiryService {
  constructor(@Inject(AUTH_TOKEN) private readonly authService: IAuthService, private matdialog: MatDialog) {}

  public sessionExpired$: Subject<0> = new Subject();
  private resetTokenTimeoutTrigger$: Subject<number | Date> = new Subject();
  public sessionExpiryWarning$: Subject<number> = new Subject();
  private sessionExpiryWarningTimeoutTrigger$: Subject<number | Date> = new Subject();
  private sessionTimeoutWarning: number; //seconds

  public initialize(config: Partial<Config>): void {
    if (!config.sessionExpiry?.useSessionExpiry) {
      return;
    }
    this.sessionTimeoutWarning = config.sessionExpiry.expiryWarnSeconds ?? 120;
    if (this.authService.isLoggedIn()) {
      // This thing emits timers based on a date or number of ms passed in to it
      // by scheduleRefreshTokenTimeout. This lets us switchmap in case scheduleRefreshTokenTimeout
      // gets fired again before the previous timer has completed.
      this.resetTokenTimeoutTrigger$
        .pipe(
          startWith(2000), // wait 2 seconds before starting up
          switchMap((timerValue) => {
            if (timerValue) {
              return timer(timerValue);
            } else {
              return of(null);
            }
          })
        )
        .subscribe((triggerVal) => {
          if (triggerVal !== null) {
            this.scheduleRefreshTokenTimeout();
          }
        });

      // This thing emits timers based on a date or number of ms passed in to it
      // by scheduleRefreshTokenWarning. This lets us switchmap in case scheduleRefreshTokenWarning
      // gets fired again before the previous timer has completed.
      this.sessionExpiryWarningTimeoutTrigger$
        .pipe(
          startWith(2000), // wait 2 seconds before starting up
          switchMap((timerValue) => {
            if (timerValue) {
              return timer(timerValue);
            } else {
              return of(null);
            }
          })
        )
        .subscribe((triggerVal) => {
          if (triggerVal !== null) {
            this.scheduleRefreshTokenWarning();
          }
        });

      this.authService.authEvent$.subscribe((e) => {
        if (e.type === AuthEventType.OnAuthRefreshSuccess) {
          // when refresh token is refreshed, reset the timeouts
          this.scheduleRefreshTokenTimeout();
          this.scheduleRefreshTokenWarning();
        }
      });
    }

    this.setUpSubscriptions();
  }

  public getTimeoutWarningTime(): number {
    return this.sessionTimeoutWarning;
  }

  protected setUpSubscriptions(): void {
    this.sessionExpired$.subscribe(() => {
      this.authService.setIsLoggedIn(false);
    });
    this.sessionExpired$.subscribe(() => {
      this.authService.logout();
    });
    this.sessionExpiryWarning$.subscribe((when) => {
      this.confirmSessionDialog(when);
    });
  }

  // If refresh token is timed out, fire sessionExpired$.
  // Otherwise, schedule another check at the refresh token
  // expiry date
  private scheduleRefreshTokenTimeout(): void {
    const refreshDt = this.authService.getRefreshTokenExpiry();
    if (refreshDt) {
      const currentDt = new Date();
      if (currentDt > refreshDt) {
        this.sessionExpired$.next(0);
      } else {
        this.resetTokenTimeoutTrigger$.next(refreshDt);
      }
    }
  }

  // If the refresh token is going to expire within the warning time,
  // fire sessionExpiryWarning$. Otherwise, schedule another check
  // for when it will be about to expire.
  private scheduleRefreshTokenWarning(): void {
    const refreshDt = this.authService.getRefreshTokenExpiry();
    if (refreshDt) {
      const currentDt = new Date();
      const secondsLeft = Math.floor((refreshDt.getTime() - currentDt.getTime()) / 1000);
      if (secondsLeft <= this.sessionTimeoutWarning) {
        this.sessionExpiryWarning$.next(secondsLeft);
      } else {
        const warnDt = new Date(refreshDt.getTime() - this.sessionTimeoutWarning * 1000);
        this.sessionExpiryWarningTimeoutTrigger$.next(warnDt);
      }
    }
  }

  // Pop a dialog asking whether the user wants to continue using the application
  // or log out. (If they don't respond within the time limit, sessionExpired$
  // will fire and log them out.)
  protected confirmSessionDialog(secondsRemaining: number): void {
    const dialogRef = this.matdialog.open(SessionExpiryDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        secondsRemaining: Math.floor(secondsRemaining),
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.authService
          .getAndRefreshLoggedIn()
          .pipe(
            concatMap((loggedIn) => {
              return loggedIn ? this.authService.updateToken(this.sessionTimeoutWarning) : of(loggedIn);
            })
          )
          .subscribe(() => this.scheduleRefreshTokenWarning());
      } else {
        void this.authService.logout();
      }
    });
  }
}

export const SESSION_EXPIRY_TOKEN = new InjectionToken<ISessionExpiryService>('Session Expiry Service');
