import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartButtonComponent } from '../../parts/part-button/part-button.component';
import { ContainerContent, PartContainerComponent } from '../../parts/part-container/part-container.component';
import {
  CompUtilHeadingComponent,
  HeadingContent,
} from '../../compounds/comp-util-heading/comp-util-heading.component';
import { overrideInputs } from '@sae/base';

export type ToolbarIsColumnHeader = 'crossover' | 'crossover-detail' | 'detail' | 'master' | 'tool' | null;

export interface ToolbarContent {
  classes?: string;
  container?: ContainerContent;
  heading?: HeadingContent;
  isColumnHeader?: ToolbarIsColumnHeader;
}

@Component({
  selector: 'fs-ui-toolbar',
  styleUrls: ['./ui-toolbar.component.scss'],
  templateUrl: './ui-toolbar.component.html',
  imports: [CommonModule, PartButtonComponent, PartContainerComponent, CompUtilHeadingComponent],
  standalone: true,
})
export class UIToolbarComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: ToolbarContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string; // Optional, provides a CSS class for the output wrapper
  @Input() container: ContainerContent; // Optional, adds a container; NOTE: Not yet implemented
  @Input() heading: HeadingContent; // Optional, adds a heading display
  @Input() hTag: number; // Optional, overrides the default heading tag when between 1 and 6
  @Input() isColumnHeader: ToolbarIsColumnHeader; // Optional, specifies use as a column header
  @Input() toolPanelClosed: boolean; // Optional, specifies the tool panel is closed
  @Input() content: ToolbarContent; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }

    if (this.hTag) {
      this.heading.hTag = this.hTag;
    }
  }
}
