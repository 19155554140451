<div class="si-dialog">
  <div class="si-dialog__h">
    <mat-icon class="si-icon">logout</mat-icon>
    <h2 class="si-dialog__title">Logout</h2>
    <span class="si-expanse"></span>
    <button mat-icon-button matTooltip="Close" aria-label="Close" class="si-icon si-button--close" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="si-dialog__b">
    <div mat-dialog-content class="si-dialog__c">Are you sure you want to log out of SAE Mobilus?</div>
    <div mat-dialog-actions class="si-dialog__a">
      <button mat-flat-button class="si-button si-button--secondary" mat-dialog-close>No</button>
      <button mat-flat-button class="si-button si-button--primary" cdkFocusInitial mat-dialog-close="YES" (click)="logOut()">Yes</button>
    </div>
  </div>
</div>
