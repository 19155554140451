import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartUtilListboxComponent } from '../../parts/part-util-listbox/part-util-listbox.component';
import { ChipItem, CompChipComponent } from '../../compounds/comp-chip/comp-chip.component';
import { overrideInputs } from '@sae/base';

export interface UIChipGroupConfig {
  label?: string;
  chips?: ChipItem[];
}

@Component({
  selector: 'fs-ui-chip-group',
  styleUrls: ['ui-chip-group.component.scss'],
  templateUrl: './ui-chip-group.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, PartUtilListboxComponent, CompChipComponent],
  standalone: true,
})
export class UIChipGroupComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: UIChipGroupConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input({ required: true }) label: string;
  @Input({ required: true }) chips: ChipItem[];

  @Output() buttonClick = new EventEmitter<ChipItem>(); // Event emitted when component is in button mode (url is null)
  @Output() removeClick = new EventEmitter<ChipItem>(); // Event emitted when component remove chip is clicked

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }

  onButtonClick(chip: ChipItem): void {
    this.buttonClick.emit(chip);
  }

  onRemoveClick(chip: ChipItem): void {
    this.removeClick.emit(chip);
  }
}
