import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, delay, EMPTY, filter, finalize, forkJoin, iif, map, switchMap, tap } from 'rxjs';
import { Bookmark, ContentApiResponse } from '../../../modules/detail/models/content.model';
import { FolderNode, FolderSection } from '../../../store/add-to-dialog/add-to-dialog.models';
import { Resource } from '../../models/api-models';
import { MobilusUiService } from '../../services/mobilus-ui.service';
import { ContentService } from '../../state/content.service';
import { Store } from '@ngrx/store';
import { MyLibraryActions } from '../../../store/my-library';

@UntilDestroy()
@Component({
  templateUrl: './add-to-dialog.component.html',
  styleUrls: [],
})
export class AddToDialogComponent implements OnInit {
  @Output() inMyLibraryChanged = new EventEmitter<boolean>();
  private isAddToShared = false;
  private contentId: number;
  public getFoldersAndBookmarksLoading$ = new BehaviorSubject(false);
  public getFoldersAndBookmarksError$ = new BehaviorSubject(undefined);

  public listManagerData: FolderSection[] = [
    {
      id: null,
      title: 'My Library',
      expanded: true,
      divider: true,
      checkboxPresent: true,
      checkboxHidesNodes: true,
      checkboxTitle: 'Added',
      checked: false,
      nodesGroupTitleVisible: true,
      nodesGroupTitleText: 'Folders',
      deleteNodeEnabled: false,
      createNodeEnabled: true,
      createNodeIcon: 'create_new_folder',
      createNodeTitle: 'Create Folder',
      createNodeFieldName: 'Title',
      createNodeActionText: 'Create',
      errorCodeVisible: false,
      errorCodeActionEnabled: false,
      nodes: [],
    },
    {
      expanded: false,
      title: 'Shared by User',
      divider: true,
      nodesGroupTitleVisible: true,
      nodesGroupTitleText: 'Folders',
      deleteNodeEnabled: true,
      createNodeEnabled: true,
      createNodeIcon: 'create_new_folder',
      createNodeTitle: 'Create Folder',
      createNodeFieldName: 'Title',
      createNodeActionText: 'Create',
      errorCodeVisible: false,
      errorCodeActionEnabled: false,
      nodes: [],
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { contentId: number; isAddToShared: boolean },
    // @Inject(MAT_DIALOG_DATA) private contentId,
    private contentService: ContentService,
    private mobilusService: MobilusUiService,
    private snackbar: MatSnackBar,
    private store: Store
  ) {
    this.contentId = data.contentId;
    this.isAddToShared = data.isAddToShared;
  }

  ngOnInit(): void {
    let userFolders = [];
    this.getFoldersAndBookmarksLoading$.next(true);
    this.getFoldersAndBookmarksError$.next(undefined);
    this.mobilusService
      .getFolders()
      .pipe(
        tap((folders: Resource[]) => {
          if (folders.some((folder) => folder?.name === 'My Favorites')) {
            userFolders = folders ?? [];
            this.getFoldersAndBookmarks(userFolders);
          } else {
            this.mobilusService
              .createFolder('My Favorites')
              .pipe(
                map((folder: Resource) => {
                  const userFolders = folders?.length ? [...folders, folder] : folder ? [folder] : [];
                  this.getFoldersAndBookmarks(userFolders);
                }),
                catchError((error) => {
                  this.snackbar.open('Something went wrong', undefined, { duration: 4500 });
                  return error;
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();

    if (this.isAddToShared) {
      this.listManagerData[0].expanded = false;
      this.listManagerData[1].expanded = true;
    }
  }

  public getFoldersAndBookmarks(folders: Resource[]): void {
    forkJoin([this.contentService.getById(this.contentId)])
      .pipe(
        tap(([content]: [ContentApiResponse]) => this.setInitialNodes(folders ?? [], content?.bookmarks ?? [])),
        switchMap(([content]) =>
          iif(
            () => content?.bookmarks?.some((bookmark) => bookmark.folderName === 'My Favorites') || this.isAddToShared,
            EMPTY,
            this.mobilusService.addItemToFolder(
              content?.meta?.cid,
              folders.find((folder) => folder.name === 'My Favorites').id
            )
          )
        ),
        filter((resp) => !!resp),
        tap(() => {
          this.listManagerData[0].checked = true;
          this.snackbar.open(`Item added to My Library`, undefined, { duration: 4500 });
          // this.contentService.setInMyLibrary(true);
          this.inMyLibraryChanged.emit(true);
        }),
        catchError((error) => {
          this.getFoldersAndBookmarksError$.next(error);
          return EMPTY;
        }),
        finalize(() => this.getFoldersAndBookmarksLoading$.next(false))
      )
      .subscribe();
  }

  checkboxClicked(event: { element: FolderSection | FolderNode; root: boolean }): void {
    if (event.root) {
      if (event.element.checked) {
        this.removeAll();
      } else {
        this.addToMyLibrary();
      }
    } else {
      if (event.element.checked) {
        this.removeItemFromFolder(event.element);
      } else {
        this.addItemToFolder(event.element);
      }
    }
  }

  createNodeSubmitted(event: { element: FolderSection | FolderNode; value: string }): void {
    this.createFolder(event.element, event.value);
  }

  processAction(): void {
    this.mobilusService
      .getFolders()
      .pipe(
        tap((folders: Resource[]) => {
          this.getFoldersAndBookmarks(folders ?? []);
        }),
        catchError((error) => {
          this.snackbar.open('Something went wrong', undefined, { duration: 4500 });
          return error;
        })
      )
      .subscribe();
  }

  private convertFolderToNode(folder: Resource, bookmarks: Bookmark[]): FolderNode {
    return {
      id: folder.id,
      parentId: folder.anchorId,
      title: folder.name,
      shared: folder.shared,
      checked: bookmarks.some((bookmark) => bookmark.folderId === folder.id),
      access: folder.access,
      nodes: folder.resources?.map((r) => this.convertFolderToNode(r, bookmarks)) ?? [],
    };
  }

  private setInitialNodes(folders: Resource[], bookmarks: Bookmark[]): void {
    const myFavoritesFolderIndex = folders.findIndex((folder) => folder.name === 'My Favorites');
    const foldersWithoutMyFavorites = folders.concat();
    foldersWithoutMyFavorites.splice(myFavoritesFolderIndex, 1);
    this.listManagerData[0].id = folders[myFavoritesFolderIndex].id;
    this.listManagerData[0].checked = bookmarks.some((bookmark) => bookmark.folderName === 'My Favorites');

    this.listManagerData[0].nodes = foldersWithoutMyFavorites
      .filter((folder) => folder.access === 'OWNER' || folder.access === 'VIEW_ADD_DELETE')
      .map((folder) => this.convertFolderToNode(folder, bookmarks));
    this.listManagerData[1].nodes = this.listManagerData[0].nodes.filter((node) => node.shared);
  }

  private addItemToFolder(element: FolderNode | FolderSection): void {
    element.spinner = true;
    element.errorCodeVisible = false;
    element.errorCodeActionEnabled = false;
    this.mobilusService
      .addItemToFolder(this.contentId, element.id)
      .pipe(
        tap(() => {
          element.checked = true;
          this.snackbar.open(`Item added to ${element.title}`, undefined, { duration: 4500 });
        }),
        delay(500),
        catchError(() => {
          element.errorCodeVisible = true;
          element.errorCodeActionEnabled = true;
          return EMPTY;
        }),
        finalize(() => (element.spinner = false))
      )
      .subscribe();
  }

  private removeItemFromFolder(element: FolderNode | FolderSection): void {
    element.spinner = true;
    element.errorCodeActionEnabled = false;
    element.errorCodeVisible = false;
    this.mobilusService
      .removeItemFromFolder(this.contentId, element.id)
      .pipe(
        tap(() => {
          element.checked = false;
          this.snackbar.open(`Item removed from ${element.title}`, undefined, { duration: 4500 });
          this.inMyLibraryChanged.emit(true);
        }),
        delay(500),
        catchError(() => {
          element.errorCodeVisible = true;
          element.errorCodeActionEnabled = true;
          return EMPTY;
        }),
        finalize(() => (element.spinner = false))
      )
      .subscribe();
  }

  private removeAll(): void {
    const foldersToAffect: FolderNode[] = [];

    function checkIfSelected(node: FolderNode): void {
      if (node.checked) {
        foldersToAffect.push(node);
      }
      node.nodes.forEach((n) => checkIfSelected(n));
    }
    this.listManagerData[0].nodes.forEach((node) => checkIfSelected(node));

    foldersToAffect.forEach((node) => {
      node.spinner = true;
    });
    this.listManagerData[0].spinner = true;

    this.mobilusService
      .removeItemFromAllFolders(this.contentId, [
        ...foldersToAffect.map((folder) => folder.id),
        this.listManagerData[0].id,
      ])
      .pipe(
        tap(() => {
          foldersToAffect.forEach((folder) => (folder.checked = false));
          this.listManagerData[0].checked = false;
          this.listManagerData[0].createNodeEnabled = false;
          this.snackbar.open('Removed from My Library', undefined, { duration: 4500 });
          this.inMyLibraryChanged.emit(false);
        }),
        finalize(() => {
          foldersToAffect.forEach((folder) => (folder.spinner = false));
          this.listManagerData[0].spinner = false;
        })
      )
      .subscribe();
  }

  private addToMyLibrary(): void {
    this.listManagerData[0].spinner = true;
    this.mobilusService
      .addItemToFolder(this.contentId, this.listManagerData[0].id)
      .pipe(
        tap(() => {
          this.listManagerData[0].checked = true;
          this.listManagerData[0].createNodeEnabled = true;
          this.snackbar.open(`Item added to My Library`, undefined, { duration: 4500 });
          // this.contentService.setInMyLibrary(true);
          this.inMyLibraryChanged.emit(true);
        }),
        finalize(() => (this.listManagerData[0].spinner = false))
      )
      .subscribe();
  }

  private createFolder(element: FolderNode | FolderSection, value: string): void {
    element.createNodeSpinner = true;
    element.errorCodeVisible = false;
    element.errorCodeActionEnabled = false;
    const id = 'divider' in element ? element['parentId'] : element.id;
    this.mobilusService
      .createFolder(value, id)
      .pipe(
        map((resource) => {
          const folderNode = this.convertFolderToNode(resource, []);
          folderNode.spinner = true;
          element.nodes.push(folderNode);
          element.createNodeVisible = false;
          this.snackbar.open(`Successfully added folder ${value}`, undefined, { duration: 4500 });
          if (!id) {
            this.store.dispatch(MyLibraryActions.addFolderToMyLibrary({ folder: resource }));
          }
          return folderNode;
        }),
        switchMap((resource) =>
          this.mobilusService.addItemToFolder(this.contentId, resource.id).pipe(
            tap(() => {
              resource.checked = true;
              resource.spinner = false;
              this.inMyLibraryChanged.emit(true);
            }),
            catchError(() => {
              resource.spinner = false;
              resource.errorCodeVisible = true;
              resource.errorCodeActionEnabled = true;
              return EMPTY;
            })
          )
        ),
        catchError((e: HttpErrorResponse) => {
          if (e?.status === 409) {
            element.errorCodeText = 'A folder with this title exists already';
          } else {
            element.errorCodeText = 'Something went wrong';
          }
          element.errorCodeActionEnabled = true;
          element.errorCodeVisible = true;
          return EMPTY;
        }),
        finalize(() => {
          element.createNodeSpinner = false;
        })
      )
      .subscribe();
  }

  public nodeErrorActionClicked(event: {
    element: FolderSection | FolderNode;
    parent: FolderSection | FolderNode;
    value: string;
  }): void {
    if (
      event.element?.errorCodeText === 'A folder with this title exists already' ||
      event.element?.errorCodeText === 'Something went wrong'
    ) {
      this.createFolder(event.element, event.value);
    } else {
      if (event.element.checked) {
        this.removeItemFromFolder(event.element);
      } else {
        this.addItemToFolder(event.element);
      }
    }
  }
  public deleteNode(event: { element: FolderSection | FolderNode; parent: FolderSection | FolderNode }): void {
    event.element.spinner = true;
    this.mobilusService
      .deleteFolder(event.element.id)
      .pipe(
        tap(() => {
          this.snackbar.open(`${event.element.title} removed from shared`, undefined, { duration: 4500 });
          event.parent.nodes = event.parent.nodes.filter((node) => node.id !== event.element.id);
        }),
        catchError(() => {
          event.element.errorCodeVisible = true;
          event.element.errorCodeActionEnabled = true;
          return EMPTY;
        }),
        finalize(() => (event.element.spinner = false))
      )
      .subscribe();
  }
}
