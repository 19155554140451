export enum FeatureKeys {
  AddToDialog = 'add-to-dialog',
  Auth = 'auth',
  Browse = 'browse',
  Detail = 'detail',
  DownloadDocument = 'download-document',
  FilterPanel = 'filter-panel',
  Home = 'home',
  Router = 'router',
  SaveSearchDialog = 'save-search-dialog',
  Search = 'search',
  MyLibrary = 'my-library',
  BatteryCell = 'battery-cell',
}
