import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@sae/components';
import { MaterialModule } from '@sae/material';
import { SharedUtilitiesModule } from '@sae/utilities';
import { AppToolbarComponent } from './app-toolbar/app-toolbar.component';
import { EnterpriseMenuComponent } from './enterprise-menu/enterprise-menu.component';
import { FeedbackBottomSheetComponent } from './feedback-bottom-sheet/feedback-bottom-sheet.component';
import { NotificationsComponent } from './notifications/notifications/notifications.component';
import {
  CrossoverMasterDetailTemplateComponent,
  CrossoverMasterDetailViewBase,
} from './templates/crossover-master-detail-template/crossover-master-detail-template.component';
import { DetailTemplateComponent, DetailViewBase } from './templates/detail-template/detail-template.component';
import {
  DetailToolTakeoverTemplateComponent,
  DetailToolTakeoverViewBase,
} from './templates/detail-tool-takeover-template/detail-tool-takeover-template.component';
import {
  DetailToolTemplateComponent,
  DetailToolViewBase,
} from './templates/detail-tool-template/detail-tool-template.component';
import {
  MasterDetailTemplateComponent,
  MasterDetailViewBase,
} from './templates/master-detail-template/master-detail-template.component';
import {
  MasterDetailToolTakeoverTemplateComponent,
  MasterDetailToolTakeoverViewBase,
} from './templates/master-detail-tool-template/master-detail-tool-template.component';
import { BaseAppComponent } from './templates/si-app-component/si-app-component.component';
import { UserDataviewerComponent } from './notifications/user-dataviewer/user-dataviewer.component';
import { NotificationsViewComponent } from './notifications/notifications-list/notifications-list.component';
import { ActionToolbarComponent } from './notifications/action-toolbar/action-toolbar.component';
import { NotificationItemComponent } from './notifications/notification-item/notification-item.component';
import { CriteriaWizardComponent } from './criteria-wizard/criteria-wizard.component';
import { CriteriaGroupComponent } from './criteria-wizard/criteria-group/criteria-group.component';
import { CriteriaRowContainerComponent } from './criteria-wizard/criteria-row-container/criteria-row-container.component';
import { CriteriaRowComponent } from './criteria-wizard/criteria-row/criteria-row.component';
import { HierarchyDialogComponent } from './criteria-wizard/hierarchy-dialog/hierarchy-dialog.component';
import { FilterPanelTemplateComponent } from './templates/filter-panel-template/filter-panel-template.component';
import { AlertsListComponent } from './notifications/alerts-list/alerts-list.component';
import { SeoFeatureToolbarComponent } from './seo-feature-toolbar/seo-feature-toolbar.component';
import { ResourceManagerComponent } from './resources/resource-manager/resource-manager.component';
import { AttachmentResourceManagerComponent } from './resources/attachment-resource-manager/attachment-resource-manager.component';
import { NotificationsHelperService } from './notifications/notifications-helper.service';
import { FileAttachmentManagerComponent } from './files/file-attachment-manager/file-attachment-manager.component';
import { TakeoverPaginationComponent } from './templates/takeover-pagination/takeover-pagination.component';
import { TakeoverComponent } from './templates/takeover/takeover.component';
import { ResourceShareComponent } from './resources/resource-share/resource-share.component';
import { PreAppTemplateComponent } from './templates/pre-app-template/pre-app-template.component';
import { DetailToolbarComponent } from './detail-toolbar/detail-toolbar.component';
import { DetailTakeoverTemplateComponent, DetailTakeoverViewBase } from './templates/detail-takeover-template/detail-takeover-template.component';

@NgModule({
  declarations: [
    ActionToolbarComponent,
    AlertsListComponent,
    AppToolbarComponent,
    AttachmentResourceManagerComponent,
    BaseAppComponent,
    CriteriaGroupComponent,
    CriteriaRowComponent,
    CriteriaRowContainerComponent,
    CriteriaWizardComponent,
    CrossoverMasterDetailTemplateComponent,
    CrossoverMasterDetailViewBase,
    DetailTakeoverTemplateComponent,
    DetailTakeoverViewBase,
    DetailTemplateComponent,
    DetailToolbarComponent,
    DetailToolTemplateComponent,
    DetailToolViewBase,
    DetailToolTakeoverTemplateComponent,
    DetailToolTakeoverViewBase,
    DetailViewBase,
    EnterpriseMenuComponent,
    FeedbackBottomSheetComponent,
    FilterPanelTemplateComponent,
    FileAttachmentManagerComponent,
    HierarchyDialogComponent,
    MasterDetailTemplateComponent,
    MasterDetailToolTakeoverTemplateComponent,
    MasterDetailToolTakeoverViewBase,
    MasterDetailViewBase,
    NotificationItemComponent,
    NotificationsComponent,
    NotificationsViewComponent,
    PreAppTemplateComponent,
    ResourceManagerComponent,
    ResourceShareComponent,
    SeoFeatureToolbarComponent,
    TakeoverComponent,
    TakeoverPaginationComponent,
    UserDataviewerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    SharedComponentsModule,
    SharedUtilitiesModule,
  ],
  exports: [
    ActionToolbarComponent,
    AlertsListComponent,
    AppToolbarComponent,
    AttachmentResourceManagerComponent,
    BaseAppComponent,
    CriteriaGroupComponent,
    CriteriaRowComponent,
    CriteriaRowContainerComponent,
    CriteriaWizardComponent,
    CrossoverMasterDetailTemplateComponent,
    CrossoverMasterDetailViewBase,
    DetailTemplateComponent,
    DetailToolbarComponent,
    DetailToolTakeoverTemplateComponent,
    DetailToolTakeoverViewBase,
    DetailViewBase,
    EnterpriseMenuComponent,
    FeedbackBottomSheetComponent,
    FilterPanelTemplateComponent,
    FileAttachmentManagerComponent,
    HierarchyDialogComponent,
    MasterDetailTemplateComponent,
    MasterDetailToolTakeoverTemplateComponent,
    MasterDetailToolTakeoverViewBase,
    MasterDetailViewBase,
    NotificationItemComponent,
    NotificationsComponent,
    NotificationsViewComponent,
    PreAppTemplateComponent,
    ResourceManagerComponent,
    ResourceShareComponent,
    SeoFeatureToolbarComponent,
    TakeoverComponent,
    TakeoverPaginationComponent,
    UserDataviewerComponent,
  ],
  providers: [
    {
      provide: NotificationsHelperService,
      useClass: NotificationsHelperService,
    },
  ],
})
export class SharedFeaturesModule {}
