import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextContent } from '../../parts/part-text/part-text.component';
import { RouterModule } from '@angular/router';
import { PartDelimiterComponent } from '../../parts/part-delimiter/part-delimiter.component';
import { PartTextComponent } from '../../parts/part-text/part-text.component';
import { overrideInputs } from '@sae/base';

export interface CompDelimitTextConfig {
  disableLinks?: boolean;
  set?: TextContent[];
  delimiter?: string;
  spaced?: boolean;
}

@Component({
  selector: 'fs-comp-delimit-text',
  styleUrls: ['comp-delimit-text.component.scss'],
  templateUrl: './comp-delimit-text.component.html',
  standalone: true,
  imports: [RouterModule, CommonModule, PartTextComponent, PartDelimiterComponent],
})
export class CompDelimitTextComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: CompDelimitTextConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() disableLinks = false; // Allows forcibly disabling links to avoid ADA issues (for when inside of a link)
  @Input() set: TextContent[]; // Required, varioius properties of the delimiter set to render
  @Input() delimiter = '&#183;'; // Use another HTML character for the delimiter, enter 'space' for a space
  @Input() spaced = false; // Spaces the segments out

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }
}
