<ng-template #template>
  <ng-container
    *ngIf="{
      facets: facets$ | async,
      filters: filters$ | async,
      customFilters: customFilters$ | async,
      preservedFacet: preservedFacet$ | async,
      collectionsFacet: collectionsFacet$ | async,
      industriesFacet: industriesFacet$ | async,
      contentTypesFacet: contentTypesFacet$ | async,
      publishedDateFacet: publishedDateFacet$ | async,
      topicsFacet: topicsFacet$ | async,
      authorsFacet: authorsFacet$ | async,
      affiliationsFacet: affiliationsFacet$ | async,
      committeesFacet: committeesFacet$ | async,
      publishersFacet: publishersFacet$ | async,
      eventsFacet: eventsFacet$ | async,
      searchFormatsFacet: searchFormatsFacet$ | async,
      categoriesFacet: categoriesFacet$ | async,
      featuresFacet: featuresFacet$ | async,
      supplierName: supplierName$ | async,
      cellFormat: cellFormat$ | async,
      length: length$ | async,
      height: height$ | async,
      width: width$ | async
    } as _"
  >
    <form class="si-form si-form--master si-impact">
      <div class="si-form__b">
        <mat-accordion class="si-expanel__container si-component">
          <mat-expansion-panel
            class="si-expanel si-expanel--form si-expanel--facet si-expanel--parent si-impact mat-elevation-z"
            [expanded]="!!startExpanded"
          >
            <mat-expansion-panel-header class="si-expanel__h">
              <mat-panel-title class="si-expanel__title si-impact__title si-major">
                <strong>Filter</strong>
              </mat-panel-title>
              <span class="si-expanse"></span>
              <button
                mat-icon-button
                [matMenuTriggerFor]="filterOpts"
                class="si-button si-icon--more si-when-expanded si-offset--toggle"
                matTooltip="Options"
                aria-label="Options"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-expansion-panel-header>
            <div *ngIf="!loading || panelInitialized" class="si-expanel__c si-expanel__c--form">
              <!-- ONLY Facet -->
              <mobi-facet-expanel
                *ngIf="onlyFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.customFilters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyCustomFilter(facet, $event)"
                (removeFacetClicked)="removeCustomFilter(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- FEATURES Facet -->
              <mobi-facet-expanel
                *ngIf="_.featuresFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- COLLECTIONS Facet -->
              <mobi-facet-expanel
                *ngIf="_.collectionsFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- INDUSTRIES Facet -->
              <mobi-facet-expanel
                *ngIf="_.industriesFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- CONTENT TYPES Facet-->
              <mobi-facet-expanel
                *ngIf="_.contentTypesFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- PUBLISHED DATE Facet -->
              <mobi-pubdate-facet-expanel
                *ngIf="_.publishedDateFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                [isPubDateFilterApplied]="isPubDateFilterApplied"
                [pubDateTo]="pubDateTo"
                [pubDateFrom]="pubDateFrom"
                (applyFilterClicked)="applyPubDateFilter(facet, $event)"
                (removeFilterClicked)="removePubDateFilter(facet)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-pubdate-facet-expanel>
              <!-- TOPICS Facet -->
              <mobi-facet-expanel
                *ngIf="_.topicsFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- AUTHORS Facet -->
              <mobi-facet-expanel
                *ngIf="_.authorsFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- AFFILIATIONS Facet -->
              <mobi-facet-expanel
                *ngIf="_.affiliationsFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- COMMITTEES Facet -->
              <mobi-facet-expanel
                *ngIf="_.committeesFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- PUBLISHERS Facet -->
              <mobi-facet-expanel
                *ngIf="_.publishersFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- EVENTS Facet -->
              <mobi-facet-expanel
                *ngIf="_.eventsFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- SEARCH FORMATS Facet -->
              <mobi-facet-expanel
                *ngIf="_.searchFormatsFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>
              <!-- CATEGORIES Facet -->
              <mobi-facet-expanel
                *ngIf="_.categoriesFacet as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>

              <!-- Battery DB Facets -->

              <!-- SUPPLIER NAME Facet -->
              <mobi-facet-expanel
                *ngIf="_.supplierName as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>

              <!-- CELL FORMAT Facet -->
              <mobi-facet-expanel
                *ngIf="_.cellFormat as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                (applyFacetClicked)="applyFacet(facet, $event)"
                (removeFacetClicked)="removeFacet(facet, $event)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-facet-expanel>

              <!-- Length Facet -->
              <mobi-slider-facet-expanel
                *ngIf="_.length as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                [isFilterApplied]="isLengthFilterApplied"
                (applyFilterClicked)="applyMinMaxFilter(facet, $event)"
                (removeFilterClicked)="removeMinMaxFilter(facet)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-slider-facet-expanel>

              <!-- Height Facet -->
              <mobi-slider-facet-expanel
                *ngIf="_.height as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                [isFilterApplied]="isHeightFilterApplied"
                (applyFilterClicked)="applyMinMaxFilter(facet, $event)"
                (removeFilterClicked)="removeMinMaxFilter(facet)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-slider-facet-expanel>

              <!-- Width Facet -->
              <mobi-slider-facet-expanel
                *ngIf="_.width as facet"
                class="si-facet__wrap"
                [facet]="facet.name === _.preservedFacet?.name ? _.preservedFacet : facet"
                [filters]="_.filters[facet.name]"
                [isPreservedFacet]="facet.name === _.preservedFacet?.name"
                [isFilterApplied]="isWidthFilterApplied"
                (applyFilterClicked)="applyMinMaxFilter(facet, $event)"
                (removeFilterClicked)="removeMinMaxFilter(facet)"
                (clearPreservedFacetClicked)="clearPreservedFacet()"
                (expandAggregationClicked)="expandAggregation(facet)"
                (collapseAggregationClicked)="collapseAggregation(facet)"
                (showMoreValuesClicked)="showMoreValues(facet)"
              ></mobi-slider-facet-expanel>
            </div>
            <div *ngIf="loading && !panelInitialized" class="si-component si-spinner__container">
              <mat-spinner class="si-spinner"></mat-spinner>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </form>

    <mat-menu #filterOpts="matMenu" class="si-menu">
      <button mat-menu-item class="si-button--detailed" (click)="expandAll()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">unfold_more</mat-icon>
        <span>Expand All</span>
      </button>
      <button mat-menu-item class="si-button--detailed" (click)="collapseAll()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">unfold_less</mat-icon>
        <span>Collapse All</span>
      </button>
      <mat-divider class="si-divider"></mat-divider>
      <button
        (click)="clearFilters()"
        mat-menu-item
        class="si-button--detailed"
        [class.si-state--zero]="
          (_.filters | keyvalue)?.length === 0 &&
          (_.customFilters | keyvalue)?.length === 0 &&
          !isPubDateFilterApplied &&
          !isLengthFilterApplied &&
          !isHeightFilterApplied &&
          !isWidthFilterApplied
        "
        [disabled]="
          (_.filters | keyvalue)?.length === 0 &&
          (_.customFilters | keyvalue)?.length === 0 &&
          !isPubDateFilterApplied &&
          !isLengthFilterApplied &&
          !isHeightFilterApplied &&
          !isWidthFilterApplied
        "
      >
        <mat-icon class="si-tool-icon mat-icon" color="primary" aria-hidden="true">cancel</mat-icon>
        <span>Clear Filters</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>
