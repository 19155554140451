import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  public subGroupToBrowseRoute(subGroup: string): string {
    switch (subGroup) {
      case 'Book':
        return '/books';
      case 'Research Report':
        return '/reports';
      case 'Journal Article':
        return '/journal-articles';
      case 'Magazine Article':
        return '/magazine-articles';
      case 'Magazine':
        return '/magazines';
      case 'Best Practice':
        return '/standards/best-practice';
      case 'Characteristics':
        return '/standards/characteristics';
      case 'Digital Annex':
        return '/standards/digital-annex';
      case 'Information Report':
        return '/standards/information-report';
      case 'Material Specification':
        return '/standards/material-specification';
      case 'Metric Aerospace Recommended Practice':
        return '/standards/metric-aerospace-recommended-practice';
      case 'Recommended Practice':
        return '/standards/recommended-practice';
      case 'Specifications':
        return '/standards/specifications';
      case 'Technical Standard':
        return '/standards/technical-standard';
      case 'Technical Paper':
        return '/technical-papers';
      case 'eLearning':
        return '/elearning';
      case 'Instructor Led':
        return '/instructor-led';
      default:
        return `/search#sub_group=${encodeURIComponent(subGroup)}`;
    }
  }
}
