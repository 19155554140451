<div class="si-card--dataviewer__data__h">
  <div class="si-sort">
    <div class="si-tools">
      <mat-chip-list #chipList class="si-chiplist" *ngIf="enableShare && shareCount">
        <mat-chip
          class="si-chip si-chip--minor si-chip--long si-button--detailed"
          data-testid="shareChip"
          (click)="shareChipClicked()"
        >
          <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">group</mat-icon>
          <span>{{ shareLabel }} ({{ shareCount }})</span>
        </mat-chip>
      </mat-chip-list>

      <span>
        <button
          mat-icon-button
          data-testid="refreshList"
          class="si-button si-button--icon"
          matTooltip="Refresh List"
          aria-label="Refresh List"
          (click)="emitRefreshEvent()"
        >
          <mat-icon aria-hidden="true">refresh</mat-icon>
        </button>
      </span>
    </div>

    <div class="si-sort-row">
      <button
        mat-icon-button
        data-testid="sortOpts"
        class="si-button--icon si-icon"
        matTooltip="Sort Options"
        aria-label="Sort Options"
        [matMenuTriggerFor]="sortOpts"
        (onMenuOpen)="emitMenuEvent(true)"
        (onMenuClose)="emitMenuEvent(false)"
      >
        <mat-icon aria-hidden="true">sort_by_alpha</mat-icon>
      </button>
      <mat-menu #sortOpts="matMenu" class="si-menu">
        <button
          mat-menu-item
          data-testid="setFileType"
          class="si-button--detailed"
          [class.si-state--selected-not]="sortProperty !== sortKey.fileType"
          (click)="setSortProperty(sortKey.fileType)"
        >
          <mat-icon aria-hidden="true">check</mat-icon>
          <span>Type</span>
        </button>
        <button
          mat-menu-item
          data-testid="setName"
          class="si-button--detailed"
          [class.si-state--selected-not]="sortProperty !== sortKey.name"
          (click)="setSortProperty(sortKey.name)"
        >
          <mat-icon aria-hidden="true">check</mat-icon>
          <span>Title</span>
        </button>
        <button
          mat-menu-item
          data-testid="setModDate"
          class="si-button--detailed"
          [class.si-state--selected-not]="sortProperty !== sortKey.modificationDate"
          (click)="setSortProperty(sortKey.modificationDate)"
        >
          <mat-icon aria-hidden="true">check</mat-icon>
          <span>Modified Date</span>
        </button>
        <button
          mat-menu-item
          data-testid="setModifiedBy"
          class="si-button--detailed"
          *ngIf="showModifiedByUser"
          [class.si-state--selected-not]="sortProperty !== sortKey.modifiedBy"
          (click)="setSortProperty(sortKey.modifiedBy)"
        >
          <mat-icon aria-hidden="true">check</mat-icon>
          <span>Modified by User</span>
        </button>
      </mat-menu>
      <span>
        <button class="si-button si-button--sort" data-testid="toggleSortDir" (click)="toggleSortDirection()">
          <span>{{ sortPropertyDisplay }}</span>
          <mat-icon class="si-icon" *ngIf="sortDirection === 'asc'" aria-label="Sort by Ascending">
            arrow_upward
          </mat-icon>
          <mat-icon class="si-icon" *ngIf="sortDirection === 'desc'" aria-label="Sort by Descending">
            arrow_downward
          </mat-icon>
        </button>
      </span>
    </div>
  </div>
</div>
