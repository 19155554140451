<dl class="si-dataout">
  <dt class="si-dataout__h" *ngIf="title">
    <div class="si-dataout__h__title">
      {{ title }}
    </div>
  </dt>
  <dd class="si-dataout__c">
    <span *ngIf="content" style="display: flex">
      <a href="{{ content?.doiUri }}" class="si-expanse">{{ content?.doiUri }}</a>
      <span
        class="__dimensions_badge_embed__"
        [attr.data-doi]="content?.issueNumber"
        data-style="large_rectangle"
        data-hide-zero-citations="true"
        data-testid="details-badge"
        style="margin-right: 5px"
      ></span>
    </span>
  </dd>
</dl>
