import { KeycloakProfile } from 'keycloak-js';

export interface AuthState {
  profile: KeycloakProfile;
  isLoggedIn: boolean;
  logInError: string;
  loadingLogIn: boolean;
  logOutError: string;
  logOutInProgress: boolean;
}

export const initialAuthState: AuthState = {
  profile: undefined,
  isLoggedIn: false,
  logInError: undefined,
  loadingLogIn: false,
  logOutError: undefined,
  logOutInProgress: false,
};
