import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { SaeHttpResponse } from '@sae/models';
import { CookieService } from 'ngx-cookie';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SubscriptionsService } from './state/subscriptions.service';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private subscriptionsService: SubscriptionsService) {}
  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse & SaeHttpResponse) => {
        if (
          error?.error?.statusCode === 401 &&
          (error?.error?.statusMessage === 'Invalid/Expired X-Auth-Token' ||
            error?.error?.statusMessage === 'No X-Auth-Token found' ||
            error?.error?.errorMessages?.includes('Invalid/Expired X-Auth-Token') ||
            error?.error?.errorMessages?.includes('No X-Auth-Token found'))
        ) {
          const newReq = request.clone({
            headers: request.headers.delete('X-Auth-Token'),
          });
          this.cookieService.remove('X-Auth-Token', {
            domain: window.location.origin.includes('localhost')
              ? undefined
              : window.location.host.substring(window.location.host.indexOf('.') + 1).split(':')[0],
          });
          this.cookieService.remove('X-Auth-Token', { domain: 'sae.org' });
          this.subscriptionsService.clearSubscriptions();
          const rememberMeToken = this.cookieService.get('X-Remember-Me');
          if (rememberMeToken) {
            return this.subscriptionsService.rememberMeLogin(rememberMeToken).pipe(
              switchMap(() => {
                return !(newReq.url.includes('/sessions') && newReq.method === 'POST') ? next.handle(newReq) : of(null);
              })
            );
          } else {
            return next.handle(newReq);
          }
        } else {
          return throwError(() => error);
        }
      })
    );
  }
}
