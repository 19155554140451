<ng-template #template>
  <ng-container
    *ngIf="{
      config: config$ | async,
      content: content$ | async,
      isToolPanelCollapsed: isToolPanelCollapsed$ | async,
      backUri: backUri$ | async,
      title: title$ | async,
      fullScreen: fullScreen$ | async
    } as _"
  >
    <mat-toolbar class="si-toolbar si-toolbar--pagination si-toolbar--navbar" [class.si-unusual]="_.fullScreen">
      <div class="si-toolbar__h" *ngIf="!_.fullScreen">
        <a (click)="goBack(_.backUri?.url, _.content)" class="si-button si-button--inline si-button--return" mat-button data-analytics="detail-page-back-btn">
          <mat-icon aria-hidden="true">arrow_back</mat-icon>
          <strong class="si-toolbar__title si-visible--beyond-mobile">{{ _.title }}</strong>
        </a>
      </div>
      <div class="si-toolbar__h" *ngIf="_.fullScreen">
        <a (click)="toggleFullScreen(false)" class="si-button si-button--inline si-button--return" mat-button>
          <mat-icon aria-hidden="true">arrow_back</mat-icon>
          <strong class="si-toolbar__title si-visible--beyond-mobile">Exit Full Screen</strong>
        </a>
      </div>
      <span class="si-expanse"></span>
      <div class="si-toolbar__c">
        <span class="si-for--pagination-count">{{ resultRecord }}</span>
      </div>
      <div class="si-toolbar__a">
        <a
          [routerLink]="prevUri"
          mat-icon-button
          [matTooltip]="prevTooltip"
          [attr.aria-label]="prevTooltip"
          class="si-icon si-button--prev si-button--browse"
          *ngIf="false"
        >
          <mat-icon aria-hidden="true">keyboard_arrow_left</mat-icon>
        </a>
        <a
          [routerLink]="nextUri"
          mat-icon-button
          [matTooltip]="nextTooltip"
          [attr.aria-label]="nextTooltip"
          class="si-icon si-button--next si-button--browse"
          *ngIf="false"
        >
          <mat-icon aria-hidden="true">keyboard_arrow_right</mat-icon>
        </a>
        <button
          *ngIf="_.isToolPanelCollapsed"
          mat-flat-button
          class="si-button si-button--aux si-visible--beyond-small-tablet"
          (click)="setIsToolPanelCollapsed(false)"
        >
          {{ _.config.header_aux_text }}
        </button>
      </div>
      <div
        class="si-reserve si-col--aux"
        [class.si-thin]="thinAux"
        [class.si-medium]="mediumAux"
        *ngIf="!_.isToolPanelCollapsed"
      ></div>
    </mat-toolbar>
  </ng-container>
</ng-template>
