<button
  #cardButton
  *ngIf="enableMenu"
  class="si-button--wrap si-button--wrap--inline"
  mat-button
  [matMenuTriggerFor]="personDetailsInline"
  (menuOpened)="setMenuState(true)"
  (menuClosed)="setMenuState(false)"
>
  <mat-card
    *ngIf="personTagInfo"
    class="si-card si-card--tag si-entity--person mat-elevation-z2"
    [class.si-entity--person--saerep]="
      personType === 'SAE International Specialist'
    "
    [class.si-entity--person--sponsor]="personType === 'Sponsor'"
    [class.si-entity--person--author]="personType === 'Author'"
  >
    <mat-card-header class="si-card__h">
      <mat-card-title class="si-card__title"
        ><div [innerHTML]="personTagInfo.displayName"></div
      ></mat-card-title>
      <span class="si-expanse"></span>
      <ng-content #headerXtras select=".hX"></ng-content>
    </mat-card-header>
  </mat-card>
</button>

<ng-container *ngIf="!enableMenu" class="si-button--wrap">
  <mat-card
    *ngIf="personTagInfo"
    class="si-card si-card--tag si-entity--person mat-elevation-z2"
    [class.si-entity--person--saerep]="
      personType === 'SAE International Specialist'
    "
    [class.si-entity--person--sponsor]="personType === 'Sponsor'"
    [class.si-entity--person--author]="personType === 'Author'"
  >
    <mat-card-header class="si-card__h">
      <mat-card-title class="si-card__title"><div [innerHTML]="personTagInfo.displayName"></div
        ></mat-card-title>
      <span class="si-expanse"></span>
    </mat-card-header>
  </mat-card>
</ng-container>

<mat-menu
  #personDetailsInline="matMenu"
  [overlapTrigger]="true"
  class="si-menu-card"
  [xPosition]="menuLocation"
>
  <ng-content></ng-content>
</mat-menu>
