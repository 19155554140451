/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AppInitCoreService } from './core/app-init.core';
import { ProteusAppInitCoreService } from './core/proteus-app-init.core';

/**
 * A service designed to log developer messages to the dev and test environments
 * @public
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  /** Determines whether or not a message should be logged */
  private _shouldLog =
    AppInitCoreService?.currentEnv === 'localhost' ||
    AppInitCoreService?.currentEnv === 'dev' ||
    AppInitCoreService?.currentEnv === 'test' ||
    ProteusAppInitCoreService?.currentEnv === 'localhost' ||
    ProteusAppInitCoreService?.currentEnv === 'dev' ||
    ProteusAppInitCoreService?.currentEnv === 'test';

  /**
   * A wrapper around console.log which adds a black background and lightgreen text
   * @param { any } message - The value to be logged
   */
  public log(message?: any): void {
    if (this._shouldLog) {
      console.log(`%c${message}`, 'background-color: black; color: lightgreen; padding: 5px;');
    }
  }

  /**
   * A wrapper around console.log which adds a black background and yellow text
   * @param { any } message  - The value to be logged
   */
  public warn(message?: any): void {
    if (this._shouldLog) {
      console.log(`%c${message}`, 'background-color: black; color: yellow; padding: 5px;');
    }
  }

  /**
   * A wrapper around console.log which adds a black background and lightcoral text
   * @param { any } message - The value to be logged
   */
  public error(message?: any): void {
    if (this._shouldLog) {
      console.log(`%c${message}`, 'background-color: black; color: lightcoral; padding: 5px;');
    }
  }
}
