import { Component, Input } from '@angular/core';

@Component({
  selector: 'si-state-card',
  templateUrl: './state-card.component.html',
})
export class StateCardComponent {
  @Input() customIcon!: string;
  @Input() materialIcon!: string;
  @Input() progressIcon!: boolean;
  @Input() spinnerIcon!: boolean;
  @Input() title!: string;
  @Input() subTitle!: string;
}
