import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map, tap } from 'rxjs';
import { AppSearchResponseFacet, RangeFacetData, ValueFacetData } from '../../models/api-models';
import { UISearchFacet, UISearchFilters } from '../../models/common-interfaces';
import { Aggregation } from '../../models/search-result.model';
import { SubscriptionsService } from '../../state/subscriptions.service';

@Component({
  selector: 'mobi-filter-panel',
  templateUrl: './filter-panel.component.html',
})
export class FilterPanelComponent implements OnInit {
  @Input() loading = false;
  @Input() panelInitialized = false;
  @Input() startExpanded: boolean;
  @Input() isPubDateFilterApplied = false;
  @Input() isLengthFilterApplied = false;
  @Input() isHeightFilterApplied = false;
  @Input() isWidthFilterApplied = false;
  @Input() pubDateTo: Date;
  @Input() pubDateFrom: Date;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() facets$: Observable<UISearchFacet<any>[]>;
  @Input() onlyFacet: UISearchFacet<ValueFacetData>;
  @Input() filters$: Observable<UISearchFilters>;
  @Input() customFilters$: Observable<UISearchFilters>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() preservedFacet$: Observable<UISearchFacet<any>>;

  @Output() showMoreValuesClicked = new EventEmitter();
  @Output() applyFacetClicked = new EventEmitter();
  @Output() removeFacetClicked = new EventEmitter();
  @Output() expandAllClicked = new EventEmitter();
  @Output() collapseAllClicked = new EventEmitter();
  @Output() clearPreservedFacetClicked = new EventEmitter();
  @Output() clearFiltersClicked = new EventEmitter();
  @Output() applyPubDateFilterClicked = new EventEmitter();
  @Output() removePubDateFilterClicked = new EventEmitter();
  @Output() applyCustomFilterClicked = new EventEmitter();
  @Output() removeCustomFilterClicked = new EventEmitter();
  @Output() applyMinMaxFilterClicked = new EventEmitter();
  @Output() removeMinMaxFilterClicked = new EventEmitter();

  public collectionsFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public industriesFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public contentTypesFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public publishedDateFacet$: Observable<UISearchFacet<RangeFacetData>>;
  public topicsFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public authorsFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public affiliationsFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public committeesFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public publishersFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public eventsFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public searchFormatsFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public categoriesFacet$: Observable<UISearchFacet<ValueFacetData>>;
  public featuresFacet$: Observable<UISearchFacet<ValueFacetData>>;

  // Battery DB Facets
  public supplierName$: Observable<UISearchFacet<ValueFacetData>>;
  public cellFormat$: Observable<UISearchFacet<ValueFacetData>>;
  public height$: Observable<UISearchFacet<ValueFacetData>>;
  public length$: Observable<UISearchFacet<ValueFacetData>>;
  public width$: Observable<UISearchFacet<ValueFacetData>>;

  @ViewChild('template', { static: true }) template;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private store: Store,
    private subscriptionsService: SubscriptionsService
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.collectionsFacet$ = this.facets$.pipe(
      map((facets) => facets?.find((facet) => facet.name === 'collection_title'))
    );
    this.industriesFacet$ = this.facets$.pipe(
      map((facets) => facets?.find((facet) => facet.name === 'industrysectors_name'))
    );
    this.contentTypesFacet$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'sub_group')));
    this.publishedDateFacet$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'pub_date')));
    this.topicsFacet$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'topics')));
    this.authorsFacet$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'authors')));
    this.affiliationsFacet$ = this.facets$.pipe(
      map((facets) => facets?.find((facet) => facet.name === 'affiliations'))
    );
    this.committeesFacet$;
    this.publishersFacet$ = this.facets$.pipe(
      map((facets) => facets?.find((facet) => facet.name === 'publisher_name'))
    );
    this.eventsFacet$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'conference_name')));
    this.searchFormatsFacet$ = this.facets$.pipe(
      map((facets) => facets?.find((facet) => facet.name === 'full_text_formats'))
    );
    this.featuresFacet$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'features')));
    this.categoriesFacet$;
    // Battery DB Facets
    this.supplierName$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'supplierName')));
    this.cellFormat$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'cellFormat')));
    this.length$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'length')));
    this.height$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'height')));
    this.width$ = this.facets$.pipe(map((facets) => facets?.find((facet) => facet.name === 'width')));
  }

  expandAll(): void {
    this.expandAllClicked.emit();
  }

  collapseAll(): void {
    this.collapseAllClicked.emit();
  }

  clearFilters(): void {
    this.clearFiltersClicked.emit();
  }

  applyFacet(facet: AppSearchResponseFacet, value): void {
    this.applyFacetClicked.emit([facet, value]);
  }

  removeFacet(facet: AppSearchResponseFacet, value): void {
    this.removeFacetClicked.emit([facet, value]);
  }

  applyPublishedFacet(facet: AppSearchResponseFacet, [from, to]: [Date, Date]): void {}

  removePublishedFacet(): void {}

  clearPreservedFacet(): void {
    this.clearPreservedFacetClicked.emit();
  }

  expandAggregation(facet: Aggregation): void {}

  collapseAggregation(facet: Aggregation): void {}

  showMoreValues(facet: UISearchFacet<any>): void {
    this.showMoreValuesClicked.emit(facet);
  }

  applyPubDateFilter(facet, value) {
    this.applyPubDateFilterClicked.emit([facet, value]);
  }

  removePubDateFilter() {
    this.removePubDateFilterClicked.emit();
  }
  applyCustomFilter(facet: AppSearchResponseFacet, value): void {
    this.applyCustomFilterClicked.emit([facet, value]);
  }

  removeCustomFilter(facet: AppSearchResponseFacet, value): void {
    this.removeCustomFilterClicked.emit([facet, value]);
  }

  applyMinMaxFilter(facet: AppSearchResponseFacet, value): void {
    this.applyMinMaxFilterClicked.emit([facet, value]);
  }
  removeMinMaxFilter(facet: AppSearchResponseFacet): void {
    this.removeMinMaxFilterClicked.emit(facet);
  }
}
