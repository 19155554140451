import { Injectable } from '@angular/core';
import { SessionCoreBase } from '@sae/services';
import { AppSessionQuery } from '../state/session.query';
import { ActiveNavIcon, AppSessionState, SessionStore } from '../state/session.store';

@Injectable({ providedIn: 'root' })
export class SessionService extends SessionCoreBase<AppSessionState> {
  constructor(public readonly query: AppSessionQuery, public readonly store: SessionStore) {
    super(store, query);
  }

  showMenu$ = this.query.select((state) => state.showMenu);
  activeViewTemplate$ = this.query.select((state) => state.activeViewTemplate);

  downloadedCids$ = this.query.select((state) => state.downloadedCids);

  setShowMenu(showMenu: boolean): void {
    this.store.update({ showMenu });
  }

  setShowAdvancedSearch(showAdvancedSearch: boolean): void {
    this.store.update({ showAdvancedSearch });
  }

  setActiveNavIcon(activeNavIcon: ActiveNavIcon): void {
    super.setActiveNavIcon(activeNavIcon);
  }

  addDownloadedCid(id: number): void {
    this.store.update({ downloadedCids: [...this.query.getValue().downloadedCids, id] });
  }
}
