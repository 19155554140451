import { ApplicationInitStatus, Component, Inject, Input, OnChanges } from '@angular/core';
import {
  DetailToolView,
  DetailToolTakeoverView,
  DetailView,
  MasterDetailToolView,
  MasterDetailView,
  TemplateModel,
  ViewTemplate,
  CrossoverMasterDetailView,
  DetailTakeoverView,
  PreAppView,
} from '@sae/models';
import { ISessionService, SESSION_TOKEN } from '@sae/services';
import { Feature } from '../../seo-feature-toolbar/seo-feature-toolbar.component';
import { BaseTemplateComponent } from '../base-template.component';

@Component({
  selector: 'si-app-component',
  templateUrl: './si-app-component.component.html',
  styleUrls: ['./si-app-component.component.scss'],
})
export class BaseAppComponent extends BaseTemplateComponent implements OnChanges {
  ViewTemplate = ViewTemplate;
  @Input() activeViewTemplate!: ViewTemplate;
  @Input() isInMaintenanceMode = false;
  /**
   * @deprecated prefer to call setShowHomeNavIcon in session core.
   * If a value for showHomeFeature is explicitly provided it will override
   * showHomeNavIcon from session core. Otherwise, showHomeNavIcon is used.
   * */
  @Input() showHomeFeature: boolean | null = null;
  @Input() features!: Feature[];
  @Input() projectedContent!: TemplateModel;
  @Input() updatesRoute = '/';
  @Input() updatesLabel = 'Updates';

  detailViewContent: DetailView;
  detailToolViewContent: DetailToolView;
  masterDetailViewContent: MasterDetailView;
  masterDetailToolViewContent: MasterDetailToolView;
  crossoverMasterDetailViewContent: CrossoverMasterDetailView;
  detailTakeoverViewContent: DetailTakeoverView;
  detailToolTakeoverViewContent: DetailToolTakeoverView;
  preAppViewContent: PreAppView;

  constructor(
    public appInit: ApplicationInitStatus,
    @Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService
  ) {
    super(sessionService);
  }

  ngOnChanges(): void {
    this.detailViewContent = this.projectedContent as DetailView;
    this.detailToolViewContent = this.projectedContent as DetailToolView;
    this.masterDetailViewContent = this.projectedContent as MasterDetailView;
    this.masterDetailToolViewContent = this.projectedContent as MasterDetailToolView;
    this.crossoverMasterDetailViewContent = this.projectedContent as CrossoverMasterDetailView;
    this.detailTakeoverViewContent = this.projectedContent as DetailTakeoverView;
    this.detailToolTakeoverViewContent = this.projectedContent as DetailToolTakeoverView;
    this.preAppViewContent = this.projectedContent as PreAppView;
  }
}
