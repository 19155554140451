import { ContentGroup } from '@sae/models';
import { AccessControlResult, DocumentAsset, MeterDetails } from '../../../shared/models/api-models';
import { Category, RelatedArticle } from '../../../shared/models/publication.model';
import {
  CollectionEntry,
  Conference,
  ContributorGroup,
  MetaData,
  Taxonomy,
} from '../../../shared/models/search-result.model';
import { DigitalStandard } from '../../../shared/state/digital-standards.service';

interface BasicUris {
  seoUri: string;
  canonicalUrl: string;
  doiUri: string;
}

interface PlatformUri {
  platformName: string;
  uri: string;
  legacyUri: string;
}

interface DocumentUris {
  basicUris: BasicUris;
  platformUris: PlatformUri[];
}

export interface Revision {
  status: string;
  selfReference: string;
  latest: string;
  cid: number;
  publicationId: string;
  code: string;
  extendedCode: string;
  useExtendedCode: boolean;
  pubDate: Date;
  productStatus: string;
  revisionStatus: string;
  title: string;
  doiUri: string;
  doiVerified: string;
  type: string;
  date: Date;
  hasXml: boolean;
  seoUri?: string;
}

export interface StandardAttributes {
  revisionStatus: string;
  current: boolean;
  revision: string;
  revisions: Revision[];
}

export interface ContentApiResponse {
  accessProperties: AccessControlResult;
  alertId?: string;
  collections: {
    collection: CollectionEntry[];
  };
  meta: MetaData;
  taxonomy: Taxonomy;
  bookmarks?: Bookmark[];
  documentAssets: DocumentAsset[];
  documentUris: DocumentUris;
  standardAttributes: StandardAttributes;
  hasAnnotation: boolean;
  courseAttributes: CourseAttributes;
  partsConfiguratorUrl?: string;
  volumes?: Volume[];
  journalSeoUri?: string;
  fulltextMediaUrl?: string;
  previewMediaUrl?: string;
}

export interface ContentView {
  id?: number;
  publicationId?: string;
  alertId?: string;
  rootCode?: string;
  hasAccess?: boolean;
  industryChips?: string[];
  subGroup?: string;
  officialType?: string;
  group?: string;
  title?: string;
  code?: string;
  pubDate?: string;
  dataSets?: DocumentAsset[];
  event?: string;
  authors?: { name: string; affiliation?: string }[];
  abstract?: string;
  topics?: string[];
  searchTerms?: string[];
  affiliations?: string[];
  doiUri?: string;
  doiVerified?: boolean;
  isbn?: string;
  issueNumber?: string;
  references?: string[];
  pageCount?: number;
  citation?: string;
  crossReferences?: CrossReference[];
  tableOfContents?: string[];
  citedBy?: string[];
  publisher?: string;
  publisherId?: string;
  language: string;
  btnExport?: boolean;
  btnShare?: boolean;
  btnFavorite?: boolean;
  btnDownload?: boolean;
  btnAlert?: boolean;
  btn3D?: boolean;
  showToolPanel?: boolean;
  showAnnotations?: boolean;
  showRedlined?: boolean;
  contribGroups?: ContributorGroup[];
  // contentType?: string;
  contentGroup?: ContentGroup;
  rationale?: string;
  digitalStandard?: DigitalStandard;
  standardAttributes: StandardAttributes;
  standardStatus?: string;
  meterDetails?: MeterDetails;
  relatedArticle?: RelatedArticle;
  country?: string;
  feature2d3d: boolean;
  featureAnnotate: boolean;
  featureDataSets: boolean;
  featureRedlined: boolean;
  bookmarks?: Bookmark[];
  documentUris?: DocumentUris;
  accessProperties?: AccessControlResult;
  documentAssets?: DocumentAsset[];
  hasAnnotation: boolean;
  videoLength?: string;
  categories?: Category[];
  magCode: number;
  fulltextMediaUrl: string;
  previewMediaUrl: string;
  learningObjectives: string;
  audience: string;
  testimonials: string;
  prerequisites: string;
  averageTimeSecs: string;
  creditsCeus: number;
  outline: string;
  materials: string;
  requirements: string;
  instructorBiography: string;
  partsConfiguratorUrl?: string;
  volumes?: Volume[];
  journalSeoUri?: string;
  issn?: Issn[];
  conferences?: Conference[];
  formatCode?: string;
  collections: {
    collection: CollectionEntry[];
  };
}

export interface Issn {
  publicationFormat: string;
  content: string;
}

export interface CrossReference {
  citation: string;
  contentId?: number;
  doi: string;
  external: boolean;
  productCode?: string;
}

export interface Bookmark {
  folderName: string;
  resourceId: string;
  folderId: string;
  shared: boolean;
}

export interface CitationOption {
  icon: string;
  name: string;
  key: string;
  divider: boolean;
}

export const citationOpts: CitationOption[] = [
  {
    icon: 'file_download',
    name: 'RefMan',
    key: 'refman',
    divider: false,
  },
  {
    icon: 'file_download',
    name: 'RefWorks',
    key: 'refworks',
    divider: false,
  },
  {
    icon: 'file_download',
    name: 'EndNote',
    key: 'endnote',
    divider: false,
  },
  {
    icon: 'file_download',
    name: 'BibTex',
    key: 'bibtex',
    divider: true,
  },
];
export interface SubscriptionAdministrators {
  type: string;
  user: {
    emailAddress: string;
    createdDate: string;
    familyName: string;
    givenName: string;
    id: string;
    industries: [];
    middleName: string;
    modifiedDate: string;
    phones: SubscriptionAdministratorPhone[];
    status: string;
    username: string;
    country: {
      alpha2: string;
      name: string;
    };
  };
}

export interface SubscriptionAdministratorPhone {
  phone: string;
  countryCode: string;
  countryCallingCode: number;
  phoneType: string;
  type: string;
  nationalFormat: string;
  internationalFormat: string;
}
export interface CourseAttributes {
  audience: string;
  demoLink: string;
  instructorBiography: string;
  learningObjectives: string;
  materials: string;
  outline: string;
  prerequisites: string;
  requirements: string;
  averageTimeSecs: number;
  specialOffers: string;
  testimonials: string;
  creditsCeus: number;
  websiteImage: string;
}

export interface JournalBrxDetail {
  authorResources: string;
  aimsAndScope: string;
  manuscriptUrl: string;
  subscriptionOptions: string;
  journalImage: string[];
  subTitle: string;
  magazineCode: string;
  seoSubTitle: string;
  publicationDate: string;
  issn: string;
  eissn: string;
  editorialBoard: string;
  indexedBy: string;
  editorInChief: string;
}

export interface Volume {
  issues: Array<{ issue: number; publicationDate: Date }>;
  volume: number;
  cid?: number;
  code?: string;
  parentCid?: number;
  serialCode?: number;
}
