import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig, withTransaction } from '@datorama/akita';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { Store } from '@ngrx/store';
import { SaeHttpResponse } from '@sae/models';
import { CookieService } from 'ngx-cookie';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { IdentityProvider } from '../models/api-models';
import { Api } from '../../../api';
import { ContentApiResponse } from '../../modules/detail/models/content.model';
import { SubscriptionAuthenticationMethod, SubscriptionDownloadsApiResponse } from '../models/suscription-auth.model';
import { isPlatformBrowser } from '@angular/common';
import { ReportFormat } from '../models/report-config.model';

export interface SubscriptionSession {
  accountId: string;
  id: string;
  collections: {
    id: string;
    productAssignmentId: string;
    metered: boolean;
    startDate: Date;
    endDate: Date;
    title: string;
    name: string;
    access3D: boolean;
  }[];
  name: string;
  headerText: string;
  features: {
    enabled: boolean;
    startDate: Date;
    endDate: Date;
    id: string;
    name: string;
  }[];
  roles: string[];
}

export interface RequestAccessData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
  justification: string;
  productCode: string;
  productTitle: string;
  itemUrl: string;
}

export interface RequestAdmininstratorData {
  name: string;
  headerText: string;
  newAdminName: string;
  newAdminPhone: string;
  newAdminEmail: string;
}

export interface RequestAuthData {
  fullName: string;
  headerText: string;
  requestNote: string;
  linkToCms: string;
  phone: string;
  email: string;
}

export type SubscriptionAuthType = 'BLIND' | 'IP';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SubscriptionsState extends EntityState<SubscriptionSession, string> {}

function createInitialState(): SubscriptionsState {
  return {} as SubscriptionsState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subscriptions' })
export class SubscriptionsStore extends EntityStore<SubscriptionsState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class SubscriptionsQuery extends QueryEntity<SubscriptionsState> {
  constructor(protected store: SubscriptionsStore) {
    super(store);
  }
}

@NgEntityServiceConfig({
  resourceName: Api.subscriptions.url,
})
@Injectable({ providedIn: 'root' })
export class SubscriptionsService extends NgEntityService<SubscriptionsState> {
  public subscriptions$ = this.query.selectAll();
  public loading$ = this.query.selectLoading();
  public error$ = this.query.selectError();
  constructor(
    protected store: SubscriptionsStore,
    private query: SubscriptionsQuery,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super(store);
  }

  getSubscriptions(): SubscriptionSession[] {
    return this.query.getAll();
  }

  setSubscriptions(subs: SubscriptionSession[]): void {
    this.store.set(subs);
  }

  clearSubscriptions(): void {
    this.store.remove();
  }

  login(encodedLogin: string, rememberMe?: boolean): Observable<HttpResponse<SaeHttpResponse<SubscriptionSession>>> {
    const url = `${this.baseUrl}/${this.resourceName}/sessions`;
    return super
      .getHttp()
      .post<SaeHttpResponse<SubscriptionSession>>(
        url,
        { grant_type: 'CREDENTIAL', generate_remember_me: rememberMe ?? false },
        {
          headers: new HttpHeaders().set('Authorization', `Basic ${encodedLogin}`),
          observe: 'response',
        }
      )
      .pipe(
        tap((resp) => {
          const result = (resp?.body as SaeHttpResponse)?.results?.[0];
          if (!result) {
            return;
          }
          const rmHeader = resp.headers.get('X-Remember-Me');
          if (rmHeader && isPlatformBrowser(this.platformId)) {
            this.cookieService.put('X-Remember-Me', rmHeader);
          }
          this.store.upsert(result.id, result);
        })
      );
  }

  logout(): Observable<unknown> {
    const url = `${this.baseUrl}/${this.resourceName}/sessions`;
    return super
      .getHttp()
      .delete(url)
      .pipe(
        tap(() => {
          this.clearSubscriptions();
          if (isPlatformBrowser(this.platformId)) {
            this.cookieService.remove('X-Remember-Me');
          }
        })
      );
  }

  rememberMeLogin(token: string): Observable<HttpResponse<SaeHttpResponse<SubscriptionSession>>> {
    const url = `${this.baseUrl}/${this.resourceName}/sessions`;
    const body = {
      grant_type: 'REMEMBER_ME',
      token: token,
    };
    return super
      .getHttp()
      .post<SaeHttpResponse<SubscriptionSession>>(url, body, { observe: 'response' })
      .pipe(
        tap((resp) => {
          const newRmToken = resp.headers.get('X-Remember-Me');
          const newAuthToken = resp.headers.get('X-Auth-Token');
          if (newRmToken && isPlatformBrowser(this.platformId)) {
            this.cookieService.put('X-Remember-Me', newRmToken);
          }
          if (newAuthToken && isPlatformBrowser(this.platformId)) {
            this.cookieService.put('X-Auth-Token', newAuthToken, {
              domain: window.location.origin.includes('localhost')
                ? undefined
                : window.location.host.substring(window.location.host.indexOf('.') + 1),
            });
          }
        }),
        withTransaction((resp) => {
          (resp.body as SaeHttpResponse).results.forEach((result) => {
            this.store.upsert(result.id, result);
          });
        })
      );
  }

  retrieveAll(): Observable<SubscriptionSession[]> {
    const url = `${this.baseUrl}/${this.resourceName}/sessions`;
    let token;
    if (isPlatformBrowser(this.platformId)) {
      token = this.cookieService.get('X-Auth-Token');
    }
    return super
      .getHttp()
      .get(url, { headers: token ? new HttpHeaders().set('X-Auth-Token', token) : undefined, observe: 'response' })
      .pipe(
        map((resp: HttpResponse<SaeHttpResponse>) => {
          return resp.body.results;
        }),
        catchError(() => {
          return of([]);
        })
      );
  }
  getIdentityProviders(): Observable<IdentityProvider[]> {
    const url = `${this.baseUrl}/${Api.identityProviders.url}`;
    return super
      .getHttp()
      .get(url)
      .pipe(
        map((resp: SaeHttpResponse<IdentityProvider>) => {
          return resp?.results;
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  setLoading(bool: boolean): void {
    this.store.setLoading(bool);
  }

  setError(err: string): void {
    this.store.setError(err);
  }

  blindLogin(key: string): Observable<HttpResponse<SaeHttpResponse<SubscriptionSession>>> {
    const url = `${this.baseUrl}/${this.resourceName}/sessions`;
    return super
      .getHttp()
      .post<SaeHttpResponse<SubscriptionSession>>(
        url,
        {
          grant_type: 'API_KEY',
          api_key: key,
        },
        { observe: 'response' }
      )
      .pipe(
        tap((resp) => {
          const result = (resp?.body as SaeHttpResponse)?.results?.[0];
          if (!result) {
            return;
          }
          this.store.upsert(result.id, result);
        }),
        catchError(() => {
          return of(null);
        })
      );
  }

  public ipLogin(): Observable<HttpResponse<SaeHttpResponse<SubscriptionSession>>> {
    const url = `${this.baseUrl}/${this.resourceName}/sessions`;
    return super
      .getHttp()
      .post<SaeHttpResponse<SubscriptionSession>>(
        url,
        {
          grant_type: 'IP',
        },
        { observe: 'response' }
      )
      .pipe(
        tap((resp) => {
          const results = (resp?.body as SaeHttpResponse)?.results;
          if (!results?.length) {
            return;
          }
          results.forEach((result) => {
            this.store.upsert(result.id, result);
          });
        }),
        catchError(() => {
          return of(null);
        })
      );
  }

  public sso(transkey: string): Observable<HttpResponse<SaeHttpResponse<SubscriptionSession>>> {
    const url = `${this.baseUrl}/${this.resourceName}/sessions/sso`;
    return super
      .getHttp()
      .post<SaeHttpResponse<SubscriptionSession>>(url, { code: transkey }, { observe: 'response' })
      .pipe(
        tap((resp) => {
          const xAuthToken = resp.headers.get('X-Auth-Token');
          if (isPlatformBrowser(this.platformId)) {
            this.cookieService.put('X-Auth-Token', xAuthToken, {
              domain: window.location.origin.includes('localhost')
                ? undefined
                : window.location.host.substring(window.location.host.indexOf('.') + 1).split(':')[0],
            });
          }
        }),
        catchError(() => {
          return of(null);
        })
      );
  }

  public requestAccess(
    subscriptionId: string,
    data: RequestAccessData
  ): Observable<SaeHttpResponse<{ subject: string; templateId: string; data: RequestAccessData }>> {
    const url = `${this.baseUrl}/${this.resourceName}/${subscriptionId}/messages`;
    return super
      .getHttp()
      .post<SaeHttpResponse<{ subject: string; templateId: string; data: RequestAccessData }>>(url, {
        templateId: 'c2511ef7-f918-4fb9-ab2a-9b847a8435d1',
        subject: `SAE MOBILUS Download Request`,
        data,
        includeInternalAdmins: false,
      });
  }

  public requestAdmin(
    subscriptionId: string,
    data: RequestAdmininstratorData
  ): Observable<SaeHttpResponse<{ subject: string; templateId: string; data: RequestAdmininstratorData }>> {
    const url = `${this.baseUrl}/${this.resourceName}/${subscriptionId}/messages`;
    return super
      .getHttp()
      .post<SaeHttpResponse<{ subject: string; templateId: string; data: RequestAdmininstratorData }>>(url, {
        templateId: 'b99a0dfc-4a0a-4660-9a9b-94d051e500fe',
        subject: `Request to Add Administrator Received`,
        data,
      });
  }

  public requestAuth(
    subscriptionId: string,
    authType: SubscriptionAuthType,
    data: RequestAuthData
  ): Observable<SaeHttpResponse<{ subject: string; templateId: string; data: RequestAuthData }>> {
    const templateId =
      authType === 'BLIND' ? 'a250f950-934c-492d-90e8-7cf0a3d0cb33' : 'b6b9382a-d0ba-4882-be11-a22999b3d239';
    const url = `${this.baseUrl}/${this.resourceName}/${subscriptionId}/messages`;
    return super.getHttp().post<SaeHttpResponse<{ subject: string; templateId: string; data: RequestAuthData }>>(url, {
      templateId,
      subject: `Administrator ${authType === 'BLIND' ? 'Blind' : 'IP'} Authentication Request`,
      data,
    });
  }

  public getSubscriptionDownloads(subscriptionId: string): Observable<SubscriptionDownloadsApiResponse> {
    const url = `${this.baseUrl}/${this.resourceName}/${subscriptionId}/downloads`;
    return super
      .getHttp()
      .get<SaeHttpResponse<SubscriptionDownloadsApiResponse>>(url)
      .pipe(
        map((r) => r.results[0]),
        catchError(() => of(null))
      );
  }

  public getSubscriptionAuthentication(subscriptionId: string): Observable<SubscriptionAuthenticationMethod> {
    const url = `${this.baseUrl}/${this.resourceName}/${subscriptionId}/authenticationMethods`;
    return super
      .getHttp()
      .get<SaeHttpResponse<SubscriptionAuthenticationMethod>>(url)
      .pipe(
        map((r) => r.results[0]),
        catchError(() => of(null))
      );
  }
  public getSubscriptionIpAddresses(subscriptionId: string): Observable<string> {
    const url = `${this.baseUrl}/${this.resourceName}/${subscriptionId}/authenticationMethods?type=IP`;
    return super.getHttp().get(url, {
      headers: {
        Accept: 'text/csv',
      },
      responseType: 'text',
    });
  }

  exportTextFile(data: string, filename: string, fileFormat: ReportFormat): void {
    const mimeType = fileFormat == 'csv' ? 'text/csv' : 'application/json';
    const file = new Blob([data], { type: mimeType });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file);
    a.download = `${filename}.${fileFormat}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
