import { Term } from '../../shared/models/publication.model';

export enum BrowseDescription {
  Home = `Browse 40,000+ industry standards and 150,000+ peer-reviewed publications spread across journal articles, technical papers, books, SAE Edge™ Research Reports and other content categories on our industry leading platform SAE Mobilus®`,
  TechnicalPapers = `SAE Technical Papers are written and peer-reviewed by experts in the automotive, aerospace, and commercial vehicle industries and provide the latest advances in technical research and applied technical engineering information.`,
  JournalArticles = `SAE journals provide rigorously peer-reviewed, archival research by subject matter experts--basic and applied research that is valuable to both academia and industry.`,
  Books = `SAE Books explores topics critical to the mobility industry. Our internationally acclaimed authors provide critical insight into historical, current, and emerging topics.`,
  EdgeReports = `SAE Edge™ Research Reports provide state-of-the-art and state-of-industry examinations of the most significant topics facing the mobility industry today. With a dedicated focus on emerging topics in new mobility, they offer a structured framework and methodical approach for thinking about and working with rapidly shifting technologies.`,
  Standards = `SAE standards are internationally recognized as some of the most trusted collections of scientific data to globally optimize the processes, practices, and products that advance technology in our industry and are vital to ensure safety, quality, and reliability. Over 40,000+ current documents available within the aerospace, automotive, and commercial vehicle industries.`,
  Journals = `SAE journals provide rigorously peer-reviewed, archival research by subject matter experts--basic and applied research that is valuable to both academia and industry.`,
}

export enum BrowseSort {
  Relevance = 'Relevance',
  MostRecent = 'Most Recent',
  Title = 'Title',
}

export interface BrowseOption {
  list?: string;
  items: { label: string; url: string; analyticsId?: string; image?: string; fragment?: string }[];
}

export interface BrowseByConfig {
  masterPanelTitle: string;
  masterPanelBrowseCategories: BrowseOption[];
  masterPanelPreviousCategory?: { label: string; url: string };
  detailTitle: string;
  detailDescription: BrowseDescription;
  contentGroup: string;
  contentSubgroup: string;
  selectedTopic?: Term;
  sortBy: string;
  sortOptions: string[];
  seoMetaDescription: string;
}

export const DEFAULT_EXPANDED_AGGREGATIONS = [
  'assignedIndustrySectors',
  // TODO: FEATURES
  'publicationDate',
  'assignedTaxonomyTermText',
  'contentSubGroup',
];

export const DEFAULT_NUMBER_OF_VALUES = new Map<string, number>([
  // TODO: ONLY
  ['collectionTitle', 10],
  // TODO: FEATURES
  ['assignedIndustrySectors', 5],
  ['contentSubgroup', 10],
  ['publicationDate', 1],
  ['assignedTaxonomyTermText', 15],
  ['authorlFullName', 10],
  ['authorAffiliations', 10],
  ['publisherId', 10],
  ['standardCommittees', 10],
  ['searchFormats', 10],
  ['eventName', 10],
  ['documentCategory', 10],
]);

export const BROWSE_HOME_OPTS: BrowseOption[] = [
  {
    list: 'Content Types',
    items: [
      {
        label: 'Books',
        url: '/books',
        analyticsId: 'browse-books-link',
      },
      {
        label: 'Courses',
        url: '/courses',
        analyticsId: 'browse-courses-link',
      },
      {
        label: 'SAE Edge™ Research Reports',
        url: '/reports',
        analyticsId: 'browse-reports-link',
      },
      {
        label: 'Journal Articles',
        url: '/journal-articles',
        analyticsId: 'browse-journal-articles-link',
      },
      {
        label: 'Magazines',
        url: '/magazines',
        analyticsId: 'browse-magazines-link',
      },
      {
        label: 'Magazine Articles',
        url: '/magazine-articles',
        analyticsId: 'browse-analytics-link',
      },
      {
        label: 'Standards',
        url: '/standards',
        analyticsId: 'browse-standards-link',
      },
      {
        label: 'Technical Papers',
        url: '/technical-papers',
        analyticsId: 'browse-technical-papers-link',
      },
    ],
  },
  {
    list: 'Categories',
    items: [
      // {
      //   label: 'Committees',
      //   url: '/committees',
      //   analyticsId: 'browse-committees-link'
      // },
      // {
      //   label: 'Events',
      //   url: '/events',
      //   analyticsId: 'browse-events-link'
      // },
      {
        label: 'Journals',
        url: '/journals',
        analyticsId: 'browse-journals-link',
      },
      {
        label: 'Topics',
        url: '/topics',
        analyticsId: 'browse-topics-link',
      },
    ],
  },
];

export const BROWSE_STANDARDS_OPTS: BrowseOption[] = [
  {
    items: [
      {
        label: 'Characteristics',
        url: 'characteristics',
        analyticsId: 'browse-standards-characteristics-link',
      },
      {
        label: 'Information Reports',
        url: 'information-reports',
        analyticsId: 'browse-standards-information-reports-link',
      },
      {
        label: 'Material Specifications',
        url: 'material-specifications',
        analyticsId: 'browse-standards-material-specifications-link',
      },
      {
        label: 'Recommended Practices',
        url: 'recommended-practices',
        analyticsId: 'browse-standards-recommended-practices-link',
      },
      {
        label: 'Specifications',
        url: 'specifications',
        analyticsId: 'browse-specifications-link',
      },
      {
        label: 'Supplements',
        url: 'supplements',
        analyticsId: 'browse-standards-supplements-link',
      },
      {
        label: 'Technical Standards',
        url: 'technical-standards',
        analyticsId: 'browse-standards-technical-standards-link',
      },
    ],
  },
];

export const BROWSE_TRAINING_OPTS: BrowseOption[] = [
  {
    items: [
      {
        label: 'eLearning',
        url: 'elearning',
        analyticsId: 'browse-courses-elearning-link',
      },
      {
        label: 'Instructor Led',
        url: 'instructor-led',
        analyticsId: 'browse-courses-instructor-led-link',
      },
    ],
  },
];
export const BROWSE_BOOKS_OPTS: BrowseOption[] = [
  {
    items: [
      {
        label: 'Handbook',
        url: 'handbook',
        analyticsId: 'browse-books-handbook-link',
      },
      {
        label: 'Narrative',
        url: 'narrative',
        analyticsId: 'browse-books-narrative-link',
      },
      {
        label: 'Picture Book',
        url: 'picture-book',
        analyticsId: 'browse-books-picture-book-link',
      },
      {
        label: 'Reference',
        url: 'reference',
        analyticsId: 'browse-books-reference-link',
      },
      {
        label: 'Textbook',
        url: 'textbook',
        analyticsId: 'browse-books-textbook-link',
      },
    ],
  },
];

export const BrowseByHomeConfig: BrowseByConfig = {
  masterPanelBrowseCategories: BROWSE_HOME_OPTS,
  masterPanelTitle: 'Browse',
  detailTitle: 'Browse SAE Mobilus',
  detailDescription: BrowseDescription.Home,
  contentGroup: null,
  contentSubgroup: null,
  sortBy: BrowseSort.Relevance,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the content on SAE Mobilus.',
};

export const BrowseByBooksConfig: BrowseByConfig = {
  masterPanelTitle: `Books`,
  masterPanelBrowseCategories: BROWSE_BOOKS_OPTS,
  detailTitle: `Books - SAE Mobilus`,
  detailDescription: BrowseDescription.Books,
  contentGroup: 'Books',
  contentSubgroup: null,
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Books on SAE Mobilus.',
};

export const BrowseByEdgeResearchReportsConfig: BrowseByConfig = {
  masterPanelTitle: `SAE Edge™ Research Reports`,
  masterPanelBrowseCategories: [],
  detailTitle: `SAE Edge™ Research Reports - SAE Mobilus`,
  detailDescription: BrowseDescription.EdgeReports,
  contentGroup: null,
  contentSubgroup: 'Research Report',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Research Reports on SAE Mobilus.',
};

export const BrowseByJournalArticlesConfig: BrowseByConfig = {
  masterPanelTitle: 'Journal Articles',
  masterPanelBrowseCategories: [],
  detailTitle: 'Journal Articles - SAE Mobilus',
  detailDescription: BrowseDescription.JournalArticles,
  contentGroup: null,
  contentSubgroup: 'Journal Article',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Journal Articles on SAE Mobilus.',
};
export const BrowseByJournalsConfig: BrowseByConfig = {
  masterPanelTitle: 'Journals',
  masterPanelBrowseCategories: [],
  detailTitle: 'Journals',
  detailDescription: BrowseDescription.Journals,
  contentGroup: null,
  contentSubgroup: 'Journal',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Journals on SAE Mobilus.',
};

export const BrowseByMagazineArticlesConfig: BrowseByConfig = {
  masterPanelTitle: 'Magazine Articles',
  masterPanelBrowseCategories: [],
  detailTitle: 'Magazine Articles - SAE Mobilus',
  detailDescription: null,
  contentGroup: null,
  contentSubgroup: 'Magazine Article',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Magazine Articles on SAE Mobilus.',
};
export const BrowseByMagazineConfig: BrowseByConfig = {
  masterPanelTitle: 'Magazines',
  masterPanelBrowseCategories: [],
  detailTitle: 'Magazines - SAE Mobilus',
  detailDescription: null,
  contentGroup: null,
  contentSubgroup: 'Magazine',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Magazines on SAE Mobilus.',
};

export const BrowseByStandardsConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: BROWSE_STANDARDS_OPTS,
  detailTitle: 'Standards - SAE Mobilus',
  detailDescription: BrowseDescription.Standards,
  contentGroup: 'Standards',
  contentSubgroup: null,
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Standards content on SAE Mobilus.',
};

export const BrowseByTechnicalPapersConfig: BrowseByConfig = {
  masterPanelTitle: 'Technical Papers',
  masterPanelBrowseCategories: [],
  detailTitle: 'Technical Papers - SAE Mobilus',
  detailDescription: BrowseDescription.TechnicalPapers,
  contentGroup: null,
  contentSubgroup: 'Technical Paper',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Technical Papers on SAE Mobilus.',
};

// Standard Subgroups

export const BrowseByCharacteristicsConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: [],
  detailTitle: 'Characteristics - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Standards',
  contentSubgroup: 'Characteristic',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Characteristics on SAE Mobilus',
};

export const BrowseByInformationReportsConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: [],
  detailTitle: 'Information Reports - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Standards',
  contentSubgroup: 'Information Report',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Information Reports on SAE Mobilus',
};

export const BrowseByMaterialSpecificationsConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: [],
  detailTitle: 'Material Specifications - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Standards',
  contentSubgroup: 'Material Specification',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Material Specifications on SAE Mobilus',
};

export const BrowseByRecommendedPracticesConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: [],
  detailTitle: 'Recommended Practices - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Standards',
  contentSubgroup: 'Recommended Practice',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Recommended Practices on SAE Mobilus',
};

export const BrowseBySpecificationsConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: [],
  detailTitle: 'Specifications - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Standards',
  contentSubgroup: 'Specification',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Specifications on SAE Mobilus',
};

export const BrowseBySupplementsConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: [],
  detailTitle: 'Supplements - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Standards',
  contentSubgroup: 'Supplement',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Supplements on SAE Mobilus',
};

export const BrowseByTechnicalStandardsConfig: BrowseByConfig = {
  masterPanelTitle: 'Standards',
  masterPanelBrowseCategories: [],
  detailTitle: 'Technical Standards - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Standards',
  contentSubgroup: 'Technical Standard',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Technical Standards on SAE Mobilus',
};

export const BrowseByTrainingCoursesConfig: BrowseByConfig = {
  masterPanelTitle: 'Courses',
  masterPanelBrowseCategories: BROWSE_TRAINING_OPTS,
  detailTitle: 'Courses - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Courses',
  contentSubgroup: null,
  sortBy: BrowseSort.Title,
  sortOptions: [BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Courses on SAE Mobilus.',
};

export const BrowseByInstructorLedConfig: BrowseByConfig = {
  masterPanelTitle: 'Courses',
  masterPanelBrowseCategories: [],
  detailTitle: 'Instructor Led - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Courses',
  contentSubgroup: 'Instructor Led',
  sortBy: BrowseSort.Title,
  sortOptions: [BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Instructor Led Courses on SAE Mobilus.',
};

export const BrowseByElearningConfig: BrowseByConfig = {
  masterPanelTitle: 'Courses',
  masterPanelBrowseCategories: [],
  detailTitle: 'eLearning - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Courses',
  contentSubgroup: 'eLearning',
  sortBy: BrowseSort.Title,
  sortOptions: [BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the eLearning Courses on SAE Mobilus.',
};

export const BrowseByTopicsConfig: BrowseByConfig = {
  masterPanelTitle: 'Topics',
  masterPanelBrowseCategories: [],
  detailTitle: 'Browse Topics',
  detailDescription: null,
  contentGroup: null,
  contentSubgroup: null,
  selectedTopic: null,
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing the Topics on SAE Mobilus.',
};

export const BrowseByHandbookConfig: BrowseByConfig = {
  masterPanelTitle: 'Books',
  masterPanelBrowseCategories: [],
  detailTitle: 'Handbook - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Books',
  contentSubgroup: 'Handbook',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Books on SAE Mobilus',
};
export const BrowseByNarrativeConfig: BrowseByConfig = {
  masterPanelTitle: 'Books',
  masterPanelBrowseCategories: [],
  detailTitle: 'Narrative - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Books',
  contentSubgroup: 'Narrative',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Books on SAE Mobilus',
};
export const BrowseByPictureBookConfig: BrowseByConfig = {
  masterPanelTitle: 'Books',
  masterPanelBrowseCategories: [],
  detailTitle: 'Picture Book - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Books',
  contentSubgroup: 'Picture Book',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Books on SAE Mobilus',
};
export const BrowseByReferenceConfig: BrowseByConfig = {
  masterPanelTitle: 'Books',
  masterPanelBrowseCategories: [],
  detailTitle: 'Reference - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Books',
  contentSubgroup: 'Reference',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Books on SAE Mobilus',
};
export const BrowseByTextbookConfig: BrowseByConfig = {
  masterPanelTitle: 'Books',
  masterPanelBrowseCategories: [],
  detailTitle: 'Textbook - SAE Mobilus',
  detailDescription: null,
  contentGroup: 'Books',
  contentSubgroup: 'Textbook',
  sortBy: BrowseSort.MostRecent,
  sortOptions: [BrowseSort.Relevance, BrowseSort.MostRecent, BrowseSort.Title],
  seoMetaDescription: 'A page for browsing Books on SAE Mobilus',
};

export interface BrowseJournal {
  label: string;
  url: string;
  analyticsId: string;
  image?: string;
  fragment?: string;
  journalTitle?: string;
}
