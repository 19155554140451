import { DomPortal } from '@angular/cdk/portal';
import { Component, Inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DetailToolView, ViewTemplate } from '@sae/models';
import { ISessionService, SESSION_TOKEN } from '@sae/services';
import { BaseTemplateComponent } from '../base-template.component';

@Component({
  selector: 'si-detail-tool-template',
  templateUrl: './detail-tool-template.component.html',
})
export class DetailToolTemplateComponent extends BaseTemplateComponent implements OnChanges {
  @Input() projectedContent!: DetailToolView;

  detailContentPortal: DomPortal<TemplateRef<unknown>>;
  toolContentPortal: DomPortal<TemplateRef<unknown>>;

  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    super(sessionService);
  }

  ngOnChanges(s: SimpleChanges): void {
    if (s?.projectedContent?.currentValue) {
      this.detailContentPortal = new DomPortal(this.projectedContent.detailContent);
      this.toolContentPortal = new DomPortal(this.projectedContent.toolContent);
    }
  }
}

@Component({ template: '', host: { 'collision-id': 'DetailToolViewBase' } })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DetailToolViewBase implements DetailToolView {
  @ViewChild('detailContent') detailContent: TemplateRef<unknown>;
  @ViewChild('toolContent') toolContent: TemplateRef<unknown>;
  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    this.sessionService.setActiveViewTemplate(ViewTemplate.DetailTool);
  }
}