<ng-container
  *ngIf="{
    content: content$ | async,
    crossReferences: crossReferences$ | async
  } as _"
>
  <mat-accordion class="si-expanel__container si-expanel--singleton" [multi]="true">
    <mat-expansion-panel
      *ngIf="!!_.content?.citation"
      class="si-expanel si-expanel--impact mat-elevation-z"
      [expanded]="true"
      data-testid="citation-panel"
    >
      <mat-expansion-panel-header class="si-expanel__h">
        <mat-panel-title class="si-title si-impact__title">Citation</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="si-expanel__c">
        <div class="si-data si-data--grid">
          <si-data-output
            class="si-data__set si-no-dataout__h si-wd si-wd-full"
            [content]="_.content?.citation"
          ></si-data-output>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="_.content?.crossReferences?.length > 0"
      class="si-expanel si-expanel--unusual si-expanel--impact mat-elevation-z"
      expanded="true"
      data-testid="cross-references-panel"
    >
      <mat-expansion-panel-header class="si-expanel__h si-expanel__h--unusual">
        <mat-panel-title class="si-title si-impact__title">Cited by CrossRef</mat-panel-title>
        <span class="si-expanse"></span>
        <span class="si-expanel__h__tool">
          <span
            class="__dimensions_badge_embed__"
            [attr.data-doi]="_.content?.issueNumber"
            data-style="large_rectangle"
            data-hide-zero-citations="true"
            data-testid="details-badge"
          ></span>
        </span>
      </mat-expansion-panel-header>
      <div class="si-expanel__c">
        <div class="si-card__wrap si-spacious" *ngFor="let reference of _.crossReferences">
          <a
            [href]="
              reference?.external || !reference?.contentId
                ? 'https://doi.org/' + reference?.doi
                : baseUrl + '/' + reference?.contentId
            "
            [target]="reference?.external || !reference?.contentId ? '_blank' : '_self'"
            class="si-a--unusual si-button si-button--inline"
            mat-button
            data-testid="reference-link"
          >
            <mat-card class="si-card si-card--basic si-simple mat-elevation-z si-tight">
              <mat-card-header class="si-card__h">
                <mat-icon mat-card-avatar class="si-icon" color="primary">
                  {{ reference.external ? 'launch' : 'subject' }}
                </mat-icon>
                <mat-card-title class="si-card__title" [innerHTML]="reference?.citation"></mat-card-title>
              </mat-card-header>
            </mat-card>
          </a>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
