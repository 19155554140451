import {
  Component,
  ElementRef,
  HostListener,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { PartButtonComponent, ButtonItem } from '../part-button/part-button.component';
import { PartUtilListboxComponent } from '../../parts/part-util-listbox/part-util-listbox.component';
import { overrideInputs } from '@sae/base';

export interface PartUtilMenuConfig {
  ariaLabel?: string;
  tooltip?: string;
  classes?: string;
  overlapTrigger?: boolean;
  selectedIndex?: number;
  simpleTrigger?: ButtonItem;
}

@Component({
  selector: 'fs-part-util-menu',
  styleUrls: ['part-util-menu.component.scss'],
  templateUrl: './part-util-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouterModule, MatMenuModule, PartButtonComponent, PartUtilListboxComponent],
  standalone: true,
})
export class PartUtilMenuComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartUtilMenuConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() ariaLabel: string;
  @Input() tooltip = 'Options';
  @Input() classes = '';
  @Input() overlapTrigger = false;
  @Input() selectedIndex = 0;
  @Input() simpleTrigger: ButtonItem;

  @Output() menuOpened = new EventEmitter<boolean>();
  @Output() targetClicked = new EventEmitter<MouseEvent>();
  @Output() triggerFocused = new EventEmitter<void>();
  @Output() triggerBlurred = new EventEmitter<void>();

  @ViewChild('menuTriggerButton') triggerButton: ElementRef<HTMLButtonElement>;

  menuOpen = false;
  triggerClientWidth: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setMinWidth();
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(event: FocusEvent): void {
    if (event.target === this.triggerButton.nativeElement) {
      this.triggerFocused.emit();
    }
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(event: FocusEvent): void {
    if (event.target === this.triggerButton.nativeElement) {
      this.triggerBlurred.emit();
    }
  }

  setMinWidth(): void {
    this.triggerClientWidth = this.triggerButton?.nativeElement?.clientWidth;
  }

  menuStateChange(state: boolean): void {
    this.menuOpen = state;
    this.menuOpened.emit(state);
  }

  public openMenu(): void {
    this.triggerButton.nativeElement.click();
  }
}
