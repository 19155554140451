
  <div class="si-takeover">
    <header class="si-takeover__h">
      <ng-template [cdkPortalOutlet]="headerContentPortal" *ngIf="projectedContent?.headerContent"></ng-template>
    </header>
    <main class="si-takeover__c si-takeover__c--panels">
      <div class="si-columns">
        <div class="si-col si-col--detail">
          <div libDetectBreakpointWidth>
            <ng-template [cdkPortalOutlet]="detailContentPortal" *ngIf="projectedContent?.detailContent"></ng-template>
          </div>
        </div>
      </div>
    </main>
  </div>