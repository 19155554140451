<fs-part-compound *ngIf="compoundWrap; else mainBody" [config]="compoundWrap">
  <ng-container *ngTemplateOutlet="mainBody"></ng-container>
</fs-part-compound>

<ng-template #mainBody>
  <ng-container *ngFor="let c of content; let first = first; let last = last">
    <ng-container *ngIf="first">
      <ng-content select="[mixedStart]"></ng-content>
    </ng-container>

    <fs-comp-util-mixed-compounds
      *ngIf="c.button || c.divider || c.textContent || c.rowText"
      [content]="[c]"
      (buttonClick)="onButtonClick($event)"
    ></fs-comp-util-mixed-compounds>
    <fs-ui-chip-group
      *ngIf="c.chips"
      class="si-mixedcontent"
      [label]="'Options'"
      [chips]="c.chips"
      (buttonClick)="onChipClick($event)"
      (removeClick)="onRemoveChipClick($event)"
    ></fs-ui-chip-group>

    <ng-container *ngIf="last"><ng-content select="[mixedEnd]"></ng-content></ng-container>
  </ng-container>
</ng-template>
