import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DetailActions, DetailSelectors } from '../../../../../store/detail';
@Component({
  selector: 'mobi-detail-2d3d',
  templateUrl: './detail-2d3d.component.html',
})
export class Detail2D3DComponent implements OnInit {
  @Input() contentId: number;
  @Input() _2d3dUrl: string;

  isLoading$ = this.store.select(DetailSelectors.selectLoadingAssets);

  public url: SafeUrl;

  constructor(private store: Store, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this._2d3dUrl);
  }
}
