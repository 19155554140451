<div
  class="si-avatarv"
  [class.si-contains--icon]="type === 'materialIcon'"
  [class.si-contains--text]="type === 'text'"
  [class.si-scheme--fill]="scheme === 'fill'"
  [class.si-statev--caution]="state === 'caution'"
  [class.si-statev--dimmed]="state === 'dimmed'"
  [class.si-statev--negative]="state === 'negative'"
  [class.si-statev--positive]="state === 'positive'"
>
  <fs-part-icon *ngIf="type === 'materialIcon'" class="si-avatarv__icon">
    {{ src }}
  </fs-part-icon>
  <span *ngIf="type === 'text'" class="si-avatarv__text">
    {{ src }}
  </span>
</div>
