<mat-card
  class="si-card si-card--dataviewer"
  [class.mat-elevation-z]="disableShadow"
  [class.mat-elevation-z2]="!disableShadow"
  [class.si-state--chiplisted]="filterList.length"
  [class.si-tight]="tight"
  [class.si-tight--margin]="noMargin"
>
  <ng-container *ngIf="showHeader">
    <mat-card-header class="si-card__h si-card--dataviewer__h">
      <mat-card-title class="si-card__title si-impact">
        {{ dataTitle }}
        <span *ngIf="showTotal">
          <span class="si-count" *ngIf="!hideCount && total && !noResults && !loading">
            ({{ total | number: '1.0':'en-US' }})
          </span>
        </span>
      </mat-card-title>
      <span class="si-expanse"></span>
      <span *ngIf="altFilterOptions; else normalFilterOptions">
        <button
          mat-icon-button
          [matMenuTriggerFor]="altOptionMenus"
          class="si-icon si-icon--more"
          color="primary"
          matTooltip="Options"
          aria-label="Options"
        >
          <mat-icon>filter_list</mat-icon>
        </button>
        <mat-menu #altOptionMenus="matMenu" class="si-menu">
          <ng-container *ngFor="let option of altOptions">
            <button mat-menu-item class="si-button--detailed" (click)="optionSelected(option.name)">
              <mat-icon class="si-tool-icon" color="primary" [ngStyle]="{ color: option.iconColor }">
                {{ option.icon }}
              </mat-icon>
              <span>{{ option.name }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </span>
      <ng-template #normalFilterOptions>
        <si-regular-filter
          *ngIf="filterOptions.length"
          [options]="filterOptions"
          [dividerIndexes]="filterDividers"
          [filterListBy]="filterList"
          (filterSelected)="filterChange($event)"
          [attr.data-analytics]="analyticsIdSearchFilter"
        ></si-regular-filter>
      </ng-template>
      <si-search-input
        *ngIf="search"
        class="si-searchbox__wrap"
        type="Data"
        (changed)="changeSearch($event)"
        [toolTipText]="searchInputSuggestionTooltip"
        [hideTooltTip]="hideSearchBoxTooltip"
        [debounceTime]="searchDebounceTime"
        [defaultSearch]="defaultSearch"
        [attr.data-analytics]="analyticsIdSearchBox"
      ></si-search-input>
      <button
        mat-icon-button
        *ngIf="moreOptions.length"
        [matMenuTriggerFor]="optionsMore"
        class="si-icon si-icon--more"
        color="primary"
        matTooltip="Options"
        aria-label="Options"
        aria-hidden="true"
        [attr.data-analytics]="analyticsIdSearchOptions"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="infoButton"
        class="si-icon"
        color="primary"
        matTooltip="Info"
        aria-label="Info"
        aria-hidden="true"
        (click)="infoButtonClick()"
      >
        <mat-icon>info</mat-icon>
      </button>
      <button
        *ngIf="auxButton"
        mat-icon-button
        class="si-icon"
        [matTooltip]="auxButtonLabel"
        [attr.aria-label]="auxButtonLabel"
        (click)="auxButtonClick()"
      >
        <mat-icon aria-hidden="true">{{ auxButtonIcon }}</mat-icon>
      </button>
      <mat-menu #optionsMore="matMenu" class="si-menu">
        <ng-container *ngFor="let option of moreOptions">
          <button mat-menu-item class="si-button--detailed" (click)="optionSelected(option.name)">
            <mat-icon class="si-tool-icon" color="primary">{{ option.icon }}</mat-icon>
            <span>{{ option.name }}</span>
          </button>
          <mat-divider class="si-divider" *ngIf="option.divider"></mat-divider>
        </ng-container>
      </mat-menu>
      <ng-container *ngIf="filterOptionsOverride === false">
        <mat-chip-list aria-label="Filters" class="si-chiplist si-chiplist--filter" *ngIf="filterList.length">
          <mat-chip class="si-chip si-chip--minor si-chip--long" *ngFor="let chip of filterList">
            <span>{{ chip }}</span>
            <mat-icon matChipRemove matTooltip="Remove" (click)="filterChange(chip)">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </ng-container>
    </mat-card-header>
    <div class="si-dataviewer__subheader">
      <ng-content select="[subheader]"></ng-content>
    </div>
  </ng-container>
  <mat-card-content class="si-card__c">
    <div *ngIf="loading" class="si-spinner__container">
      <mat-spinner diameter="30" class="si-spinner"></mat-spinner>
    </div>
    <div *ngIf="!loading" class="si-card--dataviewer__data" [class.si-no-action]="!paginator">
      <si-none-card *ngIf="!!noResults" class="si-component" [content]="noneText"></si-none-card>
      <ng-container *ngIf="!noResults">
        <div class="si-card--dataviewer__data__h" *ngIf="singleSortMenu">
          <ng-container *ngIf="filterNavActive">
            <div class="si-card--dataviewer__navigation">
              <si-filter-chip-navigation
                [chipText]="filterNavActive"
                (chipClicked)="onFilterChipNavChipClicked()"
                (cancelClicked)="onFilterChipNavCancelCancelClicked()"
                (backClicked)="onFilterChipNavBackClicked()"
                (nextClicked)="onFilterChipNavNextClicked()"
              ></si-filter-chip-navigation>
            </div>
          </ng-container>
          <div class="si-sort" [class.si-unusual]="multiselect">
            <si-sort-menu
              [sortBy]="sortBy"
              [sortDir]="sortDir"
              [options]="sortOptions"
              (sortChanged)="changeSort($event)"
            ></si-sort-menu>
          </div>
        </div>
        <ng-content></ng-content>
      </ng-container>
    </div>
    <div class="si-actions-row si-actions-row--add" *ngIf="!hidePaginator">
      <ng-container *ngIf="useSeoPaginator">
        <si-seo-paginator
          class="si-component si-paginator"
          [baseURL]="seoPaginatorBaseURL"
          [hashURL]="seoPaginatorHashURL"
          [length]="total"
          [pageSize]="pageSize"
          [pageIndex]="pageNumber"
          [pageSizeOptions]="pageSizeOptions"
          [pageSizeAllOption]="pageSizeAllOption"
          (page)="changePage($event)"
        ></si-seo-paginator>
      </ng-container>
      <ng-container *ngIf="!useSeoPaginator">
        <div class="si-actions-row__a" *ngIf="isAddButton">
          <button
            mat-icon-button
            class="si-button si-state--unusual si-button--icon"
            [matTooltip]="addButtonToolTip"
            [attr.aria-label]="addButtonToolTip"
            (click)="addButtonClick()"
          >
            <mat-icon aria-hidden="true">add_circle</mat-icon>
          </button>
        </div>
        <span class="si-expanse"></span>
        <div class="si-card--dataviewer__data__a" *ngIf="paginator && !noResults && !loading">
          <mat-paginator
            [length]="total"
            [pageSize]="pageSize"
            [pageIndex]="pageNumber"
            [pageSizeOptions]="pageSizeOptions"
            (page)="changePage($event)"
            class="si-paginator"
          ></mat-paginator>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
