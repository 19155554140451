import { InjectionToken } from '@angular/core';
export const StoresToken = new InjectionToken('Stores');
export const ApiToken = new InjectionToken('Api');

export interface ApiEntry {
  url: string;
  minor_version: number;
  patch_version: number;
  docs_link: string;
}
interface ApiRegistryCustomEntry {
  [key: string]: ApiEntry;
}

interface KnownApiRegistryEntries {
  alerts?: ApiEntry;
  authentication?: ApiEntry;
  documents?: ApiEntry;
  documentCategories?: ApiEntry;
  ballots?: ApiEntry;
  comments?: ApiEntry;
  committees?: ApiEntry;
  events?: ApiEntry;
  file?: ApiEntry;
  messaging?: ApiEntry;
  notifications?: ApiEntry; // Notifications api entry must be present on app-side if it uses app-component / toolbar etc.
  polls?: ApiEntry;
  resources?: ApiEntry;
  roleMapping?: ApiEntry;
  task?: ApiEntry;
  groups?: ApiEntry;
  users?: ApiEntry;
  surveyVersions?: ApiEntry;
  surveyResponse?: ApiEntry;
  surveys?: ApiEntry;
}

export type ApiRegistry = KnownApiRegistryEntries & ApiRegistryCustomEntry;

interface CustomStoreRegistryEntries {
  [key: string]: string;
}

interface KnownStoreRegistryEntries {
  remoteConfig: string;
  featureFlags: string;
  auth: string;
  session: string;
  comments?: string;
  resources?: string;
  notifications?: string; // which is actually the "messaging" api
  users?: string;
  userSettings?: string;
  file?: string;
  search?: string;
  collectionsSearch?: string;
  surveyUI?: string;
  surveyVersions?: string;
  surveyResponse?: string;
  surveys?: string;
}
export type StoresRegistry = KnownStoreRegistryEntries & CustomStoreRegistryEntries;
