<fs-part-util-listbox
  *ngIf="chips && label"
  childRole="option"
  [label]="label"
  [orientation]="'horizontal'"
  [role]="'listbox'"
>
  <div class="si-chip-group">
    <ng-content></ng-content>

    <ng-container *ngFor="let chip of chips">
      <ng-container *ngIf="!chip.attachChip; else comboChips">
        <ng-container *ngTemplateOutlet="chipItem; context: { chip: chip }"></ng-container>
      </ng-container>
      <ng-template #comboChips>
        <div class="si-combo">
          <ng-container *ngTemplateOutlet="chipItem; context: { chip: chip }"></ng-container>
          <ng-container *ngTemplateOutlet="chipItem; context: { chip: chip.attachChip }"></ng-container>
        </div>
      </ng-template>
    </ng-container>
  </div>
</fs-part-util-listbox>

<ng-template #chipItem let-chip="chip">
  <fs-comp-chip
    [buttonId]="chip.buttonId ?? ''"
    [inList]="true"
    [label]="chip.label ?? ''"
    [selected]="chip.selected ?? false"
    [size]="chip.size ?? 'normal'"
    [tooltip]="chip.tooltip ?? ''"
    [type]="chip.type"
    [url]="chip.url ?? null"
    [removeable]="chip.removeable ?? false"
    [removeIcon]="chip.removeIcon ?? 'cancel'"
    [removeTooltip]="chip.removeTooltip ?? 'Remove'"
    [removeUrl]="chip.removeUrl ?? ''"
    (removeClick)="onRemoveClick(chip)"
    (buttonClick)="onButtonClick(chip)"
  ></fs-comp-chip>
</ng-template>
