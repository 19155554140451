import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubscriptionSession, SubscriptionsService } from '../../state/subscriptions.service';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { catchError, pipe, throwError } from 'rxjs';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  templateUrl: './request-access-dialog.component.html',
})
export class RequestAccessDialogComponent {
  public requestAccessSubName: string;
  public requestSubmitted = false;
  public requestAccessForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    justification: new FormControl('', Validators.required),
  });

  constructor(
    private subService: SubscriptionsService,
    private snackbar: MatLegacySnackBar,
    private dialogRef: MatDialogRef<RequestAccessDialogComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: { title?: string; code?: string; subSessions: SubscriptionSession[]; itemUrl: string }
  ) {
    this.requestAccessSubName = data.subSessions.find((s) =>
      s.features.some((f) => f.name === 'request-content' && !!f.enabled)
    )?.name;
  }

  public submitRequestAccessForm(): void {
    this.requestSubmitted = true;
    const subId = this.data.subSessions.find((s) =>
      s.features.some((f) => f.name === 'request-content' && !!f.enabled)
    )?.id;
    const formData = this.requestAccessForm.getRawValue();
    this.subService
      .requestAccess(subId, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        emailAddress: formData.email,
        justification: formData.justification,
        productTitle: this.data.title,
        productCode: this.data.code,
        itemUrl: this.data.itemUrl,
      })
      .pipe(
        catchError((err) => {
          if (err.status === 500) {
            this.snackbar.open(
              'There was a problem processing your request. Please contact your administrator to request this content.',
              undefined,
              { duration: 4000 }
            );
          }
          this.requestSubmitted = false;
          return throwError(() => err);
        })
      )
      .subscribe(() => {
        this.snackbar.open('Request for access has been submitted', undefined, { duration: 4000 });
        this.dialogRef.close();
      });
  }
}
