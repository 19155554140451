import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../feature-keys';
import { AuthState } from './auth.state';


export const AuthFeature = createFeatureSelector<AuthState>(FeatureKeys.Auth);


export const selectLogInError = createSelector(AuthFeature, (state) => state.logInError);
export const selectLoadingLogIn = createSelector(AuthFeature, (state) => state.loadingLogIn);
