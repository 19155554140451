<ng-container [formGroup]="getCriteriaGroupAsFormGroup()">
  <div
    class="si-fieldset si-t--group"
    [class.si-child]="level === 1"
    [class.si-gchild]="level === 2"
    [class.si-ggchild]="level === 3"
    [class.si-gggchild]="level === 4"
    [class.si-ggggchild]="level === 5"
    [class.si-gggggchild]="level === 6"
    [class.si-ggggggchild]="level > 6"
    [class.si-state--alt]="showAltClass"
  >
    <div class="si-fieldset__c">
      <div class="si-control si-wd-control si-visible--beyond-mobile" *ngIf="level > 0">
        <button
          type="button"
          mat-icon-button
          class="si-button si-button--icon si-button--delete"
          title="Remove Group"
          (click)="onGroupRemoved()"
        >
          <mat-icon aria-hidden="true">remove_circle</mat-icon>
        </button>
      </div>
      <mat-form-field class="si-field si-wd-small">
        <mat-select class="si-select" aria-label="Condition" formControlName="condition" libSetAutofocus>
          <mat-option value="all">All</mat-option>
          <mat-option value="any">Any</mat-option>
        </mat-select>
      </mat-form-field>
      <span>of the following are true</span>
    </div>
    <button
      type="button"
      mat-icon-button
      [matMenuTriggerFor]="optsMore"
      class="si-icon si-icon--more si-visible--only-mobile"
      color="primary"
      title="Options"
      *ngIf="level > 0"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #optsMore="matMenu" class="si-menu">
      <button type="button" mat-menu-item class="si-button--detailed" (click)="onGroupRemoved()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">remove_circle</mat-icon>
        <span>Remove Group</span>
      </button>
    </mat-menu>
  </div>

  <ng-container formArrayName="rows">
    <ng-container *ngFor="let r of rows.controls; let i = index">
      <si-criteria-row-container
        [row]="r"
        [level]="level"
        [facets]="facets"
        [indexNum]="i"
        [showButton]="showButton"
        [altClass]="getAltClassLogic(i)"
        [advSearchConditionConfig]="advSearchConditionConfig"
        [autocompleteKeyword]="autocompleteKeyword"
        (rowAdded)="addRow($event)"
        (rowRemoved)="removeRow($event)"
        (groupAdded)="onGroupAdded()"
      ></si-criteria-row-container>
    </ng-container>
  </ng-container>
</ng-container>
