import { Criteria } from '../../modules/search/components/advanced-search/advanced-search.component';
import { MobilusPubDocument } from '../../shared/models/api-models';
import {
  CommonFacets,
  CommonFacetsDefaultValues,
  TableParams,
  UISearchFacet,
  UISearchFilters,
} from '../../shared/models/common-interfaces';
import { CriteriaDetails } from '../../shared/models/search-result.model';

export interface SearchState extends TableParams {
  results: MobilusPubDocument[];
  filters: UISearchFilters;
  resultsLoading: boolean;
  panelInitialized: boolean;
  resultsError: unknown;
  searchSuggestions: string[];
  documentSuggestions: MobilusPubDocument[];
  scrollTop: number;
  sortOptions: string[];
  facets: CommonFacets;
  preservedFacet: UISearchFacet<unknown>;
  pubDateFrom: Date;
  pubDateTo: Date;
  isPubDateApplied: boolean;
  customFilters: UISearchFilters;
  searchDetail: CriteriaDetails;
  advancedSearchEnabled: boolean;
  advancedSearchCriteria: Criteria[];
}

export const initialSearchState: SearchState = {
  results: [],
  filters: {},
  customFilters: {},
  facets: CommonFacetsDefaultValues,
  preservedFacet: undefined,
  resultsLoading: false,
  panelInitialized: false,
  resultsError: null,
  searchSuggestions: [],
  documentSuggestions: [],
  sortOptions: ['Relevance', 'Most Recent', 'Title'],
  searchTerm: '',
  scrollTop: 0,
  pageNumber: 1,
  pageSize: 50,
  sortBy: 'Relevance',
  sortDir: 'desc',
  total: 0,
  isPubDateApplied: false,
  pubDateFrom: new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
  pubDateTo: new Date(),
  searchDetail: null,
  advancedSearchEnabled: false,
  advancedSearchCriteria: [
    {
      type: 'GROUP',
      level: 0,
      value: 'Any',
    },
    {
      type: 'ROW',
      level: 1,
      field: 'Full Text',
      conditional: 'contains',
      value: '',
    },
  ],
};
