/**
 * Overrides the properties of an object with the properties of another object.
 * @param target - The object to be updated.
 * @param source - The object to be copied.
 * @param options - Options for the override.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function overrideInputs(target: any, source: any, options?: OverrideInputsOptions): void {
  if (target && source) {
    for (const key in source) {
      switch (options?.mode) {
        case 'whitelist': {
          if (options.list?.includes(key)) {
            target[key] = source[key];
          }
          break;
        }
        case 'blacklist': {
          if (!options.list?.includes(key)) {
            target[key] = source[key];
          }
          break;
        }
        default: {
          target[key] = source[key];
          break;
        }
      }
    }
  }
}

/**
 * Options for the overrideInputs function.
 * @param mode - Optional non default operating mode of the override.
 * @param list - The list of properties to be used in conjunction with the mode.
 */
export interface OverrideInputsOptions {
  mode?: 'whitelist' | 'blacklist' | null;
  list?: string[];
}
