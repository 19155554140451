import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../../../../shared/state/content.service';
import { DetailsHelperService } from '../../../services/details-helper.service';
import { EnhancedTreeFlatNode, EnhancedTreeNode } from '@sae/components';
import { ContentView } from '../../../models/content.model';

@Component({
  selector: 'mobi-journal-panel',
  templateUrl: './journal-panel.component.html',
})
export class JournalPanelComponent implements OnInit {
  @ViewChild('template', { static: true }) template;
  public content$ = this.contentService.content$;
  public categoryNodes$ = this.contentService.journalBrowseCategoryNodes$;
  public searchTerm: string;

  constructor(
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private contentService: ContentService,
    private router: Router,
    private detailsHelperService: DetailsHelperService
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.contentService.journalBrowseSearchTerm$.subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
  }

  onPubVolumeClicked(event: EnhancedTreeFlatNode, categoryNodes: EnhancedTreeNode[], content: ContentView): void {
    let pubVolumeQueryParam = '';

    this.contentService.setJournalBrowseCategory('NONE');
    if (event.name === 'e-First Articles' || event.name === 'Free Articles' || event.name === 'Open Access Articles') {
      if (event.name === 'e-First Articles') {
        pubVolumeQueryParam = 'volume=e-first-articles';
        this.contentService.setJournalBrowseCategory('E-FIRST');
      } else if (event.name === 'Free Articles') {
        pubVolumeQueryParam = 'volume=free-articles';
        this.contentService.setJournalBrowseCategory('FREE');
      } else if (event.name === 'Open Access Articles') {
        pubVolumeQueryParam = 'volume=open-access-articles';
        this.contentService.setJournalBrowseCategory('OPEN ACCESS');
      }
      this.contentService.getJournalBrowseResults().subscribe();
    } else if (event.name.includes('Volume')) {
      const selectedVolume = categoryNodes.find((node) => node.id === event.id);
      const selectedVolumeNumber = selectedVolume['volume'];
      JournalCategoryMetadata['VOLUME ISSUE'] = {
        breadcrumbs: [
          { title: 'All', buttonMode: true },
          { title: selectedVolume.name, id: `volume${selectedVolumeNumber}` },
        ],
      };
      pubVolumeQueryParam = `volume=${selectedVolumeNumber}`;
      this.contentService.setJournalBrowseCategory('VOLUME ISSUE');
      this.contentService.getJournalBrowseResults(selectedVolumeNumber).subscribe();
    } else if (event.name.includes('Issue')) {
      let selectedIssueNumber: number;
      let selectedIssueNode;
      const selectedVolume = categoryNodes.find((node) => {
        if (node?.children) {
          selectedIssueNode = node?.children.find((issue) => issue.id === event.id);
          if (selectedIssueNode) {
            selectedIssueNumber = selectedIssueNode['issue'];
          }
          return !!selectedIssueNumber;
        }
        return false;
      });
      const selectedVolumeNumber = selectedVolume['volume'];
      JournalCategoryMetadata['VOLUME ISSUE'] = {
        breadcrumbs: [
          { title: 'All', buttonMode: true },
          {
            title: selectedVolume.name,
            buttonMode: true,
            volume: selectedVolumeNumber,
            id: `volume${selectedVolumeNumber}`,
          },
          { title: selectedIssueNode.name, id: `volume${selectedVolumeNumber}issue${selectedIssueNumber}` },
        ],
      };
      pubVolumeQueryParam = `volume=${selectedVolumeNumber}&issue=${selectedIssueNumber}`;
      this.contentService.setJournalBrowseCategory('VOLUME ISSUE');
      this.contentService.getJournalBrowseResults(selectedVolumeNumber, selectedIssueNumber).subscribe();
    }

    const urlWithoutHash = this.router.url.split('#')[0];
    this.router.navigate([urlWithoutHash], { replaceUrl: true, fragment: `browse?${pubVolumeQueryParam}` });
  }
}
export const JournalCategoryMetadata = {
  'E-FIRST': {
    name: 'e-First Articles',
    content: `<p>e-First are Journal articles that are made available in advance of the final published version.
                 e-First articles have followed strict SAE International guidelines regarding authoring and peer review but are not in final form;
                 therefore, the e-First articles are offered for early reading and citation purposes.</p>`,
    breadcrumbs: [{ title: 'All', buttonMode: true }, { title: 'e-First Articles' }],
  },
  FREE: {
    name: 'Free Articles',
    content: `<p>Articles from this journal will be made available for free for a limited time.
                  Free articles will be provided with full access, including download, print, and share.</p>
              <p>Please check back on a regular basis.</p>`,
    breadcrumbs: [{ title: 'All', buttonMode: true }, { title: 'Free Articles' }],
  },
  'OPEN ACCESS': {
    name: 'Open Access Articles',
    content: `<div>
                <img src="https://static-content.sae.org/icons/open_access.svg" alt="Open Access" class="si-for--right"/>
                <p>Open Access articles are permanently free for everyone to read and download.
                    SAE International offers authors a choice of user licenses (indicating the type of license on the first page of the article),
                    which define the permitted reuse of articles, according to the following options:
                </p>
              </div>
              <ul>
                <li>Creative Commons Attribution (CC BY): Allows users to copy, distribute, transmit, and adapt the article as long as the author is attributed. CC BY permits commercial and non-commercial use.</li>
                <li>Creative Commons Attribution-NonCommercial (CC BY-NC): Allows users to copy, distribute, and transmit the article as long as the author is attributed. CC BY-NC allows only non-commercial use.</li>
                <li>Creative Commons Attribution-NonCommercial-NoDerivatives (CC BY-NC-ND): Allows users to copy, distribute, and transmit an article as long as the author is attributed, the article is not used for commercial purposes, and the work is not modified or adapted in any way.</li>
              </ul>`,
    breadcrumbs: [{ title: 'All', buttonMode: true }, { title: 'Open Access Articles' }],
  },
};
