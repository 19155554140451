import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { APP_ONQUE, NotificationAction, SaeNotification } from '@sae/models';
import { INotificationsService } from '@sae/services';
import { ConfirmDialogComponent, ConfirmDialogData } from '@sae/components';

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  isThisYear,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns';


@Injectable({ providedIn: 'root' })
export class NotificationsHelperService {
  extractUrlFromEmailBody(notif: SaeNotification): string {
    const urls = notif.body.html.match(/https?:\/\/[^\s<>"]*/g);
    return urls ? urls[0] : '';
  }

  handleNotificationAction(
    service: INotificationsService,
    matDialog: MatDialog,
    action: NotificationAction,
    notification: SaeNotification
  ): void {
    switch (action) {
      case NotificationAction.MarkRead: {
        service.markRead(notification.id);
        break;
      }
      case NotificationAction.MarkUnread: {
        service.markUnread(notification.id);
        break;
      }
      case NotificationAction.Delete: {
        const data: ConfirmDialogData = {
          message: 'This will remove selected notification.',
          yesButtonText: 'Delete',
          noButtonText: 'Cancel',
        };
        const dialog = matDialog.open<ConfirmDialogComponent>(ConfirmDialogComponent, {
          data,
        });

        dialog.componentInstance.yesClicked.subscribe(() => {
          service.remove(notification.id);
        });
        break;
      }
      case NotificationAction.Delete_Unsubscribe: {
        const dialog = matDialog.open<ConfirmDialogComponent>(ConfirmDialogComponent, {
          data: {
            message: 'This will delete notification and remove an alert from future notifications.',
            yesButtonText: 'Delete and Unsubscribe',
            noButtonText: 'Cancel',
          },
        });

        dialog.componentInstance.yesClicked.subscribe(() => {
          service.removeAlertAndDelete(notification.id);
        });
        break;
      }
      case NotificationAction.Access: {
        service.markRead(notification.id);
        const extractedUrlData = this.extractUrlFromEmailBody(notification);
        if (extractedUrlData) {
          const accessUrl = extractedUrlData.replace(/amp;/g, '');
          window.location.href = accessUrl;
        }
      }
    }
  }

  handleToolbarAction(service: INotificationsService, matDialog: MatDialog, action: string): void {
    switch (action) {
      case 'readSelected': {
        service.markAllSelectedAsRead();
        break;
      }
      case 'unreadSelected': {
        service.markAllSelectedAsUnread();
        break;
      }
      case 'deleteSelected': {
        const dialog = matDialog.open<ConfirmDialogComponent>(ConfirmDialogComponent, {
          data: {
            message: 'This will delete the selected notifications.',
            yesButtonText: 'Delete',
            noButtonText: 'Cancel',
          },
        });

        dialog.componentInstance.yesClicked.subscribe(() => {
          service.removeSelected();
        });
        break;
      }
      case 'deleteAndUsubscribeSelected': {
        const dialog = matDialog.open<ConfirmDialogComponent>(ConfirmDialogComponent, {
          data: {
            message:
              'This will delete all selected notifications and remove all alerts from future notifications that are selected.',
            yesButtonText: 'Delete and Unsubscribe',
            noButtonText: 'Cancel',
          },
        });

        dialog.componentInstance.yesClicked.subscribe(() => {
          service.removeAlertAndDeleteSelected();
        });
        break;
      }
    }
  }

  getDate(notification: SaeNotification): string {
    return format(new Date(notification.createdDate), 'MM/dd/yyyy hh:mm a');
  }

  getTimeAgo(notification: SaeNotification): string {
    const createdDate = parseISO(notification.createdDate.toString());
    if (!isThisYear(createdDate)) {
      return format(createdDate, 'MM/dd/yyyy');
    }

    if (isYesterday(createdDate)) {
      return 'Yesterday';
    }

    const now = new Date();
    if (isToday(createdDate)) {
      let minutes = differenceInMinutes(now, createdDate);
      if (minutes < 40) {
        minutes = minutes < 1 ? 1 : minutes;
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      }

      let hours = differenceInHours(now, createdDate);
      if (hours < 24) {
        hours = hours < 1 ? 1 : hours;
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      }
    }

    const days = differenceInDays(now, createdDate);
    if (days < 4) {
      return `${days} days ago`;
    }

    return format(createdDate, 'MMM d');
  }

  isAlert(notification: SaeNotification): boolean {
    return notification.tags ? notification.tags.indexOf('alert') > -1 : false;
  }

  getIcon(notification: SaeNotification): string {
    if (this.isAlert(notification) && notification.applicationCode === APP_ONQUE) {
      return 'compare_arrows';
    }

    return 'read_more';
  }
}
