import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UrlParametersService } from '../services/url-parameters.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

export interface DynamicHashOptions {
  hash: string; // Represents the full hash property value to use, including both left and right hand side if using an assignment (ie: "overview" | "tab=overview"), note the use of assignment properties is preferred for a greater level of uniqueness
  url?: string | null; // Optional, null by default, if provided this will be used as the base url to prefix the hash property to, otherwise the current url will be used, if anything other than the current url other hashes in affect on the current location will not be sent to this route
  routerLink?: boolean | null; // Optional, if true attaches a click handler to trap the click event and use the router to navigate instead of the element's innate [href] or [routerLink]
  noHashIfAlone?: boolean; // Optional, false by default, if true if the hash property is the only hash in effect for the url this will cause the link to remove any hash altogether
  setElementHref?: boolean; // Optional, true by default, when true any changes to the location should also dynamically update the element's href property, keeping its own hash intact but respecting changes to other hashes
  updateLocationOnClick?: boolean; // Optional, false by default, when true clicks on the hose element will invoke a window.location.hash change to update the url with the hash in question and respecting any other hashes on the page
  multiValues?: boolean; // Optional, false by default, when true causes the directive to merge the hash value with any existing hash values for the same hash name in a delimiter-separated list
  toggleValue?: boolean; // Optional, false by default, when true causes the directive to toggle the hash value on and off
  clearHash?: string[]; // Optional, if specified, the directive will remove these hash groups from the url
}

@UntilDestroy()
@Directive({
  selector: '[siDynamicHash]',
  standalone: true,
})
export class DynamicHashDirective implements OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private readonly urlParamsService: UrlParametersService
  ) {}

  @Input() set siDynamicHash(options: DynamicHashOptions | string) {
    if (typeof options !== 'string') {
      this._options = options;
    }
  }

  private _options: DynamicHashOptions;
  private _href: string;
  private _baseUrl: string;
  private _clickEventUnlisten: () => void;

  @HostListener('click') onClick(): void {
    if (this._options.updateLocationOnClick) {
      this.urlParamsService.setHash(this._href.split('#')[1]);
    }
  }

  ngOnInit(): void {
    combineLatest({
      baseUrl: this.urlParamsService.baseUrl$,
      hash: this.urlParamsService.hash$,
    })
      .pipe(untilDestroyed(this))
      .subscribe((urlData) => {
        this.update(urlData.baseUrl);
      });
  }

  ngOnDestroy(): void {
    if (this._clickEventUnlisten) {
      this._clickEventUnlisten();
    }
  }

  private update(baseUrl: string): void {
    this._baseUrl = baseUrl; // we will reference this in our click bindings so they can use the latest value when click occurs
    if (this._options.url && this._options.url !== this._baseUrl && this._options.routerLink) {
      this.applyBindingsForOffPageLink();
      // the link is to a different page, we don't need to worry about preserving the current hash params
      this.setHrefAttr(`${this._options.url}${this._options.hash ? '#' + this._options.hash : ''}`);
    } else {
      // the link is to update the hash on the current page, so ask the service for hash url
      const hashUrl = this.urlParamsService.getNewHashUrl(this._options.hash, {
        multiValues: this._options?.multiValues ?? false,
        toggleValue: this._options?.toggleValue ?? false,
        clearHash: this._options?.clearHash ?? [],
      });

      this.applyBindingsForSamePageLink(hashUrl);
      this.setHrefAttr(hashUrl);
    }
  }

  private applyBindingsForOffPageLink(): void {
    this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      event.preventDefault();
      // Out of process navigation to the new url to enable other click events on the native element to trigger
      setTimeout(() => {
        this.router.navigate([this._options.url], { fragment: this._options.hash });
      });
    });
  }

  private applyBindingsForSamePageLink(hashUrl: string): void {
    // Provided the option is set, if this is the only hash that would be active then use only the base url
    if (this._options.noHashIfAlone) {
      if (hashUrl.split('#')[1] === this._options.hash) {
        // Bind a click event (if one isn't already bound) to stop propagation and use the router instead to avoid the href reload the spa
        if (!this._clickEventUnlisten) {
          this._clickEventUnlisten = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
            event.preventDefault();
            // Out of process navigation to the url to enable other click events on the native element to trigger
            setTimeout(() => {
              this.router.navigate([this._baseUrl], {
                queryParamsHandling: 'preserve',
              });
            });
          });
        }
      } else if (this._clickEventUnlisten) {
        this._clickEventUnlisten();
        this._clickEventUnlisten = null;
      }
    } else {
      if (this._options.routerLink && !this._clickEventUnlisten) {
        this._clickEventUnlisten = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
          event.preventDefault();
          // Out of process navigation to the url to enable other click events on the native element to trigger
          setTimeout(() => {
            this.router.navigate([this._options.url], {
              fragment: this._href.split('#')[1],
              queryParamsHandling: 'preserve',
            });
          });
        });
      }
    }
  }

  private setHrefAttr(href: string): void {
    // Apply the computed url to the href property (unless we specifically suppressed the option to do this)
    if (this._options.setElementHref !== false) {
      // Out of progress execution to ensure the href is set after the current digest cycle
      setTimeout(() => {
        this.elementRef.nativeElement.href = href;
      });
    }
    // Set the local href to the computed url for other operations
    setTimeout(() => {
      this._href = href;
    });
  }
}
