import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MasterDetailViewBase } from '@sae/features';
import { tap } from 'rxjs';
import { ClassificationUiService } from '../../shared/services/classification-ui.service';
import { FragmentService } from '../../shared/services/fragment.service';
import { SeoUtilityService } from '../../shared/services/seo-utility.service';
import { ContentService } from '../../shared/state/content.service';
import { SessionService } from '../../shared/state/session.service';
import { BrowseActions } from '../../store/browse';
import { DownloadDocumentActions } from '../../store/download-document';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy()
@Component({
  templateUrl: './browse-by.component.html',
})
export class BrowseByComponent extends MasterDetailViewBase implements OnInit {
  private prevUrl: string;

  constructor(
    protected sessionService: SessionService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private fragmentService: FragmentService,
    private actions$: Actions,
    private contentService: ContentService,
    private classificationService: ClassificationUiService,
    private seoUtilityService: SeoUtilityService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super(sessionService);
    const { config } = route.snapshot.data;
    let preserve = false;
    if (isPlatformBrowser(this.platformId)) {
      preserve = localStorage.getItem('preserveSearchResults') === 'true' ?? false;
      localStorage.removeItem('preserveSearchResults');
    }
    const fragment = router.getCurrentNavigation()?.finalUrl?.fragment;
    const fragmentData = this.fragmentService.fragmentToFilters(fragment);

    const context = router.getCurrentNavigation().extras?.state?.context ?? 'search';

    this.route.url.subscribe((urls) => {
      if (router.url.includes('/topics')) {
        if (urls.length === 0) {
          this.store.dispatch(BrowseActions.getTopics({ depth: 1, config, preserve, fragmentData }));
        } else {
          this.store.dispatch(
            BrowseActions.getSpecificTopics({
              uriText: `topics/${urls.map((url) => url.path).join('/')}`,
              config,
              preserve,
              fragmentData,
            })
          );
        }
      } else if (router.url.includes('journals')) {
        this.store.dispatch(BrowseActions.getJournals({ config, preserve, fragmentData }));
      } else {
        if (this.prevUrl !== this.router.url?.split('#')[0]) {
          this.store.dispatch(BrowseActions.setBrowseConfig({ config, preserve, fragmentData, context }));
        }
      }
      this.prevUrl = this.router.url?.split('#')[0];
    });
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        untilDestroyed(this),
        ofType(DownloadDocumentActions.verifyAccess),
        tap(() => this.store.dispatch(BrowseActions.search({ context: 'search' })))
      )
      .subscribe();
    this.contentService.clearReturnUrl();
    this.sessionService.setShowMenu(true);
  }
}
