import { createAction, props } from '@ngrx/store';
import { Invitation, Resource, UserFolderAccess } from '../../shared/models/api-models';

export const getFolders = createAction('[My Library] Get Folders', props<{ folderUrl?: string }>());
export const getFoldersSucceeded = createAction('[My Library] Get Folders Succeeded', props<{ folders: Resource[] }>());
export const getFoldersFailed = createAction('[My Library] Get Folders Failed', props<{ error }>());

export const getFolderItems = createAction(
  '[My Library] Get Folder Items',
  props<{
    folderUrl: string;
    sortBy?: string;
    sortDir?: string;
    pageNum?: number;
    pageSize?: number;
    searchTerm?: string;
    isSubFolder: boolean;
    sortOrPageChanged?: boolean;
  }>()
);
export const getFolderItemsSucceeded = createAction(
  '[My Library] Get Folder items Succeeded',
  props<{ folderItems: Resource[]; total: number }>()
);
export const getFolderItemsFailed = createAction('[My Library] Get Folder Items Failed', props<{ error }>());

export const addFolderToMyLibrary = createAction(
  '[My Library] Add Folder to My Library Folders',
  props<{ folder: Resource }>()
);

export const keepLoadingFolder = createAction('[My Library] Create and Get Folders');
export const setSelectedFolderTitle = createAction(
  '[My Library] Set Selected Folder Title',
  props<{ selectedFolderTitle: string; selectedSubFolderTitle: string }>()
);
export const setSelectedFolderId = createAction(
  '[My Library] Set Selected Folder  Id',
  props<{ selectedFolderId: string }>()
);
export const setSelectedFolderAnchorId = createAction(
  '[My Library] Set Selected Folder Anchor Id',
  props<{ anchorId: string }>()
);
export const cancelGetItemCall = createAction('[My Library] Cancel Get Folder Item call');
export const setFolderHierarchy = createAction('[My Library] Set folder Hierarchy', props<{ isSubFolder: boolean }>());
export const resetFolderItems = createAction('[My Library] Reset Folder Items');
export const setParentFolderSharedAccess = createAction(
  '[My Library] Set Parent Folder Shared Access',
  props<{ isSharedFolder: boolean }>()
);
export const setAlert = createAction('[My Library] Set Alert', props<{ rootCode: string; alertId: string }>());
export const removeAlert = createAction('[My Library] Remove Alert', props<{ alertId: string }>());

export const deleteSubFolder = createAction('[My Library] Delete Sub Folder', props<{ folderId: string }>());
export const deleteSubFolderSucceeded = createAction(
  '[My Library] Delete Sub Folder Succeeded',
  props<{ folderId: string }>()
);
export const deleteSubFolderFailed = createAction('[My Library] Delete Sub Folder Failed', props<{ error }>());

export const renameFolder = createAction(
  '[My Library] Rename Folder',
  props<{ folder: Resource; folderHierarchy: string }>()
);
export const renameFolderSucceeded = createAction(
  '[My Library] Rename Folder Succeeded',
  props<{ folder: Resource; folderHierarchy: string }>()
);
export const renameFolderFailed = createAction('[My Library] Rename Folder Failed', props<{ error }>());

export const getUsersForFolder = createAction('[My Library] Retrieve Users for Folder', props<{ folderUrl: string }>());
export const getUsersForFolderSuccess = createAction(
  '[My Library] Get Users Succeeded',
  props<{
    users: UserFolderAccess[];
    total: number;
  }>()
);
export const getUsersForFolderFailure = createAction('[My Library] Get Users Failed', props<{ error: any }>());
export const paginateUsers = createAction(
  '[My Library] Paginated Users',
  props<{ pageNum: number; pageSize: number }>()
);
export const sortUsers = createAction('[My Library] Sort Users', props<{ sort: string; order: string }>());

export const getInvitesForFolder = createAction('[My Library] Get Invites for Folder', props<{ folderId: string }>());
export const getInvitesForFolderSuccess = createAction(
  '[My Library] Get Invites Succeeded',
  props<{ invites: Invitation[]; totalInvites: number }>()
);
export const getInvitesForFolderFailure = createAction('[My Library] Get Invites Failed', props<{ error: any }>());
export const sortInvites = createAction(
  '[My Library] Sort Invites',
  props<{ sort: 'Email' | 'Invited'; order: 'asc' | 'desc' }>()
);
export const pageInvites = createAction('[My Library] Page Invites', props<{ pageSize: number; pageNum: number }>());
export const inviteSuccessful = createAction('[My Library] Sending Invite Success');

export const resendInvite = createAction('[My Library] Resend Invite', props<{ inviteToResend: Invitation }>());
export const resendInviteSuccess = createAction(
  '[My Library] Resend Invite Succeeded',
  props<{ invite: Invitation }>()
);
export const resendInviteFailed = createAction('[My Library] Resend Invite Failed', props<{ error: any }>());

export const removeInvite = createAction('[My Library] Remove Invite', props<{ inviteToDelete: Invitation }>());
export const removeInviteSuccess = createAction('[My Library] Remove Invite Success');
export const removeInviteFailure = createAction('[My Library] Remove Invite Failed', props<{ error: any }>());

export const updateUserFolderPermissions = createAction(
  '[My Library] Update User Folder Permissions',
  props<{ folderId: string; userId: string; role: 'ALL' | 'READ_ONLY' }>()
);
export const updateUserFolderPermissionsSuccess = createAction(
  '[My Library] Successfully Updated User Folder Permissions',
  props<{ user: UserFolderAccess }>()
);
export const updateUserFolderPermissionsFailure = createAction(
  '[My Library] Failed To Update User Permissions',
  props<{ error: any }>()
);

export const removeUserFromFolder = createAction(
  '[My Library] Remove User from Folder',
  props<{ folderId: string; userId: string }>()
);
export const removeUserFromFolderSuccess = createAction(
  '[My Library] Successfully Removed User From Folder',
  props<{ userId: string }>()
);
export const removeUserFromFolderFailure = createAction(
  '[My Library] Failed To Remove User From Folder',
  props<{ error: any }>()
);
