import { MoreOption } from '@sae/models';
import { MobilusPubDocument } from '../../shared/models/api-models';
import {
  CommonFacets,
  CommonFacetsDefaultValues,
  TableParams,
  UISearchFacet,
  UISearchFilters,
} from '../../shared/models/common-interfaces';
import { BrowseByConfig, BrowseDescription, BrowseJournal, BrowseSort } from './browse.models';

export interface BrowseState extends TableParams, BrowseByConfig {
  results: MobilusPubDocument[];
  resultsLoading: boolean;
  topicsLoading: boolean;
  journalsDetailLoading: boolean;
  resultsError: unknown;
  sortOptions: string[];
  scrollTop: number;
  filters: UISearchFilters;
  facets: CommonFacets;
  preservedFacet: UISearchFacet<unknown>;
  panelInitialized: boolean;
  pubDateFrom: Date;
  pubDateTo: Date;
  isPubDateApplied: boolean;
  customFilters: UISearchFilters;
  journals: BrowseJournal[];
  journalOptions: MoreOption[];
}

export const initialBrowseState: BrowseState = {
  masterPanelTitle: 'Browse',
  masterPanelBrowseCategories: [],
  results: [],
  detailTitle: 'Browse SAE Mobilus',
  detailDescription: BrowseDescription.Home,
  searchTerm: '',
  pageNumber: 1,
  pageSize: 50,
  sortOptions: [BrowseSort.MostRecent, BrowseSort.Relevance, BrowseSort.Title],
  sortBy: BrowseSort.Relevance,
  sortDir: 'desc',
  total: 0,
  contentGroup: null,
  contentSubgroup: null,
  resultsLoading: true,
  topicsLoading: false,
  journalsDetailLoading: false,
  resultsError: null,
  scrollTop: 0,
  seoMetaDescription: '',
  filters: {},
  facets: CommonFacetsDefaultValues,
  preservedFacet: undefined,
  panelInitialized: false,
  isPubDateApplied: false,
  pubDateFrom: new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
  pubDateTo: new Date(),
  customFilters: {},
  journals: [],
  journalOptions: [
    {
      icon: 'view_headline',
      name: 'View Details',
    },
    {
      icon: 'layers',
      name: 'Browse Journal',
    },
    {
      icon: 'share',
      name: 'Share',
    },
  ],
};
