<mat-card class="si-card si-card--simple si-card--state mat-elevation-z">
  <div class="si-card__lead" *ngIf="materialIcon || customIcon || progressIcon || spinnerIcon" aria-hidden="true">
    <mat-progress-bar class="si-progress-bar" mode="buffer" *ngIf="progressIcon"></mat-progress-bar>
    <mat-spinner class="si-spinner" diameter="20" *ngIf="spinnerIcon"></mat-spinner>
    <mat-icon class="si-icon si-icon--status" color="primary" *ngIf="materialIcon">{{materialIcon}}</mat-icon>
  </div>
  <mat-card-header class="si-card__h">
    <mat-card-title class="si-card__title" *ngIf="title">{{title}}</mat-card-title>
    <mat-card-subtitle class="si-card__subtitle" *ngIf="subTitle">{{subTitle}}</mat-card-subtitle>
  </mat-card-header>
</mat-card>
