/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MoreOption } from '@sae/models';

@Component({
  selector: 'si-resource-card',
  templateUrl: './resource-card.component.html',
})
export class ResourceCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() descriptionSupported: boolean; // If true, firstly renders any actual description text in a subtitle under the title. Next, regardless of whether a description is set yet, an 'Edit Description' option is present in the actions menu (if enableRename is also true), which allows setting description.
  @Input() subTitle: string; // optional. Will override ModifiedDate and User
  @Input() type: string; // used for the icon style
  @Input() fileExt: string; // the actual file ext to be displayed as text over the icon
  @Input() enableRename: boolean;
  @Input() enableDelete: boolean;
  @Input() enableLink: boolean;
  @Input() enableDownload: boolean;
  @Input() enableDiscussion: boolean;
  @Input() enableFileDiscussion: boolean;
  @Input() modifiedDate: string;
  @Input() modifiedByUser: string;
  @Input() showModifiedTime: boolean;
  @Input() showModifiedByUser: boolean;
  @Input() href: string;
  @Input() replies: string;
  @Input() enableCopyLink = false;
  @Input() enableCopyName = false;
  @Input() toggleable = false; // Optional, if true enables functionality to toggle this card on/off
  @Input() toggled = false; // Optional, used in conjunction with toggleable, determines the toggle state of the card
  @Input() enableCopyUrlMenuItem = false; // Determines whether the "Copy URL" verlipsis menu item displays for files
  @Input() enableCopyFolderUrlMenuItem = false; // Determines whether the "Copy URL" verlipsis menu item displays for FOLDERS
  @Input() renameNamePatternValidator = /^[\S]+$/; // Applies the provided regex to the FormGroup validator for the name control, default is no whitespaces, note do NOT use the /g modifier to avoid interaction with RegExp lastIndex
  @Input() menuOptions: MoreOption[] = [];

  @Output() onRename = new EventEmitter<string>();
  @Output() onRenameDescription = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter<void>();
  @Output() onLink = new EventEmitter<void>();
  @Output() onDownload = new EventEmitter<string>();
  @Output() onDiscussion = new EventEmitter<void>();
  @Output() onClicked = new EventEmitter<void>();
  @Output() onEditLink = new EventEmitter<void>();
  @Output() menuIsOpen = new EventEmitter<boolean>();
  @Output() onCopy = new EventEmitter<string>();
  @Output() onCopyName = new EventEmitter<string>();
  @Output() toggleCard = new EventEmitter<boolean>(); // Event emitted if a toggleable enabled card is toggled
  @Output() optionSelected = new EventEmitter<string>();

  renameForm: FormGroup;
  renameMode: boolean;

  renameFormDescription: FormGroup;
  renameDescriptionMode: boolean;
  enableCopyUrl: boolean;
  enableVerllipsis: boolean;

  disabledRipple = false;
  showToggleButton = false;

  ngOnInit(): void {
    this.renameForm = new FormGroup({
      name: new FormControl(this.title, [Validators.required, Validators.pattern(this.renameNamePatternValidator)]),
    });

    this.renameFormDescription = new FormGroup({
      description: new FormControl(this.description, []),
    });

    this.enableCopyUrl =
      this.enableCopyUrlMenuItem &&
      (this.enableCopyLink ||
        this.enableLink ||
        this.enableDownload ||
        this.enableDiscussion ||
        this.enableFileDiscussion ||
        this.type.includes('link') ||
        this.enableCopyFolderUrlMenuItem);
    this.enableVerllipsis =
      this.enableRename || this.enableDelete || this.enableLink || this.enableDownload || this.enableCopyUrl || this.enableDiscussion || this.enableFileDiscussion;
  }

  initEdit(): void {
    if (this.type === '_link') {
      this.onEditLink.next();
    } else {
      this.renameMode = true;
    }
  }

  initEditDescription(): void {
    this.renameDescriptionMode = true;
  }

  initDelete(): void {
    this.onDelete.next();
  }

  initLink(): void {
    this.onLink.next();
  }

  initDiscussion(): void {
    this.onDiscussion.next();
  }

  initFileDiscussion(): void {
    this.onDiscussion.next();
  }

  initDownload(): void {
    this.onDownload.next(null);
  }

  initCopy(): void {
    this.onCopy.next(null);
  }

  initCopyName(): void {
    this.onCopyName.next(null);
  }

  clicked(): void {
    if (!this.renameMode) {
      this.onClicked.next();
    }
  }

  submitRename(): void {
    const newName = this.renameForm.get('name').value;
    this.onRename.next(newName);
    this.renameMode = false;
  }

  submitRenameDescription(): void {
    const newDescription = this.renameFormDescription.get('description').value;
    this.onRenameDescription.next(newDescription);
    this.renameDescriptionMode = false;
  }

  onMenu(open: boolean): void {
    this.menuIsOpen.emit(open);
  }

  onMouseEnter(): void {
    if (this.toggleable) {
      this.disabledRipple = true;
      this.showToggleButton = true;
    }
  }

  onMouseLeave(): void {
    if (this.toggleable) {
      this.disabledRipple = false;
      this.showToggleButton = false;
    }
  }

  onToggleClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.toggleCard.emit(!this.toggled);
  }

  onOptionSelected(option: string): void {
    this.optionSelected.emit(option);
  }
}
