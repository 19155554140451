import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'si-table-field',
  templateUrl: './table-field.component.html',
})
export class TableFieldComponent implements OnInit {
  @Input() title: string;
  @Input() message = 'Changes have been saved';
  @Input() data = false;

  @Output() editExit = new EventEmitter();

  public postForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.postForm = this.formBuilder.group({
      title: [this.title],
    });
  }

  ngOnInit() {
    this.postForm.controls.title.patchValue(this.title);
  }

  exitEdit() {
    this.editExit.emit();
  }
}
