import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoginLocation } from '../../models/common-interfaces';
import { AuthActions } from '../../../store/auth';

export interface SubscriptionLoginDialogData {
  icon?: string;
  title?: string;
}
@Component({
  selector: 'mobi-subscription-login-dialog',
  templateUrl: './subscription-login-dialog.component.html',
  styleUrls: ['./subscription-login-dialog.component.scss'],
})
export class SubscriptionLoginDialogComponent implements OnDestroy {
  loginHeaderText = 'Subscription Login';
  LoginFrom = LoginLocation.ToolBar;

  constructor(private store: Store) {}

  clickContactSales(): void {
    window.open('http://www.saemobilus.org/subscribe-today', '_blank');
  }

  clickProductPortfolio(): void {
    window.open('https://www.sae.org/publications/subscriptions', '_blank');
  }
  ngOnDestroy(): void {
    this.store.dispatch(AuthActions.setLogInToSubErrorMessage(null));
  }
}
