import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextContent } from '../../parts/part-text/part-text.component';
import { PartTitleComponent } from '../../parts/part-title/part-title.component';
import { CompAvatarComponent, AvatarContent } from '../../compounds/comp-avatar/comp-avatar.component';
import { RowTextContent, CompRowTextComponent } from '../../compounds/comp-row-text/comp-row-text.component';
import {
  CompUtilHeadingComponent,
  HeadingContent,
} from '../../compounds/comp-util-heading/comp-util-heading.component';
import { overrideInputs } from '@sae/base';

export interface HeaderMastheadContent {
  avatar?: AvatarContent;
  heading?: TextContent[];
  headingClasses?: string;
  headingContent?: HeadingContent;
  headingCount?: number;
  headingRow?: RowTextContent;
  subHeading?: TextContent[];
  subHeadingAside?: TextContent[];
  subHeadingClasses?: string;
  rows?: RowTextContent[];
}

@Component({
  selector: 'fs-ui-header-masthead',
  styleUrls: ['./ui-header-masthead.component.scss'],
  templateUrl: './ui-header-masthead.component.html',
  imports: [CommonModule, PartTitleComponent, CompAvatarComponent, CompRowTextComponent, CompUtilHeadingComponent],
  standalone: true,
})
export class UIHeaderMastheadComponent implements AfterViewInit, OnChanges {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: HeaderMastheadContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() avatar: AvatarContent;
  @Input() heading: TextContent[];
  @Input() headingClasses: string;
  @Input() headingContent: HeadingContent; // Optional, overrides other heading inputs when provided
  @Input() headingCount = -1; // Optional, adds a count to the heading
  @Input() headingRow: RowTextContent;
  @Input() subHeading: TextContent[];
  @Input() subHeadingAside: TextContent[];
  @Input() subHeadingClasses = 'si-masthead__b__item';
  @Input() rows: RowTextContent[];
  @Input() content: HeaderMastheadContent; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  @ViewChild('control') control: ElementRef;
  @ViewChild('context') context: ElementRef;

  contextClientWidth = 0;
  controlClientWidth: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }

    this.checkWidths();
  }

  ngAfterViewInit(): void {
    this.checkWidths();
  }

  private checkWidths(): void {
    let elementExists = 0;
    if (this.context?.nativeElement?.clientWidth) {
      this.contextClientWidth = this.context.nativeElement.clientWidth;
      elementExists++;
    }

    if (this.control?.nativeElement?.clientWidth) {
      this.controlClientWidth = this.control.nativeElement.clientWidth;
      elementExists++;
    }

    if (elementExists) this.changeDetectorRef.detectChanges();
  }
}
