import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { withTransaction } from '@datorama/akita';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { Store } from '@ngrx/store';
import { CardFeature, EnhancedTreeNode } from '@sae/components';
import { SaeHttpResponse } from '@sae/models';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  finalize,
  first,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { Api } from '../../../api';
import {
  Bookmark,
  ContentApiResponse,
  ContentView,
  CrossReference,
  JournalBrxDetail,
  SubscriptionAdministrators,
} from '../../modules/detail/models/content.model';
import { Alert } from '../models/alerts.model';
import {
  AccessControlResult,
  AppSearchSearchRequest,
  MobilusPubDocument,
  MobilusSearchResponse,
} from '../models/api-models';
import { Asset } from '../models/common-interfaces';
import { Revision, SearchResultEntry } from '../models/search-result.model';
import { AlertsUiService } from '../services/alerts-ui.service';
import { MobilusUiService } from '../services/mobilus-ui.service';
import { SnackbarDialogService } from '../services/snackbar-dialog.service';
import { ContentQuery } from './content.query';
import { ContentState, ContentStore, DetailsConfiguration, JournalBrowseCategory } from './content.store';
import { DigitalStandard, DigitalStandardsService } from './digital-standards.service';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { IEnvironmentConfigService } from '@sae/base';
import { MobilusProteusConfig } from '../../env.config';

export let JournalBrowseCategoryNodes: EnhancedTreeNode[] = [
  { name: 'e-First Articles', isSelected: false },
  { name: 'Free Articles', isSelected: false },
  { name: 'Open Access Articles', isSelected: false },
];
@NgEntityServiceConfig({
  resourceName: Api.content.url,
})
@Injectable({ providedIn: 'root' })
export class ContentService extends NgEntityService<ContentState> {
  loading$ = this.query.loading$;
  routeLoading$ = this.query.select((s) => s.routeLoading);
  selectedRevision$ = this.query.select((s) => s.selectedRevision);
  config$ = this.query.select((s) => s.config);
  private updateContent: BehaviorSubject<unknown> = new BehaviorSubject(null);
  contentUpdated$ = this.updateContent.asObservable();
  content$ = this.contentUpdated$.pipe(mergeMap(() => this.query.select((s) => s.contentView)));
  digitalStandard$ = this.query.select((s) => s.digitalStandard);
  openAllEvent$ = new Subject<void>();
  closeAllEvent$ = new Subject<void>();
  returnUrl$ = this.query.select((s) => {
    let returnUrl = { url: '', title: '' };
    if (s.returnUrl.length) {
      returnUrl = s.returnUrl[s.returnUrl.length - 1];
      // this.popReturnUrl();
    }
    return returnUrl;
  });
  bookmarks$: Observable<Bookmark[]> = this.content$.pipe(
    mergeMap(() => this.query.select((s) => [...s.contentView.bookmarks]))
  );
  bookMarkIds$: Observable<string[]> = this.content$.pipe(
    mergeMap(() => this.query.select((s) => [...s.contentView.bookmarks])),
    map((bkmrks: Bookmark[]) => {
      return bkmrks.map((bkmrk: Bookmark) => bkmrk.folderId);
    })
  );
  fileFormats$ = this.query.select((s) => s.fileFormats);
  fileFormatsError$ = this.query.select((s) => s.fileFormatsError);
  inMyLibrary$ = this.query.select((s) => s.inMyLibrary);

  // VIEW TAB
  htmlViewLoading$ = this.query.select((s) => s.htmlViewLoading);
  fullScreen$ = this.query.select((s) => s.fullScreen);

  // JOURNAL BROWSE TAB
  journalBrowsePageSize$ = this.query.select((s) => s.journalBrowsePageSize);
  journalBrowsePageNum$ = this.query.select((s) => s.journalBrowsePageNum);
  journalBrowseResults$ = this.query.select((s) => s.journalBrowseResults);
  journalBrowseSortBy$ = this.query.select((s) => s.journalBrowseSortBy);
  journalBrowseSortDir$ = this.query.select((s) => s.journalBrowseSortDir);
  journalBrowseLoading$ = this.query.select((s) => s.journalBrowseLoading);
  journalBrowseTotal$ = this.query.select((s) => s.journalBrowseTotal);
  journalBrowseSearchTerm$ = this.query.select((s) => s.journalBrowseSearchTerm);
  journalBrowseCategory$ = this.query.select((s) => s.journalBrowseCategory);
  journalBrowseCategoryNodes$ = this.query.select((s) => s.journalBrowseCategoryNodes);

  // RELATED TAB
  relatedPageSize$ = this.query.select((s) => s.relatedPageSize);
  relatedPageNum$ = this.query.select((s) => s.relatedPageNum);
  relatedTotal$ = this.query.select((s) => s.relatedTotal);
  relatedLoading$ = this.query.select((s) => s.relatedLoading);
  relatedResults$ = this.query.select((s) => s.relatedResults);

  // REFERENCES TAB
  crossReferences$ = this.query.select((s) => s.crossReferences);

  // JOURNAL BRX DETAIL
  journalBrxDetail$ = this.query.select((s) => s.journalBrxDetail);

  constructor(
    protected store: ContentStore,
    private query: ContentQuery,
    private digitalStandardsService: DigitalStandardsService,
    private mobilusUiService: MobilusUiService,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService,
    private snackbarDialogService: SnackbarDialogService,
    private alertsUiService: AlertsUiService,
    private ngRxStore: Store
  ) {
    super(store);
  }

  getById(id: number): Observable<ContentApiResponse> {
    this.store.setLoading(true);
    return super
      .get(id, {
        mapResponseFn: (res: SaeHttpResponse) => {
          if (res.statusCode === 200 && res.statusMessage?.includes('No document found for')) {
            return throwError(() => {
              return {
                status: 404,
              };
            });
          }
          return res?.result as ContentApiResponse;
        },
      })
      .pipe(finalize(() => this.store.setLoading(false)));
  }
  getBySeoURI(seoUri: string): Observable<ContentApiResponse> {
    this.store.setLoading(true);
    const httpParams = new HttpParams({ fromObject: { uri: seoUri } });
    return super
      .get<ContentApiResponse>({
        params: httpParams,
        mapResponseFn: (res: SaeHttpResponse) => {
          if (res.statusCode === 200 && res.statusMessage?.includes('No document found for')) {
            return throwError(() => {
              return {
                status: 404,
              };
            });
          }
          return res?.result as ContentApiResponse;
        },
      })
      .pipe(finalize(() => this.store.setLoading(false)));
  }

  getCrossReferences(id: number): Observable<CrossReference[]> {
    return super
      .getHttp()
      .get(`${this.baseUrl}/${Api.content.url}/${id}/crossreferences`)
      .pipe(
        map((res: SaeHttpResponse) => res?.results ?? []),
        catchError(() => of([])),
        withTransaction((crossReferences) => {
          this.store.update({ crossReferences });
        })
      );
  }

  getJournalBrowseResults(volume?: number, issue?: number): Observable<MobilusSearchResponse> {
    this.store.update({ journalBrowseLoading: true });
    const params = this.query.getValue();
    const headers = new HttpHeaders({ 'search-context': 'component' });
    const options = { headers };
    this.mobilusUiService;
    if (params.journalBrowseCategory === 'E-FIRST') {
      return this.mobilusUiService
        .getEFirstArticles(
          {
            magCode: params.contentView.magCode,
            query: params.journalBrowseSearchTerm,
            sort: 'pub_date',
            order: params.journalBrowseSortDir,
            size: params.journalBrowsePageSize,
            current: params.journalBrowsePageNum + 1,
          },
          options
        )
        .pipe(
          tap((response) => {
            this.store.update({
              journalBrowseResults: response.results,
              journalBrowseTotal: response.meta?.page?.total_results,
              journalBrowseLoading: false,
            });
          })
        );
    }

    const filters = [];
    if (params.journalBrowseCategory === 'FREE') {
      filters.push({
        collection_title: ['Free Journal Articles'],
      });
      filters.push({
        pub_date: {
          to: new Date().toISOString().split('.')[0] + '+00:00',
        },
      });
    } else if (params.journalBrowseCategory === 'OPEN ACCESS') {
      filters.push({
        collection_title: ['Open Access'],
      });
    } else if (params.journalBrowseCategory === 'VOLUME ISSUE') {
      filters.push({
        periodical_volume: [`${volume}`],
      });
      if (issue) {
        filters.push({
          periodical_issue: [`${issue}`],
        });
      }
    }

    const searchRequest: AppSearchSearchRequest = {
      query: params?.journalBrowseSearchTerm ?? '',
      page: {
        size: params?.journalBrowsePageSize,
        current: params?.journalBrowsePageNum + 1,
      },
      sort: [
        {
          pub_date: params?.journalBrowseSortDir,
        },
      ],
      filters: {
        all: [
          {
            periodical_code: [`${params?.contentView?.magCode}`],
          },
          {
            sub_group: ['Journal Article'],
          },
          ...filters,
        ],
      },
      facets: {
        total: [
          {
            type: 'value',
            name: 'total',
            size: 200,
          },
        ],
      },
    };
    return this.mobilusUiService.search2(searchRequest, options).pipe(
      tap((response) => {
        this.store.update({
          journalBrowseResults: response.results,
          journalBrowseTotal: response.facets?.total?.[0]?.data?.[0]?.count,
          journalBrowseLoading: false,
        });
      })
    );
  }

  getRecommendations(id: number): Observable<MobilusPubDocument[]> {
    this.store.update({ relatedLoading: true });
    const params = this.query.getValue();
    return super
      .getHttp()
      .get(`${this.baseUrl}/${Api.content.url}/${id}/recommendations`, {
        params: new HttpParams()
          .set('limit', params?.relatedPageSize)
          .set('offset', (params.relatedPageNum - 1) * params.relatedPageSize),
      })
      .pipe(
        map((res: SaeHttpResponse<MobilusPubDocument>) => {
          if (res?.results) {
            this.store.update({ relatedTotal: res?.pagination?.total ?? 0 });
            return res?.results;
          } else {
            this.store.update({ relatedTotal: 0 });
            return [];
          }
        }),
        tap((recommendations) => this.store.update({ relatedResults: recommendations })),
        catchError(() => of([])),
        finalize(() => this.store.update({ relatedLoading: false }))
      );
  }

  getFileFormats(id: number): Observable<Asset[]> {
    const url = `${this.baseUrl}/${Api.content.url}/${id}/assets`;
    const searchTypes = [
      'Full Text PDF',
      'Full Text PRC',
      'Full Text EPUB',
      'Compressed File ZIP',
      'Word DOC',
      'Word DOCX',
      'Excel XLS',
      'Excel XLSX',
    ];
    let params = new HttpParams();
    searchTypes.forEach((fileFormat) => {
      params = params.append('searchType', fileFormat);
    });

    return super
      .getHttp()
      .get(url, { params })
      .pipe(
        map((resp: SaeHttpResponse<Asset>) => resp.results),
        catchError(() => {
          this.setFileFormatsError(true);
          return of([] as Asset[]);
        })
      );
  }

  setFileFormats(fileFormats: Asset[]): void {
    this.store.update({ fileFormats });
  }

  setFileFormatsError(bool: boolean): void {
    this.store.update({ fileFormatsError: bool });
  }

  createAlert(alertedItem: ContentView, feature: string): Observable<Alert> {
    const revisions = alertedItem?.standardAttributes?.revisions.filter((r) => r.cid === alertedItem.id);
    const alertRequestBody = {
      application: 'mobilus',
      anchor: {
        feature: feature,
        id: alertedItem?.publicationId,
        display: alertedItem?.code,
      },
      interval: null,
      meta: {
        rootCode: alertedItem?.rootCode,
        revisions,
      },
      events: ['events.publication.document.revised'],
    };

    return super
      .getHttp()
      .post<SaeHttpResponse>(`${this.baseUrl}/${Api?.alerts?.url}`, alertRequestBody)
      .pipe(
        map((res: SaeHttpResponse) => {
          return res?.results?.length > 0 ? (res?.results as Alert[])?.[0] : null;
        }),
        tap((alert: Alert) =>
          this.store.update((s) => {
            return { contentView: { ...s.contentView, alertId: alert.id } };
          })
        ),
        tap(() => this.updateContent.next(null)),
        catchError((err) => {
          console.error(`Unable to Set Alert`, err);
          this.store.setError({ error: { alertSetError: true } });
          return of();
        })
      );
  }

  deleteAlert(alertId: string): Observable<SaeHttpResponse> {
    return super
      .getHttp()
      .delete(`${this.baseUrl}/${Api?.alerts?.url}/${alertId}`)
      .pipe(
        tap(() =>
          this.store.update((s) => {
            return { contentView: { ...s.contentView, alertId: null } };
          })
        ),
        tap(() => this.updateContent.next(null)),
        catchError((err) => {
          console.error(`Unable to Delete Alert`, err);
          this.store.setError({ error: { alertDeleteError: true } });
          return of();
        })
      ) as Observable<SaeHttpResponse>;
  }

  getHTMLView(id: number): Observable<string> {
    this.setHtmlViewLoading(true);
    return super
      .getHttp()
      .get(`${this.baseUrl}/${Api.content.url}/${id}/full-text`, { responseType: 'text' })
      .pipe(
        catchError(() => of('Error retrieving document')),
        tap(() => this.setHtmlViewLoading(false)),
        finalize(() => this.setHtmlViewLoading(false))
      );
  }

  buildFeatures(search: SearchResultEntry): CardFeature[] {
    const features: CardFeature[] = [];
    if (search?.meta?.searchFormat?.some((sf) => sf.type === 'html')) {
      features.push({
        active: false,
        name: 'Annotate',
        icon: 'create',
        count: undefined,
      });
    }
    if (search.meta?.fileFormat?.some((file) => file.type === '3d' && file.exists === 'true')) {
      features.push({
        active: false,
        name: '2D/3D',
        icon: '360',
        count: undefined,
      });
    }
    if (search.meta.dataSets?.length > 0) {
      features.push({
        active: false,
        name: 'Data Sets',
        icon: 'equalizer',
        count: undefined,
      });
    }
    if (search.meta?.fileFormat?.some((ff) => ff?.type === 'xpub')) {
      features.push({
        active: false,
        name: 'Redlined',
        icon: 'compare_arrows',
        count: undefined,
      });
    }
    return features;
  }

  removeBookmark(bookmarkId?: string): Observable<Bookmark[]> {
    return this.bookmarks$.pipe(
      first((v) => !!v),
      tap((bookmarks: Bookmark[]) => {
        const updated = [...bookmarks];
        if (bookmarkId) {
          const bkmarkIndex = updated.findIndex((bkmrk: Bookmark) => bkmrk.folderId === bookmarkId);
          updated.splice(bkmarkIndex);
        }
        this.store.update((s) => {
          return { contentView: { ...s.contentView, bookmarks: updated } };
        });
      }),
      switchMap(() => {
        return this.bookmarks$.pipe(first((v) => !!v));
      })
    );
  }

  clearBookmarks(): Observable<Bookmark[]> {
    return this.bookmarks$.pipe(
      first((v) => !!v),
      tap(() => {
        this.store.update((s) => {
          return { contentView: { ...s.contentView, bookmarks: [] } };
        });
      }),
      switchMap(() => {
        return this.bookmarks$.pipe(first((v) => !!v));
      })
    );
  }

  getCitations(optionKey: string): Observable<unknown> {
    return this.content$.pipe(
      switchMap((content: ContentView) => {
        const params = new HttpParams().set('contentIds', content.id).set('format', optionKey);
        return super
          .getHttp()
          .get(`${this.baseUrl}/${Api.citations.url}`, { params, observe: 'body', responseType: 'blob' })
          .pipe(first((v) => !!v));
      }),
      tap((blob) => {
        let fileName;
        switch (optionKey) {
          case 'refman':
            fileName = 'citationexport.ris';
            break;
          case 'refworks':
            fileName = 'citationexport.txt';
            break;
          case 'endnote':
            fileName = 'citationexport.enw';
            break;
          case 'bibtex':
            fileName = 'citationexport.bib';
            break;
        }
        this.blobToFile(blob, 'string', fileName);
      }),
      catchError((error: HttpErrorResponse) => {
        throw error;
      })
    );
  }

  blobToFile(data, type: string, fileName: string): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  addContentBookmark(bk: Bookmark): Observable<Bookmark[]> {
    return this.bookmarks$.pipe(
      tap((bkmks: Bookmark[]) => {
        if (!bkmks.some((bkmk) => bkmk.folderId === bk.folderId)) {
          this.store.update((s) => {
            return { contentView: { ...s.contentView, bookmarks: [...bkmks, bk] } };
          });
        }
      }),
      switchMap(() => this.bookmarks$.pipe(first((v) => !!v)))
    );
  }

  getRedlinedView(contentId1: number, contentId2: number): Observable<string> {
    return this.getHttp()
      .get(`${this.baseUrl}/${Api.content.url}/${contentId1}/redline/${contentId2}`)
      .pipe(map((resp: SaeHttpResponse<string>) => resp.results[0]));
  }

  setCurrentRevision(revision: Revision): void {
    this.store.update({ selectedRevision: revision });
  }

  setRouteLoading(bool: boolean): void {
    this.store.update({ routeLoading: bool });
  }

  setHtmlViewLoading(bool: boolean): void {
    this.store.update({ htmlViewLoading: bool });
  }

  clearActive(): void {
    this.store.setActive(undefined);
  }

  setActive(contentId: string): void {
    this.store.setActive(contentId);
  }

  setRelatedPageNum(num: number): void {
    this.store.update({ relatedPageNum: num + 1 });
  }

  setRelatedPageSize(num: number): void {
    this.store.update({ relatedPageSize: num });
  }

  setDigitalStandard(ds: DigitalStandard): void {
    this.store.update({ digitalStandard: ds });
  }

  setContentView(cv: ContentView): void {
    this.store.update({ contentView: cv });
  }

  updateDetailsConfiguration(config: Partial<DetailsConfiguration>): void {
    const curConfig = this.query.getValue().config;
    this.store.update({ config: { ...curConfig, ...config } });
  }

  resetStore(): void {
    const curReturnUrl = this.query.getValue().returnUrl;
    this.store.reset();
    this.store.update({ returnUrl: curReturnUrl });
  }

  setReturnUrl(url: string, title: string): void {
    const curReturnUrl = this.query.getValue().returnUrl;
    this.store.update({ returnUrl: [...curReturnUrl, { url, title }] });
  }
  popReturnUrl(): void {
    const returnUrl = [...this.query.getValue().returnUrl];
    returnUrl.pop();
    this.store.update({ returnUrl });
  }
  clearReturnUrl(): void {
    this.store.update({ returnUrl: [] });
  }

  getReturnUrl(): { url: string; title: string }[] {
    return this.query.getValue().returnUrl;
  }

  setInMyLibrary(bool: boolean): void {
    this.store.update({ inMyLibrary: bool });
  }

  setSearchTerm(searchTerm: string): void {
    this.store.update({ searchTerm });
  }

  setFullScreenMode(fullScreen: boolean): void {
    this.store.update({ fullScreen });
  }

  overrideAccessProperties(accessProperties: AccessControlResult): void {
    this.store.update((s) => ({
      contentView: {
        ...s.contentView,
        hasAccess: accessProperties.access,
        accessProperties,
      },
    }));
  }

  getSubAdminData(subscriptionId: string): Observable<SubscriptionAdministrators[]> {
    return this.getHttp()
      .get(`${this.baseUrl}/${Api.subscriptions.url}/${subscriptionId}/administrators`)
      .pipe(map((resp: SaeHttpResponse<SubscriptionAdministrators>) => resp.results));
  }

  setJournalBrowsePageSize(pageSize: number): void {
    this.store.update({ journalBrowsePageSize: pageSize });
  }

  setJournalBrowsePageNum(pageNum: number): void {
    this.store.update({ journalBrowsePageNum: pageNum });
  }

  setJournalBrowseLoading(loading: boolean): void {
    this.store.update({ journalBrowseLoading: loading });
  }

  setJournalBrowseSortDir(sortDir: SortDirection): void {
    this.store.update({ journalBrowseSortDir: sortDir });
  }

  setJournalBrowseSortBy(sortBy: string): void {
    this.store.update({ journalBrowseSortBy: sortBy });
  }

  setJournalBrowseSearchTerm(searchTerm: string): void {
    this.store.update({ journalBrowseSearchTerm: searchTerm });
  }

  setJournalBrowseCategory(category: JournalBrowseCategory): void {
    this.store.update({ journalBrowseCategory: category });
  }

  setJournalBrowseCategoryNodes(nodes: EnhancedTreeNode[]): void {
    this.store.update({ journalBrowseCategoryNodes: nodes });
  }
  addVolumeIssueNodes(journalVolumeIssueNodes: EnhancedTreeNode[]): void {
    const journalBrowseCategoryNodes = this.query.getValue().journalBrowseCategoryNodes;
    const nodes = [...journalBrowseCategoryNodes, ...journalVolumeIssueNodes];
    JournalBrowseCategoryNodes = nodes;
    this.store.update({ journalBrowseCategoryNodes: nodes });
  }

  getJournalBrxData(title: string): Observable<JournalBrxDetail> {
    let journalBrxUrl = brxJournalTitleWithUrl.find((brxJournal) => brxJournal[title])?.[title];
    if (!journalBrxUrl && title) {
      journalBrxUrl = title
        .replace(/[^\w\s—]/gi, '')
        .replace(/—/g, '--')
        .replace(/\s/g, '-')
        .toLowerCase();
    }
    return super
      .getHttp()
      .get(`${this.envConfigService.envConfig<MobilusProteusConfig>().journalDetailUrl}/${journalBrxUrl}`)
      .pipe(
        map((res: SaeHttpResponse) => res[0] ?? []),
        catchError(() => of(null)),
        withTransaction((journalBrxDetail) => {
          this.store.update({ journalBrxDetail });
        })
      );
  }
}

export const brxJournalTitleWithUrl = [
  { 'SAE International Journal of Aerospace': 'sae-international-journal-of-aerospace' },
  { 'SAE International Journal of Alternative Powertrains': 'sae-international-journal-of-alternative-powertrains' },
  { 'SAE International Journal of Commercial Vehicles': 'sae-international-journal-of-commercial-vehicles' },
  {
    'SAE International Journal of Connected and Automated Vehicles':
      'sae-international-journal-of-connected-and-automated-vehicles',
  },
  { 'SAE International Journal of Engines': 'sae-international-journal-of-engines' },
  { 'SAE International Journal of Fuels and Lubricants': 'sae-international-journal-of-fuels-and-lubricants' },
  {
    'SAE International Journal of Materials and Manufacturing':
      'sae-international-journal-of-materials-and-manufacturing',
  },
  {
    'SAE International Journal of Passenger Cars - Electronic and Electrical Systems':
      'sae-international-journal-of-passenger-cars--electronic-and-electrical-systems',
  },
  {
    'SAE International Journal of Passenger Cars - Mechanical Systems':
      'sae-international-journal-of-passenger-cars--mechanical-systems',
  },
  {
    'SAE International Journal of Transportation Cybersecurity and Privacy':
      'sae-international-journal-of-transportation-cybersecurity-and-privacy',
  },
  { 'SAE International Journal of Transportation Safety': 'sae-international-journal-of-transportation-safety' },
  {
    'SAE International Journal of Vehicle Dynamics, Stability, and NVH':
      'sae-international-journal-of-vehicle-dynamics-stability-and-nvh',
  },
  {
    'SAE International Journal of Advances and Current Practices in Mobility':
      'sae-international-journal-of-advances-and-current-practices-in-mobility',
  },
  {
    'SAE International Journal of Sustainable Transportation, Energy, Environment, & Policy':
      'sae-international-journal-of-sustainable-transportation-energy-environment--policy',
  },
  { 'SAE International Journal of Electrified Vehicles': 'sae-international-journal-of-electrified-vehicles' },
];
