import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserProfileStore } from './user-profile.store';
import { UserProfile } from '../../../../models/src/lib/user-profile.model';

@Injectable({ providedIn: 'root' })
export class UserProfileQuery extends QueryEntity<UserProfile> {
  constructor(protected store: UserProfileStore) {
    super(store);
  }
}
