import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { SearchActions, SearchSelectors } from '.';
import { RootStoreSelectors } from '..';
import {
  AggregatedSearchRequestFacets,
  AppSearchCombinedFilters,
  AppSearchSearchRequest,
  AppSearchRequestCustomFilter,
} from '../../shared/models/api-models';
import { FragmentService } from '../../shared/services/fragment.service';
import { MobilusUiService } from '../../shared/services/mobilus-ui.service';
import { SearchManagerUiService } from '../../shared/services/search-manager-ui.service';
import { LoggerService } from '@sae/services';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class SearchEffects {
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[SearchActions.search]),
      withLatestFrom(
        this.store.select(SearchSelectors.searchFeature),
        this.store.select(SearchSelectors.selectAdvancedSearchQuery)
      ),
      switchMap(([action, searchState, advQuery]) => {
        const sort =
          searchState.sortBy === 'Most Recent' ? 'pub_date' : searchState.sortBy === 'Title' ? 'title' : undefined;
        const filters: AppSearchCombinedFilters = {
          all: Object.entries(searchState.filters).map(([key, value]) => {
            return {
              [key]: value,
            };
          }),
        };

        let custom_filters: AppSearchRequestCustomFilter;
        if (searchState.customFilters?.only) {
          custom_filters = {};
        }
        searchState.customFilters?.only?.forEach((value) => {
          if (value === 'My Subscription') {
            custom_filters.my_sub = [
              {
                type: 'value',
                name: 'my_sub',
                size: 200,
              },
            ];
          } else if (value === 'New Content') {
            custom_filters.new_pub = [
              {
                type: 'value',
                name: 'new_pub',
                size: 200,
              },
            ];
          } else if (value === 'Full Text Content') {
            filters.all.push({ full_text_formats: ['PDF', 'XML'] });
          }
        });
        if (searchState.isPubDateApplied) {
          filters.all.push({
            pub_date: {
              from: searchState.pubDateFrom.toISOString().split('.')[0] + '+00:00',
              to: searchState.pubDateTo.toISOString().split('.')[0] + '+00:00',
            },
          } as any);
        }

        const req: AppSearchSearchRequest = {
          query: searchState.advancedSearchEnabled ? advQuery : searchState.searchTerm ?? '',
          page: {
            size: searchState.pageSize,
            current: searchState.pageNumber,
          },
          sort: sort
            ? [
                {
                  [sort]: searchState.sortDir,
                },
              ]
            : undefined,
          facets: AggregatedSearchRequestFacets,
          filters,
          custom_filters,
          advancedSearchCriteriaList: searchState.advancedSearchEnabled
            ? searchState.advancedSearchCriteria
            : undefined,
        };

        const url = searchState.advancedSearchEnabled
          ? this.mobilusUiService.advancedSearch(req, {
              headers: new HttpHeaders({ 'search-context': action.context }),
            })
          : this.mobilusUiService.search2(req, { headers: new HttpHeaders({ 'search-context': action.context }) });

        return url.pipe(
          map((resp) => SearchActions.searchSuccess({ resp, req })),
          catchError((error) => {
            return of(SearchActions.searchFail({ error }));
          })
        );
      })
    )
  );

  applyOrRemoveFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ...[
            SearchActions.applyFilter,
            SearchActions.removeFilter,
            SearchActions.setFilters,
            SearchActions.resetSearch,
            SearchActions.clearSearch,
            SearchActions.applyPubDateFilter,
            SearchActions.removePubDateFilter,
            SearchActions.changeSearchTerm,
            SearchActions.applyCustomFilter,
            SearchActions.removeCustomFilter,
            SearchActions.applyAdvancedSearchQuery,
            SearchActions.disableAdvancedSearchQuery,
          ]
        ),
        withLatestFrom(
          this.store.select(SearchSelectors.searchFeature),
          this.store.select(RootStoreSelectors.selectUrl)
        ),
        tap(([action, searchState, url]) => {
          const newFragment = this.fragmentService.buildFragment2({
            advancedSearchCriteria: searchState.advancedSearchEnabled ? searchState.advancedSearchCriteria : undefined,
            queryTerm: searchState.searchTerm ?? '',
            filters: searchState.filters,
            customFilters: searchState.customFilters,
            pubDate: searchState.isPubDateApplied
              ? {
                  from: searchState.pubDateFrom,
                  to: searchState.pubDateTo,
                }
              : undefined,
          });
          this.router.navigate([`${url.split('#')[0]}`], newFragment ? { fragment: `${newFragment}` } : undefined);
        })
      ),
    { dispatch: false }
  );

  getSearchSuggestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.getSearchSuggestions),
      switchMap((action) =>
        this.searchManagerUiService.getSuggestionsV2(action.searchTerm).pipe(
          map((suggestions) => SearchActions.getSearchSuggestionsSuccess({ suggestions })),
          catchError((error) => of(SearchActions.getSearchSuggestionsFailure({ error })))
        )
      )
    )
  );

  getDocumentSuggestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.getSearchSuggestions),
      switchMap((action) =>
        this.mobilusUiService.getTopThreeDocuments(action.searchTerm).pipe(
          map((response) => SearchActions.getDocumentSuggestionsSuccess({ suggestions: response.results })),
          catchError((error) => of(SearchActions.getDocumentSuggestionsFailure({ error })))
        )
      )
    )
  );

  triggerNewSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          SearchActions.applyFilter,
          SearchActions.removeFilter,
          SearchActions.setFilters,
          SearchActions.resetSearch,
          SearchActions.changePagination,
          SearchActions.changeSortParams,
          SearchActions.changeSearchTerm,
          SearchActions.applyPubDateFilter,
          SearchActions.removePubDateFilter,
          SearchActions.applyCustomFilter,
          SearchActions.removeCustomFilter,
          SearchActions.applyAdvancedSearchQuery,
          SearchActions.disableAdvancedSearchQuery,
        ]
      ),
      map(() => SearchActions.search({ context: 'main_search' }))
    )
  );

  triggerAutomatedSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.initialSearch),
      map(() => SearchActions.search({ context: 'search' }))
    )
  );

  searchSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.searchSuccess),
        tap(() => this.logger.log('Successfully got search results'))
      ),
    { dispatch: false }
  );

  searchFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.searchFail),
        tap(() => this.logger.error('Failed to retrieve search results'))
      ),
    { dispatch: false }
  );

  constructor(
    private mobilusUiService: MobilusUiService,
    private actions$: Actions,
    private store: Store,
    private fragmentService: FragmentService,
    private router: Router,
    private searchManagerUiService: SearchManagerUiService,
    private logger: LoggerService
  ) {}
}
