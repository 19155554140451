import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ResourceDialogComponent } from '@sae/components';
import {
  AutocompleteOption,
  createResource,
  FilePostBody,
  Resource,
  ResourceMenuItem,
  ResourceMetadata,
  ResourceShareDialogData,
  ResourceShareDialogResult,
  ResourcesResponse,
  ResourceType,
  UploadSubmission,
} from '@sae/models';
import { IResourcesService, RESOURCES_TOKEN } from '@sae/services';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResourceShareComponent } from './resource-share/resource-share.component';

import { SelectTreeNode } from '@sae/components';
// wraps the core service CRUD operations with the standardized UX flow

@Injectable({ providedIn: 'root' })
export class ResourcesUXService {
  private resourceDialogRef: MatDialogRef<ResourceDialogComponent>;
  private shareDialogRef: MatDialogRef<ResourceShareComponent>;

  public dialogIsOpen$ = new Subject<boolean>();

  constructor(
    @Inject(RESOURCES_TOKEN) private resourcesService: IResourcesService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) { }

  // FOLDERS
  launchAddFolderDialog(anchorId: string): Observable<Resource> {
    this.resourceDialogRef = this.dialog.open(ResourceDialogComponent, {
      data: {
        dialogType: 'addFolder',
        dialogTitle: 'Add Folder',
        dialogIcon: 'create_new_folder',
        anchorId: anchorId,
      },
    });
    this.watchDialog();
    const confirmDialogInstance = this.resourceDialogRef.componentInstance;
    return confirmDialogInstance.folderSubmitted.pipe(
      switchMap((newFolder: Resource): Observable<Resource> => {
        return this.resourcesService.create(newFolder).pipe(
          catchError((err) => {
            this.snackbar.open(`An error occurred when saving the Folder.`, 'DISMISS', { duration: 2500 });
            this.closeDialog();
            throw err;
          })
        );
      }),
      tap(() => {
        this.snackbar.open('Folder has been saved', 'DISMISS', { duration: 2500 });
        this.closeDialog();
      })
    );
  }

  // FILES
  launchUploadDialog(anchorId: string): Observable<Resource> {
    this.resourceDialogRef = this.dialog.open(ResourceDialogComponent, {
      data: {
        dialogType: 'addFile',
        dialogTitle: 'Add File',
        dialogIcon: 'file_upload',
      },
    });
    this.watchDialog();
    return this.resourceDialogRef.componentInstance.uploadSubmitted.pipe(
      switchMap((submitted: UploadSubmission): Observable<Resource> => {
        const data: UploadSubmission = {
          anchorId,
          ...submitted,
        };
        const file = data.file;
        const fileName = data.fileName;
        const resourceName = data.resourceName;
        const formData: FilePostBody = { fileName: file.name, fileId: null };
        this.resourceDialogRef.componentInstance.onUploadStarted();
        return this.resourcesService
          .getFileId({ filename: fileName }) // POST to the Files service to get an ID (POST #1)...
          .pipe(
            concatMap((fileId: string): Observable<ResourceMetadata> => {
              // then upload the file (POST #2)...
              formData.fileId = fileId;
              return this.resourcesService.fileUpload(file, formData, fileId);
            }),
            concatMap((result: ResourceMetadata): Observable<Resource> => {
              // then create the resource (POST #3).
              const resource: Resource = createResource({
                anchorId,
                name: resourceName,
                resourceType: ResourceType.FILE,
                metadata: {
                  fileId: result['fileId'],
                  resourceExtension: this.getFileExtension(file.name),
                  title: file.name,
                },
              });
              return this.resourcesService.create(resource);
            }),
            catchError((err) => {
              this.resourceDialogRef.componentInstance.onUploadFinished();
              const httpErr = err as HttpErrorResponse;
              if (httpErr.status === 403) {
                this.snackbar.open(httpErr.message, 'DISMISS', { duration: 2500 });
              } else {
                this.snackbar.open('Error: the file was not saved.', 'DISMISS', { duration: 2500 });
              }
              throw err;
            })
          );
      }),
      tap(() => {
        this.resourceDialogRef.componentInstance.onUploadFinished();
        this.snackbar.open('File has been saved', 'OK', { duration: 2500 });
        this.closeDialog();
      })
    );
  }

  download(r: Resource): void {
    const downloading = this.snackbar.open('Fetching download...', 'OK', { duration: 2500 });
    this.resourcesService
      .fileDownload(r)
      .pipe(
        catchError((err) => {
          downloading.dismiss();
          this.snackbar.open(`An error occurred when downloading the file.`, 'DISMISS', { duration: 2500 });
          throw err;
        })
      )
      .subscribe((success) => {
        downloading.dismiss();
        if (success) {
          this.snackbar.open('File was downloaded successfully.', 'OK', { duration: 2500 });
        } else {
          this.snackbar.open(`An error occurred when downloading the file.`, 'DISMISS', { duration: 2500 });
        }
      });
  }

  // LINKS
  launchAddLinkDialog(anchorId: string): Observable<Resource> {
    this.resourceDialogRef = this.dialog.open(ResourceDialogComponent, {
      data: {
        dialogType: ResourceMenuItem.addLink,
        dialogTitle: 'Add Link',
        dialogIcon: 'launch',
      },
    });
    this.watchDialog();
    return this.resourceDialogRef.componentInstance.linkSubmitted.pipe(
      switchMap((data): Observable<Resource> => {
        const resource = createResource({});
        const url = this.resourcesService.checkAndPrepareUrl(data.url);
        if (!url) {
          this.snackbar.open('Invalid URL', 'OK', { duration: 2500 });
          return of();
        }
        resource.metadata = {
          resourceLink: url,
        };
        resource.name = data.name;
        resource.resourceType = ResourceType.LINK;
        resource.anchorId = anchorId;
        return this.resourcesService.create(resource).pipe(
          catchError((err) => {
            this.snackbar.open(`An error occurred when saving the Link.`, 'DISMISS', { duration: 2500 });
            this.closeDialog();
            throw err;
          })
        );
      }),
      tap(() => {
        this.snackbar.open('Link has been saved', 'DISMISS', { duration: 2500 });
        this.closeDialog();
      })
    );
  }

  launchEditLinkDialog(link: Resource): Observable<Resource> {
    this.resourceDialogRef = this.dialog.open(ResourceDialogComponent, {
      data: {
        dialogType: 'addLink',
        dialogTitle: 'Edit Link',
        dialogIcon: 'launch',
        resource: link,
      },
    });
    this.watchDialog();
    return this.resourceDialogRef.componentInstance.linkSubmitted.pipe(
      switchMap((data) => {
        const url = this.resourcesService.checkAndPrepareUrl(data.url);
        if (!url) {
          this.snackbar.open('Invalid Url.', 'OK', { duration: 2500 });
          return of();
        }
        const newLink = { ...link };
        newLink.name = data.name;
        newLink.metadata = {};
        newLink.metadata.resourceLink = url;
        return this.resourcesService.edit(newLink).pipe(
          catchError((err) => {
            this.snackbar.open(`An error occurred when saving the Link.`, 'DISMISS', { duration: 2500 });
            this.closeDialog();
            throw err;
          })
        );
      }),
      tap(() => {
        this.snackbar.open('Link has been saved', 'DISMISS', { duration: 2500 });
        this.closeDialog();
      })
    );
  }

  followLink(r: Resource): void {
    if (this.resourcesService.isValidUrl(r.metadata.resourceLink)) {
      window.open(r.metadata.resourceLink, '_blank', 'noopener,noreferrer');
    }
  }

  copyLinkToClipboard(val: string): void {
    navigator.clipboard.writeText(val).then(
      () => {
        this.snackbar.open(`Link copied to clipboard`, 'Dismiss', {
          duration: 3000,
        });
      },
      () => {
        this.snackbar.open('Error copying link to clipboard', 'Dismiss', {
          duration: 3000,
        });
      }
    );
  }

  copyNameToClipboard(r: Resource): void {
    navigator.clipboard.writeText(r.name).then(
      () => {
        this.snackbar.open(`Resource Name copied to clipboard`, 'Dismiss', {
          duration: 3000,
        });
      },
      () => {
        this.snackbar.open('Error copying Resource Name to clipboard', 'Dismiss', {
          duration: 3000,
        });
      }
    );

  }

  // EDIT
  saveEditedResource(editedResource: Resource): void {
    const originalResource = this.resourcesService.queryGet(editedResource.id);
    if (!originalResource) {
      console.error('Unable to edit: Resource not found.');
      return;
    }
    const snack1 = this.snackbar.open('Saving changes...', '', { duration: 4500 });
    const undo$ = new Subject();

    this.resourcesService
      .edit(editedResource)
      .pipe(
        takeUntil(undo$),
        catchError((err) => {
          snack1.dismiss();
          this.snackbar.open('Error in saving the item. Please try again.', 'DISMISS', { duration: 2000 });
          undo$.complete();
          throw err;
        })
      )
      .subscribe(() => {
        snack1.dismiss();
        const snack2 = this.snackbar.open('Changes have been saved', 'Undo', { duration: 4500 });
        snack2.onAction().subscribe(() => {
          undo$.next(null);
          undo$.complete();
        });
        undo$.pipe(takeUntil(snack2.afterDismissed())).subscribe(() => {
          this.resourcesService
            .edit(originalResource)
            .pipe(
              catchError((err) => {
                this.snackbar.open('Unable to undo', 'DISMISS', { duration: 2000 });
                throw err;
              })
            )
            .subscribe();
        });
      });
  }

  moveResource(editedResource: Resource, selectedNode: SelectTreeNode): Observable<Resource> {
    const snack1 = this.snackbar.open('Moving resources...', '', { duration: 4500 });
    return this.resourcesService.move(editedResource, selectedNode.id).pipe(
      catchError((err) => {
        snack1.dismiss();
        this.snackbar.open('Error in moving the item. Please try again.', 'DISMISS', { duration: 2000 });
        throw err;
      })
    );
  }

  // DELETE
  launchDeleteDialog(resource: Resource): Observable<ResourcesResponse> {
    this.resourceDialogRef = this.dialog.open(ResourceDialogComponent, {
      data: {
        dialogType: ResourceMenuItem.itemDelete,
        dialogTitle: 'Delete item?',
        dialogIcon: 'delete',
        resource,
      },
    });
    return this.resourceDialogRef.afterClosed().pipe(
      switchMap((choice: string): Observable<ResourcesResponse> => {
        if (choice !== 'confirm') {
          return of();
        } else {
          return this.resourcesService.remove(resource.id).pipe(
            catchError((err) => {
              this.snackbar.open('An error occurred when deleting the item.', 'OK', { duration: 2500 });
              throw err;
            })
          );
        }
      }),
      tap(() => {
        this.snackbar.open('Item was deleted.', 'OK', { duration: 2500 });
      })
    );
  }

  // SHARING
  launchShareDialog(
    rootResourceId: string,
    shareOptions: AutocompleteOption[],
    shareType: string
  ): Observable<ResourceShareDialogResult> {
    if (!rootResourceId || !shareOptions?.length || !shareType) {
      return throwError('Unable to share: parameters missing.');
    }
    const dialogData: ResourceShareDialogData = {
      rootResourceId: rootResourceId,
      shareOptions: shareOptions,
      shareType: shareType,
    };
    this.shareDialogRef = this.dialog.open(ResourceShareComponent, { data: dialogData });
    return this.shareDialogRef.afterOpened().pipe(
      switchMap((): Observable<ResourceShareDialogResult> => {
        this.dialogIsOpen$.next(true);
        return this.shareDialogRef.componentInstance.dialogResult$;
      }),
      tap(() => {
        this.dialogIsOpen$.next(false);
      })
    );
  }

  // UTILS
  closeDialog(): void {
    this.resourceDialogRef.componentInstance.closeDialog();
  }

  private watchDialog(): void {
    this.resourceDialogRef.afterOpened().subscribe(() => {
      this.dialogIsOpen$.next(true);
    });
    this.resourceDialogRef.afterClosed().subscribe(() => {
      this.dialogIsOpen$.next(false);
    });
  }

  private getFileExtension(filename: string): string {
    const a = filename.split('.');
    if (a.length === 1 || (a[0] === '' && a.length === 2)) {
      return '';
    }
    return a.pop();
  }
}
