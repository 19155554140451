import { DynamicHashOptions, SharedUtilitiesModule } from '@sae/utilities';
import { isExternalLink, overrideInputs } from '@sae/base';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PartIconComponent } from '../part-icon/part-icon.component';
import { PartSpinnerComponent } from '../part-spinner/part-spinner.component';
import { CompAvatarComponent, AvatarContent } from '../../compounds/comp-avatar/comp-avatar.component';

export type ButtonRole = 'checkbox' | 'menuitem' | 'menuitemcheckbox' | 'menuitemradio' | 'option' | 'radio' | null;
// 'button' | 'link' are excluded since semantic tags are used for those

export interface ButtonItem {
  avatar?: AvatarContent;
  buttonId?: string | null;
  classes?: string;
  disableButton?: boolean;
  disableExternalIcon?: boolean;
  disableLinks?: boolean;
  external?: boolean;
  fauxButton?: boolean;
  fauxLink?: boolean;
  fine?: boolean;
  imageSrc?: string;
  itempropButton?: string;
  itempropLabel?: string;
  label?: string;
  labelBrief?: string;
  linkOnly?: boolean;
  materialIcon?: string;
  minor?: boolean;
  role?: ButtonRole;
  selected?: boolean;
  shadow?: boolean;
  stopPropagation?: boolean;
  subdued?: boolean;
  suffixIcon?: string;
  svg?: string;
  tooltip?: string;
  type?: string;
  unusual?: boolean;
  url?: string | null;
  dynamicHashOptions?: DynamicHashOptions;
}

@Component({
  selector: 'fs-part-button',
  templateUrl: './part-button.component.html',
  styleUrls: ['./part-button.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    PartIconComponent,
    PartSpinnerComponent,
    CompAvatarComponent,
    SharedUtilitiesModule,
  ],
  standalone: true,
})
export class PartButtonComponent implements OnChanges {
  constructor(private domSanitizer: DomSanitizer) {}

  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: ButtonItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() ariaControls: string | null;
  @Input() ariaExpanded: boolean | null;
  @Input() ariaHaspopup: boolean | null;
  @Input() ariaLabel: string | null;
  @Input() ariaLabelledBy: string | null;
  @Input() avatar: AvatarContent | null;
  @Input() buttonId: string | null;
  @Input() classes: string;
  @Input() external: boolean;
  @Input() disableButton: boolean; // applicable to buttons only
  @Input() disableExternalIcon: boolean;
  @Input() disableLinks: boolean; // Set to true when contained within a surrounding link
  @Input() dynamicHashOptions: DynamicHashOptions; // Allows for using dynamic directive on the button instead of url for routerLink
  @Input() fauxButton: boolean; // fake button -- for use inside another button
  @Input() fauxLink: boolean; // fake link
  @Input() fine: boolean;
  @Input() imageSrc: string;
  @Input() itempropButton: string;
  @Input() itempropLabel: string;
  @Input() label: string;
  @Input() labelBrief: string; // If provided, this is used instead of label for smaller displays
  @Input() linkOnly: boolean; // makes it a link instead of button
  @Input() materialIcon: string;
  @Input() minor: boolean;
  @Input() role: ButtonRole;
  @Input() selected: boolean;
  @Input() shadow: boolean;
  @Input() stopPropagation: boolean;
  @Input() subdued: boolean;
  @Input() suffixIcon: string;
  @Input() svg: string;
  @Input() tooltip: string;
  @Input() type = 'primary';
  @Input() unusual: boolean; // behaves differently (usually underlined without a hover/focus)
  @Input() url: string | null;
  @Input() working: boolean; // Maintains a dimmed button with a spinner over it
  @Input() buttonItem: ButtonItem; // [DEPRECATED - Will be removed] Note if this input is set, any properties it provides are overrides for all other inputs

  @Output() buttonClick = new EventEmitter<string>(); // Event emitted when component is in button mode (url is null)
  @Output() buttonClickItem = new EventEmitter<ButtonItem>(); // Event emitted when component is in button mode (url is null)

  @ViewChild('buttonElement', { static: false }) buttonElement: ElementRef<HTMLButtonElement>;
  @ViewChild('linkElement', { static: false }) linkElement: ElementRef<HTMLAnchorElement>;
  @ViewChild('linkElementExternal', { static: false }) linkElementExternal: ElementRef<HTMLAnchorElement>;

  safeSvg: SafeHtml;
  tabindex: -1 | 0 | null;
  _domainPath = false;
  mailto = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['buttonItem']) {
      overrideInputs(this, this.buttonItem);
    }

    if (this.svg) {
      this.safeSvg = this.domSanitizer.bypassSecurityTrustHtml(this.svg);
    }

    this.tabindex = this.role ? -1 : null;

    if (this.type === 'scrim') {
      this.tabindex = -1;
    }

    this.mailto = this.url?.startsWith('mailto:') ?? false;

    if (!this.mailto && isExternalLink(this.url ?? '')) {
      this._domainPath = true;
    }
  }

  onButtonClick(event: Event): void {
    if (this.stopPropagation) event.stopPropagation();
    if (this.buttonItem) this.buttonClickItem.emit(this.buttonItem);
    this.label ? this.buttonClick.emit(this.label) : this.buttonClick.emit(this.tooltip);
  }

  public setFocus(): void {
    if (this.buttonElement) this.buttonElement.nativeElement.focus();
    if (this.linkElement) this.linkElement.nativeElement.focus();
    if (this.linkElementExternal) this.linkElementExternal.nativeElement.focus();
  }

  public clickButton(): void {
    if (this.buttonElement) this.buttonElement.nativeElement.click();
    if (this.linkElement) this.linkElement.nativeElement.click();
    if (this.linkElementExternal) this.linkElementExternal.nativeElement.click();
  }
}
