<ng-container *ngIf="{} as _">
  <mat-expansion-panel
    [expanded]="facet?.expanded"
    class="si-expanel si-expanel--form si-facet si-impact mat-elevation-z"
    [class.si-state--active]="panelExpand"
    (opened)="expandThisAggregation()"
    (closed)="collapseThisAggregation()"
  >
    <mat-expansion-panel-header class="si-expanel__h">
      <mat-panel-title class="si-expanel__title">
        <span>{{ facet?.displayName }}</span>
        <mat-icon class="si-icon si-facet__icon" aria-hidden="true" *ngIf="(filters | keyvalue)?.length > 0">
          done
        </mat-icon>
        <span class="si-expanse"></span>
        <mat-icon
          class="si-icon si-facet__icon"
          *ngIf="isPreservedFacet"
          matTooltip="Refresh {{ facet?.displayName }}"
          aria-label="Refresh {{ facet?.displayName }}"
          aria-hidden="true"
          (click)="$event.stopPropagation(); clearPreservedFacet()"
        >
          refresh
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="si-expanel__c si-expanel__c--form">
      <div class="si-field si-wd-full">
        <div class="mat-form-field-wrapper">
          <div class="si-input si-input--clickstack si-facet__list">
            <mat-checkbox
              class="si-checkbox si-checkbox--date"
              value="dateRange"
              (click)="$event.stopPropagation()"
              (change)="toggleSelection($event.checked)"
              [checked]="isPubDateFilterApplied"
            >
              <span class="si-wd-full">Date Range</span>
              <mat-form-field class="si-wd si-wd-half">
                <mat-label>From</mat-label>
                <input
                  matInput
                  [matDatepicker]="from"
                  [formControl]="dateFrom"
                  [max]="dateTo.value"
                  aria-label="From"
                />
                <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                <mat-datepicker #from></mat-datepicker>
                <mat-error *ngIf="dateFrom.errors?.matDatepickerMax">
                  Start date cannot be later than end range date
                </mat-error>
                <mat-error *ngIf="dateFrom.errors?.matDatepickerParse">Invalid date</mat-error>
              </mat-form-field>
              <mat-form-field class="si-wd si-wd-half si-field--to">
                <mat-label>To</mat-label>
                <input matInput [matDatepicker]="to" [formControl]="dateTo" aria-label="To" />
                <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                <mat-datepicker #to></mat-datepicker>
                <mat-error *ngIf="dateTo.errors?.matDatepickerParse">Invalid date</mat-error>
              </mat-form-field>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
