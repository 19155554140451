import { Component } from '@angular/core';
import { ContentService } from '../../../../../shared/state/content.service';

@Component({
  selector: 'mobi-detail-journal-authoring',
  templateUrl: './detail-journal-authoring.component.html',
})
export class DetailJournalAuthoringComponent {
  public journalBrxDetail$ = this.contentService.journalBrxDetail$;
  constructor(private contentService: ContentService) {}
}
