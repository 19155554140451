import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SeoChiplistDto } from '@sae/components';

@Component({
  selector: 'si-data-output',
  templateUrl: './data-output.component.html',
})
export class DataOutputComponent implements OnInit {
  @Input() title: string; // Optional, adds a title header containing the specified text
  @Input() titleLink: string; // Optional, used in conjection with title, makes the title a clickable link
  @Input() content: string | number | string[]; // Optional, content to inject into the output wrapper, laid above any projected content

  // BEGIN: All this will eventually be deprecated and removed via UX-1196
  @Input() buttonIcon: string;
  @Input() buttonLabel: string;
  @Input() editContent = false;
  @Input() showEditField = false;
  @Input() chips: string[] = [];
  @Input() chipsLink: string[] = [];
  @Input() chipsType: string[] = [];
  @Input() removableChip = false;

  @Output() chipRemove = new EventEmitter<SeoChiplistDto>();

  chipsList: SeoChiplistDto[] = [];
  // END: All this will eventually be deprecated and removed via UX-1196

  ngOnInit() {
    for (let i = 0; i < this.chips.length; i++) {
      const newChip: SeoChiplistDto = {
        label: this.chips[i],
        href: this.chipsLink[i] ?? null,
        classes: '',
      };

      if (this.removableChip) {
        newChip.icon = 'clear';
        newChip.iconEventEmitter = true;
      }

      if (this.chipsType[i] === 'Role') {
        newChip.classes += ' si-chip--role';
      }
      if (this.chipsType[i] === 'Content') {
        newChip.classes += ' si-chip--content';
      }
      if (this.chipsType[i] === 'Keyword') {
        newChip.classes += ' si-chip--searchterm';
      }
      if (this.chipsType[i] === 'Affiliation' || this.chipsType[i] === 'Author') {
        newChip.classes += ' mat-elevation-z1';
      }
      if (this.chipsType[i] === 'Sector') {
        newChip.classes += ' si-chip--sector';
      }
      if (this.chipsType[i] === 'Topic') {
        newChip.classes += ' si-chip--topic';
      }
      if (this.chipsType[i] === 'Open') {
        newChip.classes += ' si-chip--open';
      }
      if (this.chipsType[i] === 'Closed') {
        newChip.classes += ' si-chip--closed';
      }

      this.chipsList.push(newChip);
    }
  }

  iconClicked(icon: SeoChiplistDto) {
    this.chipRemove.emit(icon);
  }
}
