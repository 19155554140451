import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Analytics, isSupported, setUserId, setUserProperties } from '@angular/fire/analytics';
import { Query, Store } from '@datorama/akita';
import { SaeEnvironmentConfig as Config } from '@sae/base';
import { KeycloakEventType, KeycloakEventType as AuthEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { from, map, Observable, of, tap } from 'rxjs';
import { StoresRegistry, StoresToken } from '../api-registry';
import { ApmRumService } from './apm.core';

export interface IAuthService {
  username$: Observable<string | undefined>;
  profile$: Observable<KeycloakProfile | undefined>;
  isLoggedIn$: Observable<boolean>;
  personType$: Observable<string>;
  apiRootUrl: string;
  initialize(config: Config): Promise<boolean>;
  login(redirectUri?: string): Promise<void>;
  logout(redirectUri?: string): Promise<void>;
  manage(): Promise<void>;
  register(redirectUri?: string): Promise<void>;
  getAccountUrl(): string;
  reset(): void;
  setIsLoggedIn(loggedIn: boolean): void;
  setProfile(profile: KeycloakProfile): void;
  setPersonType(personType: string): void;
  isLoggedIn(): boolean;
  getProfile(): KeycloakProfile | undefined;
  getUserId(): string | undefined;
  getUserRoles(): Array<string>;
  getAndRefreshLoggedIn(): Observable<boolean>;
  getRefreshTokenExpiry(): Date;
  authEvent$: Observable<AuthEvent>;
  updateToken(minValidity?: number): Observable<boolean>;
}

export { KeycloakEventType as AuthEventType };
export interface AuthEvent {
  type: AuthEventType;
  args?: unknown;
}

//base classes for akita objects
export interface AuthState {
  profile: KeycloakProfile | undefined;
  loggedIn: boolean;
  personType: string;
}
export class AuthQuery<TState extends AuthState> extends Query<TState> {
  constructor(protected store: AuthStore<TState>) {
    super(store);
  }
}
export class AuthStore<TState extends AuthState> extends Store<TState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry, derivedState?: TState) {
    const initialState = derivedState ?? (AuthStore.createInitialState() as TState);
    super(initialState, { name: storesRegistry.auth });
  }
  static createInitialState(): AuthState {
    return {
      profile: undefined,
      loggedIn: false,
      personType: 'None',
    };
  }
}

// base class for service - apps may extend this themselves if absolutely needed. not directly injectable.
export class AuthCoreBase<TState extends AuthState> implements IAuthService {
  constructor(
    @Optional() protected readonly analytics: Analytics,
    protected readonly query: AuthQuery<TState>,
    protected readonly store: AuthStore<TState>,
    protected readonly apmService: ApmRumService,
    protected readonly kcService: KeycloakService
  ) {}

  public username$ = this.query.select((state) => (state.profile ? state.profile.username : ''));
  public profile$ = this.query.select((state) => state.profile);
  public isLoggedIn$ = this.query.select((state) => state.loggedIn);
  public personType$ = this.query.select((s) => s.personType);
  public apiRootUrl!: string;
  public authEvent$ = this.kcService.keycloakEvents$.pipe(map((evt) => evt as AuthEvent));

  initApm(profile: KeycloakProfile): void {
    const parsedJWT = this.kcService.getKeycloakInstance().tokenParsed;
    const parsedIdToken = this.kcService.getKeycloakInstance().idTokenParsed;

    this.apmService.apm.setUserContext({
      username: profile?.username?.toLowerCase(),
      id: profile.id,
      email: profile.email,
    });

    this.apmService.apm.addLabels({
      parsedJWT: JSON.stringify(parsedJWT),
      profile: JSON.stringify(parsedJWT), //, -- Changed based on feedback from Chandra about how he's expecting the fields to be populated in ELK - JJV
      keycloakProfile: JSON.stringify(profile),
      parsedIdToken: JSON.stringify(parsedIdToken),
    });
  }

  public async initialize(config: Config): Promise<boolean> {
    this.apiRootUrl = config.services.apiRootUrl;
    try {
      const ready = await this.kcService.init({
        config: {
          realm: config.auth.realm,
          url: config.auth.url,
          clientId: config.auth.clientId,
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + '/silent-refresh.html',
          enableLogging: false,
          messageReceiveTimeout: 2500,
        },
        enableBearerInterceptor: true,
        loadUserProfileAtStartUp: true,
        bearerExcludedUrls: config?.auth?.bearerExcludedUrls || [],
      });

      const isLoggedIn = this.kcService.isLoggedIn();
      let profile = undefined;
      if (isLoggedIn) {
        const parsedJWT = this.kcService.getKeycloakInstance().tokenParsed;
        profile = await this.kcService.loadUserProfile();
        profile.id = parsedJWT?.sub;
        if (this.analytics && (await isSupported())) {
          setUserId(this.analytics, profile?.id ?? '', { global: true });
          setUserProperties(this.analytics, { profile }, { global: true });
        }
        if (config.metrics.useApm) {
          this.initApm(profile);
        }
      }
      this.store.update({ profile, loggedIn: isLoggedIn } as Partial<TState>);
      return ready;
    } catch (e) {
      console.error('keycloak error: ', e);
    }
    return false;
  }

  async login(redirectUri?: string): Promise<void> {
    if (redirectUri) {
      await this.kcService.login({ redirectUri });
    } else {
      await this.kcService.login();
    }
  }

  async logout(redirectUri?: string): Promise<void> {
    await this.kcService.logout(redirectUri);
    this.reset();
  }

  async manage(): Promise<void> {
    await this.kcService.getKeycloakInstance().accountManagement();
  }

  async register(redirectUri?: string): Promise<void> {
    await this.kcService.register({ redirectUri });
  }

  getAccountUrl(): string {
    return this.kcService.getKeycloakInstance()?.createAccountUrl();
  }

  reset(): void {
    this.store.reset();
  }

  setIsLoggedIn(loggedIn: boolean): void {
    this.store.update({ loggedIn } as Partial<TState>);
  }

  setProfile(profile: KeycloakProfile): void {
    this.store.update({ profile } as Partial<TState>);
  }

  setPersonType(personType: string): void {
    this.store.update({ personType } as Partial<TState>);
  }

  isLoggedIn(): boolean {
    return this.query.getValue().loggedIn;
  }

  getProfile(): KeycloakProfile | undefined {
    return this.query.getValue().profile;
  }
  getUserId(): string | undefined {
    return this.query.getValue().profile?.id;
  }

  getUserRoles(): Array<string> {
    return this.kcService.getUserRoles();
  }

  public getAndRefreshLoggedIn(): Observable<boolean> {
    return of(this.kcService.isLoggedIn()).pipe(tap((loggedIn) => this.store.update({ loggedIn } as Partial<TState>)));
  }

  public updateToken(minValidity?: number): Observable<boolean> {
    return from(this.kcService.updateToken(minValidity ?? 30));
  }

  public getRefreshTokenExpiry(): Date {
    const refreshToken = this.kcService.getKeycloakInstance().refreshTokenParsed as KeycloakTokenParsed;
    if (refreshToken?.exp) {
      return new Date(refreshToken.exp * 1000);
    }
    return null;
  }
}

// default implementations of store and query
@Injectable({ providedIn: 'root' })
export class DefaultAuthStore extends AuthStore<AuthState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super(storesRegistry, DefaultAuthStore.createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class DefaultAuthQuery extends AuthQuery<AuthState> {
  constructor(protected store: DefaultAuthStore) {
    super(store);
  }
}

@Injectable({ providedIn: 'root' })
export class AuthCoreService extends AuthCoreBase<AuthState> implements IAuthService {
  constructor(
    protected readonly analytics: Analytics,
    protected readonly query: DefaultAuthQuery,
    protected readonly store: DefaultAuthStore,
    protected readonly apmService: ApmRumService,
    protected readonly kcService: KeycloakService
  ) {
    super(analytics, query, store, apmService, kcService);
  }
}

// Alternate version of AuthCoreService for apps which have removed firebase
// Only difference is "analytics" is omitted
@Injectable({ providedIn: 'root' })
export class ProteusAuthCoreService extends AuthCoreBase<AuthState> implements IAuthService {
  constructor(
    protected readonly query: DefaultAuthQuery,
    protected readonly store: DefaultAuthStore,
    protected readonly apmService: ApmRumService,
    protected readonly kcService: KeycloakService
  ) {
    super(null, query, store, apmService, kcService);
  }
}

export const AUTH_TOKEN = new InjectionToken<IAuthService>('Auth Service');
