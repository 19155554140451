<div
  class="si-datagrid {{ classes }}"
  [class.si-datagrid--halfgap]="halfGap"
  [class.si-datagrid--simple]="simpleFlex"
  [class.si-datagrid--list]="isList"
>
  <ng-content select="[start]"></ng-content>
  <ng-container *ngFor="let item of items; let i = index">
    <fs-part-divider
      *ngIf="itemDivider"
      class="si-datagrid__divider"
      [classes]="itemDivider.classes"
      [spacious]="itemDivider.spacious"
      [simple]="itemDivider.simple"
    ></fs-part-divider>
    <fs-ui-data-item
      *ngIf="!itemContainer"
      class="si-datagrid__i {{ item.width ? 'si-wd si-wd-' + item.width : '' }} {{ item.outerClasses }}"
      [content]="item"
      (buttonClick)="onButtonClick($event)"
      (chipClick)="onChipClick($event)"
      (chipRemoveClick)="onRemoveChipClick($event)"
    ></fs-ui-data-item>
    <fs-part-container
      *ngIf="itemContainer"
      [content]="itemContainer"
      class="si-datagrid__i si-datagrid__i--contained {{ item.width ? 'si-wd si-wd-' + item.width : '' }}"
    >
      <fs-ui-data-item
        [content]="item"
        (buttonClick)="onButtonClick($event)"
        (chipClick)="onChipClick($event)"
        (chipRemoveClick)="onRemoveChipClick($event)"
      ></fs-ui-data-item>
    </fs-part-container>
  </ng-container>
  <fs-part-divider
    *ngIf="itemDivider"
    class="si-datagrid__divider"
    [classes]="itemDivider.classes"
    [spacious]="itemDivider.spacious"
    [simple]="itemDivider.simple"
  ></fs-part-divider>
  <ng-content select="[end]"></ng-content>
</div>
