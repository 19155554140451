import { Injectable, InjectionToken, Injector } from '@angular/core';
import { isSupported as analyticsSupported } from '@angular/fire/analytics';
import { isSupported as rcSupported } from '@angular/fire/remote-config';
import { Router } from '@angular/router';
import { NgEntityServiceGlobalConfig, NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { ApmService } from '@elastic/apm-rum-angular';
import { SaeEnvironmentConfig as Config, IRemoteConfigService, SaeEnvironment } from '@sae/base';
import { FirebaseOptions } from 'firebase/app';
import { proxyFirebaseRemoteConfigCalls } from '../firebase.interceptor';
import { ApmRumService } from './apm.core';
import { AUTH_TOKEN, IAuthService } from './auth.core';
import { FEATURE_FLAG_TOKEN, IFeatureFlagService } from './feature-flag.core';
import { REMOTE_CONFIG_TOKEN } from './remote-config.core';
import { ISessionExpiryService, SESSION_EXPIRY_TOKEN } from './session-expiry.core';

export const FirebaseConfigToken = new InjectionToken('firebaseConfig');

export interface SaeFirebaseConfig {
  dev: FirebaseOptions;
  test: FirebaseOptions;
  prod: FirebaseOptions;
}

export class FirebaseConfig {
  dev: FirebaseOptions;
  test: FirebaseOptions;
  prod: FirebaseOptions;
  constructor(config: SaeFirebaseConfig) {
    this.dev = config.dev;
    this.test = config.test;
    this.prod = config.prod;
  }

  getEnv(envKey: SaeEnvironment): FirebaseOptions {
    const valueIndex = Object.keys(this).findIndex((k) => k === envKey);
    return Object.values(this)[valueIndex];
  }
}

@Injectable({ providedIn: 'root' })
export class AppInitCoreService {
  public static currentEnv: SaeEnvironment;
  public static baseUrl = '';

  protected ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig;
  protected rccs: IRemoteConfigService;
  protected ffcs: IFeatureFlagService;
  protected acs: IAuthService;
  protected ses: ISessionExpiryService;

  public static getEnvBasedOnOrigin(localHostEnv: SaeEnvironment = SaeEnvironment.dev): SaeEnvironment {
    if (AppInitCoreService.currentEnv) {
      return AppInitCoreService.currentEnv;
    }
    AppInitCoreService.currentEnv = window.location.origin.includes(SaeEnvironment.localhost)
      ? localHostEnv
      : window.location.origin.includes(SaeEnvironment.dev)
      ? SaeEnvironment.dev
      : window.location.origin.includes(SaeEnvironment.test)
      ? SaeEnvironment.test
      : window.location.origin.includes(SaeEnvironment.beta)
      ? SaeEnvironment.prod // someday this could be 'beta'? -- JJV
      : SaeEnvironment.prod;
    return AppInitCoreService.currentEnv;
  }

  constructor(
    private readonly apmRumService: ApmRumService,
    private readonly apmService: ApmService,
    private readonly router: Router,
    protected readonly injector: Injector
  ) {}
  public async initialize(localHostEnv: SaeEnvironment = SaeEnvironment.dev): Promise<void> {
    proxyFirebaseRemoteConfigCalls();
    const currentEnv = AppInitCoreService.getEnvBasedOnOrigin(localHostEnv);
    console.log('Current Env: ', currentEnv);
    const rcSup = await rcSupported();
    const analyticsSup = await analyticsSupported();
    if (currentEnv && rcSup && analyticsSup) {
      this.ngEntityServiceGlobalConfig = this.injector.get<NgEntityServiceGlobalConfig>(NG_ENTITY_SERVICE_CONFIG);
      this.rccs = this.injector.get<IRemoteConfigService>(REMOTE_CONFIG_TOKEN);
      this.ffcs = this.injector.get<IFeatureFlagService>(FEATURE_FLAG_TOKEN);
      this.acs = this.injector.get<IAuthService>(AUTH_TOKEN);
      this.ses = this.injector.get<ISessionExpiryService>(SESSION_EXPIRY_TOKEN, null);

      // below will throw if we could not get a remote config or even use default,
      // which will prevent the application from loading, which is presumably
      // what we want, but if not you can catch the error and deal with it.
      const remoteConfigSettings = await this.rccs.initAndFetchConfig();
      await this.ffcs.extractFeatureFlagsToStore();
      if (remoteConfigSettings) {
        this.ngEntityServiceGlobalConfig.baseUrl = remoteConfigSettings?.services.apiRootUrl;
        await this.acs.initialize(remoteConfigSettings);
        this.setupAPM(remoteConfigSettings, currentEnv);
        if (this.ses) {
          this.ses.initialize(remoteConfigSettings);
        }
      }
    }
  }

  protected setupAPM(remoteConfigSettings: Config, currentEnv: string): void {
    if (remoteConfigSettings.metrics.useApm) {
      if (!remoteConfigSettings.metrics.apmServiceName || !remoteConfigSettings.metrics.apm) {
        throw Error('Missing APM remote config settings!');
      }
      this.apmRumService.apm = this.apmService.init({
        serviceName: remoteConfigSettings.metrics.apmServiceName,
        serverUrl: remoteConfigSettings.metrics.apm,
        distributedTracingOrigins: [
          // NOTE: distributedTracingOrigins is the base of the root url - we must remove the '/api'
          remoteConfigSettings.services.apiRootUrl.substring(0, remoteConfigSettings.services.apiRootUrl.length - 4),
        ],
        environment: currentEnv,
      });

      this.apmRumService.apm.observe('transaction:start', (transaction) => {
        // we must add a span in order for the user-interaction transaction to be captured by APM
        // timeout is necesary because elastic will discard a transaction with no duration
        if (transaction.type === 'user-interaction' && remoteConfigSettings.metrics.apmUserInteractions) {
          const uiSpan = transaction.startSpan('ui-span', 'app');
          setTimeout(() => {
            transaction.end();
            uiSpan?.end();
          }, 0);
        }
      });

      this.apmRumService.apm.observe('transaction:end', (transaction) => {
        if (transaction.type === 'http-request') {
          const url = transaction.name.split(' ');
          if (url.length > 1) {
            transaction.name = url[0].concat(' ', url[1].split('/').slice(0, -1).join('/'));
          } else {
            transaction.name = url[0].split('/').slice(0, -1).join('/');
          }
        }
        if (transaction.type === 'route-change' && transaction.name === 'Unknown') {
          transaction.name = '/' + this.router.url.split('/').slice(3, -1).join('/');
        }
      });
    }
  }
}
