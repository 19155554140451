import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { UserProfile } from '../../../../models/src/lib/user-profile.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-profile' })
export class UserProfileStore extends EntityStore<UserProfile> {
  constructor() {
    super({});
  }
}
