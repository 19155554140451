import { Component, Input, OnInit } from '@angular/core';
import { catchError, finalize, switchMap } from 'rxjs';
import { DetailsHelperService } from '../../../../services/details-helper.service';
import { ContentService } from '../../../../../../shared/state/content.service';

@Component({
  selector: 'mobi-redline-view',
  templateUrl: './redline-view.component.html',
})
export class RedlineViewComponent implements OnInit {
  @Input() private contentId: number;
  @Input() private compareAgainstId: number;

  public error = false;
  public loading = false;
  constructor(private contentService: ContentService, private detailsHelperService: DetailsHelperService) {}

  ngOnInit(): void {
    this.getRedlining();
  }

  getRedlining(): void {
    this.loading = true;
    this.error = false;
    const getRedlining = this.contentService
      .getRedlinedView(this.contentId, this.compareAgainstId)
      .pipe(
        switchMap((html) =>
          this.detailsHelperService.buildAndReplaceHTML(html, 'redline-view', this.contentId, this.compareAgainstId)
        )
      );
    getRedlining
      .pipe(
        catchError((e) => {
          this.error = true;
          return e;
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  retryAfterError(): void {
    this.getRedlining();
  }
}
