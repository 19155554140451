<ng-container *ngIf="{} as _">
  <div #detailContent class="si-col si-col--detail" cdkScrollable>
    <div libDetectBreakpointWidth>
      <div class="si-content">
        <ng-container *ngIf="!isLoading">
          <div class="si-content__h">
            <fs-ui-header-masthead class="si-masth__wrap si-impact" [content]="headingContent"></fs-ui-header-masthead>
          </div>
          <div class="si-content__c">
            <div class="si-content__detail si-follows--tab">
              <ng-container *ngIf="!isInvitationExpired && !invitationNotFound && !isNonInvitedUser">
                <fs-part-text class="si-p" [text]="invitationText"></fs-part-text>

                <fs-comp-button-group>
                  <fs-part-button type="secondary" label="Reject" (buttonClick)="rejectInvitation()"></fs-part-button>
                  <fs-part-button type="primary" label="Accept" (buttonClick)="acceptInvitation()"></fs-part-button>
                </fs-comp-button-group>
              </ng-container>
              <ng-container *ngIf="isInvitationExpired || invitationNotFound || isNonInvitedUser">
                <fs-part-text class="si-p" [text]="invitationText"></fs-part-text>

                <fs-comp-button-group>
                  <fs-part-button type="primary" [url]="'/'" label="Go to Homepage"></fs-part-button>
                </fs-comp-button-group>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div *ngIf="isLoading" class="si-spinner__container">
          <fs-part-spinner full="true"></fs-part-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-container>
