import { Feature } from '@sae/features';

export const baseURL = '';

export const mandatoryFeatures: Array<MobilusFeature> = [
  {
    name: 'Browse',
    icon: 'layers',
    url: baseURL + '/browse',
    id: 'browseNavBtn',
    analyticsId: 'browse-nav-btn',
  },
];

export const myLibraryFeature: MobilusFeature = {
  name: 'My Library',
  icon: 'library_books',
  url: baseURL + '/mylibrary',
  id: 'myLibraryNavBtn',
  analyticsId: 'my-library-nav-btn',
};

export const sharedFeature: MobilusFeature = {
  name: 'Shared',
  icon: 'folder_shared',
  url: baseURL + '/shared',
  id: 'sharedNavBtn',
  analyticsId: 'shared-nav-btn',
};

export const subscribedFeature: MobilusFeature = {
  name: 'Subscription',
  icon: 'verified_user',
  url: baseURL + '/subscription',
  id: 'subscriptionNavBtn',
  analyticsId: 'subscription-nav-btn',
};
export const batteryDB: MobilusFeature = {
  name: 'Data',
  icon: 'analytics',
  url: baseURL + '/battery-cell/search',
  id: 'batteryDbNavBtn',
  analyticsId: 'batteryDb-nav-btn',
};

export interface MobilusFeature extends Feature {
  id?: string;
}
