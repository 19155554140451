<ng-container>
  <div class="si-dialog">
    <div class="si-dialog__h">
      <h2 class="si-dialog__title">Request Change for {{ requestAccessSubName }}</h2>
      <span class="si-expanse"></span>
      <button mat-icon-button matTooltip="Close" aria-label="Close" class="si-icon si-button--close" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="si-dialog__b">
      <div mat-dialog-content class="si-dialog__c si-major">
        <div class="si-spinner__container" *ngIf="requestSubmitted === true">
          <mat-spinner class="si-spinner"></mat-spinner>
        </div>
        <form class="si-form" *ngIf="!requestSubmitted" [formGroup]="requestAccessForm">
          <div class="si-form__b">
            <si-data-output
              class="si-field si-wd si-wd-full"
              [title]="'Title'"
              [content]="data?.title"
            ></si-data-output>
            <mat-form-field class="si-field si-wd si-wd-fourth">
              <input matInput placeholder="First Name" class="si-input" required formControlName="firstName" />
              <mat-error *ngIf="requestAccessForm.get('firstName').hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="si-field si-wd si-wd-fourth">
              <input matInput placeholder="Last Name" class="si-input" required formControlName="lastName" />
              <mat-error *ngIf="requestAccessForm.get('lastName').hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="si-field si-wd si-wd-fourth">
              <input matInput placeholder="Phone" class="si-input" required formControlName="phone" />
              <mat-error *ngIf="requestAccessForm.get('phone').hasError('required')">This field is required</mat-error>
            </mat-form-field>
            <mat-form-field class="si-field si-wd si-wd-fourth">
              <input matInput placeholder="Email" class="si-input" type="email" required formControlName="email" />
              <mat-error *ngIf="requestAccessForm.get('email').hasError('required')">This field is required</mat-error>
              <mat-error *ngIf="requestAccessForm.get('email').hasError('email')">
                You must enter a valid email address
              </mat-error>
            </mat-form-field>
            <mat-form-field class="si-field si-wd si-wd-full" appearance="outline">
              <mat-label class="si-label">Request Justification</mat-label>
              <textarea
                matInput
                class="si-textarea"
                cdkTextareaAutosize
                required
                #justification
                maxlength="1000"
                formControlName="justification"
              ></textarea>
              <mat-hint class="si-field__hint si-state--unusual" align="end">
                {{ justification?.value?.length ?? 0 }} / 1000
              </mat-hint>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="si-form__a si-form__a--unusual" *ngIf="!requestSubmitted">
        <button
          mat-flat-button
          class="si-button si-button--primary"
          (click)="submitRequestAccessForm()"
          [disabled]="requestAccessForm.invalid"
        >
          Send Request
        </button>
      </div>
    </div>
  </div>
</ng-container>
