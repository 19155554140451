import { NgModule } from '@angular/core';
import { StartSessionComponent } from './start-session.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '@sae/material';

@NgModule({
  declarations: [StartSessionComponent],
  imports: [CommonModule, SharedModule, MaterialModule],
})
export class StartSessionModule {}
