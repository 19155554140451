import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { Router, RouterModule } from '@angular/router';
import { DetailViewBase } from '@sae/features';
import { SharedUtilitiesModule } from '@sae/utilities';
import { catchError } from 'rxjs';
import { SearchManagerUiService } from '../../shared/services/search-manager-ui.service';
import { SessionService } from '../../shared/state/session.service';
import { NgForOf, NgIf } from '@angular/common';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'mobi-not-found',
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [
    MatLegacyFormFieldModule,
    MatLegacyAutocompleteModule,
    SharedUtilitiesModule,
    NgForOf,
    NgIf,
    MatLegacyInputModule,
    MatIconModule,
    MatLegacyButtonModule,
    RouterModule,
  ],
})
export class NotFoundComponent extends DetailViewBase {
  public suggestionText = 'Enter keyword, author, topic...'; // Suggestion text to display in an empty search box.
  public minChars = 3;
  public debounceTime = 400;
  public type!: string;
  public defaultSearch = '';
  @ViewChild('searchInput') searchInput!: ElementRef;

  suggestions = [];

  constructor(
    protected sessionService: SessionService,
    private smService: SearchManagerUiService,
    private router: Router,
    private titleService: Title
  ) {
    super(sessionService);
    this.titleService.setTitle('Page Not Found - SAE Mobilus');
  }

  emitSearch(term: string): void {
    if (term.length < 2) {
      this.suggestions = [];
      return;
    } else {
      this.smService
        .getSuggestionsV2(term)
        .pipe(
          catchError(() => {
            this.suggestions = [];
            return [];
          })
        )
        .subscribe((suggestions) => {
          this.suggestions = suggestions;
        });
    }
  }

  keyChecked(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.performSearch(this.searchInput.nativeElement?.value);
    }
  }

  performSearch(term): void {
    this.router.navigateByUrl(term ? `search#q=${encodeURIComponent(term)}` : `search`);
  }

  public clearSearch(): void {
    this.searchInput.nativeElement.value = '';
    const event = new Event('change');
    this.searchInput.nativeElement.dispatchEvent(event);
  }
}
