import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DetailViewBase } from '@sae/features';
import { SessionService } from '../../shared/state/session.service';
import { Title } from '@angular/platform-browser';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  CompButtonGroupComponent,
  HeaderMastheadContent,
  PartButtonComponent,
  PartSpinnerComponent,
  UIHeaderMastheadComponent,
  PartTextComponent,
  TextContent,
} from '@sae/atomic-ui';
import { NgIf } from '@angular/common';
import { InvitationService } from '../../shared/services/invitation.service';
import { catchError, map, of, switchMap, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Invitation } from '../../shared/models/api-models';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'mobi-folder-invitation',
  templateUrl: './folder-invitation.component.html',
  standalone: true,
  imports: [
    RouterModule,
    UIHeaderMastheadComponent,
    NgIf,
    PartSpinnerComponent,
    CompButtonGroupComponent,
    PartButtonComponent,
    PartTextComponent,
  ],
})
export class FolderInvitationComponent extends DetailViewBase implements OnInit {
  public isLoading: boolean;
  public headingContent: HeaderMastheadContent;
  private invitationId: string;
  private invitationText: TextContent;
  private invitation: Invitation;
  private folderName: string;
  public isInvitationExpired: boolean;
  public invitationNotFound: boolean;
  public isNonInvitedUser: boolean;
  constructor(
    protected sessionService: SessionService,
    private router: Router,
    private titleService: Title,
    private invitationService: InvitationService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    super(sessionService);
    this.titleService.setTitle('Folder Collaboration Invitation - SAE Mobilus');
    this.invitationId = route.snapshot.params['invitationId'];
  }
  ngOnInit(): void {
    this.headingContent = {
      heading: [
        {
          text: 'SAE MOBILUS Folder Collaboration Invitation',
        },
      ],
    };
    this.isLoading = true;
    this.invitationService
      .getInvite(this.invitationId)
      .pipe(
        untilDestroyed(this),
        switchMap((invitation) => {
          if (!['PENDING', 'VIEWED', 'RESENT'].includes(invitation.status)) {
            this.isInvitationExpired = true;
            this.isLoading = false;
            this.invitationText = {
              text: `Invitation expired. Contact <strong>${invitation?.invitor?.givenName} ${invitation?.invitor?.familyName}</strong>`,
              another: {
                text: `(${invitation?.invitor?.emailAddress})`,
                url: `mailto:${invitation?.invitor?.emailAddress}`,
                isStrong: true,
                another: {
                  spaceBefore: true,
                  text: `to request a new invite.`,
                },
              },
            };

            return of([]);
          }
          return this.invitationService.getInvitationGroupDetail(invitation.anchor).pipe(
            map((group) => {
              this.invitation = invitation;
              this.folderName = group?.name?.substring(0, group?.name?.lastIndexOf('_'));
              this.isLoading = false;
              this.invitationText = {
                text: `You've been invited to collaborate on <strong>${this.folderName}</strong> folder by <strong>${invitation?.invitor?.givenName} ${invitation?.invitor?.familyName}</strong>`,
                another: {
                  text: `(${invitation?.invitor?.emailAddress})`,
                  url: `mailto:${invitation?.invitor?.emailAddress}`,
                  isStrong: true,
                  another: {
                    spaceBefore: true,
                    text: `on SAE MOBILUS platform. Accept to collaborate or Reject.`,
                  },
                },
              };
            }),
            catchError((error: HttpErrorResponse) => {
              this.handleError(error);
              return throwError(error);
            })
          );
        }),
        catchError((error: HttpErrorResponse) => {
          this.handleError(error);
          return throwError(error);
        })
      )
      .subscribe();
  }

  acceptInvitation(): void {
    this.isLoading = true;
    this.invitationService
      .patchInvite(this.invitation.id, { status: 'ACCEPTED' })
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.snackbar.open('Something went wrong', undefined, { duration: 4500 });
          return error;
        })
      )
      .subscribe((resp) => {
        setTimeout(() => {
          this.isLoading = false;
          this.snackbar.open(`${this.folderName} folder collaboration accepted succesfully`, undefined, {
            duration: 4500,
          });
          this.router.navigateByUrl(`mylibrary/folders/${this.folderName?.toLowerCase().replace(/( )/gi, '-')}`);
        }, 3000);
      });
  }

  rejectInvitation(): void {
    this.invitationService
      .patchInvite(this.invitation.id, { status: 'REJECTED' })
      .pipe(
        map((resp) => {
          this.snackbar.open(`${this.folderName} folder collaboration rejected`, undefined, {
            duration: 4500,
          });
          return resp;
        }),
        catchError((error) => {
          this.snackbar.open('Something went wrong', undefined, { duration: 4500 });
          return error;
        })
      )
      .subscribe((resp) => {
        this.router.navigateByUrl(`mylibrary`);
      });
  }

  private handleError(error: HttpErrorResponse): void {
    if (error.status === 403) {
      this.invitationText = {
        text: 'Invitation Access Denied.You do not have permission to access this invitation.',
        state: 'negative',
      };
      this.isNonInvitedUser = true;
    } else {
      this.invitationText = {
        text: 'We could not find the invitation you are looking for. Please check the link and try again.',
      };
      this.invitationNotFound = true;
    }
    this.isLoading = false;
  }
}
