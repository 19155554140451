import { SortDirection } from '@angular/material/sort';
import { Hash } from '@sae/models';
import { Criteria } from '../../modules/search/components/advanced-search/advanced-search.component';
import { BatteryCell } from './battery-cell.model';

interface AssetUser {
  id: string;
  username: string;
  emailAddress: string;
  nickname: string;
  givenName: string;
  familyName: string;
}

export interface Asset {
  id?: string;
  contentId: number;
  ingestionId: string;
  filename: string;
  path: string;
  searchType: string;
  size: number;
  hashes: Hash[];
  fileType: string;
  mimeType: string;
  revisionNo: number;
  priority: number;
  formatPubType: string;
  code: string;
  folder: string;
  fileDate: Date;
  exemptWatermark: boolean;
  title: string;
  description: string;
  createdBy: AssetUser;
  createdDate: Date;
  modifiedBy: AssetUser;
  modifiedDate: Date;
}

export interface DocumentAsset {
  assetId: string;
  folder: string;
  fileName: string;
  searchType: string;
  revisionNo: number;
  size: number;
  fileDate: Date;
  pageCount: number;
  title: string;
  description: string;
  priority: number;
  formatPubType: string;
  mimeType: string;
  exemptWatermark: boolean;
}

export interface Resource {
  id: string;
  name: string;
  creationDate: Date;
  resourceType: 'FOLDER' | 'FILE' | 'LINK' | 'TOPLEVEL' | 'PUBLICATION';
  anchorId: string;
  shared: boolean;
  code: string;
  rootCode: string;
  title: string;
  status: string;
  resources: Resource[];
  access: 'OWNER' | 'VIEW' | 'VIEW_ADD_DELETE';
  url?: string;
  seoUri: string;
  subGroup: string;
  cid?: string;
  alertId?: string;
  publicationId?: string;
}
export interface IdentityProvider {
  id: string;
  name: string;
  entityId: string;
}

export interface UsageEvent {
  id?: string;
  contentIds?: number[];
  criteriaDetailsId?: string;
  eventType: string;
}

// Search V2 Models

interface AppSearchRequestPage {
  size?: number; // 1 <= size <= 1000
  current?: number; // 1 <= current <= 100
}

interface AppSearchSortOrder {
  [key: string]: SortDirection;
}

interface AppSearchRequestGroup {
  field: string;
  size?: number; // 1 <= size <= 10
  sort?: AppSearchSortOrder;
  collapse?: boolean;
}

interface AppSearchRequestAnalytics {
  tags?: string[];
}

interface AppSearchRequestFacet {
  type: 'value' | 'range';
  name?: string;
  sort?: { count: SortDirection };
  size?: number;
  ranges?: { from: Date; to: Date }[];
}

export const AggregatedSearchRequestFacets: { [key: string]: AppSearchRequestFacet[] } = {
  collection_title: [
    {
      type: 'value',
      name: 'collection_title',
      size: 200,
    },
  ],
  industrysectors_name: [
    {
      type: 'value',
      name: 'industrysectors_name',
      size: 200,
    },
  ],
  sub_group: [
    {
      type: 'value',
      name: 'sub_group',
      size: 200,
    },
  ],
  topics: [
    {
      type: 'value',
      name: 'topics',
      size: 200,
    },
  ],
  authors: [
    {
      type: 'value',
      name: 'authors',
      size: 200,
    },
  ],
  affiliations: [
    {
      type: 'value',
      name: 'affiliations',
      size: 200,
    },
  ],
  // committee_name: [
  //   {
  //     type: 'value',
  //     name: 'committee_names',
  //   },
  // ],
  publisher_name: [
    {
      type: 'value',
      name: 'publisher_name',
      size: 200,
    },
  ],
  conference_name: [
    {
      type: 'value',
      name: 'conference_name',
      size: 200,
    },
  ],
  full_text_formats: [
    {
      type: 'value',
      name: 'full_text_formats',
      size: 200,
    },
  ],
  features: [
    {
      type: 'value',
      name: 'features',
      size: 200,
    },
  ],
  total: [
    {
      type: 'value',
      name: 'total',
      size: 200,
    },
  ],
};

export const BatteryDBSearchRequestFacets: { [key: string]: AppSearchRequestFacet[] } = {
  supplierName: [
    {
      type: 'value',
      size: 200,
    },
  ],
  cellFormat: [
    {
      type: 'value',
      size: 200,
    },
  ],
  length: [
    {
      type: 'value',
      size: 200,
    },
  ],
  height: [
    {
      type: 'value',
      size: 200,
    },
  ],
  width: [
    {
      type: 'value',
      size: 200,
    },
  ],
};

export interface AppSearchRequestCustomFilter {
  [key: string]: [{ type: string; name: string; size: number }];
}

interface AppSearchRequestBoost {
  type?: 'functional' | 'value' | 'proximity';
  factor?: number;
}

interface AppSearchRequestSearchField {
  weight?: number;
}

interface AppSearchRequestResultField {
  raw?: unknown;
  snippet?: unknown;
}

export interface AppSearchRequestFilter {
  [key: string]: string[];
}

export interface AppSearchCombinedFilters {
  all?: AppSearchRequestFilter[];
  none?: AppSearchRequestFilter[];
  any?: AppSearchRequestFilter[];
}

export interface AppSearchSearchRequest {
  query: string;
  page?: AppSearchRequestPage;
  sort?: AppSearchSortOrder[];
  group?: AppSearchRequestGroup;
  precision?: number; // 1 <= precision <= 11
  tags?: AppSearchRequestAnalytics;
  facets?: {
    [key: string]: AppSearchRequestFacet[];
  };
  boosts?: {
    [key: string]: AppSearchRequestBoost;
  };
  search_fields?: {
    [key: string]: AppSearchRequestSearchField;
  };
  result_fields?: {
    [key: string]: AppSearchRequestResultField;
  };
  record_analytics?: boolean;
  filters?: AppSearchCombinedFilters;
  custom_filters?: AppSearchRequestCustomFilter;
  advancedSearchCriteriaList?: Criteria[];
}

interface AppSearchResponsePage {
  total_pages?: number;
  size?: number; // 1 <= size <= 1000
  current?: number; // 1 <= current <= 100
  total_results?: number;
}

interface AppSearchEngine {
  name?: string;
  type?: string;
}

export interface AppSearchResponseMeta {
  alerts?: string[];
  warnings?: string[];
  precision?: number;
  page?: AppSearchResponsePage;
  engine?: AppSearchEngine;
  request_id?: string;
}

export interface AppSearchResponseMetaField {
  score?: number;
  engine?: string;
  id?: string;
}

export interface AppSearchResponseDocument {
  _meta?: AppSearchResponseMetaField;
  _group_key?: string;
}

export interface AppSearchResponseTextField {
  raw?: string;
  snippet?: string;
}

export interface AppSearchResponseTextArrayField {
  raw?: string[];
  snippet?: string;
}

export interface AppSearchResponseDateField {
  raw?: Date;
}

export interface AppSearchResponseNumberField {
  raw?: number;
}

export interface MeterDetails {
  requestContentAdmin?: boolean;
  metered: boolean;
  requestContentRestricted?: boolean;
  quantity: number;
  quantityRemaining: number;
}

interface AccessDetails {
  userId?: string;
  subscriptionId?: string;
  collectionId?: string;
  collectionName?: string;
}

export interface AccessControlResult {
  contentId?: number;
  access: boolean;
  openAccess: boolean;
  access3D: boolean;
  meterDetails: MeterDetails;
  accessDetails?: AccessDetails;
  userId?: string;
  appliedDateTime?: Date;
}

interface Bookmark {
  resourceId: string;
  folderId: string;
  folderName: string;
  shared: boolean;
}

export interface MobilusPubDocument {
  _meta?: AppSearchResponseMetaField;
  _group?: AppSearchResponseDocument[];
  _group_key?: string;
  id?: AppSearchResponseTextField;
  cid?: AppSearchResponseTextField;
  product_code?: AppSearchResponseTextField;
  abstract?: AppSearchResponseTextField;
  abstract_clean?: AppSearchResponseTextField;
  title?: AppSearchResponseTextField;
  sub_title?: AppSearchResponseTextField;
  sub_group?: AppSearchResponseTextField;
  seo_uri?: AppSearchResponseTextField;
  canonical_url?: AppSearchResponseTextField;
  document_type?: AppSearchResponseTextField;
  publication_type?: AppSearchResponseTextField;
  pub_date?: AppSearchResponseDateField;
  publication_id?: AppSearchResponseTextField;
  conference_location?: AppSearchResponseTextField;
  conference_state?: AppSearchResponseTextField;
  conference_startdate?: AppSearchResponseDateField;
  industrysectors_name?: AppSearchResponseTextArrayField;
  codes?: AppSearchResponseTextArrayField;
  list_price_us_cents?: AppSearchResponseNumberField;
  committee_name?: AppSearchResponseTextField;
  full_text?: AppSearchResponseTextField;
  conference_name?: AppSearchResponseTextField;
  documentasset_type?: AppSearchResponseTextField;
  group?: AppSearchResponseTextField;
  commercetools_id?: AppSearchResponseTextField;
  conference_country?: AppSearchResponseTextField;
  conference_date?: AppSearchResponseDateField;
  revision_status?: AppSearchResponseTextField;
  committee_code?: AppSearchResponseTextField;
  commercetools_sku?: AppSearchResponseTextField;
  conference_enddate?: AppSearchResponseDateField;
  conference_city?: AppSearchResponseTextField;
  collection_category?: AppSearchResponseTextArrayField;
  collection_title?: AppSearchResponseTextArrayField;
  collection_ids?: AppSearchResponseTextArrayField;
  taxonomy_level_1?: AppSearchResponseTextArrayField;
  taxonomy_level_2?: AppSearchResponseTextArrayField;
  taxonomy_level_3?: AppSearchResponseTextArrayField;
  taxonomy_level_4?: AppSearchResponseTextArrayField;
  taxonomy_level_5?: AppSearchResponseTextArrayField;
  taxonomy_level_6?: AppSearchResponseTextArrayField;
  topics?: AppSearchResponseTextArrayField;
  full_text_formats?: AppSearchResponseTextArrayField;
  authors?: AppSearchResponseTextArrayField;
  publisher_name?: AppSearchResponseTextField;
  publisher_country?: AppSearchResponseTextField;
  affiliations?: AppSearchResponseTextArrayField;
  features?: AppSearchResponseTextArrayField;
  doi?: AppSearchResponseTextField;
  accessProperties?: AccessControlResult;
  bookmarks?: Bookmark[];
  hasAnnotation?: boolean;
  mobilus_platform_uri?: AppSearchResponseTextField;
  periodical_issue?: AppSearchResponseTextField;
  periodical_volume?: AppSearchResponseTextField;
  periodical_code?: AppSearchResponseTextField;
  alertId?: string;
}

export interface PublicationDocument {
  _meta?: AppSearchResponseMetaField;
  _group?: AppSearchResponseDocument[];
  _group_key?: string;
  id?: string;
  cid?: string;
  product_code?: string;
  abstract?: string;
  abstract_clean?: string;
  title?: string;
  sub_title?: string;
  content_type?: string;
  seo_uri?: string;
  canonical_url?: string;
  document_type?: string;
  publication_type?: string;
  pub_date?: Date;
  publication_id?: string;
  conference_location?: string;
  conference_state?: string;
  conference_startdate?: Date;
  industrysectors_name?: string[];
  codes?: string[];
  list_price_us_cents?: number;
  committee_name?: string;
  full_text?: string;
  conference_name?: string;
  documentasset_type?: string;
  categories?: string;
  commercetools_id?: string;
  conference_country?: string;
  conference_date?: Date;
  standards_document_status?: string;
  committee_code?: string;
  commercetools_sku?: string;
  conference_enddate?: Date;
  conference_city?: string;
  collection_category?: string[];
  collection_title?: string[];
  collection_ids?: string[];
  taxonomy_level_1?: string[];
  taxonomy_level_2?: string[];
  taxonomy_level_3?: string[];
  taxonomy_level_4?: string[];
  taxonomy_level_5?: string[];
  taxonomy_level_6?: string[];
  topics?: string[];
  full_text_formats?: string[];
  authors?: string[];
  publisher_name?: string;
  publisher_country?: string;
  affiliations?: string[];
  features?: string[];
  doi?: string;
  accessProperties?: AccessControlResult;
  bookmarks?: Bookmark[];
  hasAnnotation?: boolean;
}

export interface AppSearchResponseGenericFacet<TData> {
  type?: string;
  name?: string;
  data?: TData[];
}

export interface ValueFacetData {
  value: string;
  count: number;
}
[];

export interface RangeFacetData {
  to: Date;
  from: Date;
  count: number;
}

export interface AppSearchResponseValueFacet extends AppSearchResponseGenericFacet<ValueFacetData> {
  type: 'value';
}

export interface AppSearchResponseRangeFacet extends AppSearchResponseGenericFacet<RangeFacetData> {
  type: 'range';
}

export type AppSearchResponseFacet = AppSearchResponseValueFacet | AppSearchResponseRangeFacet;

export interface MobilusSearchResponse {
  meta?: AppSearchResponseMeta;
  results?: MobilusPubDocument[];
  errors?: string[];
  facets?: {
    [key: string]: AppSearchResponseFacet[];
  };
}
export interface BatteryDBSearchResponse {
  meta?: AppSearchResponseMeta;
  results?: BatteryCell[];
  errors?: string[];
  facets?: {
    [key: string]: AppSearchResponseFacet[];
  };
}
export interface MobilusSearchSuggestionResponse {
  meta?: AppSearchResponseMeta;
  results?: { documents: Array<{ suggestion: string }> };
  errors?: string[];
  statusCode?: number;
  statusMessage?: string;
}

export interface GainsightResult {
  user: {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    signUpDate: number;
    userHash: string;
  };
  account: {
    id: string;
    name: string;
  };
}

// Announcements
export interface Announcement {
  actionText: string;
  applicationCode: string;
  attachmentType: string;
  description: string; // This includes HTML tags
  documentPath: string;
  documentUUID: string;
  title: string;
  type: string;
  url: string;
}

export interface BrxMenuNode {
  childrenNodes: BrxMenuNode[];
  name: string;
  parameters: { [key: string]: string };
  primaryType: string;
  properties: { [key: string]: string };
}

export interface DocumentCounts {
  aerospaceIndustry: number;
  allPubs: number;
  automotiveIndustry: number;
  commercialVehicleIndustry: number;
  compContent: number;
  governmentDefenseIndustry: number;
  medicalIndustry: number;
  peerReviewed: number;
  recentlyPublished: number;
  standards: number;
}

export interface BrxJournalData {
  authorResources?: string;
  aimsAndScope?: string;
  manuscriptUrl?: string;
  subscriptionOptions?: string;
  journalImage?: string[];
  subTitle?: string;
  magazineCode?: string;
  seoSubTitle?: string;
  publicationDate?: string;
  issn?: string;
  eissn?: string;
  editorialBoard?: string;
  indexedBy?: string;
  editorInChief?: string;
}

export interface UserFolderAccess {
  userId: string;
  name: string;
  email: string;
  access: string;
}

export interface Invitation {
  id: string;
  applicationCode: string;
  anchor: string;
  invitee: {
    id: string;
    givenName: string;
    familyName: string;
    emailAddress: string;
  };
  inviteLink: string;
  status: 'PENDING' | 'SENT' | 'VIEWED' | 'ACCEPTED' | 'REJECTED' | 'EXPIRED' | 'RESENT' | 'DELETED';
  invitor: {
    id: string;
    givenName: string;
    familyName: string;
    emailAddress: string;
  };
  attributes: Array<{
    type: string;
    attribute: string;
  }>;

  modifiedDate: Date;
}
