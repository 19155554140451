import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AutocompleteOption, Resource, ResourcePermissionTypes, ResourceShare, ResourceShareDialogData, ResourceShareDialogResult } from '@sae/models';
import { IResourcesService, RESOURCES_TOKEN } from '@sae/services';
import { forkJoin, Observable } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

@Component({
  selector: 'si-resource-share',
  templateUrl: './resource-share.component.html',
})
export class ResourceShareComponent implements OnInit {
  shareOptions: AutocompleteOption[] = [];
  resource: Resource;
  shareForm: FormGroup;
  loading: boolean;
  readonly = true;
  disableSaveButton = true;
  shareLabel = 'Group Share';

  public dialogResult$ = new EventEmitter<ResourceShareDialogResult>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<ResourceShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceShareDialogData,
    @Inject(RESOURCES_TOKEN) private readonly resourcesService: IResourcesService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.shareOptions = this.data.shareOptions;
    if (this.data.shareType === 'committee') {
      this.shareLabel = 'Committee Share';
    }
    this.resourcesService
      .getResource(this.data.rootResourceId)
      .pipe(
        catchError((err) => {
          this.snackbar.open('An error occurred when retrieving the resource.', 'DISMISS', { duration: 3000 });
          this.loading = false;
          throw err;
        })
      )
      .subscribe((r) => {
        this.resource = r;
        if (this.resourcesService.getHasPermission(r, ResourcePermissionTypes.SHARE)) {
          this.readonly = false;
        }
        this.setupShareForm();
        this.loading = false;
      });
  }

  private setupShareForm(): void {
    const preselect: AutocompleteOption[] = [];
    if (this.resource.resourceShares) {
      this.resource.resourceShares.forEach((rs) => {
        preselect.push(this.convertShareToChip(rs));
      });
    }
    if (preselect.length) {
      // if any preselect is not found in shareOpts, add it
      const existingOptIds: string[] = this.shareOptions ? this.shareOptions.map((o) => o.id) : [];
      const optsToAdd: AutocompleteOption[] = preselect.filter((o) => {
        return !existingOptIds.includes(o.id);
      });
      if (optsToAdd.length && this.shareOptions) {
        this.shareOptions = this.shareOptions.concat(optsToAdd).sort((a, b) => (a.title > b.title ? 1 : -1));
      }
    }
    this.shareForm = this.formBuilder.group({
      groups: [preselect],
    });
    this.shareForm.valueChanges.subscribe(() => {
      // form is considered valid as soon as a value is changed
      this.disableSaveButton = false;
    });
  }

  saveChanges(): void {
    this.loading = true;
    this.dialogRef.disableClose = true;
    const values = this.shareForm.get('groups').value as AutocompleteOption[];
    const existingShares: ResourceShare[] = this.resource.resourceShares ?? [];
    const existingTypeIds = existingShares.map((r) => r.typeId);
    const newShares: ResourceShare[] = values.map((v) => this.convertChipToShare(v));
    const newTypeIds = newShares.map((r) => r.typeId);
    const sharesToAdd = newShares.filter((x) => {
      return !existingTypeIds.includes(x.typeId);
    });
    const sharesToDelete = existingShares.filter((x) => {
      return !newTypeIds.includes(x.typeId);
    });
    if (!sharesToAdd.length && !sharesToDelete.length) {
      this.dialogResult$.emit();
      this.dialogRef.close();
    } else {
      forkJoin(this.createRequests(sharesToAdd, sharesToDelete))
        .pipe(
          catchError((err) => {
            this.snackbar.open('An error occurred when saving changes.', 'DISMISS', { duration: 3000 });
            this.loading = false;
            this.dialogRef.disableClose = false;
            this.resourcesService
              .getResource(this.data.rootResourceId)
              .pipe(delay(1000))
              .subscribe((r) => {
                this.resource = r;
                this.setupShareForm();
              });
            throw err;
          })
        )
        .subscribe(() => {
          const result: ResourceShareDialogResult = {
            previousShares: existingShares,
            newShares: newShares,
            addedShares: sharesToAdd,
            removedShares: sharesToDelete,
          };
          this.dialogResult$.emit(result);
          this.dialogRef.close();
        });
    }
  }

  dismiss(): void {
    if (!this.dialogRef.disableClose) {
      this.dialogResult$.emit();
      this.dialogRef.close();
    }
  }

  private convertChipToShare(chip: AutocompleteOption): ResourceShare {
    return {
      name: chip.title,
      typeId: chip.id,
      type: this.data.shareType,
    };
  }

  private convertShareToChip(share: ResourceShare): AutocompleteOption {
    return {
      title: share.name,
      id: share.typeId,
    };
  }

  private createRequests(sharesToAdd: ResourceShare[], sharesToDelete: ResourceShare[]): Array<Observable<unknown>> {
    const requests: Array<Observable<unknown>> = [];
    sharesToAdd.forEach((s) => {
      requests.push(this.resourcesService.createShare(this.resource.id, s));
    });
    sharesToDelete.forEach((s) => {
      requests.push(this.resourcesService.deleteShare(this.resource.id, s.shareId));
    });
    return requests;
  }
}
