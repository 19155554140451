<ng-container *ngIf="text.url && !disableLinks; else default">
  <ng-container *ngIf="internalUrl; else externalUrl">
    <a
      class="{{ text.classes }}"
      [class.si-for--caution]="text.state === 'caution'"
      [class.si-for--info]="text.isInfo"
      [class.si-for--minor]="text.state === 'minor'"
      [class.si-for--negative]="text.state === 'negative'"
      [class.si-for--positive]="text.state === 'positive'"
      [class.si-for--primary]="text.state === 'primary'"
      [class.si-for--secondary]="text.state === 'secondary'"
      [class.si-space--before]="text.spaceBefore"
      [fragment]="fragmentUrl"
      [routerLink]="text.url"
      [innerHTML]="_textInnerHTML"
      [attr.title]="text.tooltip ?? null"
    ></a>
  </ng-container>
  <ng-template #externalUrl>
    <a
      class="{{ text.classes }}"
      [class.si-for--caution]="text.state === 'caution'"
      [class.si-for--info]="text.isInfo"
      [class.si-for--minor]="text.state === 'minor'"
      [class.si-for--negative]="text.state === 'negative'"
      [class.si-for--positive]="text.state === 'positive'"
      [class.si-for--primary]="text.state === 'primary'"
      [class.si-for--secondary]="text.state === 'secondary'"
      [class.si-space--before]="text.spaceBefore"
      [href]="text.url + (fragmentUrl ? '#' + fragmentUrl : '')"
      [innerHTML]="_textInnerHTML"
      [attr.title]="text.tooltip ?? null"
    ></a>
  </ng-template>
</ng-container>
<ng-template #default>
  <span
    class="si-parttext {{ text.classes }}"
    [class.si-for--caution]="text.state === 'caution'"
    [class.si-for--info]="text.isInfo"
    [class.si-for--minor]="text.state === 'minor'"
    [class.si-for--negative]="text.state === 'negative'"
    [class.si-for--positive]="text.state === 'positive'"
    [class.si-for--primary]="text.state === 'primary'"
    [class.si-for--secondary]="text.state === 'secondary'"
    [class.si-space--before]="text.spaceBefore"
    [innerHTML]="_textInnerHTML"
    [attr.title]="text.tooltip ?? null"
  ></span>
</ng-template>
<fs-part-text *ngIf="text.another" [text]="text.another"></fs-part-text>
