<ng-template #template>
  <ng-container
    *ngIf="{
      config: config$ | async,
      isToolPanelCollapsed: isToolPanelCollapsed$ | async,
      fullScreen: fullScreen$ | async
    } as _"
  >
    <div libDetectBreakpointWidth *ngIf="!_.fullScreen">
      <div class="si-col__set">
        <div class="si-col__h">
          <button
            mat-mini-fab
            class="si-button si-button--collapse"
            aria-hidden="true"
            (click)="setIsToolPanelCollapsed(true)"
            matTooltip="{{ _.config?.panel_collapse_text }}"
            aria-label="{{ _.config?.panel_collapse_text }}"
          >
            <div class="si-icon">
              <span class="icon-arrow-collapse-right"></span>
              <span class="si-visible--sr-only">{{ _.config?.panel_collapse_text }}</span>
            </div>
          </button>
          <mat-toolbar class="si-toolbar si-toolbar--tool">
            <div class="si-toolbar__set">
              <div class="si-toolbar__h">
                <h3 class="si-toolbar__title">{{ _.config?.panel_title }}</h3>
              </div>
            </div>
          </mat-toolbar>
        </div>
        <div class="si-col__c">
          <mobi-standard-panel *ngIf="_.config?.panel_type === 'standard'"></mobi-standard-panel>
          <mobi-journal-panel *ngIf="_.config?.panel_type === 'journal'"></mobi-journal-panel>
          <mobi-journal-article-panel *ngIf="_.config?.panel_type === 'journal-article'"></mobi-journal-article-panel>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
