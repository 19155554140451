import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs';
import { DetailActions, DetailSelectors } from '../../../../../store/detail';
import { ContentService } from '../../../../../shared/state/content.service';
import { SubscriptionsService } from '../../../../../shared/state/subscriptions.service';
import { SessionService } from '../../../../../shared/state/session.service';
import { DownloadDocumentActions } from '../../../../../store/download-document';
import { SeoUtilityService } from '../../../../../shared/services/seo-utility.service';

@UntilDestroy()
@Component({
  selector: 'mobi-detail-main-view',
  templateUrl: './detail-main-view.component.html',
})
export class DetailMainViewComponent implements OnInit, OnDestroy {
  @ViewChild('template', { static: true }) template;

  public content$ = this.contentService.content$;
  public config$ = this.contentService.config$;
  public fragment$ = this.route.fragment;
  public relatedResults$ = this.contentService.relatedResults$;
  public journalBrowseResults$ = this.contentService.journalBrowseResults$;
  public routeLoading$ = this.contentService.routeLoading$;
  returnUrl$ = this.contentService.returnUrl$;
  public subSessions$ = this.subService.subscriptions$;
  public detailFeatureContent$ = this.store.select(DetailSelectors.detailSelector);
  private journalSearchTerm: string;
  public journalBrxDetail$ = this.contentService.journalBrxDetail$;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private contentService: ContentService,
    private store: Store,
    private subService: SubscriptionsService,
    private actions$: Actions,
    private sessionService: SessionService,
    private seoUtilityService: SeoUtilityService
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);

    this.actions$
      .pipe(
        untilDestroyed(this),
        ofType(DownloadDocumentActions.verifyAccessSucceeded),
        switchMap((action) => {
          this.contentService.setContentView(action.detailContent);
          if (this.route.snapshot.fragment === 'related') {
            return this.contentService.getRecommendations(action.detailContent.id);
          } else if (this.route.snapshot.fragment === 'browse') {
            return this.contentService.getJournalBrowseResults();
          }
        })
      )
      .subscribe();

    this.content$
      .pipe(
        untilDestroyed(this),
        tap((action) => {
          if (action?.id) {
            this.store.dispatch(DetailActions.getContentDataSets({ contentId: action.id, limit: 50 }));
          }
          this.seoUtilityService.setCanonicalURL(action);
        })
      )
      .subscribe();
    this.sessionService.setShowMenu(false);
    this.contentService.journalBrowseSearchTerm$.subscribe((searchTerm) => {
      this.journalSearchTerm = searchTerm;
    });
  }

  ngOnDestroy(): void {
    // Remove Canonical Link from the DOM
    this.seoUtilityService.removeCanonicalURL();
  }
}
