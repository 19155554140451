import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FacetOption, HierarchicalFacetOption, HierarchyDialogData } from '@sae/models';

@Component({
  selector: 'si-hierarchy-dialog',
  templateUrl: './hierarchy-dialog.component.html',
  styleUrls: ['./hierarchy-dialog.component.scss'],
})
export class HierarchyDialogComponent implements AfterViewInit {
  hierarchyTreeControl = new NestedTreeControl<HierarchicalFacetOption | FacetOption>((node) => ('children' in node ? node.children : []));
  hierarchyDataSource = new MatTreeNestedDataSource<HierarchicalFacetOption>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: HierarchyDialogData, public dialogRef: MatDialogRef<HierarchyDialogComponent>) {
    this.hierarchyDataSource.data = this.data.optionsTree;
  }

  ngAfterViewInit(): void {
    this.hierarchyTreeControl.collapseAll();
  }

  hasChild(_: number, node: HierarchicalFacetOption | FacetOption): boolean {
    return 'children' in node && node.children.length > 0;
  }

  select(node: HierarchicalFacetOption): void {
    const labelValue = node.fullName ? node.fullName + node.title : node.title;
    node.id && this.dialogRef.close({ label: labelValue , value: node.id, id: node.id });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
