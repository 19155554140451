import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ClipboardDirective } from './directives/clipboard.directive';
import { CollapsibleToolsDirective } from './directives/collapsible-tools.directive';
import { GlobalVhVarUpdateDirective } from './directives/css-var-vh-update.directive';
import { FeatureBarDirective } from './directives/feature-bar.directive';
import { TableCellDefDirective } from './directives/table-cell-def.directive';
import { TableFixedDirective } from './directives/table-fixed.directive';
import { TableHeaderCellDefDirective } from './directives/table-header-cell-def.directive';
import { DateFilterNamePipe } from './pipes/date-filter-name.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';
import { FakepathCleanPipe } from './pipes/fakepath-clean.pipe';
import { FileExtensionDisplayPipe } from './pipes/file-extension-display.pipe';
import { FilterNamePipe } from './pipes/filter-name.pipe';
import { NameAvatarPipe } from './pipes/name-avatar.pipe';
import { PersonInitialsPipe } from './pipes/person-initials.pipe';
import { ResourcePermissionsPipe } from './pipes/resource-permissions.pipe';
import { ResourceTypePipe } from './pipes/resource-type.pipe';
import { ResourceUserPipe } from './pipes/resource-user.pipe';
import { SaeDatePipe } from './pipes/sae-date.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { RunPipe } from './pipes/run.pipe';
import { SanitizeUrlDirective } from './directives/sanitize-url.directive';
import { SimpleInitialsPipe } from './pipes/simple-initials.pipe';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ThemeButtonDirective } from './directives/theme-button.directive';

// Standalone
import { DetectBreakpointWidthDirective } from './directives/detect-breakpoint-width.directive';
import { DynamicHashDirective } from './directives/dynamic-hash.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { EllipsisDirective } from './directives/ellipsis.directive';
import { EllipsisDirectiveContentComponent } from './directives/ellipsis.directive';
import { BreakpointsDirective } from './directives/breakpoints.directive';
import { IncrementalSearchDirective } from './directives/incremental-search.directive';

@NgModule({
  declarations: [
    AutofocusDirective,
    ClipboardDirective,
    CollapsibleToolsDirective,
    DateFilterNamePipe,
    EnumToArrayPipe,
    EnumToStringPipe,
    FakepathCleanPipe,
    FeatureBarDirective,
    FileExtensionDisplayPipe,
    FilterNamePipe,
    GlobalVhVarUpdateDirective,
    NameAvatarPipe,
    NumbersOnlyDirective,
    PersonInitialsPipe,
    ResourcePermissionsPipe,
    ResourceTypePipe,
    ResourceUserPipe,
    RunPipe,
    SaeDatePipe,
    SanitizeUrlDirective,
    SimpleInitialsPipe,
    SortByPipe,
    TableCellDefDirective,
    TableFixedDirective,
    TableHeaderCellDefDirective,
    ThemeButtonDirective,
    UserInitialsPipe,
  ],
  imports: [
    CommonModule,
    // Standalone
    DetectBreakpointWidthDirective,
    DynamicHashDirective,
    UppercaseDirective,
    EllipsisDirective,
    EllipsisDirectiveContentComponent,
    BreakpointsDirective,
    IncrementalSearchDirective,
  ],
  exports: [
    AutofocusDirective,
    ClipboardDirective,
    CollapsibleToolsDirective,
    DateFilterNamePipe,
    EnumToArrayPipe,
    EnumToStringPipe,
    FakepathCleanPipe,
    FeatureBarDirective,
    FileExtensionDisplayPipe,
    FilterNamePipe,
    GlobalVhVarUpdateDirective,
    NameAvatarPipe,
    NumbersOnlyDirective,
    PersonInitialsPipe,
    ResourcePermissionsPipe,
    ResourceTypePipe,
    ResourceUserPipe,
    RunPipe,
    SaeDatePipe,
    SanitizeUrlDirective,
    SimpleInitialsPipe,
    SortByPipe,
    TableCellDefDirective,
    TableFixedDirective,
    TableHeaderCellDefDirective,
    ThemeButtonDirective,
    UserInitialsPipe,
    // Standalone
    DetectBreakpointWidthDirective,
    DynamicHashDirective,
    UppercaseDirective,
    EllipsisDirective,
    EllipsisDirectiveContentComponent,
    BreakpointsDirective,
    IncrementalSearchDirective,
  ],
})
export class SharedUtilitiesModule {}
