import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { AdvSearchConditionConfig, Facet } from '@sae/models';
import { CriteriaWizardService } from '@sae/services';

@Component({
  selector: 'si-criteria-group',
  templateUrl: './criteria-group.component.html',
  styleUrls: ['./criteria-group.component.scss'],
})
export class CriteriaGroupComponent {
  @Input() level!: number;
  @Input() indexNum!: number;
  @Input() criteriaGroup!: AbstractControl;
  @Input() facets!: Array<Facet>;
  @Input() showAltClass!: boolean;
  @Input() showButton: boolean;
  @Input() advSearchConditionConfig: AdvSearchConditionConfig;
  @Input() autocompleteKeyword = false;

  @Output() groupRemoved: EventEmitter<number> = new EventEmitter<number>();

  constructor(private service: CriteriaWizardService) {}

  getCriteriaGroupAsFormGroup(): FormGroup {
    return this.criteriaGroup as FormGroup;
  }

  getAltClassLogic(index: number): boolean {
    if (this.showAltClass) {
      return index % 2 !== 0;
    } else {
      return index % 2 === 0;
    }
  }

  addRow(index: number): void {
    this.rows.insert(index, this.service.createCriteriaRow());
  }

  removeRow(index: number): void {
    if (this.rows.length > 1) {
      this.rows.removeAt(index);
    }
  }

  onGroupAdded(): void {
    this.rows.push(this.service.createCriteriaGroup(true));
  }

  onGroupRemoved(): void {
    this.groupRemoved.emit(this.indexNum);
  }

  get rows(): FormArray {
    return this.criteriaGroup.get('rows') as FormArray;
  }
}
