import { Component, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { Pagination } from '@sae/models';
import { Asset } from '../../../../../shared/models/common-interfaces';
import { SubscriptionSession } from '../../../../../shared/state/subscriptions.service';
import { AuthService } from '../../../../../shared/state/auth.service';
import { DownloadService } from '../../../../../shared/services/download.service';
import { DetailActions, DetailSelectors } from '../../../../../store/detail';
import { DownloadDocumentActions } from '../../../../../store/download-document';
import { AccessControlResult } from '../../../../../shared/models/api-models';

@Component({
  selector: 'mobi-detail-data-sets',
  templateUrl: './detail-data-sets.component.html',
})
export class DetailDataSetsComponent {
  @Input() dataSets: Asset[];
  @Input() pagination: Pagination;
  @Input() subSessions: SubscriptionSession[];
  @Input() contentId: number;
  @Input() accessProperties: AccessControlResult;

  constructor(private store: Store, private authService: AuthService, private downloadService: DownloadService) {}

  public sortDir$ = this.store.select(DetailSelectors.selectSortDir);

  public defaultSortBy = 'Title';

  openDownloadDialog(asset: Asset): void {
    this.store.dispatch(
      DownloadDocumentActions.openFromDataSet({
        accessProperties: this.accessProperties,
        subSessions: this.subSessions,
        assets: [asset],
        contentId: this.contentId as unknown as number,
      })
    );
  }

  getDownloadLink(asset: Asset): string {
    // TODO: Need to achieve model consistency
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.downloadService.getDownloadLinkForAsset(asset as any);
  }

  onPageChange(event: { pageSize: number; pageIndex: number }): void {
    const offset = event.pageSize * event.pageIndex;
    const limit = event.pageSize;
    this.store.dispatch(DetailActions.getContentDataSets({ contentId: this.contentId, offset, limit }));
  }
  onSortChange(event: Sort): void {
    let sort = 'title'; //default sort
    const order = event.direction;
    if (event.active == this.defaultSortBy) {
      this.store.dispatch(DetailActions.changeSortDirection({ sortDir: order }));
    }
    this.defaultSortBy = event?.active;
    switch (event?.active) {
      case 'Type':
        sort = 'fileType';
        break;
      case 'Description':
        sort = 'description';
        break;
      default:
        sort = 'title';
        break;
    }
    this.store.dispatch(DetailActions.getContentDataSets({ contentId: this.contentId, sort, order }));
  }
}
