import { Pipe, PipeTransform } from '@angular/core';
import { SearchResultEntry } from '../models/search-result.model';

@Pipe({ name: 'buildDetailsLink' })
export class BuildDetailsLinkPipe implements PipeTransform {
  transform(search: SearchResultEntry): string {
    if (search?.documentUris?.basicUris?.seoUri) {
      // TODO: do not need this scenario when the detail page is done
      if (contentTypeDoesntHaveDetailPage.includes(search.meta?.contentGroup?.subGroup)) {
        return null;
      }
      return search?.documentUris?.basicUris?.seoUri;
    } else {
      if (
        !search?.meta?.contentGroup?.group &&
        !search?.meta?.contentGroup?.subGroup &&
        !search?.meta?.contentType?.rootType &&
        !search?.meta?.contentType?.selectedType
      ) {
        return null;
      }
      if (
        search?.meta?.contentGroup?.group === 'Standards' ||
        search?.meta?.contentGroup?.subGroup === 'GV Companion Document' ||
        search?.meta?.contentType?.rootType === 'Standard' ||
        search?.meta?.contentType?.selectedType === 'GV Companion Document'
      ) {
        return `/standards/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'Technical Paper' ||
        search?.meta?.contentType?.selectedType === 'Technical Paper'
      ) {
        return `/articles/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'Journal Article' ||
        search?.meta?.contentType?.selectedType === 'Journal Article'
      ) {
        return `/articles/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'Research Report' ||
        search?.meta?.contentType?.selectedType === 'Research Report'
      ) {
        return `/reports/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'Magazine Article' ||
        search?.meta?.contentType?.selectedType === 'Magazine Article'
      ) {
        return `/magazine-articles/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'Book' ||
        search?.meta?.contentType?.selectedType === 'Book'
      ) {
        return `/books/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'Magazine' ||
        search?.meta?.contentType?.selectedType === 'Magazine'
      ) {
        return `/periodicals/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'Podcast' ||
        search?.meta?.contentGroup?.subGroup === 'Video' ||
        search?.meta?.contentGroup?.group === 'Media' ||
        search?.meta?.contentType?.selectedType === 'Podcast' ||
        search?.meta?.contentType?.selectedType === 'Video' ||
        search?.meta?.contentType?.rootType === 'Media'
      ) {
        return `/media/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.group === 'Journals' ||
        search?.meta?.contentType?.rootType === 'Journal'
      ) {
        return `/periodicals/${search.meta.cid}`;
      } else if (
        search?.meta?.contentGroup?.subGroup === 'NASA Feature Article' ||
        search?.meta?.contentGroup?.subGroup === 'Defense Feature Article' ||
        search?.meta?.contentGroup?.subGroup === 'Report' ||
        search?.meta?.contentType?.selectedType === 'NASA Feature Article' ||
        search?.meta?.contentType?.selectedType === 'Defense Feature Article' ||
        search?.meta?.contentType?.selectedType === 'Report'
      ) {
        return `/articles/${search.meta.cid}`;
      } else if (
        (search?.meta?.contentGroup?.subGroup === 'Instructor Led' ||
          search?.meta?.contentGroup?.subGroup === 'eLearning' ||
          search?.meta?.contentType?.selectedType === 'Classroom Training',
        search?.meta?.contentType?.selectedType === 'Online Course')
      ) {
        return `/courses/${search.meta.cid}`;
      } else {
        return null;
      }
      return '';
    }
  }
}

// TODO: remove the content type after implmenting the detail page
const contentTypeDoesntHaveDetailPage = ['Content Collection'];
