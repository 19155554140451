import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { SaeHttpResponse } from '@sae/models';
import { Observable } from 'rxjs';
import { Alert } from '../models/alerts.model';
import { Api } from '../../../api';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../env.config';

@Injectable({
  providedIn: 'root',
})
export class AlertsApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {}

  public getMany(
    params?: Partial<{
      anchorIds: string[];
      applications: string[];
      feature: string;
      userId: string;
      sort: string;
      order: string;
      offset: number;
      limit: number;
      searchTerm: string;
    }>
  ): Observable<SaeHttpResponse<Alert>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${Api.alerts}`;
    const httpParams = new HttpParams({ fromObject: params });
    return this.httpClient.get<SaeHttpResponse<Alert>>(url, { params: httpParams });
  }

  public get(id: string): Observable<SaeHttpResponse<Alert>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.alerts.url
    }/${id}`;
    return this.httpClient.get<SaeHttpResponse<Alert>>(url);
  }

  public post(alertRequest: Alert): Observable<SaeHttpResponse<Alert>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${Api.alerts.url}`;
    return this.httpClient.post<SaeHttpResponse<Alert>>(url, alertRequest);
  }

  public patch(id: string, alertRequest: Partial<Alert>): Observable<SaeHttpResponse<Alert>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.alerts.url
    }/${id}`;
    return this.httpClient.patch<SaeHttpResponse<Alert>>(url, alertRequest);
  }

  public delete(id: string): Observable<SaeHttpResponse<Alert>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.alerts.url
    }/${id}`;
    return this.httpClient.delete<SaeHttpResponse<Alert>>(url);
  }
}
