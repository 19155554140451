import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApmRumService,
  AuthCoreService,
  DefaultAuthQuery,
  DefaultAuthStore,
  ProteusAuthCoreService,
} from '@sae/services';
import { KeycloakService } from 'keycloak-angular';

// TODO: do we need to extend auth core for this app
// If not, we can just provide AuthCoreService directly in app module
@Injectable({
  providedIn: 'root',
})
export class AuthService extends ProteusAuthCoreService {
  constructor(
    query: DefaultAuthQuery,
    authStore: DefaultAuthStore,
    apmService: ApmRumService,
    private keycloakService: KeycloakService,
    private router: Router
  ) {
    super(query, authStore, apmService, keycloakService);
  }

  /**
   *
   * @param path Where you want to redirect after login. Just the part
   * of the route after http://datc.sae-itc.com, e.g., 'manage'
   * @returns a promise that you'll never be able to do anything with :(
   */
  public login(path?: string): Promise<void> {
    const redirectUri = path ?? this.router.url;
    // Note that we use createLoginUrl and window.location.assign because keycloak login
    // uses window.login.replace(), which breaks the functionality of the back button in the keycloak login page.
    const keycloakLoc = this.keycloakService.getKeycloakInstance().createLoginUrl({
      redirectUri: `${window.location.origin}/${redirectUri}`,
    });
    window.location.assign(keycloakLoc);
    return new Promise((resolve) => resolve());
  }
}
