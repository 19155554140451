<button mat-icon-button [matMenuTriggerFor]="viewFilterOpts" class="si-icon--more">
  <mat-icon>{{ icon }}</mat-icon>
</button>
<mat-menu #viewFilterOpts="matMenu" class="si-menu">
  <ng-container *ngIf="all">
    <button
      data-testid="selectedAll"
      mat-menu-item
      [class.si-state--selected-not]="(filterBy && filterBy !== 'All') || (filterListBy && filterListBy.length)"
      (click)="selectedFilter('All')"
    >
      <mat-icon aria-hidden="true">check</mat-icon>
      <span>All</span>
    </button>
    <mat-divider class="si-divider"></mat-divider>
  </ng-container>
  <ng-container *ngFor="let option of options; let i = index">
    <button
      data-testid="selectedOption"
      mat-menu-item
      [class.si-state--selected-not]="
        (filterBy && filterBy !== option) || (filterListBy && !filterListBy.includes(option))
      "
      (click)="selectedFilter(option)"
    >
      <mat-icon aria-hidden="true">check</mat-icon>
      <span>{{ option }}</span>
    </button>
    <mat-divider class="si-divider" *ngIf="dividerIndexes.includes(i)"></mat-divider>
  </ng-container>
</mat-menu>
