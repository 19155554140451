import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { Revision } from '../../../models/content.model';
import { DetailsHelperService } from '../../../services/details-helper.service';
import { ContentService } from '../../../../../shared/state/content.service';
import { FragmentService } from '../../../../../shared/services/fragment.service';
import { AuthService } from '../../../../../shared/state/auth.service';
import { SearchActions } from '../../../../../store/search';
import { SeoChiplistDto } from '@sae/components';

@UntilDestroy()
@Component({
  selector: 'mobi-detail-description',
  templateUrl: './detail-description.component.html',
})
export class DetailDescriptionComponent implements OnInit {
  @ViewChild('template', { static: true }) template;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public config$ = this.contentService.config$;
  public content$ = this.contentService.content$;
  public fragment$ = this.route.fragment;
  public digitalStandard$ = this.contentService.digitalStandard$;
  public isLoggedIn$ = this.authService.isLoggedIn$;
  public fulltextMediaUrl: SafeUrl;
  public previewMediaUrl: SafeUrl;
  public redlineOptions$: Observable<Revision[]> = this.content$?.pipe(
    map((content) =>
      content.standardAttributes?.revisions?.filter((revision) => revision.cid !== content.id && revision.hasXml)
    )
  );
  public journalBrxDetail$ = this.contentService.journalBrxDetail$;

  public topicChips$ = this.content$.pipe(
    map((c) => {
      const chips: SeoChiplistDto[] = [];
      for (const topic of c.topics ?? []) {
        chips.push({
          label: topic,
          href: '/search',
          analyticsId: 'item-detail-topic-chip',
          fragment: `topics=${encodeURIComponent(topic)}`,
          tooltip: `Browse ${topic} Content`,
        });
      }
      return chips;
    })
  );

  public affiliationChips$ = this.content$.pipe(
    map((c) => {
      const chips: SeoChiplistDto[] = [];
      for (const affiliation of c.affiliations ?? []) {
        chips.push({
          label: affiliation,
          href: '/search',
          fragment: `affiliations=${encodeURIComponent(affiliation)}`,
          analyticsId: 'item-detail-affiliation-chip',
          tooltip: `Browse ${affiliation} Content`,
        });
      }
      return chips;
    })
  );

  public publisherChips$ = this.content$.pipe(
    map((c) => {
      const chips: SeoChiplistDto[] = [];
      chips.push({
        label: c.publisher,
        href: '/search',
        fragment: `publisher_name=${encodeURIComponent(c.publisher)}`,
        classes: 'si-chip--entity',
        tooltip: `Browse ${c.publisher} Content`,
      });
      return chips;
    })
  );

  constructor(
    private viewContainerRef: ViewContainerRef,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    private fragmentService: FragmentService,
    private store: Store,
    private detailsHelperService: DetailsHelperService,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.contentService.openAllEvent$.pipe(untilDestroyed(this)).subscribe(() => this.accordion.openAll());
    this.contentService.closeAllEvent$.pipe(untilDestroyed(this)).subscribe(() => this.accordion.closeAll());
    this.content$?.subscribe((content) => {
      this.fulltextMediaUrl = content?.fulltextMediaUrl
        ? this.sanitizer.bypassSecurityTrustResourceUrl(content?.fulltextMediaUrl)
        : null;
      this.previewMediaUrl = content?.previewMediaUrl
        ? this.sanitizer.bypassSecurityTrustResourceUrl(content?.previewMediaUrl)
        : null;
    });
  }

  performEventChipSearch(event: string): void {
    this.router.navigate([`/search`], { fragment: `conference_name=${encodeURIComponent(event)}` });
  }

  performCommitteeChipSearch(committee: string): void {
    this.router.navigate([`/search`], { fragment: `authors=${encodeURIComponent(committee)}` });
  }

  performAuthorChipSearch(author: string): void {
    this.router.navigate([`/search`], { fragment: `authors=${encodeURIComponent(author)}` });
  }

  performAffiliationChipSearch(affiliation: string): void {
    this.router.navigate([`/search`], { fragment: `affiliations=${encodeURIComponent(affiliation)}` });
  }

  performTopicChipSearch(topic: string): void {
    this.router.navigate([`/search`], { fragment: `topics=${encodeURIComponent(topic)}` });
  }

  performPublisherChipSearch(publisher: string): void {
    this.router.navigate([`/search`], { fragment: `publisher_name=${encodeURIComponent(publisher)}` });
  }

  redline(revision: Revision): void {
    this.router.navigateByUrl(this.router.url + `#view&mode=redline&compare=${revision.cid}`);
  }

  login(): void {
    this.authService.login();
  }

  annotate(): void {
    this.router.navigateByUrl(`${this.router.url}#view&mode=annotation`);
  }

  dataSets(): void {
    this.router.navigateByUrl(`${this.router.url}#data-sets`);
  }

  navigate2d3d(): void {
    this.router.navigateByUrl(`${this.router.url}#2d-3d`);
  }
}
