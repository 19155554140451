<ng-container
  *ngIf="{
    content: content$ | async
  } as _"
>
  <mat-accordion class="si-expanel__container si-expanel--singleton" [multi]="true">
    <mat-expansion-panel
      *ngIf="_.content?.materials"
      class="si-expanel si-expanel--impact mat-elevation-z"
      [expanded]="true"
    >
      <mat-expansion-panel-header class="si-expanel__h">
        <mat-panel-title class="si-title si-impact__title">Materials</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="si-expanel__c">
        <si-data-output class="si-data__set si-wd-full" content="{{ _.content?.materials }}"></si-data-output>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      *ngIf="_.content?.requirements"
      class="si-expanel si-expanel--impact mat-elevation-z"
      [expanded]="true"
    >
      <mat-expansion-panel-header class="si-expanel__h">
        <mat-panel-title class="si-title si-impact__title">Requirements</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="si-expanel__c">
        <si-data-output class="si-data__set si-wd-full" content="{{ _.content?.requirements }}"></si-data-output>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
