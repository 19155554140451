import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonItem } from '../../parts/part-button/part-button.component';
import {
  DefinedDataContent,
  CompDefinedDataComponent,
} from '../../compounds/comp-defined-data/comp-defined-data.component';
import { ChipItem, CompChipComponent } from '../../compounds/comp-chip/comp-chip.component';
import {
  MixedComponentsContent,
  MixedComponentsConfig,
  UIUtilMixedComponentsComponent,
} from '../ui-util-mixed-components/ui-util-mixed-components.component';
import { overrideInputs } from '@sae/base';

export interface DataItem {
  width?: string;
  classes?: string;
  outerClasses?: string;
  data?: DataItemContent;
  mixedConfig?: MixedComponentsConfig;
}

export interface DataItemContent extends DefinedDataContent {
  mixed?: MixedComponentsContent[];
}

@Component({
  selector: 'fs-ui-data-item',
  styleUrls: ['./ui-data-item.component.scss'],
  templateUrl: './ui-data-item.component.html',
  imports: [CommonModule, CompDefinedDataComponent, CompChipComponent, UIUtilMixedComponentsComponent],
  standalone: true,
})
export class UIDataItemComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: DataItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string; // Optional, provides a CSS class for the output wrapper
  @Input() outerClasses: string; // Optional, provides a CSS class for the output wrapper
  @Input() data: DataItemContent;
  @Input() mixedConfig: MixedComponentsConfig; // Optional, provides a compound wrapper around the body
  @Input() content: DataItem; // [DEPRECATED - Will be removed] Optional, overrides other inputs when provided

  @Output() buttonClick = new EventEmitter<ButtonItem>(); // Event emitted when button is clicked
  @Output() chipClick = new EventEmitter<ChipItem>(); // Event emitted when chip is clicked
  @Output() chipRemoveClick = new EventEmitter<ChipItem>(); // Event emitted when chip remove button is clicked

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }

    if (this.mixedConfig?.compoundWrap) {
      // set a default of max resolution of 'none' if not provided
      this.mixedConfig.compoundWrap.stackMaxResolution = this.mixedConfig.compoundWrap.stackMaxResolution || 'none';
    }
  }

  onButtonClick(button: ButtonItem): void {
    this.buttonClick.emit(button);
  }

  onChipClick(chip: ChipItem): void {
    this.chipClick.emit(chip);
  }

  onRemoveChipClick(chip: ChipItem): void {
    this.chipRemoveClick.emit(chip);
  }
}
