<header
  class="si-masth"
  [class.si-contains--context]="contextClientWidth > 0"
  [class.si-contains--control]="controlClientWidth > 0"
  [class.si-contains--widecontrol]="controlClientWidth > 48"
>
  <div class="si-masth__h">
    <div class="si-masthead__context" #context>
      <ng-content select="[context]"></ng-content>
    </div>
    <div class="si-masth__title" *ngIf="contextClientWidth === 0">
      <ng-container *ngTemplateOutlet="title"></ng-container>
    </div>
    <div class="si-masth__control" #control>
      <ng-content select="[control]"></ng-content>
    </div>
  </div>
  <div class="si-masth__h" *ngIf="contextClientWidth > 0">
    <div class="si-masth__title">
      <ng-container *ngTemplateOutlet="title"></ng-container>
    </div>
  </div>

  <div class="si-masth__c">
    <div class="si-masth__subtitle" #subtitle>
      <fs-comp-row-text
        *ngIf="subHeading?.length > 0"
        class="si-datarow__wrap si-subtitle"
        [text]="subHeading"
        [asideText]="subHeadingAside"
        [delimiter]="'space'"
        [spaced]="true"
      ></fs-comp-row-text>
      <fs-comp-row-text *ngFor="let row of rows" class="si-datarow__wrap" [rowText]="row"></fs-comp-row-text>
      <ng-content select="[subtitle]"></ng-content>
    </div>
  </div>
  <ng-content select="[end]"></ng-content>
</header>

<ng-template #title>
  <fs-comp-avatar *ngIf="avatar" [avatar]="avatar" class="si-expanelv__avatar"></fs-comp-avatar>

  <fs-comp-util-heading
    [headingRow]="
      heading?.length > 0
        ? {
            delimiter: 'space',
            text: heading
          }
        : headingRow
    "
    [classes]="headingClasses"
    [content]="headingContent"
    [headingCount]="headingCount"
    [hTag]="1"
  >
    <ng-container rowStart>
      <ng-content select="[headingRowStart]"></ng-content>
    </ng-container>
  </fs-comp-util-heading>
  <ng-content select="[heading]"></ng-content>
  <ng-content select="[headingEnd]"></ng-content>
</ng-template>
