import { ListManagerNode, ListManagerSection } from '@sae/components';

export interface FolderResultType {
  anchorId: string;
  creationDate: string;
  id: string;
  name: string;
  resourceType: string;
  resources: FolderResultType[];
  shared: boolean;
}
export interface FolderNodeFlat extends FolderResultType {
  spinner: boolean;
  checked: boolean;
  viewTitle?: string;
  root?: boolean;
  sharedRoot?: boolean;
  parentId?: string;
}

export const DEFAULT_FOLDER_NAME = 'My Favorites';
export const SHARED_FOLDER_NAME = 'Shared';

export interface MobilusListManagerNode extends ListManagerNode {
  backendName?: string;
  cid?: number;
  id?: string;
}

export interface MobilusListManagerSection extends Omit<ListManagerSection, 'nodes'> {
  backendName?: string;
  cid?: number;
  id?: string;
  shared?: boolean;
  nodes: MobilusListManagerNode[];
}

export const DEFAULT_NODE_INIT_STATE = {
  title: 'My Library',
  backendName: DEFAULT_FOLDER_NAME,
  cid: null,
  expanded: true,
  divider: true,
  checkboxPresent: true,
  checkboxHidesNodes: true,
  checkboxTitle: 'Added',
  checked: false,
  nodesGroupTitleVisible: true,
  nodesGroupTitleText: 'Folders',
  deleteNodeEnabled: true,
  createNodeEnabled: true,
  createNodeIcon: 'create_new_folder',
  createNodeTitle: 'Create Folder',
  createNodeFieldName: 'Title',
  createNodeActionText: 'Create',
  spinner: false,
  nodes: [],
};
export const SHARED_FOLDER_INIT_STATE = {
  title: 'Shared by User',
  divider: true,
  nodesGroupTitleVisible: true,
  nodesGroupTitleText: 'Folders',
  nodes: [],
};

export const INITIAL_FOLDER_VIEW: MobilusListManagerSection[] = [DEFAULT_NODE_INIT_STATE, SHARED_FOLDER_INIT_STATE];

export interface MetaValueLabel {
  label: string;
  apiLabel: string;
}

export const metaData: MetaValueLabel[] = [
  { label: 'Item Number', apiLabel: 'itemNumber' },
  { label: 'Content Type', apiLabel: 'contentType' },
  { label: 'Title', apiLabel: 'title' },
  { label: 'Author(s)', apiLabel: 'author' },
  { label: 'Affiliation(s)', apiLabel: 'affiliation' },
  { label: 'Publisher', apiLabel: 'publisher' },
  { label: 'Publish Date', apiLabel: 'publicationDate' },
  { label: 'Abstract/scope', apiLabel: 'abstr' },
  { label: 'Citation', apiLabel: 'citation' },
  { label: 'DOI', apiLabel: 'doi' },
];

export interface MetaLabelItem {
  label: string;
  apiTitle: string;
  value: boolean;
}

export const DEFAULT_META_DOWNLOAD_ERROR = 'Error Downloading Metadata';
