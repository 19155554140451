<div
  class="si-crate {{ classes }}"
  [class.si-crate--not-relative]="!relative"
  [class.si-shadow]="shadow"
  [class.si-print--no-shadow]="printNoShadow"
  [class.si-tight]="tight"
  [class.si-tight--horz]="tightHorz"
  [class.si-tight--vert]="tightVert"
  [class.si-print--tight]="printTight"
>
  <ng-content></ng-content>
</div>
