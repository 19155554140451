import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { SaeHttpResponse } from '@sae/models';
import { Observable } from 'rxjs';
import { DownloadMeta } from '../models/download.model';
import { Api } from '../../../api';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../env.config';
@Injectable({
  providedIn: 'root',
})
export class DownloadManagerApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {}

  getDownload(downloadId: string, useStream = false): Observable<SaeHttpResponse<DownloadMeta>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.downloadManager.url
    }/downloads/${downloadId}`;
    const headers = new HttpHeaders({ Accept: useStream ? 'application/octet-stream' : 'application/json' });
    return this.httpClient.get<SaeHttpResponse<DownloadMeta>>(url, { headers });
  }
}
