import { Inject } from '@angular/core';
import { ISessionService, MasterWidth, SESSION_TOKEN } from '@sae/services';

export class BaseTemplateComponent {
  activeNavIcon$ = this.sessionService.activeNavIcon$;
  showMobileNavMenu$ = this.sessionService.showMobileNavMenu$;
  isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  masterWidth$ = this.sessionService.masterWidth$;
  isTakeoverActive$ = this.sessionService.isTakeoverActive$;
  isWideFeature$ = this.sessionService.isWideFeature$;
  masterHeadFilter$ = this.sessionService.masterHeadFilter$;
  showFeatures$ = this.sessionService.showFeatures$;
  showHomeNavIcon$ = this.sessionService.showHomeNavIcon$;
  showSearchNavIcon$ = this.sessionService.showSearchNavIcon$;
  showUpdatesNavIcon$ = this.sessionService.showUpdatesNavIcon$;
  showHelpNavIcon$ = this.sessionService.showHelpNavIcon$;

  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {}

  setActiveNavIcon(activeNavIcon: string): void {
    this.sessionService.setActiveNavIcon(activeNavIcon);
  }

  setShowMobileNavMenu(showMobileNavMenu: boolean): void {
    this.sessionService.setShowMobileNavMenu(showMobileNavMenu);
  }

  setIsToolPanelCollapsed(isToolPanelCollapsed: boolean): void {
    this.sessionService.setIsToolPanelCollapsed(isToolPanelCollapsed);
  }
  setMasterWidth(masterWidth: MasterWidth): void {
    this.sessionService.setMasterWidth(masterWidth);
  }

  setIsTakoverActive(isTakeoverActive: boolean): void {
    this.sessionService.setIsTakoverActive(isTakeoverActive);
  }

  setIsWideFeature(isWideFeature: boolean): void {
    this.sessionService.setIsWideFeature(isWideFeature);
  }

  setMasterHeadFilter(masterHeadFilter: boolean): void {
    this.sessionService.setMasterHeadFilter(masterHeadFilter);
  }

  setShowFeatures(showFeatures: boolean): void {
    this.sessionService.setShowFeatures(showFeatures);
  }
}
