import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'si-regular-filter',
  templateUrl: './regular-filter.component.html',
  styleUrls: [],
})
export class RegularFilterComponent {
  @Input() icon = 'filter_list';
  @Input() options!: string[];
  @Input() filterBy!: string;
  @Input() filterListBy!: string[];
  @Input() all = true;
  @Input() dividerIndexes: number[] = [];
  @Output() filterSelected = new EventEmitter();

  selectedFilter(sel: string): void {
    this.filterSelected.emit(sel);
  }
}
