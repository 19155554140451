import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Feature } from '@sae/features';
import { TemplateModel, ViewTemplate } from '@sae/models';
import { AUTH_TOKEN, AuthCoreService, REMOTE_CONFIG_TOKEN } from '@sae/services';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, map, startWith, tap, withLatestFrom } from 'rxjs/operators';
import {
  mandatoryFeatures,
  myLibraryFeature,
  sharedFeature,
  subscribedFeature,
  batteryDB,
} from '../app/shared/utils/session_utils';
import { Group } from './shared/models/groups.model';
import { RouterHistoryService } from './shared/services/router-history.service';
import { SessionService } from './shared/state/session.service';
import { SubscriptionsService } from './shared/state/subscriptions.service';
import { UserGroupsService } from './shared/state/user-groups.service';
import { RootActions } from './store';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { IEnvironmentConfigService } from '@sae/base';
import { UserService } from './shared/state/user.service';
import { Location, isPlatformServer } from '@angular/common';
import { MobilusProteusConfig } from './env.config';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'mobi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterModule, SharedModule],
})
export class AppComponent implements OnInit {
  ViewTemplate = ViewTemplate;
  isInMaintenanceMode$ = this.envConfigService.config$.pipe(
    filter((c) => !!c),
    map((c) => c.maintenance)
  );
  activeViewTemplate$ = this.sessionService.activeViewTemplate$;
  projectedContent$: Observable<TemplateModel>;
  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  userProfile$ = this.authService.profile$;
  personType$ = this.authService.personType$;
  userId$: Observable<string> = this.authService.profile$.pipe(map((profile) => profile?.id));
  groups$: Observable<Array<Partial<Group>>> = this.userGroupsService.getAll();
  sharedFolderVisible$: Observable<boolean>;
  features$: Observable<Array<Feature>>;
  showMobileNavMenu$ = this.sessionService.showMobileNavMenu$;
  public showBatteryDb = !!this.envConfigService.envConfig<MobilusProteusConfig>().featureFlags['show_battery_db'];
  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
    @Inject(AUTH_TOKEN) private authService: AuthCoreService,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService,
    private userGroupsService: UserGroupsService,
    private routerHistoryService: RouterHistoryService,
    private dialog: MatDialog,
    private subscriptionsService: SubscriptionsService,
    private renderer2: Renderer2,
    private readonly elementRef: ElementRef,
    private store: Store,
    private userService: UserService,
    private location: Location,
    @Inject(PLATFORM_ID) protected readonly platformId: unknown
  ) {
    this.sessionService.setAppName('MOBILUS-NG');
    this.sessionService.setShowSearchNavIcon(true);
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.sessionService.setShowCustomToolbar(true);
    this.sessionService.setAppLogoClass('si-brand--mobilus');
    this.sessionService.setActiveViewTemplate(ViewTemplate.Detail);
    let currentUrl;
    if (!isPlatformServer(this.platformId)) {
      currentUrl = this.location.path(true);
    }

    this.features$ = combineLatest([
      this.subscriptionsService.subscriptions$,
      currentUrl === '/subscription'
        ? this.userService.isUserAdminonSub$
        : this.userService.isCurrentUserAdminOnSubV2(),
    ]).pipe(
      map(([subscriptions, isUserAdminOnSub]) => {
        const features = [...mandatoryFeatures, { ...myLibraryFeature }];

        if (subscriptions.length > 0 || isUserAdminOnSub) {
          features.push(subscribedFeature);
        }
        if (this.authService.isLoggedIn() && this.showBatteryDb) {
          features.push(batteryDB);
        }
        return features;
      }),
      startWith([...mandatoryFeatures, { ...myLibraryFeature }])
    );

    this.sessionService.activeNavIcon$
      .pipe(
        tap((activeNav) => {
          if (activeNav === 'My Library') {
            this.store.dispatch(RootActions.usageTrackingEvent({ event: { eventType: 'view_mylibrary' } }));
          }
        })
      )
      .subscribe();
  }

  activate(e: TemplateModel): void {
    this.routerHistoryService.contentProjected$.next(true);
    this.projectedContent$ = of(e);
  }
}
