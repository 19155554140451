import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Breadcrumbs {
  title: string;
  url?: string;
  buttonMode?:boolean; 
}

@Component({
  selector: 'si-breadcrumb-enhanced',
  templateUrl: './breadcrumb-enhanced.component.html',
})
export class BreadcrumbEnhancedComponent {
  @Input() breadcrumbs: Breadcrumbs[] = []; // Array of breadcrumb objects to render
  @Output() crumbClick = new EventEmitter<Breadcrumbs>(); // Event that gets triggered when we pass buttonMode true in breadCrumb object 

  crumbClicked(breadCrumb: Breadcrumbs) {
    this.crumbClick.emit(breadCrumb);
  }
}
