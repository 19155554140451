import { Component, Input } from '@angular/core';

@Component({
  selector: 'si-tidbit-card',
  templateUrl: './tidbit-card.component.html',
})
export class TidbitCardComponent {
  @Input() titleText!: string;
  @Input() subTitle!: string;
  @Input() when!: string;
  @Input() materialIcon!: string;
  @Input() selected = false;
  @Input() unread = false;
  @Input() wrapper: 'Normal' | 'None' = 'Normal';
}
