import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonItem } from '../../parts/part-button/part-button.component';
import {
  MixedPartsContent,
  PartUtilMixedPartsComponent,
} from '../../parts/part-util-mixed-parts/part-util-mixed-parts.component';
import { RowTextContent, CompRowTextComponent } from '../comp-row-text/comp-row-text.component';
import { overrideInputs } from '@sae/base';

export interface MixedCompoundsContent extends MixedPartsContent {
  rowText?: RowTextContent;
}

export interface CompUtilMixedCompoundsConfig {
  content?: MixedCompoundsContent[];
  disableLinks?: boolean;
}

@Component({
  selector: 'fs-comp-util-mixed-compounds',
  styleUrls: ['comp-util-mixed-compounds.component.scss'],
  templateUrl: './comp-util-mixed-compounds.component.html',
  standalone: true,
  imports: [CommonModule, PartUtilMixedPartsComponent, CompRowTextComponent],
})
export class CompUtilMixedCompoundsComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: CompUtilMixedCompoundsConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() content: MixedCompoundsContent[];
  @Input() disableLinks: boolean; // Set to true if it is contained within a surrounding link already

  @Output() buttonClick = new EventEmitter<ButtonItem>(); // Event emitted when button is clicked

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }

  onButtonClick(button: ButtonItem): void {
    this.buttonClick.emit(button);
  }

  // _content: MixedContent[];

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.content) {
  //     if (this.disableLinks) {
  //       const newContent: MixedContent[] = [];

  //       for (const content of this.content) {
  //         if (content.contentHtml) {
  //           let contentHtml: string = content.contentHtml.toString();
  //           contentHtml = contentHtml.replace(/<a/g, '<span');
  //           contentHtml = contentHtml.replace(/<\/a/g, '</span');

  //           newContent.push({ ...content, contentHtml });
  //         } else {
  //           newContent.push({ ...content });
  //         }
  //       }

  //       this._content = newContent;
  //     } else {
  //       this._content = this.content;
  //     }
  //   }
  // }
}
