<ng-container *ngIf="{
  content: content$ | async
} as _">
  <mat-card class="si-card si-card--dataviewer mat-elevation-z2">
    <mat-card-header class="si-card--dataviewer__h">
      <mat-card-title class="si-card__title si-impact">References</mat-card-title>
    </mat-card-header>
    <div class="si-card--dataviewer__c">
      <ol class="si-ol">
        <li *ngFor="let reference of _.content?.references" class="si-li" [innerHtml]="reference"></li>
      </ol>
    </div>
  </mat-card>
</ng-container>
