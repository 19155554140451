import { createReducer, on } from '@ngrx/store';
import { SearchActions } from '.';
import { Criteria } from '../../modules/search/components/advanced-search/advanced-search.component';
import { CommonFacets } from '../../shared/models/common-interfaces';
import { initialSearchState } from './search.state';

export const searchReducer = createReducer(
  initialSearchState,
  on(SearchActions.search, (state) => ({
    ...state,
    resultsLoading: true,
    resultsError: null,
  })),
  on(SearchActions.searchSuccess, (state, { resp, req }) => {
    const updatedFacets: Partial<CommonFacets> = {};
    Object.values(resp.facets).forEach((responseFacetArray) => {
      updatedFacets[responseFacetArray[0].name] = {
        ...state.facets[responseFacetArray[0].name],
        ...responseFacetArray[0],
      };
    });
    return {
      ...state,
      results: resp.results,
      facets: {
        ...state.facets,
        ...updatedFacets,
      },
      total: resp.facets?.total[0]?.data[0]?.count,
      resultsLoading: false,
      resultsError: null,
      panelInitialized: true,
      searchDetail: {
        appCode: 'mobilus',
        engineName: resp?.meta?.engine?.name,
        appsearchRequest: req,
        advancedSearchCriteriaList: state.advancedSearchEnabled ? state.advancedSearchCriteria : undefined,
        searchType: state.advancedSearchEnabled ? 'advanced' : 'simple',
      },
    };
  }),
  on(SearchActions.searchFail, (state, { error }) => ({
    ...state,
    results: [],
    resultsLoading: false,
    resultsError: error,
    total: 0,
  })),
  on(SearchActions.changePagination, (state, { event }) => ({
    ...state,
    pageNumber: event.pageIndex + 1,
    pageSize: event.pageSize,
    resultsLoading: true,
  })),
  on(SearchActions.changeSearchTerm, (state, { searchTerm }) => ({
    ...state,
    searchTerm,
    pageNumber: 1,
    preservedFacet: undefined,
  })),
  on(SearchActions.changeSortParams, (state, { sortParams }) => ({
    ...state,
    sortBy: sortParams.active,
    sortDir: sortParams.direction,
    pageNumber: 1,
    resultsLoading: true,
  })),
  on(SearchActions.getSearchSuggestionsSuccess, (state, { suggestions }) => ({
    ...state,
    searchSuggestions: suggestions,
  })),
  on(SearchActions.resetSearch, (state) => ({
    ...state,
    searchSuggestions: [],
    documentSuggestions: [],
    searchTerm: '',
    sortBy: 'Most Recent',
    filters: {},
    customFilters: {},
    isPubDateApplied: false,
    advancedSearchEnabled: false,
  })),
  on(SearchActions.clearSearch, (state) => ({
    ...state,
    searchSuggestions: [],
    documentSuggestions: [],
    searchTerm: '',
  })),
  on(SearchActions.saveScrollPosition, (state, { scrollTop }) => ({ ...state, scrollTop })),
  on(SearchActions.showMoreValues, (state, { fieldName }) => ({
    ...state,
    facets: {
      ...state.facets,
      [fieldName]: {
        ...state.facets[fieldName],
        numValuesShown: state.facets[fieldName]?.numValuesShown + 50,
      },
    },
    preservedFacet: {
      ...state.preservedFacet,
      numValuesShown: !state.preservedFacet
        ? undefined
        : fieldName === state.preservedFacet?.name
        ? state.preservedFacet?.numValuesShown + 50
        : state.preservedFacet?.numValuesShown,
    },
  })),
  on(SearchActions.applyFilter, (state, { fieldName, value }) => {
    const filterValues = state.filters[fieldName]?.concat() ?? [];
    if (!filterValues.includes(value)) {
      filterValues.push(value);
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        [fieldName]: filterValues,
      },
      preservedFacet: state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
    };
  }),
  on(SearchActions.removeFilter, (state, { fieldName, value }) => {
    const filterValues = state.filters[fieldName]?.concat() ?? [];
    const idx = filterValues.indexOf(value);
    filterValues.splice(idx, 1);
    if (!filterValues?.length) {
      const { [fieldName]: value, ...cleanedFilters } = state.filters;
      return {
        ...state,
        filters: cleanedFilters,
        preservedFacet: undefined,
      };
    } else {
      return {
        ...state,
        filters: {
          ...state.filters,
          [fieldName]: filterValues,
        },
        preservedFacet:
          state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
      };
    }
  }),
  on(SearchActions.setFilters, (state, { filters, customFilters }) => ({
    ...state,
    filters,
    customFilters,
    preservedFacet: Object.keys(filters).length === 0 ? undefined : state.preservedFacet,
    isPubDateApplied: false,
  })),
  on(SearchActions.expandAllFacets, (state) => {
    const newFacets = { ...state.facets };
    Object.keys(newFacets).forEach((key) => {
      newFacets[key] = {
        ...newFacets[key],
        expanded: true,
      };
    });
    return {
      ...state,
      facets: newFacets,
    };
  }),
  on(SearchActions.collapseAllFacets, (state) => {
    const newFacets = { ...state.facets };
    Object.keys(newFacets).forEach((key) => {
      newFacets[key] = {
        ...newFacets[key],
        expanded: false,
      };
    });
    return {
      ...state,
      facets: newFacets,
    };
  }),
  on(SearchActions.clearPreservedFacet, (state) => ({
    ...state,
    preservedFacet: undefined,
  })),
  on(SearchActions.initialSearch, (state, { searchTerm, filters, pubDate, customFilters, advancedSearchCriteria }) => ({
    ...state,
    advancedSearchEnabled: !!advancedSearchCriteria?.length && advancedSearchCriteria.length > 0,
    advancedSearchCriteria: advancedSearchCriteria?.length
      ? advancedSearchCriteria
      : ([
          {
            type: 'GROUP',
            level: 0,
            value: 'Any',
          },
          {
            type: 'ROW',
            level: 1,
            field: 'Full Text',
            conditional: 'contains',
            value: '',
          },
        ] as Criteria[]),
    searchTerm,
    filters,
    isPubDateApplied: !!pubDate?.from && !!pubDate?.to,
    pubDateFrom: pubDate?.from,
    pubDateTo: pubDate?.to,
    customFilters,
  })),
  on(SearchActions.applyPubDateFilter, (state, { to, from }) => {
    return {
      ...state,
      pubDateFrom: from,
      pubDateTo: to,
      preservedFacet: undefined,
      isPubDateApplied: true,
    };
  }),
  on(SearchActions.removePubDateFilter, (state) => ({
    ...state,
    isPubDateApplied: false,
    preservedFacet: undefined,
  })),
  on(SearchActions.applyCustomFilter, (state, { fieldName, value }) => {
    const filterValues = state.customFilters[fieldName]?.concat() ?? [];
    if (!filterValues.includes(value)) {
      filterValues.push(value);
    }
    return {
      ...state,
      customFilters: {
        ...state.customFilters,
        [fieldName]: filterValues,
      },
      preservedFacet: state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
    };
  }),
  on(SearchActions.removeCustomFilter, (state, { fieldName, value }) => {
    const filterValues = state.customFilters[fieldName]?.concat() ?? [];
    const idx = filterValues.indexOf(value);
    filterValues.splice(idx, 1);
    if (!filterValues?.length) {
      const { [fieldName]: value, ...cleanedFilters } = state.customFilters;
      return {
        ...state,
        customFilters: cleanedFilters,
        preservedFacet: undefined,
      };
    } else {
      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          [fieldName]: filterValues,
        },
        preservedFacet:
          state.preservedFacet?.name === fieldName ? state.preservedFacet : { ...state.facets[fieldName] },
      };
    }
  }),
  on(SearchActions.getDocumentSuggestionsSuccess, (state, { suggestions }) => {
    return {
      ...state,
      documentSuggestions: suggestions,
    };
  }),
  on(SearchActions.setAlert, (state, { rootCode, alertId }) => {
    let searchResults = [...state.results];

    searchResults = searchResults.map((result) => {
      if (result?.codes?.raw[result.codes.raw.length - 1] === rootCode) {
        return { ...result, alertId: alertId };
      }
      return result;
    });

    return {
      ...state,
      results: searchResults,
    };
  }),

  on(SearchActions.removeAlert, (state, { alertId }) => {
    let searchResults = [...state.results];

    searchResults = searchResults.map((result) => {
      if (result?.alertId === alertId) {
        return { ...result, alertId: null };
      }
      return result;
    });

    return {
      ...state,
      results: searchResults,
    };
  }),

  on(SearchActions.disableAdvancedSearchQuery, (state) => {
    return {
      ...state,
      advancedSearchEnabled: false,
      advancedSearchQuery: '',
      advancedSearchCriteria: [],
    };
  }),
  on(SearchActions.applyAdvancedSearchQuery, (state, action) => {
    return {
      ...state,
      advancedSearchCriteria: action.criteria,
      advancedSearchEnabled: true,
      searchTerm: '',
      pageNumber: 1,
    };
  })
);
