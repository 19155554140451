import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs';
import { ContentView } from '../../../models/content.model';
import { ContentService } from '../../../../../shared/state/content.service';
import { SessionService } from '../../../../../shared/state/session.service';
import { BrowseSelectors } from '../../../../../store/browse';

@Component({
  selector: 'mobi-detail-header',
  templateUrl: './detail-header.component.html',
})
export class DetailHeaderComponent implements OnInit {
  @ViewChild('template', { static: true }) template;
  @Input() floatTop = false;

  public config$ = this.contentService.config$;
  public content$ = this.contentService.content$;
  public isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  public backUri$ = this.contentService.returnUrl$;
  public title$ = this.contentService.returnUrl$.pipe(
    withLatestFrom(this.store.select(BrowseSelectors.selectDetailTitle)),
    map(([returnUrl, browseTitle]) => {
      if (returnUrl?.url.includes('search')) {
        return 'Search';
      } else if (returnUrl?.url.includes('/browse')) {
        return 'Browse';
      } else if (returnUrl.url) {
        return returnUrl.title;
      } else {
        if (browseTitle === 'Browse SAE Mobilus') {
          return 'Browse';
        } else {
          return browseTitle;
        }
      }
    })
  );
  public fullScreen$ = this.contentService.fullScreen$;
  public prevUri = '';
  public prevTooltip = '';
  public nextUri = '';
  public nextTooltip = '';

  constructor(
    private viewContainerRef: ViewContainerRef,
    private contentService: ContentService,
    private sessionService: SessionService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  setIsToolPanelCollapsed(bool: boolean): void {
    this.sessionService.setIsToolPanelCollapsed(bool);
  }

  goBack(url: string, content: ContentView): void {
    let browseUri = '/browse';
    if (!url) {
      browseUri = this.getBrowseUri(content.subGroup);
    }

    const returnUrl = this.contentService.getReturnUrl();

    if (returnUrl?.length === 1 && url) {
      localStorage.setItem('preserveSearchResults', 'true');
    }

    this.router.navigateByUrl(url ? url : browseUri);
    if (!url.includes('/search') && url) {
      this.contentService.popReturnUrl();
    }
  }
  toggleFullScreen(enabled: boolean): void {
    this.contentService.setFullScreenMode(enabled);
  }
  getBrowseUri(selectedType: string): string {
    let browseUri = selectedType?.replace(' ', '-').toLowerCase();
    browseUri = browseUri.concat('s');
    switch (true) {
      case toBrowseContentTypeList.includes(selectedType):
        browseUri = `/${browseUri}`;
        break;
      case standardsSubContentTypeList.includes(selectedType):
        browseUri = `/standards/${getStandardsMappedUrl(selectedType)}`;
        break;
      case trainingCoursesContentTypeList.includes(selectedType):
        browseUri = `/courses/${getTrainingsMappedUrl(selectedType)}`;
        break;
      case selectedType === 'Research Report':
        browseUri = `/reports`;
        break;
      default:
        browseUri = '/browse';
        break;
    }
    return browseUri;
  }
}

export const toBrowseContentTypeList = [
  'Journal Article',
  'Book',
  'Research Report',
  'Magazine Article',
  'Technical Paper',
  'Journal',
  'Magazine',
];
export const standardsSubContentTypeList = [
  'Aerospace Material Specification',
  'Aerospace Standard',
  'Ground Vehicle Standard',
  'SAE ITC E&A Standard',
  'ARINC Standard',
  'AFuzion Standard',
  'Index',
  'Best Practice',
];

export const trainingCoursesContentTypeList = ['Classroom Training', 'Online Courses'];

export const getStandardsMappedUrl = (contentType: string): string => {
  let mappedUrl: string;
  if (contentType == 'Aerospace Material Specification') mappedUrl = 'aerospace-material-specification';
  else if (contentType == 'Aerospace Standard') mappedUrl = 'aerospace';
  else if (contentType == 'Ground Vehicle Standard') mappedUrl = 'ground-vehicle';
  else if (contentType == 'SAE ITC E&A Standard') mappedUrl = 'sae-itc';
  else if (contentType == 'ARINC Standard') mappedUrl = 'arinc';
  else if (contentType == 'AFuzion Standard') mappedUrl = 'standard';
  else if (contentType == 'Index') mappedUrl = 'index';
  else if (contentType == 'Best Practice') mappedUrl = 'best-practice';

  return mappedUrl;
};
export const getTrainingsMappedUrl = (contentType: string): string => {
  let mappedUrl: string;
  if (contentType == 'Classroom Training') mappedUrl = 'classroom';
  else if (contentType == 'Online Courses') mappedUrl = 'online';

  return mappedUrl;
};
