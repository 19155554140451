import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { SaeHttpResponse } from '@sae/models';
import { Observable } from 'rxjs';
import { Api } from '../../../api';
import {
  AccessControlResult,
  AppSearchSearchRequest,
  BatteryDBSearchRequestFacets,
  BatteryDBSearchResponse,
  GainsightResult,
  Invitation,
  MobilusSearchResponse,
  MobilusSearchSuggestionResponse,
  Resource,
  UsageEvent,
  UserFolderAccess,
} from '../models/api-models';
import { Asset } from '../models/common-interfaces';
import { SearchBody } from '../models/search-request.model';
import { SearchResult } from '../models/search-result.model';
import { AuthService } from '../state/auth.service';
import { GainSightDetails, REMOTE_CONFIG_TOKEN } from '@sae/services';
import { IEnvironmentConfigService } from '@sae/base';
import { MobilusProteusConfig } from '../../env.config';
import { BatteryCell } from '../models/battery-cell.model';
import { SubscriptionSession } from '../state/subscriptions.service';
import { SortDirection } from '@angular/material/sort';

export interface HomepageCounts {
  aerospaceIndustry: number;
  allPubs: number;
  automotiveIndustry: number;
  commercialVehicleIndustry: number;
  compContent: number;
  governmentDefenseIndustry: number;
  medicalIndustry: number;
  peerReviewed: number;
  recentlyPublished: number;
  standards: number;
}

export interface SearchHttpOptions {
  headers?: HttpHeaders;
}

@Injectable({
  providedIn: 'root',
})
export class MobilusApiService {
  private authService: AuthService;
  constructor(
    private injector: Injector,
    private httpClient: HttpClient,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {
    this.authService = injector.get(AuthService);
  }

  public search(request: SearchBody): Observable<SearchResult> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/search`;
    return this.httpClient.post<SearchResult>(url, request);
  }

  public searchV2(req: AppSearchSearchRequest, options?: SearchHttpOptions): Observable<MobilusSearchResponse> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${Api.search.url}`;
    return this.httpClient.post<MobilusSearchResponse>(url, req, options);
  }

  public getDownloadIdFromAssetId(assetId: string): Observable<SaeHttpResponse<string>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/assets/${assetId}`;
    return this.httpClient.get<SaeHttpResponse<string>>(url);
  }

  public getMobilusFolders(): Observable<SaeHttpResponse<Resource>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders`;
    return this.httpClient.get<SaeHttpResponse<Resource>>(url);
  }

  public postMobilusFolders(params: { name: string; parentFolderId?: string }): Observable<SaeHttpResponse<Resource>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders`;
    const httpParams = new HttpParams(
      params?.parentFolderId ? { fromObject: params } : { fromObject: { name: params.name } }
    );
    return this.httpClient.post<SaeHttpResponse<Resource>>(url, httpParams);
  }

  public deleteMobilusFolders(params: { cid: number; folderIds: string[] }): Observable<object> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders`;
    const httpParams = new HttpParams({ fromObject: params });
    return this.httpClient.delete(url, { params: httpParams });
  }

  public postMobilusFolderItems(cid: number, id: string): Observable<SaeHttpResponse<Resource>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders/${id}/items/${cid}`;
    return this.httpClient.post<SaeHttpResponse<Resource>>(url, null);
  }

  public deleteMobilusFolderItems(cid: number, id: string): Observable<SaeHttpResponse<Resource>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders/${id}/items/${cid}`;
    return this.httpClient.delete<SaeHttpResponse<Resource>>(url);
  }

  public deleteMobilusFolder(folderId: string): Observable<SaeHttpResponse<Resource>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders/${folderId}`;
    return this.httpClient.delete<SaeHttpResponse<Resource>>(url);
  }
  public renameMobilusFolder(folder: Resource): Observable<SaeHttpResponse<Resource>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders/${folder.id}`;
    return this.httpClient.patch<SaeHttpResponse<Resource>>(url, folder);
  }

  public getMobilusFolderItems(
    anchorId: string,
    order: string,
    sort: string,
    limit: number,
    offset: number
  ): Observable<SaeHttpResponse<Resource>> {
    const params = new HttpParams({
      fromObject: { order, sort, limit, offset },
    });
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/mobilus-folders/${anchorId}/items`;
    return this.httpClient.get<SaeHttpResponse<Resource>>(url, { params });
  }

  public getAssets(contentId: number, params: HttpParams): Observable<SaeHttpResponse<Asset>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/content/${contentId}/assets`;
    return this.httpClient.get<SaeHttpResponse<Asset>>(url, { params });
  }

  public postUsageTracking(event: UsageEvent): Observable<SaeHttpResponse<UsageEvent>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/usage-events`;
    return this.httpClient.post<SaeHttpResponse<UsageEvent>>(url, event);
  }

  public getAccessProperties(params: HttpParams): Observable<SaeHttpResponse<AccessControlResult>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.mobilus.url
    }/access-properties`;
    return this.httpClient.get<SaeHttpResponse<AccessControlResult>>(url, { params });
  }

  public getHomepageCounts(): Observable<HomepageCounts> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/counts/homePageCounts`;
    return this.httpClient.get<HomepageCounts>(url);
  }

  public getEFirstArticles(
    params: {
      magCode: number;
      query?: string;
      sort?: string;
      order?: string;
      size?: number;
      current?: number;
    },
    options?: SearchHttpOptions
  ): Observable<MobilusSearchResponse> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/content/efirst`;
    return this.httpClient.get<MobilusSearchResponse>(url, {
      params: new HttpParams({ fromObject: params }),
      ...options,
    });
  }

  public getGainsightInfo(track = false, accountId = '1111111111'): Observable<SaeHttpResponse<GainSightDetails>> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/gainsight/users/me`;
    const params = new HttpParams().set('trackingAccepted', track);
    const headers = new HttpHeaders().set('X-SAE-CUSTNUM', accountId);
    return this.httpClient.get<SaeHttpResponse<GainSightDetails>>(url, { params, headers });
  }

  public postAdvancedSearch(
    req: AppSearchSearchRequest,
    options?: SearchHttpOptions
  ): Observable<MobilusSearchResponse> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/advancedSearch`;
    return this.httpClient.post<MobilusSearchResponse>(url, req, options);
  }

  public getUsersForFolder(req: {
    anchorId: string;
    sort?: string;
    order?: string;
    limit?: number;
    offset?: number;
  }): Observable<SaeHttpResponse<UserFolderAccess>> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/mobilus-folders/${req.anchorId}/users`;
    return this.httpClient.get<SaeHttpResponse<UserFolderAccess>>(url);
  }
  public sendInvitationToAddUserToFolder(
    anchorId: string,
    recipientEmail: string,
    folderRole: string
  ): Observable<SaeHttpResponse<Invitation>> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/mobilus-folders/${anchorId}/invitations`;
    return this.httpClient.post<SaeHttpResponse<Invitation>>(url, { recipientEmail, folderRole });
  }

  //Battery Cell APIs
  public getBatteryCellDetail(id: string): Observable<SaeHttpResponse<BatteryCell>> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/battery-cells/${id}`;
    return this.httpClient.get<SaeHttpResponse<BatteryCell>>(url);
  }
  public searchBatteryDb(req: AppSearchSearchRequest): Observable<BatteryDBSearchResponse> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/battery-cells/search`;
    return this.httpClient.post<BatteryDBSearchResponse>(url, req);
  }

  public getSubscriptions(params: {
    offset?: number;
    limit?: number;
    order?: SortDirection;
    sort?: 'subscriptionTitle' | 'subscriptionEndDate';
    status?: 'ACTIVE' | 'INACTIVE';
  }) {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/subscriptions`;
    return this.httpClient.get<SaeHttpResponse<SubscriptionSession>>(url, { params });
  }
  public getBatteryDBSuggestions(query: string): Observable<MobilusSearchSuggestionResponse> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/battery-cells/search/suggestions`;
    return this.httpClient.post<MobilusSearchSuggestionResponse>(url, {
      query,
      size: 5,
      types: {
        documents: {
          fields: ['supplierName', 'model'],
        },
      },
    });
  }

  public modifyUserFolderPermissions(
    folderId: string,
    userId: string,
    role: 'ALL' | 'READ_ONLY'
  ): Observable<SaeHttpResponse<UserFolderAccess>> {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/mobilus-folders/${folderId}/users/${userId}/permissions`;
    return this.httpClient.patch<SaeHttpResponse<UserFolderAccess>>(url, { folderRole: role });
  }

  public deleteUserFromFolder(folderId: string, userId: string) {
    const url = `${
      this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl
    }/v2/mobilus/mobilus-folders/${folderId}/users/${userId}`;
    return this.httpClient.delete(url);
  }
}
