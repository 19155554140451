import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartIconComponent } from '../../parts/part-icon/part-icon.component';
import { overrideInputs } from '@sae/base';

export type AvatarScheme = '' | 'fill';
export type AvatarState = 'caution' | 'dimmed' | 'negative' | 'positive' | null;
export type AvatarType = 'materialIcon' | 'picture' | 'text' | null;

export interface AvatarContent {
  type: AvatarType;
  scheme?: AvatarScheme;
  src: string;
  state?: AvatarState;
}

@Component({
  selector: 'fs-comp-avatar',
  templateUrl: './comp-avatar.component.html',
  styleUrls: ['./comp-avatar.component.scss'],
  imports: [CommonModule, PartIconComponent],
  standalone: true,
})
export class CompAvatarComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: AvatarContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() type: AvatarType;
  @Input() scheme: AvatarScheme; // Design style
  @Input() src = ''; // icon name or path
  @Input() state: AvatarState;
  @Input() avatar: AvatarContent; // [DEPRECATED - Will be removed]

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['avatar']) {
      overrideInputs(this, this.avatar);
    }
  }
}
