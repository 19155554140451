import { FormControl, ValidationErrors } from '@angular/forms';

export function scroll(el: HTMLElement): void {
  el.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

export function noWhitespaceValidator(this: void, control: FormControl): { whitespace: boolean } | null {
  const isWhitespace = ((control.value as string) || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

export function getFileExtension(filename: string): string {
  const a = filename.split('.');
  if (a.length === 1 || (a[0] === '' && a.length === 2)) {
    return '';
  }
  return a.pop() ?? '';
}

export function validUrl(prependHttps = false): (control: FormControl) => ValidationErrors | null {
  return (control: FormControl): ValidationErrors | null => {
    const urlOK = isValidUrl((prependHttps ? 'https://' : '') + (control.value as string));
    return urlOK ? null : { badUrl: { value: control.value as string } };
  };
}

export function isValidUrl(url: string, allowHttp = false): boolean {
  let urlObj: URL;
  try {
    urlObj = new URL(url);
  } catch {
    return false;
  }
  return urlObj.protocol === 'https:' || (urlObj.protocol === 'http:' && allowHttp);
}

export function delimit(arr: string[], delimiter: string): string {
  let output = '';
  if (arr?.length) {
    output = arr.join(delimiter);
  }
  return output;
}

export function stripProtocolPrefix(url: string): string {
  if (/^https?:\/\//.test(url)) {
    return url.substr(url.indexOf('://') + 3).trim();
  }
  return url;
}

export function hasExtension(file: File, exts: string[]): boolean {
  const fileName = file.name;
  return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(fileName);
}
