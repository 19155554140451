<div class="si-sort-row">
  <button
    mat-icon-button
    data-testid="menuTriggerButton"
    class="si-button--icon si-icon"
    matTooltip="Sort Options"
    aria-label="Sort Options"
    [matMenuTriggerFor]="sortOpts"
  >
    <mat-icon aria-hidden="true">{{ icon }}</mat-icon>
  </button>
  <mat-menu #sortOpts="matMenu" class="si-menu">
    <button
      mat-menu-item
      data-testid="sortOption"
      [class.si-state--selected-not]="sortBy !== option"
      (click)="changeActive(option)"
      *ngFor="let option of options"
    >
      <mat-icon aria-hidden="true">check</mat-icon>
      <span>{{ option }}</span>
    </button>
  </mat-menu>
  <span>
    <button class="si-button si-button--sort" data-testid="sort" (click)="changeActive()" *ngIf="sortBy">
      <span>{{ sortBy }}</span>
      <mat-icon class="si-icon" *ngIf="sortDir === 'asc'" aria-label="Sort by Ascending">arrow_upward</mat-icon>
      <mat-icon class="si-icon" *ngIf="sortDir === 'desc'" aria-label="Sort by Descending">arrow_downward</mat-icon>
    </button>
  </span>
  <span class="si-expanse"></span>
  <button
    *ngIf="showRefreshButton"
    data-testid="refresh"
    class="si-button si-button--icon"
    mat-icon-button
    matTooltip="Refresh List"
    aria-label="Refresh List"
    (click)="onRefreshClicked()"
  >
    <mat-icon aria-hidden="true">refresh</mat-icon>
  </button>
</div>
