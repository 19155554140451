import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailToolTakeoverViewBase } from '@sae/features';
import { Observable, switchMap, tap } from 'rxjs';
import { DetailsHelperService } from '../../services/details-helper.service';
import { SessionService } from '../../../../shared/state/session.service';
import { ContentService } from '../../../../shared/state/content.service';
import { SeoUtilityService } from '../../../../shared/services/seo-utility.service';
import { AuthService } from '../../../../shared/state/auth.service';
import { DetailsConfiguration } from '../../../../shared/state/content.store';
@UntilDestroy()
@Component({
  templateUrl: '../templates/detail.component.html',
  host: { 'collision-id': 'StandardComponent' },
})
export class StandardComponent extends DetailToolTakeoverViewBase implements OnInit, OnDestroy {
  public fragment$: Observable<string> = this.route.fragment;
  public routeLoading$: Observable<boolean> = this.contentService.routeLoading$;
  public isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  public config$ = this.contentService.config$;

  constructor(
    public sessionService: SessionService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private detailsHelperService: DetailsHelperService,
    private seoUtilityService: SeoUtilityService,
    private authService: AuthService
  ) {
    super(sessionService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        untilDestroyed(this),
        tap((data) =>
          this.seoUtilityService.setMetaTags(
            `${data?.content?.code}: ${data?.content?.title} - ${data?.content?.subGroup}`,
            data?.content?.abstract
          )
        ),
        tap((data) => this.setConfigValue(data)),
        switchMap((data) => this.detailsHelperService.getAdditionalDetails(data?.content))
      )
      .subscribe((cv) => this.contentService.setContentView(cv));
  }
  setConfigValue(data): void {
    let customConfig = {};
    switch (data?.content?.subGroup) {
      case 'GV Companion Document':
        customConfig = {
          panel_enabled: false,
          panel_type: null,
          detail_show_provide_feedback: true,
        };
        break;
      default:
        break;
    }
    this.updateDetailsConfig(customConfig);
  }

  updateDetailsConfig(customConfig: Partial<DetailsConfiguration>): void {
    this.contentService.updateDetailsConfiguration({
      panel_enabled: true,
      detail_masthead_subheading_type: 'status',
      detail_description_summary_title: 'Scope',
      panel_title: 'Revisions',
      header_aux_text: `Revisions`,
      panel_collapse_text: 'Collapse Revisions Panel',
      detail_show_features: true,
      detail_show_digital_standard: true,
      detail_show_issuing_committee: true,
      detail_show_abstract: true,
      detail_show_rationale: true,
      detail_show_tags: true,
      detail_show_details: true,
      detail_show_doi: true,
      detail_show_citations_badge: true,
      detail_show_page_count: true,
      detail_show_citation: true,
      detail_show_table_of_contents: true,
      detail_show_additional_details: true,
      detail_show_publisher: true,
      detail_show_pub_date: true,
      detail_show_code: true,
      detail_show_selected_type: true,
      detail_show_standard_status: true,
      detail_show_language: true,
      detail_show_view: true,
      detail_show_2d_3d: true,
      detail_show_data_sets: true,
      detail_show_references: true,
      detail_show_related: true,
      panel_type: 'standard',
      detail_masthead_title_with_code: true,
      detail_show_alert: true,
      ...customConfig,
    });
  }
  ngOnDestroy(): void {
    this.contentService.resetStore();
    this.seoUtilityService.clearAllSEOTags();
  }
}
