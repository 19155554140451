<mat-accordion class="si-expanel__container si-expanel--singleton si-expanel--browser" tabindex="0">
  <mat-expansion-panel class="si-expanel si-expanel--tree" [expanded]="expanded">
    <mat-expansion-panel-header class="si-expanel__h">
      <mat-panel-title class="si-expanel__title">
        <div class="si-entity si-spinner__container" *ngIf="anchorLoading">
          <mat-spinner class="si-spinner" diameter="17"></mat-spinner>
        </div>
        <si-breadcrumb
          [breadcrumbs]="breadcrumbs"
          [displayProp]="'name'"
          [required]="required"
          [error]="error"
          (goto)="onNavigate($event)"
        ></si-breadcrumb>
        <span class="si-expanse"></span>
        <si-overflow-menu
          [menuData]="mainMenu"
          (menuItemClicked)="onMenuItemClick($event)"
          (menuIsOpen)="onMenu($event)"
        ></si-overflow-menu>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="si-expanel__c">
      <div class="si-card--dataviewer__data">
        <div class="si-card--dataviewer__data__h">
          <div class="si-sort" [class.si-unusual]="selectionEnabled">
            <div *ngIf="filterChip" class="si-filters__chip">
              <mat-chip-list aria-label="Filters" class="si-chiplist si-chiplist--filter">
                <mat-chip class="si-chip si-chip--complex" aria-label="Remove" (click)="this.filterChipClicked.emit()">
                  <span>{{ filterChip }}</span>
                  <mat-icon matChipRemove title="Change">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <div *ngIf="selectionEnabled">
              <si-multi-select-toolbar
                class="si-toolbar__container--actions"
                [selectAllChecked]="selectionAllToggled"
                [minifiedMode]="selectionMinifiedMode"
                [actionOptions]="selectionActionOptions"
                [moreMenu]="true"
                (actionSelected)="onActionSelected($event)"
                (selectAllChanged)="onSelectAllChanged($event)"
              ></si-multi-select-toolbar>
            </div>
            <si-resource-sort
              *ngIf="enableSort"
              [sortProperty]="sortBy"
              [sortDirection]="sortDirection"
              [showModifiedByUser]="appliedDisplaySettings && appliedDisplaySettings.byUser"
              (onRefresh)="onRefresh()"
              (onSortChange)="onSortChange($event)"
              [shareCount]="enableSharing ? shareCount : 0"
              [enableShare]="true"
              (onShareChipClicked)="onShareDialog()"
            ></si-resource-sort>
          </div>
        </div>
        <div class="si-spinner--scroll__container" *ngIf="resourcesLoading; else resourcesLoaded">
          <mat-spinner class="si-spinner" diameter="20"></mat-spinner>
        </div>
        <ng-template #resourcesLoaded>
          <ng-container *ngFor="let r of childResources; let i = index">
            <mat-divider *ngIf="i < morePages * morePaginationCount" class="si-divider"></mat-divider>
            <si-resource-card
              *ngIf="i < morePages * morePaginationCount"
              class="si-component"
              [title]="r.name"
              [modifiedDate]="r.modificationDate"
              [modifiedByUser]="r | resourceUser"
              [type]="r | resourceType"
              [fileExt]="r.fileType"
              [href]="r.metadata && (r.metadata.resourceLink ?? '')"
              [enableRename]="(r | resourcePermissions: 'edit') && !readonly"
              [enableDelete]="(r | resourcePermissions: 'delete') && !readonly"
              [enableDownload]="r.resourceType !== 'FOLDER' && r.resourceType !== 'LINK' && !disableDownload"
              [showModifiedTime]="appliedDisplaySettings && appliedDisplaySettings.time"
              [showModifiedByUser]="appliedDisplaySettings && appliedDisplaySettings.byUser"
              [enableCopyLink]="r.resourceType !== 'FOLDER' && enableCopyLink"
              [enableCopyName]="enableCopyName"
              [enableCopyUrlMenuItem]="enableCopyUrlMenuItem"
              [enableCopyFolderUrlMenuItem]="r.resourceType === 'FOLDER' && enableCopyFolderUrlMenuItem"
              [toggleable]="selectionEnabled"
              [toggled]="r.toggled"
              [menuOptions]="r.menuOptions ?? []"
              [replies]="r.replies ?? ''"
              (onDelete)="onDelete(r)"
              (onDownload)="onDownload(r)"
              (onCopy)="onCopy(r)"
              (onCopyName)="onCopyName(r)"
              (onClicked)="onResourceClick(r)"
              (onRename)="onRename(r, $event)"
              (onEditLink)="onEditLink(r)"
              (menuIsOpen)="onMenu($event)"
              (toggleCard)="onToggleCard($event, r)"
            ></si-resource-card>
          </ng-container>
          <div *ngIf="childResources && !childResources.length" class="si-card si-card--simple">
            <div data-testid="emptyMessage" class="si-card si-card--simple">{{ emptyMessage }}</div>
          </div>
        </ng-template>
        <mat-divider *ngIf="addMenu || morePagination || paginator" class="si-divider"></mat-divider>
        <div
          *ngIf="addMenu || morePagination || paginator"
          class="si-actions-row si-actions-row--left si-wrap"
          [class.si-unusual]="morePagination"
        >
          <div *ngIf="addMenu" class="si-actions-row__a">
            <si-overflow-menu
              [menuData]="addMenu"
              (menuItemClicked)="onMenuItemClick($event)"
              (menuIsOpen)="onMenu($event)"
            ></si-overflow-menu>
          </div>
          <ng-container *ngIf="morePagination && morePaginationCount * morePages < childResources?.length">
            <div></div>
            <div>
              <button
                mat-button
                data-testid="viewMore"
                color="primary"
                class="si-button si-button--more"
                (click)="morePages = morePages + 1"
              >
                View More ({{ childResources.length - morePaginationCount * morePages }})
              </button>
            </div>
            <div></div>
          </ng-container>
          <ng-container *ngIf="paginator && !morePagination">
            <span class="si-expanse"></span>
            <div>
              <div class="si-card--dataviewer__data__a si-wd--tablet" *ngIf="paginator && !morePagination">
                <span class="si-expanse"></span>
                <mat-paginator
                  class="si-paginator"
                  [length]="length"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [pageSizeOptions]="pageSizeOptions"
                  (page)="onPage($event)"
                ></mat-paginator>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-error *ngIf="error" class="si-field__error">
  <small>{{ errorMessage }}</small>
</mat-error>
