<div class="si-searchbox" [class.si-searchbox--minor]="type === 'Minor'" [class.si-searchbox--data]="type === 'Data'">
  <form class="si-form">
    <mat-form-field class="si-field si-wd si-wd-full" appearance="legacy">
      <mat-icon matPrefix>search</mat-icon>
      <label for="searchInput{{ uniqueId }}" class="si-visible--sr-only">Search</label>
      <input
        siIncrementalSearch
        (searchChange)="emitSearch($event)"
        [debounceTime]="debounceTime"
        (blur)="emitBlur()"
        (keyup)="emitKeyUp($event)"
        [minChars]="minChars"
        matInput
        class="si-input si-input--search"
        type="text"
        #searchInput
        id="searchInput{{ uniqueId }}"
        [matTooltip]="toolTipText"
        [matTooltipDisabled]="hideTooltTip"
        [value]="defaultSearch"
      />
      <span data-testid="suggestion" class="si-field__placeholder" *ngIf="!searchInput.value">{{ suggestion }}</span>
      <button
        *ngIf="searchInput.value"
        data-testid="clear"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        type="button"
        class="si-button si-button--cancel"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
</div>
