import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from './state/auth.service';
import { CookieService } from 'ngx-cookie';
import { isPlatformServer } from '@angular/common';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../env.config';

@Injectable()
export class SubTokenInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {}

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    if (isPlatformServer(this.platformId)) {
      return next.handle(request);
    }
    const token = this.cookieService.get('X-Auth-Token');

    if (!request.url.includes(this.envConfigService.envConfig<MobilusProteusConfig>()?.services?.apiRootUrl)) {
      return next.handle(request);
    } else if (!token) {
      return next.handle(request).pipe(
        tap((event: HttpEvent<object>) => {
          if (event instanceof HttpResponse) {
            const newToken = event.headers.get('X-Auth-Token');
            if (!newToken) {
              return;
            }
            if (window.location.origin.includes('localhost')) {
              this.cookieService.put('X-Auth-Token', newToken);
            } else {
              this.cookieService.put('X-Auth-Token', newToken, {
                domain: window.location.host.substring(window.location.host.indexOf('.') + 1).split(':')[0],
              });
            }
          }
        })
      );
    } else {
      return next.handle(
        request.clone({
          setHeaders: {
            'X-Auth-Token': token,
          },
        })
      );
    }
  }
}
