import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NG_ENTITY_SERVICE_CONFIG, NgEntityServiceGlobalConfig } from '@datorama/akita-ng-entity-service';
import { ApiRegistry, ApiToken, GainSightService } from '@sae/services';
import { Observable, catchError, map, of } from 'rxjs';
import { SubscriptionsService } from '../state/subscriptions.service';
import { MobilusApiService } from './mobilus-api.service';

export interface GainSightDetails {
  user: {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    signUpDate: number;
    userHash: string;
    company: {
      companyId: string;
      companyName: string;
    };
    employer: {
      customerNumber: number;
    };
  };
  account: {
    id: string;
    name: string;
  };
}

declare global {
  interface Window {
    dataLayer: unknown[];
    aptrinsic: (field: string, user: object, account: object) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MobilusGainSightService extends GainSightService {
  constructor(
    http: HttpClient,
    @Inject(NG_ENTITY_SERVICE_CONFIG)
    ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) apiReg: ApiRegistry,
    private mobilusApiService: MobilusApiService,
    private subService: SubscriptionsService,
    @Inject(PLATFORM_ID) protected platformId
  ) {
    super(http, ngEntityServiceGlobalConfig, apiReg, platformId);
  }

  loadGainsightUser(isCookieAccepted: boolean): Observable<GainSightDetails> {
    const accountId = this.subService.getSubscriptions().find((sub) => sub?.accountId)?.accountId ?? '1111111111';
    return this.mobilusApiService.getGainsightInfo(isCookieAccepted, accountId).pipe(
      map((res) => {
        this.gainSightDetails$.next(res.results[0]);
        return this.gainSightDetails;
      }),
      catchError(() => of(null))
    );
  }
}
