import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TableParams, UISearchFilters } from '../models/common-interfaces';
import { CriteriaGroup } from '../models/criterion.model';
import { Query } from '../models/search-request.model';
import { AppSearchRequestFilter } from '../models/api-models';
import { SubscriptionsService } from '../state/subscriptions.service';
import { Criteria } from '../../modules/search/components/advanced-search/advanced-search.component';

export interface PredefinedFilters {
  published: {
    to: Date;
    from: Date;
  };
}

export interface FragmentQueryData {
  facets?: Query[];
  params?: Partial<TableParams>;
  predefinedFilters?: Partial<PredefinedFilters>;
}

export interface FragmentData {
  queryTerm: string;
  filters: UISearchFilters;
  pubDate: {
    from: Date;
    to: Date;
  };
  customFilters: UISearchFilters;
  advancedSearchCriteria?: Criteria[];
}

@Injectable({
  providedIn: 'root',
})
export class FragmentService {
  constructor(private router: Router, private subscriptionsService: SubscriptionsService) {}

  public buildFragment2(fragmentData: FragmentData): string {
    let fragment = '';
    if (fragmentData.queryTerm) {
      fragment += `q=${encodeURIComponent(fragmentData.queryTerm)}&`;
    }
    if (fragmentData.advancedSearchCriteria?.length) {
      fragment += `adv=${JSON.stringify(fragmentData.advancedSearchCriteria).replace(/,/g, '%2C')}&`;
    }
    Object.entries(fragmentData.customFilters).forEach(([key, values], index) => {
      if (!values?.length) {
        return;
      }
      fragment += `${key}=`;
      values.forEach((value, j) => {
        fragment += encodeURIComponent(value);
        if (j < values.length - 1) {
          fragment += `,`;
        }
      });
      fragment += '&';
    });

    Object.entries(fragmentData.filters).forEach(([key, values], index) => {
      if (!values?.length) {
        return;
      }
      fragment += `${key}=`;
      values.forEach((value, j) => {
        fragment += encodeURIComponent(value);
        if (j < values.length - 1) {
          fragment += `,`;
        }
      });
      fragment += '&';
    });

    if (fragmentData.pubDate) {
      const fromDate = `${fragmentData.pubDate?.from?.getFullYear()}-${
        fragmentData.pubDate?.from?.getMonth() + 1
      }-${fragmentData.pubDate?.from?.getDate()}`;
      const toDate = `${fragmentData.pubDate?.to?.getFullYear()}-${
        fragmentData.pubDate?.to?.getMonth() + 1
      }-${fragmentData.pubDate?.to?.getDate()}`;
      fragment += `pub_date=${fromDate},${toDate}&`;
    }

    fragment = fragment.substring(0, fragment.length - 1);

    return fragment;
  }

  public fragmentToFilters(fragment: string): FragmentData {
    let mySubRedirect = false;
    if (!fragment || fragment === 'tags') {
      return {
        advancedSearchCriteria: [],
        queryTerm: '',
        filters: {},
        customFilters: {},
        pubDate: undefined,
      };
    }
    const filters = {};
    const customFilters = {};
    let queryTerm = '';
    let advancedSearchCriteria = [];
    let from,
      to = undefined;
    const queries = fragment.split('&');
    queries.forEach((query) => {
      const [filterName, encodedValues] = query.split('=');
      const decodedValues = encodedValues.split(',').map((v) => decodeURIComponent(v));
      if (filterName === 'q') {
        queryTerm = decodedValues[0];
      } else if (filterName === 'adv') {
        advancedSearchCriteria = JSON.parse(decodedValues[0]);
      } else if (filterName === 'pub_date') {
        from = new Date(decodedValues[0]);
        to = new Date(decodedValues[1]);
      } else if (filterName === 'only') {
        const subscriptions = this.subscriptionsService.getSubscriptions();
        if (subscriptions?.length > 0) {
          customFilters[filterName] = decodedValues;
        } else {
          customFilters[filterName] = decodedValues.filter((decodedValue) => decodedValue !== 'My Subscription');
          mySubRedirect = decodedValues.some((decodedValue) => decodedValue == 'My Subscription');
        }
      } else {
        filters[filterName] = decodedValues;
      }
    });
    if (mySubRedirect) {
      const updatedFragment = fragment.replace(/,My%20Subscription|My%20Subscription,/gi, '');
      this.router.navigate([this.router.url.split('#')[0]], {
        replaceUrl: true,
        fragment: updatedFragment,
      });
    }
    return {
      advancedSearchCriteria,
      queryTerm,
      filters,
      customFilters,
      pubDate:
        from && to
          ? {
              from,
              to,
            }
          : undefined,
    };
  }

  public buildFragmentFromCriteria2(fragmentData: FragmentData): string {
    let fragment = '';
    if (fragmentData.queryTerm) {
      fragment += `q=${encodeURIComponent(fragmentData.queryTerm)}&`;
    }
    if (fragmentData.advancedSearchCriteria) {
      fragment += `adv=${JSON.stringify(fragmentData.advancedSearchCriteria).replace(/,/g, '%2C')}&`;
    }
    const isFullTextContentChecked = fragmentData?.filters?.all?.some((filter) =>
      filter['full_text_formats']?.includes('XML')
    );
    if (Object.entries(fragmentData?.customFilters).length || isFullTextContentChecked) {
      fragment += `only=`;

      if (fragmentData?.customFilters['new_pub']) fragment += encodeURIComponent('New Content');
      if (fragmentData?.customFilters['new_pub'] && fragmentData?.customFilters['my_sub']) fragment += `,`;
      if (fragmentData?.customFilters['my_sub']) fragment += encodeURIComponent('My Subscription');
      if (
        fragmentData?.customFilters['new_pub'] ||
        (fragmentData?.customFilters['my_sub'] &&
          fragmentData?.filters.all.some((filter) => filter['full_text_formats']?.includes('XML')))
      )
        fragment += `,`;
      if (fragmentData?.filters.all.some((filter) => filter['full_text_formats']?.includes('XML')))
        fragment += encodeURIComponent('Full Text Content');

      fragment += '&';
    }

    if (isFullTextContentChecked) {
      const filters = [...fragmentData.filters.all];
      const filtersWithOutFullText = filters.map((filter) => {
        if (filter['full_text_formats']?.includes('XML')) {
          const formatsWithOutFullTextContent = filter['full_text_formats'].filter(
            (format) => format !== 'XML' && format !== 'PDF'
          );

          return {
            full_text_formats: formatsWithOutFullTextContent,
          };
        }
        return filter;
      });
      fragmentData = { ...fragmentData, filters: { all: filtersWithOutFullText as any } };
    }
    fragmentData?.filters?.all?.forEach((filter) => {
      Object.entries(filter).forEach(([key, values], index) => {
        if (!values?.length || key === 'total') {
          return;
        }
        fragment += `${key}=`;
        const filterValues = values as any as string[];
        filterValues.forEach((value, j) => {
          fragment += encodeURIComponent(value);
          if (j < values.length - 1) {
            fragment += `,`;
          }
        });
        fragment += '&';
      });
    });

    if (fragmentData.pubDate) {
      const fromDate = fragmentData.pubDate?.from;
      const toDate = fragmentData.pubDate?.to;
      fragment += `pub_date=${fromDate},${toDate}&`;
    }

    fragment = fragment.substring(0, fragment.length - 1);

    return fragment;
  }
}
