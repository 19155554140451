import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { AuthService } from '../../shared/state/auth.service';
import { Api } from '../../../api';
import { ActivatedRoute, Router } from '@angular/router';
import { DetailViewBase } from '@sae/features';
import { SessionService } from '../../shared/state/session.service';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { IEnvironmentConfigService } from '@sae/base';
import { MobilusProteusConfig } from '../../env.config';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'mobi-start-session',
  templateUrl: './start-session.component.html',
})
export class StartSessionComponent extends DetailViewBase implements AfterViewInit {
  @ViewChild('ssoLoginForm') ssoLoginForm!: ElementRef;

  public ssoUrl = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
    Api.subscriptions.url
  }/sessions`;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService,
    private router: Router,
    protected sessionService: SessionService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super(sessionService);
  }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const entityId =
      this.route.snapshot.queryParams?.entityId ?? this.route.snapshot.queryParams?.entityID ?? undefined;
    if (!entityId) {
      this.router.navigateByUrl('/');
    }
    let targetUrl = this.route.snapshot.queryParams?.target;
    if (entityId && !targetUrl) {
      const currApp = this.envConfigService
        .envConfig<MobilusProteusConfig>()
        .enterpriseMenu.apps.find((app) => app.currentApp);
      targetUrl = currApp.href;
    }
    const uuid = this.generateUUID();
    localStorage.setItem('ssoState', uuid);
    this.ssoLoginForm.nativeElement.targetUrl.value = targetUrl;
    this.ssoLoginForm.nativeElement.entityId.value = entityId;
    this.ssoLoginForm.nativeElement.state.value = uuid;
    setTimeout(() => {
      this.ssoLoginForm.nativeElement.submit();
    }, 0);
  }

  generateUUID(): string {
    let d = new Date().getTime(),
      d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
  }
}
