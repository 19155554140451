<ng-container *ngIf="!url">
  <button
    *ngIf="!fauxButton"
    siThemeButton
    (click)="onButtonClick($event)"
    class="si-button--{{ type }} {{ classes }}"
    [class.si-button]="type !== 'plain'"
    [class.si-contains--image]="type === 'image' && imageSrc"
    [class.si-fine]="fine"
    [class.si-minor]="minor"
    [class.si-shadow]="shadow"
    [class.si-state--disabled]="disableButton"
    [class.si-state--subdued]="subdued"
    [class.si-state--selected]="selected"
    [class.si-state--working]="working"
    [attr.aria-checked]="role === 'checkbox' || role === 'radio' ? selected ?? false : null"
    [attr.aria-controls]="ariaControls ?? null"
    [attr.aria-expanded]="ariaExpanded ?? null"
    [attr.aria-haspopup]="ariaHaspopup ?? null"
    [attr.aria-label]="ariaLabel ?? null"
    [attr.disabled]="disableButton ? true : null"
    [attr.id]="buttonId ?? null"
    [attr.itemprop]="itempropButton ?? null"
    [attr.role]="role"
    [attr.tabindex]="tabindex"
    [attr.tabtext]="label"
    [attr.title]="tooltip ?? null"
    #buttonElement
  >
    <ng-container *ngTemplateOutlet="body"></ng-container>
  </button>
  <ng-container *ngIf="fauxButton">
    <ng-container *ngTemplateOutlet="faux"></ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="url">
  <ng-container *ngIf="!disableLinks">
    <ng-container *ngIf="external || _domainPath">
      <a
        #linkElementExternal
        siThemeButton
        class="si-button si-button--{{ type }} {{ classes }} si-external-link"
        [class.si-contains--image]="type === 'image' && imageSrc"
        [class.si-fine]="fine"
        [class.si-minor]="minor"
        [class.si-shadow]="shadow"
        [class.si-state--disabled]="disableButton"
        [class.si-state--selected]="selected"
        [attr.id]="buttonId ?? null"
        [attr.itemprop]="itempropButton"
        [attr.role]="role"
        [attr.title]="tooltip"
        [attr.tabindex]="tabindex"
        [attr.tabtext]="label"
        [attr.target]="external ? '_blank' : undefined"
        [href]="url"
      >
        <ng-container *ngTemplateOutlet="body"></ng-container>
      </a>
    </ng-container>
    <ng-container *ngIf="!external && !_domainPath">
      <ng-container *ngIf="dynamicHashOptions">
        <a
          #linkElement
          siThemeButton
          [siDynamicHash]="dynamicHashOptions"
          class="si-button si-button--{{ type }} {{ classes }}"
          [class.si-contains--image]="type === 'image' && imageSrc"
          [class.si-fine]="fine"
          [class.si-minor]="minor"
          [class.si-shadow]="shadow"
          [class.si-state--disabled]="disableButton"
          [class.si-state--selected]="selected"
          [class.si-state--subdued]="subdued"
          [attr.id]="buttonId ?? null"
          [attr.itemprop]="itempropButton"
          [attr.aria-label]="ariaLabel ?? null"
          [attr.role]="role"
          [attr.tabindex]="tabindex"
          [attr.tabtext]="label"
          [attr.title]="tooltip"
        >
          <ng-container *ngTemplateOutlet="body"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="!dynamicHashOptions">
        <ng-container *ngIf="!mailto; else mailtoLink">
          <a
            #linkElement
            siThemeButton
            [routerLink]="url"
            class="si-button si-button--{{ type }} {{ classes }}"
            [class.si-contains--image]="type === 'image' && imageSrc"
            [class.si-fine]="fine"
            [class.si-minor]="minor"
            [class.si-shadow]="shadow"
            [class.si-state--selected]="selected"
            [class.si-state--subdued]="subdued"
            [attr.id]="buttonId ?? null"
            [attr.itemprop]="itempropButton"
            [attr.role]="role"
            [attr.tabindex]="tabindex"
            [attr.tabtext]="label"
            [attr.title]="tooltip"
          >
            <ng-container *ngTemplateOutlet="body"></ng-container>
          </a>
        </ng-container>
        <ng-template #mailtoLink>
          <a
            #linkElement
            siThemeButton
            [href]="url"
            class="si-button si-button--{{ type }} {{ classes }}"
            [class.si-contains--image]="type === 'image' && imageSrc"
            [class.si-fine]="fine"
            [class.si-minor]="minor"
            [class.si-shadow]="shadow"
            [class.si-state--selected]="selected"
            [class.si-state--subdued]="subdued"
            [attr.aria-label]="ariaLabel ?? null"
            [attr.id]="buttonId ?? null"
            [attr.itemprop]="itempropButton"
            [attr.role]="role"
            [attr.tabindex]="tabindex"
            [attr.tabtext]="label"
            [attr.title]="tooltip"
          >
            <ng-container *ngTemplateOutlet="body"></ng-container>
          </a>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="disableLinks">
    <ng-container *ngTemplateOutlet="faux"></ng-container>
  </ng-container>
</ng-container>

<ng-template #faux>
  <span
    siThemeButton
    class="si-button si-button--{{ type }} {{ classes }}"
    [class.si-button--disabled]="disableButton"
    [class.si-contains--image]="type === 'image' && imageSrc"
    [class.si-fine]="fine"
    [class.si-minor]="minor"
    [class.si-shadow]="shadow"
    [class.si-state--selected]="selected"
    [class.si-state--subdued]="subdued"
    [attr.title]="tooltip"
    #fauxElement
  >
    <ng-container *ngTemplateOutlet="body"></ng-container>
  </span>
</ng-template>

<ng-template #body>
  <ng-container *ngIf="type === 'image' && tooltip && imageSrc">
    <img [src]="imageSrc" [attr.alt]="tooltip" />
  </ng-container>
  <ng-container *ngIf="type !== 'image'">
    <fs-part-spinner *ngIf="working" class="si-button__spinner"></fs-part-spinner>
    <span *ngIf="safeSvg" class="si-button__svg" [class.si-visible--complimentary]="label" [innerHTML]="safeSvg"></span>
    <img *ngIf="imageSrc" class="si-button__img" [class.si-visible--complimentary]="label" [src]="imageSrc" alt="" />
    <fs-part-icon
      *ngIf="materialIcon && (label || tooltip)"
      class="si-button__icon"
      [class.si-visible--complimentary]="label && type !== 'return'"
      aria-hidden="true"
    >
      {{ materialIcon }}
    </fs-part-icon>
    <fs-comp-avatar
      *ngIf="avatar"
      class="si-button__avatar"
      [class.si-visible--complimentary]="label"
      [avatar]="avatar"
    ></fs-comp-avatar>
    <span
      *ngIf="label"
      class="si-button__label"
      [class.si-effect--title]="disableLinks"
      [class.si-visible--complimentary]="labelBrief"
      [innerHTML]="label"
      [attr.itemprop]="itempropLabel ?? null"
    ></span>
    <span
      *ngIf="labelBrief"
      class="si-button__label si-visible--not-complimentary"
      [class.si-effect--title]="disableLinks"
      [innerHTML]="labelBrief"
      [attr.itemprop]="itempropLabel ?? null"
    ></span>
    <fs-part-icon class="si-button__icon si-icon--suffix" *ngIf="suffixIcon" aria-hidden="true">
      {{ suffixIcon }}
    </fs-part-icon>
    <fs-part-icon
      *ngIf="external && !disableExternalIcon"
      class="si-button__suffix si-minor si-icon"
      aria-label="External Link"
      role="note"
    >
      open_in_new
    </fs-part-icon>
  </ng-container>
  <ng-content [body]></ng-content>
</ng-template>
