import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../feature-keys';
import { DetailState } from './detail.state';

export const detailSelector = createFeatureSelector<DetailState>(FeatureKeys.Detail);
export const selectLoadingAssets = createSelector(detailSelector, (state) => state.loadingAssets);
export const selectDataSets = createSelector(detailSelector, (state) => state);
export const selectSortDir = createSelector(detailSelector, (state) => state.sortDir);
export const select2D3DUrl = createSelector(detailSelector, (state) => state.detail2D3DUrl);



