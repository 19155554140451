import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FeatureKeys } from '../feature-keys';
import { EffectsModule } from '@ngrx/effects';
import {  AuthReducers } from '.';
import {  AuthEffects } from './auth.effects';
@NgModule({
  imports: [
    StoreModule.forFeature(FeatureKeys.Auth, AuthReducers.authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
})
export class AuthStoreModule {}
