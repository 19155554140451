import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { CriteriaDetails, saveSearchResponse } from '../models/search-result.model';
import { Api } from '../../../api';
import { MobilusSearchSuggestionResponse } from '../models/api-models';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../env.config';

@Injectable({
  providedIn: 'root',
})
export class SearchManagerApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {}

  postCriteria(body: CriteriaDetails): Observable<saveSearchResponse> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.searchManager.url
    }/appsearch/criteria`;
    return this.httpClient.post<saveSearchResponse>(url, body);
  }

  getSuggestionsV2(args: {
    query: string;
    size?: number;
    types?: { documents: { fields: string[] } };
  }): Observable<MobilusSearchSuggestionResponse> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.search.url
    }/query-suggestions`;
    return this.httpClient.post<MobilusSearchSuggestionResponse>(url, args);
  }
}
