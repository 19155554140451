import { createAction, props } from '@ngrx/store';
import { ContentView } from '../../modules/detail/models/content.model';
import { Asset } from '../../shared/models/common-interfaces';
import { SubscriptionSession } from '../../shared/state/subscriptions.service';
import { DownloadDialogLayout } from './download-document.state';
import { AccessControlResult, MeterDetails } from '../../shared/models/api-models';

export const getAccessToDownload = createAction(
  '[Download Dialog] Get Access to Download',
  props<{ contentId: number; hasAccess: boolean; meterDetails: MeterDetails; asset: Asset }>()
);
export const openAccessSubscriptionDialog = createAction('[Download Dialog] Access Subscription');
export const openPurchaseAccessDialog = createAction('[Download Dialog] Open Purchase Access Dialog');

export const verifyAccess = createAction('[Download Dialog] Verify Access For Document');
export const verifyAccessSucceeded = createAction(
  '[Download Dialog] Verify Access Succeeded',
  props<{ detailContent?: ContentView; downloadContent: ContentView }>()
);
export const verifyAccessFailed = createAction('[Download Dialog] Verify Access Failed');
export const performDownload = createAction('[Download Dialog] Perform Download', props<{ assetId: string }>());
export const setLayout = createAction(
  '[Download Dialog] Set Layout',
  props<{ layout: DownloadDialogLayout; contentId: number; accessProperties: AccessControlResult }>()
);
export const openFromDetailPage = createAction(
  '[Detail Page] Open Download Dialog',
  props<{
    accessProperties: AccessControlResult;
    contentId: number;
    subSessions: SubscriptionSession[];
    purchaseLink: string;
    detailCid?: number;
    title?: string;
    code?: string;
    itemUrl?: string;
    content?: ContentView;
    calledFrom?: string;
  }>()
);
export const openFromSearchResult = createAction(
  '[Search Result] Open Download Dialog',
  props<{
    accessProperties: AccessControlResult;
    contentId: number;
    subSessions: SubscriptionSession[];
    title?: string;
    code?: string;
    itemUrl?: string;
  }>()
);
export const openFromDataSet = createAction(
  '[Data Set] Open Download Dialog',
  props<{
    accessProperties: AccessControlResult;
    contentId: number;
    subSessions: SubscriptionSession[];
    assets: Asset[];
    title?: string;
    code?: string;
    itemUrl?: string;
  }>()
);
export const getAssets = createAction('[Download Dialog] Get Assets', props<{ contentId: number }>());
export const getAssetsSucceeded = createAction('[Download Dialog] Get Assets Succeeded', props<{ assets: Asset[] }>());
export const getAssetsFailed = createAction('[Download Dialog] Get Assets Failed');
export const resetStore = createAction('[Download Dialog] Reset Store');
export const getAccessProperties = createAction(
  '[Download Dialog] Get Access Properties',
  props<{ contentId: number }>()
);
export const getAccessPropertiesSucceeded = createAction(
  '[Download Dialog] Get Access Properties Succeeded',
  props<{ accessProperties: AccessControlResult }>()
);
export const getAccessPropertiesFailed = createAction('[Download Dialog] Get Access Properties Failed');
