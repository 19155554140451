import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { overrideInputs } from '@sae/base';

export interface PartSpinnerItem {
  flipped?: boolean;
  full?: boolean;
  spaced?: boolean;
  tight?: boolean;
  unusualVertical?: boolean;
}

@Component({
  selector: 'fs-part-spinner',
  styleUrls: ['part-spinner.component.scss'],
  templateUrl: './part-spinner.component.html',
  standalone: true,
})
export class PartSpinnerComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartSpinnerItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() flipped: boolean;
  @Input() full: boolean;
  @Input() spaced: boolean;
  @Input() tight: boolean;
  @Input() unusualVertical: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }
}
