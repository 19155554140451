import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  first,
  from,
  iif,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AddToDialogComponent } from '../../../../../shared/components/add-to-dialog/add-to-dialog.component';
import { Alert } from '../../../../../shared/models/alerts.model';
import { SearchResultEntry } from '../../../../../shared/models/search-result.model';
import { AuthService } from '../../../../../shared/state/auth.service';
import { ContentService } from '../../../../../shared/state/content.service';
import { SessionService } from '../../../../../shared/state/session.service';
import { SubscriptionSession, SubscriptionsService } from '../../../../../shared/state/subscriptions.service';
import { UsageEventsService } from '../../../../../shared/state/usage-events.service';
import { DISCLAIMER, LINEBREAK } from '../../../../../shared/string-files/common-strings';
import { RootActions } from '../../../../../store';
import { BrowseActions } from '../../../../../store/browse';
import * as BrowseSelectors from '../../../../../store/browse/browse.selectors';
import { DetailActions, DetailSelectors } from '../../../../../store/detail';
import { DownloadDocumentActions } from '../../../../../store/download-document';
import { SearchActions } from '../../../../../store/search';
import * as SearchSelectors from '../../../../../store/search/search.selectors';
import { CitationOption, ContentView, citationOpts } from '../../../models/content.model';
import { MastheadSnackbarDialogService } from './masthead-snackbar-dialog.service';
import { MobilusPubDocument } from '../../../../../shared/models/api-models';
import { Router } from '@angular/router';
import { UtilityService } from '../../../../../shared/services/utility.service';
import { SaeHttpResponse } from '@sae/models';
import { HttpErrorResponse } from '@angular/common/http';
import { SeoChiplistDto } from '@sae/components';
import { isFuture } from 'date-fns';
import { isPlatformServer } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'mobi-masthead',
  templateUrl: './masthead.component.html',
  styleUrls: [],
})
export class MastheadComponent {
  @Input() baseURL: string;
  @Input() detailViewType: string;
  @Input() selected: string;
  @Input() date: string;
  @Input() libraryActive = false;
  @Input() content: ContentView;
  @Input() returnUrl = '';
  citationOpts = citationOpts;
  downloadActive = true;

  public config$ = this.contentService.config$;
  public content$ = this.contentService.content$;
  public openAllEvent$ = this.contentService.openAllEvent$;
  public closeAllEvent$ = this.contentService.closeAllEvent$;
  public fileFormats$ = this.contentService.fileFormats$;
  public loadingAssets$ = this.store.select(DetailSelectors.selectLoadingAssets);
  public journalBrxDetail$ = this.contentService.journalBrxDetail$;

  public chips$ = this.content$.pipe(
    map((c) => {
      const chips: SeoChiplistDto[] = [];
      for (const industry of c.industryChips ?? []) {
        chips.push({
          label: industry,
          href: '/search',
          fragment: `industrysectors_name=${industry}`,
          classes: 'si-chip--industry',
          tooltip: `Browse ${industry} Content`,
        });
      }

      if (c.subGroup) {
        chips.push({
          label: c.subGroup,
          href: '/search',
          fragment: `sub_group=${c.subGroup}`,
          classes: 'si-chip--role',
          tooltip: `Browse ${c.subGroup} Content`,
        });
      }
      return chips;
    })
  );

  public canDirectlyDownload$ = this.content$.pipe(
    map(
      (content) =>
        content?.hasAccess &&
        (content?.accessProperties?.meterDetails?.metered === false ||
          (!content?.accessProperties?.meterDetails?.requestContentRestricted &&
            content?.accessProperties?.meterDetails?.quantity === 0))
    )
  );

  public isDownloadable$ = this.content$.pipe(
    map(
      (content) =>
        content?.subGroup !== 'Podcast' &&
        content?.subGroup !== 'Video' &&
        content?.subGroup !== 'Journal' &&
        !isFuture(new Date(content?.pubDate).getTime())
    )
  );

  public subSessions$ = this.subService.subscriptions$;
  public hasBeenDownloaded$ = combineLatest([this.content$, this.sessionService.downloadedCids$]).pipe(
    map(([content, cids]) => cids.includes(content?.id))
  );
  public isLoggedIn$ = this.authService.isLoggedIn$;
  public inMyLibrary$ = this.contentService.inMyLibrary$;

  constructor(
    private usageEventsService: UsageEventsService,
    private contentService: ContentService,
    private authService: AuthService,
    private store: Store,
    private snackbarDialogService: MastheadSnackbarDialogService,
    private dialog: MatDialog,
    private subService: SubscriptionsService,
    private sessionService: SessionService,
    private router: Router,
    private utilityService: UtilityService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  openDialog(title: string, type: string): void {
    if (type === 'downloadMetadata') {
      const { id } = this.content;
      const metaDialogRef$ = this.snackbarDialogService.openDownloadMetaDialog('', '', id);
      metaDialogRef$
        .pipe(
          untilDestroyed(this),
          first((v) => !!v)
        )
        .subscribe();
    }
  }

  selectedOption(option: CitationOption): void {
    this.contentService
      .getCitations(option.key)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          response;
        },
        error: () => {
          this.snackbarDialogService.openSnackbar('Error exporting citations', 'DISMISS', { duration: 3000 });
        },
      });
  }

  openClipboardSnackbar(): void {
    this.snackbarDialogService.openSnackbar('URL copied to clipboard', 'DISMISS', { duration: 4500 });
    this.shareDocumentUsageEvent();
  }

  shareDocumentUsageEvent(): void {
    this.store.dispatch(
      RootActions.usageTrackingEvent({
        event: { eventType: 'share_publication', contentIds: [this.content?.id] },
      })
    );
  }

  getCurrentUrl(): string {
    // AU TODO: Resolve this
    if (isPlatformServer(this.platformId)) {
      return '';
    }
    return window.location.href.split('#')[0];
  }

  performIndustryChipSearch(industry: string): void {
    this.router.navigate([`/search`], { fragment: `industrysectors_name=${encodeURIComponent(industry)}` });
  }

  performSubGroupChipSearch(subGroup: string): void {
    this.router.navigateByUrl(this.utilityService.subGroupToBrowseRoute(subGroup));
  }

  toggleAlert(content: ContentView, alertId: string): void {
    this.authService.isLoggedIn$
      .pipe(
        untilDestroyed(this),
        switchMap((loggedIn) => {
          if (loggedIn) {
            return iif(
              () => !!alertId,
              this.contentService.deleteAlert(alertId),
              this.contentService.createAlert(content, 'PUBLICATION')
            ).pipe(
              first((v) => !!v),
              map((v) => {
                if (alertId) {
                  this.snackbarDialogService.openAlertRemoveSuccessSnackbar();
                } else {
                  this.snackbarDialogService.openSetAlertSuccessSnackbar();
                }
                return v;
              }),
              catchError((error: HttpErrorResponse) => {
                if (alertId) {
                  this.snackbarDialogService.openSnackbar('Error removing alert', 'DISMISS', { duration: 3000 });
                } else {
                  this.snackbarDialogService.openSnackbar('Error adding alert', 'DISMISS', { duration: 3000 });
                }
                return of(error);
              })
            );
          } else {
            const login = this.authService.login();
            return from(login);
          }
        })
      )
      .subscribe({
        error: (error) => console.error('error: ', error),
      });
  }

  openMailApp(contentType, code, title): void {
    const bodyFirstLine = `I saw this information from SAE International and I think you will find it very useful.${LINEBREAK}`;
    const bodySecondLine = 'Title:' + ' ' + code + ' ' + title;
    const itemUrl = 'URL:' + ' ' + window.location.origin + window.location.pathname;
    const body = bodyFirstLine + bodySecondLine + LINEBREAK + itemUrl + LINEBREAK + DISCLAIMER;
    const encodedBody = encodeURIComponent(body);
    const mailText = `mailto:?subject=FYI - Sharing an interesting article I came across on SAE Mobilus&body=${encodedBody}`;
    window.location.href = mailText;
    this.shareDocumentUsageEvent();
  }

  retryGettingAssets(contentId: number): void {
    this.store.dispatch(DetailActions.getAssets({ contentId }));
  }

  openDownloadDialog(content: ContentView, subSessions: SubscriptionSession[]): void {
    this.store.dispatch(
      DownloadDocumentActions.openFromDetailPage({
        contentId: content.id,
        accessProperties: content.accessProperties,
        subSessions,
        purchaseLink:
          `${
            content?.documentUris?.platformUris?.find((uri) => uri?.platformName === 'SAE-WEBSITE')?.legacyUri
          }?PC=DL2BUY` ?? 'https://sae.org',
        title: content.title,
        code: content.code,
        itemUrl: window.location.href.split('#')[0],
        content,
        calledFrom: 'detailPage',
      })
    );
  }

  fileDownloaded(cid: number): void {
    this.sessionService.addDownloadedCid(cid);
  }
  getDownloadFileFormat(contentId: number): void {
    this.store.dispatch(DetailActions.getAssets({ contentId }));
  }
  print(cid: number): void {
    window.print();
    this.store.dispatch(
      RootActions.usageTrackingEvent({
        event: { eventType: 'print_details', contentIds: [cid] },
      })
    );
  }

  openAddToLibraryDialog(contentId: number, isAddToShared = false): void {
    if (!this.authService.isLoggedIn()) {
      this.authService.login();
    } else {
      const ref = this.dialog.open<AddToDialogComponent>(AddToDialogComponent, { data: { contentId, isAddToShared } });
      const librarySub = ref.componentInstance.inMyLibraryChanged
        .pipe(
          tap((inMyLibrary) => this.contentService.setInMyLibrary(inMyLibrary)),
          withLatestFrom(this.contentService.returnUrl$),
          tap(([, returnUrl]) => {
            if (returnUrl?.url?.includes('search')) {
              // this.store.dispatch(SearchActions.searchWithFacets({ searchContext: 'main_search' }));
            } else {
              // this.store.dispatch(BrowseActions.searchWithFacets());
            }
          })
        )
        .subscribe();
      ref.afterClosed().subscribe(() => librarySub.unsubscribe());
    }
  }
}
