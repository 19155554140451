import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MoreOption } from '@sae/models';
import {
  CartCardDeleteItemEvent,
  CartCardMenuOptionSelectedEvent,
  CartCardOverQuantityRequestedEvent,
  CartCardQuantityChangeEvent,
} from '../../../materials/card/cart-card/cart-card.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { debounceTime, Subscription } from 'rxjs';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

export interface ShoppingCartDetails {
  orderTotal?: number | null; // Grand total for the current cart, used in the payment step of the checkout screen
  items?: ShoppingCartDetailsItem[] | null; // The list of items within the shopping cart and their details
}

export interface ShoppingCartDetailsItem {
  quantity?: number | null; // Only used for root items, specified the quantity of this item, note that child items can have a null value (their quantity is ignored)
  maxQuantity?: number | null; // Only used for root items, if specified allows an override to the component level maxQuantity input, used when there is less than that in stock
  price: number | null; // If provided will display this price on the line, can be omitted at either the root or child item level depending on where price display is desired, if provided and child items are supplied, the parent price is displayed as a subtotal line below the child line items
  discounts?: ShoppingCartDetailsItemDiscounts[] | null; // An optional array of discount line items to display along with the price, will be displayed under the final price of item(s), and above a subtotal display which will always show when this is provided (and/or if multiple item children exist)
  context?: ShoppingCartDetailsItemContext[] | null; // An optional list of context phrases to display for the item
  description?: string | null; // Primary description of the item, displayed under any context phrases provided
  sku?: string | null; // If provided, will display after the description on the receipt/complete page
  items?: ShoppingCartDetailsItem[] | null; // An optional list of sub items to display under this parent
  menuOptions?: MoreOption[] | null; // Additional menu items to use (note a 'Delete' option is automatically supplied), menus will only ever be attached to leaf items (if a parent has children, only the children will have menus)
  suggestions?: ShoppingCartDetailsItemSuggestion[] | null; // Only used for root items, an optional list of suggestion(s) to render at the end line item to show things like hotel suggstions and the like
}

export interface ShoppingCartDetailsItemDiscounts {
  label: string; // The discount text to display
  price: number; // The discount value to display, note that a negative value should be supplied
}

export interface ShoppingCartDetailsItemContext {
  label: string; // The text of the context phrase to display
  italic?: boolean | null; // If true renders the text in italic style
  warning?: boolean | null; // If true render the text in red color
  hideOnReceipt?: boolean | null; // Optional, false by default, if true hides this context item on the complete/receipt page for the item
}

export interface ShoppingCartDetailsItemSuggestion {
  icon: string; // Material icon to use for the suggestion line
  label: string; // Text of the suggestion
  href: string; // Url to open in a new window when clicking the suggestion
}

export interface ShoppingCartBillingDetails {
  firstName?: string | null;
  middleInitial?: string | null;
  lastName?: string | null;
  employed?: string | null;
  company?: string | null; // Only exposed is employed is "Yes"
  street1?: string | null;
  street2?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  postal?: string | null;
  email?: string | null;
  shippingSameAsBilling?: boolean | null; // If true hides collecting shipping information
}

export interface ShoppingCartShippingDetails {
  firstName?: string | null;
  middleInitial?: string | null;
  lastName?: string | null;
  employed?: string | null;
  company?: string | null; // Only exposed is employed is "Yes"
  street1?: string | null;
  street2?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  postal?: string | null;
  email?: string | null;
}

export interface ShoppingCartPaymentDetails {
  creditCardNumber?: string | null;
  expirationMonth?: number | null;
  expirationYear?: number | null;
  creditCardCode?: string | null;
  paymentError?: boolean | null; // Set this to true if an unsuccessful payment attempt was made
  paymentErrorMessage?: string | null; // Use in conjunction with paymentError to display payment issue information to user
}

export interface ShoppingCartOrderDetails {
  siteLogoUrl: string; // Shows up when printing the order reciept
  siteLogoAlt: string; // Alt text for the siteLogoUrl
  orderNumber: string;
  itemCount: number;
  paymentTotal: number;
  paymentNote: string;
}

export interface ShoppingCartPromoCodeConfiguration {
  label: string; // Label to display over input field
  loadingMessage: string; // Text to display when a trigger to parse input occurs
  validMessage: string; // Title text to display when parsed input is valid
  validActionPrompt: string; // Subtitle text to display when parsed input is valid
  invalidMessage: string; // Title text to display when parsed input is invalid
  invalidActionPrompt: string; // Subtitle text to display when parsed input is invalid
  removable: boolean; // Whether the chips can be removed
  chips: string[]; // A string array of chips for the widget
  maxItems: number; // Maximum items to allow for selection (0 = unlimited)
  debounceTime: number; // Time to wait for user input to settle before dispatching a searchChange event
  minCharsForSearch: number; // Minimum number of characters required before dispatching a searchChange event
  showLoadingCard: boolean; // Whether to display the loading card state
  showResultsInvalidCard: boolean; // Whether to display the invalid results card state
  showResultsValidCard: boolean; // Whether to display the valid results card state
  applyADAFix: boolean; // When set to true, fixes wcag412 by adding a hidden chip to the chiplist, however this causes the mat floating label to float as it thinks there is content to trigger the auto behavior
}

// Represents the default configuration for the promo code autocomplete component, changes to defaults can be in the form of spreading those over the default object
export const ShoppingCartPromoCodeConfigurationDefaults: ShoppingCartPromoCodeConfiguration = {
  label: 'Enter Code',
  loadingMessage: 'Loading...',
  validMessage: `The specified code is valid`,
  validActionPrompt: 'Press enter or click to apply code',
  invalidMessage: `The specified code is invalid`,
  invalidActionPrompt: 'Continue typing or try another',
  removable: true,
  chips: [],
  maxItems: 1,
  debounceTime: 450,
  minCharsForSearch: 3,
  showLoadingCard: false,
  showResultsInvalidCard: false,
  showResultsValidCard: false,
  applyADAFix: false,
};

export interface ShoppingCartCountryCodeOptions {
  label: string;
  value: string;
}

// Represents some basic label/value pairs to present for countries in the billing country dropdown
export const ShoppingCartCountryCodeOptionsDefaults: ShoppingCartCountryCodeOptions[] = [
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'United States',
    value: 'United States',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
];

export interface ShoppingCartSubmitOrderEvent {
  cartDetails: ShoppingCartDetails;
  billingDetails: ShoppingCartBillingDetails;
  paymentDetails: ShoppingCartPaymentDetails;
}

class CreditCardCodeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && (control.dirty || control.touched) && (!control.valid || form.hasError('invalidCSCLength'));
  }
}

class CreditCardExpirationDateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && (control.dirty || control.touched) && (!control.valid || form.hasError('invalidExpirationDate'));
  }
}

@Component({
  selector: 'si-shopping-cart',
  templateUrl: './shopping-cart.component.html',
})
export class ShoppingCartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() state: 'summary' | 'checkout' | 'complete' = 'summary'; // Specifies the main display state of the panel
  @Input() cartDetails: ShoppingCartDetails = null; // Core shopping cart item information
  @Input() billingDetails: ShoppingCartBillingDetails = null; // Checkout billing information
  @Input() shippingDetails: ShoppingCartShippingDetails = null; // Checkout shipping information
  @Input() paymentDetails: ShoppingCartPaymentDetails = null; // Checkout payment information
  @Input() orderDetails: ShoppingCartOrderDetails = null; // Post checkout order details
  @Input() countryCodes: ShoppingCartCountryCodeOptions[] = ShoppingCartCountryCodeOptionsDefaults; // List of country codes to present in the billing information form
  @Input() maxQuantity = 5; // Sets the maximum selectable quantity for all items in the cart, however an individual cart item can specify a lower number (for cases when there is less than this in stock)
  @Input() zeroQuantityMessage: string | null = '0 (Delete)'; // Specifies the label for the 0 quantity option, however if null simply uses the 0 number
  @Input() overQuantityMessage: string | null = '6 + (Contact Sales)'; // If not null, adds a final option to the quantity selection with the provided label, when selected triggers an event which can be used for handling
  @Input() promoCodeEnabled = true; // Determines whether to expose the ability to enter in promo codes in an autocomplete hidden value chip selector
  @Input() promoCodeConfig: ShoppingCartPromoCodeConfiguration = ShoppingCartPromoCodeConfigurationDefaults; // Defines the auto complete component configuration for the promo codes (if enabled)
  @Input() collectShipping = true; // Determines whether to collect shipping information, if false hides the step and the question whether shipping is the same as billing
  @Input() submitting = false; // Used to set a submitting display state on the checkokut cart (spinner/etc)

  // The inputs below are useful for localizing the currency display, defaults represent pipe default for en-US region
  @Input() currencyCode = 'USD'; // currencyCode value for the CurrencyPipe
  @Input() currencyDisplay = 'symbol'; // display value for the CurrencyPipe
  @Input() currencyDigitsInfo = '1.2-2'; // digitsInfo value for the CurrencyPipe
  @Input() currencyLocale = 'en-US'; // locale value for the CurrencyPipe (NOTE: If this is changed, ensure the locale data for the LOCALE_ID is imported into the app.)

  @Output() deleteItem = new EventEmitter<CartCardDeleteItemEvent>(); // Event triggered when an item's quantity is desired to be deleted
  @Output() quantityChange = new EventEmitter<CartCardQuantityChangeEvent>(); // Event triggered when an item's quantity has changed and is not 0 nor over quantity
  @Output() overQuantityRequested = new EventEmitter<CartCardOverQuantityRequestedEvent>(); // Event triggered when the item's quantity has changed to the optionally specified over quantity option
  @Output() menuOptionSelected = new EventEmitter<CartCardMenuOptionSelectedEvent>(); // Event triggered when the item's quantity has changed to the optionally specified over quantity option
  @Output() promoCodeInputKeyDown = new EventEmitter<string>(); // Event for the promo code autocomplete input that triggers immediately whenever the input field value is affected by a key press
  @Output() promoCodeSearchChange = new EventEmitter<string>(); // Event for the promo code autocomplete input that triggers after debounceTime has settled, sufficient characters are present, and the string is not already within the chips array
  @Output() promoCodeValueSelected = new EventEmitter<string>(); // Event for the promo code autocomplete input that triggers when the user selects the results valid card, via click on it or pressing enter within the input field
  @Output() promoCodeValueRemoved = new EventEmitter<string>(); // Event for the promo code autocomplete input that triggers when the user removes a chip value
  @Output() billingInfoChanged = new EventEmitter<ShoppingCartBillingDetails>(); // Event triggered when the billing information form has been changed by the user
  @Output() shippingInfoChanged = new EventEmitter<ShoppingCartShippingDetails>(); // Event triggered when the shipping information form has been changed by the user
  @Output() paymentInfoChanged = new EventEmitter<ShoppingCartPaymentDetails>(); // Event triggered when the billing information form has been changed by the user
  @Output() submitOrder = new EventEmitter<ShoppingCartSubmitOrderEvent>(); // Event triggered when the checkout forms are complete and the user desires to place the order

  constructor(private formBuilder: FormBuilder) {}

  totalItems = 0;
  showShippingForm = true;
  billingFormGroup: FormGroup;
  billingFormGroupSubscription: Subscription;
  shippingFormGroup: FormGroup;
  shippingFormGroupSubscription: Subscription;
  reviewItemsFormGroup: FormGroup;
  paymentFormGroup: FormGroup;
  paymentFormGroupSubscription: Subscription;
  expirationYearOptions: number[] = [];
  creditCardCodeErrorStateMatcher = new CreditCardCodeErrorStateMatcher();
  creditCardExpirationDateErrorStateMatcher = new CreditCardExpirationDateErrorStateMatcher();

  // Within the component we act on local copies of these objects and do not mutate input data
  _billingDetails: ShoppingCartBillingDetails = null;
  _shippingDetails: ShoppingCartShippingDetails = null;
  _paymentDetails: ShoppingCartPaymentDetails = null;

  ngOnInit() {
    this._billingDetails = { ...this.billingDetails };
    this._shippingDetails = { ...this.shippingDetails };
    this._paymentDetails = { ...this.paymentDetails };

    this.billingFormGroup = this.formBuilder.group({
      firstName: [this._billingDetails.firstName ?? null, Validators.required],
      middleInitial: [this._billingDetails.middleInitial ?? null],
      lastName: [this._billingDetails.lastName ?? null, Validators.required],
      employed: [this._billingDetails.employed ?? null, Validators.required],
      company: [this._billingDetails.company ?? null],
      street1: [this._billingDetails.street1 ?? null, Validators.required],
      street2: [this._billingDetails.street2 ?? null],
      country: [this._billingDetails.country ?? null, Validators.required],
      state: [this._billingDetails.state ?? null, Validators.required],
      city: [this._billingDetails.city ?? null, Validators.required],
      postal: [this._billingDetails.postal ?? null, Validators.required],
      email: [this._billingDetails.email ?? null, [Validators.required, Validators.email]],
      shippingSameAsBilling: [this._billingDetails.shippingSameAsBilling ?? null],
    });
    this.shippingFormGroup = this.formBuilder.group({
      firstName: [this._shippingDetails.firstName ?? null, Validators.required],
      middleInitial: [this._shippingDetails.middleInitial ?? null],
      lastName: [this._shippingDetails.lastName ?? null, Validators.required],
      employed: [this._shippingDetails.employed ?? null, Validators.required],
      company: [this._shippingDetails.company ?? null],
      street1: [this._shippingDetails.street1 ?? null, Validators.required],
      street2: [this._shippingDetails.street2 ?? null],
      country: [this._shippingDetails.country ?? null, Validators.required],
      state: [this._shippingDetails.state ?? null, Validators.required],
      city: [this._shippingDetails.city ?? null, Validators.required],
      postal: [this._shippingDetails.postal ?? null, Validators.required],
      email: [this._shippingDetails.email ?? null, [Validators.required, Validators.email]],
    });
    this.reviewItemsFormGroup = this.formBuilder.group({});
    this.paymentFormGroup = this.formBuilder.group(
      {
        creditCardNumber: [
          this._paymentDetails.creditCardNumber ?? null,
          [Validators.required, Validators.pattern('[0-9]*'), this.validCreditCardLength],
        ],
        expirationMonth: [this._paymentDetails.expirationMonth ?? null, Validators.required],
        expirationYear: [this._paymentDetails.expirationYear ?? null, Validators.required],
        creditCardCode: [
          this._paymentDetails.creditCardCode ?? null,
          [Validators.required, Validators.pattern('[0-9]*')],
        ],
      },
      { validators: [this.validCardSecurityCodeLength, this.validCreditCardExpirationDate] }
    );

    this.billingFormGroupSubscription = this.billingFormGroup.valueChanges
      .pipe(debounceTime(450))
      .subscribe((response) => {
        this.billingInfoChanged.emit(response);
      });
    this.shippingFormGroupSubscription = this.shippingFormGroup.valueChanges
      .pipe(debounceTime(450))
      .subscribe((response) => {
        this.shippingInfoChanged.emit(response);
      });
    this.paymentFormGroupSubscription = this.paymentFormGroup.valueChanges
      .pipe(debounceTime(450))
      .subscribe((response) => {
        this.paymentInfoChanged.emit(response);
      });

    const currentYear = new Date().getFullYear();

    for (let i = 0; i < 10; i++) {
      this.expirationYearOptions.push(currentYear + i);
    }

    this.showShippingForm = !this._billingDetails.shippingSameAsBilling ?? true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cartDetails) {
      this.totalItems = 0;

      if (this.cartDetails?.items?.length > 0) {
        for (const item of this.cartDetails.items) {
          this.totalItems += item.quantity;
        }
      }
    }

    // If the either of these inputs has changed externally, restamp the local copy with the new data and spread the values into respective the FormGroup (if initialized and suppressing event emits)
    if (changes.billingDetails) {
      this._billingDetails = { ...this.billingDetails };

      if (this.billingFormGroup) {
        this.billingFormGroup.setValue({ ...this._billingDetails }, { emitEvent: false });
      }
    }
    if (changes.paymentDetails) {
      this._paymentDetails = { ...this.paymentDetails };

      if (this.paymentFormGroup) {
        this.paymentFormGroup.setValue({ ...this._paymentDetails }, { emitEvent: false });
      }
    }
  }

  ngOnDestroy() {
    if (this.billingFormGroupSubscription) {
      this.billingFormGroupSubscription.unsubscribe();
    }
    if (this.shippingFormGroupSubscription) {
      this.shippingFormGroupSubscription.unsubscribe();
    }
    if (this.paymentFormGroupSubscription) {
      this.paymentFormGroupSubscription.unsubscribe();
    }
  }

  validCreditCardLength(control: AbstractControl): ValidationErrors | null {
    const cardNumber = `${control.value}`;

    if (cardNumber?.length < 15 || cardNumber?.length > 16) {
      return { invalidCreditCardLength: true };
    } else if (cardNumber.charAt(0) === '3' && cardNumber.length !== 15) {
      return { invalidCreditCardLength: true };
    } else if (cardNumber.charAt(0) !== '3' && cardNumber.length !== 16) {
      return { invalidCreditCardLength: true };
    } else {
      return null;
    }
  }

  validCardSecurityCodeLength(control: AbstractControl): ValidationErrors | null {
    const cscNumber = `${control.get('creditCardCode').value}`;
    const cardNumber = `${control.get('creditCardNumber').value}`;

    if (cscNumber?.length < 3 || cscNumber?.length > 4) {
      return { invalidCSCLength: true };
    } else if (cardNumber.charAt(0) === '3' && cscNumber.length !== 4) {
      return { invalidCSCLength: true };
    } else if (cardNumber.charAt(0) !== '3' && cscNumber.length !== 3) {
      return { invalidCSCLength: true };
    } else {
      return null;
    }
  }

  validCreditCardExpirationDate(control: AbstractControl): ValidationErrors | null {
    const expirationMonth = control.get('expirationMonth').value;
    const expirationYear = control.get('expirationYear').value;

    if (expirationMonth && expirationYear) {
      const now = new Date();
      const expiration = new Date();

      expiration.setMonth(+expirationMonth - 1);
      expiration.setFullYear(expirationYear);

      if (expiration.getTime() < now.getTime()) {
        return { invalidExpirationDate: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  onDeleteItem(event: CartCardDeleteItemEvent) {
    this.deleteItem.emit(event);
  }

  onQuantityChange(event: CartCardQuantityChangeEvent) {
    this.quantityChange.emit(event);
  }

  onOverQuantityRequested(event: CartCardOverQuantityRequestedEvent) {
    this.overQuantityRequested.emit(event);
  }

  onMenuOptionSelected(event: CartCardMenuOptionSelectedEvent) {
    this.menuOptionSelected.emit(event);
  }

  onPromoCodeInputKeyDown(event: string) {
    this.promoCodeInputKeyDown.emit(event);
  }

  onPromoCodeSearchChange(event: string) {
    this.promoCodeSearchChange.emit(event);
  }

  onPromoCodeValueSelected(event: string) {
    this.promoCodeValueSelected.emit(event);
  }

  onPromoCodeValueRemoved(event: string) {
    this.promoCodeValueRemoved.emit(event);
  }

  onChangeShippingSameAsBilling(event: MatCheckboxChange) {
    this.showShippingForm = !event.checked;
  }

  onSubmitOrder() {
    this.submitOrder.emit({
      cartDetails: this.cartDetails,
      billingDetails: this.billingFormGroup.value,
      paymentDetails: this.paymentFormGroup.value,
    });
  }

  onPrintReceipt() {
    window.print();
  }
}
