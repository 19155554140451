import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PersonTagInfo } from '@sae/models';
import { convertKeycloakProfileToPersonTagInfo } from '@sae/utilities';
import { KeycloakProfile } from 'keycloak-js';
import { map } from 'rxjs';
import { AuthService } from '../../state/auth.service';
import { SessionService } from '../../state/session.service';
import { SubscriptionsService } from '../../state/subscriptions.service';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { SubscriptionLoginDialogComponent } from '../subscription-login-dialog/subscription-login-dialog.component';
import { NOTIFICATIONS_TOKEN, NotificationsCoreService, REMOTE_CONFIG_TOKEN } from '@sae/services';
import { LoginLocation } from '../../models/common-interfaces';
import { IEnvironmentConfigService } from '@sae/base';
import { MobilusProteusConfig } from '../../../env.config';
@Component({
  selector: 'mobi-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  public isMasterPanelCollapsed$ = this.sessionService.isMasterPanelCollapsed$;
  public template$ = this.sessionService.activeViewTemplate$;
  public userLoggedIn$ = this.authService.isLoggedIn$;
  public subscriptions$ = this.subscriptionsService.subscriptions$;
  public subLoggedIn$ = this.subscriptionsService.subscriptions$.pipe(
    map((subscriptions) => subscriptions?.length > 0)
  );
  public showMobileNavMenu$ = this.sessionService.showMobileNavMenu$;
  public showMenu$ = this.sessionService.showMenu$;
  public userProfile$ = this.authService.profile$;
  public personType$ = this.authService.personType$;
  public userInitials$ = this.authService.profile$.pipe(
    map((profile) => profile?.firstName?.charAt(0) + profile?.lastName?.charAt(0))
  );

  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private subscriptionsService: SubscriptionsService,
    private dialog: MatDialog,
    @Inject(NOTIFICATIONS_TOKEN) private notificationsService: NotificationsCoreService,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {
    notificationsService.setSelectedAppFilter(
      envConfigService.envConfig<MobilusProteusConfig>().enterpriseMenu.apps.find((app) => app.currentApp)
    );
  }

  toggleMobileNavMenuClick(showMobileNavMenu: boolean): void {
    this.sessionService.setShowMobileNavMenu(!showMobileNavMenu);
  }

  login(): void {
    void this.authService.login();
  }

  subscriptionLogin(): void {
    const ref = this.dialog.open(SubscriptionLoginDialogComponent);
    ref.componentInstance.LoginFrom = LoginLocation.ToolBar;
  }

  logoutConfirmDialog(): void {
    this.dialog.open(LogoutDialogComponent);
  }

  getPersonTagInfo(profile: KeycloakProfile): PersonTagInfo {
    return convertKeycloakProfileToPersonTagInfo(profile);
  }
}
