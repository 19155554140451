import { Injectable } from '@angular/core';
import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig, NgEntityService } from '@datorama/akita-ng-entity-service';
import { Api } from '../../../api';

export interface UsageEvent {
  id?: string;
  contentIds: number[];
  criteriaDetailsId: string;
  eventType: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsageEventsState {}

function createInitialState(): UsageEventsState {
  return {} as UsageEventsState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'UsageEvents' })
export class UsageEventsStore extends EntityStore<UsageEventsState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class UsageEventsQuery extends QueryEntity<UsageEventsState> {
  constructor(protected store: UsageEventsStore) {
    super(store);
  }
}

@NgEntityServiceConfig({
  resourceName: Api.usageEvents.url,
})
@Injectable({ providedIn: 'root' })
export class UsageEventsService extends NgEntityService<UsageEventsState> {
  constructor(protected store: UsageEventsStore, private query: UsageEventsQuery) {
    super(store);
  }
}
