<fs-part-button
  [avatar]="avatar"
  [buttonId]="buttonId"
  [classes]="classes"
  [disableButton]="disabled"
  [label]="label"
  [materialIcon]="materialIcon"
  [role]="inMenu ? 'menuitem' : null"
  [selected]="selected"
  [stopPropagation]="stopPropagation"
  [tooltip]="tooltip"
  [type]="'detail'"
  [url]="url"
  (buttonClick)="onButtonClick($event)"
></fs-part-button>
