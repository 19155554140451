<ng-content select="[start]"></ng-content>
<ng-container *ngIf="returnButton">
  <fs-part-button
    class="si-visible--only-mobile"
    [classes]="returnButton.classes"
    [materialIcon]="'arrow_back'"
    [tooltip]="returnButton.label"
    [type]="'return'"
    [url]="returnButton.url"
  ></fs-part-button>
  <fs-part-button
    class="si-visible--beyond-mobile"
    [classes]="returnButton.classes"
    [label]="returnButton.label"
    [materialIcon]="'arrow_back'"
    [type]="'return'"
    [url]="returnButton.url"
  ></fs-part-button>
</ng-container>
<ng-container *ngIf="heading || headingRow">
  <fs-part-title *ngIf="hTag; else noHTag" [hTag]="hTag" [classes]="classes">
    <ng-container *ngTemplateOutlet="body"></ng-container>
  </fs-part-title>
  <ng-template #noHTag>
    <ng-container *ngTemplateOutlet="body"></ng-container>
  </ng-template>
</ng-container>
<fs-part-label *ngIf="label" [content]="label"></fs-part-label>
<ng-content select="[end]"></ng-content>

<ng-template #body>
  <fs-part-text *ngIf="heading" [text]="heading"></fs-part-text>
  <span *ngIf="headingCount > -1" class="si-heading__count">{{ ' (' + headingCount + ')' }}</span>
  <fs-comp-row-text *ngIf="headingRow" [rowText]="headingRow">
    <ng-container start>
      <ng-content select="[rowStart]"></ng-content>
    </ng-container>
  </fs-comp-row-text>
</ng-template>
