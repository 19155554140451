import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { Term } from '../models/publication.model';
import { ClassificationApiService } from './classification-api.service';

@Injectable({
  providedIn: 'root',
})
export class ClassificationUiService {
  constructor(private service: ClassificationApiService) {}

  public getTerms(params?: { depth?: number; uri?: string }): Observable<Term[]> {
    return this.service.getTerms(params).pipe(map((response) => response.results ?? []));
  }

  public getTopicsForBrowse(uriText: string): Observable<[Term[], Term[], Term]> {
    return this.service.getTerms({ uri: `${uriText}` }).pipe(
      switchMap((terms) => {
        const term = terms.results[terms.results.length - 1];
        const descReq = this.service.getTermDescendents(term.id).pipe(map((resp) => resp.results));
        const ancReq = this.service.getTerm(term.parentId).pipe(map((resp) => resp.results));
        return forkJoin([descReq, ancReq, of(term)]);
      })
    );
  }
}
