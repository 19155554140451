import { ApiRegistry } from '@sae/services';

export const Api: ApiRegistry = {
  messaging: {
    url: 'v1/messaging/messages',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  groups: {
    url: 'v1/users-groups/users/me/groups',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  comments: {
    url: 'v2/comments/comments',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  alerts: {
    url: 'v1/alerts/alerts',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  savedSearches: {
    url: 'v1/searchmanager/criteria',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  savedAppSearches: {
    url: 'v1/searchmanager/appsearch/criteria',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  searchHistory: {
    url: 'v1/searchmanager/recent/searches',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  appSearchHistory: {
    url: 'v1/searchmanager/appsearch/criteria/recent',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  notifications: {
    url: 'v1/messaging/messages',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  search: {
    url: 'v2/mobilus/search',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  suggestions: {
    url: 'v1/searchmanager/suggestions',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  resources: {
    url: 'v1/resources/resources',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  content: {
    url: 'v2/mobilus/content',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  digitalStandards: {
    url: 'v1/digitalstandards/documents',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  committees: {
    url: 'v1/committee/committees',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  usageEvents: {
    url: 'v2/mobilus/usage-events',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  subscriptions: {
    url: 'v2/mobilus/subscriptions',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  mobilus: {
    url: 'v2/mobilus',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  searchManager: {
    url: 'v1/searchmanager',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  folders: {
    url: 'v2/mobilus/mobilus-folders',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  citations: {
    url: 'v2/mobilus/citations',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  downloadManager: {
    url: 'v1/download-manager',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  classifications: {
    url: 'v1/classification',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  identityProviders: {
    url: 'v2/mobilus/identityProviders',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  annotations: {
    url: 'v2/mobilus/annotations',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  apiKey: {
    url: 'v1/usage-tracking-reports/apikey-management',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://this.url.doesnt.do.anything',
  },
  reportConfig: {
    url: 'v1/usage-tracking-reports/reports/lookup',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://this.url.doesnt.do.anything',
  },
  reportApiKey: {
    url: 'v1/usage-tracking-reports/apikey-management',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://this.url.doesnt.do.anything',
  },
  reportGenerate: {
    url: 'v1/usage-tracking-reports', //append the path from the report def
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://this.url.doesnt.do.anything',
  },
  reportFilters: {
    url: 'v2/mobilus/report',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://there.are.no.docs.com',
  },
  customerSuccess: {
    url: 'v1/customersuccess',
    minor_version: 4,
    patch_version: 0,
    docs_link: 'https://api-awsdev.sae.org/api/docs',
  },
  usersGroups: {
    url: 'v2/users-groups',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  invitation: {
    url: 'invitation/v2',
    minor_version: 0,
    patch_version: 0,
    docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  },
  //  ballots: {
  //     url: 'v1/ballots/ballots',
  //     minor_version: 0,
  //     patch_version: 0,
  //     docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  //  },
  //  file: {
  //     url: 'v1/file/files',
  //     minor_version: 0,
  //     patch_version: 0,
  //     docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  //  },
  //  task: {
  //     url: 'v1/task/tasks',
  //     minor_version: 0,
  //     patch_version: 0,
  //     docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  //  },
  //  events: {
  //     url: 'v1/events/events',
  //     minor_version: 0,
  //     patch_version: 0,
  //     docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  //  },
  //  authentication: {
  //     url: 'v1/authentication/oauth',
  //     minor_version: 0,
  //     patch_version: 0,
  //     docs_link: 'https://dev.sae.org/exdomains/agx/api-docs/v1_0_0-DEV/index.html',
  //  },
};
