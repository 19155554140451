<div class="approve-reject-dialog">
  <div mat-dialog-header class="si-dialog__h">
    {{ message }}
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="noClick()">{{ noButtonText }}</button>
    <button mat-button color="primary" cdkFocusInitial (click)="yesClick()">
      {{ yesButtonText }}
    </button>
  </div>
</div>
