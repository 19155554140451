<fs-part-container
  class="si-toolbarv"
  classes="si-toolbarv__container {{ classes }}"
  [class.si-toolbarv--colheader]="isColumnHeader"
  [class.si-toolbarv--crossover]="isColumnHeader === 'crossover' || isColumnHeader === 'crossover-detail'"
  [class.si-toolbarv--detail]="isColumnHeader === 'detail'"
  [class.si-toolbarv--master]="isColumnHeader === 'master'"
  [class.si-toolbarv--tool]="isColumnHeader === 'tool'"
>
  <ng-content select="[preBody]"></ng-content>
  <div class="si-toolbarv__body si-button--condensor">
    <ng-content select="[preStart]"></ng-content>
    <fs-comp-util-heading
      *ngIf="heading"
      [classes]="heading.classes"
      [heading]="heading.heading"
      [headingCount]="heading.headingCount"
      [hTag]="heading.hTag"
      [label]="heading.label"
      [returnButton]="heading.returnButton"
    ></fs-comp-util-heading>
    <ng-content select="[start]"></ng-content>
    <span class="si-expanse"></span>
    <ng-content select="[middle]"></ng-content>
    <span class="si-expanse"></span>
    <ng-content select="[end]"></ng-content>
    <ng-content select="[reserve]"></ng-content>
  </div>
</fs-part-container>
