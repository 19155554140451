import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ApplicationCode, Group, User } from '../models/groups.model';

export interface UserGroupsState extends EntityState<Group> {
  id: string;
  name: string;
  description: string;
  users: Array<Partial<User>>;
  applicationCodes: ApplicationCode[];
  tags: null;
}

export function createInitialState(): Partial<UserGroupsState> {
  return {
    id: null,
    name: null,
    description: null,
    users: null,
    applicationCodes: null,
    tags: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'user-groups',
  idKey: '_id',
  cache: {
    ttl: 1000 * 60,
  },
})
export class UserGroupsStore extends EntityStore<UserGroupsState> {
  constructor() {
    super(createInitialState());
  }
}
