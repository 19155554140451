<ng-container
  *ngIf="{
    showMobileNavMenu: showMobileNavMenu$ | async,
    isTakeoverActive: isTakeoverActive$ | async
  } as _"
>
  <main class="si-columns" [class.si-state--open]="_.showMobileNavMenu" [class.si-state--closed]="!_.showMobileNavMenu" [hidden]="_.isTakeoverActive">
    <div class="si-col si-col--detail">
      <div libDetectBreakpointWidth>
        <ng-template [cdkPortalOutlet]="detailContentPortal" *ngIf="projectedContent?.detailContent"></ng-template>
      </div>
    </div>
  </main>
</ng-container>
