<div class="si-card__wrap">
  <a [routerLink]="seoUri" class="si-a--unusual si-button si-button--inline" mat-button>
    <mat-card class="si-card si-card--category mat-elevation-z" [class.si-contains--minor-img]="image">
      <div class="si-card__minor-img si-offset--toolbar" *ngIf="image">
        <img src="{{ image }}" alt="{{ preTitleText + ' ' + titleText }}" class="si-image" />
      </div>
      <article class="si-article">
        <mat-card-header class="si-card__h">
          <mat-card-title class="si-pretitle" *ngIf="preTitleText">
            {{ preTitleText }}
          </mat-card-title>
          <mat-card-title class="si-card__title">
            {{ titleText }}
          </mat-card-title>
          <mat-card-subtitle class="si-card__subtitle" *ngIf="subTitleText">
            <span class="si-ellipsed">{{ subTitleText }}</span>
          </mat-card-subtitle>
        </mat-card-header>
      </article>
    </mat-card>
  </a>
  <div class="si-card__toolbar" [class.si-contains--minor-img]="image">
    <si-seo-chiplist
      *ngIf="types"
      class="si-component"
      ariaLabel="Type"
      [isMinor]="true"
      [chips]="typesChips"
    ></si-seo-chiplist>
    <span class="si-expanse"></span>
    <button
      mat-icon-button
      [matMenuTriggerFor]="viewToolbarOpts"
      class="si-icon--more"
      matTooltip="Options"
      aria-label="Options"
      (click)="$event.stopPropagation()"
    >
      <mat-icon aria-hidden="true">more_vert</mat-icon>
    </button>
    <mat-menu #viewToolbarOpts="matMenu" class="si-menu">
      <ng-container *ngFor="let option of options">
        <mat-divider class="si-divider"></mat-divider>
        <button
          *ngIf="option.name === 'Share'"
          mat-menu-item
          [matMenuTriggerFor]="viewShare"
          class="si-button--detailed"
          (click)="$event.stopPropagation()"
        >
          <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">share</mat-icon>
          <span>Share</span>
        </button>

        <button
          *ngIf="option.name !== 'Share'"
          mat-menu-item
          class="si-button--detailed"
          (click)="optionSelected.emit(option.name)"
        >
          <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">{{ option.icon }}</mat-icon>
          <span>{{ option.name }}</span>
        </button>
      </ng-container>
    </mat-menu>

    <mat-menu #viewShare="matMenu" class="si-menu">
      <button (click)="openMailApp()" mat-menu-item class="si-button--detailed">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">email</mat-icon>
        <span>Email</span>
      </button>
      <button (click)="openClipboardSnackbar()" mat-menu-item [cdkCopyToClipboard]="getShareUrl()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">content_copy</mat-icon>
        <span>Copy URL</span>
      </button>
    </mat-menu>
  </div>
</div>
