/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, filter, first, Observable, Subject, tap, timeout } from 'rxjs';

interface MathJaxConfig {
  source: string;
  integrity: string;
  id: string;
}

declare global {
  interface Window {
    MathJax: {
      typesetPromise: () => void;
      startup: {
        promise: Promise<any>;
      };
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class MathService {
  private signal: Subject<boolean>;
  private mathJax: MathJaxConfig = {
    source: 'https://cdn.jsdelivr.net/npm/mathjax@3.2.1/es5/mml-chtml.js',
    integrity: 'sha256-tO6A4e3qQ9P20PEyoS/GLdAFdlrDCYzA9yfi2lcY2Uw=',
    id: 'MathJaxScript',
  };

  constructor(@Inject(PLATFORM_ID) private platformId) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.signal = new BehaviorSubject<boolean>(false);
    void this.registerMathJaxAsync(this.mathJax)
      .then(() => {
        return this.signal.next(true);
      })
      .catch((error) => {
        // TODO FALLBACK STRAT
        console.log('Failed to register', error);
      });
  }

  private async registerMathJaxAsync(config: MathJaxConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      const script: HTMLScriptElement = document.createElement('script');
      script.id = config.id;
      script.type = 'text/javascript';
      script.src = config.source;
      script.integrity = config.integrity;
      script.crossOrigin = 'anonymous';
      script.async = true;
      script.onload = (): any => resolve(null);
      script.onerror = (error): any => reject(error);
      document.head.appendChild(script);
    });
  }

  ready(): Observable<boolean> {
    return this.signal;
  }

  render(element: Element, math: string): void {
    this.ready()
      .pipe(
        filter((v) => !!v),
        first(),
        timeout({ first: 5_000 }),
        tap(() => {
          window.MathJax.startup.promise.then(() => {
            element.innerHTML = math;
            window.MathJax.typesetPromise();
          });
        })
      )
      .subscribe();
  }
}
