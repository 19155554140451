import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailToolTakeoverViewBase } from '@sae/features';
import { Observable, switchMap, tap } from 'rxjs';
import { ContentService } from '../../../../shared/state/content.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { SessionService } from '../../../../shared/state/session.service';
import { SeoUtilityService } from '../../../../shared/services/seo-utility.service';
@UntilDestroy()
@Component({
  templateUrl: '../templates/detail.component.html',
  host: { 'collision-id': 'PeriodicalsComponent' },
})
export class PeriodicalsComponent extends DetailToolTakeoverViewBase implements OnInit, OnDestroy {
  public fragment$: Observable<string> = this.route.fragment;
  public routeLoading$: Observable<boolean> = this.contentService.routeLoading$;
  public isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  public config$ = this.contentService.config$;

  constructor(
    public sessionService: SessionService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private detailsHelperService: DetailsHelperService,
    private seoUtilityService: SeoUtilityService
  ) {
    super(sessionService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        untilDestroyed(this),
        tap((data) =>
          this.seoUtilityService.setMetaTags(
            `${data?.content?.code}: ${data?.content?.title} - ${data?.content?.subGroup}`,
            data?.content?.abstract
          )
        ),
        switchMap((data) => this.detailsHelperService.getAdditionalDetails(data?.content))
      )
      .subscribe((cv) => {
        this.contentService.setContentView(cv);
        this.detailsHelperService.mapJournalVolumeIssues(cv?.volumes ?? []);
      });

    if (this.route.snapshot.data?.content?.subGroup === 'Journal') {
      this.contentService.updateDetailsConfiguration({
        panel_enabled: true,
        // detail_masthead_subheading_type: 'journal',
        panel_type: 'journal',
        panel_title: 'Published Volumes',
        header_aux_text: 'Published Volumes',
        panel_collapse_text: 'Collapse Published Volumes Panel',
        detail_description_summary_title: 'Aims & Scope',
        detail_show_abstract: true,
        detail_show_related: true,
        detail_show_browse: true,
        detail_show_subscriptions: true,
        detail_show_authoring: true,
        detail_show_indexed_in: true,
        detail_show_editorial_board: true,
        detail_show_aim_and_scope: true,
        detail_show_submit_manuscript: true,
      });
    } else if (this.route.snapshot.data?.content?.subGroup === 'Magazine') {
      this.contentService.updateDetailsConfiguration({
        detail_masthead_subheading_type: 'journal',
        detail_show_abstract: true,
        detail_show_tags: true,
        detail_show_additional_details: true,
        detail_show_publisher: true,
        detail_show_country: true,
        detail_show_view: false,
        detail_show_references: true,
        detail_show_related: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.contentService.resetStore();
    this.seoUtilityService.clearAllSEOTags();
  }
}
