import { HttpClient } from '@angular/common/http';
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[downloadFile]',
})
export class DownloadFileDirective {
  @Input() fileName!: string;
  @Output() fileDownloaded = new EventEmitter();

  constructor(private readonly httpClient: HttpClient) {}

  private downloadUrl: string;

  @Input('downloadFile')
  public set url(url: string) {
    this.downloadUrl = url;
  }

  @HostListener('click', ['$event'])
  public async onClick(event): Promise<void> {
    event.preventDefault();
    // Download the document as a blob
    const response = await lastValueFrom(
      this.httpClient.get(this.downloadUrl, { responseType: 'blob', observe: 'response' })
    );
    this.fileDownloaded.emit();

    // Create a URL for the blob
    const url = URL.createObjectURL(response.body);

    // Create an anchor element to "point" to it
    const anchor = document.createElement('a');
    anchor.href = url;

    // Get the suggested filename for the file from the response headers
    anchor.download = this.fileName ?? 'file';

    // Simulate a click on our anchor element
    anchor.click();

    // Discard the object data
    URL.revokeObjectURL(url);
  }
}
