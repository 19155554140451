<button class="si-button--wrap" mat-button *ngIf="wrapElement !== 'noClick'">
  <ng-container *ngTemplateOutlet="cardBody"></ng-container>
</button>

<div class="si-card__container si-card__container--noclick" *ngIf="wrapElement === 'noClick'">
  <ng-container *ngTemplateOutlet="cardBody"></ng-container>
</div>

<ng-template #cardBody>
  <mat-card class="si-card si-card--micro mat-elevation-z2" [class.si-state--selected]="isSelected">
    <mat-card-header class="si-card__h">
      <div mat-card-avatar class="si-avatar" *ngIf="materialIcon || customIcon">
        <mat-icon
          class="si-icon"
          color="primary"
          [class.si-state--negative]="materialIconStyle === 'negative'"
          [class.si-state--positive]="materialIconStyle === 'positive'"
          [class.si-state--activated]="materialIconStyle === 'activated'"
          [class.si-state--highlight]="materialIconStyle === 'highlight'"
          [class.si-state--inaccessible]="materialIconStyle === 'inaccessible'"
          [class.si-state--locked]="materialIconStyle === 'locked'"
          *ngIf="materialIcon && !avatarTitle"
        >
          {{ materialIcon }}
        </mat-icon>
        <mat-icon
          class="si-icon"
          color="primary"
          [class.si-state--negative]="materialIconStyle === 'negative'"
          [class.si-state--positive]="materialIconStyle === 'positive'"
          [class.si-state--activated]="materialIconStyle === 'activated'"
          [class.si-state--highlight]="materialIconStyle === 'highlight'"
          [class.si-state--inaccessible]="materialIconStyle === 'inaccessible'"
          [class.si-state--locked]="materialIconStyle === 'locked'"
          *ngIf="materialIcon && avatarTitle"
          [title]="avatarTitle"
        >
          {{ materialIcon }}
        </mat-icon>
      </div>
      <div mat-card-avatar class="si-avatar" *ngIf="avatar">
        <div class="si-avatar__h" *ngIf="avatarTitle" [title]="avatarTitle">{{ avatar }}</div>
        <div class="si-avatar__h" *ngIf="!avatarTitle">{{ avatar }}</div>
      </div>
      <mat-card-title class="si-card__title" *ngIf="title">{{ title }}</mat-card-title>
    </mat-card-header>
  </mat-card>
</ng-template>
