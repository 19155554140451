import { Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NotificationAction, Pagination, SaeNotification, ToolbarAction } from '@sae/models';
import { INotificationsService, NOTIFICATIONS_TOKEN, REMOTE_CONFIG_TOKEN } from '@sae/services';
import { NotificationsHelperService } from '../notifications-helper.service';
import { IRemoteConfigService, EnterpriseAppEntry } from '@sae/base';

@Component({
  selector: 'si-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnDestroy {
  unreadCount$ = this.notificationService.totalUnread$;
  loading$ = this.notificationService.loading$;
  selectedAppFilter$ = this.notificationService.selectedAppFilter$;
  notifications$ = this.notificationService.notifications$;
  enterpriseMenu$ = this.remoteConfigService.enterpriseMenu$;
  dataViewActions: ToolbarAction[] = [
    {
      title: 'All Alert Notifications',
      icon: 'call_merge',
      name: 'all_alert_notifications',
    },
    {
      title: 'All Alerts Set',
      icon: 'call_merge',
      name: 'all_alert_set',
    },
  ];

  @Input() hideNotificationOptions: boolean;

  @ViewChild('list', { static: false }) list: ElementRef<HTMLElement>;

  constructor(
    @Inject(REMOTE_CONFIG_TOKEN) protected remoteConfigService: IRemoteConfigService,
    @Inject(NOTIFICATIONS_TOKEN) protected notificationService: INotificationsService,
    protected notificationsHelperService: NotificationsHelperService,
    protected dialog: MatDialog,
    protected router: Router
  ) {
    this.notificationService.init();

    //SW2 doesn't need certain menu options
    if (!this.hideNotificationOptions) {
      this.dataViewActions.splice(1, 1);
    }
  }

  onScroll(): void {
    const pagination = { ...this.notificationService.getPagination() };
    pagination.offset = pagination.offset + pagination.limit;
    this.notificationService.setPagination(pagination);
  }

  trackByFn(_: number, notification: SaeNotification): string {
    return notification.id;
  }

  selectFilter(selectedAppFilter: EnterpriseAppEntry): void {
    this.notificationService.setSelectedAppFilter(selectedAppFilter);
    this.list?.nativeElement.scrollTo(0, 0);
  }

  extractUrlFromEmailBody(notif: SaeNotification): string {
    const urls = notif.body.html.match(/https?:\/\/[^\s<>"]*/g);
    return urls ? urls[0] : null;
  }

  markRead(notification: SaeNotification): void {
    this.notificationsHelperService.handleNotificationAction(
      this.notificationService,
      this.dialog,
      NotificationAction.MarkRead,
      notification
    );
  }
  markUnread(notification: SaeNotification): void {
    this.notificationsHelperService.handleNotificationAction(
      this.notificationService,
      this.dialog,
      NotificationAction.MarkUnread,
      notification
    );
  }
  delete(notification: SaeNotification): void {
    this.notificationsHelperService.handleNotificationAction(
      this.notificationService,
      this.dialog,
      NotificationAction.Delete,
      notification
    );
  }
  goToAlert(notification: SaeNotification): void {
    this.notificationsHelperService.handleNotificationAction(
      this.notificationService,
      this.dialog,
      NotificationAction.Access,
      notification
    );
  }
  removeAlertAndDeleteNotification(notification: SaeNotification): void {
    this.notificationsHelperService.handleNotificationAction(
      this.notificationService,
      this.dialog,
      NotificationAction.Delete_Unsubscribe,
      notification
    );
  }

  onAction(action: string): void {
    this.resetPagination();
    switch (action) {
      case 'all_alert_set': {
        void this.router.navigate(['/enterprise/alerts']);
        break;
      }
      case 'all_alert_notifications': {
        void this.router.navigate(['/enterprise/notifications']);
      }
    }
  }

  getApplicationName(apps: EnterpriseAppEntry[], notification: SaeNotification): string {
    const appWithMessages = apps.find((a) => a.messagesKey === notification.applicationCode);
    return appWithMessages?.label;
  }

  getTimeAgo(notification: SaeNotification): string {
    return this.notificationsHelperService.getTimeAgo(notification);
  }

  getIsAlert(notification: SaeNotification): boolean {
    return this.notificationsHelperService.isAlert(notification);
  }

  getIcon(notification: SaeNotification): string {
    return this.notificationsHelperService.getIcon(notification);
  }

  private resetPagination(): void {
    const pagination: Pagination = { offset: 0, limit: 50, total: 0 };
    this.notificationService.setPagination(pagination);
  }

  // reset offset to fix disappearing notifications bug
  ngOnDestroy(): void {
    this.resetPagination();
  }
}
