import { ComponentType } from '@angular/cdk/portal';
import { Injectable, InjectionToken } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, catchError, first, of, tap } from 'rxjs';
import { MobilusListManagerNode, MobilusListManagerSection } from '../models/resource.model';
import { SessionService } from '../state/session.service';

export const SNACKBAR_LOCAL_CONFIG = { duration: 3000 };
export interface IDialogComponent {
  data: unknown;
  service: ISnackbarDialogService;
  data$: Observable<unknown>;
  loading$: Observable<boolean>;
  error$: Observable<unknown>;
  createNodeSubmitted(event: unknown): unknown;
}
@Injectable({
  providedIn: 'root',
})
export class SnackbarDialogService {
  constructor(
    protected matDialog: MatDialog,
    protected snackBar: MatSnackBar,
    private router: Router,
    private sessionService: SessionService
  ) {}

  openDialog(type: string, title: string, icon: string, component: ComponentType<unknown>): MatDialogRef<unknown> {
    const dialogRef = this.matDialog.open(component, {
      data: { dialogTitle: title, dialogType: type, dialogIcon: icon },
    });
    return dialogRef;
  }

  openSnackbar(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<unknown> {
    return this.snackBar.open(message, action, config);
  }

  openSetAlertSuccessSnackbar(): void {
    const alertSuccessSnackbarRef = this.openSnackbar('Alert has been set', 'View', SNACKBAR_LOCAL_CONFIG);
    alertSuccessSnackbarRef
      .onAction()
      .pipe(
        first(),
        tap(() => {
          this.sessionService.setActiveNavIcon('My Library');
          this.router.navigate(['mylibrary/alerts/all']);
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe({
        error: (error) => console.error('error', error),
      });
  }

  openAlertRemoveSuccessSnackbar(): void {
    this.openSnackbar('Alert has been removed', 'DISMISS', SNACKBAR_LOCAL_CONFIG);
  }
}

export const SNACKDIALOG_TOKEN = new InjectionToken<ISnackbarDialogService>('SnackbarDialogService');

export interface ISnackbarDialogService {
  toggleSelected(element: MobilusListManagerSection | MobilusListManagerNode, root: boolean): Observable<unknown>;
  createNode(element: MobilusListManagerSection | MobilusListManagerNode, name: string): Observable<unknown>;
  processAction(event: unknown): Observable<unknown>;
  onComponentClose(): void;
}
