import { AuditFields, SaeHttpResponse } from './http-response.model';

export function convertStatusFilterToStatus(
  filter: SurveyVersionStatusFilter
): SurveyVersionStatus | undefined {
  switch (filter) {
    case SurveyVersionStatusFilter.all:
      return undefined;
      break;
    case SurveyVersionStatusFilter.editing:
      return SurveyVersionStatus.editing;
      break;
    case SurveyVersionStatusFilter.inReview:
      return SurveyVersionStatus.reviewing;
      break;
    case SurveyVersionStatusFilter.inactive:
      return SurveyVersionStatus.inactive;
      break;
    case SurveyVersionStatusFilter.published:
      return SurveyVersionStatus.published;
      break;
  }
}

export enum SurveyVersionStatus {
  published = 'published',
  inactive = 'inactive',
  editing = 'editing',
  reviewing = 'reviewing',
}

export enum SurveyVersionStatusFilter {
  all = 'all',
  published = 'published',
  inactive = 'inactive',
  editing = 'editing',
  inReview = 'inreview',
}

export interface SurveyVersionHttpResponse extends SaeHttpResponse {
  results: Array<SaeSurveyVersion>;
}

export interface SurveyResponse extends SaeHttpResponse {
  results: Array<SaeSurveyVersion>;
}

export interface SaeSurveyVersionFields {
  status: SurveyVersionStatus;
  surveyId: string;
  version: number;
  survey: SurveyData;
  titleUrlText?: string;
  metadata?: unknown;
}

export type CreateSurveyVersionPayload = SaeSurveyVersionFields;

export interface Item {
  name: string;
  [key: string]: any;
}

export interface Page {
  name?: string;
  title?: string;
  description?: string;
  elements?: SurveyElement[];
  [key: string]: any;
}

export enum SurveyElementType {
  checkbox = 'checkbox',
  text = 'text',
  radiogroup = 'radiogroup',
  dropdown = 'dropdown',
  comment = 'comment',
  rating = 'rating',
  ranking = 'ranking',
  imagepicker = 'imagepicker',
  boolean = 'boolean',
  image = 'image',
  html = 'html',
  signaturepad = 'signaturepad',
  expression = 'expression',
  file = 'file',
  matrix = 'matrix',
  matrixdropdown = 'matrixdropdown',
  matrixdynamic = 'matrixdynamic',
  multipletext = 'multipletext',
  panel = 'panel',
  paneldynamic = 'paneldynamic',
}

export enum SurveyElementInputType {
  color = 'color',
  date = 'date',
  datetime = 'datetime',
  datetimelocal = 'datetime-local',
  email = 'email',
  month = 'month',
  number = 'number',
  password = 'password',
  range = 'range',
  tel = 'tel',
  text = 'text',
  time = 'time',
  url = 'url',
  week = 'week',
}

export interface ChoiceObject {
  value?: string;
  text?: string;
}

export interface SurveyElement {
  choices?: any[];
  choicesOrder?: string;
  columns?: any[];
  commentText?: string;
  defaultValue?: string | Array<string>;
  description?: string;
  hasComment?: boolean;
  hasOther?: boolean;
  hideIfChoicesEmpty?: string;
  hideNumber?: boolean;
  imageLink?: string;
  inputType?: SurveyElementInputType;
  isRequired?: boolean;
  items?: Item[];
  maxLength?: number;
  maxSelectedChoices?: number;
  maxSize?: number;
  name: string;
  otherErrorText?: string;
  otherText?: string;
  placeHolder?: string;
  readOnly?: boolean;
  requiredIf?: string;
  rows?: string[];
  startWithNewLine?: boolean;
  title?: string;
  type: SurveyElementType;
  visible?: boolean;
  visibleIf?: string;
  width?: string;
  [key: string]: any;
}


export interface SurveyData {
  title?: string;
  description?: string;
  pages?: Page[];
  showPageNumbers?: boolean;
  startSurveyText?: string;
  pagePrevText?: string;
  pageNextText?: string;
  completeText?: string;
  previewText?: string;
  editText?: string;
  [key: string]: any;
}
export type SurveyItem = Page | SurveyData | SurveyElement | null;

export enum SurveyPageAction {
  delete = 'Delete Page',
  clear = 'Clear Page',
  duplicate = 'Duplicate Page',
  add = 'Add Page After',
}

export enum SurveyElementAction {
  delete = 'Delete Element',
  clear = 'Clear Element',
  duplicate = 'Duplicate Element',
}

export enum SurveyAction {
  clear = 'Clear Survey',
  add = 'Add Page',
}

export enum SurveyItemType {
  surveyData = 'survey data',
  page = 'page',
  element = 'element',
}

export interface SaeSurveyVersion
  extends SaeSurveyVersionFields,
  Partial<AuditFields> { }
