<div class="si-dialog">
  <div class="si-dialog__h">
    <mat-icon class="si-icon" *ngIf="data.dialogIcon">{{ data.dialogIcon }}</mat-icon>
    <h2 class="si-dialog__title">{{ data.dialogTitle }}</h2>
    <span class="si-expanse"></span>
    <button mat-icon-button matTooltip="Close" aria-label="Close" class="si-icon si-button--close" (click)="cancel()" tabIndex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- add folder -->
  <div class="si-dialog__b" *ngIf="data.dialogType === 'addFolder'">
    <div mat-dialog-content class="si-dialog__c">
      <div class="si-spinner__container" *ngIf="formSubmitted === true">
        <mat-spinner class="si-spinner"></mat-spinner>
      </div>
      <form class="si-form" [formGroup]="addFolderForm" *ngIf="formSubmitted === false">
        <div class="si-form__b">
          <mat-form-field class="si-field si-wd si-wd-full">
            <input matInput placeholder="Name" formControlName="name" required class="si-input" swSetAutofocus />
            <mat-error class="si-field__error">Name is required</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div mat-dialog-actions class="si-dialog__a" *ngIf="formSubmitted === false">
      <button mat-flat-button class="si-button si-button--secondary" (click)="cancel()">Cancel</button>
      <button mat-flat-button class="si-button si-button--primary" [class.si-state--disabled]="!addFolderForm.valid" [disabled]="!addFolderForm.valid" (click)="addFolder()">
        Save Folder
      </button>
    </div>
  </div>

  <!-- add file -->
  <div class="si-dialog__b" *ngIf="data.dialogType === 'addFile'">
    <div mat-dialog-content class="si-dialog__c">
      <div class="si-spinner__container" *ngIf="formSubmitted === true">
        <mat-spinner class="si-spinner"></mat-spinner>
      </div>
      <form class="si-form si-form--row" [formGroup]="addFileForm" enctype="multipart/form-data">
        <div class="si-form__b">
          <input type="file" formControlName="file" style="display: none" [accept]="delimitedFileTypes" #fileControl (change)="onFileInputChanged($event)" />
          <input type="hidden" name="fileHidden" formControlName="fileInput" />
          <mat-form-field class="si-field si-wd si-wd-full" (click)="fileControl.click()">
            <input class="si-input si-input--upload" placeholder="File *" matInput readonly formControlName="filePath" [value]="fileControl.value | fakepathClean" />
            <mat-icon matSuffix class="si-suffix si-suffix--icon si-link">cloud_upload</mat-icon>
            <mat-error class="si-field__error">File is required</mat-error>
          </mat-form-field>
          <mat-form-field class="si-field si-wd si-wd-full">
            <input matInput placeholder="Name" class="si-input" required formControlName="name" />
            <mat-error class="si-field__error">Name is required</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div mat-dialog-actions class="si-dialog__a" *ngIf="formSubmitted === false">
      <button mat-flat-button class="si-button si-button--secondary" (click)="cancel()">Cancel</button>
      <button mat-flat-button class="si-button si-button--primary" [class.si-state--disabled]="!addFileForm.valid" [disabled]="!addFileForm.valid" (click)="addFile()">
        Save File
      </button>
    </div>
  </div>

  <!-- add link -->
  <div class="si-dialog__b" *ngIf="data.dialogType === 'addLink'">
    <div mat-dialog-content class="si-dialog__c">
      <div class="si-spinner__container" *ngIf="formSubmitted === true">
        <mat-spinner class="si-spinner"></mat-spinner>
      </div>

      <form class="si-form" [formGroup]="addLinkForm" *ngIf="formSubmitted === false">
        <div class="si-form__b">
          <mat-form-field class="si-field si-wd si-wd-full">
            <mat-label class="si-label">URL</mat-label>
            <span matPrefix class="si-field__prefix">https://</span>
            <input matInput formControlName="url" class="si-input" swSetAutofocus (blur)="formatUrl(addLinkForm.controls.url)" />
            <mat-error *ngIf="addLinkForm.controls.url.invalid && addLinkForm.controls.url.errors.required" class="si-field__error"> URL is required </mat-error>
            <mat-error *ngIf="addLinkForm.controls.url.invalid && addLinkForm.controls.url.errors.badUrl && !addLinkForm.controls.url.errors.required" class="si-field__error">
              URL is invalid
            </mat-error>
          </mat-form-field>
        </div>
        <div class="si-form__b">
          <mat-form-field class="si-field si-wd si-wd-full">
            <input matInput placeholder="Name" formControlName="name" required class="si-input" />
            <mat-error class="si-field__error">Name is required</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div mat-dialog-actions class="si-dialog__a" *ngIf="formSubmitted === false">
      <button mat-flat-button class="si-button si-button--primary" [class.si-state--disabled]="!addLinkForm.valid" [disabled]="!addLinkForm.valid" (click)="addLink()">
        Save Changes
      </button>
    </div>
  </div>

  <!-- delete -->
  <div class="si-dialog__b" *ngIf="data.dialogType === 'itemDelete'">
    <div mat-dialog-content class="si-dialog__c">This will remove the selected item from the available resources.</div>
    <div mat-dialog-actions class="si-dialog__a">
      <button mat-flat-button class="si-button si-button--secondary" (click)="cancel()">Cancel</button>
      <button mat-flat-button class="si-button si-button--primary" cdkFocusInitial (click)="dialogRef.close('confirm')">Delete</button>
    </div>
  </div>

</div>
