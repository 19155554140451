<div class="si-wrap--control" [class.si-wrap]="enableVerllipsis">
  <ng-container *ngIf="href?.length > 0; else noLinkage">
    <a
      [href]="href"
      siSanitizeUrl
      class="si-a--unusual si-component si-button si-button--inline"
      mat-button
      [disableRipple]="disabledRipple"
    >
      <ng-container *ngTemplateOutlet="cardContentTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-template #noLinkage>
    <button class="si-component si-button--wrap" mat-button (click)="clicked()" [disableRipple]="disabledRipple">
      <ng-container *ngTemplateOutlet="cardContentTemplate"></ng-container>
    </button>
  </ng-template>
  <button
    *ngIf="enableVerllipsis"
    mat-icon-button
    [matMenuTriggerFor]="itemMore"
    class="si-icon si-icon--more"
    color="primary"
    matTooltip="Options"
    aria-label="Options"
    (click)="$event.stopPropagation()"
    (menuClosed)="onMenu(false)"
    (menuOpened)="onMenu(true)"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #itemMore="matMenu" class="si-menu">
    <ng-container *ngIf="menuOptions?.length > 0; else standardOptions">
      <si-more-menu
        *ngIf="menuOptions"
        [options]="menuOptions"
        [onlyRenderOptions]="true"
        (optionSelected)="onOptionSelected($event)"
      ></si-more-menu>
    </ng-container>
    <ng-template #standardOptions>
      <button
        mat-menu-item
        data-testid="copy-name"
        class="si-button--detailed"
        *ngIf="enableCopyName"
        (click)="initCopyName()"
      >
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">content_copy</mat-icon>
        <span>Copy Name</span>
      </button>
      <button
        mat-menu-item
        data-testid="copy-url"
        class="si-button--detailed"
        *ngIf="enableCopyUrl"
        (click)="initCopy()"
      >
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">content_copy</mat-icon>
        <span>Copy URL</span>
      </button>
      <button mat-menu-item data-testid="edit" class="si-button--detailed" *ngIf="enableRename" (click)="initEdit()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">edit</mat-icon>
        <span *ngIf="!type.includes('link')">Rename</span>
        <span *ngIf="type.includes('link')">Edit</span>
      </button>
      <button
        mat-menu-item
        data-testid="edit-description"
        class="si-button--detailed"
        *ngIf="enableRename && descriptionSupported"
        (click)="initEditDescription()"
      >
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">edit</mat-icon>
        <span>Edit Description</span>
      </button>
      <button
        mat-menu-item
        data-testid="delete"
        class="si-button--detailed"
        *ngIf="enableDelete"
        (click)="initDelete()"
      >
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">delete</mat-icon>
        <span>Delete</span>
      </button>
      <button mat-menu-item data-testid="get-link" class="si-button--detailed" *ngIf="enableLink" (click)="initLink()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">link</mat-icon>
        <span>Get Link</span>
      </button>
      <button mat-menu-item data-testid="delete" class="si-button--detailed" *ngIf="enableDiscussion" (click)="initDiscussion()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">link</mat-icon>
        <span>Discussion / History</span>
      </button>
      <button mat-menu-item data-testid="delete" class="si-button--detailed" *ngIf="enableFileDiscussion" (click)="initFileDiscussion()">
        <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">link</mat-icon>
        <span>Discussion / History</span>
      </button>
      <ng-container *ngIf="enableDownload">
        <mat-divider class="si-divider"></mat-divider>
        <button
          *ngIf="!type.includes('link')"
          mat-menu-item
          data-testid="download"
          class="si-button--detailed"
          (click)="initDownload()"
        >
          <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">file_download</mat-icon>
          <span>Download</span>
        </button>
        <button
          *ngIf="type.includes('link')"
          mat-menu-item
          data-testid="download"
          class="si-button--detailed"
          (click)="initDownload()"
        >
          <mat-icon class="si-tool-icon" color="primary" aria-hidden="true">launch</mat-icon>
          <span>Access Link</span>
        </button>
      </ng-container>
    </ng-template>
  </mat-menu>
</div>

<ng-template #cardContentTemplate>
  <mat-card class="si-card si-card--basic si-card--file mat-elevation-z">
    <mat-card-header class="si-card__h" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
      <span
        mat-card-avatar
        class="si-file-icon"
        *ngIf="type.includes('folder')"
        [class.si-file-icon__hidden]="toggleable && (toggled || showToggleButton)"
      >
        <span class="si-icon__wrapper si-icon--folder">
          <mat-icon>folder</mat-icon>
        </span>
      </span>
      <span
        mat-card-avatar
        class="si-file-icon"
        *ngIf="type.includes('link')"
        [class.si-file-icon__hidden]="toggleable && (toggled || showToggleButton)"
      >
        <a [href]="href" siSanitizeUrl class="si-icon__wrapper si-icon--folder" (click)="$event.stopPropagation()">
          <mat-icon color="primary" aria-hidden="true">launch</mat-icon>
        </a>
      </span>
      <span
        mat-card-avatar
        class="si-file-icon"
        *ngIf="type.includes('survey')"
        [class.si-file-icon__hidden]="toggleable && (toggled || showToggleButton)"
      >
        <span class="si-icon__wrapper si-icon--resource">
          <mat-icon color="primary" aria-hidden="true">assignment</mat-icon>
        </span>
      </span>
      <span
        mat-card-avatar
        class="si-file-icon"
        *ngIf="type.includes('discussion')"
        [class.si-file-icon__hidden]="toggleable && (toggled || showToggleButton)"
      >
        <span class="si-icon__wrapper si-icon--resource">
          <mat-icon color="primary" aria-hidden="true">question_answer</mat-icon>
        </span>
      </span>
      <span
        mat-card-avatar
        class="si-file-icon"
        *ngIf="
          !type.includes('folder') && !type.includes('link') && !type.includes('survey') && !type.includes('discussion')
        "
        [class.si-file-icon__hidden]="toggleable && (toggled || showToggleButton)"
      >
        <span class="si-icon__wrapper si-icon--file si-icon--file--{{ type }}">
          <mat-icon aria-hidden="true">insert_drive_file</mat-icon>
          <div class="si-dec">{{ fileExt | fileExtensionDisplay }}</div>
        </span>
      </span>
      <ng-container *ngIf="toggleable && (toggled || showToggleButton)">
        <button
          mat-icon-button
          class="si-button si-button--icon si-button--row si-button--multiselect"
          [title]="!toggled ? 'Select Row' : 'Unselect Row'"
          (click)="onToggleClick($event)"
        >
          <mat-icon aria-hidden="true" *ngIf="!toggled">radio_button_unchecked</mat-icon>
          <mat-icon aria-hidden="true" *ngIf="toggled">check_circle</mat-icon>
        </button>
      </ng-container>
      <mat-card-title class="si-card__h si-card__title">
        <ng-container *ngIf="title && !renameMode">
          <span class="si-card__title--resource">{{ title }}</span>
        </ng-container>
        <div class="si-component" *ngIf="renameMode">
          <form class="si-form si-form--cell" [formGroup]="renameForm" (submit)="submitRename()">
            <mat-form-field class="si-field si-wd si-wd-full">
              <input
                matInput
                formControlName="name"
                class="si-input"
                libSetAutofocus
                (blur)="submitRename()"
                (keydown.enter)="submitRename()"
              />
            </mat-form-field>
          </form>
        </div>
      </mat-card-title>
      <mat-card-subtitle class="si-card__subtitle" *ngIf="descriptionSupported">
        <ng-container *ngIf="!renameDescriptionMode && description">
          {{ description }}
        </ng-container>
        <div class="si-component" *ngIf="renameDescriptionMode">
          <form class="si-form si-form--cell" [formGroup]="renameFormDescription" (submit)="submitRenameDescription()">
            <mat-form-field class="si-field si-wd si-wd-full">
              <input
                matInput
                formControlName="description"
                class="si-input"
                libSetAutofocus
                (blur)="submitRenameDescription()"
                (keydown.enter)="submitRenameDescription()"
              />
            </mat-form-field>
          </form>
        </div>
      </mat-card-subtitle>
      <mat-card-subtitle class="si-card__subtitle" *ngIf="!renameMode">
        <ng-container *ngIf="subTitle; else modifiedInfo">
          {{ subTitle }}
          <!-- <ng-container *ngIf="replies">
            <span class="si-expanse"></span>
            <a class="si-wd--tablet">
              <em>{{ replies }}</em>
            </a>
          </ng-container> -->
        </ng-container>
        <ng-template #modifiedInfo>
          <!-- TODO: Show 'edited;' text if boolean is true -->
          <ng-container *ngIf="showModifiedTime; else dateOnly">
            {{ modifiedDate | date: 'MMM d, y hh:mm a' }}
          </ng-container>
          <ng-template #dateOnly>
            {{ modifiedDate | date: 'MMM d, y' }}
          </ng-template>
        </ng-template>
        <ng-container *ngIf="showModifiedByUser">
          {{ modifiedByUser }}
        </ng-container>
        <ng-container *ngIf="replies">
          <span class="si-expanse"></span>
          <a class="si-wd--tablet">
            <em>{{ replies }}</em>
          </a>
        </ng-container>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</ng-template>
