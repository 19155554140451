<form [formGroup]="feedbackFormGroup">
  <mat-form-field class="si-field si-wd si-wd-full">
    <textarea class="si-textarea feedback-textarea" matInput placeholder="Type your feedback here" required formControlName="feedbackText"></textarea>
  </mat-form-field>
  <ng-container *ngIf="feedbackConfig.showFileUpload">
    <input
      formControlName="files"
      type="file"
      name="files"
      placeholder="Upload"
      accept=".doc, .docx, .xls, .xlsx, jpeg, .ppt, .pptx, .jpg, .pdf, .png, .text, .txt, .zip"
      (change)="processAttachments(files.files)"
      multiple="true"
      #files
      style="display: none"
    />
    <mat-form-field class="si-field si-wd si-wd-full" (click)="files.click()">
      <input matInput placeholder="Attach File" [value]="files.value | fakepathClean" readonly class="si-input si-input--upload" />
      <mat-icon matSuffix class="si-suffix si-suffix--icon">cloud_upload</mat-icon>
    </mat-form-field>
  </ng-container>
  <p *ngIf="!feedbackConfig.disclaimerText">Your feedback will be sent to {{ feedbackConfig.tenant }}.</p>
  <p *ngIf="feedbackConfig.disclaimerText">{{feedbackConfig.disclaimerText}}</p>
  <div class="si-form__a">
    <button type="cancel" mat-flat-button class="si-button si-button--secondary" (click)="cancelFeedback()">Cancel</button>
    <button type="submit" mat-flat-button class="si-button si-button--primary" [disabled]="!feedbackFormGroup.valid" (click)="sendFeedback()">Send</button>
  </div>
</form>
