import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { overrideInputs } from '@sae/base';

export interface PartTitleConfig {
  hTag?: number;
  classes?: string;
  heading?: string;
  tooltip?: string;
  impact?: boolean;
}

@Component({
  selector: 'fs-part-title',
  styleUrls: ['part-title.component.scss'],
  templateUrl: './part-title.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class PartTitleComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartTitleConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() hTag: number;
  @Input() classes: string; // Optional, provides a CSS class for the heading
  @Input() heading: string;
  @Input() tooltip: string;
  @Input() impact = true; // If true, the heading will be formatted with an impact style

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }

    if (this.hTag === null) {
      // eslint-disable-next-line @nx/workspace-no-reassign-inputs
      this.hTag = 2;
    }
  }
}
