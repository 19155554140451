import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { MoreOption } from '@sae/models';

export type SeoChiplistIconPosType = 'left' | 'right' | null; // Defines the position of the icon, if null 'right' is default.

export interface SeoChiplistDto {
  label: string; // Text label inside the chip.
  href?: string; // Optional, sets the routerlink URL to use when clicking on the chip, if not supplied sets routerLink to null.
  fragment?: string; // Optional, if supplied and a href is being used, adds supplied string to the fragment attribute.
  clickEventEmitter?: boolean; // Optional, if set and true instead of the chip being a routerLink to the href, dispatches a chipClicked event when the chip is clicked.
  classes?: string; // Optional, appends the supplied string to the class attribute for the chip.
  icon?: string; // Optional, if set adds the specified Material Icon to the right of the chip.
  iconPos?: SeoChiplistIconPosType; // Optional, defines the position of the icon, if null 'right' is default.
  iconEventEmitter?: boolean; // Optional, only used if icon is set, when true dispatches the iconClicked event when the icon is clicked.
  tooltip?: string; // Optional, replaces title and ariaLabel, sets the aria-label attribute and matTooltip to the supplied string.
  classObject?: { [klass: string]: boolean }; // Do not use. Internal property derived at runtime to set ngClass of chip.
  analyticsId?: string; // value for the data-analytics attribute
}

@Component({
  selector: 'si-seo-chiplist',
  templateUrl: './seo-chiplist.component.html',
})
export class SeoChiplistComponent implements DoCheck {
  @Input() ariaLabel = 'List'; // Text to include in the outer container <div> aria-label attribute.
  @Input() isMinor = false; // If true applies si-chip--minor to all chips.
  @Input() isLong = false; // If true applies si-chip--long to all chips.
  @Input() isMedium = false; // If true applies si-chip--medium to all chips.
  @Input() isTopic = false; // If true applies si-chip--topic to all chips.
  @Input() isSearchTerm = false; // If true applies si-chip--searchterm to all chips.
  @Input() isRole = false; // If true applies si-chip--role to all chips.
  @Input() isDescriptor = false; // If true applies si-chip--descriptor to all chips.
  @Input() isBit = false; // If true applies si-chip--bit to all chips.
  @Input() isCommittee = false; // If true applies si-chip--committee to all chips.
  @Input() chips: SeoChiplistDto[] = []; // List of SeoChiplistDto objects to display.
  @Input() chipsMenu: MoreOption[] = []; // Optional, if provided, causes clicking on chips to trigger opening a menu with these options, note using this will override href/routerLink and clickEventEmitter event handling on the chip, where instead it is a trigger for the menu
  @Input() ellipsisOverflow = false; // If true, any overflow beyond a certain size text to be ellipsed on one line.

  @Output() iconClicked = new EventEmitter<SeoChiplistDto>(); // Event emitted if iconEventEmitter property is true and when the icon itself is clicked (as opposed to the outer chip).
  @Output() chipClicked = new EventEmitter<SeoChiplistDto>(); // Event emitted if clickEventEmitter property is true and when the chip is clicked.
  @Output() optionSelected = new EventEmitter<{ option: string; label: string }>(); // Event emitted if using chipsMenu and a menu option was selected

  ngDoCheck() {
    this.compileClasses();
  }

  compileClasses() {
    for (const chip of this.chips) {
      if (typeof chip === 'object') {
        const nativeClasses = {
          'mat-chip': true,
          'mat-primary': true,
          'mat-standard-chip': true,
          'si-chip': true,
          'si-chip--unusual': chip.icon && chip.iconPos !== 'left',
          'si-chip--minor': this.isMinor,
          'si-chip--long': this.isLong,
          'si-chip--medium': this.isMedium,
          'si-chip--topic': this.isTopic,
          'si-chip--searchterm': this.isSearchTerm,
          'si-chip--role': this.isRole,
          'si-chip--descriptor': this.isDescriptor,
          'si-chip--bit': this.isBit,
          'si-chip--committee': this.isCommittee,
        };
        const dynamicClasses: { [klass: string]: boolean } = {};

        if (chip.classes && chip.classes.length > 0) {
          const classes = chip.classes.split(' ');

          for (let i = 0; i < classes.length; i++) {
            if (classes[i].length > 0) {
              dynamicClasses[classes[i]] = true;
            }
          }
        }

        chip.classObject = { ...nativeClasses, ...dynamicClasses };
      }
    }
  }

  onIconClicked(chip: SeoChiplistDto, event: MouseEvent) {
    if (chip.iconEventEmitter === true) {
      event.preventDefault();
      event.stopPropagation();

      this.iconClicked.emit(chip);
    }
  }

  onChipClicked(chip: SeoChiplistDto, event: MouseEvent) {
    if (chip.clickEventEmitter === true) {
      event.preventDefault();
      event.stopPropagation();

      this.chipClicked.emit(chip);
    }
  }

  onOptionSelected(option: string, label: string) {
    this.optionSelected.emit({ option, label });
  }
}
