import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { isExternalLink, overrideInputs } from '@sae/base';

export type TextState = '' | 'caution' | 'minor' | 'negative' | 'positive' | 'primary' | 'secondary';

export interface TextContent {
  classes?: string;
  external?: boolean;
  isStrong?: boolean;
  isEm?: boolean;
  isInfo?: boolean;
  spaceBefore?: boolean; // Adds a space before the text
  state?: TextState;
  text?: string;
  tooltip?: string;
  url?: string;
  another?: TextContent;
}

export interface PartTextConfig {
  text?: TextContent;
  disableLinks?: boolean;
}

@Component({
  selector: 'fs-part-text',
  styleUrls: ['part-text.component.scss'],
  templateUrl: './part-text.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class PartTextComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartTextConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() text: TextContent;
  @Input() disableLinks = false; // Allows forcibly disabling links to avoid ADA issues (for when inside of a link)

  _textInnerHTML: string;
  fragmentUrl: string;
  internalUrl = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }

    if (changes['text']) {
      this._textInnerHTML = this.formatOutput(changes['text'].currentValue);
    }

    if (this.text?.url?.includes('#')) {
      const urlParts = this.text.url.split('#');
      this.text.url = urlParts[0];
      this.fragmentUrl = urlParts[1];
    }

    if (this.text?.url?.startsWith('mailto:')) {
      this.internalUrl = false;
    }

    if (isExternalLink(this.text.url ?? '')) {
      this.internalUrl = false;
    }
  }

  formatOutput(c: TextContent): string {
    let output = `<span class="si-parttext__inside">${c.text}</span>`;

    if (c.isEm) {
      output = `<em class="si-parttext__inside">${output}</em>`;
    }
    if (c.isStrong) {
      output = `<strong class="si-parttext__inside">${output}</strong>`;
    }
    return output;
  }
}
