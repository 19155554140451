export interface FileToUpload {
  // the data provided by the user prior to file upload
  name: string;
  file: File;
  fileExt?: string;
}
export interface FileUpload {
  // the response from files API after uploading a file (*which will be mostly empty unless you do another GET to the meta endpoint)
  anchorId: string;
  fileId: string;
  fileSize: string; // for some reason the service types this as a human-readable string e.g. "200 MB"
  hash: [
    {
      algorithm: string;
      checksum: string;
    }
  ];
  location: string;
  mimeType: string;
  originalFileName: string;
  previousFileId: string;
  status: string;
  tenantId: string;
  uploadType: string;
  uploadedBy: string;
  uploadedDate: string;
}

export interface DisplayFile {
  // This shape is API-agnostic.
  name: string;
  id: string;
  fileExt?: string; // Icon will be derived from this. Saved from file input and populated in files service upload method, because it doesn't come back from API.
  fileSize?: number; // Saved from file input and populated in files service upload method, because it doesn't come back from API.
  permissions?: FilePermissions; // Determines available file-card menu items. File-level permissions, if any, will override the component-level fileCardMenu.
}

export interface FilePermissions {
  rename?: boolean;
  delete?: boolean;
  download?: boolean;
  getLink?: boolean;
}

export enum FileExtension {
  asn = 'asn',
  doc = 'doc',
  docm = 'docm',
  docx = 'docx',
  dot = 'dot',
  dotm = 'dotm',
  dotx = 'dotx',
  ics = 'ics',
  jpeg = 'jpeg',
  jpg = 'jpg',
  kml = 'kml',
  mdzip = 'mdzip',
  mp4 = 'mp4',
  pdf = 'pdf',
  png = 'png',
  ppt = 'ppt',
  potx = 'potx',
  pptx = 'pptx',
  text = 'text',
  txt = 'txt',
  vsd = 'vsd',
  vsdx = 'vsdx',
  xls = 'xls',
  xlsm = 'xlsm',
  xlsx = 'xlsx',
  xsd = 'xsd',
  zip = 'zip'
}

// used for validation and form input attributes, so it needs to have the '.'
export const AllowedFileTypes: string[] = Object.values(FileExtension).map((t) => '.' + t);
