/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { ResourceSortKey } from '@sae/models';

@Component({
  selector: 'si-resource-sort',
  templateUrl: './resource-sort.component.html',
})
export class ResourceSortComponent implements OnInit {
  @Input() sortProperty: ResourceSortKey | string = ResourceSortKey.modificationDate;
  @Input() sortDirection: SortDirection = 'asc';
  @Input() showModifiedByUser = false;
  @Input() enableShare = false;
  @Input() shareCount = 0;
  @Input() shareLabel = 'Group Share';

  @Output() onMenuEvent = new EventEmitter<boolean>();
  @Output() onSortChange = new EventEmitter<Sort>();
  @Output() onRefresh = new EventEmitter<null>();
  @Output() onShareChipClicked = new EventEmitter<void>();

  public sortPropertyDisplay: string;
  public sortKey = ResourceSortKey;

  ngOnInit(): void {
    this.setSortPropertyDisplay(this.sortProperty);
  }

  public emitMenuEvent(open: boolean): void {
    this.onMenuEvent.emit(open);
  }

  public setSortProperty(property: ResourceSortKey): void {
    this.sortProperty = property;
    this.setSortPropertyDisplay(property);
    this.emitSortChange();
  }

  public toggleSortDirection(): void {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.emitSortChange();
  }

  public emitRefreshEvent(): void {
    this.onRefresh.emit();
  }

  public shareChipClicked(): void {
    this.onShareChipClicked.emit();
  }

  private setSortPropertyDisplay(property: ResourceSortKey | string): void {
    switch (property) {
      case ResourceSortKey.modificationDate:
        this.sortPropertyDisplay = 'Modified Date';
        break;
      case ResourceSortKey.modifiedBy:
        this.sortPropertyDisplay = 'Modified by User';
        break;
      case ResourceSortKey.name:
        this.sortPropertyDisplay = 'Title';
        break;
      case ResourceSortKey.fileType:
        this.sortPropertyDisplay = 'Type';
        break;
    }
  }

  private emitSortChange(): void {
    const sort: Sort = {
      direction: this.sortDirection,
      active: this.sortProperty,
    };
    this.onSortChange.emit(sort);
  }
}
