<ng-container
  *ngIf="{
    isMasterPanelCollapsed: isMasterPanelCollapsed$ | async,
    template: template$ | async,
    userLoggedIn: userLoggedIn$ | async,
    subscriptions: subscriptions$ | async,
    subLoggedIn: subLoggedIn$ | async,
    showMobileNavMenu: showMobileNavMenu$ | async,
    showMenu: showMenu$ | async,
    userProfile: userProfile$ | async,
    personType: personType$ | async,
    userInitials: userInitials$ | async
  } as _"
>
  <mat-toolbar
    class="si-toolbar-top si-narrow--top"
    [class.si-template--d]="_.template === 'd'"
    [class.si-template--m-d]="_.template === 'm-d'"
    [class.si-template--c-m-d]="_.template === 'c-m-d'"
    [class.si-template--d-t]="_.template === 'd-t'"
  >
    <mat-toolbar-row>
      <button
        *ngIf="_.showMenu"
        mat-icon-button
        role="button"
        aria-label="Menu"
        class="si-button--menu"
        (click)="toggleMobileNavMenuClick(_.showMobileNavMenu)"
        [class.si-state--selected]="_.showMobileNavMenu"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <si-enterprise-menu></si-enterprise-menu>
      <span class="si-expanse"></span>

      <a
        [matMenuTriggerFor]="loginMenu"
        mat-button
        class="si-button"
        id="loginBtn"
        data-analytics="login-toolbar-btn"
        *ngIf="!_.userLoggedIn && !_.subLoggedIn"
        role="button"
      >
        Login
      </a>
      <mat-menu #loginMenu="matMenu" class="si-menu">
        <button mat-menu-item (click)="login()" class="si-button--detailed">
          <mat-icon color="primary" aria-hidden="true">person</mat-icon>
          <span>My Login (Personal)</span>
        </button>
        <button mat-menu-item (click)="subscriptionLogin()" class="si-button--detailed">
          <mat-icon color="primary">verified_user</mat-icon>
          <span>Subscription Login</span>
        </button>
      </mat-menu>
      <si-notifications class="si-alert__wrap" *ngIf="_.userLoggedIn"></si-notifications>
      <button class="si-button si-button--user" mat-icon-button [matMenuTriggerFor]="userMenu" *ngIf="_.userLoggedIn">
        <div class="si-avatar mat-card-avatar">{{ _.userInitials }}</div>
      </button>
      <mat-menu #userMenu="matMenu" class="si-menu">
        <ng-container *ngIf="!_.subLoggedIn">
          <ng-container *ngTemplateOutlet="userOptions"></ng-container>
        </ng-container>
        <ng-container *ngIf="!!_.subLoggedIn">
          <ng-container *ngTemplateOutlet="bothOptions"></ng-container>
        </ng-container>
      </mat-menu>
      <a
        [routerLink]="baseURL"
        mat-button
        class="si-button"
        (click)="subscriptionLogin()"
        *ngIf="_.userLoggedIn && !_.subLoggedIn"
      >
        Subscription Login
      </a>
      <a [routerLink]="baseURL" (click)="login()" mat-button class="si-button" *ngIf="!_.userLoggedIn && _.subLoggedIn">
        <em>My Login (Personal)</em>
      </a>
      <button
        class="si-button si-button--subscription"
        [matMenuTriggerFor]="subscriptionMenu"
        *ngIf="_.subLoggedIn"
        aria-label="My Subscriptions"
      >
        <mat-icon class="si-icon">verified_user</mat-icon>
        <div class="si-subscription">
          <em>Provided by</em>
          <span class="si-subscription__name">{{ _.subscriptions[0]?.headerText }}</span>
        </div>
      </button>
      <mat-menu #subscriptionMenu="matMenu" class="si-menu">
        <ng-container *ngIf="!_.userLoggedIn">
          <ng-container *ngTemplateOutlet="subscriptionOptions"></ng-container>
        </ng-container>
        <ng-container *ngIf="_.userLoggedIn">
          <ng-container *ngTemplateOutlet="bothOptions"></ng-container>
        </ng-container>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>

  <ng-template #userOptions>
    <div class="si-menu__component">
      <si-person-card-tag
        [personType]="_.personType"
        [personTagInfo]="getPersonTagInfo(_.userProfile)"
      ></si-person-card-tag>
    </div>
    <a [routerLink]="'user-profile'" mat-menu-item class="si-button--detailed">
      <mat-icon color="primary">account_circle</mat-icon>
      <span>Manage Profile</span>
    </a>
    <!--<button mat-menu-item class="si-button--detailed">
      <mat-icon color="primary" aria-hidden="true">settings</mat-icon>
      <span>Settings</span>
    </button> -->
    <mat-divider class="si-divider"></mat-divider>
    <button mat-menu-item class="si-button--detailed" (click)="subscriptionLogin()">
      <mat-icon color="primary" aria-hidden="true">verified_user</mat-icon>
      <span>Subscription Login</span>
    </button>
    <mat-divider class="si-divider"></mat-divider>
    <button mat-menu-item (click)="logoutConfirmDialog()" class="si-button--detailed">
      <mat-icon color="primary">exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </ng-template>

  <ng-template #subscriptionOptions>
    <button mat-menu-item (click)="login()" class="si-button--detailed">
      <mat-icon color="primary">account_circle</mat-icon>
      <span>My Login (Personal)</span>
    </button>
    <mat-divider class="si-divider"></mat-divider>
    <si-basic-card
      *ngFor="let subscription of _.subscriptions"
      class="si-component"
      title="{{ subscription?.headerText }}"
      materialIcon="verified_user"
      subTitle="Subscription"
      [shadow]="false"
    ></si-basic-card>
    <button mat-menu-item class="si-button--detailed" (click)="subscriptionLogin()">
      <mat-icon color="primary" aria-hidden="true">add_circle</mat-icon>
      <span>Add Subscription Login</span>
    </button>
    <mat-divider class="si-divider"></mat-divider>
    <button mat-menu-item class="si-button--detailed" (click)="logoutConfirmDialog()">
      <mat-icon color="primary">exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </ng-template>

  <ng-template #bothOptions>
    <div class="si-menu__component">
      <si-person-card-tag
        [personType]="_.personType"
        [personTagInfo]="getPersonTagInfo(_.userProfile)"
      ></si-person-card-tag>
    </div>
    <a [routerLink]="'user-profile'" mat-menu-item class="si-button--detailed">
      <mat-icon color="primary">account_circle</mat-icon>
      <span>Manage Profile</span>
    </a>
    <!--  <button mat-menu-item class="si-button--detailed">
      <mat-icon color="primary" aria-hidden="true">settings</mat-icon>
      <span>Settings</span>
    </button> -->
    <mat-divider class="si-divider"></mat-divider>
    <si-basic-card
      *ngFor="let subscription of _.subscriptions"
      class="si-component"
      title="{{ subscription?.headerText }}"
      materialIcon="verified_user"
      subTitle="Subscription"
      [shadow]="false"
    ></si-basic-card>
    <button mat-menu-item class="si-button--detailed" (click)="subscriptionLogin()">
      <mat-icon color="primary" aria-hidden="true">add_circle</mat-icon>
      <span>Add Subscription Login</span>
    </button>
    <mat-divider class="si-divider"></mat-divider>
    <button mat-menu-item class="si-button--detailed" (click)="logoutConfirmDialog()">
      <mat-icon color="primary">exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </ng-template>
</ng-container>
