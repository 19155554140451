import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { SaeHttpResponse } from '@sae/models';
import { Observable } from 'rxjs';
import { Term } from '../models/publication.model';
import { Api } from '../../../api';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../env.config';

@Injectable({
  providedIn: 'root',
})
export class ClassificationApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {}

  public getTerms(params?: { depth?: number; uri?: string }): Observable<SaeHttpResponse<Term>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.classifications.url
    }/terms`;
    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);
    return this.httpClient.get<SaeHttpResponse<Term>>(url, {
      params: params ? new HttpParams({ fromObject: params }) : undefined,
    });
  }

  public getTerm(termId: number): Observable<SaeHttpResponse<Term>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.classifications.url
    }/terms/${termId}`;
    return this.httpClient.get<SaeHttpResponse<Term>>(url);
  }

  public getTermDescendents(termId: number): Observable<SaeHttpResponse<Term>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${
      Api.classifications.url
    }/terms/${termId}/descendants`;
    return this.httpClient.get<SaeHttpResponse<Term>>(url);
  }
}
