<form class="si-form" [class.si-tight]="tight">
  <mat-accordion class="si-list-manager si-expanel__container si-expanel--group" [multi]="multiplePanels">
    <div class="si-list-manager__error" *ngIf="errorCodeVisible">
      <si-process-card
        *ngIf="!errorCodeActionEnabled"
        class="si-component"
        type="error"
        [titleText]="errorCodeText ?? 'Failed to load folders'"
      ></si-process-card>
      <si-process-card
        *ngIf="errorCodeActionEnabled"
        class="si-component"
        type="error"
        [titleText]="errorCodeText ?? 'Failed to load folders'"
        [action]="errorCodeActionText ?? 'Retry'"
        actionIcon="refresh"
        (actionSelected)="onSectionsErrorActionClicked()"
        (optionSelected)="onSectionsErrorActionClicked()"
      ></si-process-card>
    </div>
    <ng-container *ngIf="readOnlyInput">
      <ng-container *ngFor="let section of _sections">
        <ng-container *ngTemplateOutlet="sectionTemplate; context: { section: section }"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!readOnlyInput">
      <ng-container *ngFor="let section of sections">
        <ng-container *ngTemplateOutlet="sectionTemplate; context: { section: section }"></ng-container>
      </ng-container>
    </ng-container>
  </mat-accordion>
</form>

<ng-template #sectionTemplate let-section="section">
  <mat-expansion-panel
    #panel
    class="si-expanel mat-elevation-z"
    [(expanded)]="section.expanded"
    [@.disabled]="!initialized"
  >
    <mat-expansion-panel-header
      *ngIf="!hidePanelHeaders"
      class="si-expanel__h si-expanel__h--dialog"
      (click)="onSectionClicked(section)"
    >
      <mat-panel-title class="si-expanel__title">
        <h2>
          <span>{{ section.title }}</span>
          <span *ngIf="section.subtitle" class="si-list-manager__subtitle">
            {{ section.subtitle }}
          </span>
        </h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="si-expanel__c" [class.si-list-manager__headless]="hidePanelHeaders">
      <div *ngIf="section.checkboxPresent" class="si-list-manager__master-checkbox--title">
        <ng-container
          *ngTemplateOutlet="
            checkboxTemplate;
            context: { node: section, title: section.checkboxTitle ?? 'Added', root: true }
          "
        ></ng-container>
      </div>
      <div
        *ngIf="
          !section.checkboxPresent ||
          (section.checkboxPresent && section.checked) ||
          (section.checkboxPresent && !section.checked && !section.checkboxHidesNodes)
        "
      >
        <div *ngIf="section.nodesGroupTitleVisible" class="si-masthead__h">
          <h4 class="si-list-manager__nodes--title">{{ section.nodesGroupTitleText }}</h4>
          <span class="si-expanse"></span>
          <ng-container *ngIf="section.createNodeEnabled">
            <button
              mat-icon-button
              [matMenuTriggerFor]="sectionMenu"
              class="si-icon"
              color="primary"
              matTooltip="Options"
              aria-label="Options"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #sectionMenu="matMenu" class="si-menu">
              <button
                *ngIf="section.createNodeEnabled"
                mat-menu-item
                class="si-button--detailed"
                (click)="panelCreateNode.open(); onRootAddFolderClick(section, panel)"
              >
                <mat-icon color="primary" aria-hidden="true">create_new_folder</mat-icon>
                <span>{{ section.addNodeMenuText ?? 'Add Folder' }}</span>
              </button>
            </mat-menu>
          </ng-container>
        </div>
        <ng-container *ngFor="let node of section.nodes">
          <ng-container *ngIf="!node.hidden">
            <ng-container
              *ngTemplateOutlet="nodeTemplate; context: { node: node, parentNode: null, section: section, depth: 0 }"
            ></ng-container>
          </ng-container>
        </ng-container>
        <div *ngIf="!section.nodes || section.nodes.length === 0">
          <si-none-card [content]="section.noItemsText ?? 'No items found'"></si-none-card>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    #panelCreateNode
    [hidden]="!section.createNodeEnabled || (hideCreateNodeWithSectionCollapse && !section.expanded)"
    [(expanded)]="section.createNodeVisible"
    [@.disabled]="!initialized"
    class="si-expanel mat-elevation-z"
  >
    <mat-expansion-panel-header
      class="si-expanel__h si-expanel__h--dialog"
      (click)="onCreateHeaderClick(section, panel)"
    >
      <mat-icon *ngIf="section.createNodeIcon" class="si-icon" color="primary">
        {{ section.createNodeIcon }}
      </mat-icon>
      <em>{{ section.createNodeTitle }}</em>
    </mat-expansion-panel-header>
    <div class="si-expanel__c" [class.si-list-manager__panel]="section.createNodeVisible">
      <ng-container
        *ngTemplateOutlet="
          createNodeFormTemplate;
          context: {
            node: section,
            parentNode: section,
            fieldName: section.createNodeFieldName,
            actionText: section.createNodeActionText
          }
        "
      ></ng-container>
    </div>
  </mat-expansion-panel>
  <mat-divider *ngIf="section.divider" class="si-divider si-offset--form"></mat-divider>
</ng-template>

<ng-template #nodeTemplate let-node="node" let-parentNode="parentNode" let-section="section" let-depth="depth">
  <div #nodeContainer>
    <div *ngIf="node.headerLabel" class="si-list-manager__node--header">
      {{ node.headerLabel }}
    </div>
    <div class="si-list-manager__node">
      <ng-container *ngIf="enableNodeNavigation">
        <div class="si-list-manager__node--menu">
          <button
            mat-icon-button
            class="si-button si-list-manager__node-navigation"
            [class.si-unusual]="
              !node.nodes || node.nodes?.length === 0 || depth >= maxNodeDepth || node.hideExpandButton
            "
            [matTooltip]="node.open ? 'Collapse' : 'Expand'"
            [attr.aria-label]="node.open ? 'Collapse' : 'Expand'"
            (click)="onNodeOpenToggle(node)"
          >
            <mat-icon aria-hidden="true">{{ node.open ? 'expand_more' : 'chevron_right' }}</mat-icon>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="node.checkboxPresent !== false">
        <ng-container
          *ngTemplateOutlet="checkboxTemplate; context: { node: node, title: node.title, root: false }"
        ></ng-container>
      </ng-container>
      <span
        *ngIf="node.checkboxPresent === false"
        class="si-link si-list-manager__node--text-only"
        (click)="$event.preventDefault(); onNodeClicked(node)"
      >
        {{ node.title }}
      </span>
      <div class="si-list-manager__node--checkbox-container">
        <mat-icon *ngIf="node.shared" class="si-icon si-state--locked" aria-label="Shared" matTooltip="Shared">
          group
        </mat-icon>
        <ng-container
          *ngIf="
            (((section.createNodeEnabled && node.createNodeEnabled !== false) || node.createNodeEnabled) &&
              depth < maxNodeDepth) ||
            (section.deleteNodeEnabled && node.deleteNodeEnabled !== false) ||
            node.deleteNodeEnabled
          "
        >
          <div class="si-list-manager__node--menu">
            <button
              mat-icon-button
              [matMenuTriggerFor]="nodeMenu"
              class="si-icon"
              color="primary"
              matTooltip="Options"
              aria-label="Options"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #nodeMenu="matMenu" class="si-menu">
              <button
                *ngIf="
                  ((section.createNodeEnabled && node.createNodeEnabled !== false) || node.createNodeEnabled) &&
                  depth < maxNodeDepth
                "
                mat-menu-item
                class="si-button--detailed"
                (click)="onCreateNode(node, nodeContainer)"
              >
                <mat-icon color="primary" aria-hidden="true">create_new_folder</mat-icon>
                <span>{{ node.addNodeMenuText ?? 'Add Subfolder' }}</span>
              </button>
              <button
                *ngIf="(section.deleteNodeEnabled && node.deleteNodeEnabled !== false) || node.deleteNodeEnabled"
                mat-menu-item
                class="si-button--detailed"
                (click)="onDeleteNode(node, parentNode, section)"
              >
                <mat-icon color="primary" aria-hidden="true">delete</mat-icon>
                <span>{{ node.deleteNodeMenuText ?? 'Delete Folder' }}</span>
              </button>
            </mat-menu>
          </div>
        </ng-container>
        <ng-container *ngIf="node.customMenuOptions?.length > 0 || node.auxButton">
          <div class="si-list-manager__node--menu">
            <button
              *ngIf="node.customMenuOptions?.length > 0"
              mat-icon-button
              [matMenuTriggerFor]="customNodeMenu"
              class="si-icon si-list-manager__buttons"
              color="primary"
              [matTooltip]="node.customMenuTooltip ?? 'Options'"
              [attr.aria-label]="node.customMenuTooltip ?? 'Options'"
            >
              <mat-icon [class]="node.customMenuIconClass" aria-hidden="true">{{ node.customMenuIcon }}</mat-icon>
            </button>
            <mat-menu #customNodeMenu="matMenu" class="si-menu">
              <si-more-menu
                [options]="node.customMenuOptions"
                [onlyRenderOptions]="true"
                (optionSelected)="onNodeCustomMenuOptionClick(node, $event)"
              ></si-more-menu>
            </mat-menu>
            <button
              *ngIf="node.auxButton && node.auxButtonTriggersCustomMenu"
              mat-icon-button
              [matMenuTriggerFor]="customNodeMenu"
              class="si-button si-icon--more si-list-manager__buttons"
              [matTooltip]="node.auxButtonTooltip ?? 'Options'"
              [attr.aria-label]="node.auxButtonTooltip ?? 'Options'"
            >
              <mat-icon [class]="node.auxButtonIconClass" aria-hidden="true">{{ node.auxButtonIcon }}</mat-icon>
            </button>
            <button
              *ngIf="node.auxButton && !node.auxButtonTriggersCustomMenu"
              mat-icon-button
              class="si-button si-icon--more si-list-manager__buttons"
              [matTooltip]="node.auxButtonTooltip"
              [attr.aria-label]="node.auxButtonTooltip"
            >
              <mat-icon [class]="node.auxButtonIconClass" aria-hidden="true">{{ node.auxButtonIcon }}</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="node.subtitle" class="si-list-manager__node--subtitle">
      {{ node.subtitle }}
    </div>
    <div [hidden]="!node.createNodeVisible" class="si-expanel--nested">
      <mat-accordion class="si-expanel__container si-expanel--group">
        <mat-expansion-panel class="si-expanel mat-elevation-z" [expanded]="true">
          <mat-expansion-panel-header class="si-expanel__h si-expanel__h--dialog">
            <mat-icon *ngIf="node.createNodeIcon || section.createNodeIcon" class="si-icon" color="primary">
              {{ node.createNodeIcon ?? section.createNodeIcon }}
            </mat-icon>
            <em>{{ node.createNodeTitle ?? section.createNodeTitle }}</em>
          </mat-expansion-panel-header>
          <div class="si-expanel__c">
            <ng-container
              *ngTemplateOutlet="
                createNodeFormTemplate;
                context: {
                  node: node,
                  parentNode: parentNode,
                  fieldName: node.createNodeFieldName ?? section.createNodeFieldName,
                  actionText: node.createNodeActionText ?? section.createNodeActionText
                }
              "
            ></ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <ng-container *ngIf="node.errorCodeVisible && !node.createNodeVisible">
    <div class="si-list-manager__error--wrapper">
      <ng-container
        *ngTemplateOutlet="
          errorTemplate;
          context: { node: node, parentNode: parentNode, section: section, createNodeField: null }
        "
      ></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="depth < maxNodeDepth && (!enableNodeNavigation || (enableNodeNavigation && node.open))">
    <ng-container *ngFor="let childNode of node.nodes">
      <ng-container *ngIf="!childNode.hidden">
        <div class="si-list-manager__subnode">
          <ng-container
            *ngTemplateOutlet="
              nodeTemplate;
              context: { node: childNode, parentNode: node, section: section, depth: depth + 1 }
            "
          ></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #checkboxTemplate let-node="node" let-title="title" let-root="root">
  <ng-container *ngIf="!hideNodeCheckboxes">
    <mat-checkbox
      [class.si-list-manager__checkbox--hidden]="node.spinner"
      class="si-checkbox"
      [checked]="node.checked"
      (click)="$event.preventDefault(); onCheckboxClicked(node, root)"
    >
      {{ title }}
    </mat-checkbox>
    <ng-container *ngIf="node.spinner">
      <div class="si-list-manager__checkbox--spinner">
        <div>
          <mat-spinner color="primary" [diameter]="16"></mat-spinner>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hideNodeCheckboxes">
    {{ title }}
  </ng-container>
</ng-template>

<ng-template
  #createNodeFormTemplate
  let-node="node"
  let-parentNode="parentNode"
  let-section="section"
  let-fieldName="fieldName"
  let-actionText="actionText"
>
  <div *ngIf="node.createNodeSpinner" class="si-list-manager__form--spinner">
    <mat-spinner class="si-spinner" [diameter]="32"></mat-spinner>
  </div>
  <div *ngIf="!node.createNodeSpinner" class="si-list-manager__form">
    <mat-form-field class="si-wd si-wd-full">
      <mat-label class="si-label">{{ fieldName }}</mat-label>
      <input
        #createNodeField
        matInput
        class="si-input si-list-manager__input"
        (keydown)="onCreateNodeSubmitCheck($event, node, createNodeField.value)"
        [(ngModel)]="node.createNodeValue"
      />
    </mat-form-field>
    <div *ngIf="!node.errorCodeVisible" class="si-form__a si-form__a--unusual">
      <button
        mat-flat-button
        class="si-button si-button--secondary"
        [class.si-state--disabled]="createNodeField.value.length === 0"
        [disabled]="createNodeField.value.length === 0"
        (click)="onCreateNodeSubmit(node, createNodeField.value)"
      >
        {{ actionText }}
      </button>
    </div>
    <ng-container *ngIf="node.errorCodeVisible">
      <ng-container
        *ngTemplateOutlet="
          errorTemplate;
          context: { node: node, parentNode: parentNode, section: section, createNodeField: createNodeField }
        "
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #errorTemplate
  let-node="node"
  let-parentNode="parentNode"
  let-section="section"
  let-createNodeField="createNodeField"
>
  <span class="si-button--negative">{{ node.errorCodeText ?? 'An error occurred...' }}</span>
  <button
    *ngIf="node.errorCodeActionEnabled"
    mat-flat-button
    class="si-button si-button--secondary"
    [class.si-state--disabled]="createNodeField?.value.length === 0"
    [disabled]="createNodeField?.value.length === 0"
    (click)="onNodeErrorActionClicked(node, parentNode, section, createNodeField?.value)"
  >
    {{ node.errorCodeActionText ?? 'Retry' }}
  </button>
</ng-template>
