import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { overrideInputs } from '@sae/base';

export interface DividerItem {
  classes?: string;
  simple?: boolean;
  spacious?: boolean;
}

@Component({
  selector: 'fs-part-divider',
  styleUrls: ['part-divider.component.scss'],
  templateUrl: './part-divider.component.html',
  standalone: true,
})
export class PartDividerComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: DividerItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes = '';
  @Input() simple = false; // removes border color
  @Input() spacious = false;
  @Input() item: DividerItem; // [DEPRECATED - Will be removed] used instead of other inputs

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['item']) {
      overrideInputs(this, this.item);
    }
  }
}
