export enum ViewTemplate {
  Detail = 'Detail',
  DetailTool = 'DetailTool',
  MasterDetail = 'MasterDetail',
  MasterDetailTool = 'MasterDetailTool',
  CrossoverMasterDetail = 'CrossoverMasterDetail',
  DetailTakeover = 'DetailTakeover',
  DetailToolTakeover = 'DetailToolTakeover',
  PreApp = 'PreApp',
}
