import { Inject, Injectable, InjectionToken } from '@angular/core';
import { fetchAndActivate, getString, RemoteConfig } from '@angular/fire/remote-config';
import { Query, Store } from '@datorama/akita';
import { filter } from 'rxjs/operators';
import { EnterpriseMenu, FeatureFlags, IRemoteConfigService, SaeEnvironmentConfig } from '@sae/base';
import { StoresRegistry, StoresToken } from './../api-registry';

export const REMOTE_CONFIG_TOKEN = new InjectionToken<IRemoteConfigService>('Core Remote Config Service');

@Injectable({ providedIn: 'root' })
export class RemoteConfigQuery<TState extends RemoteConfigState> extends Query<TState> {
  constructor(protected store: RemoteConfigStore<TState>) {
    super(store);
  }
}

/**
 * @deprecated
 * Firebase integration is deprecated.
 * For Proteus/EnvironmentConfigService, enterpriseMenu and featureFlags will be contained within the config.
 * @export
 * @interface RemoteConfigState
 * @typedef {RemoteConfigState}
 */
export interface RemoteConfigState {
  config: SaeEnvironmentConfig | undefined;
  enterpriseMenu: EnterpriseMenu | undefined;
  featureFlags?: FeatureFlags | undefined;
  [key: string]: unknown;
}

export class RemoteConfigStore<TState extends RemoteConfigState> extends Store<TState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry, derivedState?: TState) {
    const initialState = derivedState ?? (RemoteConfigStore.createInitialState() as TState);
    super(initialState, { name: storesRegistry.remoteConfig });
  }
  static createInitialState(): RemoteConfigState {
    return {
      config: undefined,
      enterpriseMenu: undefined,
    };
  }
}

export class RemoteConfigCoreBase<TState extends RemoteConfigState> implements IRemoteConfigService {
  constructor(
    protected readonly remoteConfig: RemoteConfig,
    protected readonly store: RemoteConfigStore<TState>,
    protected readonly query: RemoteConfigQuery<TState>
  ) {}

  public get config(): SaeEnvironmentConfig | undefined {
    return this.query.getValue().config;
  }

  public get enterpriseMenu(): EnterpriseMenu | undefined {
    return this.query.getValue().enterpriseMenu;
  }

  public config$ = this.query.select((state) => state.config).pipe(filter((config) => !!config));
  public enterpriseMenu$ = this.query.select((state) => state.enterpriseMenu);

  public async initAndFetchConfig(): Promise<SaeEnvironmentConfig | undefined> {
    try {
      await fetchAndActivate(this.remoteConfig);
    } catch (err) {
      console.error('error retrieving remote config, will attempt to use defaults', err);
    }
    return this.extractConfigToStore();
  }

  // Populate the akita store with whatever config firebase has settled on.
  private async extractConfigToStore(): Promise<SaeEnvironmentConfig | undefined> {
    const config = JSON.parse(getString(this.remoteConfig, 'config')) as SaeEnvironmentConfig;
    const enterpriseMenu = JSON.parse(getString(this.remoteConfig, 'enterpriseMenu')) as EnterpriseMenu;
    const initState: TState = {
      config,
      enterpriseMenu,
    } as TState;
    this.store.update(initState);
    return this.config;
  }
}

// default implementations of store and query
@Injectable({ providedIn: 'root' })
export class DefaultRemoteConfigStore extends RemoteConfigStore<RemoteConfigState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super(storesRegistry, DefaultRemoteConfigStore.createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class DefaultRemoteConfigQuery extends RemoteConfigQuery<RemoteConfigState> {
  constructor(protected store: DefaultRemoteConfigStore) {
    super(store);
  }
}

/**
 * @deprecated
 * Apps should migrate away from Firebase by using the EnvironmentConfigService
 * https://fullsight.atlassian.net/wiki/spaces/DR/pages/327876627/Proteus+Onboarding+for+Angular+Apps
 * @export
 * @class RemoteConfigCoreService
 * @typedef {RemoteConfigCoreService}
 * @extends {RemoteConfigCoreBase<RemoteConfigState>}
 */
@Injectable({ providedIn: 'root' })
export class RemoteConfigCoreService extends RemoteConfigCoreBase<RemoteConfigState> {
  constructor(
    protected readonly remoteConfig: RemoteConfig,
    protected readonly store: DefaultRemoteConfigStore,
    protected readonly query: DefaultRemoteConfigQuery
  ) {
    super(remoteConfig, store, query);
  }
}
