import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailToolTakeoverViewBase } from '@sae/features';
import { Observable, switchMap, tap } from 'rxjs';
import { ContentService } from '../../../../shared/state/content.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { SessionService } from '../../../../shared/state/session.service';
import { SeoUtilityService } from '../../../../shared/services/seo-utility.service';
import { MobilusProteusConfig } from '../../../../env.config';
import { formatDate } from '@angular/common';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { ContentView } from '../../models/content.model';
@UntilDestroy()
@Component({
  templateUrl: '../templates/detail.component.html',
  host: { 'collision-id': 'ArticlesComponent' },
})
export class ArticlesComponent extends DetailToolTakeoverViewBase implements OnInit, OnDestroy {
  public fragment$: Observable<string> = this.route.fragment;
  public routeLoading$: Observable<boolean> = this.contentService.routeLoading$;
  public isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  public config$ = this.contentService.config$;

  constructor(
    public sessionService: SessionService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private detailsHelperService: DetailsHelperService,
    private seoUtilityService: SeoUtilityService,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {
    super(sessionService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        untilDestroyed(this),
        tap((data) =>
          this.seoUtilityService.setMetaTags(
            `${data?.content?.code}: ${data?.content?.title} - ${data?.content?.subGroup}`,
            data?.content?.abstract
          )
        ),
        tap((data: { content: ContentView }) => {
          if (data?.content?.subGroup !== 'Journal Article') {
            return;
          }
          const tags: { name: string; value?: string }[] = [];
          if (data?.content?.title) {
            tags.push({ name: 'citation_title', value: data.content.title });
          }

          if (data?.content?.contribGroups) {
            for (const cg of data.content.contribGroups) {
              for (const author of cg.contrib) {
                if (author.fullName) {
                  tags.push({ name: 'citation_author', value: author.fullName });
                }
              }
              if (cg.affiliation?.name) {
                tags.push({ name: 'citation_author_institution', value: cg.affiliation.name });
              }
            }
          }

          if (data?.content?.pubDate) {
            const format = 'yyyy/MM/dd';
            const locale = 'en-US';
            const formattedDate = formatDate(data.content.pubDate, format, locale);
            tags.push({ name: 'citation_date', value: formattedDate });
          }

          if (data?.content?.issueNumber) {
            tags.push({ name: 'citation_doi', value: data.content.issueNumber });
          }

          if (data?.content?.language) {
            tags.push({ name: 'citation_language', value: data.content.language });
          }

          if (data?.content?.documentUris?.basicUris?.seoUri) {
            tags.push({
              name: 'citation_abstract_html_url',
              value:
                this.envConfigService.envConfig<MobilusProteusConfig>().baseURL +
                data.content.documentUris.basicUris.seoUri,
            });
          }

          if (data?.content?.id) {
            tags.push({
              name: 'citation_pdf_url',
              value:
                this.envConfigService.envConfig<MobilusProteusConfig>().services.apiRootUrl +
                '/v1/externalindexer/content/' +
                data.content.id +
                '/Full%20Text%20PDF',
            });
          }

          if (data?.content?.publisher) {
            tags.push({
              name: 'citation_publisher',
              value: data.content.publisher,
            });
          }

          if (data?.content?.issn?.length) {
            for (const issn of data.content.issn) {
              tags.push({ name: 'citation_issn', value: issn.content });
            }
          }

          if (data?.content?.relatedArticle?.relatedArticleType === 'Journal') {
            if (data?.content?.relatedArticle?.journalTitle) {
              tags.push({ name: 'citation_journal_title', value: data.content.relatedArticle.journalTitle });
            }
            if (data?.content?.relatedArticle?.volume) {
              tags.push({ name: 'citation_volume', value: `${data.content.relatedArticle.volume}` });
            }
            if (data?.content?.relatedArticle?.issue) {
              tags.push({ name: 'citation_issue', value: `${data.content.relatedArticle.issue}` });
            }
            if (data?.content?.relatedArticle?.fpage) {
              tags.push({ name: 'citation_firstpage', value: `${data.content.relatedArticle.fpage}` });
            }
            if (data?.content?.relatedArticle?.lpage) {
              tags.push({ name: 'citation_lastpage', value: `${data.content.relatedArticle.lpage}` });
            }

            if (data?.content?.formatCode === 'OPEN') {
              tags.push({ name: 'citation_fulltext_world_readable' });
            }
          }

          this.seoUtilityService.googleScholarize(tags);
        }),
        tap((data) => this.setConfigValue(data)),
        switchMap((data) => this.detailsHelperService.getAdditionalDetails(data?.content))
      )
      .subscribe((cv) => this.contentService.setContentView(cv));
  }

  setConfigValue(data): void {
    let customConfig = {};

    switch (data?.content?.subGroup) {
      case 'Journal Article':
        customConfig = {
          panel_enabled: true,
          panel_type: 'journal-article',
          detail_masthead_subheading_type: 'journal',
          panel_title: 'Journal',
          header_aux_text: 'Journal',
          panel_collapse_text: 'Collapse Journal Panel',
        };
        break;
      case 'Technical Paper':
        customConfig = {
          detail_masthead_subheading_type: 'code',
        };
        break;
      case 'Magazine Article':
        customConfig = {
          detail_show_features: false,
          detail_show_data_sets: false,
          detail_show_table_of_contents: false,
          detail_show_references: false,
          detail_show_citations_badge: false,
        };
        break;
      case 'Medical Design Brief':
      case 'NASA Tech Brief':
        customConfig = {
          detail_show_features: false,
          detail_show_data_sets: false,
          detail_show_table_of_contents: false,
          detail_show_references: false,
          detail_show_citations_badge: false,
          detail_show_citation: false,
        };
        break;
      case 'White Paper':
      case 'Solution Notes':
      case 'Tech insights':
        customConfig = {
          detail_show_features: false,
          detail_show_data_sets: false,
        };
        break;
      case 'Defense Feature Article':
      case 'NASA Feature Article':
      case 'Report':
        customConfig = {
          detail_show_features: false,
          detail_show_data_sets: false,
          detail_show_table_of_contents: false,
          detail_show_references: false,
          detail_show_citations_badge: false,
          detail_show_citation: false,
          detail_show_features_adjacent: true,
        };
        break;
      default:
        break;
    }

    this.updateDetailsConfig(customConfig);
  }

  updateDetailsConfig(customConfig): void {
    this.contentService.updateDetailsConfiguration({
      panel_enabled: false,
      detail_show_features: true,
      detail_show_event: true,
      detail_show_authors: true,
      detail_show_abstract: true,
      detail_show_tags: true,
      detail_show_details: true,
      detail_show_doi: true,
      detail_show_citations_badge: true,
      detail_show_page_count: true,
      detail_show_citation: true,
      detail_show_table_of_contents: true,
      detail_show_additional_details: true,
      detail_show_publisher: true,
      detail_show_pub_date: true,
      detail_show_code: true,
      detail_show_selected_type: true,
      detail_show_language: true,
      detail_show_view: true,
      detail_show_data_sets: true,
      detail_show_references: true,
      detail_show_related: true,
      ...customConfig,
    });
  }
  ngOnDestroy(): void {
    this.contentService.resetStore();
    this.seoUtilityService.clearAllSEOTags();
  }
}
