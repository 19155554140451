import { Pipe, PipeTransform } from '@angular/core';
import { Resource, ResourceType } from '@sae/models';

@Pipe({
  name: 'resourceType',
})
export class ResourceTypePipe implements PipeTransform {
  transform(r: Resource): string {
    if (r.resourceType === ResourceType.FOLDER) {
      return '_folder';
    } else if (r.resourceType === ResourceType.LINK) {
      return '_link';
    } else if (r.resourceType === ResourceType.DISCUSSION || r.resourceType === ResourceType.TOPIC) {
      return '_discussion';
    } else {
      return this.getFileExtForIcon(r);
    }
  }

  private getFileExtForIcon(r: Resource): string {
    if (r.metadata && r.metadata.resourceExtension) {
      const ext = r.metadata.resourceExtension.toLowerCase();
      // return generic extensions
      switch (ext) {
        case 'doc':
        case 'docx':
        case 'dot':
        case 'dotm':
        case 'dotx':
          return 'doc';
        case 'xls':
        case 'xlsm':
        case 'xlsx':
          return 'xls';
        case 'ppt':
        case 'pptx':
          return 'ppt';
        default:
          return ext;
      }
    }
    return '';
  }
}
