import {
  inject,
  Inject,
  Injectable,
  InjectionToken,
  ProviderToken,
} from '@angular/core';
import { ActiveState } from '@datorama/akita';
import {
  NgEntityServiceGlobalConfig,
  NG_ENTITY_SERVICE_CONFIG,
} from '@datorama/akita-ng-entity-service';
import { Comment } from '@sae/models';
import { Observable } from 'rxjs';
import {
  ApiRegistry,
  ApiToken,
  StoresRegistry,
  StoresToken,
} from './../api-registry';
import {
  BaseEntityQuery,
  BaseEntityService,
  BaseEntityStore,
  BaseState,
} from './base.service';

export interface ICommentsService {
  selectedComment$: Observable<Comment | undefined>;
  loading$: Observable<boolean>;
  fetchCommentsForAnchorId(anchorId: string): void;
  setApplicationCode(applicationCode: string): void;
  setLoading(loading: boolean): void;
  setActive(commentId: string): void;
  updateComment(commentId: string, updatedComment: Partial<Comment>): void;
  updateActive(updatedComment: Partial<Comment>): void;
  selectCommentsForAnchorId$(anchorId: string): Observable<Array<Comment>>;
}

export interface CommentCoreState extends BaseState<Comment>, ActiveState {}

@Injectable({ providedIn: 'root' })
export class CommentsCoreStore extends BaseEntityStore<CommentCoreState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super({ name: storesRegistry.comments, idKey: 'id' });
  }
}

@Injectable({ providedIn: 'root' })
export class CommentsCoreQuery extends BaseEntityQuery<CommentCoreState> {
  constructor(protected store: CommentsCoreStore) {
    super(store);
  }
}

export class CommentsCoreService
  extends BaseEntityService<CommentCoreState>
  implements ICommentsService
{
  selectedComment$ = this.query.selectActive();
  loading$ = this.query.selectLoading();

  constructor(
    public readonly store: CommentsCoreStore,
    public readonly query: CommentsCoreQuery,
    @Inject(NG_ENTITY_SERVICE_CONFIG)
    ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) apiReg: ApiRegistry
  ) {
    super(store, query, {
      baseUrl: ngEntityServiceGlobalConfig.baseUrl,
      resourceName: apiReg?.comments?.url,
    });
  }

  protected preFetchEntities(): void {
    this.sortFieldParamName = 'sort';
    this.sortField = 'date';
    this.sortDirParamName = 'order';
  }

  fetchCommentsForAnchorId(anchorId: string): void {
    this.addCustomParam('anchor', anchorId);
    this.initializeAndFetch();
  }

  setApplicationCode(applicationCode: string): void {
    this.addCustomParam('applicationCode', applicationCode);
  }

  setLoading(loading: boolean): void {
    this.store.update({ loading });
  }

  setActive(commentId: string): void {
    this.store.setActive(commentId);
  }

  updateComment(commentId: string, updatedComment: Partial<Comment>): void {
    this.store.update(commentId, updatedComment);
  }

  updateActive(updatedComment: Partial<Comment>): void {
    this.store.updateActive(updatedComment);
  }

  selectCommentsForAnchorId$(anchorId: string): Observable<Array<Comment>> {
    return this.query.selectAll({
      filterBy: (comment) => comment.anchor === anchorId,
    });
  }
}

export const COMMENTS_TOKEN = new InjectionToken<ICommentsService>(
  'Comments Service',
  {
    providedIn: 'root',
    factory: () =>
      new CommentsCoreService(
        inject(CommentsCoreStore),
        inject(CommentsCoreQuery),
        inject(NG_ENTITY_SERVICE_CONFIG),
        inject(ApiToken as ProviderToken<ApiRegistry>)
      ),
  }
);
