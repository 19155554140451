<fs-part-util-menu [simpleTrigger]="simpleTrigger">
  <ng-container trigger>
    <ng-content select="[buttonBody]"></ng-content>
    <fs-part-button *ngIf="triggerButton" [buttonItem]="triggerButton"></fs-part-button>
    <span *ngIf="triggerLabel" class="si-button__label" [title]="triggerTooltip">
      {{ triggerLabel }}
    </span>
  </ng-container>
  <ng-container menu>
    <div class="si-menu__buttons">
      <ng-content select="[menuTop]"></ng-content>
    </div>
    <ng-container *ngFor="let item of content; let offset = index">
      <fs-part-divider *ngIf="item.divider" [item]="item.divider"></fs-part-divider>
      <fs-part-label *ngIf="item.label" [content]="item.label"></fs-part-label>
      <ng-container *ngFor="let option of item.options">
        <ng-container *ngIf="option.subMenu; else leafTemplate">
          <fs-part-button
            [matMenuTriggerFor]="subMenu"
            buttonBody
            [label]="option.label ?? ''"
            [materialIcon]="option.materialIcon ?? ''"
            [type]="'detail'"
            [role]="'menuitem'"
            [stopPropagation]="true"
            (buttonClick)="onOpenSubMenu(offset)"
          ></fs-part-button>
          <mat-menu #subMenu="matMenu" class="si-menu si-menu--util" [overlapTrigger]="true">
            <fs-part-util-listbox
              class="si-listbox--util"
              label="Options"
              [role]="null"
              childRole="menuitem"
              orientation="vertical"
            >
              <ng-container menu>
                <ng-container *ngFor="let subMenuOption of option.subMenu">
                  <fs-comp-menu-option
                    [option]="subMenuOption"
                    (buttonClick)="onButtonClick(subMenuOption)"
                  ></fs-comp-menu-option>
                </ng-container>
              </ng-container>
            </fs-part-util-listbox>
          </mat-menu>
        </ng-container>
        <ng-template #leafTemplate>
          <fs-comp-menu-option
            *ngIf="!option.hide"
            [option]="option"
            (buttonClick)="onButtonClick(option)"
          ></fs-comp-menu-option>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-content select="[menuBottomOption]"></ng-content>
    <div class="si-menu__buttons">
      <ng-content select="[menuBottom]"></ng-content>
    </div>
  </ng-container>
</fs-part-util-menu>
