<ng-container
  *ngIf="{
    showMobileNavMenu: showMobileNavMenu$ | async,
    isInMaintenanceMode: isInMaintenanceMode$ | async,
    activeViewTemplate: activeViewTemplate$ | async,
    projectedContent: projectedContent$ | async,
    userProfile: userProfile$ | async,
    personType: personType$ | async,
    isLoggedIn: isLoggedIn$ | async,
    features: features$ | async
  } as _"
>
  <div class="si-canvas">
    <si-app-component
      [activeViewTemplate]="_.activeViewTemplate"
      [isInMaintenanceMode]="_.isInMaintenanceMode"
      [showHomeFeature]="true"
      [features]="_.features"
      [projectedContent]="_.projectedContent"
      updatesRoute="/announcements"
      updatesLabel="Announcement"
    >
      <div customToolbarContent>
        <mobi-toolbar></mobi-toolbar>
      </div>
      <router-outlet (activate)="activate($event)"></router-outlet>
    </si-app-component>
  </div>
</ng-container>
