<ng-container *ngIf="{
  isLoading: isLoading$ | async
} as _">

  <mat-card class="mat-elevation-z2 si-card si-card--dataviewer si-card--stub">
    <mat-card-header class="si-card--dataviewer__h si-stub-header">
      <mat-card-title class="si-card__title si-impact">2D/3D Drawings</mat-card-title>
    </mat-card-header>
    <mat-card-content class="si-card__c" *ngIf="!_.isLoading else loading">

      <mat-card class="si-card mat-elevation-z3 si-wd-full">
        <div class="value"  >
          <!-- <div class="value">Some older browsers could require a plugin install for 3D and or 2D previewer.</div> -->
          <iframe width="100%"  height="700px" scrolling='no' frameborder='no'  [src]="url" >
        
          </iframe>
        </div>

      </mat-card>
    </mat-card-content>

    <ng-template #loading>
      <div class="si-spinner__container">
        <mat-spinner class="si-spinner"></mat-spinner>
      </div>
    </ng-template>
  </mat-card>
</ng-container>