import { Component, Inject, Input } from '@angular/core';
import { PersonTagInfo, ViewTemplate } from '@sae/models';
import {
  AUTH_TOKEN,
  IAuthService,
  ISessionService,
  MasterWidth,
  SESSION_TOKEN,
} from '@sae/services';
import { convertKeycloakProfileToPersonTagInfo } from '@sae/utilities';
import { KeycloakProfile } from 'keycloak-js';
import { Observable } from 'rxjs';

@Component({
  selector: 'si-app-toolbar',
  templateUrl: './app-toolbar.component.html',
})
export class AppToolbarComponent {
  @Input() hideNotificationOptions: boolean;
  public masterWidth$: Observable<MasterWidth> = this.sessionService.masterWidth$;
  public activeViewTemplate$: Observable<ViewTemplate> = this.sessionService.activeViewTemplate$;
  public showMobileNavMenu$ = this.sessionService.showMobileNavMenu$;
  public isLoggedIn$ = this.authService.isLoggedIn$;
  public loggedInUser$ = this.authService.profile$;
  public personType$ = this.authService.personType$;
  public showRegister$ = this.sessionService.showRegisterLoginOption$;
  public showCustomToolbar$ = this.sessionService.showCustomToolbar$;
  public ViewTemplate = ViewTemplate;

  constructor(
    @Inject(AUTH_TOKEN) private readonly authService: IAuthService,
    @Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService
  ) { }

  getPersonTagInfo(profile: KeycloakProfile): PersonTagInfo {
    return convertKeycloakProfileToPersonTagInfo(profile);
  }
  toggleMobileNavMenuClick(showMobileNavMenu: boolean): void {
    this.sessionService.setShowMobileNavMenu(!showMobileNavMenu);
  }

  login(): void {
    void this.authService.login();
  }
  logout(): void {
    void this.authService.logout();
  }
  manage(): void {
    void this.authService.manage();
  }
}
