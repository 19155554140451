<ng-container *ngIf="{ feedbackConfig: feedbackConfig$ | async } as _">
  <button
    mat-button
    class="si-button si-button--scrim"
    (click)="active = false"
    aria-label="Close Menu"
    matTooltip="Close Menu"
    *ngIf="active"
  ></button>
  <nav
    class="si-features"
    siFeatureBar
    [class.si-state--active]="active"
    [class.si-state--open]="showMobileNavMenu"
    [class.si-state--closed]="!showMobileNavMenu"
  >
    <div class="si-features__c">
      <button
        mat-button
        class="si-button si-button--icon si-button--app si-control si-influenced"
        (click)="active = true"
        *ngIf="!active"
      >
        <mat-icon aria-hidden="true">more_horiz</mat-icon>
      </button>
      <button
        mat-button
        class="si-button si-button--icon si-button--app si-control"
        (click)="active = false"
        *ngIf="active"
      >
        <mat-icon aria-hidden="true">close</mat-icon>
      </button>
      <div *ngIf="showHomeFeature || showSearchFeature" [class.si-set]="showHomeFeature && showSearchFeature">
        <a
          *ngIf="showHomeFeature"
          routerLink="/"
          mat-button
          class="si-button si-button--icon si-button--app"
          aria-label="Home"
          title="Home"
          [class.si-state--selected]="activeNavIcon === 'Home'"
          (click)="setActiveNavIcon('Home')"
          id="homeNavBtn"
          data-analytics="home-nav-btn"
        >
          <mat-icon aria-hidden="true">home</mat-icon>
        </a>
        <a
          *ngIf="showSearchFeature"
          routerLink="/search"
          mat-button
          class="si-button si-button--icon si-button--app"
          aria-label="Search"
          title="Search"
          [class.si-state--selected]="activeNavIcon === 'Search'"
          (click)="setActiveNavIcon('Search')"
          id="searchNavBtn"
          data-analytics="search-nav-btn"
        >
          <mat-icon aria-hidden="true">search</mat-icon>
        </a>
      </div>
      <ng-container *ngFor="let feature of features">
        <ng-container *ngIf="!feature.externalUrl && !feature.externalUrls">
          <a
            [routerLink]="feature.url"
            (click)="setActiveNavIcon(feature.name)"
            mat-button
            class="si-button si-button--icon si-button--app"
            [class.si-state--unusual]="feature.name === 'Help'"
            [class.si-state--selected]="activeNavIcon === feature.name"
            [class.si-state--disabled]="feature.disabled"
            [attr.title]="feature.tooltip"
            [attr.id]="feature.id ? feature.id : null"
            [attr.data-analytics]="feature.analyticsId"
          >
            <mat-icon aria-hidden="true">{{ feature.icon }}</mat-icon>
            <span>{{ feature.name }}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="feature.externalUrl">
          <button mat-button [matMenuTriggerFor]="externalLink" class="si-button si-button--icon si-button--app">
            <mat-icon aria-hidden="true">{{ feature.icon }}</mat-icon>
            <span>{{ feature.name }}</span>
          </button>
          <mat-menu #externalLink="matMenu" class="si-menu si-menu--detail">
            <label class="si-label">{{ feature.externalUrl.label }}</label>
            <div class="si-menu__item">
              <a
                href="{{ feature.externalUrl.url }}"
                [attr.title]="feature.tooltip"
                mat-flat-button
                class="si-button si-button--primary"
                rel="noopener"
                target="_blank"
              >
                <mat-icon aria-hidden="true">launch</mat-icon>
                {{ feature.externalUrl.linkButtonText }}
              </a>
            </div>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="feature.externalUrls">
          <button
            mat-button
            [matMenuTriggerFor]="externalLink"
            class="si-button si-button--icon si-button--app si-state--unusual"
          >
            <mat-icon aria-hidden="true">{{ feature.icon }}</mat-icon>
            <span>{{ feature.name }}</span>
          </button>
          <mat-menu #externalLink="matMenu" class="si-menu si-menu--detail">
            <ng-container *ngFor="let externalUrl of feature.externalUrls">
              <div class="si-menu__item">
                <a
                  href="{{ externalUrl.url }}"
                  [attr.title]="feature.tooltip"
                  mat-flat-button
                  class="si-button--detailed"
                  rel="noopener"
                  target="_blank"
                >
                  <mat-icon aria-hidden="true" color="primary">{{ externalUrl.icon }}</mat-icon>
                  {{ externalUrl.linkButtonText }}
                </a>
              </div>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-container>
      <a
        *ngIf="_.feedbackConfig"
        aria-label="Feedback"
        title="Feedback"
        data-testid="feedback"
        mat-button
        class="si-button si-button--icon si-button--app"
        [class.si-state--unusual]="showHelpFeature"
        (click)="showFeedbackBottomsheet()"
      >
        <mat-icon>feedback</mat-icon>
        <span>Feedback</span>
      </a>
      <div *ngIf="showUpdatesFeature || showHelpFeature" class="si-set" [class.si-state--unusual]="!_.feedbackConfig">
        <a
          *ngIf="showUpdatesFeature"
          [routerLink]="updatesRoute"
          mat-button
          data-testid="updates"
          class="si-button si-button--icon si-button--app"
          [attr.aria-label]="updatesLabel"
          [title]="updatesLabel"
          [class.si-state--selected]="activeNavIcon === 'Messages'"
          [style.width.%]="showHelpFeature ? 50 : 100"
          (click)="setActiveNavIcon('Messages')"
          data-analytics="updates-nav-btn"
        >
          <mat-icon aria-hidden="true">campaign</mat-icon>
        </a>
        <a
          *ngIf="showHelpFeature"
          routerLink="/help"
          mat-button
          data-testid="help"
          class="si-button si-button--icon si-button--app"
          aria-label="Help"
          title="Help"
          [class.si-state--selected]="activeNavIcon === 'Help'"
          [style.width.%]="showUpdatesFeature ? 50 : 100"
          (click)="setActiveNavIcon('Help')"
          data-analytics="help-nav-btn"
        >
          <mat-icon aria-hidden="true">help</mat-icon>
        </a>
      </div>
    </div>
  </nav>
</ng-container>
