import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../feature-keys';
import { DownloadDocumentState } from './download-document.state';

export const downloadDocumentFeature = createFeatureSelector<DownloadDocumentState>(FeatureKeys.DownloadDocument);
export const selectLayout = createSelector(downloadDocumentFeature, (state) => state.layout);

export const selectLoadingAccessVerification = createSelector(
  downloadDocumentFeature,
  (state) => state.loadingAccessVerification
);
export const selectContentId = createSelector(downloadDocumentFeature, (state) => state.contentId);
export const selectAccessProperties = createSelector(downloadDocumentFeature, (state) => state.accessProperties);
export const selectDownloadSource = createSelector(downloadDocumentFeature, (state) => state.downloadSource);
export const selectAssets = createSelector(downloadDocumentFeature, (state) => state.assets);
export const selectPurchaseLink = createSelector(downloadDocumentFeature, (state) => state.purchaseLink);
export const selectLoadingAssets = createSelector(downloadDocumentFeature, (state) => state.loadingAssets);
export const selectDetailContentCid = createSelector(downloadDocumentFeature, (state) => state.detailCid);
