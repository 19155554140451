import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../feature-keys';
import { MyLibraryState } from './my-library.state';

export const myLibraryFeature = createFeatureSelector<MyLibraryState>(FeatureKeys.MyLibrary);
export const selectFolders = createSelector(myLibraryFeature, (state) => state.folders);
export const selectIsLoadingFolders = createSelector(myLibraryFeature, (state) => state.loadingFolders);
export const selectFolderItems = createSelector(myLibraryFeature, (state) => state.folderItems);
export const getSelectedFolderTitle = createSelector(myLibraryFeature, (state) => state.selectedFolderTitle);
export const getSelectedfolderId = createSelector(myLibraryFeature, (state) => state.selectedFolderId);
export const getSelectedFolderAnchorId = createSelector(myLibraryFeature, (state) => state.selectedFolderAnchorId);
export const getSelectedSubFolderTitle = createSelector(myLibraryFeature, (state) => state.selectedSubFolderTitle);
export const selectIsLoadingFolderItems = createSelector(myLibraryFeature, (state) => state.loadingFolderItems);
export const selectTotal = createSelector(myLibraryFeature, (state) => state.total);
export const selectIsSubFolder = createSelector(myLibraryFeature, (state) => state.isSubFolder);
export const selectParentFolderShareAccess = createSelector(myLibraryFeature, (state) => state.isSharedFolder);
export const selectMyFavoriteFolder = createSelector(myLibraryFeature, (state) => state.myFavoriteFolder);
export const selectFolderUsers = createSelector(myLibraryFeature, (state) => state.users);
export const selectFolderUsersLoading = createSelector(myLibraryFeature, (state) => state.loadingUsers);
export const selectFolderUsersError = createSelector(myLibraryFeature, (state) => state.usersError);
export const selectFolderUsersPageSize = createSelector(myLibraryFeature, (state) => state.usersPageSize);
export const selectFolderTotalUsers = createSelector(myLibraryFeature, (state) => state.totalUsers);
export const selectFolderUsersPageNum = createSelector(myLibraryFeature, (state) => state.usersPageNum);
export const selectFolderUsersSort = createSelector(myLibraryFeature, (state) => state.usersSort);
export const selectFolderUsersOrder = createSelector(myLibraryFeature, (state) => state.usersOrder);
export const selectFolderInvites = createSelector(myLibraryFeature, (state) => state.folderInvites);
export const selectTotalInvites = createSelector(myLibraryFeature, (state) => state.totalInvites);
export const selectInvitesPageNum = createSelector(myLibraryFeature, (state) => state.invitesPageNum);
export const selectInvitesPageSize = createSelector(myLibraryFeature, (state) => state.invitesPageSize);
export const selectInvitesSort = createSelector(myLibraryFeature, (state) => state.invitesSort);
export const selectInvitesOrder = createSelector(myLibraryFeature, (state) => state.invitesOrder);
