import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, take } from 'rxjs';

export interface EllipsisDirectiveOptions {
  indicator?: string; // Optional, default is a string '...'; Determines what to use for the ellipsis indicator which can be any string, or a template which is appended instead
  wordBreak?: boolean; // Optional, default is true; When true allows an ellipsis to be inserted mid-word, otherwise the ellipsis will be inserted at the end of the last word
  shrinkToFit?: boolean; // Optional, default is false; When true, the directive will attempt to shrink the container to fit the content when it does not overflow
  shrinkPadding?: number; // Optional, default is 0; When shrinkToFit is true, this value will be used as the padding offset to apply to the container height after shrinking
}

interface EllipsisDirectiveDimensions {
  width: number;
  height: number;
  scrollWidth: number;
  scrollHeight: number;
}

@Directive({
  selector: '[siEllipsis]',
  standalone: true,
})
export class EllipsisDirective implements AfterContentInit, OnDestroy {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private templateRef: TemplateRef<unknown>,
    private ngZone: NgZone
  ) {}

  @Input() set siEllipsis(options: EllipsisDirectiveOptions | string) {
    if (typeof options !== 'string') {
      if (typeof options.indicator === 'string') {
        this._options.indicator = options.indicator;
      }
      if (typeof options.wordBreak === 'boolean') {
        this._options.wordBreak = options.wordBreak;
      }
      if (typeof options.shrinkToFit === 'boolean') {
        this._options.shrinkToFit = options.shrinkToFit;
      }
      if (typeof options.shrinkPadding === 'number') {
        this._options.shrinkPadding = options.shrinkPadding;
      }
    }
  }

  // Local default options object, properties can be overridden by the input
  private _options: EllipsisDirectiveOptions = {
    indicator: '...',
    wordBreak: true,
    shrinkToFit: true,
    shrinkPadding: 8,
  };
  private templateView!: EmbeddedViewRef<unknown>; // ViewRef of the main template (the one to be truncated)
  private element?: HTMLElement; // The referenced element
  private initialTextLength!: number; // Text length before truncating
  private removeResizeListeners$ = new Subject<void>(); // Subject triggered when resize listeners should be removed
  private previousDimensions!: EllipsisDirectiveDimensions; // Previous dimensions of the element, used to determine if the element has been resized and needs ellipsis recalculation

  ngAfterContentInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.ngZone.onStable.pipe(take(1)).subscribe(() => {
        this.ngZone.run(() => {
          this.main();
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.removeResizeListeners$.next();
    this.removeResizeListeners$.complete();

    if (this.templateView) {
      this.templateView.destroy();
    }
  }

  private main(): void {
    this.resetView();

    if (this.element) {
      this.previousDimensions = {
        width: this.element.clientWidth,
        height: this.element.clientHeight,
        scrollWidth: this.element.scrollWidth,
        scrollHeight: this.element.scrollHeight,
      };

      if (isPlatformBrowser(this.platformId)) {
        window.requestAnimationFrame(() => {
          this.applyEllipsis();
        });
      }
    }
  }

  // Gets the length of all character data of the element by flattening all text|element nodes and summing the length of the character data nodes
  private get currentLength(): number {
    if (this.element) {
      return this.flattenNodes(this.element)
        .filter((node) => node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE)
        .map((node) => (node instanceof CharacterData ? node.data.length : 1))
        .reduce((sum, length) => sum + length, 0);
    } else {
      return 0;
    }
  }

  // Determines if the element is overflowing (temporarily setting overflow to visible if hidden) and comparing client and scroll dimensions
  private get isOverflowing(): boolean {
    if (this.element) {
      const currentOverflow = this.element.style.overflow;

      if (!currentOverflow || currentOverflow === 'visible') {
        this.element.style.overflow = 'hidden';
      }

      const isOverflowing =
        this.element.clientWidth < this.element.scrollWidth - 1 ||
        this.element.clientHeight < this.element.scrollHeight - 1;

      this.element.style.overflow = currentOverflow;

      return isOverflowing;
    } else {
      return false;
    }
  }

  // Determines the amount of underflow in the container by comparing the client height to the height of the first child element
  private get underflow(): number {
    if (this.element && this.element.children.length > 0) {
      let underflow = 0;
      const container = this.element.children[0] as HTMLElement;

      if (container.children.length > 0) {
        const child = container.children[0] as HTMLElement;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const containerHeight = container.clientHeight ?? 0;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const childHeight = child.offsetHeight ?? child.scrollHeight ?? child.clientHeight;

        if (typeof childHeight === 'number') {
          underflow = Math.max(containerHeight - childHeight, 0);
        }
      }

      return underflow;
    } else {
      return 0;
    }
  }

  // Restores the view to the original state by clearing the view container and creating a new view with the original template housed by EllipsisDirectiveContentComponent
  private resetView(): void {
    this.viewContainer.clear();

    if (isPlatformBrowser(this.platformId)) {
      if (this.element && document.body.contains(this.element)) {
        this.renderer.setStyle(this.element, 'display', 'none');
      }
    }

    if (this.templateView) {
      this.templateView.destroy();
    }
    this.templateView = this.templateRef.createEmbeddedView({});
    this.templateView.detectChanges();

    const componentRef: ComponentRef<EllipsisDirectiveContentComponent> = this.viewContainer.createComponent(
      EllipsisDirectiveContentComponent,
      {
        injector: this.viewContainer.injector,
        projectableNodes: [this.templateView.rootNodes],
      }
    );

    this.element = componentRef.instance.elementRef.nativeElement;
    this.initialTextLength = this.currentLength;
  }

  // Applies all the necessary logic to truncate the text content of the element and add an ellipsis indicator, or shrink the container if the option is set and content is not underflowing
  private applyEllipsis(): void {
    if (this.element) {
      this.removeResizeListeners$.next();
      this.resetView();

      const shrinkable = !this.isOverflowing; // If no truncation is needed to fit the native contents, set flag that we can shrink our container
      const maxLength = EllipsisDirective.numericBinarySearch(this.initialTextLength, (curLength) => {
        this.truncateText(curLength);
        return !this.isOverflowing;
      });

      this.truncateText(maxLength);
      this.addResizeListener();

      // If the container is shrinkable and the direction option is set, adjust container height by the underflow plus padding
      if (shrinkable && this._options.shrinkToFit) {
        const container = this.element.children[0] as HTMLElement;
        container.style.height = `${container.clientHeight - this.underflow + (this._options.shrinkPadding ?? 0)}px`;
      }
    }
  }

  // Adds a resize observer to the element to recalculate the ellipsis when the element is resized
  private addResizeListener(): void {
    if (this.element && isPlatformBrowser(this.platformId)) {
      const resizeObserver = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
          if (this.element) {
            if (
              this.previousDimensions.width !== this.element.clientWidth ||
              this.previousDimensions.height !== this.element.clientHeight ||
              this.previousDimensions.scrollWidth !== this.element.scrollWidth ||
              this.previousDimensions.scrollHeight !== this.element.scrollHeight
            ) {
              this.ngZone.run(() => {
                this.applyEllipsis();
              });

              this.previousDimensions.width = this.element.clientWidth;
              this.previousDimensions.height = this.element.clientHeight;
              this.previousDimensions.scrollWidth = this.element.scrollWidth;
              this.previousDimensions.scrollHeight = this.element.scrollHeight;
            }
          }
        });
      });

      resizeObserver.observe(this.element);

      // Unsubscribe from resize observer when the directive is destroyed
      this.removeResizeListeners$.pipe(take(1)).subscribe(() => {
        resizeObserver.disconnect();
      });
    }
  }

  // Truncates the text content of the element to the specified length, optionally adding an ellipsis indicator
  private truncateText(max: number): void {
    if (this.element) {
      this.resetView();

      const nodes = <(HTMLElement | CharacterData)[]>(
        this.flattenNodes(this.element).filter(
          (node) => node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE
        )
      );

      let foundIndex = -1;
      let foundNode: Node | null = null;
      let offset = this.initialTextLength;

      for (let i = nodes.length - 1; i >= 0; i--) {
        const node = nodes[i];

        if (node instanceof CharacterData) {
          offset -= node.data.length;
        } else {
          offset--;
        }

        if (offset <= max) {
          if (node instanceof CharacterData) {
            if (this._options.wordBreak) {
              node.data = node.data.substring(0, max - offset);
            } else {
              if (node.data.charAt(max - offset) !== '') {
                let adjustmet = max - offset;

                while (adjustmet > 0 && node.data.charAt(adjustmet) !== ' ' && node.data.charAt(adjustmet) !== '.') {
                  adjustmet--;
                }

                node.data = node.data.substring(0, adjustmet);
              }
            }
          }

          foundIndex = i;
          foundNode = node;

          break;
        }
      }

      for (let i = foundIndex + 1; i < nodes.length; i++) {
        const node = nodes[i];

        if (node.textContent !== '' && node.parentNode !== this.element && node.parentNode?.childNodes.length === 1) {
          node.parentNode.parentNode?.removeChild(node.parentNode);
        } else {
          node.parentNode?.removeChild(node);
        }
      }

      const truncatedNode = this.currentLength !== this.initialTextLength ? foundNode : null;

      if (truncatedNode) {
        if (truncatedNode instanceof CharacterData) {
          truncatedNode.data += this._options.indicator;
        } else {
          this.renderer.appendChild(this.element, this.renderer.createText(this._options.indicator ?? ''));
        }
      }
    }
  }

  // Generic binary search utility to find the maximum value that satisfies the callback
  private static numericBinarySearch(max: number, callback: (n: number) => boolean): number {
    let best = -1;
    let low = 0;
    let high = max;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const result = callback(mid);

      if (!result) {
        high = mid - 1;
      } else {
        best = mid;
        low = mid + 1;
      }
    }

    return best;
  }

  // Generic utility to recursively flatten all child text|element nodes into an array of nodes
  private flattenNodes(element: HTMLElement): (CharacterData | HTMLElement)[] {
    const nodes: (CharacterData | HTMLElement)[] = [];

    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes.item(i);

      // We only want to act on text and element nodes
      if (child instanceof HTMLElement || child instanceof CharacterData) {
        nodes.push(child);

        if (child instanceof HTMLElement) {
          nodes.push(...this.flattenNodes(child));
        }
      }
    }

    return nodes;
  }
}

@Component({
  selector: 'si-ellipsis-directive-content',
  standalone: true,
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: block;
        overflow: hidden;
        word-break: break-word;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class EllipsisDirectiveContentComponent {
  constructor(public elementRef: ElementRef) {}
}
