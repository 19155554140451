import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../feature-keys';
import { BrowseState } from './browse.state';

export const browseFeature = createFeatureSelector<BrowseState>(FeatureKeys.Browse);
export const masterPanelTitle = createSelector(browseFeature, (browse) => browse.masterPanelTitle);
export const masterPanelBrowseCategories = createSelector(
  browseFeature,
  (browse) => browse.masterPanelBrowseCategories
);
export const selectConfig = createSelector(browseFeature, (browse) => ({
  masterPanelTitle: browse.masterPanelTitle,
  masterPanelBrowseCategories: browse.masterPanelBrowseCategories,
  masterPanelPreviousCategory: browse.masterPanelPreviousCategory,
  detailTitle: browse.detailTitle,
  detailDescription: browse.detailDescription,
  rootType: browse.contentGroup,
  selectedType: browse.contentSubgroup,
  selectedTopic: browse.selectedTopic,
}));
export const selectDetailTitle = createSelector(browseFeature, (browse) => browse.detailTitle);
export const selectDetailDescription = createSelector(browseFeature, (browse) => browse.detailDescription);
export const selectContentGroup = createSelector(browseFeature, (browse) => browse.contentGroup);
export const selectContentSubgroup = createSelector(browseFeature, (browse) => browse.contentSubgroup);
export const selectSelectedTopic = createSelector(browseFeature, (browse) => browse.selectedTopic);
export const selectPageNumber = createSelector(browseFeature, (browse) => browse.pageNumber);
export const selectPageSize = createSelector(browseFeature, (browse) => browse.pageSize);
export const selectTotal = createSelector(browseFeature, (browse) => browse.total);
export const selectSortOptions = createSelector(browseFeature, (browse) => browse.sortOptions);
export const selectSortBy = createSelector(browseFeature, (browse) => browse.sortBy);
export const selectSortDir = createSelector(browseFeature, (browse) => browse.sortDir);
export const selectSearchTerm = createSelector(browseFeature, (browse) => browse.searchTerm);

export const selectResults = createSelector(browseFeature, (browse) => browse.results);
export const selectResultsLoading = createSelector(browseFeature, (browse) => browse.resultsLoading);
export const selectResultsError = createSelector(browseFeature, (browse) => browse.resultsError);
export const selectScrollTop = createSelector(browseFeature, (browse) => browse.scrollTop);

export const selectFacets = createSelector(browseFeature, (browse) => browse.facets);
export const selectFilters = createSelector(browseFeature, (browse) => browse.filters);
export const selectPreservedFacet = createSelector(browseFeature, (browse) => browse.preservedFacet);
export const selectTopicsLoading = createSelector(browseFeature, (browse) => browse.topicsLoading);
export const selectPanelInitialized = createSelector(browseFeature, (browse) => browse.panelInitialized);
export const selectPubDateTo = createSelector(browseFeature, (browse) => browse.pubDateTo);
export const selectPubDateFrom = createSelector(browseFeature, (browse) => browse.pubDateFrom);
export const selectIsPubDateApplied = createSelector(browseFeature, (browse) => browse.isPubDateApplied);
export const selectCustomFilters = createSelector(browseFeature, (browse) => browse.customFilters);

export const selectBrowseJournal = createSelector(browseFeature, (browse) => browse.journals);
export const selectJournalsLoading = createSelector(browseFeature, (browse) => browse.journalsDetailLoading);
export const selectJournalOptions = createSelector(browseFeature, (browse) => browse.journalOptions);
