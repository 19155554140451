import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Alert } from '../models/alerts.model';
import { SearchResultEntry } from '../models/search-result.model';
import { AlertsApiService } from './alerts-api.service';
import { SaeHttpResponse } from '@sae/models';
import { MobilusPubDocument } from '../models/api-models';

@Injectable({
  providedIn: 'root',
})
export class AlertsUiService {
  constructor(private api: AlertsApiService) {}

  public createAlertForPublication(pubDocument: MobilusPubDocument): Observable<Alert> {
    const alertRequest: Alert = {
      application: 'mobilus',
      anchor: {
        feature: 'PUBLICATION',
        id: pubDocument.publication_id?.raw,
        display: pubDocument?.product_code?.raw,
      },
      interval: null,
      events: ['events.publication.document.revised'],
      meta: {
        rootCode: pubDocument?.codes?.raw[pubDocument?.codes?.raw.length - 1],
        title: pubDocument?.title.raw,
      },
    };
    return this.api.post(alertRequest).pipe(map((resp) => resp.results[0]));
  }

  public createAlertForSearchResult(searchResult: SearchResultEntry): Observable<Alert> {
    const alertRequest: Alert = {
      application: 'mobilus',
      anchor: {
        feature: 'PUBLICATION',
        id: searchResult.meta.publicationId,
        display:
          searchResult?.meta?.useExtendedCode && searchResult?.meta?.extendedCode
            ? searchResult?.meta?.extendedCode
            : searchResult.meta.code,
      },
      interval: null,
      meta: {
        rootCode: searchResult?.meta?.rootCode,
        title: searchResult?.meta?.title,
      },
      events: ['events.publication.document.revised'],
    };
    return this.api.post(alertRequest).pipe(map((resp) => resp.results[0]));
  }

  public createAlertForCriteria(id: string, name: string, frequency: string): Observable<Alert> {
    const alertRequest: Alert = {
      application: 'mobilus',
      anchor: {
        feature: 'SEARCH',
        id,
        display: name,
      },
      interval:
        frequency === null
          ? undefined
          : {
              frequency,
              lastRunDate: new Date(),
              nextRunDate: null,
            },
    };
    return this.api.post(alertRequest).pipe(map((resp) => resp.results[0]));
  }

  public deleteAlert(alertId: string): Observable<SaeHttpResponse<Alert>> {
    return this.api.delete(alertId);
  }
}
