import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppSearchResponseFacet } from '../../models/api-models';
import { UISearchFilters } from '../../models/common-interfaces';

@Component({
  selector: 'mobi-facet-expanel',
  templateUrl: './facet-expanel.component.html',
})
export class FacetExpanelComponent implements OnInit {
  private _filters: string[];

  @Input() facet: AppSearchResponseFacet;
  @Input() set filters(filters: string[]) {
    this._filters = filters ?? [];
  }
  get filters(): string[] {
    return this._filters;
  }
  @Input() isPreservedFacet = false;
  @Output() applyFacetClicked = new EventEmitter();
  @Output() removeFacetClicked = new EventEmitter();
  @Output() clearPreservedFacetClicked = new EventEmitter();
  @Output() expandAggregationClicked = new EventEmitter();
  @Output() collapseAggregationClicked = new EventEmitter();
  @Output() showMoreValuesClicked = new EventEmitter();

  panelExpand = false;
  selectedCount = 0;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  toggleSelection(event: MatCheckboxChange, facetEntryName: string): void {
    const value = facetEntryName;

    if (event.checked) {
      this.applyFacetClicked.emit(value);
    } else {
      this.removeFacetClicked.emit(value);
    }
  }

  clearPreservedFacet(): void {
    this.clearPreservedFacetClicked.emit();
  }

  expandThisAggregation(alreadyExpanded: boolean): void {
    if (!alreadyExpanded) {
      this.expandAggregationClicked.emit();
    }
  }

  collapseThisAggregation(alreadyCollapsed: boolean): void {
    if (!alreadyCollapsed) {
      this.collapseAggregationClicked.emit();
    }
  }

  showMoreValues(): void {
    this.showMoreValuesClicked.emit();
  }
}
