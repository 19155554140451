import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelContent, PartLabelComponent } from '../../parts/part-label/part-label.component';
import { TextContent, PartTextComponent } from '../../parts/part-text/part-text.component';
import { RowTextContent, CompRowTextComponent } from '../comp-row-text/comp-row-text.component';
import { CompUtilHeadingComponent } from '../comp-util-heading/comp-util-heading.component';
import { overrideInputs } from '@sae/base';

export interface DefinedDataContent {
  label?: LabelContent;
  heading?: TextContent;
  headingClasses?: string;
  hTag?: number;
  bodyClasses?: string;
  bodyText?: TextContent;
  bodyRowText?: RowTextContent[];
  content?: TextContent;
  inline?: boolean;
  major?: boolean;
  presentation?: boolean;
  tight?: boolean;
  tightHorz?: boolean;
}

@Component({
  selector: 'fs-comp-defined-data',
  styleUrls: ['comp-defined-data.component.scss'],
  templateUrl: './comp-defined-data.component.html',
  standalone: true,
  imports: [CommonModule, PartLabelComponent, PartTextComponent, CompRowTextComponent, CompUtilHeadingComponent],
})
export class CompDefinedDataComponent implements AfterViewInit, OnChanges {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: DefinedDataContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string; // Optional, provides a CSS class for the output wrapper
  @Input() label: LabelContent; // Optional, adds a label
  @Input() heading: TextContent; // Optional, adds a title header containing the specified text
  @Input() headingClasses: string; // Optional, provides a CSS class for the heading
  @Input() hTag = 0; // Optional (for heading only), 0 uses label, 1-6 uses title
  // @Input() headingUrl: string; // Optional, used in conjection with title, makes the title a clickable link
  @Input() bodyClasses: string; // Optional, provides a CSS class for the body
  @Input() bodyText: TextContent; // Optional, content to inject into the output wrapper
  @Input() bodyRowText: RowTextContent[]; // Optional, content to inject into the output wrapper
  @Input() inline: boolean; // Optional, used to display as a single line instead of a block
  @Input() major: boolean; // Optional, used to add a major display style
  // @Input() menuItem: boolean; // If true, adds role="menuitem"
  @Input() presentation: boolean; // if true, adds role="presentation"
  @Input() tight: boolean; // Removes the padding around the labels
  @Input() tightHorz: boolean; // Removes horizontal padding on sides
  @Input() data: DefinedDataContent; // [DEPRECATED - Will be removed] Overrides all other inputs

  overridePresentation = false; // Used to override the presentation role for when the heading is not present

  @ViewChild('headingZone') headingZone: ElementRef;

  ngAfterViewInit(): void {
    this.detectHeadingZone();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
      // eslint-disable-next-line @nx/workspace-no-reassign-inputs
      this.bodyText = this.objConfig.content || this.bodyText;
    }
    if (changes['data']) {
      overrideInputs(this, this.data);
      // eslint-disable-next-line @nx/workspace-no-reassign-inputs
      this.bodyText = this.data.content || this.bodyText;
    }

    this.detectHeadingZone();
  }

  private detectHeadingZone(): void {
    // Detect if no heading is present and override the presentation role to ensure proper accessibility

    this.overridePresentation = true;
    if (this.headingZone?.nativeElement?.clientHeight > 0) {
      this.overridePresentation = false;
    }
    this.changeDetectorRef.detectChanges();
  }
}
