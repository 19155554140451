<div hidden>
  <mat-form-field appearance="outline">
    <mat-label></mat-label>
    <input matInput />
  </mat-form-field>
</div>
<div
  class="si-field si-wd si-wd-full mat-form-field mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label"
>
  <div class="mat-form-field-wrapper">
    <div class="mat-form-field-label si-field-label">{{ label }}</div>
    <div class="si-output">
      <ng-content></ng-content>
    </div>
  </div>
</div>
