<ng-container [ngSwitch]="hTag">
  <h1 *ngSwitchCase="1" class="si-htitle {{ classes }}" [class.si-impactv]="impact">
    <ng-container *ngTemplateOutlet="headingTemplate"></ng-container>
  </h1>
  <h2 *ngSwitchCase="2" class="si-htitle {{ classes }}" [class.si-impactv]="impact">
    <ng-container *ngTemplateOutlet="headingTemplate"></ng-container>
  </h2>
  <h3 *ngSwitchCase="3" class="si-htitle {{ classes }}" [class.si-impactv]="impact">
    <ng-container *ngTemplateOutlet="headingTemplate"></ng-container>
  </h3>
  <h4 *ngSwitchCase="4" class="si-htitle {{ classes }}" [class.si-impactv]="impact">
    <ng-container *ngTemplateOutlet="headingTemplate"></ng-container>
  </h4>
  <h5 *ngSwitchCase="5" class="si-htitle {{ classes }}" [class.si-impactv]="impact">
    <ng-container *ngTemplateOutlet="headingTemplate"></ng-container>
  </h5>
  <h6 *ngSwitchCase="6" class="si-htitle {{ classes }}" [class.si-impactv]="impact">
    <ng-container *ngTemplateOutlet="headingTemplate"></ng-container>
  </h6>
  <span *ngSwitchDefault class="si-htitle {{ classes }}" [class.si-impactv]="impact">
    <ng-container *ngTemplateOutlet="headingTemplate"></ng-container>
  </span>
</ng-container>

<ng-template #headingTemplate>
  <span *ngIf="heading" [attr.title]="tooltip ? tooltip : null">{{ heading }}</span>
  <ng-content></ng-content>
</ng-template>
