import { inject, Inject, Injectable, InjectionToken, ProviderToken } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActiveState, Order, SortBy } from '@datorama/akita';
import { HttpMethod, NgEntityServiceGlobalConfig, NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import {
  Anchor,
  createResource,
  FilePostBody,
  ResourceMetadata,
  ResourcePermissionTypes,
  Resource,
  ResourceManagerData,
  ResourceResponse,
  ResourceShare,
  ResourceShareApiResponse,
  ResourceSortKey,
  ResourcesResponse,
  ResourceType,
  Security,
} from '@sae/models';
import { APP_CONFIG_TOKEN, AppConfig } from '@sae/base';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRegistry, ApiToken, StoresRegistry, StoresToken } from '../api-registry';
import { BaseEntityQuery, BaseEntityService, BaseEntityStore, BaseState } from './base.service';

export interface IResourcesService {
  active$: Observable<Resource>;
  loading$: Observable<boolean>;
  selectResource$(id: string): Observable<Resource>;
  selectResourcesForAnchorId$(anchorId: string, sort?: Sort): Observable<Array<Resource>>;
  selectCombinedResourceData$(anchorId: string, sort?: Sort): Observable<ResourceManagerData>;
  queryGet(id: string): Resource;
  getResource(id: string): Observable<Resource>;
  getResourcesForAnchor(anchorId: string): Observable<Array<Resource>>;
  getCombinedResourceData(anchorId: string): Observable<ResourceManagerData>;
  create(resource: Resource): Observable<Resource>;
  edit(resource: Resource): Observable<Resource>;
  move(resource: Resource, newAnchorId: string): Observable<Resource>;
  remove(resourceId: string): Observable<ResourcesResponse>;
  fileUpload(file: File, body: FilePostBody, fileId: string): Observable<ResourceMetadata>;
  fileDownload(resource: Resource): Observable<boolean>;
  getFileId(body?: { filename: string }): Observable<string>;
  isValidUrl(url: string, allowHttp?: boolean): boolean;
  checkAndPrepareUrl(url: string): string;
  getShares(resourceId: string): Observable<unknown>;
  createShare(resourceId: string, share: ResourceShare): Observable<unknown>;
  deleteShare(resourceId: string, shareId: string): Observable<unknown>;
  updateSharesOnResource(resourceId: string, shares: ResourceShare[]): void;
  getHasPermission(resource: Resource, permission: ResourcePermissionTypes): boolean;
  getFileDownloadUrl(resource: Resource): Observable<string>;
  getFileCopyUrl(resource: Resource): Observable<string>;
}

// Akita Feature
export interface ResourcesCoreState extends BaseState<Resource>, ActiveState { }

@Injectable({ providedIn: 'root' })
export class ResourcesCoreStore extends BaseEntityStore<ResourcesCoreState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super({ name: storesRegistry.resources, idKey: 'id' });
  }
}

@Injectable({ providedIn: 'root' })
export class ResourcesCoreQuery extends BaseEntityQuery<ResourcesCoreState> {
  constructor(protected store: ResourcesCoreStore) {
    super(store);
  }
}

// the core service itself
export class ResourcesCoreService extends BaseEntityService<ResourcesCoreState> implements IResourcesService {
  active$ = this.query.selectActive();
  loading$ = this.query.selectLoading();

  constructor(
    public readonly store: ResourcesCoreStore,
    public readonly query: ResourcesCoreQuery,
    @Inject(NG_ENTITY_SERVICE_CONFIG)
    ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) private readonly apiRegistry: ApiRegistry,
    @Inject(APP_CONFIG_TOKEN) private readonly appConfig: AppConfig
  ) {
    super(store, query, { baseUrl: ngEntityServiceGlobalConfig.baseUrl, resourceName: apiRegistry.resources.url });
  }

  // STORE QUERIES
  selectResource$(id: string): Observable<Resource> {
    return this.query.selectEntity(id);
  }

  selectResourcesForAnchorId$(anchorId: string, sort?: Sort): Observable<Array<Resource>> {
    return this.query.selectAll({
      filterBy: (res) => res.anchorId === anchorId,
      sortBy: sort && sort.active ? (sort.active as SortBy<Resource>) : ResourceSortKey.modificationDate,
      sortByOrder: sort && sort.direction ? (sort.direction as Order) : Order.DESC,
    });
  }

  selectCombinedResourceData$(anchorId: string, sort?: Sort): Observable<ResourceManagerData> {
    const anchorResource$ = this.selectResource$(anchorId);
    const childResources$ = this.selectResourcesForAnchorId$(anchorId, sort);
    return combineLatest([anchorResource$, childResources$]).pipe(
      map(([anchor, children]): ResourceManagerData => {
        return this.makeResourceManagerData(anchor, children);
      })
    );
  }

  queryGet(id: string): Resource {
    return this.query.getEntity(id);
  }

  // API INTERACTIONS
  getResource(id: string): Observable<Resource> {
    return this.get<Resource>(id, {
      append: true,
      mapResponseFn: (res: ResourceResponse) => {
        const resource: Resource = res.results;
        resource.path = res.anchorsItems.slice().reverse();
        return resource;
      },
    });
  }

  getResourcesForAnchor(anchorId: string): Observable<Array<Resource>> {
    const sort: Sort = this.getSort();
    const resourceTypeSortBy = this.getResourceTypeSortBy(sort);
    const sortBy = (sort.active !== 'resourceType') ? `resourceType,` + sort.active : sort.active;
    return this.get<Array<Resource>>({
      append: true,
      params: { anchorId, sortBy, resourceTypeSortBy },
      mapResponseFn: (res: ResourcesResponse): Array<Resource> => {
        const resources: Resource[] = res.results.map((resource) => {
          return this.enhanceResourceData(resource);
        });
        return resources;
      },
    });
  }

  getCombinedResourceData(anchorId: string): Observable<ResourceManagerData> {
    const getAnchor = this.getResource(anchorId);
    const getChildren = this.getResourcesForAnchor(anchorId);
    return forkJoin([getAnchor, getChildren]).pipe(
      map(([anchor, children]) => {
        return this.makeResourceManagerData(anchor, children);
      })
    );
  }

  create(resource: Resource): Observable<Resource> {
    if (this.appConfig.applicationCode) {
      resource.applicationCodes = [this.appConfig.applicationCode];
    }
    return this.add<Resource>(resource, {
      mapResponseFn: (res: ResourceResponse): Resource => {
        const resourceInstance: Resource = res.results;
        const security: Security = {
          permissions: [
            // permissions are not available in the API response
            { code: 'FOLDER_CONTENT_MODIFY', uuid: '', name: '' },
            { code: 'FOLDER_CONTENT_DELETE', uuid: '', name: '' },
          ],
          roles: [],
        };
        resource.security = security;
        const withFileExt: Resource = this.enhanceResourceData(resourceInstance);
        return withFileExt;
      },
    });
  }

  move(resource: Resource, newAnchorId: string): Observable<Resource> {
    const updateObj: Partial<Resource> = { anchorId: newAnchorId };
    if (resource.resourceType === ResourceType.LINK) {
      updateObj.metadata = { resourceLink: resource.metadata.resourceLink };
    }
    return this.update<Resource>(resource.id, updateObj, {
      method: HttpMethod.PATCH,
      mapResponseFn: (res: ResourceResponse): Resource => {
        const enhanced: Resource = this.enhanceResourceData(res.results);
        return enhanced;
      },
    });
  }

  edit(resource: Resource): Observable<Resource> {
    const updateObj: Partial<Resource> = { name: resource.name };
    if (resource.resourceType === ResourceType.LINK) {
      updateObj.metadata = { resourceLink: resource.metadata.resourceLink };
    }
    return this.update<Resource>(resource.id, updateObj, {
      method: HttpMethod.PATCH,
      mapResponseFn: (res: ResourceResponse): Resource => {
        const enhanced: Resource = this.enhanceResourceData(res.results);
        return enhanced;
      },
    });
  }

  remove(resourceId: string): Observable<ResourcesResponse> {
    return this.delete(resourceId);
  }

  // MUTATIONS
  private enhanceResourceData(resource: Resource): Resource {
    const enhanced = { ...resource };
    // populate frontend-only fields so Akita can sort on them
    if (resource.metadata && resource.metadata.resourceExtension) {
      enhanced.fileType = resource.metadata.resourceExtension;
    } else {
      if (resource.resourceType === 'FOLDER') {
        enhanced.fileType = ' '; // sort folders to beginning
      } else if (resource.resourceType === 'LINK') {
        enhanced.fileType = 'link';
      } else {
        enhanced.fileType = '~'; // sort unknowns to end
      }
    }
    if (resource.modifiedByUser) {
      enhanced.modifiedBy = resource.modifiedByUser.lastName + ', ' + resource.modifiedByUser.firstName;
    }
    if (enhanced.fileType === 'link') {
      if (enhanced.metadata && enhanced.metadata.resourceLink && !this.isValidUrl(enhanced.metadata.resourceLink)) {
        enhanced.metadata.resourceLink = 'javascript:;';
      }
    }
    return enhanced;
  }

  private makeResourceManagerData(anchor: Resource, children: Resource[]): ResourceManagerData {
    // produces an object containing a resource along with its children and breadcrumbs (the three things needed for resource manager ui)
    const anchorResource: Resource = anchor;
    const childResources: Resource[] = children ? children : [];
    let breadcrumbs: Array<Anchor> = [];
    if (anchor) {
      breadcrumbs = anchor.path
        ? anchor.path.map((r) => {
          const name = r.name;
          return { resourceId: r.resourceId, name };
        })
        : [{ resourceId: anchor.id, name: anchor.description }];
    }
    return { currentAnchor: anchorResource, children: childResources, breadcrumbs };
  }

  // FILES (UPLOAD/DOWNLOAD)
  fileUpload(file: File, body: FilePostBody, fileId: string): Observable<ResourceMetadata> {
    const requestUrl = `${this.baseUrl}/${this.getFilesUrl()}/${fileId}`;
    const formData = new FormData();
    const json = JSON.stringify(body);
    const blob = new Blob([json], {
      type: 'application/json',
    });
    formData.append('metadata', blob);
    formData.append('file', file);
    return this.getHttp().patch<ResourceMetadata>(requestUrl, formData);
  }

  fileDownload(resource: Resource): Observable<boolean> {
    const getFileMeta: Observable<ResourceMetadata> = this.getFileMeta(resource);
    const getFile: Observable<Blob> = this.getFile(resource);
    return forkJoin([getFileMeta, getFile]).pipe(
      map(([meta, file]) => {
        const newResource: Resource = createResource({});
        newResource.file = file;
        newResource.metadata = meta;
        if (newResource.metadata && newResource.file) {
          const fileName = this.fileName(resource);
          const binaryData = [newResource.file];
          const file = new Blob(binaryData, { type: newResource.metadata.mimeType });
          window.btoa(JSON.stringify(file));
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(file);
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          return true;
        } else {
          return false;
        }
      })
    );
  }

  getFileDownloadUrl(resource: Resource): Observable<string> {
    return this.getFile(resource).pipe(
      map((blob: Blob) => {
        return window.URL.createObjectURL(blob);
      })
    );
  }

  getFileCopyUrl(resource: Resource): Observable<string> {
    const fileId = this.fileId(resource);
    if (!resource || !fileId) {
      return throwError({ error: { message: 'No file found to download!' } });
    }
    return of(`${this.baseUrl}/${this.getFilesUrl()}/${fileId}`);
  }

  getFileId(body?: { filename: string }): Observable<string> {
    return this.getHttp().post<string>(`${this.baseUrl}/${this.getFilesUrl()}`, body);
  }

  isValidUrl(url: string, allowHttp = false): boolean {
    let urlObj: URL;
    try {
      urlObj = new URL(url);
    } catch {
      return false;
    }
    return urlObj.protocol === 'https:' || (urlObj.protocol === 'http:' && allowHttp);
  }

  checkAndPrepareUrl(url: string): string {
    let newUrl: string = url;
    if (url.indexOf('http://') === 0) {
      newUrl = url.replace('http://', 'https://');
    } else if (url.indexOf('https://') !== 0) {
      newUrl = 'https://' + url;
    }
    if (!this.isValidUrl(newUrl)) {
      return null;
    }
    return newUrl;
  }

  getSort(): Sort {
    return {
      active: this.query.getValue().sortField,
      direction: this.query.getValue().sortDir
    }
  }

  getResourceTypeSortBy(sort: Sort): string {
    return (sort.direction === 'asc') ? "FOLDER,FILE,LINK,SURVEY" : "FOLDER,FILE,SURVEY,LINK"
  }

  private getFilesUrl(): string {
    return this.apiRegistry.file.url;
  }

  private getFile(resource: Resource): Observable<Blob> {
    const fileId = this.fileId(resource);
    if (!resource || !fileId) {
      return throwError({ error: { message: 'No file found to download!' } });
    }
    const requestUrl = `${this.baseUrl}/${this.getFilesUrl()}/${fileId}`;
    return this.getHttp().get(requestUrl, {
      responseType: 'blob',
    });
  }

  private getFileMeta(resource: Resource): Observable<ResourceMetadata> {
    const fileId = this.fileId(resource);
    if (!resource || !fileId) {
      return throwError({ error: { message: 'No file found to download!' } });
    }
    const requestUrl = `${this.baseUrl}/${this.getFilesUrl()}/${fileId}/meta`;
    return this.getHttp().get<ResourceMetadata>(requestUrl);
  }

  private fileName(resource: Resource): string {
    if (resource.metadata.resourceExtension && resource.metadata.resourceExtension !== '') {
      if (!resource.name.endsWith('.' + resource.metadata.resourceExtension)) {
        return `${resource.name}.${resource.metadata.resourceExtension}`;
      }
    }
    return resource.name;
  }

  private fileId(resource: Resource): string {
    return (resource.metadata && resource.metadata.fileId) || null;
  }

  // Resource Sharing
  public getShares(resourceId: string): Observable<unknown> {
    const endpoint = `${this.baseUrl}/${this.resourceName}/${resourceId}/shares`;
    return this.getHttp().get<ResourceShare>(endpoint);
  }

  public createShare(resourceId: string, share: ResourceShare): Observable<unknown> {
    const endpoint = `${this.baseUrl}/${this.resourceName}/${resourceId}/shares`;
    return this.getHttp().post<ResourceShareApiResponse>(endpoint, share);
  }

  public deleteShare(resourceId: string, shareId: string): Observable<unknown> {
    const endpoint = `${this.baseUrl}/${this.resourceName}/${resourceId}/shares/${shareId}`;
    return this.getHttp().delete(endpoint);
  }

  public updateSharesOnResource(resourceId: string, shares: ResourceShare[]): void {
    // updates shares on a resource in the store, for eventual consistency
    const resourceToUpdate: Resource = this.query.getEntity(resourceId);
    const updatedResource: Partial<Resource> = { ...resourceToUpdate, resourceShares: shares };
    this.store.update(resourceId, updatedResource);
  }

  // util
  public getHasPermission(resource: Resource, permissionCode: ResourcePermissionTypes): boolean {
    return resource.security &&
      resource.security.permissions &&
      resource.security.permissions.find((p) => p.code === permissionCode)
      ? true
      : false;
  }
}

export const RESOURCES_TOKEN = new InjectionToken<IResourcesService>('Core Resources Service', {
  providedIn: 'root',
  factory: (): ResourcesCoreService =>
    new ResourcesCoreService(
      inject(ResourcesCoreStore),
      inject(ResourcesCoreQuery),
      inject(NG_ENTITY_SERVICE_CONFIG),
      inject(ApiToken as ProviderToken<ApiRegistry>),
      inject(APP_CONFIG_TOKEN as ProviderToken<AppConfig>)
    ),
});
