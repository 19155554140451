/* eslint-disable @typescript-eslint/unbound-method */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Comment, DisplayFile, FeedbackConfig, FileToUpload } from '@sae/models';
import {
  CommentsCoreService,
  COMMENTS_TOKEN,
  FilesService,
  ISessionService,
  SESSION_TOKEN,
} from '@sae/services';
import { catchError, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'si-feedback-bottom-sheet',
  templateUrl: './feedback-bottom-sheet.component.html',
  styleUrls: ['./feedback-bottom-sheet.component.css'],
})
export class FeedbackBottomSheetComponent {
  public feedbackConfig!: FeedbackConfig | undefined;
  public feedbackFormGroup: FormGroup;

  private fileToUpload!: File | null;

  constructor(
    public readonly fileService: FilesService,
    @Inject(COMMENTS_TOKEN)
    private readonly commentsService: CommentsCoreService,
    @Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService,
    private readonly fb: FormBuilder,
    private readonly bsRef: MatBottomSheetRef<FeedbackBottomSheetComponent>,
    private readonly routerQuery: RouterQuery,
    private readonly snackBar: MatSnackBar
  ) {
    this.sessionService.feedbackConfig$
      .pipe(tap((fc) => (this.feedbackConfig = fc)))
      .subscribe();
    this.feedbackFormGroup = this.fb.group({
      feedbackText: [null, Validators.required],
      files: [],
    });
  }

  closeBottomSheet(): void {
    this.bsRef.dismiss();
  }

  cancelFeedback(): void {
    this.closeBottomSheet();
  }

  sendFeedback(): void {
    const submitFeedbackFn = (fileUpload?: DisplayFile) =>
      this.routerQuery
        .select((store) => store.state)
        .pipe(untilDestroyed(this))
        .subscribe((state) => {
          if (this.feedbackConfig) {
            const commentBody: Comment = {
              anchor: this.feedbackConfig.feedbackAnchorUUID,
              title: `User Feedback`,
              applicationCode: this.feedbackConfig.applicationCode,
              text: `Router State: [${JSON.stringify(state)}]
          Message From User: [${
            this.feedbackFormGroup.controls.feedbackText.value as string
          }]`,
              type: 'NEUTRALFEEDBACK',
            };

            if (fileUpload) {
              commentBody.attachments = [
                {
                  fileId: fileUpload.id,
                  fileName: fileUpload.name,
                },
              ];
            }

            this.commentsService
              .add(commentBody)
              .pipe(
                tap(() => this.closeBottomSheet()),
                catchError((err: HttpErrorResponse) => {
                  this.closeBottomSheet();
                  this.snackBar.open(`Error: ${err.message}`, 'DISMISS', {
                    panelClass: ['error'],
                    duration: 3000,
                  });
                  throw err;
                })
              )
              .subscribe(() =>
                this.snackBar.open(`Feedback received!`, 'Ok', {
                  panelClass: ['info'],
                  duration: 3000,
                })
              );
          }
        });

    if (this.fileToUpload) {
      const file: FileToUpload = {
        name: this.fileToUpload.name,
        file: this.fileToUpload
      }
      this.fileService
        .uploadFile(file)
        .pipe(
          catchError((err) => {
            this.snackBar.open(err, 'DISMISS', {duration: 3000});
            throw err;
          })
        )
        .subscribe((uploadedFile: DisplayFile) => {
          this.fileToUpload = null;
          submitFeedbackFn(uploadedFile);
        });
    } else {
      submitFeedbackFn();
    }
  }

  processAttachments(files: FileList): void {
    this.fileToUpload = files.length > 0 ? files[0] : null;
  }
}
