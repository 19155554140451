import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Comment } from '@sae/models';

export interface AnnotationDialogData {
  currentAnnotation: Comment;
  allAnnotations: Comment[];
  mode: 'add' | 'edit';
}

@Component({
  selector: 'si-annotation-dialog-edit',
  templateUrl: './annotation-dialog.component.html',
})
export class AnnotationDialogComponent implements OnInit {
  @Output() dialogSubmitted = new EventEmitter<Partial<Comment>>();
  @Output() createSubmitted = new EventEmitter<Partial<Comment>>();
  @Output() editSubmitted = new EventEmitter<Partial<Comment>>();
  @Output() deleteSubmitted = new EventEmitter<Partial<Comment>>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: AnnotationDialogData,
    public dialogRef: MatDialogRef<AnnotationDialogComponent>,
    private formBuilder: FormBuilder
  ) {
    this.editAnnotationForm = this.formBuilder.group({
      text: [dialogData.currentAnnotation.text, Validators.required],
    });
  }

  submitting = false;
  editAnnotationForm: FormGroup;
  allAnnotations: Comment[] = null;
  currentAnnotation: Comment = null;
  currentAnnotationIndex = 1;

  ngOnInit() {
    this.currentAnnotation = { ...this.dialogData.currentAnnotation };
    this.allAnnotations = this.dialogData.mode === 'edit' ? [...this.dialogData.allAnnotations] : [];

    for (let i = 0; i < this.allAnnotations.length; i++) {
      if (this.allAnnotations[i].id === this.currentAnnotation.id) {
        this.currentAnnotationIndex = i + 1;
        break;
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  deleteAnnotation() {
    this.deleteSubmitted.emit({
      ...this.currentAnnotation,
    });
  }

  createAnnotation() {
    this.createSubmitted.emit({
      ...this.dialogData.currentAnnotation,
      text: this.editAnnotationForm.value.text,
    });
  }

  editAnnotation() {
    this.editSubmitted.emit({
      ...this.currentAnnotation,
      text: this.editAnnotationForm.value.text,
    });
  }

  previousAnnotation() {
    if (this.currentAnnotationIndex > 1) {
      this.currentAnnotationIndex -= 1;
    } else {
      this.currentAnnotationIndex = this.allAnnotations.length;
    }

    this.setCurrentAnnotation();
  }

  nextAnnotation() {
    if (this.currentAnnotationIndex < this.allAnnotations.length) {
      this.currentAnnotationIndex += 1;
    } else {
      this.currentAnnotationIndex = 1;
    }

    this.setCurrentAnnotation();
  }

  setCurrentAnnotation() {
    this.currentAnnotation = this.allAnnotations[this.currentAnnotationIndex - 1];
    this.editAnnotationForm.reset();
    this.editAnnotationForm.setValue({ text: this.currentAnnotation.text });
  }
}
