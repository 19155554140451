<mat-card class="si-card si-card--stub mat-elevation-z3" [class.si-card--dataviewer]="stubCardDataViewer">
  <ng-container *ngIf="headerType === 'title-with-link'">
    <mat-card-header class="si-card__h si-stub-header no-print">
      <ng-container *ngIf="title">
        <mat-card-title class="si-title">
          <a *ngIf="titleUrl" [routerLink]="titleUrl" class="si-card__subtitle si-stub-link">{{ title }}</a>
          <span *ngIf="!titleUrl" (click)="titleOnClick()" class="si-card__subtitle si-stub-link">{{ title }}</span>
        </mat-card-title>
      </ng-container>
      <ng-container *ngIf="link">
        <span class="si-expanse"></span>
        <a *ngIf="linkUrl" [routerLink]="linkUrl" class="si-card__title si-toolbar__a">
          <strong>{{ link }}</strong>
        </a>
        <a *ngIf="!linkUrl" (click)="linkOnClick()" class="si-card__title si-toolbar__a">
          <strong>{{ link }}</strong>
        </a>
      </ng-container>
    </mat-card-header>
  </ng-container>

  <ng-container *ngIf="headerType === 'title-with-subtitle'">
    <mat-card-header class="si-card__h si-stub-header si-enhanced no-print">
      <mat-card-title class="si-card__title si-impact">
        <span>{{ title }}</span>
      </mat-card-title>
      <mat-card-subtitle class="si-card__subtitle">
        <span>{{ subtitle }}</span>
      </mat-card-subtitle>
    </mat-card-header>
  </ng-container>

  <ng-container *ngIf="headerType === 'breadcrumb'">
    <mat-card-header class="si-card__h si-stub-header no-print">
      <si-breadcrumb-enhanced
        [breadcrumbs]="breadcrumbs"
        (crumbClick)="onCrumbClicked($event)"
      ></si-breadcrumb-enhanced>
    </mat-card-header>
  </ng-container>

  <ng-content></ng-content>
</mat-card>
