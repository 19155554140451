import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@sae/material';
import { SharedModule as Shared } from '../../shared/shared.module';
import { SharedRoutingModule } from './shared-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, Shared, MaterialModule, SharedRoutingModule],
})
export class SharedModule {}
