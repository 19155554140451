import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Breadcrumbs } from '../../breadcrumb-enhanced/breadcrumb-enhanced.component';

export type StubCardHeaderType = 'title-with-link' | 'title-with-subtitle' | 'breadcrumb';

@Component({
  selector: 'si-stub-card',
  templateUrl: './stub-card.component.html',
})
export class StubCardComponent {
  @Input() headerType: StubCardHeaderType = 'title-with-link'; // Optional, defaults to 'title-with-link', determines the content type of the header ('title-with-link' | 'title-with-subtitle' | 'breadcrumb')
  @Input() title = ''; // Optional, used in conjunction with 'title-with-link' headerType, if supplied this text is a title on the left
  @Input() titleUrl = ''; // Optional, used with title property, if supplied the title becomes a clickable link and routes to this url
  @Input() subtitle = ''; // Optional, used in conjunction with 'title-with-subtitle' headerType, if supplied this text is a subtitle on the left under the title
  @Input() link = ''; // Optional, used in conjunction with 'title-with-link' headerType, if supplied this text is a link on the right
  @Input() linkUrl = ''; // Optional, used with link property and required if link is supplied, makes the link route to this url
  @Input() breadcrumbs: Breadcrumbs[] = []; // Optional, used in conjunction with 'breadcrumb' headerType, renders a breadcrumb list of the array in the header
  @Input() stubCardDataViewer = false; // Optional, whether to apply the 'si-card--dataviewer' class to the mat card

  @Output() titleClick = new EventEmitter<void>(); // Event triggered by clicking the title, if one is present based on the card configuration and titleUrl is not used for a route link
  @Output() linkClick = new EventEmitter<void>(); // Event triggered by clicking the link, if one is present based on the card configuration and linkUrl is not used for a route link
  @Output() crumbClick = new EventEmitter(); // Event triggered by clicking the breadCrumbs from the si-breadcrumb-enhanced component

  titleOnClick() {
    this.titleClick.emit();
  }

  linkOnClick() {
    this.linkClick.emit();
  }

  onCrumbClicked(event:Breadcrumbs){
    this.crumbClick.emit(event);
  }
}
