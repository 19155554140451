<ng-template #template>
  <ng-container
    *ngIf="{
      content: content$ | async
    } as _"
  >
    <mat-list class="si-list si-list--wrap si-list--master">
      <ng-container *ngIf="!!_.content?.standardAttributes?.revisions?.length > 0">
        <ng-container *ngFor="let revision of _.content?.standardAttributes?.revisions; let i = index">
          <mat-list-item class="si-list__item" *ngIf="i === 1">
            <div class="si-list__item__h">
              <h4 class="si-list__subtitle si-ellipsed">Historical</h4>
            </div>
          </mat-list-item>
          <mat-list-item *ngIf="!!revision.seoUri" class="si-list__item">
            <si-general-status-card
              class="si-component"
              [icon]="revision?.status === 'Current' ? 'verified' : 'history'"
              [iconAnalyticsId]="'detail-page-panel-redline-revision-btn'"
              [shadow]="true"
              [title]="revision.useExtendedCode ? revision.extendedCode : revision.code"
              [subtitle]="revision.pubDate | date: 'YYYY-MM-dd'"
              [selected]="revision.cid === _.content?.id"
              [appLink]="revision.seoUri"
            >
              <button
                *ngIf="revision.hasXml && revision.cid !== _.content.id"
                mat-icon-button
                matTooltip="Compare to"
                aria-label="Compare to"
                color="primary"
                (click)="redline($event, revision, _.content)"
                [disabled]="!_.content.hasAccess"
              >
                <mat-icon>compare_arrows</mat-icon>
              </button>
            </si-general-status-card>
          </mat-list-item>
        </ng-container>
      </ng-container>
    </mat-list>
  </ng-container>
</ng-template>
