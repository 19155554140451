import { Pipe, PipeTransform } from '@angular/core';
import { FileExtension } from '@sae/models';

@Pipe({
  name: 'fileExtensionDisplay',
})
export class FileExtensionDisplayPipe implements PipeTransform {
  transform(input: string | FileExtension): string {
    if (!input) {
      return '';
    }
    const extension = input.toLowerCase();
    let output = '';
    switch (extension) {
      case FileExtension.docx:
        output = FileExtension.doc;
        break;
      case FileExtension.dotx:
        output = FileExtension.dot;
        break;
      case FileExtension.pptx:
        output = FileExtension.ppt;
        break;
      case FileExtension.vsdx:
        output = FileExtension.vsd;
        break;
      case FileExtension.xlsx:
        output = FileExtension.xls;
        break;
      default:
        output = extension;
    }
    return output;
  }
}
