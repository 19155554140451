import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PartButtonComponent } from '../../parts/part-button/part-button.component';
import { overrideInputs, randomUUID } from '@sae/base';

interface UICarouselPage {
  pageNumber: number;
  offset: number;
  contentWidth: number;
}

export type UICarouselTabSizes = 1 | 2 | 3 | 4;

export interface UICarouselConfig {
  tabSize?: UICarouselTabSizes;
  dialNavigation?: boolean;
  pageWrap?: boolean;
  autoScale?: boolean;
  navPosition?: 'offset' | 'top';
}

@Component({
  selector: 'fs-carousel',
  templateUrl: './ui-carousel.component.html',
  styleUrls: ['./ui-carousel.component.scss'],
  imports: [CommonModule, PartButtonComponent],
  standalone: true,
})
export class UICarouselComponent implements OnChanges, AfterViewInit {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: UICarouselConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() tabSize: UICarouselTabSizes = 1; // Number of slides being shown per page
  @Input() dialNavigation = true; // If true, the carousel will display dial buttons for navigation to different pages of content (if there are any)
  @Input() pageWrap = true; // If true, the carousel will wrap around to the opposite side when the end is reached
  @Input() autoScale = false; // If true, the carousel will automatically scale the child container of the siv-carousel--slide host element to 100% height
  @Input() navPosition: 'offset' | 'top' = 'offset'; // The position of the navigation buttons (absolute top or offset from the top of the carousel)

  @ViewChild('carousel') carousel: ElementRef;
  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  id = `tab-carousel__${randomUUID()}`;
  _tabSize: UICarouselTabSizes = 1; // Internal tabSize the component uses which respects responsive breakpoints
  childContent: NodeList;
  pages: UICarouselPage[] = [];
  activeIndex = -1;
  endIndex = -1;
  swipeCoord = [0, 0];
  swipeTime = 0;
  left = 0;
  offsetWidth = 0;
  scrollWidth = 0;
  clippedOpacity = '0.5';
  pagePadding = 16; // Should match siv-carousel--wraper__container column-gap width

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }

  @HostListener('window:resize', ['$event']) onResize(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.refreshCarousel();
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.childContent = document.querySelectorAll(`#${this.id} .siv-carousel--slide`);

    for (let i = 0; i < this.childContent.length; i++) {
      const child = this.childContent[i] as HTMLElement;

      if (this.autoScale && child.children.length > 0) {
        const childContent = child.children[0] as HTMLElement;

        if (childContent) {
          childContent.style.height = '100%';
        }
      }

      child.setAttribute('tab-carousel--id', `${randomUUID()}`);
      child.addEventListener('focusin', () => {
        this.focusOnContentChild(child);
      });
      child.addEventListener('click', () => {
        this.focusOnContentChild(child);
      });
    }

    // Execute the initial calculation after the frame following the view init
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        this.refreshCarousel();
      });
    });
  }

  public onDrag(e: MouseEvent, when: string): void {
    this.triggerNavEvent([e.clientX, e.clientY], when);
  }

  public onSwipe(e: TouchEvent, when: string): void {
    this.triggerNavEvent([e.changedTouches[0].clientX, e.changedTouches[0].clientY], when);
  }

  public onNavBack(): void {
    this.onLoadIndex(this.activeIndex - 1);
  }

  public onNavNext(): void {
    this.onLoadIndex(this.activeIndex + 1);
  }

  public onLoadIndex(index: number): void {
    this.activeIndex = index;

    if (this.activeIndex < 0) {
      if (this.pageWrap) {
        this.activeIndex = this.endIndex;
      } else {
        this.activeIndex = 0;
      }
    } else if (this.activeIndex > this.endIndex) {
      if (this.pageWrap) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = this.endIndex;
      }
    }

    if (this.pages.length > 0) {
      this.animateCarouselMovement(
        +this.left,
        this.pages[this.activeIndex].offset * -1 - this.pagePadding * this._tabSize * this.activeIndex
      );
    }
  }

  public refreshCarousel(): void {
    this.activeIndex = 0;
    this.left = 0;
    this.offsetWidth = this.carousel.nativeElement.offsetWidth;
    this.scrollWidth = this.contentWrapper.nativeElement.scrollWidth;
    this.buildImageSlides();
  }

  private triggerNavEvent(coord: [number, number], when: string): void {
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 500 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        direction[0] < 0 ? this.onNavBack() : this.onNavBack();
      }
    }
  }

  private animateCarouselMovement(startLeft: number, endLeft: number): void {
    window.requestAnimationFrame((currentTime) => {
      this.animateCarouselMovementProcess(performance.now(), currentTime, startLeft, endLeft);
    });
  }

  private animateCarouselMovementProcess(
    startTime: number,
    currentTime: number,
    startLeft: number,
    endLeft: number
  ): void {
    const progress = currentTime - startTime;
    const duration = 100;

    if (progress < duration) {
      this.left = startLeft + ((endLeft - startLeft) * progress) / duration;
      this.setOpacity();

      window.requestAnimationFrame((currentTime) => {
        this.animateCarouselMovementProcess(startTime, currentTime, startLeft, endLeft);
      });
    } else {
      this.left = endLeft;
      this.setOpacity();
    }
  }

  private setClasses(): void {
    const fullClass = 'siv-carousel--slide__full';
    const halfClass = 'siv-carousel--slide__half';
    const thirdClass = 'siv-carousel--slide__third';
    const fourthClass = 'siv-carousel--slide__fourth';
    const tabSizeClasses = [fullClass, halfClass, thirdClass, fourthClass];

    this._tabSize = 1;

    // NOTE: This logic mimmicks the 'siv-carousel--slide' breakpoint css
    if (this.tabSize >= 2 && document.getElementsByClassName('si-bp-8').length > 0) {
      this._tabSize = 2;
    }
    if (this.tabSize >= 3 && document.getElementsByClassName('si-bp-12').length > 0) {
      this._tabSize = 3;
    }
    if (this.tabSize >= 4 && document.getElementsByClassName('si-bp-14').length > 0) {
      this._tabSize = 4;
    }

    // Iterates through all slides and sets the correct slide width class based on the tab size
    for (let i = 0; i < this.childContent.length; i++) {
      const child = this.childContent[i] as HTMLElement;

      for (let ii = 0; ii < tabSizeClasses.length; ii++) {
        const tabSizeClass = tabSizeClasses[ii];

        if (this._tabSize - 1 === ii && !child.classList.contains(tabSizeClass)) {
          child.classList.add(tabSizeClass);
        } else if (this._tabSize - 1 !== ii && child.classList.contains(tabSizeClass)) {
          child.classList.remove(tabSizeClass);
        }
      }
    }
  }

  private setOpacity(): void {
    let currentLeft = Math.floor(this.left + this.pagePadding * this._tabSize * this.activeIndex);

    // Iterate through all child elements and set lower opacity for anything clipped from view
    for (let i = 0; i < this.childContent.length; i++) {
      const child = this.childContent[i] as HTMLElement;
      const clipped = child.offsetWidth - currentLeft > this.offsetWidth || currentLeft < 0;
      child.style.opacity = clipped ? this.clippedOpacity : '1';
      currentLeft += Math.floor(child.offsetWidth);
    }
  }

  private focusOnContentChild(child: HTMLElement): void {
    if (child.style.opacity === this.clippedOpacity) {
      let left = 0;

      // Find the child who is being focused and determine if we need to invoke back or next navigation to bring it into view
      for (let i = 0; i < this.childContent.length; i++) {
        const sibling = this.childContent[i] as HTMLElement;

        if (sibling.getAttribute('tab-carousel--id') === child.getAttribute('tab-carousel--id')) {
          if (left > this.left * -1) {
            this.onNavNext();
          } else {
            this.onNavBack();
          }

          break;
        }

        left += sibling.offsetWidth;
      }
    }
  }

  private buildImageSlides(): void {
    if (this.childContent?.length > 0) {
      // Always start by setting the slide width classes
      this.setClasses();

      let currentPage = null;

      this.pages = [];

      for (let i = 0; i < this.childContent.length; i++) {
        // Create the first page if we don't have one yet
        if (currentPage === null) {
          this.pages.push({
            pageNumber: this.pages.length + 1,
            offset: 0,
            contentWidth: 0,
          });

          currentPage = this.pages[this.pages.length - 1];
        }

        const child = this.childContent[i] as HTMLElement;

        if (currentPage.contentWidth > 0 && currentPage.contentWidth + child.offsetWidth > this.offsetWidth) {
          // This child is cut off from the current page so it should be added to the next page
          this.pages.push({
            pageNumber: this.pages.length + 1,
            offset: this.pages[this.pages.length - 1].offset + this.pages[this.pages.length - 1].contentWidth,
            contentWidth: 0,
          });

          currentPage = this.pages[this.pages.length - 1];
        }

        currentPage.contentWidth += child.offsetWidth;
      }

      if (this.activeIndex === -1) {
        this.activeIndex = 0;
      }

      this.endIndex = this.pages.length - 1;

      // Finish by setting opacity for clipped elements
      this.setOpacity();
    }
  }
}
