import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiEntry, ApiRegistry, ApiToken } from './api-registry';

@Injectable({ providedIn: 'root' })
export class ApiVersionInterceptor implements HttpInterceptor {
  constructor(@Inject(ApiToken) private readonly apiRegistry: ApiRegistry) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.isApiRequest(request)) {
      const entry = this.findEntryByUrl(request.url);
      if (entry) {
        const majorVersion = this.getMajorVersion(entry);
        const minorVersion = entry.minor_version;
        const patchVersion = entry.patch_version;
        const modifiedReq = request.clone({
          headers: request.headers.append(
            'X-Version',
            `${majorVersion}.${minorVersion}.${patchVersion}`
          ),
        });
        return next.handle(modifiedReq).pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        );
      }
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  isApiRequest(request: HttpRequest<unknown>): boolean {
    return this.allUrls().some((url) => request.url.includes(url));
  }

  allUrls(): Array<string> {
    return Object.keys(this.apiRegistry).map((k) => this.apiRegistry[k].url);
  }

  findEntryByUrl(url: string): ApiEntry | undefined {
    return Object.values<ApiEntry>(this.apiRegistry).find(
      (entry) => entry.url && url.includes(entry.url)
    );
  }

  getMajorVersion(entry: ApiEntry): string {
    // given: vX/service/endpoint, split on /, take the first entry, cut off the v - JJV
    return entry.url.split(`/`)[0].substring(1);
  }
}
