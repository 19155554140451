import { EnterpriseSearchFilters, EnterpriseSearchFilterValue } from '@sae/models';

export const HashIds = {
  limit: 'size',
  filterAll: 'filter_all',
  filterAny: 'filter_any',
  filterNone: 'filter_none',
};
export const HashStructure = {
  assign: '=',
  paramDelimiter: '&',
  valueDelimiter: ',',
};
export const HashParamStructure = {
  assign: ':', // the divider between keys and values within a single hash parameter
  keyValuePairDelimiter: ';', // there can be multiple key-value pairs per param, so this is a sub-delimiter
  valueDelimiter: '+', // each key can have one or many values. sub-sub-delimiter.
  mockComma: '^', // Workaround for a Chrome issue (does not respect encoded commas in fragment)
  // We'll temporarily change commas in filter values to something else before encoding,
  // then change them back when we read from the fragment.
};

/**
 * Generates relative URL with updated hash parameters, preserving existing query and hash parameters.
 * To remove specific hash parameters, pass their hash ids into options.clearHash.
 */
export function getHashUrlNonDestructively(
  currentBaseUrl: string,
  currentQueryParams: string,
  currentFragment: string,
  hash: string,
  options?: { multiValues?: boolean; toggleValue?: boolean; clearHash?: string[] }
): string {
  let hashUrl = currentFragment;
  if (!hash && options?.clearHash?.length === 0) {
    if (options?.clearHash?.length > 0) {
      // Could perform a check if there are other operations to do besides clearing here and just skip the extra processing down below otherwise
    } else {
      return hashUrl;
    }
  }
  let manipulatedHash = false;
  // If the hashUrl contains any name/value pairs, remove any entries that are of the same type as the hash property we are working on (if it is a name/value variant)
  if ((hashUrl && hash?.includes(HashStructure.assign)) || options?.clearHash?.length > 0) {
    const nameToken = hash?.split(HashStructure.assign)[0];
    const valueToken = hash?.split(HashStructure.assign)[1];
    const newHashUrl = [];
    // Iterate through every hash parameter and only push ones that don't include the hash name family we are operating on
    for (const parameter of hashUrl.split(HashStructure.paramDelimiter)) {
      if (options?.clearHash?.includes(parameter.split(HashStructure.assign)[0])) {
        manipulatedHash = true;
      } else if (!parameter.includes(`${nameToken}=`)) {
        newHashUrl.push(parameter);
      } else if (options?.multiValues === true) {
        const parameterName = parameter.split(HashStructure.assign)[0];
        const parameterValues = parameter.split(HashStructure.assign)[1].split(HashStructure.valueDelimiter);
        // If we don't find the value token in the parameter values, add it, otherwise if present and toggleValue is true, remove it
        if (parameterValues.findIndex((record) => record === valueToken) === -1) {
          parameterValues.push(valueToken);
        } else if (options?.toggleValue === true) {
          parameterValues.splice(
            parameterValues.findIndex((record) => record === valueToken),
            1
          );
        }
        // Reconstruct the group values and sort the alphabetically ascending to produce the fewest possible link permutations for SEO
        if (parameterValues.length > 0) {
          parameterValues.sort((a, b) => (a > b ? 1 : -1));
          newHashUrl.push(`${parameterName}=${parameterValues.join(HashStructure.valueDelimiter)}`);
        }
        manipulatedHash = true;
      } else if (options?.toggleValue === true) {
        if (hash !== parameter) {
          newHashUrl.push(hash);
        }
        manipulatedHash = true;
      }
    }
    hashUrl = newHashUrl.join(HashStructure.paramDelimiter);
  }
  const baseUrlWithQueryParams = currentBaseUrl + (currentQueryParams ? '?' + currentQueryParams : '');
  // Construct the new url off base, if no manipulations were done prepend the hash to any existing hashUrl, otherwise use the manipulated full hashUrl
  if (!manipulatedHash) {
    hashUrl = `${baseUrlWithQueryParams}#${hash}${hashUrl ? HashStructure.paramDelimiter + hashUrl : ''}`;
  } else {
    hashUrl = `${baseUrlWithQueryParams}#${hashUrl}`;
  }
  // If we actually have a hash url, sort all hash properties alphabetically ascending to produce the fewest possible link permutations for SEO
  const sortedHash = hashUrl.split('#')[1];
  if (sortedHash) {
    const sortedPairs = sortedHash.split(HashStructure.paramDelimiter).sort((a, b) => (a > b ? 1 : -1));
    hashUrl = `${baseUrlWithQueryParams}#${sortedPairs.join(HashStructure.paramDelimiter)}`;
  }
  // Remove trailing hash if hash string is empty
  if (hashUrl.charAt(hashUrl.length - 1) === '#') {
    hashUrl = hashUrl.slice(0, -1);
  }
  return hashUrl;
}

export function getHashParamStringVal(fragment: string, hashParameterId: string): string | null {
  const hashParameter = fragment
    ?.split(HashStructure.paramDelimiter)
    .find((h) => h.split(HashStructure.assign)[0] === hashParameterId);
  return hashParameter && hashParameter.includes(HashStructure.assign)
    ? hashParameter.split(HashStructure.assign)[1]
    : null;
}

export function getHashParamNumVal(fragment: string, hashParameterId: string): number | null {
  const val = getHashParamStringVal(fragment, hashParameterId);
  return val ? +val : null;
}

/**
 * A regular expression to help with SEO pagination UI,
 * which requires us to guess at which part of a URL represents a page number.
 * Will match on all substrings where:
 * 1. The sequence starts with a slash followed by one or more numeric characters.
 * 2. The next character is either "?" or "#" or the end of the sequence.
 * Note we do not match if the numbers are followed by anything else.
 * Therefore, "/search/2/5" would match only on "/5".
 * "/foo/2bar" would match nothing.
 */
export const seoPaginatorUrlSegmentFinder = new RegExp('\\/\\d+(?=#|\\?|$)');

export function validatePaginationState(offset: number, limit: number, total: number): boolean {
  if (typeof offset === 'number' && typeof limit === 'number' && typeof total === 'number') {
    // based on the above info, is there enough results to put at least 1 on the current page?
    const minTotal = (offset + 1) * limit - limit + 1;
    return total >= minTotal;
  } else {
    // not enough info
    return null;
  }
}

/**
 * Inspects a full or partial URL to find the SEO pagination page number segment.
 * Example: /my/page/route/with/search/3?q=searchterm#size=20 would return 3.
 * Will return null if zero or more than one page number segment is found in the input.
 */
export function findPageNumInSeoPaginationUrl(url: string): number {
  const matches = url?.match(seoPaginatorUrlSegmentFinder);
  return matches?.length === 1 ? parseInt(matches[0].substring(1), 10) : null;
}

/**
 * Inspects a full or partial URL to find the SEO pagination page number segment
 * and converts it to a pagination offset (base 0)
 * Example: /my/page/route/with/search/3?q=searchterm#size=20 would return 2.
 * Will return null if zero or more than one page number segment is found in the input.
 */
export function findOffsetInSeoPaginationUrl(url: string): number {
  const matches = url?.match(seoPaginatorUrlSegmentFinder);
  return matches?.length === 1 ? parseInt(matches[0].substring(1), 10) - 1 : null;
}

/**
 * Converts a string containing one or more filter hash parameters into a single EnterpriseSearchFilters object
 */
export function convertEnterpriseSearchFilters(input: string): EnterpriseSearchFilters;
/**
 * Converts an entire EnterpriseSearchFilters object into a filter hash params string
 */
export function convertEnterpriseSearchFilters(input: EnterpriseSearchFilters): string;
export function convertEnterpriseSearchFilters(
  input: string | EnterpriseSearchFilters
): EnterpriseSearchFilters | string {
  if (typeof input === 'string') {
    const filterAll = getHashParamStringVal(input, HashIds.filterAll)?.split(HashParamStructure.keyValuePairDelimiter),
      output: EnterpriseSearchFilters = {};
    if (filterAll) {
      output.all = filterAll.map((value) => convertEnterpriseSearchFilterValue(value));
    }
    return output;
  } else {
    if (!input) return {};
    const filterAll = input.all?.map((fv) => convertEnterpriseSearchFilterValue(fv)),
      filterAllValueString = filterAll ? filterAll.join(HashParamStructure.keyValuePairDelimiter) : '';
    let filterHashParams = '';
    if (filterAllValueString) {
      filterHashParams += `${HashIds.filterAll + HashStructure.assign + filterAllValueString}`;
    }
    return filterHashParams;
  }
}

/**
 * Converts a hash param string representing a single EnterpriseSearchFilterValue to a EnterpriseSearchFilterValue object.
 */
export function convertEnterpriseSearchFilterValue(input: string): EnterpriseSearchFilterValue;
/**
 * Converts a single EnterpriseSearchFilterValue object to a hash param string.
 */
export function convertEnterpriseSearchFilterValue(input: EnterpriseSearchFilterValue): string;
export function convertEnterpriseSearchFilterValue(
  input: string | EnterpriseSearchFilterValue
): EnterpriseSearchFilterValue | string {
  if (typeof input === 'string') {
    const output: EnterpriseSearchFilterValue = {},
      keyAndValues = input.split(HashParamStructure.assign),
      key = keyAndValues[0],
      values = keyAndValues[1].split(HashParamStructure.valueDelimiter),
      decodedAndRestoredValue = values.map((value) => {
        const decoded = decodeURIComponent(value);
        return decoded.replace(HashParamStructure.mockComma, ',');
      });
    output[key] = decodedAndRestoredValue;
    return output;
  } else {
    const facet = Object.entries(input)[0];
    const key = facet[0];
    let value = '';
    if (typeof facet[1] === 'string' || typeof facet[1] === 'number') {
      // it would seem that this never happens - it is always an array even when there is only one value
      value = `${facet[1]}`;
    } else {
      facet[1].forEach((el, i) => {
        // We need to do crazy things because Chrome doesn't respect encoded commas.
        // When we read this back from the fragment, we will convert mockComma back to ','.
        const commasReplaced = el.toString().replace(',', HashParamStructure.mockComma),
          encoded = commasReplaced ? encodeURIComponent(commasReplaced) : '';
        value += i ? HashParamStructure.valueDelimiter + encoded : encoded;
      });
    }
    return key + HashParamStructure.assign + value;
  }
}
