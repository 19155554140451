import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { overrideInputs } from '@sae/base';

export type ImageType = 'decorative' | 'logo' | 'picture';

export interface PartImageItem {
  type?: ImageType;
  alt?: string;
  classes?: string;
  lazyLoad?: boolean;
  src?: string;
  tooltip?: string;
  useNgOptimizedImage?: boolean;
  priority?: boolean;
  ngSrcset?: string;
}

@Component({
  selector: 'fs-part-image',
  styleUrls: ['part-image.component.scss'],
  templateUrl: './part-image.component.html',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
})
export class PartImageComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartImageItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() type: ImageType = 'decorative';
  @Input() alt: string;
  @Input() classes: string;
  @Input() lazyLoad = true;
  @Input() src: string;
  @Input() tooltip: string;
  @Input() useNgOptimizedImage = false; // When true the image will be optimized by the image service
  @Input() priority = false; // Used in conjuction with useNgOptimizedImage, when true applies the priority attribute on the image, should always be used for LCP images
  @Input() ngSrcset: string; // Used in conjuction with useNgOptimizedImage, by default the image is assumed to support the fill list of breakpoints configured in the image service, if this is not the case a custom srcset can be provided

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }
}
