<ng-container
  *ngIf="{
    recommendations: results$ | async,
    pageSize: pageSize$ | async,
    pageNum: pageNum$ | async,
    total: total$ | async,
    loading: loading$ | async,
    content: content$ | async
  } as _"
>
  <si-basic-data-viewer
    class="si-component"
    dataTitle="Related"
    [search]="false"
    [moreOptions]="[
      { icon: 'visibility', name: 'View Short' },
      { icon: 'visibility', name: 'View Summary' }
    ]"
    [pageSize]="_.pageSize"
    [pageNumber]="_.pageNum - 1"
    [total]="_.total"
    [loading]="_.loading"
    [noneText]="'No related documents'"
    [noResults]="_.recommendations?.length === 0"
    [sortOptions]="sortOptions"
    [sortBy]="sortBy"
    [sortDir]="sortDir"
    [singleSortMenu]="false"
    (selectedOption)="selectedOption($event)"
    (pageChanged)="pageChanged($event)"
    (sortChanged)="sortChanged($event)"
  >
    <mobi-publication-document-card
      *ngFor="let related of _.recommendations"
      [pubDocument]="related"
      [returnUrlTitle]="_.content?.title"
      [showSummary]="showSummary"
      [detailCid]="_.content?.id"
    ></mobi-publication-document-card>
  </si-basic-data-viewer>
</ng-container>
