import { Inject, Injectable } from '@angular/core';
import { EntityStore, Query } from '@datorama/akita';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { DisplayFile, FileToUpload, FileUpload } from '@sae/models';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import {
  ApiRegistry,
  ApiToken,
  StoresRegistry,
  StoresToken,
} from '../api-registry';

export interface FilesState {
  uploading: boolean;
  processing: boolean;
  displayFiles: DisplayFile[];
}

export function createInitialState(): FilesState {
  return {
    uploading: false,
    processing: false,
    displayFiles: []
  };
}

@Injectable({ providedIn: 'root' })
export class FilesStore extends EntityStore<FilesState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super(createInitialState(), { name: storesRegistry.file });
  }
}

@Injectable({ providedIn: 'root' })
export class FilesQuery extends Query<FilesState> {
  constructor(protected store: FilesStore) {
    super(store);
  }
}

@Injectable({
  providedIn: 'root',
})
export class FilesService extends NgEntityService<FilesState> {
  private fileUrl: string;

  uploading$ = this.query.select((s) => s.uploading);

  constructor(
    @Inject(ApiToken) private readonly apiRegistry: ApiRegistry,
    protected readonly store: FilesStore,
    protected readonly query: FilesQuery,
  ) {
    super(store);
    this.fileUrl = `${this.baseUrl}/${this.apiRegistry?.file?.url}`;
  }

  public uploadFile(fileInfo: FileToUpload, maxSize = 25000000, mib = false, toFixed = 0): Observable<DisplayFile> {
    if (fileInfo.file.size > maxSize) {
      const maxInMB = this.getBytesToMegs(maxSize, mib, toFixed);
      // Windows users expect MiB, incorrectly labelled as MB, to match Explorer
      return throwError(() => new Error(`File size must be below ${maxInMB} MB`));
    } else if (this.query.getValue().uploading) {
      return throwError(() => new Error('Only 1 file can be uploading at a time.'));
    } else {
      return this.getFileId(fileInfo.file).pipe(
        mergeMap((fileId: string) => this.sendFileBytesViaPatch(fileInfo.file, fileId)),
        map((f: FileUpload) => {
          const displayFile: DisplayFile = {
            id: f.fileId, // the only useful part of the API response
            name: fileInfo.name ?? fileInfo.file.name,
            fileSize: fileInfo.file.size,
            fileExt: fileInfo.fileExt
          }
          return displayFile;
        }),
        catchError(() => {
          return throwError(() => new Error('An error occurred when uploading the file.'));
        }),
      );
    }
  }

  public downloadFile(fileId: string, fileName?: string): Observable<boolean> {
    // fileName is provided as an optional param in case the 'actual' filename
    // is stored somewhere other than Files API (which only supports ogFileName)
    const getFileMeta: Observable<FileUpload> = this.getFileMeta(fileId);
    const getFile: Observable<Blob> = this.getFile(fileId);
    return forkJoin([getFileMeta, getFile]).pipe(
      map(([meta, file]) => {
        if (meta && file) {
          const name = fileName ?? meta.originalFileName;
          const binaryData = [file];
          const constructedFile = new Blob(binaryData, { type: meta.mimeType });
          window.btoa(JSON.stringify(constructedFile));
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(constructedFile);
          a.download = name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          return true;
        } else {
          return false;
        }
      })
    );
  }

  public deleteFile(fileId: string): Observable<void> {
    const requestUrl = `${this.fileUrl}/${fileId}`;
    return this.getHttp().delete<void>(requestUrl);
  }

  public getFile(fileId: string): Observable<Blob> {
    const requestUrl = `${this.fileUrl}/${fileId}`;
    return this.getHttp().get(requestUrl, {
      responseType: 'blob',
    });
  }

  public getFileMeta(fileId: string): Observable<FileUpload> {
    const url = `${this.fileUrl}/${fileId}/meta`;
    return this.getHttp().get<FileUpload>(url);
  }

  public getFileDownloadUrl(fileId: string) {
    return `${this.fileUrl}/${fileId}`;
  }

  public getFileId(file: File): Observable<string> {
    return this.getHttp().post<string>(this.fileUrl, { filename: file.name });
  }

  private sendFileBytesViaPatch(
    file: File,
    fileId: string
  ): Observable<FileUpload> {
    const uploadUrl = `${this.fileUrl}/${fileId}`;
    const formData = new FormData();
    const json = JSON.stringify({
      filename: file.name,
      fileid: fileId,
    });
    const blob = new Blob([json], {
      type: 'application/json',
    });
    formData.append('metadata', blob);
    formData.append('file', file);
    this.store.update({ uploading: true });
    return this.getHttp()
      .patch<FileUpload>(uploadUrl, formData)
      .pipe(
        catchError((err) => {
          this.store.update({ uploading: false });
          throw err;
        }),
        tap(() => {
          this.store.update({ uploading: false });
        })
      );
  }

  public getBytesToMegs(bytes: number, mib = false, toFixed = 0): string {
    if (mib) {
      // 1 MiB = 2^20 bytes
      return (bytes / 1048576).toFixed(toFixed);
    } else {
      // 1 MB = 10^6 bytes
      return (bytes / 1000000).toFixed(toFixed);
    }
  }

}
