<ng-container *ngFor="let c of content">
  <fs-part-button
    *ngIf="c.button"
    class="si-mixedcontent"
    [buttonItem]="c.button"
    (buttonClick)="onButtonClick(c.button)"
  ></fs-part-button>
  <fs-part-divider
    *ngIf="c.divider"
    class="si-mixedcontent"
    [classes]="c.divider.classes"
    [spacious]="c.divider.spacious"
    [simple]="c.divider.simple"
  ></fs-part-divider>
  <fs-part-text *ngIf="c.textContent" class="si-mixedcontent" [text]="c.textContent"></fs-part-text>
</ng-container>
