export interface FacetExpanelItem {
  name: string;
  icon?: string;
  count: number | string;
  active: boolean;
  url?: string;
}

// this is a group of facets
// we iterate through an array of these groups
// to render multiple facet expanel components
export interface FacetItemGroup {
  active?: boolean;
  items: FacetExpanelItem[];
  key: string;
  label: string;
}

const stringLitArray = <L extends string>(arr: L[]): L[] => arr;
export const STRING_FACET_TYPE = 'string';
export const DATE_FACET_TYPE = 'date';
export const HIERARCHICAL_FACET_TYPE = 'hierarchical';
export const NUMERIC_FACET_TYPE = 'numeric';
export const KEYWORD_FACET_TYPE = 'keyword';
const facetType = stringLitArray([
  STRING_FACET_TYPE,
  DATE_FACET_TYPE,
  HIERARCHICAL_FACET_TYPE,
  NUMERIC_FACET_TYPE,
  KEYWORD_FACET_TYPE,
]);

export interface HierarchyDialogData {
  title: string;
  optionsTree: Array<HierarchicalFacetOption>;
}

export type FacetType = typeof facetType[number];

export type Facet = TextFacet | KeywordFacet | DateFacet | HierarchicalFacet | NumericFacet;

export interface FacetOption {
  value: string;
  label: string;
  key?: string;
  name?: string;
}

export interface HierarchicalFacetOption extends FacetOption {
  children: Array<HierarchicalFacetOption | FacetOption>;
  id?: string;
  title?: string;
  fullName?: string;
}

interface BaseFacet {
  name: string;
  label: string;
  type: FacetType;
  key?: string;
}

export interface TextFacet extends BaseFacet {
  type: 'string';
}
export interface DateFacet extends BaseFacet {
  type: 'date';
}

export interface NumericFacet extends BaseFacet {
  type: 'numeric';
}
export interface KeywordFacet extends BaseFacet {
  type: 'keyword';
  options: Array<FacetOption>;
  values?: Array<FacetOption>;
}

export interface HierarchicalFacet extends BaseFacet {
  type: 'hierarchical';
  options: Array<HierarchicalFacetOption>;
  values?: Array<FacetOption>;
}
