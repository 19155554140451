import { Component, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, combineLatest, from, map, switchMap } from 'rxjs';
import { SearchResultCardActions, SearchResultEntry } from '../../../../../shared/models/search-result.model';
import { BuildDetailsLinkPipe } from '../../../../../shared/pipes/build-details-link.pipe';
import { AuthService } from '../../../../../shared/state/auth.service';
import { ContentService } from '../../../../../shared/state/content.service';

@UntilDestroy()
@Component({
  selector: 'mobi-detail-related',
  templateUrl: 'detail-related.component.html',
})
export class DetailRelatedComponent implements OnInit {
  private refresh$ = new Subject();
  public results$ = this.contentService.relatedResults$;
  public pageNum$ = this.contentService.relatedPageNum$;
  public pageSize$ = this.contentService.relatedPageSize$;
  public total$ = this.contentService.relatedTotal$;
  public loading$ = this.contentService.relatedLoading$;
  public content$ = this.contentService.content$;
  public sortOptions = ['Relevance', 'Most Recent', 'Title'];
  public sortBy = 'Relevance';
  public sortDir = 'desc';
  showSummary = true;

  constructor(
    private contentService: ContentService,
    private router: Router,
    private buildDetailsLink: BuildDetailsLinkPipe,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    combineLatest([this.refresh$, this.content$])
      .pipe(
        untilDestroyed(this),
        switchMap(([, content]) => this.contentService.getRecommendations(content.id))
      )
      .subscribe();
  }

  selectedOption(e: 'View Short' | 'View Summary'): void {
    this.showSummary = e === 'View Summary' ? true : false;
  }

  pageChanged(event: PageEvent): void {
    this.contentService.setRelatedPageSize(event.pageSize);
    this.contentService.setRelatedPageNum(event.pageIndex);
    this.refresh$.next(null);
  }
  sortChanged(event: Sort): void {
    this.sortBy = event.active;
    this.sortDir = event.direction;
  }
  onRelatedClicked(title: string, relatedContent: SearchResultEntry): void {
    const relatedUrl = this.buildDetailsLink.transform(relatedContent);
    if (relatedUrl) {
      this.contentService.setReturnUrl(this.router.url, title);
    }
  }
}
