import { Pipe, PipeTransform } from '@angular/core';
import { Resource, ResourcePermissionTypes } from '@sae/models';

@Pipe({
  name: 'resourcePermissions',
})
export class ResourcePermissionsPipe implements PipeTransform {
  transform(resource: Resource, permissionType: 'add' | 'edit' | 'delete' | 'download'): boolean | void {
    const code: string =
      permissionType === 'add'
        ? ResourcePermissionTypes.ADD
        : permissionType === 'edit'
        ? ResourcePermissionTypes.EDIT
        : permissionType === 'delete'
        ? ResourcePermissionTypes.DELETE
        : permissionType === 'download'
        ? ResourcePermissionTypes.DOWNLOAD
        : '';
    if (!code || !resource || !resource.security || !resource.security.permissions) {
      return null;
    }
    const permitted = !!resource.security.permissions.find((x) => x.code === code);
    return permitted;
  }
}
