import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { PersonCardInfo, PersonTagInfo } from '@sae/models';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  // This can be improved by making it more configurable
  scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  noWhitespaceValidator(this: void, control: FormControl): { whitespace: boolean } | null {
    const isWhitespace = ((control.value as string) || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getFileExtension(filename: string): string {
    const a = filename.split('.');
    if (a.length === 1 || (a[0] === '' && a.length === 2)) {
      return '';
    }
    return a.pop() ?? '';
  }

  validUrl(prependHttps = false): (control: FormControl) => ValidationErrors | null {
    return (control: FormControl): ValidationErrors | null => {
      const urlOK = this.isValidUrl((prependHttps ? 'https://' : '') + (control.value as string));
      return urlOK ? null : { badUrl: { value: control.value as string } };
    };
  }

  isValidUrl(url: string, allowHttp = false): boolean {
    let urlObj: URL;
    try {
      urlObj = new URL(url);
    } catch {
      return false;
    }
    return urlObj.protocol === 'https:' || (urlObj.protocol === 'http:' && allowHttp);
  }

  delimit(arr: string[], delimiter: string): string {
    let output = '';
    if (arr && arr.length) {
      output = arr.join(delimiter);
    }
    return output;
  }

  stripProtocolPrefix(url: string): string {
    if (/^https?:\/\//.test(url)) {
      return url.substr(url.indexOf('://') + 3).trim();
    }
    return url;
  }

  hasExtension(file: File, exts: string[]): boolean {
    const fileName = file.name;
    return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(fileName);
  }

}

export function convertKeycloakProfileToPersonTagInfo(profile: KeycloakProfile): PersonTagInfo {
  return {
    displayName: `${profile?.firstName} ${profile?.lastName}`,
    emailAddress: profile?.email ?? '',
    userId: profile?.id ?? '',
  };
}

export function convertKeycloakProfileToPersonInfo(profile: KeycloakProfile): PersonCardInfo {
  return {
    user: {
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      email: profile?.email ?? '',
      id: profile?.id ?? '',
      address: '',
      company: '',
      contact: '',
      title: '',
    },
    security: [],
  };
}
