import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Sort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Anchor,
  AutocompleteOption,
  DisplayModified,
  ItemGroup,
  MenuData,
  MenuItem,
  Resource,
  ResourceManagerData,
  ResourceMenuItem,
  ResourceMenuItemEvent,
  ResourcePermissionTypes,
  ResourceShareDialogResult,
  ResourceSortKey,
  ResourceType,
} from '@sae/models';
import { IResourcesService, RESOURCES_TOKEN } from '@sae/services';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResourcesUXService } from '../resources-ux.service';
@UntilDestroy()
@Component({
  selector: 'si-resource-manager',
  templateUrl: './resource-manager.component.html',
})
export class ResourceManagerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() rootResourceId: string; // Similar to a root directory. This is the only required input!

  // optional inputs/outputs
  @Input() morePagination = false;
  @Input() expanded = true;
  @Input() readonly = false;
  @Input() disableFolders = false;
  @Input() disableDownload = false;
  @Input() disableAddLink = false;
  @Input() emptyMessage = 'No results found.';
  @Input() enableCopyLink = false;
  @Input() enableCopyName = false;
  @Input() enableSort = true;
  @Input() maxUploads: number; // disables adding files when childResources contains n files or greater
  @Output() resourceClicked = new EventEmitter<Resource>();

  // Menus
  @Input() topMenuItems: MenuItem[]; // will be prepended to the main menu items
  @Input() bottomMenuItems: MenuItem[]; // will be appended to the main menu items
  @Input() topAddMenuItems: MenuItem[]; // will be prepended to the 'add' menu items
  @Input() bottomAddMenuItems: MenuItem[]; // will be appended to the 'add' menu items
  @Output() menuItemClicked = new EventEmitter<ResourceMenuItemEvent>();

  // Core sub-feature: user display settings (opt-in)
  @Input() enableDisplaySettings = false;
  @Input() displaySettings: DisplayModified;
  @Output() displaySettingsChanged = new EventEmitter<DisplayModified>();

  // Core sub-feature: sharing (opt-in)
  @Input() enableSharing = false;
  @Input() shareOptions: AutocompleteOption[];
  @Input() shareType: string; // the type of entity the resource is being shared with, e.g. 'committee'
  @Output() sharesChanged = new EventEmitter<ResourceShareDialogResult>();

  // for when RM must behave as a FormControl
  @Input() error = false;
  @Input() required = false;
  @Input() errorMessage: string;
  @Output() resourcesUpdated = new EventEmitter<Resource[]>();

  // internal
  rootResource: Resource;
  currentAnchor: Resource;
  childResources: Resource[];
  breadcrumbs: Anchor[];
  resourcesLoading: boolean;
  anchorLoading: boolean;
  sortByDisplay: string;
  sortBy = ResourceSortKey.modificationDate;
  sortDirection: SortDirection = 'desc';
  maxUploadsReached: boolean;
  mainMenu: MenuData;
  addMenu: MenuData;
  appliedDisplaySettings: DisplayModified = { time: true, byUser: true };
  shareCount: number;
  elemTouched: boolean;
  elemFocused: boolean;
  suppressErrors: boolean;
  private menuIsOpen: boolean;
  private dialogIsActive: boolean;
  private focusListener: EventListener;
  private blurListener: EventListener;
  private activeResourceId: string;
  private dataSub$: Subscription;
  @ViewChild('templateRef') templateRef: ElementRef<HTMLElement>;

  constructor(@Inject(RESOURCES_TOKEN) private resourcesService: IResourcesService, private uxService: ResourcesUXService, private snackbar: MatSnackBar) { }

  ngAfterViewInit(): void {
    if (this.required) {
      this.setRequired();
    }
    this.uxService.dialogIsOpen$.pipe(untilDestroyed(this)).subscribe((is) => {
      this.dialogIsActive = is;
      this.checkSuppressErrors();
    });
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.rootResourceId && this.rootResourceId) {
      this.selectRootResource();
      this.navigate(this.rootResourceId);
    }
    if (c.displaySettings) {
      if (this.displaySettings) {
        this.appliedDisplaySettings = this.displaySettings;
        this.constructMenus();
      }
    }
    if (
      c.shareOptions ||
      c.topMenuItems ||
      c.bottomMenuItems ||
      c.topAddMenuItems ||
      c.bottomAddMenuItems
    ) {
      // reconstruct menu if any of this comes in or changes after init
      this.constructMenus();
    }
  }

  ngOnDestroy(): void {
    if (this.dataSub$) {
      this.dataSub$.unsubscribe();
    }
    if (this.templateRef) {
      const el = this.templateRef.nativeElement;
      if (this.focusListener) {
        el.removeEventListener('focusin', this.focusListener, true);
      }
      if (this.blurListener) {
        el.removeEventListener('focusout', this.blurListener, true);
      }
    }
  }

  private selectRootResource(): void {
    this.resourcesService
      .selectResource$(this.rootResourceId)
      .pipe(untilDestroyed(this))
      .subscribe((r) => {
        this.rootResource = r;
        if (this.displaySettings) {
          this.appliedDisplaySettings = this.displaySettings;
        }
      });
    this.displaySettingsChanged.pipe(untilDestroyed(this)).subscribe((s) => {
      if (!this.displaySettings) {
        // fallback for implementations that do not provide displaySettings via input
        this.appliedDisplaySettings = s;
        this.constructMenus();
      }
    });
  }

  private selectResourceManagerData(): void {
    // selects combined data from store based on the current activeResourceId
    if (this.dataSub$) {
      this.dataSub$.unsubscribe();
    }
    this.dataSub$ = null;
    const activeSort: Sort = {
      active: (this.sortBy !== 'resourceType') ? `resourceType,` + this.sortBy : this.sortBy,
      direction: this.sortDirection,
    };
    this.dataSub$ = this.resourcesService.selectCombinedResourceData$(this.activeResourceId, activeSort).subscribe((rd) => this.onDataUpdate(rd));
  }

  private fetchResources(): void {
    this.resourcesService
      .getCombinedResourceData(this.activeResourceId)
      .pipe(
        catchError((e) => {
          this.snackbar.open(`An error occurred when fetching resources.`, 'DISMISS', { duration: 2500 });
          throw e;
        })
      )
      .subscribe(() => {
        this.selectResourceManagerData();
      });
  }

  private fetchChildResourcesOnly(): void {
    this.resourcesService
      .getResourcesForAnchor(this.activeResourceId)
      .pipe(
        catchError((e) => {
          this.snackbar.open(`An error occurred when fetching resources.`, 'DISMISS', { duration: 2500 });
          throw e;
        })
      )
      .subscribe(() => {
        this.selectResourceManagerData();
      });
  }

  private onDataUpdate(rmdata: ResourceManagerData): void {
    this.breadcrumbs = rmdata.breadcrumbs;
    this.currentAnchor = rmdata.currentAnchor;
    this.childResources = rmdata.children;
    if (this.currentAnchor) {
      this.anchorLoading = false;
    }
    if (this.childResources) {
      this.resourcesLoading = false;
    }
    if (this.currentAnchor && this.childResources) {
      this.checkShareCount();
      this.checkMaxUploads();
      this.constructMenus();
    }
  }

  private clearAndSetLoading(): void {
    this.breadcrumbs = undefined;
    this.currentAnchor = undefined;
    this.childResources = undefined;
    this.anchorLoading = true;
    this.resourcesLoading = true;
  }

  private toggleDisplay(opt: ResourceMenuItem.modifiedByUser | ResourceMenuItem.modifiedTime): void {
    switch (opt) {
      case ResourceMenuItem.modifiedByUser:
        this.displaySettingsChanged.emit({
          byUser: !this.appliedDisplaySettings.byUser,
          time: this.appliedDisplaySettings.time,
        });
        break;
      case ResourceMenuItem.modifiedTime:
        this.displaySettingsChanged.emit({
          byUser: this.appliedDisplaySettings.byUser,
          time: !this.appliedDisplaySettings.time,
        });
    }
  }

  refresh(): void {
    this.childResources = undefined;
    this.resourcesLoading = true;
    this.fetchChildResourcesOnly();
  }

  navigate(anchorId: string): void {
    this.clearAndSetLoading();
    this.activeResourceId = anchorId;
    this.fetchResources();
  }

  rename(editedResource: Resource): void {
    this.uxService.saveEditedResource(editedResource);
  }

  delete(resource: Resource): void {
    this.uxService.launchDeleteDialog(resource).subscribe(() => {
      this.resourcesUpdated.emit(this.childResources);
    });
  }

  resourceClick(r: Resource): void {
    this.resourceClicked.emit(r);
    switch (r.resourceType) {
      case ResourceType.FOLDER:
        this.navigate(r.id);
        break;
      case ResourceType.FILE:
        this.download(r);
        break;
      case ResourceType.LINK:
        this.uxService.followLink(r);
    }
  }

  menuItemClick(item: MenuItem): void {
    this.menuItemClicked.emit({
      menuItem: item,
      currentAnchor: this.currentAnchor,
      childResources: this.childResources,
    });
    switch (item.name) {
      case ResourceMenuItem.addFolder:
        this.addFolder();
        break;
      case ResourceMenuItem.addFile:
        this.addFile();
        break;
      case ResourceMenuItem.addLink:
        this.addLink();
        break;
      case ResourceMenuItem.share:
        this.launchShareDialog();
        break;
      case ResourceMenuItem.modifiedByUser:
        this.toggleDisplay(ResourceMenuItem.modifiedByUser);
        break;
      case ResourceMenuItem.modifiedTime:
        this.toggleDisplay(ResourceMenuItem.modifiedTime);
    }
  }

  sortChange(sort: Sort): void {
    this.sortBy = sort.active as ResourceSortKey;
    this.sortDirection = sort.direction;
    this.selectResourceManagerData();
  }

  addFolder(): void {
    this.uxService.launchAddFolderDialog(this.currentAnchor.id).subscribe(() => {
      this.resourcesUpdated.emit(this.childResources);
    });
  }

  addFile(): void {
    this.uxService.launchUploadDialog(this.currentAnchor.id).subscribe(() => {
      this.resourcesUpdated.emit(this.childResources);
    });
  }

  download(r: Resource): void {
    this.uxService.download(r);
  }

  copyLink(r: Resource): void {
    if (r.resourceType === ResourceType.LINK) {
      this.uxService.copyLinkToClipboard(r.metadata.resourceLink);
    } else if (r.resourceType === ResourceType.FILE) {
      this.resourcesService.getFileCopyUrl(r).subscribe((downloadUrl: string) => this.uxService.copyLinkToClipboard(downloadUrl));
    }
  }

  copyName(r: Resource): void {
    this.uxService.copyNameToClipboard(r);
  }

  addLink(): void {
    this.uxService.launchAddLinkDialog(this.currentAnchor.id).subscribe(() => {
      this.resourcesUpdated.emit(this.childResources);
    });
  }

  editLink(link: Resource): void {
    this.uxService.launchEditLinkDialog(link).subscribe(() => {
      this.resourcesUpdated.emit(this.childResources);
    });
  }

  launchShareDialog(): void {
    this.uxService.launchShareDialog(this.rootResource.id, this.shareOptions, this.shareType).subscribe((res: ResourceShareDialogResult) => {
      this.sharesChanged.emit(res);
      if (res && res.newShares) {
        this.shareCount = res.newShares.length;
      }
    });
  }

  private checkShareCount(): void {
    if (this.enableSharing && this.rootResource?.resourceShares) {
      this.shareCount = this.rootResource.resourceShares.length;
    } else {
      this.shareCount = null;
    }
  }

  private checkMaxUploads(): void {
    if (this.maxUploads && this.childResources) {
      const len = this.childResources.filter((r) => r.resourceType === ResourceType.FILE).length;
      this.maxUploadsReached = len >= this.maxUploads;
    } else {
      this.maxUploadsReached = false;
    }
  }

  // dynamic menus
  private constructMenus(): void {
    if (!this.currentAnchor) {
      this.addMenu = null;
      this.mainMenu = null;
      return;
    }
    const addResourceOpts = this.makeAddResourceControls();
    this.setupAddMenu(addResourceOpts);
    this.setupMainMenu(addResourceOpts);
  }

  private makeAddResourceControls(): MenuItem[] {
    const addPermission = this.resourcesService.getHasPermission(this.currentAnchor, ResourcePermissionTypes.ADD);
    const items: MenuItem[] = [];
    if (addPermission) {
      if (!this.disableFolders) {
        items.push({
          name: ResourceMenuItem.addFolder,
          label: 'Add Folder',
          icon: 'create_new_folder',
        });
      }
      if (!this.maxUploadsReached) {
        items.push({
          name: ResourceMenuItem.addFile,
          label: 'Add File',
          icon: 'file_upload',
        });
      }
      if (!this.disableAddLink) {
        items.push({
          name: ResourceMenuItem.addLink,
          label: 'Add Link',
          icon: 'launch',
        });
      }
    }
    return items;
  }

  private setupAddMenu(addResourceOpts: MenuItem[]): void {
    let allItems = [...addResourceOpts];
    if (this.topAddMenuItems) {
      allItems = this.topAddMenuItems.concat(allItems);
    }
    if (this.bottomAddMenuItems) {
      allItems = allItems.concat(this.bottomAddMenuItems);
    }
    this.addMenu = allItems.length
      ? {
        tooltip: 'Add Item',
        icon: 'add_circle',
        content: allItems,
      }
      : null;
  }

  private setupMainMenu(addResourceOpts: MenuItem[]): void {
    // main menu contains same items as the add menu
    const mainMenuGroup: ItemGroup = { items: addResourceOpts };
    if (this.enableSharing && this.shareOptions?.length) {
      const sharePermission = this.rootResource ? this.resourcesService.getHasPermission(this.rootResource, ResourcePermissionTypes.SHARE) : false;
      if (sharePermission || (!sharePermission && this.shareCount)) {
        const share: MenuItem = {
          name: ResourceMenuItem.share,
          label: 'Group Share',
          icon: 'launch',
        };
        mainMenuGroup.items.unshift(share);
      }
    }
    if (this.topMenuItems) {
      mainMenuGroup.items = this.topMenuItems.concat(mainMenuGroup.items);
    }
    if (this.bottomMenuItems) {
      mainMenuGroup.items = mainMenuGroup.items.concat(this.bottomMenuItems);
    }
    const mainMenu: MenuData = {
      tooltip: 'Options',
      icon: 'more_vert',
      content: [mainMenuGroup],
    };
    // display settings is a separate ItemGroup, always at the bottom
    if (this.enableDisplaySettings) {
      mainMenuGroup.divider = true;
      const displayOpts: ItemGroup = {
        label: 'Display Modified',
        items: [
          {
            name: ResourceMenuItem.modifiedByUser,
            label: 'By User',
            selected: this.appliedDisplaySettings.byUser ? true : false,
          },
          {
            name: ResourceMenuItem.modifiedTime,
            label: 'Time',
            selected: this.appliedDisplaySettings.time ? true : false,
          },
        ],
      };
      mainMenu.content.push(displayOpts);
    }
    this.mainMenu = mainMenu;
  }

  // form control behavior
  private setRequired(): void {
    const el = this.templateRef.nativeElement;
    if (!el) {
      return;
    }
    this.focusListener = () => {
      this.elemTouched = true;
      this.elemFocused = true;
    };
    this.blurListener = () => {
      const childFocus = el.contains(document.activeElement);
      if (childFocus) {
        this.elemFocused = true;
      } else {
        this.elemFocused = false;
      }
    };
    el.addEventListener('focusin', this.focusListener, true);
    el.addEventListener('focusout', this.blurListener, true);
  }

  onMenu(open: boolean): void {
    this.menuIsOpen = open;
    this.checkSuppressErrors();
  }

  private checkSuppressErrors(): void {
    this.suppressErrors = this.dialogIsActive || this.menuIsOpen;
  }
}
