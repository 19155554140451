import { Component } from '@angular/core';
import { ContentService } from '../../../../../shared/state/content.service';
@Component({
  selector: 'mobi-detail-references',
  templateUrl: 'detail-references.component.html',
})
export class DetailReferencesComponent {
  public content$ = this.contentService.content$;
  constructor(private contentService: ContentService) {}
}
