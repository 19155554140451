import { ApiBaseUrls } from '../models/app-config.model';

// These URLs will be used as default/fallback if not defined in AppConfig or EnvironmentConfig
export const DefaultApiBaseUrls: ApiBaseUrls = {
  dev: 'https://dsa-dev.cld.fullsight.org/api',
  test: 'https://dsa-test.cld.fullsight.org/api',
  prod: 'https://api2.sae.org/api',
};

// This URL is needed on the frontend so apps can get their environment configuration from the Proteus service on startup.
export const ProteusApiUrl = `https://dsa.fullsight.org/api/v1/proteus`;
