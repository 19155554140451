import { Injectable } from '@angular/core';
import { Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouterHistoryService {
  public contentProjected$ = new BehaviorSubject(false);
  public currentUrl$ = new BehaviorSubject('');
  public previousUrl$ = new BehaviorSubject('');

  private _scrollPositions = new Map<string, { scrollTop: number; classNames: string }>();
  private _returnUrl: string;
  private _currentUrl: string;
  private _previousUrl: string;

  public set returnUrl(val: string) {
    this._returnUrl = val;
  }

  public get returnUrl(): string {
    return this._returnUrl;
  }

  private set currentUrl(val: string) {
    this._currentUrl = val;
    this.currentUrl$.next(val);
  }

  public getCurrentUrl(): string {
    return this._currentUrl;
  }

  private set previousUrl(val: string) {
    this._previousUrl = val;
    this.previousUrl$.next(val);
  }

  public getPreviousUrl(): string {
    return this._previousUrl;
  }

  constructor(private readonly router: Router) {
    // Note: this could be modified to be a stack of the history of urls instead of just current and previous... -- JJV
    this.router.events
      .pipe(
        // NG16 MIGRATION: filter((evt: RouterEvent) => evt instanceof RoutesRecognized),
        filter((evt: any) => evt instanceof RoutesRecognized),
        tap((r: RoutesRecognized) => {
          this.previousUrl = this._currentUrl;
          this.currentUrl = r.urlAfterRedirects;
        })
      )
      .subscribe();
  }

  /**
   * for the current url / activated route
   */
  public hasScrollPosition(): boolean {
    return this._scrollPositions.has(this._currentUrl);
  }

  /**
   * Expects the css classname(s) of the element who's scroll position will be restored.
   */
  public registerScrollPositionRestoration(scrollElementClassNames: string): void {
    const el = document.getElementsByClassName(scrollElementClassNames);
    if (el && el.length > 0) {
      el[0].addEventListener('scroll', () => {
        this._scrollPositions.set(this._currentUrl, {
          scrollTop: el[0].scrollTop,
          classNames: scrollElementClassNames,
        });
      });
    }
  }

  /**
   * for the current url / activated route
   */
  public restoreScrollPosition(): void {
    if (this._scrollPositions.has(this._currentUrl)) {
      const entry = this._scrollPositions.get(this._currentUrl);
      const el = document.getElementsByClassName(entry.classNames);
      if (el && el.length > 0) {
        el[0].scrollTop = entry.scrollTop;
      }
    }
  }

  /**
   * for the current url / activated route
   */
  public unregisterScrollPositionRestoration(): void {
    if (this.hasScrollPosition()) {
      this._scrollPositions.delete(this._currentUrl);
    }
  }

  /**
   * nuke everything and start over...
   */
  public reset(): void {
    this._scrollPositions.clear();
  }
}
