import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { LoginLocation } from '../../shared/models/common-interfaces';

export const logInToSubscription = createAction(
  '[Auth] Log In to Subscription',
  props<{ encodedLogin: string; rememberMe?: boolean; loginFrom?: LoginLocation }>()
);
export const logInToSubscriptionSucceeded = createAction(
  '[Auth] Log In to Subscription Succeeded',
  props<{ loginFrom: LoginLocation }>()
);
export const logInToSubscriptionFailed = createAction(
  '[Auth] Log In to Subscription Failed',
  props<{ error: HttpErrorResponse }>()
);

export const setLogInToSubErrorMessage = createAction(
  '[Auth] Set Log In to Subscription Error Message',
  props<{ errorMessage: string }>()
);

export const logOut = createAction('[Auth] Personal and Subscription Logout', props<{ redirectUri: string }>());
export const KCLogOut = createAction('[Auth] Personal and Subscription Logout');
export const logOutSucceeded = createAction('[Auth] Personal and Subscription Logout Succeeded');
export const logOutFailed = createAction(
  '[Auth] Personal and Subscription Logout Failed',
  props<{ error: HttpErrorResponse }>()
);
