<mat-form-field class="si-field si-wd si-wd-full" [appearance]="readOnly ? noUnderline : 'legacy'">
  <mat-label *ngIf="inputLabel" class="si-label">
    {{ inputLabel }}
    <ng-container *ngIf="selectionCountVisible">{{ ' (' + selections.length + ')' }}</ng-container>
  </mat-label>
  <mat-chip-list
    #chipList
    class="si-chiplist"
    [formControl]="chipListControl"
    [attr.aria-label]="inputLabel"
    [required]="isRequired"
  >
    <ng-container *ngFor="let s of selections">
      <ng-container *ngIf="!multidimensionalChip">
        <mat-chip
          *ngIf="!showSubtitle"
          [selectable]="!readOnly"
          [removable]="!readOnly"
          [value]="s.id"
          class="si-chip si-chip--minor"
          [class.si-chip--long]="!sectorChip"
          [class.si-chip--sector]="sectorChip"
          [class.si-chip--complex]="sectorChip"
          [class.si-chip--content]="sectorChip"
          (removed)="onRemove(s)"
          title="Remove"
        >
          <ng-container *ngIf="!personCard">{{ s.title }}</ng-container>
          <ng-container *ngIf="personCard">
            <si-person-card-tag
              class="si-tag si-tag--person"
              personType="Author"
              [personTagInfo]="s.data"
            ></si-person-card-tag>
          </ng-container>
          <mat-icon matChipRemove *ngIf="!readOnly">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="showSubtitle" [value]="s.id" class="si-chip--card">
          <button class="si-button--wrap" mat-button>
            <mat-card class="si-card si-card--basic mat-elevation-z2">
              <mat-card-header class="si-card__h">
                <mat-card-title class="si-card__title">{{ s.title }}</mat-card-title>
                <mat-icon aria-hidden="true" class="si-card--close" matTooltip="Remove" (click)="onRemove(s)">
                  close
                </mat-icon>
                <mat-card-subtitle class="si-card__subtitle">{{ s.subtitle }}</mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </button>
        </mat-chip>
      </ng-container>
      <ng-container *ngIf="multidimensionalChip && s.data?.start">
        <ng-container *ngIf="s.data?.start && s.data?.end; else onlyStartTemplate">
          <div class="si-bound">
            <mat-chip class="si-chip si-chip--medium si-chip--start si-chip--committee" title="Committee">
              {{ s.data.start }}
            </mat-chip>
            <mat-chip
              class="si-chip si-chip--medium si-chip--end"
              title="Sub-Committee"
              [selectable]="!readOnly"
              [removable]="!readOnly"
              [value]="s.id"
              (removed)="onRemove(s)"
            >
              {{ s.data.end }}
              <mat-icon *ngIf="!readOnly" matChipRemove title="Remove">cancel</mat-icon>
            </mat-chip>
          </div>
        </ng-container>
        <ng-template #onlyStartTemplate>
          <mat-chip
            class="si-chip si-chip--medium si-chip--committee"
            title="Committee"
            [selectable]="!readOnly"
            [removable]="!readOnly"
            [value]="s.id"
            (removed)="onRemove(s)"
          >
            {{ s.data.start }}
            <mat-icon *ngIf="!readOnly" matChipRemove title="Remove">cancel</mat-icon>
          </mat-chip>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="readOnly; else editable">
      <input #textInput [matChipInputFor]="chipList" disabled />
    </ng-container>
    <ng-template #editable>
      <mat-icon
        matPrefix
        class="si-icon si-icon--add"
        *ngIf="selections && selections.length && (!maxChips || (maxChips && selections.length < maxChips))"
        (click)="textInput.focus()"
      >
        add_circle
      </mat-icon>
      <input
        #textInput
        [formControl]="textInputControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="onAdd($event)"
        (blur)="markAsTouched()"
        [disabled]="maxChips && selections.length >= maxChips"
      />
      <button
        *ngIf="showSelectListButton && !readOnly && (!maxChips || (maxChips && selections.length < maxChips))"
        matSuffix
        class="si-button si-button--icon si-suffix--icon"
        matTooltip="Select List"
        aria-label="Select List"
        (click)="onSelectListClicked($event)"
      >
        <mat-icon aria-hidden="true">call_made</mat-icon>
      </button>
    </ng-template>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" class="si-autocomplete" (optionSelected)="onSelect($event)">
    <ng-container *ngIf="!readOnly && filteredOptions$ | async as opts">
      <ng-container *ngFor="let o of opts">
        <mat-option *ngIf="!showSubtitle" [value]="o">
          <ng-container *ngIf="multidimensionalChip && o.data?.start; else plainTitleTemplate">
            {{ o.data.start + (o.data.end ? ' > ' + o.data.end : '') }}
          </ng-container>
          <ng-template #plainTitleTemplate>
            {{ o.title }}
          </ng-template>
        </mat-option>
        <mat-option *ngIf="showSubtitle" [value]="o" class="si-option si-option--complex">
          <si-basic-card class="si-component" [title]="o.title" [subTitle]="o.subtitle"></si-basic-card>
        </mat-option>
      </ng-container>
      <ng-container *ngIf="showAddOption">
        <mat-divider class="si-divider"></mat-divider>
        <mat-option (click)="onAddOptionClick($event)" class="si-option si-option--complex si-option--add">
          <si-micro-card class="si-component" [title]="addOptionLabel" wrapElement="noClick"></si-micro-card>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
  <mat-hint *ngIf="matHint" class="si-field__hint si-state--unusual" align="end">{{ matHint }}</mat-hint>
  <mat-error *ngIf="chipListControl.errors">
    <ng-container *ngIf="chipListControl.errors.required">{{ inputLabel }} is required</ng-container>
    <ng-container *ngIf="chipListControl.errors.minlength">
      Minimum length is {{ chipListControl.errors.minlength.requiredLength }}
    </ng-container>
  </mat-error>
</mat-form-field>
