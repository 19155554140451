<div class="si-dialog__h">
  <mat-icon class="si-icon">group</mat-icon>
  <h2 class="si-dialog__title si-impact">{{ shareLabel }}</h2>
  <span class="si-expanse"></span>
  <button
    mat-icon-button
    matTooltip="Close"
    aria-label="Close"
    class="si-icon si-button--close"
    (click)="dismiss()"
    tabIndex="-1"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="si-dialog__b">
  <div mat-dialog-content class="si-dialog__c si-major">
    <div class="si-spinner__container" *ngIf="loading">
      <mat-spinner class="si-spinner"></mat-spinner>
    </div>
    <form class="si-form" [formGroup]="shareForm" *ngIf="!loading">
      <div class="si-form__b">
        <si-autocomplete-chip
          class="si-component"
          inputLabel="Committees"
          formControlName="groups"
          [options]="shareOptions"
          [readOnly]="readonly"
        ></si-autocomplete-chip>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="si-dialog__a" *ngIf="!readonly && !loading">
    <button mat-flat-button class="si-button si-button--primary" [disabled]="disableSaveButton" (click)="saveChanges()">
      Save Changes
    </button>
  </div>
</div>
