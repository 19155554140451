<div
  class="si-chipitem si-chipitem--{{ type }}"
  [class.si-state--singleton]="!inList"
  [class.si-state--removeable]="removeable"
>
  <ng-content select="prefix"></ng-content>
  <fs-part-button
    type="chip"
    [buttonId]="buttonId"
    [classes]="'si-chipitem--' + type + ' si-chipitem--' + size + ' ' + classes"
    [label]="label"
    [minor]="size === 'minor'"
    [role]="inList ? 'option' : null"
    [selected]="selected"
    [stopPropagation]="true"
    [tooltip]="tooltip"
    [url]="url"
    (buttonClick)="onButtonClick($event)"
  ></fs-part-button>
  <fs-part-button
    *ngIf="removeable"
    class="si-chipitem__remove"
    classes="'si-chipitem--' + size"
    [buttonId]="buttonId + '-remove'"
    [class.si-minor]="size === 'minor'"
    [materialIcon]="removeIcon ? removeIcon : 'cancel'"
    [minor]="size === 'minor'"
    [role]="inList ? 'option' : null"
    [stopPropagation]="true"
    [tooltip]="removeTooltip + ' ' + label"
    type="icon"
    [url]="removeUrl"
    (buttonClick)="onRemoveClick($event)"
  ></fs-part-button>
  <ng-content select="suffix"></ng-content>
</div>
