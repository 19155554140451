import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailToolTakeoverViewBase } from '@sae/features';
import { Observable, switchMap, tap } from 'rxjs';
import { ContentService } from '../../../../shared/state/content.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { SessionService } from '../../../../shared/state/session.service';
import { SeoUtilityService } from '../../../../shared/services/seo-utility.service';
@UntilDestroy()
@Component({
  templateUrl: '../templates/detail.component.html',
  host: { 'collision-id': 'MediaComponent' },
})
export class MediaComponent extends DetailToolTakeoverViewBase implements OnInit, OnDestroy {
  public fragment$: Observable<string> = this.route.fragment;
  public routeLoading$: Observable<boolean> = this.contentService.routeLoading$;
  public isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  public config$ = this.contentService.config$;

  constructor(
    public sessionService: SessionService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private detailsHelperService: DetailsHelperService,
    private seoUtilityService: SeoUtilityService
  ) {
    super(sessionService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        untilDestroyed(this),
        tap((data) =>
          this.seoUtilityService.setMetaTags(
            `${data?.content?.code}: ${data?.content?.title} - ${data?.content?.subGroup}`,
            data?.content?.abstract
          )
        ),
        switchMap((data) => this.detailsHelperService.getAdditionalDetails(data?.content))
      )
      .subscribe((cv) => this.contentService.setContentView(cv));

    this.contentService.updateDetailsConfiguration({
      panel_enabled: false,
      // detail_masthead_subheading_type: 'journal',
      detail_show_features: false,
      detail_show_event: false,
      detail_show_authors: true,
      detail_show_abstract: true,
      detail_show_tags: true,
      detail_show_details: false,
      detail_show_doi: false,
      detail_show_citations_badge: false,
      detail_show_page_count: false,
      detail_show_citation: false,
      detail_show_table_of_contents: false,
      detail_show_additional_details: true,
      detail_show_publisher: true,
      detail_show_pub_date: true,
      detail_show_code: true,
      detail_show_selected_type: true,
      detail_show_language: true,
      detail_show_view: false,
      detail_show_data_sets: false,
      detail_show_references: false,
      detail_show_related: false,
      detail_show_length: true,
      detail_show_series: true,
    });
  }

  ngOnDestroy(): void {
    this.contentService.resetStore();
    this.seoUtilityService.clearAllSEOTags();
  }
}
