<div class="si-multiselect" [class.si-state--selected]="selected">
  <div class="si-multiselect__control">
    <fs-part-button
      *ngIf="selected === false"
      type="icon"
      materialIcon="radio_button_unchecked"
      classes="si-button--row"
      tooltip="Select Row"
      (buttonClick)="selected = !selected; selectedChange.emit(selected)"
    ></fs-part-button>
    <fs-part-button
      *ngIf="selected"
      type="icon"
      materialIcon="check_circle"
      classes="si-button--row si-state--selected"
      tooltip="Unselect Row"
      (buttonClick)="selected = !selected; selectedChange.emit(selected)"
    ></fs-part-button>
  </div>
  <div class="si-multiselect__body">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
