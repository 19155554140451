<ng-container
  *ngIf="{
    content: content$ | async
  } as _"
>
  <div class="si-content__detail">
    <div class="si-data si-data--grid si-wd-full">
      <si-data-output
        class="si-data__set si-no-dataout__h si-wd si-wd-full"
        [content]="_.content?.outline"
      ></si-data-output>
    </div>
  </div>
</ng-container>
