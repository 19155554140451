<ng-container
  *ngIf="{
    showMobileNavMenu: showMobileNavMenu$ | async,
    isTakeoverActive: isTakeoverActive$ | async,
    isToolPanelCollapsed: isToolPanelCollapsed$ | async
  } as _"
>
<main class="si-columns si-contains--aux" [class.si-state--open]="_.showMobileNavMenu"
  [class.si-state--closed]="!_.showMobileNavMenu" [hidden]="_.isTakeoverActive"
  [class.si-state--collapsed--tools]="_.isToolPanelCollapsed"
  [class.si-state--collapsed-not--tools]="!_.isToolPanelCollapsed">
  <div class="si-col si-col--detail">
    <button class="si-button si-button--cover" aria-hidden="true" matTooltip="Collapse Panel"
      aria-label="Collapse Panel"></button>

    <div libDetectBreakpointWidth>
      <ng-template [cdkPortalOutlet]="detailContentPortal" *ngIf="projectedContent?.detailContent"></ng-template>
      <!-- the below is the expected markup to be projected - can we make sub-templates? e.g. this could be DetailContentTemplate
        <article class="si-content"><header class="si-content__h">
              <h1 class="si-content__title">Detail Panel Header</h1>
            </header>
            <div class="si-content__c">
              <div class="si-content__detail">
                [content]
              </div>
            </div> 
          </article> -->
    </div>
  </div>
  <div class="si-col si-col--aux">
    <div libDetectBreakpointWidth>
      <ng-template [cdkPortalOutlet]="toolContentPortal" *ngIf="projectedContent?.toolContent"></ng-template>
      <!-- <div class="si-col__set"><div class="si-col__h">
              <mat-toolbar class="si-toolbar si-toolbar--tool">
                <div class="si-toolbar__set">
                  <div class="si-toolbar__h">
                    <h3 class="si-toolbar__title">Tool Title</h3>
                  </div>
                  <span class="si-expanse"></span>
                </div>
              </mat-toolbar>
            </div>
            <div class="si-col__c">
                [content]
            </div>  -->
    </div>
  </div>
</main>
</ng-container>