<ng-container *ngIf="!appInit.done">Hang on a sec...</ng-container>
<div id="loader-container" *ngIf="isInMaintenanceMode">
  <img id="loader-image" src="https://sae-static-content-prod.cl.sae.org/logos/sae_logo_rev.png" alt="logo" />
  <h1 id="loader-text">This application is currently undergoing maintenance.</h1>
</div>
<ng-container *ngIf="appInit.done && !isInMaintenanceMode">
  <ng-container *ngIf="activeViewTemplate !== ViewTemplate.PreApp; else PreApp">
    <ng-container
      *ngIf="{
        showMobileNavMenu: showMobileNavMenu$ | async,
        isTakeoverActive: isTakeoverActive$ | async,
        activeNavIcon: activeNavIcon$ | async,
        masterWidth: masterWidth$ | async,
        isWideFeature: isWideFeature$ | async,
        showUpdatesNavIcon: showUpdatesNavIcon$ | async,
        showHelpNavIcon: showHelpNavIcon$ | async,
        showHomeNavIcon: showHomeNavIcon$ | async,
        showSearchNavIcon: showSearchNavIcon$ | async,
        isToolPanelCollapsed: isToolPanelCollapsed$ | async,
        showFeatures: showFeatures$ | async
      } as _"
    >
      <div globalVhVarUpdate>
        <si-app-toolbar class="si-component">
          <ng-content select="[customToolbarContent]" ngProjectAs="[customToolbarContent]"></ng-content>
        </si-app-toolbar>
        <div
          data-testid="layoutDiv"
          class="si-layout si-narrow--top"
          [class.si-template--d]="
            activeViewTemplate === ViewTemplate.Detail || activeViewTemplate === ViewTemplate.DetailTakeover
          "
          [class.si-template--d-t]="
            activeViewTemplate === ViewTemplate.DetailTool || activeViewTemplate === ViewTemplate.DetailToolTakeover
          "
          [class.si-template--m-d]="activeViewTemplate === ViewTemplate.MasterDetail"
          [class.si-template--m-d-t]="activeViewTemplate === ViewTemplate.MasterDetailTool"
          [class.si-template--c-m-d]="activeViewTemplate === ViewTemplate.CrossoverMasterDetail"
          [class.si-layout--feature]="_.showFeatures"
          [class.si-contains--features]="_.showFeatures"
          [class.si-layout--no-feature]="!_.showFeatures"
          [class.si-wide--feature]="_.isWideFeature"
          [class.si-wide--master]="_.masterWidth === 'wide'"
          [class.si-narrow--master]="_.masterWidth === 'narrow'"
          [class.si-state--open]="_.showMobileNavMenu"
          [class.si-state--closed]="!_.showMobileNavMenu"
        >
          <si-seo-feature-toolbar
            class="si-features__wrap"
            *ngIf="_.showFeatures"
            [features]="features"
            [activeNavIcon]="_.activeNavIcon"
            (featureClicked)="setActiveNavIcon($event)"
            [showHomeFeature]="showHomeFeature ?? _.showHomeNavIcon"
            [showSearchFeature]="_.showSearchNavIcon"
            [showUpdatesFeature]="_.showUpdatesNavIcon"
            [showHelpFeature]="_.showHelpNavIcon"
            [updatesRoute]="updatesRoute"
            [updatesLabel]="updatesLabel"
          ></si-seo-feature-toolbar>
          <button
            class="si-button si-button--cover"
            data-testid="showMobileNav"
            (click)="setShowMobileNavMenu(false)"
            aria-hidden="true"
            matTooltip="Collapse Panel"
            aria-label="Collapse Panel"
          ></button>
          <ng-container [ngSwitch]="activeViewTemplate">
            <si-detail-template
              *ngSwitchCase="ViewTemplate.Detail"
              class="si-template"
              [projectedContent]="detailViewContent"
            ></si-detail-template>

            <si-detail-tool-template
              *ngSwitchCase="ViewTemplate.DetailTool"
              class="si-template"
              [projectedContent]="detailToolViewContent"
            ></si-detail-tool-template>

            <si-master-detail-template
              *ngSwitchCase="ViewTemplate.MasterDetail"
              class="si-template"
              [projectedContent]="masterDetailViewContent"
            ></si-master-detail-template>

            <si-master-detail-tool-template
              *ngSwitchCase="ViewTemplate.MasterDetailTool"
              class="si-template"
              [projectedContent]="masterDetailToolViewContent"
            ></si-master-detail-tool-template>

            <si-crossover-master-detail-template
              *ngSwitchCase="ViewTemplate.CrossoverMasterDetail"
              class="si-template"
              [projectedContent]="crossoverMasterDetailViewContent"
            ></si-crossover-master-detail-template>

            <si-detail-takeover-template
              *ngSwitchCase="ViewTemplate.DetailTakeover"
              class="si-template"
              [projectedContent]="detailTakeoverViewContent"
            ></si-detail-takeover-template>

            <si-detail-tool-takeover-template
              *ngSwitchCase="ViewTemplate.DetailToolTakeover"
              class="si-template"
              [projectedContent]="detailToolTakeoverViewContent"
            ></si-detail-tool-takeover-template>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #PreApp>
    <si-pre-app-template [projectedContent]="preAppViewContent"></si-pre-app-template>
  </ng-template>
</ng-container>
