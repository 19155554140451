import {
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  Optional,
  PLATFORM_ID,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgEntityServiceGlobalConfig, NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { ApmService } from '@elastic/apm-rum-angular';
import {
  SaeEnvironmentConfig,
  SaeEnvironment,
  APP_CONFIG_TOKEN,
  AppConfig,
  getApiBaseUrlForEnvironment,
  DefaultApiBaseUrls,
  IEnvironmentConfigService,
  ConfigJSON,
  CONFIG_JSON,
  HOST_URL,
} from '@sae/base';
import { ApmRumService } from './apm.core';
import { AUTH_TOKEN, IAuthService } from './auth.core';
import { REMOTE_CONFIG_TOKEN } from './remote-config.core';
import { ISessionExpiryService, SESSION_EXPIRY_TOKEN } from './session-expiry.core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { firstValueFrom, catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const configJSON = makeStateKey<ConfigJSON>('configJSON');
const hostURL = makeStateKey<string>('hostURL');

@Injectable({ providedIn: 'root' })
export class ProteusAppInitCoreService implements OnDestroy {
  public static currentEnv: SaeEnvironment;

  protected ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig;
  protected envConfigService: IEnvironmentConfigService;
  protected authService: IAuthService;
  protected sessionExpiryService: ISessionExpiryService;

  private transactionStartObserver: any;
  private transactionEndObserver: any;

  constructor(
    protected readonly apmRumService: ApmRumService,
    protected readonly apmService: ApmService,
    protected readonly router: Router,
    protected readonly injector: Injector,
    protected readonly http: HttpClient,
    protected readonly transferState: TransferState,
    @Inject(APP_CONFIG_TOKEN) protected readonly appConfig: AppConfig,
    @Inject(PLATFORM_ID) protected readonly platformId: unknown,
    @Optional() @Inject(CONFIG_JSON) protected readonly configJSON: ConfigJSON,
    @Optional() @Inject(HOST_URL) protected readonly host: string
  ) {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(hostURL, this.host);
    }
  }

  public async initialize(localHostEnv: SaeEnvironment = SaeEnvironment.dev): Promise<void> {
    const { version, environment } = await this.getConfigJSON();

    if (environment) {
      this.ngEntityServiceGlobalConfig = this.injector.get<NgEntityServiceGlobalConfig>(NG_ENTITY_SERVICE_CONFIG);
      this.authService = this.injector.get<IAuthService>(AUTH_TOKEN);
      this.envConfigService = this.injector.get<IEnvironmentConfigService>(REMOTE_CONFIG_TOKEN);
      this.sessionExpiryService = this.injector.get<ISessionExpiryService>(SESSION_EXPIRY_TOKEN, null);

      const targetEnv = environment === SaeEnvironment.localhost ? localHostEnv : environment;
      ProteusAppInitCoreService.currentEnv = targetEnv;

      const envConfig = await this.envConfigService.initialize(targetEnv, this.transferState.get(hostURL, null));

      // if the apiRootUrl is defined on the environment config, it overrules whatever is on the app config
      const apiBaseUrl =
        envConfig.services.apiRootUrl ??
        getApiBaseUrlForEnvironment(targetEnv, this.appConfig.apiBaseUrls ?? DefaultApiBaseUrls);
      this.ngEntityServiceGlobalConfig.baseUrl = apiBaseUrl;

      this.setupAPM(envConfig, environment, apiBaseUrl);
      if (isPlatformBrowser(this.platformId)) {
        // skip auth service initialize during SSR
        await this.authService.initialize(envConfig);
      }
      if (this.sessionExpiryService) {
        this.sessionExpiryService.initialize(envConfig);
      }

      // some feedback for us while we prove this out on other environments
      if (environment !== SaeEnvironment.prod) {
        if (version) console.log(`Version: ${version}`);
        console.log(
          `Environment: ${environment === SaeEnvironment.localhost ? environment + ' -> ' + localHostEnv : environment}`
        );
        console.log('Resolved Environment Configuration:', envConfig);
        const host = this.transferState.get(hostURL, null);
        if (host) {
          console.log('TransferState successful. The host URL is: ', host);
        }
      }
    } else {
      throw new Error('The application could not be initialized.');
    }
  }

  protected getConfigJSON(): Promise<ConfigJSON> {
    if (isPlatformServer(this.platformId)) {
      // server context
      if (this.configJSON) {
        this.transferState.set(configJSON, this.configJSON); // pass config to client side

        return Promise.resolve(this.configJSON);
      } else {
        throw new Error('config.json or its injection token is missing');
      }
    } else {
      // browser context
      // for SSR apps, use data from transferState
      const config = this.transferState.get(configJSON, null);

      if (config) {
        return Promise.resolve(config);
      } else {
        // for non-SSR apps, get configJSON via HTTP:
        return firstValueFrom(
          this.http.get<ConfigJSON>('/config.json').pipe(
            catchError((err) => {
              if (err.url === 'http://localhost:4200/config.json') {
                console.warn('Please create a config.json for local development');
                return of({ environment: SaeEnvironment.localhost });
              } else {
                throw err;
              }
            })
          )
        );
      }
    }
  }

  protected setupAPM(environmentConfig: SaeEnvironmentConfig, currentEnv: string, apiBaseUrl: string): void {
    if (environmentConfig.metrics.useApm) {
      if (!environmentConfig.metrics.apmServiceName || !environmentConfig.metrics.apm) {
        throw new Error('Missing APM remote config settings!');
      }
      this.apmRumService.apm = this.apmService.init({
        serviceName: environmentConfig.metrics.apmServiceName,
        serverUrl: environmentConfig.metrics.apm,
        distributedTracingOrigins: [
          // NOTE: distributedTracingOrigins is the base of the root url - we must remove the '/api'
          apiBaseUrl.substring(0, apiBaseUrl.length - 4),
        ],
        environment: currentEnv,
      });

      if (isPlatformServer(this.platformId)) {
        return;
      }

      this.transactionStartObserver = this.apmRumService.apm.observe('transaction:start', (transaction) => {
        // we must add a span in order for the user-interaction transaction to be captured by APM
        // timeout is necesary because elastic will discard a transaction with no duration
        if (transaction.type === 'user-interaction' && environmentConfig.metrics.apmUserInteractions) {
          const uiSpan = transaction.startSpan('ui-span', 'app');
          setTimeout(() => {
            transaction.end();
            uiSpan?.end();
          }, 0);
        }
      });

      this.transactionEndObserver = this.apmRumService.apm.observe('transaction:end', (transaction) => {
        if (transaction.type === 'http-request') {
          const url = transaction.name.split(' ');
          if (url.length > 1) {
            transaction.name = url[0].concat(' ', url[1].split('/').slice(0, -1).join('/'));
          } else {
            transaction.name = url[0].split('/').slice(0, -1).join('/');
          }
        }
        if (transaction.type === 'route-change' && transaction.name === 'Unknown') {
          transaction.name = '/' + this.router.url.split('/').slice(3, -1).join('/');
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.transactionEndObserver = undefined;
    this.transactionStartObserver = undefined;
  }
}
