<dl class="si-dataout si-dataout--basic" [class.si-dataout--headless]="!title">
  <dt class="si-dataout__h" *ngIf="title">
    <div class="si-dataout__h__title">
      <ng-container *ngIf="!titleLink">{{ title }}</ng-container>
      <ng-container *ngIf="titleLink">
        <a [href]="titleLink">{{ title }}</a>
      </ng-container>
    </div>
  </dt>
  <dt class="si-dataout__h si-visible--sr-only" *ngIf="!title">Content</dt>
  <dd class="si-dataout__c">
    <span [innerHTML]="content" *ngIf="content"></span>
    <ng-content></ng-content>
    <!-- 
      BEGIN: Everything within this comment wrapper will eventually be deprecated and removed via UX-1196 -->
    <si-table-field
      *ngIf="showEditField"
      [title]="title"
      [data]="true"
      message="{{ title }} changes saved."
      (editExit)="showEditField = false"
    ></si-table-field>
    <button
      *ngIf="buttonIcon"
      mat-icon-button
      class="si-button si-button--icon si-dataout--icon"
      [attr.aria-label]="buttonLabel"
      [matTooltip]="buttonLabel ? buttonLabel : null"
    >
      <mat-icon color="primary">{{ buttonIcon }}</mat-icon>
    </button>
    <ng-container *ngIf="editContent">
      <button
        *ngIf="!showEditField"
        mat-icon-button
        class="si-button si-button--icon si-button--edit si-dataout--icon"
        matTooltip="Edit"
        aria-label="Edit"
        (click)="showEditField = true; $event.stopPropagation()"
      >
        <mat-icon aria-hidden="true">create</mat-icon>
      </button>
      <div *ngIf="!content" class="si-dataout__c--offset"></div>
    </ng-container>
    <!-- END: Everything within this comment wrapper will eventually be deprecated and removed via UX-1196 -->
  </dd>
  <!-- BEGIN: Everything within this comment wrapper will eventually be deprecated and removed via UX-1196 -->
  <dd class="si-dataout__c" *ngIf="chips.length">
    <si-seo-chiplist
      class="si-component"
      [ariaLabel]="title"
      [chips]="chipsList"
      [isMinor]="true"
      [isLong]="true"
      (iconClicked)="iconClicked($event)"
    ></si-seo-chiplist>
  </dd>
  <!-- END: Everything within this comment wrapper will eventually be deprecated and removed via UX-1196 -->
</dl>
