import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { SessionService } from '../../../../../shared/state/session.service';
import { ContentService } from '../../../../../shared/state/content.service';

@Component({
  selector: 'mobi-detail-panel',
  templateUrl: './detail-panel.component.html',
})
export class DetailPanelComponent implements OnInit {
  @ViewChild('template', { static: true }) template;

  public isToolPanelCollapsed$ = this.sessionService.isToolPanelCollapsed$;
  public content$ = this.route.data.pipe(map((c) => c?.content));
  public config$ = this.contentService.config$;
  public fullScreen$ = this.contentService.fullScreen$;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private contentService: ContentService
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  setIsToolPanelCollapsed(toggle: boolean): void {
    this.sessionService.setIsToolPanelCollapsed(toggle);
  }
}
