import { Component } from '@angular/core';
import { ContentService } from '../../../../../shared/state/content.service';
@Component({
  selector: 'mobi-detail-materials-and-requirements',
  templateUrl: './detail-materials-and-requirements.component.html',
})
export class DetailMaterialsAndRequirementsComponent {
  public content$ = this.contentService.content$;
  constructor(private contentService: ContentService) {}
}
