<ng-container
  *ngIf="{
    loading: viewLoading$ | async,
    viewMode: viewMode$ | async,
    isLoggedIn: isLoggedIn$ | async,
    fullScreen: fullScreen$ | async,
    fragment: fragment$ | async
  } as _"
>
  <mat-card class="si-card mat-elevation-z2 si-card--dataviewer" *ngIf="_.showView === false && _.errorView === true">
    <mat-card-header class="si-card--dataviewer__h">
      <mat-card-title class="si-card__title si-impact">View</mat-card-title>
    </mat-card-header>
    <div class="si-card--dataviewer__c">
      <p>There was a problem loading the document.</p>
      <div class="si-actions-row">
        <div class="si-actions-row__a">
          <button mat-flat-button class="si-button si-button--primary" (click)="retryAfterError()">Retry</button>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card
    class="mat-elevation-z2 si-card si-card--dataviewer si-card--stub"
    id="si-fullscreen-container"
    [class.si-state--full]="_.fullScreen"
  >
    <mat-card-header class="si-card--dataviewer__h si-stub-header" [class.si-state--full]="_.fullScreen">
      <mat-card-title class="si-card__title si-impact">View</mat-card-title>
      <span class="si-expanse"></span>
      <button
        *ngIf="redlineOptions?.length"
        mat-icon-button
        [matMenuTriggerFor]="redlineOpts"
        class="si-icon si-icon--more"
        color="primary"
        matTooltip="Compare to"
        aria-label="Compare to"
      >
        <mat-icon>compare_arrows</mat-icon>
      </button>
      <mat-menu #redlineOpts="matMenu" class="si-menu">
        <mat-label class="si-label">Compare to</mat-label>
        <ng-container *ngFor="let revision of redlineOptions">
          <button mat-menu-item class="si-button--detailed" data-testid="selectOption" (click)="redline(revision)">
            <mat-icon color="primary" aria-hidden="true">
              {{ revision?.status === 'Current' ? 'verified' : 'history' }}
            </mat-icon>
            <span>{{ revision.useExtendedCode ? revision.extendedCode : revision.code }}</span>
          </button>
        </ng-container>
      </mat-menu>
      <button
        *ngIf="!_.fullScreen"
        mat-icon-button
        class="si-icon si-icon--more"
        color="primary"
        matTooltip="Full Screen"
        aria-label="Full Screen"
        (click)="toggleFullScreen(true)"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button
        *ngIf="_.fullScreen"
        mat-icon-button
        class="si-icon si-icon--more"
        color="primary"
        matTooltip="Exit Full Screen"
        aria-label="Exit Full Screen"
        (click)="toggleFullScreen(false)"
      >
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button
        mat-icon-button
        [matMenuTriggerFor]="optionsMore"
        class="si-icon si-icon--more"
        color="primary"
        matTooltip="Options"
        aria-label="Options"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #optionsMore="matMenu" class="si-menu">
        <button
          *ngIf="_.viewMode === 'Annotation' && accessLevel === 'FULL'"
          mat-menu-item
          [disabled]="!annotations?.length"
          class="si-button--detailed"
          (click)="clearAnnotations()"
        >
          <mat-icon class="si-tool-icon" color="primary">clear</mat-icon>
          <span>Clear Annotations</span>
        </button>
        <mat-divider class="si-divider"></mat-divider>
        <button
          *ngIf="_.viewMode === 'Annotation' && accessLevel === 'FULL'"
          mat-menu-item
          [disabled]="!annotations?.length"
          class="si-button--detailed"
          (click)="hideAnnotations()"
        >
          <mat-icon class="si-tool-icon" color="primary">hide_source</mat-icon>
          <span>Hide Annotations</span>
        </button>
        <button
          *ngIf="_.viewMode === 'Document'"
          mat-menu-item
          class="si-button--detailed"
          [class.si-state--inaccessible]="!_.isLoggedIn"
          (click)="_.isLoggedIn ? showAnnotations() : login()"
        >
          <mat-icon class="si-tool-icon" [class.si-state--inaccessible]="!_.isLoggedIn" color="primary">
            visibility
          </mat-icon>
          <span>Show Annotations</span>
        </button>
        <button *ngIf="!_.fullScreen" mat-menu-item class="si-button--detailed" (click)="toggleFullScreen(true)">
          <mat-icon class="si-tool-icon" color="primary">fullscreen</mat-icon>
          <span>Full Screen</span>
        </button>
        <button *ngIf="_.fullScreen" mat-menu-item class="si-button--detailed" (click)="toggleFullScreen(false)">
          <mat-icon class="si-tool-icon" color="primary">fullscreen_exit</mat-icon>
          <span>Exit Full Screen</span>
        </button>
      </mat-menu>
      <mat-card-subtitle>
        <div class="si-chiplist__wrap">
          <mat-chip-list aria-label="Filters" class="si-chiplist si-chiplist--tabs">
            <mat-chip
              class="si-chip si-chip--minor"
              [class.si-state--selected]="_.viewMode === 'Document'"
              (click)="onViewChipClicked('Document')"
            >
              <mat-icon aria-hidden="true">article</mat-icon>
              <span>Document</span>
            </mat-chip>
            <mat-chip
              class="si-chip si-chip--minor"
              [class.si-state--selected]="_.viewMode === 'Annotation'"
              (click)="onViewChipClicked('Annotation')"
              [class.si-state--inaccessible]="!_.isLoggedIn"
            >
              <mat-icon aria-hidden="true">create</mat-icon>
              <span>Annotation</span>
            </mat-chip>
            <mat-chip
              *ngIf="redlineOptions?.length"
              class="si-chip si-chip--minor"
              [class.si-state--selected]="_.viewMode === 'Redlining'"
              [matMenuTriggerFor]="redlineOpts"
            >
              <mat-icon aria-hidden="true">compare_arrows</mat-icon>
              <span>Redlining</span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-card-subtitle>
    </mat-card-header>
    <div *ngIf="accessLevel !== 'FULL'" class="si-card--dataviewer__c">
      <p>{{ actionText }}</p>
      <div class="si-actions-row">
        <div class="si-actions-row__a">
          <button mat-flat-button class="si-button si-button--secondary" (click)="secondaryClicked()">
            {{ secondaryText }}
          </button>
          <button mat-flat-button class="si-button si-button--primary" (click)="primaryClicked()">
            {{ primaryText }}
          </button>
        </div>
      </div>
    </div>
    <mat-card-content *ngIf="accessLevel === 'FULL'" class="si-card__c">
      <div class="si-component">
        <mobi-document-view *ngIf="_.viewMode === 'Document'" [contentId]="content.id"></mobi-document-view>
        <mobi-annotation-view
          #annotationView
          *ngIf="_.viewMode === 'Annotation'"
          [contentId]="content.id"
          (annotationsChanged)="annotations = $event"
        ></mobi-annotation-view>
        <mobi-redline-view
          *ngIf="_.viewMode === 'Redlining'"
          [contentId]="content.id"
          [compareAgainstId]="compareAgainstId"
        ></mobi-redline-view>
      </div>
    </mat-card-content>
  </mat-card>
  <ng-template #loading>
    <ng-container *ngIf="_.loading">
      <div class="si-spinner__container">
        <mat-spinner class="si-spinner"></mat-spinner>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
