/* eslint-disable @typescript-eslint/no-unsafe-return */
import { inject, Inject, Injectable, InjectionToken, ProviderToken } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SortDirection } from '@angular/material/sort';
import { applyTransaction, arrayAdd, arrayRemove, QueryEntity, transaction } from '@datorama/akita';
import {
  HttpGetConfig,
  HttpMethod,
  NgEntityServiceGlobalConfig,
  NG_ENTITY_SERVICE_CONFIG,
} from '@datorama/akita-ng-entity-service';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { EnterpriseAppEntry } from '@sae/base';
import { NotificationFacet, NotificationsResponse, Pagination, SaeNotification } from '@sae/models';

import { ApiRegistry, ApiToken, StoresRegistry, StoresToken } from './../api-registry';
import { BaseEntityService, BaseEntityStore, BaseState } from './base.service';


export interface INotificationsService {
  loading$: Observable<boolean>;
  notifications$: Observable<Array<SaeNotification>>;
  selectedAppFilter$: Observable<EnterpriseAppEntry | undefined>;
  selectedNotifications$: Observable<Array<SaeNotification>>;
  isAllSelected$: Observable<boolean>;
  isAnySelected$: Observable<boolean>;
  pagination$: Observable<Pagination>;
  total$: Observable<number>;
  totalUnread$: Observable<number>;
  sortBy$: Observable<string>;
  sortDir$: Observable<SortDirection>;
  init(): void;
  setTags(tags: Array<string>): void;
  setSelectedAppFilter(selectedAppFilter: EnterpriseAppEntry): void;
  setApplicationCode(applicationCode: string): void;
  setLoading(loading: boolean): void;
  updateIsRead(notifId: string, isRead: boolean): Observable<SaeNotification | null>;
  remove(notifId: string): void;
  removeSelected(): void;
  removeAlertAndDeleteSelected(): void;
  markAllSelectedAsRead(): void;
  markAllSelectedAsUnread(): void;
  markRead(notifId: string): void;
  markUnread(notifId: string): void;
  errorHandler(error: unknown): Observable<any>;
  getPagination(): Pagination;
  setPagination(pagination: Pagination): void;
  isLoading(): boolean;
  isAllFetched(): boolean;
  getTotalUnreadFromResponse(res: NotificationsResponse): number;
  getTotalUnreadFromFacets(facets: NotificationFacet[]): number;
  removeAlert(notifId: string): Observable<SaeNotification>;
  removeAlertAndDelete(notifId: string): void;
  selectNotificationById(notificationId: string): void;
  selectAll(): void;
  deselectAll(): void;
  setSortBy(sortBy: string): void;
  setSortDir(sortDir: SortDirection): void;
}

@Injectable({ providedIn: 'root' })
export class NotificationsCoreQuery extends QueryEntity<NotificationsState> {
  constructor(protected store: NotificationsCoreStore) {
    super(store);
  }
}

export const POLL_INTERVAL = 60_000; // default 60 seconds
export interface NotificationsState extends BaseState<SaeNotification> {
  totalUnread: number;
  selectedAppFilter: EnterpriseAppEntry | undefined;
  selectedNotifications: Array<SaeNotification>;
}

@Injectable({ providedIn: 'root' })
export class NotificationsCoreStore extends BaseEntityStore<NotificationsState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super(
      { name: storesRegistry.notifications, cache: { ttl: POLL_INTERVAL } },
      NotificationsCoreStore.createInitialState()
    );
  }
  static createInitialState(): NotificationsState {
    return {
      active: null,
      totalUnread: 0,
      pagination: { offset: 0, limit: 50, total: 0 },
      sortField: 'sentDate',
      sortDir: 'desc',
      filter: '',
      searchTerm: '',
      customParams: [],
      isInitialized: false,
      selectedAppFilter: undefined,
      selectedNotifications: [],
      mostRecentResponse: undefined,
    };
  }
}

export interface NotificationActionEvent {
  notification: SaeNotification;
  source: string;
}

export class NotificationsCoreService extends BaseEntityService<NotificationsState> implements INotificationsService {
  loading$ = this.query.selectLoading();
  notifications$ = this.query.selectAll();
  selectedAppFilter$ = this.query.select('selectedAppFilter');
  selectedNotifications$ = this.query.select('selectedNotifications');
  isAllSelected$ = this.query.select((s) => s.selectedNotifications.length === s?.ids?.length);
  isAnySelected$ = this.query.select((s) => s.selectedNotifications.length > 0);
  pagination$ = this.query.select('pagination');
  total$ = this.query.select((s) => s.pagination.total);
  totalUnread$ = this.query.select((s) => s.totalUnread);
  sortBy$ = this.query.select((s) => s.sortField);
  sortDir$ = this.query.select((s) => s.sortDir);

  constructor(
    public readonly store: NotificationsCoreStore,
    public readonly query: NotificationsCoreQuery,
    private readonly snackBar: MatSnackBar,
    @Inject(NG_ENTITY_SERVICE_CONFIG)
    ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) apiReg: ApiRegistry
  ) {
    super(store, query, {
      baseUrl: ngEntityServiceGlobalConfig.baseUrl,
      resourceName: apiReg.notifications?.url,
    });
    this.error$
      .pipe(
        filter((e) => !!e),
        tap((e) => this.errorHandler(e))
      )
      .subscribe();
    this.selectedAppFilter$.pipe(tap(() => this.processFetchEntities(true))).subscribe();
  }

  init(): void {
    this.sortFieldParamName = 'sortBy';
    this.sortDirParamName = 'sortOrder';
    this.initializeAndFetch();
  }


  protected preFetchEntities(): void {
    this.sortFieldParamName = 'sortBy';
    this.sortDirParamName = 'sortOrder';
  }

  protected fetchEntitiesGetData(config: HttpGetConfig): Observable<NotificationsResponse> {
    let response: NotificationsResponse;

    this.setLoading(true);
    return this.get({
      ...config,
      mapResponseFn: (res: NotificationsResponse) => {
        response = { ...res };
        return res.results;
      },
    }).pipe(
      catchError((error) => {
        this.setLoading(false);
        this.errorHandler(error);
        return EMPTY;
      }),
      map(() => {
        this.setLoading(false);
        return { ...response };
      }),
      tap((r: NotificationsResponse) => {
        this.getTotalUnreadFromResponse(r);
      })
    );
  }

  setTags(tags: Array<string>): void {
    this.addCustomParam('tags', tags.join(','));
  }

  @transaction()
  setSelectedAppFilter(selectedAppFilter: EnterpriseAppEntry): void {
    if (!selectedAppFilter) {
      this.removeCustomParam('applicationCode');
    } else {
      this.addCustomParam('applicationCode', selectedAppFilter.messagesKey);
    }
    this.store.update({ selectedAppFilter });
  }

  setApplicationCode(applicationCode: string): void {
    this.addCustomParam('applicationCode', applicationCode);
  }

  setLoading(loading: boolean): void {
    this.store.update({ loading });
  }

  @transaction()
  updateIsRead(notifId: string, isRead: boolean): Observable<SaeNotification | null> {
    return this.update(
      notifId,
      { isRead },
      {
        method: HttpMethod.PATCH,
        mapResponseFn: (res: NotificationsResponse) => {
          const n = res && res.results && res.results.length ? res.results[0] : null;
          if (!n) {
            throwError(null);
          }
          this.store.update((s) => {
            return {
              totalUnread: isRead ? s.totalUnread - 1 : s.totalUnread + 1,
            };
          });
          return n;
        },
      }
    ).pipe(
      catchError((error) => {
        this.errorHandler(error);
        return EMPTY;
      })
    );
  }

  @transaction()
  remove(notifId: string): void {
    const notif = this.query.getEntity(notifId);
    this.store.update((s) => {
      return {
        ...s,
        totalUnread: !notif?.isRead ? s.totalUnread - 1 : s.totalUnread,
        pagination: { ...s.pagination, total: s.pagination.total - 1 },
      };
    });
    try {
      this.deleteEntity(notifId);
    } catch (error) {
      this.errorHandler(error);
    } finally {
      this.store.remove(notifId);
    }
  }

  @transaction()
  removeSelected(): void {
    applyTransaction(() => {
      this.query.getValue().selectedNotifications.forEach((n) => this.remove(n.id));
    });
    this.store.update({ selectedNotifications: [] });
    setTimeout(() => { this.initializeAndFetch() }, 2000);
  }

  removeAlertAndDeleteSelected(): void {
    this.query.getValue().selectedNotifications.forEach((n) => this.removeAlertAndDelete(n.id));
  }

  markAllSelectedAsRead(): void {
    applyTransaction(() => {
      this.query.getValue().selectedNotifications.forEach((n) => this.markRead(n.id));
    });
    // Trigger fetch event after 2000ms to give elastic enough time for data sync
    setTimeout(() => this.initializeAndFetch(), 2000);
  }

  markAllSelectedAsUnread(): void {
    applyTransaction(() => {
      this.query.getValue().selectedNotifications.forEach((n) => this.markUnread(n.id));
    });
    setTimeout(() => this.initializeAndFetch(), 2000);
  }

  @transaction()
  markRead(notifId: string): void {
    this.updateIsRead(notifId, true).subscribe();
  }

  @transaction()
  markUnread(notifId: string): void {
    this.updateIsRead(notifId, false).subscribe();
  }

  errorHandler(error: unknown): Observable<any> {
    console.error(error);
    this.snackBar.open('Oops something seems to have gone wrong!', 'OK', {
      duration: 4500,
    });
    return of(null);
  }

  getPagination(): Pagination {
    return this.query.getValue().pagination;
  }
  setPagination(pagination: Pagination): void {
    this.store.update({ pagination });
  }

  isLoading(): boolean {
    return this.query.getValue().loading ?? false;
  }

  isAllFetched(): boolean {
    const pagination = this.getPagination();
    const total = pagination ? pagination.total : 0;
    return total === this.query.getCount();
  }

  getTotalUnreadFromResponse(res: NotificationsResponse): number {
    const unreadMessages = this.getTotalUnreadFromFacets(res.facets);
    this.store.update({ totalUnread: unreadMessages });
    return unreadMessages;
  }

  getTotalUnreadFromFacets(facets: NotificationFacet[]): number {
    let unread = 0;
    const allFacets = facets.find((f) => f.name === 'Read');
    if (allFacets) {
      unread = (allFacets.values || []).reduce((s, v) => {
        if (v.label === 'false') {
          s = s + v.count;
        }
        return s;
      }, 0);
    }
    return unread;
  }

  removeAlert(notifId: string): Observable<SaeNotification> {
    const notif = this.query.getEntity(notifId);
    const alertId = notif?.metadata.alertId;
    return this.getHttp().delete<SaeNotification>(`${this.baseUrl}/v1/alerts/alerts/${alertId}`);
  }

  removeAlertAndDelete(notifId: string): void {
    this.removeAlert(notifId)
      .pipe(
        tap(() => this.remove(notifId)),
        catchError((error) => this.errorHandler(error))
      )
      .subscribe();
  }

  selectNotificationById(notificationId: string): void {
    const notification = this.query.getEntity(notificationId);
    this.store.update((s) => {
      return {
        ...s,
        selectedNotifications: arrayAdd(s.selectedNotifications, notification),
      };
    });
  }

  deselectNotificationById(notificationId: string): void {
    const notification = this.query.getEntity(notificationId);
    this.store.update((s) => {
      return {
        ...s,
        selectedNotifications: arrayRemove(s.selectedNotifications, notification.id),
      };
    });
  }

  selectAll(): void {
    const all = this.query.getAll();
    this.store.update((s) => {
      return { ...s, selectedNotifications: all };
    });
  }

  deselectAll(): void {
    this.store.update((s) => {
      return { ...s, selectedNotifications: [] };
    });
  }

  setSortBy(sortBy: string): void {
    this.store.update({ sortField: sortBy });
  }
  setSortDir(sortDir: SortDirection): void {
    this.store.update({ sortDir });
  }
}

export const NOTIFICATIONS_TOKEN = new InjectionToken<INotificationsService>('Notifications Service', {
  providedIn: 'root',
  factory: () =>
    new NotificationsCoreService(
      inject(NotificationsCoreStore),
      inject(NotificationsCoreQuery),
      inject(MatSnackBar),
      inject(NG_ENTITY_SERVICE_CONFIG),
      inject(ApiToken as ProviderToken<ApiRegistry>)
    ),
});
