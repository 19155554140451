import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MoreOption } from '@sae/models';

export interface ListManagerCommon extends ListManagerUIState {
  // NOTE: When in readOnlyInput mode, properties of ListManagerCommon will are directly inherited from both the initial "sections" input as well as when change detection triggers on it.
  id?: string | number; // Optional. Represents the distinct primary key of the object, only used by getNodeState/setNodeState to specifically target a direct node and get/set local ui state on it.
  title: string; // Required. Should be distinct within siblings, especially for readOnlyInput mode as this will be used as a unique key. For sections this is the primary title of the section to render at the toggle header of the expansion panel. For nodes, this is the primary text of the node element.
  subtitle?: string; // Optional. For sections it is used in conjunction with title, displays text after title in ligher font. For nodes, if set text displays under the primary line of the node in more subdued style.
  checked?: boolean; // Optional, false by default, used in conjunction with checkboxPresent, holds the checked state of the checkbox.
  nodes?: ListManagerNode[]; // Optional, child nodes to generate under this node, albeit respecting maxNodeDepth input.
}

export interface ListManagerSection extends ListManagerCommon {
  // NOTE: When in readOnlyInput mode, properties of ListManagerSection will be used for initial configuration of new objects, afterwards any changes to "sections" input for existing sections/nodes will have these properties preserved from local ui state. Note that manipulating the local ui state copy is fine and a recommended approach to achieve any ui/ux desired by these properties.
  divider?: boolean; // Optional, false by default, if true renders a divider break after this section's full content.
  checkboxTitle?: string; // Optional, used in conjunction with checkboxPresent, represents the title to place next to the checkbox, if omitted "Added" title will be used by default.
  checkboxHidesNodes?: boolean; // Optional, used in conjunction with checkboxPresent, if true when the checkbox is unchecked the nodes (and a node title if present) are hidden.
  nodesGroupTitleVisible?: boolean; // Optional, false by default, if true displays a header with nodesGroupTitleText before rendering this sections child nodes tree.
  nodesGroupTitleText?: string; // Optional, used in conjunction with nodesGroupTitleVisible to display this a header text before rendering this sections child nodes tree.
  createNodeIcon?: string; // Optional, if set displays provided mat icon on the left of the header bar for the create node section.
  createNodeTitle?: string; // Optional, used in conjuction with createNodeEnabled and specifies the title text to display on the create node section header.
  noItemsText?: string; // Optional, text to display when there are no items to display for the section, if not supplied "No items found" will be used as default.
}

export interface ListManagerNode extends ListManagerCommon {
  // NOTE: When in readOnlyInput mode, properties of ListManagerCommon will be used for initial configuration of new objects, afterwards any changes to "sections" input for existing sections/nodes will have these properties preserved from local ui state. Note that manipulating the local ui state copy is fine and a recommended approach to achieve any ui/ux desired by these properties.
  headerLabel?: string; // Optional, if set text displays as a header above the primary line of the node using a label style.
  shared?: boolean; // Optional, if true displays a shared icon after the title text.
  deleteNodeMenuText?: string; // Optional, text to display if a delete node action is present on the menu, if not supplied "Delete Folder" is used by default.
  customMenuIcon?: string; // Optional, if provided, and if customMenuOptions is used, this can be used to specify the icon style for the trigger button, if omitted 'more_vert' is used by default
  customMenuIconClass?: string; // Optional, assigns this class to the mat-icon of customMenuIcon, useful for providing color/style overrides that aren't available via native color property.
  customMenuTooltip?: string; // Optional, defines the tooltip to show for the button, if omitted 'Options' will be used by default
  auxButton?: boolean; // Optional, if true exposes an additional auxilliary button to the right of the node line
  auxButtonTriggersCustomMenu?: boolean; // Optional, if true binds a unique use case where the button is an trigger for the custom menu options (thus two buttons will both trigger and share the same menu)
  auxButtonIcon?: string; // Optional, if provided, and if auxButton is used, this can be used to specify the icon style for the trigger button, if omitted 'more_vert' is used by default
  auxButtonIconClass?: string; // Optional, assigns this class to the mat-icon of auxButton, useful for providing color/style overrides that aren't available via native color property.
  auxButtonTooltip?: string; // Optional, defines the tooltip to show for the button, if omitted none will be used by default.
  hideExpandButton?: boolean; // Optional, indicates whether node expansion button should be hidden
  hidden?: boolean; // Optional, indicates whether node should not be shown (e.g. for filtering list)
}

export interface ListManagerUIState {
  // NOTE: When in readOnlyInput mode, properties of ListManagerUIState will be used for initial configuration of new objects, afterwards any changes to "sections" input for existing sections/nodes will have these properties preserved from local ui state. Note that manipulating the local ui state copy is fine and a recommended approach to achieve any ui/ux desired by these properties.
  open?: boolean; // Optional, only used if enableNodeNavigation is enabled in the component, if true the node is considered open and will show any child nodes under it, otherwise collapsed, if omitted false by default.
  expanded?: boolean; // Optional, false by default. For sections determines the expanded state. Note multiplePanels and multipleSections impact how a user manipulating expanded state will operate.
  spinner?: boolean; // Optional, only used if checkboxPresent is set, if set to true replaces the checkbox with a spinner, see spinnerDisablesEvents input for useful async ui/ux.
  checkboxPresent?: boolean; // Optional. For sections, false by default, if true renders a checkbox under the section header before its nodes tree. For nodes, true by default which causes the node to be contained as a checkbox element, if false the node is simply a text node.
  deleteNodeEnabled?: boolean; // Optional, false by default, determines whether to expose a delete node option in options menu. Note this setting dictates the default createNodeEnabled state for nodes under this object, where individual nodes can override by declaring deleteNodeEnabled state themselves.
  createNodeEnabled?: boolean; // Optional, false by default. For sections, if true displays a following section below the nodes exposing a toggleable create node form, additiona displays an "Add Folder" option to the menu of the nodes group title (if nodesGroupTitleVisible is true). Note this setting dictates the default createNodeEnabled state for nodes under this object, where individual nodes can override by declaring createNodeEnabled state themselves. For nodes, if true enables spawning a form to allow for creating new entries into this element's nodes property. Note that nodes inherit the default createNodeEnabled setting but can use this property to override it.
  createNodeFieldName?: string; // Optional, used in conjuction with createNodeEnabled and is the label for the input field of the embedded form.
  createNodeActionText?: string; // Optional, used in conjuction with createNodeEnabled and is used for the action button text of the embedded form's submit button.
  addNodeMenuText?: string; // Optional, text to display if a create node action is present on the menu, if not supplied "Add Folder" is used by default.
  createNodeSpinner?: boolean; // Optional, used in conjunction with createNodeEnabled, when set to true replaces the form with a spinner, for useful ui/ux for async handling.
  createNodeVisible?: boolean; // Optional, used in conjuction with createNodeEnabled, allows controlling the create node's form expansion state, for useful ui/ux for async handling.
  createNodeValue?: string; // Optional, if specified represents the default value of the create node input field.
  errorCodeVisible?: boolean; // Optional, if true displays a line below the node in red, note if createNodeVisible is true this line is below the input field and causes the normal form button to be hidden, intended to be replaced by errorCodeActionEnabled for any retry action override.
  errorCodeText?: string; // Optional, used in conjunction with errorCodeVisible, if specified sets the text on the process card, otherwise defaults to "An error occurred...".
  errorCodeActionEnabled?: boolean; // Optional, used in conjunction with errorCodeVisible, if true exposes a clickable button.
  errorCodeActionText?: string; // Optional, used in conjunction with errorCodeActionEnabled, if specified sets the text on the action button, otherwise defaults to "Retry".
  customMenuOptions?: MoreOption[]; // Optional, if provided a menu with the provided options will be shown after the node title.
}

@Component({
  selector: 'si-list-manager',
  templateUrl: './list-manager.component.html',
})
export class ListManagerComponent implements OnChanges, AfterViewInit {
  @Input() sections: ListManagerSection[] = []; // Main configuration for ListManagerComponent, defining all sections, settings, nodes, etc.
  @Input() readOnlyInput = false; // If true, the component treats sections input as immutable read only data, thus making a copy of it and operating on an internal version for events. Changes to this input from change detection (which requires spreading a new array into the input) will cause the component to rebuild its internal version by first using the newly passed input, but spreading any ListManagerUIState on sections and nodes from internal state over it.
  @Input() maxNodeDepth = 10; // Defines the maximum depth of nodes that can be rendered (nodes deeper than this will be ignored), governs some functionality around how far down a tree the create node feature will work.
  @Input() spinnerDisablesEvents = true; // Setting that governs whether a node that has a spinner visible should suppress emitting events until the spinner is disabled.
  @Input() multiplePanels = true; // Whether the accordion should allow multiple expanded accordion items simultaneously, useful to allow a section and its root level create accordian to both be open at the same time.
  @Input() multipleSections = false; // Whether to allow multiple section accordians to be expanded at the same time, slightly different than multiplePanels as this means a section being opened will close all accordians not tied to this section.
  @Input() hideCreateNodeWithSectionCollapse = true; // Whether to collapse and hide the create node accordian associated with a collapsed section.
  @Input() errorCodeVisible = false; // If true, displays a process card in an error style state at the top of the component.
  @Input() errorCodeText: string; // Used in conjunction with errorCodeVisible, if specified sets the text on the process card, otherwise defaults to "Failed to load folders".
  @Input() errorCodeActionEnabled = false; // Used in conjunction with errorCodeVisible, if true exposes a clickable button.
  @Input() errorCodeActionText: string; // Used in conjunction with errorCodeActionEnabled, if specified sets the text on the action button, otherwise defaults to "Retry".
  @Input() tight = false; // When true removes margin on the outer form to be inline with the host container
  @Input() hidePanelHeaders = false; // Special use cases only, where headers are hidden and thus panel expanded control can only be done programmatically
  @Input() hideNodeCheckboxes = false; // When true places the entire component in a mode where no checkbox controls are visible, useful when management is desired from other means instead (say from a menu or aux button on the items)
  @Input() enableNodeNavigation = false; // When true, enables tree nodes themselves to be collapsable/expandable, which state is reflected in a corresponding 'open' boolean property (false by default if omitted with this mode on)

  @Output() checkboxClicked = new EventEmitter<{ element: ListManagerSection | ListManagerNode; root: boolean }>(); // Event triggered by a ListManagerSection or ListManagerNode element of checkbox type when the checkbox or its title is clicked.
  @Output() nodeClicked = new EventEmitter<{ element: ListManagerSection | ListManagerNode }>(); // Event triggered by a ListManagerNode element of text type when the title is clicked.
  @Output() createNodeSubmitted = new EventEmitter<{
    element: ListManagerSection | ListManagerNode;
    value: string;
  }>(); // Event triggered by the user submitting the create node form.
  @Output() deleteNode = new EventEmitter<{
    element: ListManagerNode;
    parent: ListManagerSection | ListManagerNode;
  }>(); // Event triggered by the user submitting the create node form.
  @Output() sectionsErrorActionClicked = new EventEmitter(); // Event triggered when the error code action button is clicked for the overall component (not an individual item).
  @Output() nodeErrorActionClicked = new EventEmitter<{
    element: ListManagerSection | ListManagerNode;
    parent: ListManagerSection | ListManagerNode;
    value: string;
  }>(); // Event triggered by the user clicking on the node's error code action button, note value is null if the action was not shown with the context of createNodeVisible.
  @Output() nodeCustomMenuOptionClick = new EventEmitter<{ element: ListManagerNode; option: string }>(); // Event triggered by clicking an option in a node custom menu.
  @Output() nodeOpenToggle = new EventEmitter<{ element: ListManagerNode }>(); // Event triggered with enableNodeNavigation turned on when the open/close button is clicked.

  initialized = false;
  _sections: ListManagerSection[] | null = null;

  ngAfterViewInit() {
    // This causes the smoothest initial rendering behavior, superior to setting the flag and invoking change detection
    setTimeout(() => {
      this.initialized = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.readOnlyInput) {
      if (changes.sections) {
        if (this._sections === null) {
          this._sections = _cloneDeep(this.sections);
        } else {
          this.syncSectionState(_cloneDeep(this.sections), this._sections);
        }
      }
    }
  }

  // This method preserves the old state memory reference when processing changes to keep all existing pointers intact
  syncSectionState(newSections: ListManagerSection[], oldSections: ListManagerSection[]) {
    function syncUIState(
      newElement: ListManagerSection | ListManagerNode,
      oldElement: ListManagerSection | ListManagerNode
    ) {
      // Note that nodes property is skipped here as it is handled by syncNodes
      oldElement.title = newElement.title;
      oldElement.subtitle = newElement.subtitle;
      oldElement.checked = !!newElement.checked;
    }

    function syncNodes(
      newElement: ListManagerSection | ListManagerNode,
      oldElement: ListManagerSection | ListManagerNode
    ) {
      // Ensure any nodes that already exist in the old element are synched, or if they don't exist are added at the correct position
      if (newElement.nodes) {
        for (let i = 0; i < newElement.nodes.length; i++) {
          let newNodeFound = false;

          if (oldElement.nodes) {
            for (let ii = 0; ii < oldElement.nodes.length; ii++) {
              if (newElement.nodes[i].title === oldElement.nodes[ii].title) {
                newNodeFound = true;
                syncUIState(newElement.nodes[i], oldElement.nodes[ii]);
                break;
              }
            }
          } else {
            oldElement.nodes = [];
          }

          if (!newNodeFound) {
            oldElement.nodes.splice(i, 0, newElement.nodes[i]);
          }
        }
      }

      // Ensure that any nodes in the old element which don't exist in the new element are removed
      if (oldElement.nodes) {
        for (let i = 0; i < oldElement.nodes.length; i++) {
          let oldNodeFound = false;

          if (newElement.nodes) {
            for (let ii = 0; ii < newElement.nodes.length; ii++) {
              if (oldElement.nodes[i].title === newElement.nodes[ii].title) {
                oldNodeFound = true;
                break;
              }
            }
          }

          if (!oldNodeFound) {
            oldElement.nodes.splice(i, 1);
          }
        }
      }

      if (newElement.nodes) {
        for (let i = 0; i < newElement.nodes.length; i++) {
          syncNodes(newElement.nodes[i], oldElement.nodes[i]);
        }
      }
    }

    // First add any new sections that aren't found into the appropriate position (this will not be common)
    for (let i = 0; i < newSections.length; i++) {
      let newSectionFound = false;

      for (let ii = 0; ii < oldSections.length; ii++) {
        if (newSections[i].title === oldSections[ii].title) {
          newSectionFound = true;
          syncUIState(newSections[i], oldSections[ii]);
          break;
        }
      }

      if (!newSectionFound) {
        oldSections.splice(i, 0, newSections[i]);
      }
    }

    // Next remove any old sections that no longer exist in the updated configuration (this will not be common)
    for (let i = 0; i < oldSections.length; i++) {
      let oldSectionFound = false;

      for (let ii = 0; ii < newSections.length; ii++) {
        if (oldSections[i].title === newSections[ii].title) {
          oldSectionFound = true;
          break;
        }
      }

      if (!oldSectionFound) {
        oldSections.splice(i, 1);
      }
    }

    // Now ensure all nodes within each section is in sync and preserve prior local ui state on existing nodes
    for (let i = 0; i < newSections.length; i++) {
      syncNodes(newSections[i], oldSections[i]);
    }
  }

  getNodeState(id: string | number): ListManagerNode | null {
    const sectionsPtr = this.readOnlyInput ? this._sections : this.sections;

    function getNodeStateDeep(nodes: ListManagerNode[], id: string | number): ListManagerNode | null {
      for (const node of nodes) {
        if (node.id === id) {
          return node;
        } else if (node.nodes) {
          const retVal = getNodeStateDeep(node.nodes, id);

          if (retVal !== null) {
            return retVal;
          }
        }
      }

      return null;
    }

    for (const section of sectionsPtr) {
      const retVal = getNodeStateDeep(section.nodes, id);

      if (retVal !== null) {
        return retVal;
      }
    }

    return null;
  }

  setNodeState(id: string | number, uiState: Partial<ListManagerUIState>): boolean {
    const sectionsPtr = this.readOnlyInput ? this._sections : this.sections;

    function setNodeStateDeep(
      nodes: ListManagerNode[],
      id: string | number,
      uiState: Partial<ListManagerUIState>
    ): boolean {
      for (const node of nodes) {
        if (node.id === id) {
          // Note, specifically avoiding spreading a new object into node as that will destroy original memory reference thus iterating through each property, priority to new state input if present if not uses original state if present
          node.open = uiState.open ?? node.open;
          node.expanded = uiState.expanded ?? node.expanded;
          node.spinner = uiState.spinner ?? node.spinner;
          node.checkboxPresent = uiState.checkboxPresent ?? node.checkboxPresent;
          node.deleteNodeEnabled = uiState.deleteNodeEnabled ?? node.deleteNodeEnabled;
          node.createNodeEnabled = uiState.createNodeEnabled ?? node.createNodeEnabled;
          node.createNodeFieldName = uiState.createNodeFieldName ?? node.createNodeFieldName;
          node.createNodeActionText = uiState.createNodeActionText ?? node.createNodeActionText;
          node.addNodeMenuText = uiState.addNodeMenuText ?? node.addNodeMenuText;
          node.createNodeSpinner = uiState.createNodeSpinner ?? node.createNodeSpinner;
          node.createNodeVisible = uiState.createNodeVisible ?? node.createNodeVisible;
          node.createNodeValue = uiState.createNodeValue ?? node.createNodeValue;
          node.errorCodeVisible = uiState.errorCodeVisible ?? node.errorCodeVisible;
          node.errorCodeText = uiState.errorCodeText ?? node.errorCodeText;
          node.errorCodeActionEnabled = uiState.errorCodeActionEnabled ?? node.errorCodeActionEnabled;
          node.errorCodeActionText = uiState.errorCodeActionText ?? node.errorCodeActionText;
          node.customMenuOptions = uiState.customMenuOptions ?? node.customMenuOptions;

          return true;
        } else if (node.nodes) {
          const retVal = setNodeStateDeep(node.nodes, id, uiState);

          if (retVal !== false) {
            return true;
          }
        }
      }

      return false;
    }

    for (const section of sectionsPtr) {
      if (section.nodes) {
        const retVal = setNodeStateDeep(section.nodes, id, uiState);

        if (retVal !== false) {
          return true;
        }
      }
    }

    return false;
  }

  onSectionClicked(element: ListManagerSection) {
    if (!this.multipleSections) {
      const stateObject = this.readOnlyInput ? this._sections : this.sections;

      for (const section of stateObject) {
        if (section.title !== element.title) {
          if (section.expanded) {
            section.expanded = false;
          }
          if (section.createNodeVisible) {
            section.createNodeVisible = false;
          }
        }
      }
    }

    if (this.hideCreateNodeWithSectionCollapse && !element.expanded && element.createNodeVisible) {
      element.createNodeVisible = false;
    }
  }

  onCreateHeaderClick(element: ListManagerSection, panel: MatExpansionPanel) {
    if (element.createNodeVisible) {
      element.createNodeValue = ''; // Resets the form value when a spawn action is triggered
      this.onRootAddFolderClick(element, panel);
    }
  }

  onCheckboxClicked(element: ListManagerSection | ListManagerNode, root: boolean) {
    if (!element.spinner) {
      this.checkboxClicked.emit({ element, root });
    }
  }

  onNodeClicked(element: ListManagerSection | ListManagerNode) {
    if (!element.spinner) {
      this.nodeClicked.emit({ element });
    }
  }

  onCreateNodeSubmitCheck(event: KeyboardEvent, element: ListManagerSection | ListManagerNode, value: string) {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      this.onCreateNodeSubmit(element, value);
    }
  }

  onCreateNodeSubmit(element: ListManagerSection | ListManagerNode, value: string) {
    element.errorCodeVisible = false; // Forces a reset of any prior error condition on the element

    this.createNodeSubmitted.emit({ element, value });
  }

  onDeleteNode(element: ListManagerNode, parent: ListManagerSection | ListManagerNode, section: ListManagerSection) {
    element.errorCodeVisible = false; // Forces a reset of any prior error condition on the element
    element.createNodeValue = ''; // Resets the form value when deleting the node
    element.createNodeVisible = false; // Prevents a collision of showing a create form if user decides to delete the node

    this.deleteNode.emit({ element, parent: parent ?? section });
  }

  onCreateNode(element: ListManagerNode, container: HTMLDivElement) {
    element.errorCodeVisible = false; // Forces a reset of any prior error condition on the element
    element.createNodeValue = ''; // Resets the form value when a spawn action is triggered
    element.createNodeVisible = true; // Toggles the create node form on

    const inputElement: Partial<HTMLInputElement> = container.getElementsByClassName('si-list-manager__input')[0];

    this.focusMatInputElement(inputElement);
  }

  onRootAddFolderClick(element: ListManagerSection, panel: MatExpansionPanel) {
    element.errorCodeVisible = false; // Forces a reset of any prior error condition on the element
    element.createNodeValue = ''; // Resets the form value when a spawn action is triggered

    const targetElement = document.getElementById(panel.id);
    const nextPanelElement = targetElement?.parentElement.nextElementSibling;
    const inputElement: Partial<HTMLInputElement> =
      nextPanelElement?.getElementsByClassName('si-list-manager__input')[0];

    this.focusMatInputElement(inputElement);
  }

  focusMatInputElement(inputElement: Partial<HTMLInputElement>) {
    if (inputElement) {
      // One of the only ways to force manipulate focus state on a dynamic input[matInput] element within prerendered template outlets active on the DOM tree
      setTimeout(() => {
        inputElement.blur();
        inputElement.focus();
      }, 50);
    }
  }

  onSectionsErrorActionClicked() {
    this.sectionsErrorActionClicked.emit();
  }

  onNodeErrorActionClicked(
    element: ListManagerSection | ListManagerNode,
    parent: ListManagerSection | ListManagerNode,
    section: ListManagerSection,
    value: string
  ) {
    element.errorCodeVisible = false; // Forces a reset of an error condition after the error action is invoked
    this.nodeErrorActionClicked.emit({ element, parent: parent ?? section, value });
  }

  onNodeCustomMenuOptionClick(element: ListManagerNode, option: string) {
    this.nodeCustomMenuOptionClick.emit({ element, option });
  }

  onNodeOpenToggle(element: ListManagerNode) {
    this.nodeOpenToggle.emit({ element });
  }
}
