import { SortDirection } from '@angular/material/sort';

export interface UserV2 {
  id: string;
  company: {
    companyId: string;
    companyName: string;
  }[];
  country: UserCountry;
  createdDate: string;
  emailAddress: string;
  familyName: string;
  middleName: string;
  modifiedDate: string;
  givenName: string;
  honorific: string;
  jobTitle: string;
  phones: {
    phone: string; // must start with "+" for POST/PUT/PATCH
    type: ApiPhoneTypes;
  }[];
  industries: { name: string }[];
  status: string;
  suffix: string;
  username: string;
  nickname: string;
  userGroups: UserGroupRoles[];
}

export interface UserCountry {
  alpha2: string;
  name: string;
}

export interface UserGroupRoles {
  groupId: string;
  roles: string[];
}

export interface ApiPhone {
  phone: string;
  type: ApiPhoneTypes;
}

export enum ApiPhoneTypes {
  WORK_MOBILE = 'WORK_MOBILE',
  WORK_PHONE = 'WORK_PHONE',
  WORK_FAX = 'WORK_FAX',
  MOBILE = 'MOBILE',
  HOME_PHONE = 'HOME_PHONE',
  HOME_FAX = 'HOME_FAX',
}

export interface UsersGetParams {
  // https://api-awsdev.sae.org/api/docs?urls.primaryName=users-groups-v2#/UsersV2/usersGetV2
  groupAppCode?: string;
  groupId?: string;
  includeGroupInfo?: boolean;
  limit?: number;
  offset?: number;
  search?: string;
  sort?: string; // comma-separated UserV2Sort values
  useSettingsMask?: boolean;
  userId?: string;
}

export interface UserSettingsObject {
  userId?: string;
  settingsId?: string;
  applicationCode: string;
  applicationSettings: { [key: string]: string };
}

/* ----- because the users-groups api expects unusual sort params ----- */
export enum UserV2Sort {
  // api accepts multiple of these and applies them in order
  givenNameAsc = 'givenNameAsc',
  givenNameDesc = 'givenNameDesc',
  familyNameAsc = 'familyNameAsc',
  familyNameDesc = 'familyNameDesc',
  emailAsc = 'emailAsc',
  emailDesc = 'emailDesc',
  userNameAsc = 'userNameAsc',
  userNameDesc = 'userNameDesc',
  relevanceAsc = 'relevanceAsc',
  relevanceDesc = 'relevanceDesc',
}

export enum UserV2SortField {
  // available prefixes
  relevance = 'relevance',
  givenName = 'givenName',
  familyName = 'familyName',
  email = 'email',
  userName = 'userName',
}

export function makeUserV2Sort(field: UserV2SortField, direction: SortDirection): UserV2Sort {
  const output = `${field}${direction.charAt(0).toUpperCase() + direction.slice(1)}`;
  return output as UserV2Sort;
}
