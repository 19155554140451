import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { BrowseByComponent } from './modules/browse-by/browse-by.component';
import { ArticlesComponent } from './modules/detail/components/content-types/articles.component';
import { BookComponent } from './modules/detail/components/content-types/book.component';
import { EdgeReportComponent } from './modules/detail/components/content-types/edge-report.component';
import { MagazineArticleComponent } from './modules/detail/components/content-types/magazine-article.component';
import { MagazineComponent } from './modules/detail/components/content-types/magazine.component';
import { MediaComponent } from './modules/detail/components/content-types/media.component';
import { StandardComponent } from './modules/detail/components/content-types/standard.component';
import { DetailResolver } from './modules/detail/services/detail.resolver';
import { AuthGuard } from './shared/guards/auth.guard';
import { ActiveNavIcon } from './shared/state/session.store';
import { combineLatest, map } from 'rxjs';
import { PeriodicalsComponent } from './modules/detail/components/content-types/periodicals.component';
import { TrainingCoursesComponent } from './modules/detail/components/content-types/training-course.component';
import { SubscriptionsService } from './shared/state/subscriptions.service';
import {
  BrowseByBooksConfig,
  BrowseByCharacteristicsConfig,
  BrowseByEdgeResearchReportsConfig,
  BrowseByElearningConfig,
  BrowseByHomeConfig,
  BrowseByInformationReportsConfig,
  BrowseByInstructorLedConfig,
  BrowseByJournalArticlesConfig,
  BrowseByMagazineArticlesConfig,
  BrowseByMagazineConfig,
  BrowseByMaterialSpecificationsConfig,
  BrowseByRecommendedPracticesConfig,
  BrowseBySpecificationsConfig,
  BrowseByStandardsConfig,
  BrowseBySupplementsConfig,
  BrowseByTechnicalPapersConfig,
  BrowseByTechnicalStandardsConfig,
  BrowseByTopicsConfig,
  BrowseByTrainingCoursesConfig,
  BrowseByJournalsConfig,
  BrowseByHandbookConfig,
  BrowseByNarrativeConfig,
  BrowseByPictureBookConfig,
  BrowseByReferenceConfig,
  BrowseByTextbookConfig,
} from './store/browse/browse.models';
import { PapersComponent } from './modules/detail/components/content-types/papers.component';
import { StartSessionComponent } from './modules/start-session/start-session.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { UserService } from './shared/state/user.service';
import { FolderInvitationComponent } from './modules/folder-invitation/folder-invitation.component';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    data: {
      activeIcon: 'Home' as ActiveNavIcon,
    },
  },
  {
    path: 'browse',
    component: BrowseByComponent,
    data: {
      config: BrowseByHomeConfig,
      activeIcon: 'Browse' as ActiveNavIcon,
    },
    loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
  },
  {
    path: 'mylibrary',
    canActivate: [typeof window !== 'undefined' ? AuthGuard : () => false],
    loadChildren: () => import('./modules/my-library/my-library.module').then((m) => m.MyLibraryModule),
    data: {
      activeIcon: 'My Library' as ActiveNavIcon,
    },
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule),
    data: {
      activeIcon: 'Search' as ActiveNavIcon,
    },
  },
  {
    path: 'version',
    loadChildren: () => import('./modules/version/version.module').then((m) => m.VersionModule),
  },
  {
    path: 'announcements',
    loadChildren: () => import('./modules/announcements/announcement.module').then((m) => m.AnnouncementModule),
    data: {
      activeIcon: 'Messages' as ActiveNavIcon,
    },
  },
  {
    path: 'technical-papers',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByTechnicalPapersConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
    ],
  },
  {
    path: 'journal-articles',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByJournalArticlesConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
    ],
  },
  {
    path: 'journals',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByJournalsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
    ],
  },
  {
    path: 'topics',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByTopicsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: '**',
        component: BrowseByComponent,
        data: {
          config: BrowseByTopicsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
    ],
  },
  {
    path: 'magazine-articles',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByMagazineArticlesConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: ':id',
        component: MagazineArticleComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'committees',
    loadChildren: () => import('./modules/browse/browse.module').then((m) => m.BrowseModule),
    data: {
      activeIcon: 'Browse' as ActiveNavIcon,
    },
  },
  {
    path: 'magazines',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByMagazineConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: ':id',
        component: MagazineComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'shared',
    loadChildren: () => import('./modules/shared/shared.module').then((m) => m.SharedModule),
    data: {
      activeIcon: 'Shared' as ActiveNavIcon,
    },
  },
  {
    path: 'subscription',
    loadChildren: () => import('./modules/subscription/subscription.module').then((m) => m.SubscriptionModule),
    canActivate: [
      (): unknown => {
        const router = inject(Router);
        const subService = inject(SubscriptionsService);
        const userService = inject(UserService);
        return combineLatest([subService.subscriptions$, userService.isCurrentUserAdminOnSubV2()]).pipe(
          map(([subscriptions, isUserAdminOnSub]) =>
            subscriptions.length > 0 || isUserAdminOnSub ? true : router.navigateByUrl('/')
          )
        );
      },
    ],
    data: {
      activeIcon: 'Subscription' as ActiveNavIcon,
    },
  },
  {
    path: 'articles',
    children: [
      {
        path: ':id',
        component: ArticlesComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'books',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByBooksConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'handbook',
        component: BrowseByComponent,
        data: {
          config: BrowseByHandbookConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'narrative',
        component: BrowseByComponent,
        data: {
          config: BrowseByNarrativeConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'picture-book',
        component: BrowseByComponent,
        data: {
          config: BrowseByPictureBookConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'reference',
        component: BrowseByComponent,
        data: {
          config: BrowseByReferenceConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'textbook',
        component: BrowseByComponent,
        data: {
          config: BrowseByTextbookConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: ':id',
        component: BookComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'courses',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByTrainingCoursesConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'instructor-led',
        component: BrowseByComponent,
        data: {
          config: BrowseByInstructorLedConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'elearning',
        component: BrowseByComponent,
        data: {
          config: BrowseByElearningConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: ':id',
        component: TrainingCoursesComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'media',
    children: [
      {
        path: ':id',
        component: MediaComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'papers',
    children: [
      {
        path: ':id',
        component: PapersComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'periodicals',
    children: [
      {
        path: ':id',
        component: PeriodicalsComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'reports',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByEdgeResearchReportsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: ':id',
        component: EdgeReportComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'standards',
    children: [
      {
        path: '',
        component: BrowseByComponent,
        data: {
          config: BrowseByStandardsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'characteristics',
        component: BrowseByComponent,
        data: {
          config: BrowseByCharacteristicsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'information-reports',
        component: BrowseByComponent,
        data: {
          config: BrowseByInformationReportsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'material-specifications',
        component: BrowseByComponent,
        data: {
          config: BrowseByMaterialSpecificationsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'recommended-practices',
        component: BrowseByComponent,
        data: {
          config: BrowseByRecommendedPracticesConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'specifications',
        component: BrowseByComponent,
        data: {
          config: BrowseBySpecificationsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'supplements',
        component: BrowseByComponent,
        data: {
          config: BrowseBySupplementsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: 'technical-standards',
        component: BrowseByComponent,
        data: {
          config: BrowseByTechnicalStandardsConfig,
          activeIcon: 'Browse' as ActiveNavIcon,
        },
        loadChildren: () => import('./modules/browse-by/browse-by.module').then((m) => m.BrowseByModule),
      },
      {
        path: ':id',
        component: StandardComponent,
        resolve: { content: DetailResolver },
        loadChildren: () => import('./modules/detail/detail.module').then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: 'help',
    data: {
      activeIcon: 'Help' as ActiveNavIcon,
    },
    loadChildren: () => import('./modules/help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'start-session',
    component: StartSessionComponent,
  },
  {
    path: 'institutional-access-signup',
    loadChildren: () =>
      import('./modules/sso-subscription-signup/sso-subscription-signup.module').then(
        (m) => m.SsoSubscriptionSignupModule
      ),
  },
  {
    path: 'user-profile',
    canActivate: [typeof window !== 'undefined' ? AuthGuard : () => false],
    loadChildren: () =>
      import('./modules/profile-management/profile-management.module').then((m) => m.ProfileManagementModule),
    data: {
      activeIcon: 'Help' as ActiveNavIcon,
    },
  },
  {
    path: 'battery-cell',
    canActivate: [typeof window !== 'undefined' ? AuthGuard : () => false],
    loadChildren: () => import('./modules/battery-cell/battery-cell.module').then((m) => m.BatteryCellModule),
    data: {
      activeIcon: 'Data' as ActiveNavIcon,
    },
  },
  {
    path: 'invitation/:invitationId',
    canActivate: [typeof window !== 'undefined' ? AuthGuard : () => false],
    component: FolderInvitationComponent,
    data: {
      activeIcon: 'My Library' as ActiveNavIcon,
    },
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
