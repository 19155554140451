<ng-container *ngIf="isGroup">
  <si-criteria-group
    [facets]="facets"
    [level]="level"
    [criteriaGroup]="row"
    [showAltClass]="altClass"
    [indexNum]="indexNum"
    (groupRemoved)="onRemoveRow($event)"
  ></si-criteria-group>
</ng-container>
<ng-container *ngIf="!isGroup">
  <si-criteria-row
    class="si-fieldset si-t--row"
    [facets]="facets"
    [level]="level"
    [row]="row"
    [indexNum]="indexNum"
    (rowAdded)="onAddRow($event)"
    (rowRemoved)="onRemoveRow($event)"
    (groupAdded)="onAddGroup($event)"
    [showButton]="showButton"
    [advSearchConditionConfig]="advSearchConditionConfig"
    [autocompleteKeyword]="autocompleteKeyword"
    [class.si-child]="level === 1"
    [class.si-gchild]="level === 2"
    [class.si-ggchild]="level === 3"
    [class.si-gggchild]="level === 4"
    [class.si-ggggchild]="level === 5"
    [class.si-gggggchild]="level === 6"
    [class.si-ggggggchild]="level > 6"
    [class.si-state--alt]="altClass"
  ></si-criteria-row>
</ng-container>
