import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MoreOption } from '@sae/models';
import { MatLegacyMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'si-more-menu',
  templateUrl: './more-menu.component.html',
})
export class MoreMenuComponent implements OnChanges {
  @Input() options!: MoreOption[]; // List of options to display in the menu, see MoreOption interface definition for more details.
  @Input() additionalOptions: MoreOption[]; // Special list of additional options, when provided these are appended to the options input to make the full list of options.
  @Input() label: string; // Optional, if specified shows this text label preceeding rendering any options.
  @Input() isAudit = false; // Optional, if true sets a special class to anchor the menu using si-icon--audit additions.
  @Input() isHeader = false; // Optional, if true sets a special class to anchor the menu using si-icon--header additions.
  @Input() menuIcon = 'more_vert'; // Optional, enables overriding the default icon used for the menu button
  @Input() onlyRenderOptions = false; // Special use case rendering option, where only the inner options of the mat-menu are outputted by the component, such that these options can be injected in other menu providers.

  @Output() optionSelected = new EventEmitter<string>(); // Event triggered by clicking on an option in the menu, containing the name of the option.

  @ViewChild(MatLegacyMenuTrigger) matMenuTrigger: MatLegacyMenuTrigger;

  _options: MoreOption[] = [];

  ngOnChanges(): void {
    this.buildOptions();
  }

  public buildOptions(): void {
    let newOptions: MoreOption[] = [];

    if (this.options?.length > 0) {
      newOptions = [...newOptions, ...this.options];
    }

    if (this.additionalOptions?.length > 0) {
      newOptions = [...newOptions, ...this.additionalOptions];
    }

    this._options = [...newOptions];
  }

  public selectedOption(sel: MoreOption, event: MouseEvent = null): void {
    if (sel.preventDefault && event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.optionSelected.emit(sel.name);
  }

  public closeMenu(): void {
    this.matMenuTrigger?.closeMenu();
  }
}
