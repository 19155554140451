<div [id]="annotationBufferId" class="si-annotated--buffer"></div>
<div
  class="si-annotated"
  [class.si-contains--card]="cardWrappedContent"
  (selectstart)="onSelectStart()"
  (mouseup)="onSelectEnd()"
  (mouseleave)="onSelectCancel()"
>
  <ng-container *ngFor="let bookmark of bookmarks">
    <ng-container *ngIf="!bookmark.isGroup">
      <div
        [id]="bookmark.id ? annotationBookmarkIdBase + bookmark.id : null"
        [style.top]="bookmark.offset"
        class="si-button--annotation__wrap"
        [class.si-unusual]="cardWrappedContent"
        (mouseenter)="onShowBookmark(bookmark)"
        (mouseleave)="onHideBookmark(bookmark)"
        (click)="onClickBookmark(bookmark)"
      >
        <button
          mat-button
          [matTooltip]="
            maxTooltipSize > 0
              ? bookmark.text.length > maxTooltipSize
                ? bookmark.text.substr(0, maxTooltipSize) + '...'
                : bookmark.text
              : bookmark.text
          "
          [matTooltipPosition]="'left'"
          [matTooltipHideDelay]="0"
          [attr.aria-label]="'Annotation ' + bookmark.label"
          class="si-button si-button--annotation"
        >
          <span>
            {{ bookmark.label }}
          </span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="bookmark.isGroup">
      <div
        [id]="bookmark.id ? annotationBookmarkIdBase + bookmark.id : null"
        [style.top]="bookmark.offset"
        class="si-button--annotation__wrap"
        [class.si-unusual]="cardWrappedContent"
        (mouseenter)="onShowGroupBookmark(bookmark)"
        (mouseleave)="onHideGroupBookmark(bookmark)"
        [matMenuTriggerFor]="groupList"
      >
        <button
          mat-button
          class="si-button si-button--annotation"
          [attr.aria-label]="bookmark.label"
          [matTooltip]="bookmark.label"
          [matTooltipPosition]="'left'"
          [matTooltipHideDelay]="0"
        >
          <span *ngIf="+bookmark.groupBookmarks[0].label < 100">{{ bookmark.groupBookmarks[0].label }}+</span>
          <span *ngIf="+bookmark.groupBookmarks[0].label > 99">...</span>
        </button>
      </div>
      <mat-menu #groupList="matMenu" class="si-menu">
        <ng-container *ngFor="let groupBookmark of bookmark.groupBookmarks">
          <button
            mat-menu-item
            (mouseenter)="onShowBookmark(groupBookmark, true)"
            (mouseleave)="onHideBookmark(groupBookmark, true)"
            (click)="onClickBookmark(groupBookmark)"
            [matTooltip]="
              maxTooltipSize > 0
                ? groupBookmark.text.length > maxTooltipSize
                  ? groupBookmark.text.substr(0, maxTooltipSize) + '...'
                  : groupBookmark.text
                : groupBookmark.text
            "
            [matTooltipPosition]="'left'"
            [matTooltipHideDelay]="0"
            [attr.aria-label]="'Annotation ' + groupBookmark.label"
          >
            <button
              mat-menu-item
              class="si-button si-button--annotation si-button--annotation__menu-item"
              [class.si-state--selected]="showingBookmarkId === groupBookmark.id"
            >
              <span>{{ groupBookmark.label }}</span>
            </button>
            <span>{{ groupBookmark.quote }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </ng-container>
  <div [id]="annotationContentId">
    <ng-content></ng-content>
  </div>
</div>
<div
  *ngIf="annotations?.length > 0"
  class="si-annotated si-annotated--previews"
  [class.si-contains--card]="cardWrappedContent"
>
  <div>
    <mat-expansion-panel class="si-expanel si-dataviewer si-expanel--mobile-indicator" [expanded]="expandPreviewPanel">
      <mat-expansion-panel-header class="si-expanel__h si-annotation--header">
        <mat-panel-title class="si-expanel__title">
          <h2>
            <strong>Annotations</strong>
          </h2>
        </mat-panel-title>
        <span class="si-expanse"></span>
        <button
          mat-icon-button
          class="si-icon si-icon--more"
          color="primary"
          matTooltip="Print Annotations"
          aria-label="Print Annotations"
          (click)="$event.stopPropagation(); printAnnotations()"
        >
          <mat-icon>print</mat-icon>
        </button>
      </mat-expansion-panel-header>
      <div class="si-expanel__c">
        <div class="si-card--dataviewer__data si-tight">
          <ng-container *ngFor="let bookmark of bookmarks">
            <ng-container *ngIf="!bookmark.isGroup">
              <ng-container *ngTemplateOutlet="bookmarkPreviewTemplate; context: { bookmark: bookmark }"></ng-container>
            </ng-container>
            <ng-container *ngIf="bookmark.isGroup">
              <ng-container *ngFor="let groupBookmark of bookmark.groupBookmarks">
                <ng-container
                  *ngTemplateOutlet="bookmarkPreviewTemplate; context: { bookmark: groupBookmark }"
                ></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>

<ng-template #bookmarkPreviewTemplate let-bookmark="bookmark">
  <mat-card class="si-card si-card--annotation mat-elevation-z2">
    <div class="si-card--annotation__container si-visible--below-bp-12">
      <div>
        <div>
          <div>
            <ng-container *ngTemplateOutlet="bookmarkLabelTemplate; context: { bookmark: bookmark }"></ng-container>
          </div>
          <div class="si-card--annotation__column"></div>
        </div>
      </div>
      <div>
        <div>
          <div class="si-card--annotation__column"></div>
          <div class="si-card--annotation__action">
            <ng-container
              *ngTemplateOutlet="bookmarkEditButtonTemplate; context: { bookmark: bookmark }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="si-card--annotation__container si-card--annotation__stack">
      <div>
        <div>
          <div class="si-card--annotation__label si-visible--beyond-bp-12">
            <ng-container *ngTemplateOutlet="bookmarkLabelTemplate; context: { bookmark: bookmark }"></ng-container>
          </div>
          <div class="si-card--annotation__column">
            <ng-container *ngTemplateOutlet="bookmarkCommentTemplate; context: { bookmark: bookmark }"></ng-container>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="si-card--annotation__column">
            <ng-container
              *ngTemplateOutlet="bookmarkAnnotationTextTemplate; context: { bookmark: bookmark }"
            ></ng-container>
          </div>
          <div class="si-card--annotation__action si-visible--beyond-bp-12">
            <ng-container
              *ngTemplateOutlet="bookmarkEditButtonTemplate; context: { bookmark: bookmark }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</ng-template>

<ng-template #bookmarkLabelTemplate let-bookmark="bookmark">
  <strong>{{ bookmark.label }}</strong>
</ng-template>

<ng-template #bookmarkEditButtonTemplate let-bookmark="bookmark">
  <button
    mat-icon-button
    class="si-icon"
    color="primary"
    (click)="onClickBookmark(bookmark)"
    matTooltip="Edit"
    aria-label="Edit"
  >
    <mat-icon>edit</mat-icon>
  </button>
</ng-template>

<ng-template #bookmarkCommentTemplate let-bookmark="bookmark">
  <si-data-output class="si-data si-tight" [title]="'Comment'">
    <div>{{ bookmark.text }}</div>
  </si-data-output>
</ng-template>

<ng-template #bookmarkAnnotationTextTemplate let-bookmark="bookmark">
  <si-data-output class="si-data si-tight" [title]="'Annotated Text'">
    <div class="si-mark--annotation si-dialog__text">{{ bookmark.quote }}</div>
  </si-data-output>
</ng-template>
