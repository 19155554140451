import { Injectable } from '@angular/core';
import { SessionCoreQuery } from '@sae/services';
import { AppSessionState, SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class AppSessionQuery extends SessionCoreQuery<AppSessionState> {
  constructor(protected store: SessionStore) {
    super(store);
  }
}
