import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonItem } from '../../parts/part-button/part-button.component';
import { DividerItem, PartDividerComponent } from '../../parts/part-divider/part-divider.component';
import { ChipItem } from '../../compounds/comp-chip/comp-chip.component';
import { DataItem, UIDataItemComponent } from '../ui-data-item/ui-data-item.component';
import { PartContainerComponent, ContainerContent } from '../../parts/part-container/part-container.component';
import { overrideInputs } from '@sae/base';

export interface DataGridContent {
  classes?: string;
  halfGap?: boolean;
  isList?: boolean;
  itemContainer?: ContainerContent;
  itemDivider?: DividerItem;
  items?: DataItem[];
  simpleFlex?: boolean;
}

@Component({
  selector: 'fs-ui-data-grid',
  styleUrls: ['./ui-data-grid.component.scss'],
  templateUrl: './ui-data-grid.component.html',
  imports: [CommonModule, PartContainerComponent, PartDividerComponent, UIDataItemComponent],
  standalone: true,
})
export class UIDataGridComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: DataGridContent;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string; // Optional, provides a CSS class for the output wrapper
  @Input() simpleFlex: boolean; // Optional, if true uses flexbox with wrapping and css gap
  @Input() halfGap: boolean; // Optional, reduces normal gap by half beteween items
  @Input() isList: boolean; // Optional, set to true if the items are in a list. Used for being in master or aux column
  @Input() itemContainer: ContainerContent; // Optional, provides a container for each item
  @Input() itemDivider: DividerItem; // Optional, adds a divider between items
  @Input() items: DataItem[] = [];
  @Input() content: DataGridContent; // [DEPRECATED - Will be removed] Optional, provides a configuration object for the component

  @Output() buttonClick = new EventEmitter<ButtonItem>(); // Event emitted when button is clicked
  @Output() chipClick = new EventEmitter<ChipItem>(); // Event emitted when chip is clicked
  @Output() chipRemoveClick = new EventEmitter<ChipItem>(); // Event emitted when chip remove button is clicked

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['content']) {
      overrideInputs(this, this.content);
    }
  }

  onButtonClick(button: ButtonItem): void {
    this.buttonClick.emit(button);
  }

  onChipClick(chip: ChipItem): void {
    this.chipClick.emit(chip);
  }

  onRemoveChipClick(chip: ChipItem): void {
    this.chipRemoveClick.emit(chip);
  }
}
