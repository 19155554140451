import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MobilusPubDocument } from '../../models/api-models';
import { SeoChiplistDto } from '@sae/components';

export interface MetaTagChipEvent {
  type: 'INDUSTRY' | 'TOPIC' | 'TYPE' | 'AFFILIATION' | 'AUTHOR';
  value: string;
}

@Component({
  selector: 'mobi-meta-tags',
  templateUrl: './meta-tags.component.html',
})
export class MetaTagsComponent {
  private _publicationDocuments: MobilusPubDocument[];

  public baseURL = '';

  public industries: SeoChiplistDto[] = [];
  public topics: SeoChiplistDto[] = [];
  public types: SeoChiplistDto[] = [];
  public affiliations: SeoChiplistDto[] = [];
  public authors: SeoChiplistDto[] = [];

  get publicationDocuments(): MobilusPubDocument[] {
    return this.publicationDocuments;
  }

  @Input() set publicationDocuments(pubDocuments: MobilusPubDocument[]) {
    this._publicationDocuments = pubDocuments;
    const uniqueIndustries = pubDocuments
      .map((pubDocument) => pubDocument.industrysectors_name?.raw)
      .flat(1)
      .filter((ui) => !!ui);
    this.industries = [...new Set(uniqueIndustries)]
      .map((uniqueIndustry) => ({
        label: uniqueIndustry,
        clickEventEmitter: true,
        tooltip: `Browse ${uniqueIndustry} Content`,
      }))
      .slice(0, 20);

    this.topics = [
      ...new Set(
        pubDocuments
          .map((pubDocument) => pubDocument.topics?.raw)
          .flat(1)
          .filter((topic) => !!topic)
      ),
    ]
      .map((topic) => ({
        label: topic,
        clickEventEmitter: true,
        tooltip: `Browse ${topic} Content`,
      }))
      .slice(0, 20);

    this.types = [
      ...new Set(
        pubDocuments
          .map((pubDocument) => pubDocument.sub_group?.raw)
          .flat(1)
          .filter((type) => !!type)
      ),
    ]
      .map((subGroup) => ({
        label: subGroup,
        clickEventEmitter: true,
        tooltip: `Browse ${subGroup} Content`,
      }))
      .slice(0, 20);

    this.affiliations = [
      ...new Set(
        pubDocuments
          .map((pubDocument) => pubDocument.affiliations?.raw)
          .flat(1)
          .filter((a) => !!a)
      ),
    ]
      .map((affiliation) => ({
        label: affiliation,
        clickEventEmitter: true,
        tooltip: `Browse ${affiliation} Content`,
      }))
      .slice(0, 20);

    this.authors = [
      ...new Set(
        pubDocuments
          .map((pubDocument) => pubDocument.authors?.raw)
          .flat(1)
          .filter((a) => !!a)
      ),
    ]
      .map((author) => ({
        label: author,
        clickEventEmitter: true,
        tooltip: `Browse ${author} Content`,
      }))
      .slice(0, 20);

    this._publicationDocuments = pubDocuments;
    //
  }

  @Output() chipClicked = new EventEmitter<MetaTagChipEvent>();
}
