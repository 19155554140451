<mat-menu #fileFormatMenu="matMenu" class="si-menu si-menu--detail">
  <label class="si-label">File Format{{ assets?.length > 1 ? 's' : '' }}</label>
  <ng-container *ngIf="!loading; else isLoading">
    <a
      *ngFor="let asset of assets"
      mat-menu-item
      class="si-button--detailed"
      [href]="getDownloadLink(asset)"
      [downloadFile]="getDownloadLink(asset)"
      fileName="{{ asset.filename }}"
      (fileDownloaded)="fileDownloaded.emit(asset)"
    >
      <mat-icon aria-hidden="true" [color]="hasAccess ? 'primary' : null" [class.si-state--locked]="!hasAccess">
        insert_drive_file
      </mat-icon>
      {{ asset.fileType | uppercase }}
    </a>
    <ng-container *ngIf="assets?.length === 0">
      <si-process-card
        class="si-component"
        type="error"
        titleText="Error loading file formats"
        action="Retry"
        actionIcon="refresh"
        [options]="[{ icon: 'mail', name: 'Contact Customer Service' }]"
        (actionSelected)="retry.emit()"
      ></si-process-card>
    </ng-container>
  </ng-container>
  <ng-template #isLoading>
    <div class="si-spinner__container si-minor">
      <mat-spinner diameter="40" class="si-spinner"></mat-spinner>
    </div>
  </ng-template>
</mat-menu>
