<ng-container
  *ngIf="{
    getFoldersAndBookmarksLoading: getFoldersAndBookmarksLoading$ | async,
    getFoldersAndBookmarksError: getFoldersAndBookmarksError$ | async
  } as _"
>
  <div class="si-dialog" [class.si-contains--expanel]="true">
    <div class="si-dialog__h" [class.si-tight]="true">
      <h2 class="si-dialog__title">Add To</h2>
      <span class="si-expanse"></span>
      <button mat-icon-button matTooltip="Close" aria-label="Close" class="si-icon si-button--close" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="si-dialog__b">
      <div mat-dialog-content class="si-dialog__c">
        <ng-container [ngTemplateOutlet]="_.getFoldersAndBookmarksLoading ? loading : result"></ng-container>
        <ng-template #result>
          <si-process-card
            *ngIf="_.getFoldersAndBookmarksError"
            class="si-component"
            type="error"
            [titleText]="'Something went wrong. Please try again.'"
            action="Retry"
            actionIcon="refresh"
            (actionSelected)="processAction()"
            (optionSelected)="processAction()"
            [options]="[]"
          ></si-process-card>
          <si-list-manager
            *ngIf="!_.getFoldersAndBookmarksError"
            [sections]="listManagerData"
            [maxNodeDepth]="1"
            (checkboxClicked)="checkboxClicked($event)"
            (nodeClicked)="nodeClicked($event)"
            (nodeErrorActionClicked)="nodeErrorActionClicked($event)"
            (createNodeSubmitted)="createNodeSubmitted($event)"
            (deleteNode)="deleteNode($event)"
          ></si-list-manager>
        </ng-template>
        <ng-template #loading>
          <div class="si-spinner__container">
            <mat-spinner class="si-spinner"></mat-spinner>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
