import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { RangeFacetData } from '../../models/api-models';
import { UISearchFacet } from '../../models/common-interfaces';

@UntilDestroy()
@Component({
  selector: 'mobi-pubdate-facet-expanel',
  templateUrl: './pubdate-facet-expanel.component.html',
})
export class PubdateFacetExpanelComponent implements OnInit {
  private _filters: { to?: string; from?: string };
  @Input() facet: UISearchFacet<RangeFacetData>;
  @Input() set filters(filters: { to?: string; from?: string }) {
    this._filters = filters ?? {};
  }
  get filters(): { to?: string; from?: string } {
    return this._filters;
  }
  @Input() isPreservedFacet = false;
  @Input() isPubDateFilterApplied = false;
  @Input() pubDateTo: Date;
  @Input() pubDateFrom: Date;
  @Output() applyFilterClicked = new EventEmitter();
  @Output() removeFilterClicked = new EventEmitter();
  @Output() clearPreservedFacetClicked = new EventEmitter();
  @Output() expandAggregationClicked = new EventEmitter();
  @Output() collapseAggregationClicked = new EventEmitter();
  @Output() dateChanged = new EventEmitter();
  @Output() dateFromChanged = new EventEmitter();
  @Output() dateToChanged = new EventEmitter();

  panelExpand = false;
  selectedCount = 0;
  debounceTime = 450;
  currentDate = new Date();
  dateFrom = new FormControl();
  dateTo = new FormControl();

  constructor(private store: Store) {}

  ngOnInit(): void {
    const curDate = new Date();
    this.dateFrom.setValue(this.pubDateFrom ?? new Date(new Date(curDate).setFullYear(curDate.getFullYear() - 5)));
    this.dateTo.setValue(this.pubDateTo ?? curDate);

    this.dateFrom.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          if (this.dateFrom.invalid) {
            this.dateFrom.markAsTouched();
          } else {
            this.toggleSelection(true);
          }
        })
      )
      .subscribe();

    this.dateTo.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          if (this.dateTo.invalid) {
            this.dateTo.markAsTouched();
          } else {
            this.toggleSelection(true);
          }
        })
      )
      .subscribe();
  }

  toggleSelection(checked: boolean): void {
    if (this.dateFrom.valid && this.dateTo.valid) {
      if (checked) {
        this.applyFilterClicked.emit([this.dateFrom.value, this.dateTo.value]);
      } else if (checked !== undefined) {
        this.removeFilterClicked.emit();
      }
    }
  }

  clearPreservedFacet(): void {
    this.clearPreservedFacetClicked.emit();
  }

  expandThisAggregation(alreadyExpanded: boolean): void {
    if (!alreadyExpanded) {
      this.expandAggregationClicked.emit();
    }
  }

  collapseThisAggregation(alreadyCollapsed: boolean): void {
    if (!alreadyCollapsed) {
      this.collapseAggregationClicked.emit();
    }
  }
}
