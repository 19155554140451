<ng-container *ngIf="state === 'summary'">
  <header class="si-content__h si-content__h--cart">
    <mat-icon class="si-icon" color="primary">shopping_cart</mat-icon>
    <h1 class="si-content__title si-impact">
      Cart
      <span class="si-count">({{ totalItems }} Items)</span>
    </h1>
  </header>
  <div class="si-content__c">
    <ng-container *ngTemplateOutlet="cartItemsTemplate; context: { items: cartDetails.items }"></ng-container>
    <ng-container *ngTemplateOutlet="promoCodeTemplate"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="state === 'checkout'">
  <header class="si-content__h si-content__h--cart">
    <h1 class="si-content__title si-impact">
      Checkout
      <span class="si-count">({{ totalItems }} Items)</span>
    </h1>
  </header>
  <div class="si-content__c">
    <ng-container *ngIf="!cartDetails.items || cartDetails.items.length === 0">
      <si-none-card class="si-component" content="Shopping cart is empty."></si-none-card>
    </ng-container>
    <ng-container *ngIf="cartDetails.items?.length > 0">
      <mat-vertical-stepper #stepper class="si-stepper si-stepper--vert" [linear]="true">
        <mat-step [stepControl]="billingFormGroup" class="si-step">
          <form [formGroup]="billingFormGroup" class="si-form" enctype="multipart/form-data">
            <ng-template matStepLabel>Billing</ng-template>
            <div class="si-form__b">
              <ng-container *ngTemplateOutlet="promoCodeTemplate"></ng-container>
              <div class="si-wrap si-wrap--card">
                <mat-card class="si-card">
                  <mat-form-field class="si-field si-wd si-wd-fourtenth">
                    <mat-label class="si-label">Given (First) Name</mat-label>
                    <input matInput class="si-input" formControlName="firstName" required />
                    <mat-error class="si-field__error">Given (First) Name is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-twotenth">
                    <mat-label class="si-label">Middle Initial</mat-label>
                    <input matInput class="si-input" formControlName="middleInitial" />
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-fourtenth">
                    <mat-label class="si-label">Family (Last) Name</mat-label>
                    <input matInput class="si-input" formControlName="lastName" required />
                    <mat-error class="si-field__error">Family (Last) Name is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-fourth">
                    <mat-label class="si-label">Employed?</mat-label>
                    <mat-select matNativeControl class="si-select" formControlName="employed" required #employed>
                      <mat-option value=""></mat-option>
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                    <mat-error class="si-field__error">Is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-threefourth" *ngIf="employed.value === 'Yes'">
                    <mat-label class="si-label">Company</mat-label>
                    <input matInput class="si-input" formControlName="company" />
                  </mat-form-field>
                  <mat-divider class="si-divider si-divider--simple"></mat-divider>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Street</mat-label>
                    <input matInput class="si-input" formControlName="street1" required />
                    <mat-error class="si-field__error">Street is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Street 2</mat-label>
                    <input matInput class="si-input" formControlName="street2" />
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Country</mat-label>
                    <mat-select matNativeControl class="si-select" formControlName="country" required>
                      <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode.value">
                        {{ countryCode.label }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="si-field__error">Country is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">State/Province</mat-label>
                    <input matInput class="si-input" required formControlName="state" />
                    <mat-error class="si-field__error">State/Province is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">City</mat-label>
                    <input matInput class="si-input" required formControlName="city" />
                    <mat-error class="si-field__error">City is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Postal</mat-label>
                    <input matInput class="si-input" required formControlName="postal" />
                    <mat-error class="si-field__error">Postal is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-full">
                    <mat-label class="si-label">Email</mat-label>
                    <input matInput class="si-input" formControlName="email" required />
                    <mat-error class="si-field__error">Valid email is required</mat-error>
                  </mat-form-field>
                  <div *ngIf="collectShipping" class="si-field si-wd-full">
                    <div class="mat-form-field-wrapper">
                      <div class="si-input">
                        <mat-checkbox
                          class="si-checkbox"
                          formControlName="shippingSameAsBilling"
                          (change)="onChangeShippingSameAsBilling($event)"
                        >
                          Shipping same as billing?
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div class="si-form__a">
              <button
                mat-button
                matStepperNext
                class="si-button"
                [class.si-button--proceed]="billingFormGroup.valid"
                [class.si-state--disabled]="!billingFormGroup.valid"
                [disabled]="!billingFormGroup.valid"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step *ngIf="collectShipping && showShippingForm" [stepControl]="shippingFormGroup" class="si-step">
          <form [formGroup]="shippingFormGroup" class="si-form" enctype="multipart/form-data">
            <ng-template matStepLabel>Shipping</ng-template>
            <div class="si-form__b">
              <div class="si-wrap si-wrap--card">
                <mat-card class="si-card">
                  <mat-form-field class="si-field si-wd si-wd-fourtenth">
                    <mat-label class="si-label">Given (First) Name</mat-label>
                    <input matInput class="si-input" formControlName="firstName" required />
                    <mat-error class="si-field__error">Given (First) Name is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-twotenth">
                    <mat-label class="si-label">Middle Initial</mat-label>
                    <input matInput class="si-input" formControlName="middleInitial" />
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-fourtenth">
                    <mat-label class="si-label">Family (Last) Name</mat-label>
                    <input matInput class="si-input" formControlName="lastName" required />
                    <mat-error class="si-field__error">Family (Last) Name is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-fourth">
                    <mat-label class="si-label">Employed?</mat-label>
                    <mat-select matNativeControl class="si-select" formControlName="employed" required #employed>
                      <mat-option value=""></mat-option>
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                    <mat-error class="si-field__error">Is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-threefourth" *ngIf="employed.value === 'Yes'">
                    <mat-label class="si-label">Company</mat-label>
                    <input matInput class="si-input" formControlName="company" />
                  </mat-form-field>
                  <mat-divider class="si-divider si-divider--simple"></mat-divider>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Street</mat-label>
                    <input matInput class="si-input" formControlName="street1" required />
                    <mat-error class="si-field__error">Street is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Street 2</mat-label>
                    <input matInput class="si-input" formControlName="street2" />
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Country</mat-label>
                    <mat-select matNativeControl class="si-select" formControlName="country" required>
                      <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode.value">
                        {{ countryCode.label }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="si-field__error">Country is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">State/Province</mat-label>
                    <input matInput class="si-input" required formControlName="state" />
                    <mat-error class="si-field__error">State/Province is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">City</mat-label>
                    <input matInput class="si-input" required formControlName="city" />
                    <mat-error class="si-field__error">City is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-half">
                    <mat-label class="si-label">Postal</mat-label>
                    <input matInput class="si-input" required formControlName="postal" />
                    <mat-error class="si-field__error">Postal is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="si-field si-wd si-wd-full">
                    <mat-label class="si-label">Email</mat-label>
                    <input matInput class="si-input" formControlName="email" required />
                    <mat-error class="si-field__error">Valid email is required</mat-error>
                  </mat-form-field>
                </mat-card>
              </div>
            </div>
            <div class="si-form__a">
              <button mat-button matStepperPrevious class="si-button si-button--retreat">Back</button>
              <button
                mat-button
                matStepperNext
                class="si-button"
                [class.si-button--proceed]="shippingFormGroup.valid"
                [class.si-state--disabled]="!shippingFormGroup.valid"
                [disabled]="!shippingFormGroup.valid"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="reviewItemsFormGroup" class="si-step">
          <form [formGroup]="reviewItemsFormGroup" class="si-form">
            <ng-template matStepLabel>Review Items</ng-template>
            <div class="si-form__b">
              <div class="si-field">
                <ng-container
                  *ngTemplateOutlet="cartItemsTemplate; context: { items: cartDetails.items }"
                ></ng-container>
              </div>
            </div>
            <div class="si-form__a">
              <button mat-button matStepperPrevious class="si-button si-button--retreat">Back</button>
              <button mat-button matStepperNext class="si-button si-button--proceed">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="paymentFormGroup" class="si-step">
          <form [formGroup]="paymentFormGroup" class="si-form">
            <ng-template matStepLabel>Payment</ng-template>
            <div class="si-form__b">
              <si-read-only-field class="si-wd si-wd-fourth" label="Order Total (USD)">
                <ng-container
                  *ngTemplateOutlet="currencyTemplate; context: { value: cartDetails.orderTotal }"
                ></ng-container>
              </si-read-only-field>
              <mat-form-field class="si-field si-wd si-wd-threefourth">
                <mat-label class="si-label">Credit Card Number</mat-label>
                <input matInput class="si-input" maxlength="16" formControlName="creditCardNumber" />
                <mat-error class="si-field__error">Invalid Valid Credit Card Number</mat-error>
              </mat-form-field>
              <mat-form-field class="si-field si-wd si-wd-fourth">
                <mat-label class="si-label">Expiration Month</mat-label>
                <mat-select
                  matNativeControl
                  class="si-select"
                  formControlName="expirationMonth"
                  [errorStateMatcher]="creditCardExpirationDateErrorStateMatcher"
                >
                  <mat-option [value]="1">01</mat-option>
                  <mat-option [value]="2">02</mat-option>
                  <mat-option [value]="3">03</mat-option>
                  <mat-option [value]="4">04</mat-option>
                  <mat-option [value]="5">05</mat-option>
                  <mat-option [value]="6">06</mat-option>
                  <mat-option [value]="7">07</mat-option>
                  <mat-option [value]="8">08</mat-option>
                  <mat-option [value]="9">09</mat-option>
                  <mat-option [value]="10">10</mat-option>
                  <mat-option [value]="11">11</mat-option>
                  <mat-option [value]="12">12</mat-option>
                </mat-select>
                <mat-error class="si-field__error">Invalid Expiration Month</mat-error>
              </mat-form-field>
              <mat-form-field class="si-field si-wd si-wd-fourth">
                <mat-label class="si-label">Expiration Year</mat-label>
                <mat-select
                  matNativeControl
                  class="si-select"
                  formControlName="expirationYear"
                  [errorStateMatcher]="creditCardExpirationDateErrorStateMatcher"
                >
                  <mat-option *ngFor="let expirationYearOption of expirationYearOptions" [value]="expirationYearOption">
                    {{ expirationYearOption }}
                  </mat-option>
                </mat-select>
                <mat-error class="si-field__error">Invalid Expiration Year</mat-error>
              </mat-form-field>
              <mat-form-field class="si-field si-wd si-wd-fourth">
                <mat-label class="si-label">CSC / CVV</mat-label>
                <input
                  matInput
                  class="si-input"
                  maxlength="4"
                  formControlName="creditCardCode"
                  [errorStateMatcher]="creditCardCodeErrorStateMatcher"
                />
                <mat-error class="si-field__error">Invalid CSC / CVV</mat-error>
              </mat-form-field>
            </div>
            <si-process-card
              *ngIf="paymentDetails.paymentError"
              class="si-component"
              [type]="'error'"
              [titleText]="paymentDetails.paymentErrorMessage"
            ></si-process-card>
            <div class="si-form__a">
              <ng-container *ngIf="!submitting">
                <button mat-button matStepperPrevious class="si-button si-button--retreat">Back</button>
                <button
                  mat-flat-button
                  class="si-button si-button--primary si-button--detailed"
                  (click)="onSubmitOrder()"
                  [disabled]="!paymentFormGroup.valid || totalItems === 0"
                >
                  <mat-icon aria-hidden="true">forward</mat-icon>
                  <span>Submit Order</span>
                </button>
              </ng-container>
              <div class="si-spinner--button__container" *ngIf="submitting">
                <mat-spinner class="si-spinner" diameter="20"></mat-spinner>
              </div>
            </div>
          </form>
        </mat-step>
      </mat-vertical-stepper>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="state === 'complete'">
  <header class="si-content__h si-content__h--cart">
    <div class="si-print-only">
      <img [src]="orderDetails.siteLogoUrl" class="si-receipt--logo" [alt]="orderDetails.siteLogoAlt" />
    </div>
    <si-process-card
      class="si-component si-screen-only"
      [type]="'success'"
      [titleText]="'Thank you!'"
      [subTitleText]="'Your order has been submitted'"
    ></si-process-card>
    <div class="si-page__h si-enhanced">
      <h1 class="si-content__title si-impact">
        Receipt
        <span class="si-count">({{ orderDetails.itemCount }} Items)</span>
      </h1>
      <span class="si-expanse"></span>
      <span class="si-fine si-visible--beyond-bp-12">Order #: {{ orderDetails.orderNumber }}</span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="optionsMore"
        class="si-icon si-icon--more"
        color="primary"
        matTooltip="Options"
        aria-label="Options"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #optionsMore="matMenu" class="si-menu">
      <button mat-menu-item class="si-button--detailed" (click)="onPrintReceipt()">
        <mat-icon class="si-tool-icon" color="primary">print</mat-icon>
        <span>Print Receipt</span>
      </button>
    </mat-menu>
  </header>
  <div class="si-content__c">
    <div class="si-content__detail si-exempt si-visible--below-bp-12">
      <span class="si-fine">Order #: {{ orderDetails.orderNumber }}</span>
    </div>
    <mat-accordion class="si-expanel__container si-expanel--group" [multi]="true">
      <mat-expansion-panel class="si-expanel si-expanel--impact mat-elevation-z" [expanded]="true">
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Billing</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <ng-container *ngTemplateOutlet="addressDetailsTemplate; context: { address: billingDetails }"></ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="si-expanel si-expanel--impact mat-elevation-z" [expanded]="true">
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Shipping</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <ng-container *ngIf="billingDetails.shippingSameAsBilling">
            <ng-container
              *ngTemplateOutlet="addressDetailsTemplate; context: { address: billingDetails }"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="!billingDetails.shippingSameAsBilling">
            <ng-container
              *ngTemplateOutlet="addressDetailsTemplate; context: { address: shippingDetails }"
            ></ng-container>
          </ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="cartDetails.items?.length > 0"
        class="si-expanel si-expanel--impact mat-elevation-z"
        expanded="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Items</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <ng-container *ngTemplateOutlet="cartItemsTemplate; context: { items: cartDetails.items }"></ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="si-expanel si-expanel--impact mat-elevation-z" [expanded]="true">
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Payment</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <si-data-output class="si-data__set si-wd-full">
            <ng-container
              *ngTemplateOutlet="currencyTemplate; context: { value: orderDetails.paymentTotal }"
            ></ng-container>
            {{ orderDetails.paymentNote }}
          </si-data-output>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>

<ng-template #cartItemsTemplate let-items="items">
  <ng-container *ngIf="!items || items.length === 0">
    <si-none-card class="si-component" content="Shopping cart is empty."></si-none-card>
  </ng-container>
  <ng-container *ngIf="items?.length > 0">
    <ng-container *ngFor="let item of items">
      <si-cart-card
        class="si-component"
        [state]="state"
        [item]="item"
        [maxQuantity]="maxQuantity"
        [zeroQuantityMessage]="zeroQuantityMessage"
        [overQuantityMessage]="overQuantityMessage"
        [currencyCode]="currencyCode"
        [currencyDisplay]="currencyDisplay"
        [currencyDigitsInfo]="currencyDigitsInfo"
        [currencyLocale]="currencyLocale"
        (deleteItem)="onDeleteItem($event)"
        (quantityChange)="onQuantityChange($event)"
        (overQuantityRequested)="onOverQuantityRequested($event)"
        (menuOptionSelected)="onMenuOptionSelected($event)"
      ></si-cart-card>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #promoCodeTemplate>
  <ng-container *ngIf="promoCodeEnabled">
    <ng-container *ngIf="state === 'summary'">
      <mat-card class="si-card si-card--basic mat-elevation-z2">
        <ng-container *ngTemplateOutlet="promoCodeTemplateAutocompleteHiddenValueTemplate"></ng-container>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="state === 'checkout'">
      <ng-container *ngTemplateOutlet="promoCodeTemplateAutocompleteHiddenValueTemplate"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #promoCodeTemplateAutocompleteHiddenValueTemplate>
  <si-autocomplete-hidden-value
    class="si-component"
    [label]="promoCodeConfig.label"
    [loadingMessage]="promoCodeConfig.loadingMessage"
    [validMessage]="promoCodeConfig.validMessage"
    [validActionPrompt]="promoCodeConfig.validActionPrompt"
    [invalidMessage]="promoCodeConfig.invalidMessage"
    [invalidActionPrompt]="promoCodeConfig.invalidActionPrompt"
    [removable]="promoCodeConfig.removable"
    [chips]="promoCodeConfig.chips"
    [maxItems]="promoCodeConfig.maxItems"
    [debounceTime]="promoCodeConfig.debounceTime"
    [minCharsForSearch]="promoCodeConfig.minCharsForSearch"
    [showLoadingCard]="promoCodeConfig.showLoadingCard"
    [showResultsInvalidCard]="promoCodeConfig.showResultsInvalidCard"
    [showResultsValidCard]="promoCodeConfig.showResultsValidCard"
    [applyADAFix]="promoCodeConfig.applyADAFix"
    (inputKeyDown)="onPromoCodeInputKeyDown($event)"
    (searchChange)="onPromoCodeSearchChange($event)"
    (valueSelected)="onPromoCodeValueSelected($event)"
    (valueRemoved)="onPromoCodeValueRemoved($event)"
  ></si-autocomplete-hidden-value>
</ng-template>

<ng-template #currencyTemplate let-value="value">
  {{ value | currency: currencyCode:currencyDisplay:currencyDigitsInfo:currencyLocale }}
</ng-template>

<ng-template #addressDetailsTemplate let-address="address">
  <si-data-output class="si-data__set si-wd-full">
    <span>{{ address.firstName }} {{ address.lastName }}</span>
    <div class="si-for--minor" *ngIf="address.employed === 'Yes'">{{ address.company }}</div>
    <div class="si-for--minor">
      {{ address.street1 }},
      {{ address.street2 ? address.street2 + ',' : '' }}
      {{ address.city }}, {{ address.state }}, {{ address.postal }},
      {{ address.country }}
    </div>
    <div class="si-for--minor">{{ address.email }}</div>
  </si-data-output>
</ng-template>
