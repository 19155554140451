<ng-container *ngIf="src && (type === 'decorative' || alt)">
  <ng-container *ngIf="useNgOptimizedImage; else nonNgOptimizedImage">
    <img
      class="si-img {{ classes }}"
      [ngSrc]="src"
      [attr.alt]="type === 'decorative' ? undefined : alt"
      [attr.loading]="lazyLoad ? 'lazy' : undefined"
      [attr.title]="tooltip ? tooltip : undefined"
      [priority]="priority"
      [ngSrcset]="ngSrcset"
      sizes="100%"
      fill="true"
    />
  </ng-container>
  <ng-template #nonNgOptimizedImage>
    <img
      class="si-img {{ classes }}"
      [src]="src"
      [attr.alt]="type === 'decorative' ? undefined : alt"
      [attr.loading]="lazyLoad ? 'lazy' : undefined"
      [attr.title]="tooltip ? tooltip : undefined"
    />
  </ng-template>
</ng-container>
