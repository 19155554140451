
import { inject, Inject, Injectable, InjectionToken, ProviderToken } from '@angular/core';
import { ActiveState } from '@datorama/akita';
import { HttpMethod, NgEntityServiceGlobalConfig, NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { CreateSurveyResponsePayload, FilterParams, SaeSurveyResponse, SaeSurveyVersion, SurveyResponseStatus } from '@sae/models';
import { Observable } from 'rxjs';
import { ApiRegistry, ApiToken, StoresRegistry, StoresToken } from './../api-registry';
import { BaseEntityQuery, BaseEntityService, BaseEntityStore, BaseState } from './base.service';

export interface ISurveyResponseService {
  all$: Observable<Array<SaeSurveyResponse>>;
  response$: Observable<SaeSurveyResponse>;
  loading$: Observable<boolean>;
  setLoading(loading: boolean): void;
  initialize(): void;
  setSaeResponse(response: SaeSurveyResponse): void;
  setSurveyJSResponse(data: any): void;
  setSurveyVersion(surveyVersion: SaeSurveyVersion): void;
  getSaeResponse(): SaeSurveyResponse;
  initializeAndFetchAllResponses(surveyId: string): void;
  isPartialResponse(): boolean;
  saveSurveyResponse(status: SurveyResponseStatus): void;
}

export interface SurveyResponseCoreState extends BaseState<SaeSurveyResponse>, ActiveState {
  activeSurveyVersionId: string;
  appliedFilters: FilterParams[];
  filterType: string;
  filterStatus: string;
  searchTerm: string;
  showResponderNames: boolean;
  unfilteredTotal: number;
  userHasResponded: boolean;
}

export function createResponseInitialState(): SurveyResponseCoreState {
  const initialState = BaseEntityStore.createInitialState();
  return {
    activeSurveyVersionId: null,
    appliedFilters: [],
    filterType: null,
    filterStatus: null,
    ...initialState,
    showResponderNames: false,
    unfilteredTotal: 0,
    userHasResponded: null
  }
}

@Injectable({ providedIn: 'root' })

export class SurveyResponseCoreStore extends BaseEntityStore<SurveyResponseCoreState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super({ name: storesRegistry.surveyResponse, idKey: '_id' }, createResponseInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class SurveyResponseCoreQuery extends BaseEntityQuery<SurveyResponseCoreState> {
  constructor(protected store: SurveyResponseCoreStore) {
    super(store);
  }
}

export class SurveyResponseCoreService extends BaseEntityService<SurveyResponseCoreState> implements ISurveyResponseService {
  loading$ = this.query.selectLoading();
  public response$ = this.query.select((s) => s.response as SaeSurveyResponse);
  public showResponderNames$ = this.query.select((s) => s.showResponderNames);
  public all$ = this.query.selectAll();
  constructor(
    public readonly store: SurveyResponseCoreStore,
    public readonly query: SurveyResponseCoreQuery,
    @Inject(NG_ENTITY_SERVICE_CONFIG)
    ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    @Inject(ApiToken) apiReg: ApiRegistry
  ) {
    super(store, query, { baseUrl: ngEntityServiceGlobalConfig.baseUrl, resourceName: apiReg?.surveyResponse?.url });
  }

  setLoading(loading: boolean): void {
    this.store.update({ loading });
  }

  setSaeResponse(response: SaeSurveyResponse): void {
    this.store.update({ response });
  }

  setSurveyVersion(surveyVersion: SaeSurveyVersion): void {
    this.store.update((state) => {
      return { response: { ...state.response, surveyVersion } };
    });
  }

  setSurveyJSResponse(data: any): void {
    this.store.update((state) => {
      return { response: { ...state.response, response: data } };
    });
  }

  getSaeResponse(): SaeSurveyResponse {
    return this.query.getValue().response as SaeSurveyResponse;
  }

  initializeAndFetchAllResponses(surveyId: string): void {
    this.customParams = [{ paramName: 'surveyId', value: surveyId }];
    this.initializeAndFetch();
  }

  isPartialResponse(): boolean {
    return !!this.query.getValue().response._id;
  }

  getHasResponded(): boolean {
    return this.query.getValue().userHasResponded;
  }

  setHasResponded(userHasResponded: boolean): void {
    this.store.update({ userHasResponded });
  }

  saveSurveyResponse(status: SurveyResponseStatus): void {
    const response = this.query.getValue().response;
    response.status = status;
    if (this.isPartialResponse()) {
      const payload: Partial<SaeSurveyResponse> = { ...response };
      this.updateEntity(response.id, payload, HttpMethod.PATCH);
    } else {
      const payload: CreateSurveyResponsePayload = { ...response, meta: response.meta ?? {} };
      this.createEntity(payload);
    }
  }
}

export const SURVEY_RESPONSE_TOKEN = new InjectionToken<ISurveyResponseService>('SurveyResponse Service', {
  providedIn: 'root',
  factory: () =>
    new SurveyResponseCoreService(
      inject(SurveyResponseCoreStore),
      inject(SurveyResponseCoreQuery),
      inject(NG_ENTITY_SERVICE_CONFIG),
      inject(ApiToken as ProviderToken<ApiRegistry>)
    ),
});
