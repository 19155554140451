import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ExportMetaDialogComponent } from '../../../../../shared/components/export-meta-dialog/export-meta-dialog.component';
import { metaData } from '../../../../../shared/models/resource.model';
import { IDialogComponent, SnackbarDialogService } from '../../../../../shared/services/snackbar-dialog.service';
import { SessionService } from '../../../../../shared/state/session.service';

@Injectable({
  providedIn: 'root',
})
export class MastheadSnackbarDialogService extends SnackbarDialogService {
  dialogRef$: Observable<MatDialogRef<IDialogComponent>>;
  constructor(
    protected matDialog: MatDialog,
    protected matSnackBar: MatSnackBar,
    router: Router,
    sessionService: SessionService
  ) {
    super(matDialog, matSnackBar, router, sessionService);
  }

  openDownloadMetaDialog(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dialogTitle = '',
    dialogIcon = '',
    cid: number,
    component: ComponentType<unknown> = ExportMetaDialogComponent
  ): Observable<MatDialogRef<unknown>> {
    const dialogRef = this.matDialog.open(component, {
      data: { dialogTitle: 'Export Metadata', dialogIcon, cid, dataItems: metaData },
    });
    return of(dialogRef);
  }
}
