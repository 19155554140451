import { DropdownFilterPanelComponent } from './dropdown-filter-panel/dropdown-filter-panel.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@sae/material';
import { SharedUtilitiesModule } from '@sae/utilities';
import { ResourceDialogComponent, SurveyResourceDialogComponent } from '..';
import { AutocompleteChipSearchComponent } from './autocomplete/autocomplete-chip-search/autocomplete-chip-search.component';
import { AutocompleteChipComponent } from './autocomplete/autocomplete-chip/autocomplete-chip.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AutocompleteHiddenValueComponent } from './autocomplete/autocomplete-hidden-value/autocomplete-hidden-value.component';
import { BasicDataViewerComponent } from './basic-data-viewer/basic-data-viewer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SortButtonComponent } from './button/sort-button/sort-button.component';
import { BasicCardComponent } from './card/basic-card/basic-card.component';
import { CompanyStatusCardComponent } from './card/company-status-card/company-status-card.component';
import { ContentCardComponent } from './card/content-card/content-card.component';
import { ErrorCardComponent } from './card/error-card/error-card.component';
import { MicroCardComponent } from './card/micro-card/micro-card.component';
import { NoneCardComponent } from './card/none-card/none-card.component';
import { NoneActionableCardComponent } from './card/none-actionable-card/none-actionable-card.component';
import { ParentCardComponent } from './card/parent-card/parent-card.component';
import { PersonCardTagComponent } from './card/person-card-tag/person-card-tag.component';
import { ProcessCardComponent } from './card/process-card/process-card.component';
import { ReportCardComponent } from './card/report-card/report-card.component';
import { StateCardComponent } from './card/state-card/state-card.component';
import { TidbitCardComponent } from './card/tidbit-card/tidbit-card.component';
import { CommentComponent } from './comments/comment/comment.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DataOutputComponent } from './data-output/data-output.component';
import { DateFilterPanelComponent } from './date-filter-panel/date-filter-panel.component';
import { EnterpriseMenuLegacyComponent } from './enterprise-menu-legacy/enterprise-menu-legacy.component';
import { FileAttachmentManagerUiComponent } from './files/file-attachment-manager-ui/file-attachment-manager-ui.component';
import { FileCardComponent } from './files/file-card/file-card.component';
import { FileUploadDialogComponent } from './files/file-upload-dialog/file-upload-dialog.component';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { CondensedFilterComponent } from './filter/condensed-filter/condensed-filter.component';
import { RegularFilterComponent } from './filter/regular-filter/regular-filter.component';
import { FormControlComponent } from './form-control/form-control.component';
import { MoreMenuComponent } from './more-menu/more-menu.component';
import { MultiSelectToolbarComponent } from './multi-select-toolbar/multi-select-toolbar.component';
import { OverflowMenuInnerComponent } from './overflow-menu/overflow-menu-inner/overflow-menu-inner.component';
import { OverflowMenuComponent } from './overflow-menu/overflow-menu.component';
import { PortalDialogComponent } from './portal-dialog/portal-dialog.component';
import { PrintErrorComponent } from './print-error/print-error.component';
import { AttachmentResourceManagerUIComponent } from './resources/attachment-resource-manager-ui.component';
import { ResourceCardComponent } from './resources/resource-card/resource-card.component';
import { ResourceManagerUIComponent } from './resources/resource-manager-ui.component';
import { ResourceSortComponent } from './resources/resource-sort/resource-sort.component';
import { SearchBarComponent } from './search/search-bar/search-bar.component';
import { SearchInputComponent } from './search/search-input/search-input.component';
import { SortMenuComponent } from './sort-menu/sort-menu.component';
import { SuccessConfirmationComponent } from './success-confirmation/success-confirmation.component';
import { ToolbarTakeoverComponent } from './toolbar/toolbar-takeover/toolbar-takeover.component';
import { VersionComponent } from './version/version.component';
import { ConfirmDialogFullComponent } from './confirm-dialog/confirm-dialog-full/confirm-dialog-full.component';
import { EnhancedTreeComponent } from './tree/enhanced-tree/enhanced-tree.component';
import { BreadcrumbEnhancedComponent } from './breadcrumb-enhanced/breadcrumb-enhanced.component';
import { StubCardComponent } from './card/stub-card/stub-card.component';
import { ReaderViewComponent } from './reader-view/reader-view.component';
import { ItemListDataViewerComponent } from './entities/data-viewer/item-list-data-viewer/item-list-data-viewer';
import { FormalListComponent } from './materials/list/formal-list/formal-list.component';
import { FilterChipNavigationComponent } from './materials/chip/filter-chip-navigation/filter-chip-navigation.component';
import { ListManagerComponent } from './entities/list-manager/list-manager.component';
import { BallotCardComponent } from './card/ballot-card/ballot-card.component';
import { IntroHeroComponent } from './entities/intro-hero/intro-hero.component';
import { SupplierCardComponent } from './card/supplier-card/supplier-card.component';
import { GradeCardComponent } from './card/grade-card/grade-card.component';
import { ReadOnlyFieldComponent } from './materials/form/read-only-field/read-only-field.component';
import { SeoChiplistComponent } from './materials/chip/seo-chiplist/seo-chiplist.component';
import { IngestionCardComponent } from './materials/card/ingestion-card/ingestion-card.component';
import { QPLCardComponent } from './materials/card/qpl-card/qpl-card.component';
import { AuditCardComponent } from './materials/card/audit-card/audit-card.component';
import { GeneralStatusCardComponent } from './materials/card/general-status-card/general-status-card.component';
import { LibraryCardComponent } from './materials/card/library-card/library-card.component';
import { ContentMixCardComponent } from './materials/card/content-mix-card/content-mix-card.component';
import { CharactersRemainingTextarea } from './characters-remaining-textarea/characters-remaining-textarea.component';
import { AnnotationZoneComponent } from './entities/annotation-zone/annotation-zone.component';
import { AnnotationDialogComponent } from './annotation-dialog/annotation-dialog.component';
import { FacetExpanelComponent } from './materials/expanel/facet-expanel/facet-expanel.component';
import { SeoPaginatorComponent } from './materials/paginator/seo-paginator/seo-paginator.component';
import { PersonCardComponent } from './card/person-card/person-card.component';
import { PersonCardMenuComponent } from './card/person-card-menu/person-card-menu.component';
import { TableFieldComponent } from './materials/form/table-field/table-field.component';
import { ChipTabsComponent } from './materials/chip/chip-tabs/chip-tabs.component';
import { CartCardComponent } from './materials/card/cart-card/cart-card.component';
import { OrderPanelComponent } from './entities/cart/order-panel/order-panel.component';
import { ShoppingCartComponent } from './entities/cart/shopping-cart/shopping-cart.component';
import { SelectChipDialogComponent } from './autocomplete/select-chip-dialog/select-chip-dialog.component';
import { QueueCardComponent } from './materials/card/queue-card/queue-card.component';
import { SelectTreeComponent } from './materials/tree/select-tree/select-tree.component';
import { PartPieChartComponent } from './parts/pie-chart/pie-chart.component';
import { PartIconComponent } from './parts/part-icon/part-icon.components';
import { EffectsDirectingScrimComponent } from './entities/effects/directing-scrim/directing-scrim.component';
import { ExPanelComponent } from './compounds/ex-panel/ex-panel.components';
import { PartAvatarComponent } from './parts/part-avatar/part-avatar.component';
import { PartCompoundComponent } from './parts/part-compound/part-compound.component';
import { PartDelimiterSetComponent } from './parts/part-delimiter-set/part-delimiter-set.component';
import { PartRowComponent } from './parts/part-row/part-row.component';
import { PartTextComponent } from './parts/part-text/part-text.component';
import { PartTitleComponent } from './parts/part-title/part-title.component';
import { PartSelectorComponent } from './parts/part-selector/part-selector.component';
import { UniversalCardComponent } from './card/universal-card/universal-card.component';

@NgModule({
  declarations: [
    AttachmentResourceManagerUIComponent,
    AutocompleteChipComponent,
    AutocompleteComponent,
    AutocompleteHiddenValueComponent,
    AutocompleteChipSearchComponent,
    BasicCardComponent,
    CompanyStatusCardComponent,
    BasicDataViewerComponent,
    BreadcrumbComponent,
    CharactersRemainingTextarea,
    CommentComponent,
    CondensedFilterComponent,
    ConfirmDialogComponent,
    ConfirmDialogFullComponent,
    ContentCardComponent,
    DataOutputComponent,
    DateFilterPanelComponent,
    DropdownFilterPanelComponent,
    EnterpriseMenuLegacyComponent,
    ErrorCardComponent,
    FileAttachmentManagerUiComponent,
    FileCardComponent,
    FileUploadDialogComponent,
    FilterPanelComponent,
    FormControlComponent,
    MicroCardComponent,
    MoreMenuComponent,
    MultiSelectToolbarComponent,
    NoneCardComponent,
    NoneActionableCardComponent,
    OverflowMenuComponent,
    OverflowMenuInnerComponent,
    ParentCardComponent,
    PersonCardTagComponent,
    ProcessCardComponent,
    ReportCardComponent,
    PortalDialogComponent,
    PrintErrorComponent,
    RegularFilterComponent,
    ResourceCardComponent,
    ResourceDialogComponent,
    ResourceManagerUIComponent,
    ResourceSortComponent,
    SearchBarComponent,
    SearchInputComponent,
    SortButtonComponent,
    SortMenuComponent,
    StateCardComponent,
    SuccessConfirmationComponent,
    SurveyResourceDialogComponent,
    TidbitCardComponent,
    ToolbarTakeoverComponent,
    VersionComponent,
    FileCardComponent,
    ConfirmDialogFullComponent,
    EnhancedTreeComponent,
    BreadcrumbEnhancedComponent,
    StubCardComponent,
    ReaderViewComponent,
    ItemListDataViewerComponent,
    FormalListComponent,
    FilterChipNavigationComponent,
    ListManagerComponent,
    BallotCardComponent,
    IntroHeroComponent,
    SupplierCardComponent,
    GradeCardComponent,
    ReadOnlyFieldComponent,
    SeoChiplistComponent,
    IngestionCardComponent,
    QPLCardComponent,
    AuditCardComponent,
    GeneralStatusCardComponent,
    LibraryCardComponent,
    ContentMixCardComponent,
    AnnotationZoneComponent,
    AnnotationDialogComponent,
    FacetExpanelComponent,
    SeoPaginatorComponent,
    PersonCardComponent,
    PersonCardMenuComponent,
    TableFieldComponent,
    ChipTabsComponent,
    CartCardComponent,
    OrderPanelComponent,
    ShoppingCartComponent,
    SelectChipDialogComponent,
    QueueCardComponent,
    SelectTreeComponent,
    PartPieChartComponent,
    PartIconComponent,
    EffectsDirectingScrimComponent,
    ExPanelComponent,
    PartAvatarComponent,
    PartCompoundComponent,
    PartDelimiterSetComponent,
    PartRowComponent,
    PartTextComponent,
    PartTitleComponent,
    PartSelectorComponent,
    UniversalCardComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedUtilitiesModule, RouterModule],
  exports: [
    AttachmentResourceManagerUIComponent,
    AutocompleteChipComponent,
    AutocompleteComponent,
    AutocompleteHiddenValueComponent,
    AutocompleteChipSearchComponent,
    BasicCardComponent,
    CompanyStatusCardComponent,
    BasicDataViewerComponent,
    BreadcrumbComponent,
    CharactersRemainingTextarea,
    CommentComponent,
    CondensedFilterComponent,
    ConfirmDialogComponent,
    ConfirmDialogFullComponent,
    ContentCardComponent,
    DataOutputComponent,
    DateFilterPanelComponent,
    DropdownFilterPanelComponent,
    EnterpriseMenuLegacyComponent,
    ErrorCardComponent,
    FileAttachmentManagerUiComponent,
    FileCardComponent,
    FileUploadDialogComponent,
    FilterPanelComponent,
    FormControlComponent,
    MicroCardComponent,
    MoreMenuComponent,
    MultiSelectToolbarComponent,
    NoneCardComponent,
    NoneActionableCardComponent,
    OverflowMenuComponent,
    OverflowMenuInnerComponent,
    ParentCardComponent,
    PersonCardTagComponent,
    ProcessCardComponent,
    ReportCardComponent,
    PortalDialogComponent,
    PrintErrorComponent,
    RegularFilterComponent,
    ResourceCardComponent,
    ResourceDialogComponent,
    ResourceManagerUIComponent,
    ResourceSortComponent,
    SearchBarComponent,
    SearchInputComponent,
    SortButtonComponent,
    SortMenuComponent,
    StateCardComponent,
    SuccessConfirmationComponent,
    SurveyResourceDialogComponent,
    TidbitCardComponent,
    ToolbarTakeoverComponent,
    VersionComponent,
    EnhancedTreeComponent,
    BreadcrumbEnhancedComponent,
    StubCardComponent,
    ReaderViewComponent,
    ItemListDataViewerComponent,
    FormalListComponent,
    FilterChipNavigationComponent,
    ListManagerComponent,
    BallotCardComponent,
    IntroHeroComponent,
    SupplierCardComponent,
    GradeCardComponent,
    ReadOnlyFieldComponent,
    SeoChiplistComponent,
    IngestionCardComponent,
    QPLCardComponent,
    AuditCardComponent,
    GeneralStatusCardComponent,
    LibraryCardComponent,
    ContentMixCardComponent,
    AnnotationZoneComponent,
    AnnotationDialogComponent,
    FacetExpanelComponent,
    SeoPaginatorComponent,
    PersonCardComponent,
    PersonCardMenuComponent,
    TableFieldComponent,
    ChipTabsComponent,
    CartCardComponent,
    OrderPanelComponent,
    ShoppingCartComponent,
    SelectChipDialogComponent,
    QueueCardComponent,
    SelectTreeComponent,
    PartPieChartComponent,
    PartIconComponent,
    EffectsDirectingScrimComponent,
    ExPanelComponent,
    PartAvatarComponent,
    PartCompoundComponent,
    PartDelimiterSetComponent,
    PartRowComponent,
    PartTextComponent,
    PartTitleComponent,
    PartSelectorComponent,
    UniversalCardComponent,
  ],
})
export class SharedComponentsModule {}
