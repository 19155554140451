<div class="si-dialog">
  <div class="si-dialog__h">
    <h2 class="si-dialog__title">{{data.title}}</h2>
    <span class="si-expanse"></span>
    <button mat-icon-button title="Close" class="si-icon si-button--close" (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="si-dialog__b">
  <div mat-dialog-content class="si-dialog__c">
    <mat-tree [dataSource]="hierarchyDataSource" [treeControl]="hierarchyTreeControl" class="si-tree si-tree--detail h-tree"
      role="tree">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding role="treeitem"
        class="si-tree__node" [matTreeNodePaddingIndent]="16">
        <div class="mat-tree-node">
        <span class="si-tree__control mat-icon-button si-icon"></span>
        <mat-radio-button class="si-radio " [value]="node.value" (click)="select(node)">{{node.fullName ? (node.fullName +' '+ node.title) : node.title}}
        </mat-radio-button>
        <a class="si-tree__title si-button--link" mat-button hidden></a>
      </div>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding role="group"
        [matTreeNodePaddingIndent]="16" class="si-tree__node">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.title">
              <mat-icon class="mat-icon-rtl-mirror">
                {{hierarchyTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <mat-radio-button class="si-radio" *ngIf="node.id" [value]="node.value" (click)="select(node)">
            </mat-radio-button>
            <span>{{node.fullName ? (node.fullName +' '+ node.title) : node.title}}</span>
          </div>
          <ul [class.h-tree-invisible]="!hierarchyTreeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
