import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../feature-keys';
import { SearchState } from './search.state';
import { Criteria, CriteriaField } from '../../modules/search/components/advanced-search/advanced-search.component';

export const searchFeature = createFeatureSelector<SearchState>(FeatureKeys.Search);
export const selectPageNumber = createSelector(searchFeature, (search) => search.pageNumber);
export const selectPageSize = createSelector(searchFeature, (search) => search.pageSize);
export const selectTotal = createSelector(searchFeature, (search) => search.total);
export const selectSortOptions = createSelector(searchFeature, (search) => search.sortOptions);
export const selectSortBy = createSelector(searchFeature, (search) => search.sortBy);
export const selectSortDir = createSelector(searchFeature, (search) => search.sortDir);
export const selectSearchTerm = createSelector(searchFeature, (search) => search.searchTerm);
export const selectSearchSuggestions = createSelector(searchFeature, (search) => search.searchSuggestions);
export const selectDocumentSuggestions = createSelector(searchFeature, (search) => search.documentSuggestions);
export const selectResults = createSelector(searchFeature, (search) => search.results);
export const selectResultsLoading = createSelector(searchFeature, (search) => search.resultsLoading);
export const selectPanelInitialized = createSelector(searchFeature, (search) => search.panelInitialized);
export const selectResultsError = createSelector(searchFeature, (search) => search.resultsError);
export const selectScrollTop = createSelector(searchFeature, (search) => search.scrollTop);
export const selectFacets = createSelector(searchFeature, (search) => search.facets);
export const selectFilters = createSelector(searchFeature, (search) => search.filters);
export const selectPreservedFacet = createSelector(searchFeature, (search) => search.preservedFacet);
export const selectPubDateTo = createSelector(searchFeature, (search) => search.pubDateTo);
export const selectPubDateFrom = createSelector(searchFeature, (search) => search.pubDateFrom);
export const selectIsPubDateApplied = createSelector(searchFeature, (search) => search.isPubDateApplied);
export const selectCustomFilters = createSelector(searchFeature, (search) => search.customFilters);
export const selectSearchDetail = createSelector(searchFeature, (search) => search.searchDetail);
export const selectAdvancedSearchCriteria = createSelector(searchFeature, (search) => search.advancedSearchCriteria);
export const selectAdvancedSearchEnabled = createSelector(searchFeature, (search) => search.advancedSearchEnabled);
export const selectAdvancedSearchQuery = createSelector(searchFeature, (search) => {
  const criteria: Criteria[] = search.advancedSearchCriteria;

  if (!criteria || !criteria.length) {
    return '';
  }
  const conditionMap: { [key: string]: (field: string, value: string) => string } = {
    contains: (field, value) => `(${field}.stem: (${value}))`,
    'does not contain': (field, value) => `-(${field}.stem: (${value}))`,
  };

  const labelMap: { [key in CriteriaField]: string } = {
    '': undefined,
    Abstract: 'abstract_clean',
    'Author Affiliation': 'affiliations',
    'Author Name': 'authors',
    Event: 'conference_name',
    'Full Text': 'full_text',
    Industry: 'industrysectors_name',
    'Issue Number': 'periodical_issue',
    'Item Code': 'product_code',
    Publisher: 'publisher_name',
    Title: 'title',
    Topic: 'topics',
    'Volume Number': 'periodical_volume',
  };

  const processCriteria = (
    criteria: Criteria[],
    currentIndex: number,
    currentLevel: number,
    groupOperator: string
  ): [string, number] => {
    const resultParts: string[] = [];
    let i = currentIndex;

    while (i < criteria.length && criteria[i].level >= currentLevel) {
      const criterion = criteria[i];

      if (criterion.type === 'GROUP' && criterion.level === currentLevel) {
        const [groupString, newIndex] = processCriteria(criteria, i + 1, currentLevel + 1, criterion.value);
        if (groupString) {
          resultParts.push(`(${groupString})`);
        }
        i = newIndex;
      } else if (criterion.type === 'ROW' && criterion.level === currentLevel) {
        const conditionFunction = conditionMap[criterion.conditional];
        if (conditionFunction) {
          const conditionString = conditionFunction(labelMap[criterion.field], criterion.value || '');
          resultParts.push(conditionString);
        }
        i++;
      } else {
        // Process nested group at a different level
        const [nestedString, newIndex] = processCriteria(criteria, i, criterion.level, criteria[i].value);
        if (nestedString) {
          resultParts.push(nestedString);
        }
        i = newIndex;
      }
    }

    if (resultParts.length === 0) {
      return ['', i];
    }
    const result = resultParts.join(groupOperator === 'Any' ? ' OR ' : ' AND ').trim();
    return [result, i];
  };

  const [query] = processCriteria(criteria, 0, 0, criteria[0].value);
  return query;
});
