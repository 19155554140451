import { createAction, props } from '@ngrx/store';
import { SaeHttpResponse } from '@sae/models';
import { Asset } from '../../shared/models/common-interfaces';

export const getAssets = createAction('[Detail Page] Get Assets', props<{ contentId: number }>());
export const getAssetsSucceeded = createAction('[Detail Page] Get Assets Succeeded', props<{ assets: Asset[] }>());
export const getAssetsFailed = createAction('[Detail Page] Get Assets Failed');
export const refreshContent = createAction('[Detail Page] Refresh Content');
export const getContentDataSets = createAction(
  '[Detail Page] Get Content Data Sets ',
  props<{
    contentId: number;
    offset?: number;
    limit?: number;
    sort?: string;
    order?: string;
    datasetIndex?: number;
    ingestionId?: string;
    searchType?: string;
    fileType?: string;
    filename?: string;
  }>()
);
export const getContentDataSetsSucceeded = createAction(
  '[Detail Page] Get Content Data Sets Succeeded',
  props<SaeHttpResponse<Asset>>()
);
export const changeSortDirection = createAction(
  '[Detail Page] Change Data Sets Sort Direction',
  props<{ sortDir: string }>()
);
