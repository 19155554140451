import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { EnterpriseMenu, IRemoteConfigService } from '@sae/base';
import { ISessionService, REMOTE_CONFIG_TOKEN, SESSION_TOKEN } from '@sae/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'si-enterprise-menu',
  templateUrl: './enterprise-menu.component.html',
  styleUrls: ['./enterprise-menu.component.scss'],
})
export class EnterpriseMenuComponent {
  appName$ = this.sessionService.appName$;
  showHomeButton$ = this.sessionService.showHomeButtonInEnterpriseMenu$;
  enterpriseMenu$: Observable<EnterpriseMenu> = this.remoteConfigService.enterpriseMenu$;
  appLogoClass$ = this.sessionService.appLogoClass$;

  constructor(
    private readonly router: Router,
    @Inject(REMOTE_CONFIG_TOKEN) private readonly remoteConfigService: IRemoteConfigService,
    @Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService
  ) {}

  public async goToHome(): Promise<void> {
    await this.router.navigateByUrl('/');
  }
}
