import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SeoChiplistDto } from '../../chip/seo-chiplist/seo-chiplist.component';
import { MoreOption } from '@sae/models';

export type GeneralStatusCardIconStyle =
  | 'negative'
  | 'positive'
  | 'activated'
  | 'highlight'
  | 'inaccessible'
  | 'locked'
  | null; // Determines the color style of the icon, if null the default color/style is the theme primary (blue).

export type GeneralStatusSubtitle = {
  label?: string;
  value: string;
  em?: boolean;
};

@Component({
  selector: 'si-general-status-card',
  templateUrl: './general-status-card.component.html',
})
export class GeneralStatusCardComponent implements AfterViewInit {
  @Input() appLink!: string; // Optional, if specified wraps the card in an <a> tag using routerLink. Don't use in conjuction with hrefLink.
  @Input() hrefLink!: string; // Optional, if specified wraps the card in an <a> tag using href. Don't use in conjuction with appLink.
  @Input() avatarText!: string; // Optional, use instead of icon
  @Input() icon!: string; // Optional, Material icon name to use in the left side of the card.
  @Input() iconStyle!: GeneralStatusCardIconStyle; // Optional, specifies the color/stype of the icon, if ommitted it is blue by default.
  @Input() chips!: SeoChiplistDto[]; // Optional, list of SeoChiplist chips to display in a top row of the card.
  @Input() chipsMenu: MoreOption[] = []; // Optional, if provided, causes clicking on chips to trigger opening a menu with these options
  @Input() subChips!: SeoChiplistDto[]; // Optional, list of SeoChiplist chips to display in a bottom row of the card.
  @Input() subChipsMenu: MoreOption[] = []; // Optional, if provided, causes clicking on subChips to trigger opening a menu with these options
  @Input() chipsIsTopic = false; // Optional, if true will set all chips to be topic
  @Input() useSeoChips = true; // Optional, if true will use SeoChiplist component to render the chips, otherwise the chips will be mat-chip buttons
  @Input() chipsInlineWithSubtitle = false; // Optional, if true and if chips are provided, they are rendered inline with the subtitle for a display variation
  @Input() tagline: string; // Optional, if specified adds a slightly subdued line of text at the very top without margin
  @Input() title!: string; // Optional, if specified adds a bold title line to the card under the chips.
  @Input() titleLinked = false; // Optional, if true will make the title appear blue
  @Input() titleAdditional!: GeneralStatusSubtitle[]; // Optional, if specified adds a additional re
  @Input() subtitle!: string; // Optional, if specified adds a non bolded line to the card under the chips/title.
  @Input() subtitleAdditional: string; // Optional, used in conjunction with subtitle, if specified appends this after the regular subtitle with spacing and a dot
  @Input() subtitleLines!: GeneralStatusSubtitle[][]; // Optional, a 0-N array of lines with 0-N label/value pairs which will be rendered in distinct subtitle lines under the chips/title/subtitle.
  @Input() shadow = false; // Optional, if true adds a z2 elevation shadow to the mat-card
  @Input() selected = false; // Optional, if true adds a selected class on the card
  @Input() toggleable = false; // Optional, if true enables functionality to toggle this card on/off
  @Input() toggleableSticky = false; // Optional, used in conjunction with toggleable, if true the toggle button are always shown and never hide on blur (and thus never expose the icon if one is provided)
  @Input() toggled = false; // Optional, used in conjunction with toggleable, determines the toggle state of the card
  @Input() ariaLabel = 'Type'; // Text to include for aria-label of the chips
  @Input() tight = false; // If true all vertical padding is removed from the mat-card-title lines
  @Input() divider = false; // Used in conjunction with shadow false, this places bottom shadow only as a divider between inline cards
  @Input() cornerTagLeft: string = null; // Optional, if specified adds a corner tag to the top left of the which is placed between the title and subtitle
  @Input() cornerTagRight: string = null; // Optional, if specified adds a corner tag to the top right of the which is placed between the title and subtitle

  // Optional Analytics IDs
  @Input() analyticsIdIcon: string; // Optional, specifies the data-analytics attribute value for the icon

  @Output() selectChip = new EventEmitter<SeoChiplistDto>(); // Event emitted if a chip with clickEventEmitter property set to true is clicked.
  @Output() toggleCard = new EventEmitter(); // Event emitted if a toggleable enabled card is toggled
  @Output() optionSelected = new EventEmitter<{ option: string; label: string }>(); // Event emitted if using chipsMenu and/or subChips and an option was selected

  @ViewChild('cardBottomContent') cardBottomContentRef: ElementRef;
  @ViewChild('preChipsContent') preChipsContentRef: ElementRef;

  disabledRipple = false;
  showToggleButton = false;
  showCardBottomContent = true;
  showPreChipsContent = true;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    let detectChanges = false;
    if (!this.cardBottomContentRef?.nativeElement?.children?.length) {
      this.showCardBottomContent = false;
      detectChanges = true;
    }
    if (!this.preChipsContentRef?.nativeElement?.children?.length) {
      this.showPreChipsContent = false;
      detectChanges = true;
    }
    if (detectChanges) {
      this.cd.detectChanges();
    }
  }

  chipClicked(chip: SeoChiplistDto): void {
    this.selectChip.emit(chip);
  }

  matChipClicked(label: string): void {
    this.selectChip.emit({ label });
  }

  onMouseEnter(): void {
    if (this.toggleable) {
      this.disabledRipple = true;
      this.showToggleButton = true;
    }
  }

  onMouseLeave(): void {
    if (this.toggleable) {
      this.disabledRipple = false;
      this.showToggleButton = false;
    }
  }

  onToggleClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.toggleCard.emit();
  }

  onOptionSelected(event: { option: string; label: string }): void {
    this.optionSelected.emit({ option: event.option, label: event.label });
  }

  onMatChipOptionSelected(option: string, label: string): void {
    this.optionSelected.emit({ option, label });
  }
}
