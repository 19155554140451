import { Component, Input } from '@angular/core';

export type MicroCardComponentIconStyle =
  | 'negative'
  | 'positive'
  | 'activated'
  | 'highlight'
  | 'inaccessible'
  | 'locked'
  | null; // Determines the color style of the icon, if null the default color/style is the theme primary (blue)

@Component({
  selector: 'si-micro-card',
  templateUrl: './micro-card.component.html',
})
export class MicroCardComponent {
  @Input() customIcon!: string;
  @Input() materialIcon!: string;
  @Input() materialIconStyle!: MicroCardComponentIconStyle;
  @Input() avatar!: string;
  @Input() avatarTitle!: string;
  @Input() isSelected!: boolean;
  @Input() title!: string;
  @Input() appLink!: string;
  @Input() hrefLink!: string;
  @Input() wrapElement!: string;
  @Input() ext!: string;
}
