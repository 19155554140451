import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { SeoChiplistDto } from '@sae/components';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DISCLAIMER, LINEBREAK } from '../../string-files/common-strings';
import { MoreOption } from '@sae/models';
import { isPlatformServer } from '@angular/common';
@Component({
  selector: 'mobi-category-card',
  templateUrl: './mobi-category-card.component.html',
})
export class MobiCategoryCardComponent implements OnInit {
  @Input() baseURL = '';
  @Input() seoUri: string;
  @Input() preTitleText: string;
  @Input() titleText: string;
  @Input() subTitleText: string;
  @Input() materialIcon: string;
  @Input() action: string;
  @Input() actionIcon: string;
  @Input() image: string;
  @Input() types: { name: string; URL: string; classes: string }[] = [];
  @Input() options: MoreOption[];

  @Output() optionSelected = new EventEmitter();

  typesChips: SeoChiplistDto[] = [];

  constructor(private snackbar: MatSnackBar, @Inject(PLATFORM_ID) private platformId) {}

  ngOnInit(): void {
    for (const type of this.types) {
      this.typesChips.push({
        label: type.name,
        href: this.baseURL + type.URL,
        classes: type.classes,
        tooltip: `Browse ${type.name} Content`,
      });
    }
  }

  openClipboardSnackbar(): void {
    this.snackbar.open('URL copied to clipboard', 'DISMISS', { duration: 4500 });
  }

  openMailApp(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const bodyFirstLine = `I saw this information from SAE International and I think you will find it very useful.\r\n`;
    const bodySecondLine = 'Title:' + ' ' + this.preTitleText + ' ' + this.titleText;
    const itemUrl = 'URL:' + ' ' + this.getShareUrl();
    const body = bodyFirstLine + bodySecondLine + LINEBREAK + itemUrl + ' ' + LINEBREAK + DISCLAIMER;
    const encodedBody = encodeURIComponent(body);
    const mailText = `mailto:?subject=FYI - Sharing an interesting article I came across on SAE Mobilus&body=${encodedBody}`;
    window.location.href = mailText;
  }

  getShareUrl(): string {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    return window.location.origin + this.seoUri;
  }
}
