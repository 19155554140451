import { FormArray } from '@angular/forms';
import { FacetType } from './facets.model';

export interface GroupFormModel {
  condition: string;
  rows: FormArray;
}

export interface RowFormModel {
  condition: string;
  value: any;
  valueLabel: any;
  fieldName: string;
  label: string;
  type: string;
  id: string;
}

export interface Criteria {
  condition: 'all' | 'any';
  rows: Array<RowFormModel | Criteria>;
}

export interface TransformedGroup {
  groupType: 'ADV';
  operator: Operators;
  query: Array<TransformedRow | TransformedGroup>;
}

export interface TransformedRow {
  field: { name: string };
  label: string;
  searchType: string;
  operator?: 'AND' | 'OR'; // applies to multiple values in values array
  values: Array<any>;
  valueLabels: Array<string>;
  rangeOperator?: string;
  id?: string;
}

export type Operators = '' | 'OR' | 'AND' | 'NOT';

export const CRITERIA_CONDITION_MAP: { [key: string]: Operators } = {
  all: 'AND',
  any: 'OR',
};

export const DATE_RANGE_MAP: { [key: string]: string } = {
  RANGE_GT: 'GT',
  RANGE_LT: 'LT',
  RANGE_EQ: 'EQ',
};

export const OPTIONS_DATE_MAP: { [key: string]: string } = {
  GT: 'after',
  LT: 'before',
  EQ: 'on',
};

export const OPTIONS_NUMBER_MAP: { [key: string]: string } = {
  GT: 'greater than',
  LT: 'less than',
  EQ: 'equal to',
};

export type ConditionValueDate = 'RANGE_GT' | 'RANGE_LT' | 'RANGE_EQ';
export type ConditionValueText = 'PREFIX' | 'TEXT' | 'TEXT_NOT';
export type ConditionValueNumeric = 'RANGE_GT' | 'RANGE_LT' | 'RANGE_EQ';
export type ConditionValueKeyword = 'KEYWORD' | 'KEYWORD_NOT';
export type ConditionValueBooleanText = 'TEXT' | 'TEXT_NOT';

export type ConditionValue =
  | ConditionValueDate
  | ConditionValueText
  | ConditionValueNumeric
  | ConditionValueKeyword
  | ConditionValueBooleanText;

export const OPTIONS_DATE: Array<{ label: string; value: ConditionValueDate }> = [
  {
    label: 'after',
    value: 'RANGE_GT',
  },
  {
    label: 'before',
    value: 'RANGE_LT',
  },
  {
    label: 'on',
    value: 'RANGE_EQ',
  },
];

export const OPTIONS_CONDITION: Array<{ label: string; value: ConditionValueText }> = [
  {
    label: 'begins with',
    value: 'PREFIX',
  },
  {
    label: 'contains',
    value: 'TEXT',
  },
  {
    label: 'does not contain',
    value: 'TEXT_NOT',
  },
];

export const OPTIONS_NUMERIC: Array<{ label: string; value: ConditionValueNumeric }> = [
  {
    label: 'greater than',
    value: 'RANGE_GT',
  },
  {
    label: 'less than',
    value: 'RANGE_LT',
  },
  {
    label: 'equals',
    value: 'RANGE_EQ',
  },
];

export const OPTIONS_BOOLEAN: Array<{ label: string; value: ConditionValueKeyword }> = [
  {
    label: 'is',
    value: 'KEYWORD',
  },
  {
    label: 'is not',
    value: 'KEYWORD_NOT',
  },
];

export const OPTIONS_BOOLEAN_TEXT: Array<{ label: string; value: ConditionValueBooleanText }> = [
  {
    label: 'is',
    value: 'TEXT',
  },
  {
    label: 'is not',
    value: 'TEXT_NOT',
  },
];

/**
 * Configuration for advanced search criteria rows
 */
export interface AdvSearchConditionConfig {
  /**
   * If true, use the built-in standard condition defaults
   * (will be overridden if facetTypeDefaultConditions and/or
   * fieldNameDefaultConditions are included)
   */
  useStandardConditionDefaults?: boolean;
  /**
   * Maps facet type to a default condition value
   */
  facetTypeDefaultConditions?: Partial<Record<FacetType, ConditionValue>>;
  /**
   * Maps field name to a default condition value
   */
  fieldNameDefaultConditions?: Record<string, ConditionValue>;
}
