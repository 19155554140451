import { Pipe, PipeTransform } from '@angular/core';
import { PersonCardInfo, PersonTagInfo } from '@sae/models';

@Pipe({
  name: 'initials',
})
export class PersonInitialsPipe implements PipeTransform {
  transform(person: PersonCardInfo | PersonTagInfo): string {
    const personCardInfo = person as PersonCardInfo;
    if (personCardInfo.user) {
      return `${personCardInfo.user.firstName ? personCardInfo.user.firstName.charAt(0) : ''}${personCardInfo.user.lastName ? personCardInfo.user.lastName.charAt(0) : ''}`;
    } else {
      const personTagInfo = person as PersonTagInfo;
      const names = personTagInfo.displayName.split(' ');
      return `${names[0].charAt(0)}${names[1].charAt(0)}`;
    }
  }
}
