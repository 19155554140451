import { Inject, Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { DocumentAsset } from '../models/api-models';
import { Asset } from '../models/common-interfaces';
import { DownloadManagerApiService } from './download-manager-api.service';
import { MobilusApiService } from './mobilus-api.service';
import { Api } from '../../../api';
import { SaeHttpResponse } from '@sae/models';
import { DownloadMeta } from '../models/download.model';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../env.config';
@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private dmService: DownloadManagerApiService,
    private mobilusService: MobilusApiService,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {}

  public downloadAsset(assetId: string): Observable<SaeHttpResponse<DownloadMeta>> {
    return this.mobilusService.getDownloadIdFromAssetId(assetId).pipe(
      map((resp) => resp.results[0]),
      switchMap((downloadId) => this.dmService.getDownload(downloadId, false))
    );
  }

  public getDownloadLinkForAsset(asset: Asset & DocumentAsset): string {
    return `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${Api.mobilus.url}/assets/${
      asset?.id ?? asset?.assetId
    }`;
  }
}
