import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'si-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() breadcrumbs!: unknown[];
  @Input() displayProp = 'name';
  @Input() required = false; // renders an asterix on the root
  @Input() error = false; // turns it red
  @Input() collapsible = true; // usually, breadcrumbs will be inside a mat-expansion-panel header. if not, disable this.
  @Output() goto = new EventEmitter<unknown>();

  getDisplayProperty(x: unknown): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (x as any)[this.displayProp];
  }
}
