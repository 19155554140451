import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { overrideInputs } from '@sae/base';

export type StackMaxResolution = 'mobile' | 'tablet' | 'notebook' | 'none' | null;

export interface CompoundConfig {
  classes?: string;
  flipped?: boolean;
  full?: boolean;
  spaced?: boolean;
  stackMaxResolution?: StackMaxResolution;
  suppressVerticalGap?: boolean;
  tight?: boolean;
  unusualVertical?: boolean;
}

@Component({
  selector: 'fs-part-compound',
  styleUrls: ['part-compound.component.scss'],
  templateUrl: './part-compound.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class PartCompoundComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: CompoundConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string;
  @Input() flipped: boolean;
  @Input() full: boolean;
  @Input() spaced: boolean;
  @Input() stackMaxResolution: StackMaxResolution; // null means no stack ever, otherwise stack at or below this resolution
  @Input() suppressVerticalGap: boolean;
  @Input() tight: boolean;
  @Input() unusualVertical: boolean;
  @Input() config: CompoundConfig; // [DEPRECATED - Will be removed] If provided, overrides other inputs

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
    if (changes['config']) {
      overrideInputs(this, this.config);
    }
  }
}
