import { Pagination } from "@sae/models";
import { Asset } from "../../shared/models/common-interfaces";

export interface DetailState {
  loadingAssets: boolean;
  dataSets: Asset[];
  pagination:Pagination;
  sortDir:string;
  detail2D3DUrl:string;
}

export const initialDetailState: DetailState = {
  loadingAssets: false,
  dataSets:[],
  pagination:null,
  sortDir: 'desc',
  detail2D3DUrl:""
};
