import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';

@Directive({
  selector: '[siThemeButton]',
})
export class ThemeButtonDirective implements OnInit {
  button: HTMLElement = this.el.nativeElement;
  wrapping: HTMLSpanElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private readonly platformId: unknown
  ) {
    // TODO: SSR
    if (isPlatformBrowser(this.platformId)) {
      this.wrapping = document.createElement('span'); // Create housing for effects
    }
  }

  main(): void {
    // TODO: SSR
    if (isPlatformServer(this.platformId)) return;
    this.button.classList.add('si-theme--button'); // Apply theming capability
    this.wrapping.classList.add('si-effects');

    const focus = document.createElement('span'); // Apply focus effect
    focus.classList.add('si-effect--focus');

    this.renderer.appendChild(this.el.nativeElement, this.wrapping);
    this.wrapping.appendChild(focus);
  }

  ngOnInit(): void {
    this.main();
  }

  @HostListener('click', ['$event']) onClick($event: MouseEvent): void {
    this.createRipple($event);
  }

  createRipple(event: MouseEvent): void {
    // TODO: SSR
    if (isPlatformServer(this.platformId)) return;
    const _w = this.button.clientWidth;
    const _h = this.button.clientHeight;
    const rippleTag = document.createElement('span'); // Add ripple effect
    rippleTag.classList.add('si-effect--ripple');
    const diameter = Math.max(_w, _h);
    const radius = diameter / 2;
    rippleTag.style.width = rippleTag.style.height = diameter + 'px';
    rippleTag.style.animationDuration = 150 + diameter + 'ms'; // compensate for button size differences
    let offsetX = 0;
    let offsetY = 0;

    if (event.clientX || event.clientY) {
      offsetX = event.clientX - (this.button.getBoundingClientRect().left + radius);
      offsetY = event.clientY - (this.button.getBoundingClientRect().top + radius);
    } else if (_w !== _h) {
      // Center ripple for enter key
      if (_h > _w) {
        offsetX = offsetX - (_h - _w) / 2;
      } else {
        offsetY = offsetY - (_w - _h) / 2;
      }
    }
    rippleTag.style.left = offsetX + 'px';
    rippleTag.style.top = offsetY + 'px';

    const rippleEl = this.wrapping.getElementsByClassName('si-effect--ripple')[0];
    if (rippleEl) {
      rippleEl.remove();
    }
    this.wrapping.appendChild(rippleTag);

    setTimeout(() => {
      rippleTag.remove();
    }, 2000); // remove after 2 seconds
  }
}
