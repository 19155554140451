<ng-template #template>
  <ng-container
    *ngIf="{
      masterPanelTitle: masterPanelTitle$ | async,
      contentGroup: contentGroup$ | async,
      contentSubgroup: contentSubgroup$ | async,
      selectedTopic: selectedTopic$ | async
    } as _"
  >
    <mat-toolbar class="si-toolbar si-toolbar--master">
      <div class="si-toolbar__h si-toolbar__h--full">
        <button class="si-button--wrap" [matMenuTriggerFor]="selectView">
          <div class="si-toolbar__h">
            <h3 class="si-toolbar__title si-impact__title">
              {{ masterPanelTitle$ | async }}
            </h3>
            <mat-icon class="si-icon" aria-hidden="true">keyboard_arrow_down</mat-icon>
          </div>
        </button>
        <mat-menu #selectView="matMenu" class="si-menu">
          <a
            [routerLink]="'/browse'"
            mat-menu-item
            [class.si-state--selected-not]="'Browse' !== _.masterPanelTitle"
            [state]="{ context: 'main_search' }"
          >
            <mat-icon aria-hidden="true">check</mat-icon>
            <span>Browse</span>
          </a>
          <ng-container *ngFor="let menu of browseOptions; let i = index">
            <mat-divider class="si-divider"></mat-divider>
            <label class="si-label" *ngIf="menu.list">{{ menu.list }}</label>
            <a
              [routerLink]="item.url"
              mat-menu-item
              *ngFor="let item of menu.items"
              [class.si-state--selected-not]="item.label !== _.masterPanelTitle"
              [state]="{ context: 'main_search' }"
              [attr.data-analytics]="item?.analyticsId ?? null"
            >
              <mat-icon aria-hidden="true" color="primary">check</mat-icon>
              <span>{{ item.label }}</span>
            </a>
          </ng-container>
        </mat-menu>
      </div>
      <si-seo-chiplist
        #chipList
        aria-label="Filter Selection"
        class="si-chiplist si-chiplist--master"
        *ngIf="(_.contentGroup && _.contentSubgroup) || _.selectedTopic"
      >
        <mat-chip *ngIf="_.contentGroup && _.contentSubgroup" class="si-chip si-chip--minor si-ellipsed">
          <a
            class="si-ellipsed"
            routerLink=".."
            #link
            [title]="'Clear Selected: ' + _.contentSubgroup"
            [state]="{ context: 'main_search' }"
          >
            {{ _.contentSubgroup }}
          </a>
          <mat-icon matChipRemove routerLink="..">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="_.selectedTopic" class="si-chip si-chip--minor si-ellipsed">
          <a
            class="si-ellipsed"
            routerLink="/topics"
            #link
            [title]="'Clear Selected: ' + _.selectedTopic.text"
            [state]="{ context: 'main_search' }"
          >
            {{ _.selectedTopic.text }}
          </a>
          <mat-icon matChipRemove routerLink="/topics">cancel</mat-icon>
        </mat-chip>
      </si-seo-chiplist>
    </mat-toolbar>
  </ng-container>
</ng-template>
