<mat-form-field class="si-field si-wd si-wd-full">
  <mat-chip-list #chipList [attr.aria-label]="label" class="si-chiplist">
    <mat-chip
      *ngIf="applyADAFix && (!chips || chips.length === 0)"
      class="si-visible--sr-only"
      (click)="userInputField.focus()"
    >
      <span>None</span>
    </mat-chip>
    <mat-chip
      *ngFor="let chip of chips"
      [removable]="removable"
      (removed)="removeChip(chip)"
      class="si-chip si-chip--complex si-chip--minor si-chip--contains"
    >
      {{ chip }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <mat-icon
      matPrefix
      class="si-icon si-icon--add"
      *ngIf="(maxItems === 0 && chips.length > 0) || (maxItems !== 1 && chips.length > 0 && chips.length < maxItems)"
      (click)="userInputField.focus()"
    >
      add_circle
    </mat-icon>
    <input
      siIncrementalSearch
      #userInputField
      autocomplete="off"
      [placeholder]="label"
      [matChipInputFor]="chipList"
      [matAutocomplete]="auto"
      [readonly]="maxItems > 0 && chips.length >= maxItems"
      [minChars]="minCharsForSearch"
      [debounceTime]="debounceTime"
      (searchChange)="performSearch($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" class="si-autocomplete" (optionSelected)="selectValue()">
    <mat-option class="si-option si-option--complex si-state--disabled" [disabled]="true" *ngIf="showLoadingCard">
      <si-basic-card class="si-component" materialIcon="refresh" [title]="loadingMessage"></si-basic-card>
    </mat-option>
    <mat-option
      class="si-option si-option--complex si-state--disabled"
      [disabled]="true"
      *ngIf="showResultsInvalidCard"
    >
      <si-basic-card
        class="si-component"
        materialIcon="block"
        [negative]="true"
        [title]="invalidMessage"
        [subTitle]="invalidActionPrompt"
      ></si-basic-card>
    </mat-option>
    <mat-option class="si-option si-option--complex" *ngIf="showResultsValidCard">
      <button class="si-wd-full si-button--wrap" mat-button>
        <si-basic-card
          class="si-component"
          materialIcon="done"
          [positive]="true"
          [title]="validMessage"
          [subTitle]="validActionPrompt"
          (click)="selectValue()"
        ></si-basic-card>
      </button>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
