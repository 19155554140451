import { inject, Inject, Injectable, InjectionToken } from '@angular/core';
import { Query, Store } from '@datorama/akita';
import { FeedbackConfig, ViewTemplate } from '@sae/models';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { StoresRegistry, StoresToken } from './../api-registry';

export interface ISessionService {
  showMobileNavMenu$: Observable<boolean>;
  showScrim$: Observable<boolean>;
  activeNavIcon$: Observable<string>;
  activeViewTemplate$: Observable<ViewTemplate>;
  isTakeoverActive$: Observable<boolean>;
  isToolPanelCollapsed$: Observable<boolean>;
  isMasterPanelCollapsed$: Observable<boolean>;
  masterWidth$: Observable<MasterWidth>;
  isWideFeature$: Observable<boolean>;
  appName$: Observable<string>;
  appLogoClass$: Observable<string>;
  showHomeButtonInEnterpriseMenu$: Observable<boolean>;
  showRegisterLoginOption$: Observable<boolean>;
  masterHeadFilter$: Observable<boolean>;
  showFeatures$: Observable<boolean>;
  showHomeNavIcon$: Observable<boolean>;
  showSearchNavIcon$: Observable<boolean>;
  showUpdatesNavIcon$: Observable<boolean>;
  showHelpNavIcon$: Observable<boolean>;
  feedbackConfig$: Observable<FeedbackConfig | undefined>;
  showCustomToolbar$: Observable<boolean>;
  getMockDataDelay(): number;
  getUseMockData(): boolean;
  getLogResponse(): boolean;
  setShowMobileNavMenu(showMobileNavMenu: boolean): void;
  setShowScrim(showScrim: boolean): void;
  setActiveNavIcon(activeNavIcon: string): void;
  setActiveViewTemplate(activeViewTemplate: ViewTemplate): void;
  setIsTakoverActive(isTakeoverActive: boolean): void;
  setIsToolPanelCollapsed(isToolPanelCollapsed: boolean): void;
  setIsMasterPanelCollapsed(isMasterPanelCollapsed: boolean): void;
  setMasterWidth(masterWidth: MasterWidth): void;
  setIsWideFeature(isWideFeature: boolean): void;
  setShowHomeButtonInEnterpriseMenu(
    showHomeButtonInEnterpriseMenu: boolean
  ): void;
  setAppName(appName: string): void;
  setAppLogoClass(appLogoClass: string): void;
  setUseMockData(useMockData: boolean): void;
  setMockDataDelay(mockDataDelayInMs: number): void;
  setMasterHeadFilter(masterHeadFilter: boolean): void;
  setShowFeatures(showFeatures: boolean): void;
  setShowHomeNavIcon(showHomeNavIcon: boolean): void;
  setShowUpdatesNavIcon(showUpdatesNavIcon: boolean): void;
  setShowHelpNavIcon(showHelpNavIcon: boolean): void;
  setFeedbackConfig(feedbackConfig: FeedbackConfig): void;
  setShowCustomToolbar(showCustomToolbar: boolean): void;
}

export type MasterWidth = 'normal' | 'wide' | 'narrow';

export interface SessionState {
  useMockData: boolean;
  mockDataDelayInMs: number;
  logResponse: boolean;
  showMobileNavMenu: boolean;
  showScrim?: boolean;
  activeNavIcon: string;
  activeViewTemplate: ViewTemplate;
  isTakeoverActive: boolean;
  isToolPanelCollapsed: boolean;
  isMasterPanelCollapsed: boolean;
  masterWidth: MasterWidth;
  isWideFeature: boolean;
  showHomeButtonInEnterpriseMenu: boolean;
  showRegisterLoginOption: boolean;
  appName: string;
  appLogoClass: string;
  masterHeadFilter: boolean;
  showFeatures: boolean;
  showHomeNavIcon: boolean;
  showSearchNavIcon: boolean;
  showUpdatesNavIcon: boolean;
  showHelpNavIcon: boolean;
  feedbackConfig: FeedbackConfig | undefined;
  showCustomToolbar: boolean;
}

// core classes for the Akita feature
@Injectable({ providedIn: 'root' })
export class SessionCoreQuery<
  TState extends SessionState
> extends Query<TState> {
  constructor(protected store: SessionCoreStore<TState>) {
    super(store);
  }
}

export class SessionCoreStore<
  TState extends SessionState
> extends Store<TState> {
  constructor(
    @Inject(StoresToken) storesRegistry: StoresRegistry,
    extendedState?: TState
  ) {
    const initialState =
      extendedState ?? (SessionCoreStore.createInitialState() as TState);
    super(initialState, { name: storesRegistry.session });
  }
  static createInitialState(): SessionState {
    return {
      useMockData: false,
      mockDataDelayInMs: 300,
      logResponse: false,
      showMobileNavMenu: true,
      showScrim: false,
      activeNavIcon: '',
      activeViewTemplate: ViewTemplate.MasterDetail,
      isTakeoverActive: false,
      isToolPanelCollapsed: false,
      isMasterPanelCollapsed: false,
      masterWidth: 'normal',
      isWideFeature: false,
      showHomeButtonInEnterpriseMenu: true,
      showRegisterLoginOption: false,
      appName: 'ChangeThis',
      appLogoClass: 'si-brand--sae',
      masterHeadFilter: false,
      showFeatures: true,
      showHomeNavIcon: false,
      showSearchNavIcon: false,
      showUpdatesNavIcon: true,
      showHelpNavIcon: true,
      feedbackConfig: undefined,
      showCustomToolbar: false,
    };
  }
}

export class SessionCoreBase<TState extends SessionState>
  implements ISessionService
{
  public showMobileNavMenu$ = this.query.select((s) => s.showMobileNavMenu);
  public showScrim$ = this.query.select((s) => s.showScrim);
  public activeNavIcon$ = this.query.select((s) => s.activeNavIcon);
  public isTakeoverActive$ = this.query.select((s) => s.isTakeoverActive);
  public isToolPanelCollapsed$ = this.query.select(
    (s) => s.isToolPanelCollapsed
  );
  public isMasterPanelCollapsed$ = this.query.select(
    (s) => s.isMasterPanelCollapsed
  );
  public masterWidth$ = this.query.select((s) => s.masterWidth);
  public isWideFeature$ = this.query.select((s) => s.isWideFeature);
  public appName$ = this.query.select((s) => s.appName);
  public appLogoClass$ = this.query.select((s) => s.appLogoClass);
  public showHomeButtonInEnterpriseMenu$ = this.query.select(
    (s) => s.showHomeButtonInEnterpriseMenu
  );
  public showRegisterLoginOption$ = this.query.select(
    (s) => s.showRegisterLoginOption
  );
  public masterHeadFilter$ = this.query.select((s) => s.masterHeadFilter);
  public showFeatures$ = this.query.select((s) => s.showFeatures);
  public showHomeNavIcon$ = this.query.select((s) => s.showHomeNavIcon);
  public showSearchNavIcon$ = this.query.select((s) => s.showSearchNavIcon);
  public showUpdatesNavIcon$ = this.query.select((s) => s.showUpdatesNavIcon);
  public showHelpNavIcon$ = this.query.select((s) => s.showHelpNavIcon);
  public activeViewTemplate$ = this.query
    .select((s) => s.activeViewTemplate)
    .pipe(delay(0));
  public showCustomToolbar$ = this.query.select((s) => s.showCustomToolbar);
  public feedbackConfig$ = this.query.select((s) => s.feedbackConfig);

  constructor(
    protected readonly store: SessionCoreStore<TState>,
    protected readonly query: SessionCoreQuery<TState>
  ) {}

  getMockDataDelay(): number {
    return this.query.getValue().mockDataDelayInMs;
  }
  getUseMockData(): boolean {
    return this.query.getValue().useMockData;
  }
  getLogResponse(): boolean {
    return this.query.getValue().logResponse;
  }
  setShowMobileNavMenu(showMobileNavMenu: boolean): void {
    this.store.update({ showMobileNavMenu } as TState);
  }
  setShowScrim(showScrim: boolean): void {
    this.store.update({ showScrim } as TState);
  }
  setActiveNavIcon(activeNavIcon: string): void {
    // should match feature.name - see 'seo-feature-toolbar'
    this.store.update({ activeNavIcon } as TState);
  }
  setActiveViewTemplate(activeViewTemplate: ViewTemplate): void {
    this.store.update({ activeViewTemplate } as TState);
  }
  setIsTakoverActive(isTakeoverActive: boolean): void {
    this.store.update({ isTakeoverActive } as TState);
  }
  setIsToolPanelCollapsed(isToolPanelCollapsed: boolean): void {
    this.store.update({ isToolPanelCollapsed } as TState);
  }
  setIsMasterPanelCollapsed(isMasterPanelCollapsed: boolean): void {
    this.store.update({ isMasterPanelCollapsed } as TState);
  }
  setMasterWidth(masterWidth: MasterWidth): void {
    this.store.update({ masterWidth } as TState);
  }
  setIsWideFeature(isWideFeature: boolean): void {
    this.store.update({ isWideFeature } as TState);
  }
  setShowHomeButtonInEnterpriseMenu(
    showHomeButtonInEnterpriseMenu: boolean
  ): void {
    this.store.update({ showHomeButtonInEnterpriseMenu } as TState);
  }
  setAppName(appName: string): void {
    this.store.update({ appName } as TState);
  }
  setAppLogoClass(appLogoClass: string): void {
    this.store.update({ appLogoClass } as TState);
  }
  setUseMockData(useMockData: boolean): void {
    this.store.update({ useMockData } as TState);
  }
  setMockDataDelay(mockDataDelayInMs: number): void {
    this.store.update({ mockDataDelayInMs } as TState);
  }
  setMasterHeadFilter(masterHeadFilter: boolean): void {
    this.store.update({ masterHeadFilter } as TState);
  }
  setShowFeatures(showFeatures: boolean): void {
    this.store.update({ showFeatures } as TState);
  }
  setShowHomeNavIcon(showHomeNavIcon: boolean): void {
    this.store.update({ showHomeNavIcon } as TState);
  }
  setShowSearchNavIcon(showSearchNavIcon: boolean): void {
    this.store.update({ showSearchNavIcon } as TState);
  }
  setShowUpdatesNavIcon(showUpdatesNavIcon: boolean): void {
    this.store.update({ showUpdatesNavIcon } as TState);
  }
  setShowHelpNavIcon(showHelpNavIcon: boolean): void {
    this.store.update({ showHelpNavIcon } as TState);
  }
  setFeedbackConfig(feedbackConfig: FeedbackConfig): void {
    this.store.update({ feedbackConfig } as TState);
  }
  setShowCustomToolbar(showCustomToolbar: boolean): void {
    this.store.update({ showCustomToolbar } as TState);
  }
}

// default implementations of store and query
@Injectable({ providedIn: 'root' })
export class DefaultSessionStore extends SessionCoreStore<SessionState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super(storesRegistry, DefaultSessionStore.createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class DefaultSessionQuery extends SessionCoreQuery<SessionState> {
  constructor(protected store: DefaultSessionStore) {
    super(store);
  }
}

// The core service itself (a default implementation of the 'core base' svc)
export class SessionCoreService extends SessionCoreBase<SessionState> {
  constructor(
    protected readonly store: DefaultSessionStore,
    protected readonly query: DefaultSessionQuery
  ) {
    super(store, query);
  }
}

export const SESSION_TOKEN = new InjectionToken<ISessionService>(
  'Core Session Service',
  {
    providedIn: 'root',
    factory: () =>
      new SessionCoreService(
        inject(DefaultSessionStore),
        inject(DefaultSessionQuery)
      ),
  }
);
