import { createReducer, on } from '@ngrx/store';
import { AuthActions, AuthStoreState } from '.';
export const authReducer = createReducer(
  AuthStoreState.initialAuthState,

  on(AuthActions.logInToSubscription, (state) => ({ ...state, loadingLogIn: true, logInError: undefined })),
  on(AuthActions.logInToSubscriptionSucceeded, (state) => ({ ...state, loadingLogIn: false })),
  on(AuthActions.logInToSubscriptionFailed, (state, { error }) => ({
    ...state,
    loadingLogIn: false,
    logInError: error?.status === 401 ? 'Invalid subscription login or password' : 'Something went wrong',
  })),
  on(AuthActions.setLogInToSubErrorMessage, (state, { errorMessage }) => ({
    ...state,
    logInError: errorMessage,
  })),

  on(AuthActions.logOut, (state) => ({ ...state, logOutInProgress: true, logOutError: undefined })),
  on(AuthActions.logOutSucceeded, () => ({ ...AuthStoreState.initialAuthState })),
  on(AuthActions.logOutFailed, (state, { error }) => ({
    ...state,
    logOutInProgress: false,
    logOutError: error?.status === 401 ? error.message : 'Something went wrong',
  }))
);
