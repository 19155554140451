<form class="si-form si-form--cell" [class.si-tight]="data" [formGroup]="postForm">
  <mat-form-field class="si-field si-wd si-wd-full">
    <input
      matInput
      formControlName="title"
      placeholder="Title"
      class="si-input"
      libSetAutofocus
      (blur)="exitEdit()"
      (keydown.enter)="exitEdit()"
    />
    <button matSuffix *ngIf="data" class="si-button si-button--icon" aria-label="Done" matTooltip="Done">
      <mat-icon color="primary" (click)="exitEdit()">done</mat-icon>
    </button>
  </mat-form-field>
</form>
