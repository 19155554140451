import { InjectionToken } from '@angular/core';
import { AppConfig } from './models/app-config.model';
import { ConfigJSON } from './models/environment-config.model';

/**
 * Static, per-app configurations. Apps must provide their own AppConfig on this token.
 */
export const APP_CONFIG_TOKEN = new InjectionToken<AppConfig>('AppConfig');

/**
 * For Angular Universal apps, this will be read on the server side, provided within the server.ts, and accessible to apps and components during SSR and on the client side
 * It will be used by the ProteusAppInitService to determine the current environment (instead of `window.location`).
 */
export const CONFIG_JSON = new InjectionToken<ConfigJSON>('config.json');

/**
 * For SSR, we can get this on the server side and provide it to the client using TransferState,
 * and use it to help replace window.location methods throughout our codebase.
 */
export const HOST_URL = new InjectionToken<string>('Host URL');
