import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { overrideInputs } from '@sae/base';

export type SignalOption = 'Read' | 'Unread';

export interface PartSignalItem {
  classes?: string;
  description?: string;
  signal?: SignalOption;
}

@Component({
  selector: 'fs-part-signal',
  styleUrls: ['part-signal.component.scss'],
  templateUrl: './part-signal.component.html',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class PartSignalComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartSignalItem;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() classes: string;
  @Input() description: string; // Optional, provides a description for the signal
  @Input() signal: SignalOption;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }
  }
}
