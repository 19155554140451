<div class="si-dialog">
  <div class="si-dialog__h">
    <mat-icon aria-hidden="true" *ngIf="data.icon" class="si-icon">
      {{ data.icon }}
    </mat-icon>
    <h2 class="si-dialog__title">
      {{ data.title ?? 'Confirm' }}
    </h2>
    <span class="si-expanse"></span>
    <button mat-icon-button matTooltip="Close" aria-label="Close" class="si-icon si-button--close" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="si-dialog__b">
    <div mat-dialog-content class="si-dialog__c">{{ data.message }}</div>
    <div mat-dialog-actions class="si-dialog__a">
      <button mat-flat-button class="si-button si-button--secondary" (click)="negativeAction.emit()">
        {{ data.negativeText ?? 'No' }}
      </button>
      <button mat-flat-button class="si-button si-button--primary" cdkFocusInitial (click)="positiveAction.emit()">
        {{ data.positiveText ?? 'Yes' }}
      </button>
    </div>
  </div>
</div>
