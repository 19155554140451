import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BrowseState } from '../../../../store/browse/browse.state';
import { BrowseSelectors } from '../../../../store/browse';
import { BROWSE_HOME_OPTS } from '../../../../store/browse/browse.models';

@Component({
  selector: 'mobi-browse-by-master-panel-header',
  templateUrl: './browse-by-master-panel-header.component.html',
})
export class BrowseByMasterPanelHeaderComponent implements OnInit {
  @ViewChild('template', { static: true }) template;

  public masterPanelTitle$ = this.store.select(BrowseSelectors.masterPanelTitle);
  public contentGroup$ = this.store.select(BrowseSelectors.selectContentGroup);
  public contentSubgroup$ = this.store.select(BrowseSelectors.selectContentSubgroup);
  public selectedTopic$ = this.store.select(BrowseSelectors.selectSelectedTopic);
  public browseOptions = BROWSE_HOME_OPTS;

  constructor(private viewContainerRef: ViewContainerRef, private store: Store<BrowseState>) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
}
