import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Comment } from '@sae/models';
import { Subscription, catchError, combineLatest, filter, map, tap } from 'rxjs';
import { ContentView, Revision, SubscriptionAdministrators } from '../../../models/content.model';
import { AccessLevel, DetailsHelperService } from '../../../services/details-helper.service';
import { AnnotationViewComponent } from './annotation-view/annotation-view.component';
import { selectFragment } from '../../../../../store/root.selectors';
import { ContentService } from '../../../../../shared/state/content.service';
import { AuthService } from '../../../../../shared/state/auth.service';
import { SubscriptionLoginDialogComponent } from '../../../../../shared/components/subscription-login-dialog/subscription-login-dialog.component';
import { DISCLAIMER, LINEBREAK } from '../../../../../shared/string-files/common-strings';
import { SubscriptionsService } from '../../../../../shared/state/subscriptions.service';
import { RequestAccessDialogComponent } from '../../../../../shared/components/request-access-dialog/request-access-dialog.component';
@UntilDestroy()
@Component({
  selector: 'mobi-detail-view',
  templateUrl: './detail-view.component.html',
})
export class DetailViewComponent implements OnInit, OnDestroy {
  @Input() content: ContentView;
  @ViewChild('annotationView') annotationView: AnnotationViewComponent;
  public secondaryText = 'Cancel';
  public primaryText = 'Yes, View Document';
  public actionText = 'Click to continue';
  public viewLoading$ = this.detailsHelperService.viewLoading$;
  public fragment$ = this.store.select(selectFragment);
  public annotations: Partial<Comment>[] = [];

  public compareAgainstId: number;
  public viewMode$ = this.fragment$.pipe(
    filter((fragment) => !!fragment),
    map((fragment) => {
      if (fragment === 'view') {
        return 'Document';
      } else if (fragment === 'view&mode=annotation') {
        if (!this.authService.isLoggedIn()) {
          this.accessLevel = 'USER_LOGIN';
          this.actionText = 'You need to log in to annotate this document';
          this.primaryText = 'Login';
          this.secondaryText = 'Cancel';
        }
        return 'Annotation';
      } else if (fragment.includes('view&mode=redline&compare=')) {
        this.compareAgainstId = parseInt(fragment.split('=')[2], 10);
        return 'Redlining';
      }
    })
  );

  public redlineOptions: Revision[];

  public isLoggedIn$ = this.authService.isLoggedIn$;
  public fullScreen$ = this.contentService.fullScreen$;

  public getSubAdminSubscription: Subscription;
  public subAdminData: SubscriptionAdministrators[];

  public accessLevel: AccessLevel;

  constructor(
    private contentService: ContentService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private detailsHelperService: DetailsHelperService,
    private store: Store,
    private subscriptionsService: SubscriptionsService
  ) {}

  ngOnInit(): void {
    combineLatest([this.fragment$, this.contentService.content$])
      .pipe(untilDestroyed(this))
      .subscribe(([fragment, content]) => {
        this.accessLevel = this.detailsHelperService.getAccessLevel(content?.accessProperties);
        if (this.accessLevel === 'METERED') {
          this.actionText =
            'Viewing this document will count as a download for your subscription. Are you sure you want to proceed?';
        } else if (this.accessLevel === 'REQUEST') {
          this.actionText = 'Your administrator requires permission before this content can be viewed or downloaded';
          this.primaryText = 'Request Access';
          this.secondaryText = 'Cancel';
          this.getSubAdminData();
        } else if (this.accessLevel === 'EXHAUSTED') {
          this.actionText =
            'The download limit has been exhausted for the subscription that you are logged into. Please reach out to your Account Administrator to explore options to avail this publication.';
          this.primaryText = 'Access Another Subscription';
          this.secondaryText = 'Cancel';
        } else if (this.accessLevel === 'SUB_LOGIN') {
          const subscriptions = this.subscriptionsService.getSubscriptions();
          if (!subscriptions || subscriptions?.length === 0) {
            this.actionText = 'You need to log in to a subscription to view this document';
            this.primaryText = 'Login';
            this.secondaryText = 'Cancel';
          } else {
            const subsToText = subscriptions.map((sub) => `"${sub.name}"`).join(', ');
            this.actionText = `This document is not included within your ${subsToText} subscription${
              subscriptions?.length > 1 ? 's.' : '.'
            }`;
            this.primaryText = 'Access Another Subscription';
            this.secondaryText = 'Cancel';
          }
        }
      });

    this.redlineOptions = this.content?.standardAttributes?.revisions.filter(
      (revision) => revision.cid !== this.content.id && revision.hasXml
    );
  }

  primaryClicked(): void {
    if (this.accessLevel === 'METERED') {
      this.accessLevel = 'FULL';
    } else if (this.accessLevel === 'REQUEST') {
      this.dialog.open(RequestAccessDialogComponent, {
        data: {
          title: this.content?.title,
          code: this.content?.code,
          subSessions: this.subscriptionsService.getSubscriptions(),
          itemUrl: window.location.href.split('#')[0],
        },
      });
    } else if (this.accessLevel === 'SUB_LOGIN' || this.accessLevel === 'EXHAUSTED') {
      this.dialog.open(SubscriptionLoginDialogComponent);
    } else if (this.accessLevel === 'USER_LOGIN') {
      this.authService.login();
    }
  }

  secondaryClicked(): void {
    this.router.navigateByUrl(this.router.url.split('#')[0]);
  }

  showAnnotations(): void {
    this.router.navigateByUrl(this.router.url.split('#')[0] + '#view&mode=annotation');
  }

  hideAnnotations(): void {
    this.router.navigateByUrl(this.router.url.split('#')[0] + '#view');
  }

  clearAnnotations(): void {
    this.annotationView.clearAnnotations();
  }

  login(): void {
    this.authService.login();
  }
  toggleFullScreen(enabled: boolean): void {
    this.contentService.setFullScreenMode(enabled);

    setTimeout(() => {
      if (enabled) {
        const container = document.getElementById('si-fullscreen-container');

        if (container) {
          container.scrollTo(0, 0);
        }
      }
    });
  }

  onViewChipClicked(chip: string): void {
    let url = this.router.url.split('#')[0] + '#view';
    if (chip === 'Annotation') {
      url += `&mode=annotation`;
    }
    this.router.navigateByUrl(url);
  }

  redline(revision: Revision): void {
    const url = this.router.url.split('#')[0] + `#view&mode=redline&compare=${revision.cid}`;
    this.router.navigateByUrl(url);
  }

  sendRequestEmail(content: ContentView): void {
    if (this.subAdminData.length === 0) {
      this.actionText =
        'There are no Administrators on the subscription. Please contact SAE Sales team at customersales@sae.org';
      this.primaryText = '';
      this.secondaryText = '';
      return;
    }
    const bodyFirstLine = `Dear SAE MOBILUS Administrator,${LINEBREAK}`;
    const bodySecondLine = `A request to download content has been received from a user within your organization. The information and justification provided by the user for the request is as follows:${LINEBREAK}`;
    const bodyProductIdLine = 'Product Id: ' + content?.code;
    const publicationTitle = `Publication Title: ${content?.title}`;
    const contentUrl = `Content URL: ${window.location.origin}${window.location.pathname} ${LINEBREAK}`;
    const bodyMainLine = `To approve & download this document for the requester, please login to your SAE Mobilus ${window.location.origin} account & your personal login account. Once you are logged into these accounts, please search for AS9100D or select the link above which will take you directly to the Summary and Detail page of the publication where you can access the download button. Once you have downloaded the document, please consider reaching out to the requester so that they can have access to this document.`;
    const bodyLastLine = `If you do not wish to approve the request, no further action is required. However, it is recommended that a status request is provided to the original requester.`;
    const body =
      bodyFirstLine +
      LINEBREAK +
      bodySecondLine +
      LINEBREAK +
      bodyProductIdLine +
      LINEBREAK +
      publicationTitle +
      LINEBREAK +
      contentUrl +
      LINEBREAK +
      bodyMainLine +
      LINEBREAK +
      bodyLastLine +
      LINEBREAK +
      DISCLAIMER;
    const encodedBody = encodeURIComponent(body);
    const emailAddresses = this.subAdminData.map((admin) => admin.user.emailAddress).join('; ');
    const mailText = `mailto:${emailAddresses}?subject=SAE MOBILUS Download Request&body=${encodedBody}`;
    window.location.href = mailText;
  }

  getSubAdminData(): void {
    const subscriptionId = this.content.accessProperties.accessDetails.subscriptionId;
    this.getSubAdminSubscription = this.contentService
      .getSubAdminData(subscriptionId)
      .pipe(
        tap((data) => {
          this.subAdminData = data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.subAdminData = [];
          throw error;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.getSubAdminSubscription) {
      this.getSubAdminSubscription.unsubscribe();
    }
  }
}
