import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../../../../shared/state/content.service';

@Component({
  selector: 'mobi-detail-citation',
  templateUrl: 'detail-citation.component.html',
})
export class DetailCitationComponent implements OnInit {
  baseUrl = this.route.snapshot.parent.url;

  public crossReferences$ = this.contentService.crossReferences$;
  public content$ = this.contentService.content$;

  constructor(private route: ActivatedRoute, private contentService: ContentService) {}

  ngOnInit(): void {
    setTimeout(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__dimensions_embed.addBadges();
      } catch (ignored) {
        // Fallback
      }
    });
  }
}
