import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';

// Directive adds classes based on the element's width.  These classes can be used as breakpoints for writing CSS.

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[libDetectBreakpointWidth]',
  standalone: true,
})
export class DetectBreakpointWidthDirective implements AfterViewInit, OnDestroy {
  lastWidth!: number;
  selObserver: ResizeObserver;
  isIE: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private readonly platformId: unknown
  ) {
    // TODO: SSR
    if (isPlatformBrowser(this.platformId)) {
      this.selObserver = new ResizeObserver(() => this.main());
      this.isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    }
  }

  @HostListener('window:resize') windowResize(): void {
    //  Fire the main function again if the window resizes
    this.main();
  }

  @HostListener('window:onload') windowOnload(): void {
    //  Fire the main function again if the window loads
    this.main();
  }

  private main(): void {
    // TODO: SSR
    if (isPlatformServer(this.platformId)) return;
    if (this.isIE === false) {
      const sel = this.el.nativeElement;
      const width = this.el.nativeElement.offsetWidth as number;
      const toAdd = [];

      for (let bp = 1; bp * 64 < width + 1; bp++) {
        toAdd.push(`si-bp-${bp}`);
      }

      toAdd.push(`si-bp-max-${Math.floor(width / 64)}`);
      toAdd.push(`si-bpd-max-${Math.floor(width / 128)}`);
      toAdd.push(`si-bpdd-max-${Math.floor(width / 256)}`);
      toAdd.push(`si-bpddd-max-${Math.floor(width / 512)}`);

      this.renderer.setAttribute(sel, 'class', toAdd.join(' '));
    }
  }

  ngAfterViewInit(): void {
    this.selObserver?.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.selObserver?.unobserve(this.el.nativeElement);
  }
}
