import { SaeEnvironmentConfig } from '@sae/base';

export interface MobilusProteusConfig extends SaeEnvironmentConfig {
  sso: {
    loginUrl: string;
    logoutUrl: string;
    tokenEndpoint: string;
    userinfoEndpoint: string;
  };
  maintenance: boolean;
  mobilusAboutUsPageUrl: string;
  mobilusContactUsPageUrl: string;
  mobilusAnnouncementUrl: string;
  hero_images: string[];
  journalDetailUrl: string;
  msgLinkToCms: string;
  mobilusHelpUrl: string;
  brxHelpMenuUrl: string;
  brx14MobilusUrl: string;
  baseURL: string;
}

export const defaultEnvironmentConfig: MobilusProteusConfig = {
  baseURL: 'https://saemobilus.sae.org',
  services: {
    apiRootUrl: 'https://dsa.fullsight.org/api',
  },
  metrics: {
    useApm: true,
    apm: 'https://sae-prod.apm.us-east-1.aws.found.io',
    apmUserInteractions: true,
    apmServiceName: 'mobilus',
  },
  auth: {
    url: 'https://identity.sae.org/auth/',
    realm: 'SAE',
    clientId: 'app_mobilus',
    bearerExcludedUrls: [
      'https://dsa.fullsight.org/api/v2/mobilus/subscriptions/sessions',
      'https://wcm14.sae.org/site/mobilus-brx/restservices/',
    ],
  },
  sso: {
    loginUrl: 'https://dsa.fullsight.org/api/v1/authentication/oauth/authorize',
    logoutUrl: 'https://dsa.fullsight.org/api/v1/authentication/oauth/logout',
    tokenEndpoint: 'https://dsa.fullsight.org/api/v1/authentication/oauth/token',
    userinfoEndpoint: 'https://dsa.fullsight.org/api/v1/authentication/oauth/userinfo',
  },
  maintenance: false,
  mobilusAboutUsPageUrl: 'https://www.sae.org/shared-content/restservices/mobilus/about-us',
  mobilusContactUsPageUrl: 'https://www.sae.org/shared-content/restservices/mobilus/contact-us',
  mobilusAnnouncementUrl: 'https://www.sae.org/restservices/announcement',
  hero_images: [
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/18_wheeler.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/accident_reconstruction.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/airplane_interior.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/drone.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/electric_vehicle.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/empty_highway.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/energy_sources.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/engine.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/environment_emissions.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/flying_taxi.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/jet.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/materials.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/mining_construction.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/satellite.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/sustainability_scooters.jpg',
    'https://sae-static-content-prod.cl.sae.org/hero/mobilityrxiv/tires.jpg',
  ],
  journalDetailUrl: 'https://www.sae.org/restservices/journal',
  msgLinkToCms: 'https://sae.org/cms',
  mobilusHelpUrl: 'https://wcm14.sae.org/site/mobilus-brx/restservices/page/mobilus-brx/content/help/home',
  brx14MobilusUrl: 'https://wcm14.sae.org/site/mobilus-brx/restservices',
  brxHelpMenuUrl:
    'https://wcm14.sae.org/site/mobilus-brx/restservices/configuration?project=mobilus-brx&node-type=hst%3Asitemenus&node-name=main',
  enterpriseMenu: {
    apps: [
      {
        name: 'SAE International Site',
        label: 'SAE International Site',
        messagesKey: 'sae-dot-org',
        href: 'https://www.sae.org/',
        show: true,
      },
      {
        name: 'SAE MobilityRxiv',
        label: 'SAE MobilityRxiv',
        messagesKey: 'mobility-rxiv',
        href: 'https://mobilityrxiv.sae.org',
        show: true,
      },
      {
        name: 'SAE Mobilus (New)',
        label: 'SAE Mobilus (New)',
        messagesKey: 'mobilus',
        href: 'https://saemobilus.sae.org/',
        show: true,
        currentApp: true,
      },
      {
        name: 'SAE Mobilus (Classic)',
        label: 'SAE Mobilus (Classic)',
        messagesKey: 'mobilus',
        href: 'https://saemobilus-classic.sae.org/',
        show: true,
      },
      {
        name: 'SAE OnQue',
        label: 'SAE OnQue',
        messagesKey: 'digital-standards',
        href: 'https://onque.sae.org',
        show: true,
      },
      {
        name: 'SAE StandardsWorks',
        label: 'SAE StandardsWorks',
        messagesKey: 'standards-works',
        href: 'https://standardsworks.sae.org/',
        show: true,
      },
      {
        name: 'Proxy Works',
        label: 'SAE Proxy Works',
        messagesKey: 'proxy-works',
        href: 'https://dev.sae.org/servlets/works',
        show: false,
      },
    ],
    images: {
      appLogo: 'https://sae-static-content-prod.cl.sae.org/logos/svg/sae-mobilus_rev.svg',
      saeLogo: 'https://sae-static-content-prod.cl.sae.org/logos/sae-logo.png',
    },
    links: {
      saeOrg: 'https://www.sae.org/',
      about: 'https://www.sae.org/about/',
      contact: 'https://www.sae.org/about/contact/',
      legalPolicies: 'https://www.sae.org/about/legal-policies',
    },
  },
  featureFlags: {
    show_users_folder: false,
    enable_advanced_search: false,
  },
};
