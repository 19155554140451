import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { EnhancedTreeNode } from '@sae/components';
import { ContentView, CrossReference, JournalBrxDetail } from '../../modules/detail/models/content.model';
import { Asset } from '../models/common-interfaces';
import { Revision, SearchResultEntry } from '../models/search-result.model';
import { DigitalStandard } from './digital-standards.service';
import { MobilusPubDocument } from '../models/api-models';
import { JournalBrowseCategoryNodes } from './content.service';

export type JournalBrowseCategory = 'E-FIRST' | 'OPEN ACCESS' | 'FREE' | 'VOLUME ISSUE' | 'NONE';
export interface DetailsConfiguration {
  header_aux_text: string;
  panel_title: string;
  panel_enabled: boolean;
  panel_collapse_text: string;
  panel_type: 'book' | 'journal' | 'standard';
  detail_masthead_subheading_type: 'journal' | 'code' | 'status';
  detail_masthead_title_with_code: boolean;
  detail_onque_link: string;
  detail_description_summary_title: string;
  detail_show_features: boolean;
  detail_show_event: boolean;
  detail_show_digital_standard: boolean;
  detail_show_issuing_committee: boolean;
  detail_show_authors: boolean;
  detail_show_abstract: boolean;
  detail_show_rationale: boolean;
  detail_show_tags: boolean;
  detail_show_details: boolean;
  detail_show_doi: boolean;
  detail_show_isbn: boolean;
  detail_show_citations_badge: boolean;
  detail_show_page_count: boolean;
  detail_show_table_of_contents: boolean;
  detail_show_additional_details: boolean;
  detail_show_publisher: boolean;
  detail_show_pub_date: boolean;
  detail_show_code: boolean;
  detail_show_selected_type: boolean;
  detail_show_standard_status: boolean;
  detail_show_language: boolean;
  detail_show_country: boolean;
  detail_show_view: boolean;
  detail_show_2d_3d: boolean;
  detail_show_data_sets: boolean;
  detail_show_references: boolean;
  detail_show_related: boolean;
  detail_show_citation: boolean;
  detail_show_alert: boolean;
  detail_show_length: boolean;
  detail_show_series: boolean;
  detail_show_browse: boolean;
  detail_show_subscriptions: boolean;
  detail_show_authoring: boolean;
  detail_show_provide_feedback: boolean;
  detail_show_course_outline: boolean;
  detail_show_materials_and_requirements: boolean;
  detail_show_instructors: boolean;
  detail_show_learning_objective: boolean;
  detail_show_audience: boolean;
  detail_show_testimonials: boolean;
  detail_show_prerequisites: boolean;
  detail_show_course_duration: boolean;
  detail_show_course_credit: boolean;
  detail_description_tab_title: string;
  detail_show_indexed_in: boolean;
  detail_show_editorial_board: boolean;
  detail_show_aim_and_scope: boolean;
  detail_show_submit_manuscript: boolean;
}

export interface ContentState {
  selectedRevision: Revision;
  routeLoading: boolean;
  relatedResults?: MobilusPubDocument[];
  relatedPageNum?: number;
  relatedPageSize?: number;
  relatedTotal?: number;
  relatedLoading: boolean;
  alertLoading?: boolean;
  htmlViewLoading: boolean;
  config: DetailsConfiguration;
  crossReferences: CrossReference[];
  digitalStandard: DigitalStandard;
  contentView: ContentView;
  returnUrl: Array<{ url: string; title: string }>;
  fileFormats: Asset[];
  fileFormatsError: boolean;
  inMyLibrary: boolean;
  sortBy: string;
  sortDir: SortDirection;
  searchTerm: string;
  fullScreen: boolean;
  journalBrowseSortBy?: string;
  journalBrowseSortDir?: SortDirection;
  journalBrowsePageNum?: number;
  journalBrowsePageSize?: number;
  journalBrowseResults?: MobilusPubDocument[];
  journalBrowseLoading?: boolean;
  journalBrowseTotal?: number;
  journalBrowseSearchTerm?: string;
  journalBrowseCategory: JournalBrowseCategory;
  journalBrowseCategoryNodes: EnhancedTreeNode[];
  journalBrxDetail: JournalBrxDetail;
}

function createInitialState(): ContentState {
  return {
    selectedRevision: null,
    routeLoading: false,
    relatedResults: [],
    relatedPageNum: 1,
    relatedPageSize: 50,
    relatedTotal: 0,
    relatedLoading: false,
    htmlViewLoading: false,
    crossReferences: [],
    digitalStandard: null,
    contentView: null,
    returnUrl: [],
    fileFormats: [],
    fileFormatsError: false,
    inMyLibrary: false,
    sortBy: 'pubDate',
    sortDir: 'desc',
    searchTerm: '',
    journalBrowsePageNum: 0,
    journalBrowsePageSize: 50,
    journalBrowseSortBy: 'pub_date',
    journalBrowseSortDir: 'desc',
    journalBrowseResults: [],
    journalBrowseLoading: false,
    journalBrowseTotal: 0,
    journalBrowseSearchTerm: '',
    journalBrowseCategory: 'NONE',
    journalBrowseCategoryNodes: JournalBrowseCategoryNodes,
    config: {
      header_aux_text: null,
      detail_description_summary_title: 'Abstract',
      detail_show_2d_3d: false,
      detail_show_abstract: false,
      detail_show_additional_details: false,
      detail_show_authors: false,
      detail_show_citation: false,
      detail_show_data_sets: false,
      detail_show_details: false,
      detail_show_features: false,
      detail_show_features_adjacent: false,
      detail_show_rationale: false,
      detail_show_references: false,
      detail_show_related: false,
      detail_show_table_of_contents: false,
      detail_show_tags: false,
      detail_show_view: false,
      detail_show_citations_badge: false,
      detail_show_code: false,
      detail_show_country: false,
      detail_show_digital_standard: false,
      detail_show_doi: false,
      detail_show_isbn: false,
      detail_show_event: false,
      detail_show_issuing_committee: false,
      detail_show_language: false,
      detail_show_page_count: false,
      detail_show_pub_date: false,
      detail_show_publisher: false,
      detail_show_selected_type: false,
      detail_show_standard_status: false,
      detail_masthead_subheading_type: 'code',
      detail_masthead_title_with_code: false,
      detail_onque_link: null,
      panel_collapse_text: null,
      panel_enabled: false,
      panel_title: null,
      panel_type: null,
      detail_show_alert: false,
      detail_show_length: false,
      detail_show_series: false,
      detail_show_browse: false,
      detail_show_subscriptions: false,
      detail_show_authoring: false,
      detail_show_provide_feedback: false,
      detail_show_course_outline: false,
      detail_show_materials_and_requirements: false,
      detail_show_instructors: false,
      detail_show_learning_objective: false,
      detail_show_audience: false,
      detail_show_testimonials: false,
      detail_show_prerequisites: false,
      detail_show_course_duration: false,
      detail_show_course_credit: false,
      detail_description_tab_title: 'Description',
      detail_show_indexed_in: false,
      detail_show_editorial_board: false,
      detail_show_aim_and_scope: false,
      detail_show_submit_manuscript: false,
    },
    fullScreen: false,
    journalBrxDetail: null,
  } as ContentState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'content', resettable: true })
export class ContentStore extends EntityStore<ContentState> {
  constructor() {
    super(createInitialState());
  }
}
