<ul
  class="rc-breadcrumb--temp si-card__breadcrumb si-state--length si-state--length-{{
    breadcrumbs.length
  }}"
  *ngIf="breadcrumbs"
>
  <ng-container *ngFor="let b of breadcrumbs; let i = index">
    <li
      class="si-card__breadcrumb__item si-when-collapsed"
      *ngIf="i === 0 && collapsible"
    >
      <strong [class.rc-error--temp]="error"
        >{{ getDisplayProperty(b) }}<span *ngIf="required"> *</span></strong
      >
    </li>
    <li
      class="si-card__breadcrumb__item si-when-expanded"
      (click)="$event.stopPropagation()"
      *ngIf="i === 0 && breadcrumbs.length > 5"
    >
      <strong>
        <a
          (click)="$event.stopPropagation(); goto.emit(b)"
          [title]="getDisplayProperty(b)"
          >...</a
        >
      </strong>
    </li>
    <li
      class="si-card__breadcrumb__item si-when-expanded"
      (click)="$event.stopPropagation()"
      *ngIf="i === 0 && breadcrumbs.length <= 5"
    >
      <strong>
        <a
          (click)="$event.stopPropagation(); goto.emit(b)"
          [class.rc-error--temp]="error"
          >{{ getDisplayProperty(b) }}</a
        >
        <span *ngIf="required" [class.rc-error--temp]="error"> *</span>
      </strong>
    </li>

    <ng-container *ngIf="i > 0">
      <li class="si-divider si-when-expanded">
        <mat-icon class="si-tool-icon" aria-hidden="true"
          >keyboard_arrow_right</mat-icon
        >
      </li>
      <li
        class="si-card__breadcrumb__item si-when-expanded si-choice--short st-state--item-{{
          i
        }}"
        (click)="$event.stopPropagation()"
        *ngIf="breadcrumbs.length > 3 && breadcrumbs.length !== i + 1"
      >
        <a
          (click)="$event.stopPropagation(); goto.emit(b)"
          [title]="getDisplayProperty(b)"
          >...</a
        >
      </li>
      <li
        class="si-card__breadcrumb__item si-when-expanded si-choice--full st-state--item-{{
          i
        }}"
        (click)="$event.stopPropagation()"
        *ngIf="breadcrumbs.length <= 3 || breadcrumbs.length === i + 1"
      >
        <a (click)="$event.stopPropagation(); goto.emit(b)">{{
          getDisplayProperty(b)
        }}</a>
      </li>
    </ng-container>
  </ng-container>
</ul>
