import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MoreOption } from '@sae/models';

@Component({
  selector: 'si-basic-card',
  templateUrl: './basic-card.component.html',
})
export class BasicCardComponent {
  @Input() simple = false;
  @Input() selected = false;
  @Input() preventOverflow = false;
  @Input() shadow = true;
  @Input() tight = false;
  @Input() customIcon!: string;
  @Input() materialIcon!: string;
  @Input() file = false;
  @Input() fileType = '';
  @Input() breadcrumbs: string;
  @Input() title!: string;
  @Input() strongTitle = false;
  @Input() titleEllipsed = false;
  @Input() titleSecondary!: string;
  @Input() subTitle!: string;
  @Input() subTitleFooter: string;
  @Input() subTitlePrimary!: string;
  @Input() subTitleStatus!: string;
  @Input() subTitleEllipsed = false;
  @Input() negative = false;
  @Input() positive = false;
  @Input() activated = false;
  @Input() highlight = false;
  @Input() inaccessible = false;
  @Input() locked = false;
  @Input() action!: string;
  @Input() actionAppLink!: string;
  @Input() actionAppHref!: string;
  @Input() appLink!: string;
  @Input() fragment: string = undefined;
  @Input() hrefLink!: string;
  @Input() ext!: string;
  @Input() otherIcon: string;
  @Input() otherIconDesc: string; // If otherIcon is specified, this should always be used for ADA compliance
  @Input() options: MoreOption[] = [];

  @Output() selectedOption = new EventEmitter<string>();

  optionSelected(option: string): void {
    this.selectedOption.emit(option);
  }
}
