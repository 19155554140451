import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    if (isPlatformServer(this.platformId)) {
      console.error('An error occurred which prevented Angular Universal from working: ', error);
      if (error?.stack) {
        console.error(error.stack);
      }
      return;
    }
    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    const snackBar = this.injector.get(MatSnackBar);
    let errorMessage = '';
    let showToUser = true;
    if (error instanceof HttpErrorResponse) {
      // server-side errror
      if (typeof navigator.onLine === 'boolean' && navigator.onLine === false) {
        errorMessage = 'No Internet Connection';
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        showToUser = false;
      }
    } else {
      // client-side error
      errorMessage = `Error: ${error.message}`;
      showToUser = false;
    }

    if (showToUser) {
      snackBar.open(errorMessage, 'DISMISS', { panelClass: ['error'], duration: 7000 });
    }
    console.error(error);
  }
}
