import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { SaeHttpResponse } from '@sae/models';
import { Api } from '../../../api';
import { Observable, of } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { Group, GroupParams, GroupsResponse } from '../models/groups.model';
import { UserGroupsQuery } from './user-groups.query';
import { UserGroupsState, UserGroupsStore } from './user-groups.store';

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
  resourceName: Api.groups.url,
})
export class UserGroupsService extends NgEntityService<UserGroupsState> {
  get groups$(): Observable<Array<Partial<Group>>> {
    return this.getAll();
  }

  constructor(protected store: UserGroupsStore, private query: UserGroupsQuery) {
    super(store);
  }

  loading$ = this.query.select('loading');

  getParams(): GroupParams {
    return {
      offset: 0,
    };
  }
  // Currently we only want this to be called once
  getAll(append = false): Observable<Array<Partial<Group>>> {
    const params = this.getParams();
    this.setLoading(true);
    return this.get<Array<Partial<Group>>>({
      params,
      append,
      mapResponseFn: (res: GroupsResponse) => {
        this.setLoading(false);
        return res?.results;
      },
    }).pipe(
      first((val) => !!val),
      finalize(() => {
        this.store.setLoading(false);
      }),
      catchError((err) => this.errorHandler(err))
    );
  }

  setLoading(loading: boolean): void {
    this.store.update({ loading });
  }

  errorHandler(error: HttpErrorResponse | SaeHttpResponse): Observable<[]> {
    if ((error as HttpErrorResponse)?.status === 404 || (error as SaeHttpResponse)?.statusCode === 404) {
      this.store.update({ total: 0, totalUnread: 0 });
      this.store.set([]);
      return of([]);
    } else {
      throw error;
    }
  }
}
