<ng-template #template>
  <ng-container
    *ngIf="{
      fragment: fragment$ | async,
      config: config$ | async,
      content: content$ | async,
      digitalStandard: digitalStandard$ | async,
      isLoggedIn: isLoggedIn$ | async,
      journalBrxDetail: journalBrxDetail$ | async
    } as _"
  >
    <div class="si-content__detail" *ngIf="_.fragment === null">
      <div class="si-data si-data--grid si-wd-full">
        <si-data-output
          *ngIf="
            (_.content?.feature2d3d ||
              _.content?.featureAnnotate ||
              _.content?.featureDataSets ||
              _.content?.featureRedlined) &&
            _.config?.detail_show_features
          "
          class="si-data__set si-wd si-wd-twothird"
          [title]="'Features'"
        >
          <si-micro-card
            *ngIf="_.content?.feature2d3d"
            class="si-griditem"
            title="2D/3D"
            materialIcon="360"
            [gridItem]="true"
            data-analytics="detail-page-2d3d-feature-card"
            (click)="navigate2d3d()"
          ></si-micro-card>
          <si-micro-card
            *ngIf="_.content?.featureAnnotate"
            class="si-griditem"
            title="Annotate"
            materialIcon="create"
            [gridItem]="true"
            [materialIconStyle]="!_.isLoggedIn ? 'inaccessible' : _.content?.hasAnnotation ? 'activated' : ''"
            (click)="annotate()"
            data-analytics="detail-page-annotate-feature-card"
          ></si-micro-card>
          <si-micro-card
            *ngIf="_.content?.featureDataSets"
            class="si-griditem"
            title="Data Sets"
            materialIcon="equalizer"
            [gridItem]="true"
            (click)="dataSets()"
            data-analytics="detail-page-data-sets-feature-card"
          ></si-micro-card>
          <si-micro-card
            *ngIf="_.content?.featureRedlined"
            [matMenuTriggerFor]="redlineOpts"
            class="si-griditem"
            title="Redlined"
            materialIcon="compare_arrows"
            [gridItem]="true"
            data-analytics="detail-page-redlined-feature-card"
          ></si-micro-card>
          <mat-menu #redlineOpts="matMenu" class="si-menu">
            <mat-label class="si-label">Compare to</mat-label>
            <ng-container *ngFor="let revision of redlineOptions$ | async">
              <button
                mat-menu-item
                class="si-button--detailed"
                data-testid="selectOption"
                (click)="redline(revision, _.content)"
              >
                <mat-icon color="primary" aria-hidden="true">
                  {{ revision?.status === 'Current' ? 'verified' : 'history' }}
                </mat-icon>
                <span>{{ revision.useExtendedCode ? revision.extendedCode : revision.code }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </si-data-output>

        <si-data-output
          *ngIf="!!_.content?.event && _.config?.detail_show_event"
          class="si-data__set si-wd si-wd-third"
          [title]="'Event'"
        >
          <si-seo-chiplist #chipList class="si-chiplist" role="listbox" aria-label="Event">
            <mat-chip
              class="si-chip si-chip--medium si-chip--long si-chip--role"
              role="option"
              (click)="performEventChipSearch(_.content?.event)"
              data-analytics="detail-page-event-chip"
              [title]="'Browse ' + _.content?.event + ' Content'"
            >
              {{ _.content?.event }}
            </mat-chip>
          </si-seo-chiplist>
        </si-data-output>

        <si-data-output
          *ngIf="!!_.config?.detail_onque_link && _.config?.detail_show_digital_standard"
          class="si-data__set si-wd si-wd-third si-expanel--group"
          [title]="'Digital Standards System'"
        >
          <a
            mat-button
            class="si-button--link"
            href="{{ _.config?.detail_onque_link }}"
            matTooltip="Opens in New Window"
            aria-label="Opens in New Window"
            target="_blank"
            data-analytics="detail-page-digital-standard-link"
          >
            <mat-icon class="si-icon" color="primary" aria-hidden="true">memory</mat-icon>
            <span class="si-for--primary si-impact">View in SAE OnQue</span>
          </a>
        </si-data-output>

        <si-data-output
          *ngIf="_.content?.contribGroups?.[0]?.contrib?.[0]?.collab && _.config?.detail_show_issuing_committee"
          class="si-data__set si-wd si-wd-full"
          [title]="'Issuing Committee'"
        >
          <si-seo-chiplist #chipList class="si-chiplist" role="listbox" aria-label="Issuing Committee">
            <mat-chip
              class="si-chip si-chip--medium si-chip--long si-chip--entity mat-elevation-z1"
              role="option"
              [title]="'Browse ' + _.content?.contribGroups?.[0]?.contrib?.[0]?.collab + ' Content'"
            >
              <a (click)="performCommitteeChipSearch(_.content?.contribGroups?.[0]?.contrib?.[0]?.collab)">
                {{ _.content?.contribGroups?.[0]?.contrib?.[0]?.collab }}
              </a>
            </mat-chip>
          </si-seo-chiplist>
        </si-data-output>

        <si-data-output
          *ngIf="_.content?.affiliations?.length > 0 && _.config?.detail_show_features_adjacent"
          class="si-data__set si-wd si-wd-full"
          [title]="'Affiliation'"
        >
          <si-seo-chiplist #chipList class="si-chiplist" role="listbox" aria-label="Affiliation">
            <mat-chip
              class="si-chip si-chip--medium si-chip--long si-chip--entity mat-elevation-z1"
              role="option"
              [title]="'Browse ' + _.content?.affiliations?.[0] + ' Content'"
            >
              <a (click)="performAffiliationChipSearch(_.content?.affiliations?.[0])">
                {{ _.content?.affiliations?.[0] }}
              </a>
            </mat-chip>
          </si-seo-chiplist>
        </si-data-output>
      </div>
    </div>
    <mat-accordion class="si-expanel__container si-expanel--singleton" [multi]="true">
      <mat-expansion-panel
        *ngIf="_.content?.authors?.length > 0 && _.config?.detail_show_authors"
        class="si-expanel si-expanel--impact mat-elevation-z"
        expanded="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title">
            <strong>Authors</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <div class="si-data si-data--grid si-authors">
            <div class="si-griditem si-wd si-wd-fourth" *ngFor="let a of _.content?.authors">
              <mat-card class="si-card si-card--basic si-simple si-card--author mat-elevation-z2">
                <mat-card-header class="si-card__h">
                  <mat-card-title class="si-card__title">
                    <a
                      (click)="performAuthorChipSearch(a.lnameFname)"
                      data-analytics="item-detail-author-card-author-btn"
                    >
                      {{ a.name }}
                    </a>
                  </mat-card-title>
                  <mat-card-subtitle *ngIf="a?.affiliation" class="si-card__subtitle">
                    <a
                      (click)="performAffiliationChipSearch(a.affiliation)"
                      data-analytics="item-detail-author-card-affiliation-btn"
                    >
                      {{ a.affiliation }}
                    </a>
                  </mat-card-subtitle>
                </mat-card-header>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="_.config?.detail_show_abstract"
        class="si-expanel si-expanel--impact mat-elevation-z si-expanel--allowfloat"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">
            {{ _.config?.detail_description_summary_title }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="si-expanel__c">
          <ng-container *ngIf="!_.config?.detail_show_aim_and_scope">
            <div class="si-data si-data--grid" *ngIf="_.content?.abstract; else noAbstract">
              <si-data-output class="si-data__set si-wd-full" [content]="_.content?.abstract"></si-data-output>
            </div>
          </ng-container>

          <ng-container *ngIf="_.config?.detail_show_aim_and_scope">
            <ng-container *ngIf="_.journalBrxDetail?.aimsAndScope; else noAbstract">
              <img
                *ngIf="_.journalBrxDetail?.journalImage"
                alt="Journal Image"
                class="si-journal--cover si-when-expanded"
                [src]="_.journalBrxDetail?.journalImage[1] ?? _.journalBrxDetail?.journalImage[0]"
              />
              <div class="si-data si-data--grid">
                <si-data-output
                  class="si-data__set si-wd si-wd-full"
                  [content]="_.journalBrxDetail?.aimsAndScope"
                ></si-data-output>
                <dl class="si-dataout si-wd si-wd-full" *ngIf="_.config?.detail_show_indexed_in">
                  <dt class="si-dataout__h">
                    <div class="si-dataout__h__title">Indexed in</div>
                  </dt>
                  <dd class="si-dataout__c">
                    <ul class="si-ul">
                      <si-data-output
                        class="si-data__set si-wd si-wd-full"
                        [content]="_.journalBrxDetail?.indexedBy"
                      ></si-data-output>
                    </ul>
                  </dd>
                </dl>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <ng-template #noAbstract>
          <si-data-output
            class="si-data__set si-wd-full"
            [content]="'No ' + _.config?.detail_description_summary_title + ' available'"
          ></si-data-output>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="_.content?.rationale && _.config?.detail_show_rationale"
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Rationale</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <si-data-output class="si-data__set si-wd-full" content="{{ _.content?.rationale }}"></si-data-output>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="_.content?.learningObjectives && _.config?.detail_show_learning_objective"
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Learning Objectives</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <si-data-output
            class="si-data__set si-wd-full"
            content="{{ _.content?.learningObjectives }}"
          ></si-data-output>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="_.content?.audience && _.config?.detail_show_audience"
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Who Should Attend</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <si-data-output class="si-data__set si-wd-full" content="{{ _.content?.audience }}"></si-data-output>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="_.content?.testimonials && _.config?.detail_show_testimonials"
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Testimonials</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <si-data-output class="si-data__set si-wd-full" content="{{ _.content?.testimonials }}"></si-data-output>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="_.content?.prerequisites && _.config?.detail_show_prerequisites"
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Prerequisites</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <si-data-output class="si-data__set si-wd-full" content="{{ _.content?.prerequisites }}"></si-data-output>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="_.config?.detail_show_tags && (_.content?.topics?.length > 0 || _.content?.affiliations?.length > 0)"
        class="si-expanel si-expanel--impact mat-elevation-z"
        expanded="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Meta Tags</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <div class="si-data si-data--meta">
            <si-data-output *ngIf="_.content?.topics?.length > 0" class="si-data__set si-wd-full" [title]="'Topics'">
              <si-seo-chiplist
                class="si-component"
                ariaLabel="Topics"
                [isLong]="true"
                [isTopic]="true"
                [isMedium]="true"
                [chips]="topicChips$ | async"
              ></si-seo-chiplist>
            </si-data-output>
            <si-data-output
              *ngIf="_.content?.affiliations?.length > 0"
              class="si-data__set si-wd-full"
              [title]="'Affiliated or Co-Author'"
            >
              <si-seo-chiplist
                class="si-component"
                ariaLabel="Affiliated or Co-Author"
                [isLong]="true"
                [isMinor]="true"
                [chips]="affiliationChips$ | async"
              ></si-seo-chiplist>
            </si-data-output>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="
          _.config?.detail_show_details &&
          (_.content?.doiUri ||
            _.content?.pageCount ||
            _.content?.citation ||
            _.content?.averageTimeSecs ||
            _.content?.creditsCeus)
        "
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Details</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <div class="si-data si-data--grid">
            <mobi-doi-data-output
              *ngIf="_.content?.doiUri && _.content?.doiVerified && _.config?.detail_show_doi"
              class="si-data__set si-wd si-wd-threefourth"
              [title]="'DOI'"
              [content]="_.content"
            ></mobi-doi-data-output>
            <si-data-output
              *ngIf="_.content?.isbn && _.config?.detail_show_isbn"
              class="si-data__set si-wd si-wd-fourth"
              [title]="'ISBN'"
              content="{{ _.content?.isbn }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.pageCount && _.config?.detail_show_page_count"
              class="si-data__set si-wd si-wd-fourth"
              [title]="'Pages'"
              content="{{ _.content?.pageCount }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.citation && _.config?.detail_show_citation"
              class="si-data__set si-wd si-wd-full"
              [title]="'Citation'"
              content="{{ _.content?.citation }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.averageTimeSecs && _.config?.detail_show_course_duration"
              class="si-data__set si-wd si-wd-threefourth"
              [title]="'Duration'"
              content="{{ _.content?.averageTimeSecs }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.creditsCeus && _.config?.detail_show_course_credit"
              class="si-data__set si-wd si-wd-fourth"
              [title]="'CEU'"
              content="{{ _.content?.creditsCeus }}"
            ></si-data-output>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="_.config?.detail_show_additional_details"
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title si-impact__title">Additional Details</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <div class="si-data si-data--meta">
            <si-data-output
              *ngIf="_.content?.publisher && _.config?.detail_show_publisher"
              class="si-data__set si-wd si-wd-half"
              [title]="'Publisher'"
            >
              <si-seo-chiplist
                class="si-chiplist"
                ariaLabel="Publisher"
                [isMedium]="true"
                [isLong]="true"
                [chips]="publisherChips$ | async"
              ></si-seo-chiplist>
            </si-data-output>
            <si-data-output
              *ngIf="_.content?.pubDate && _.config?.detail_show_pub_date"
              class="si-data__set si-wd si-wd-fourth"
              [title]="'Published'"
              content="{{ _.content?.pubDate | formatDateToTextPipe: 'PP' }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.code && _.config?.detail_show_code"
              class="si-data__set si-wd si-wd-fourth"
              [title]="'Product Code'"
              content="{{ _.content?.code }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.subGroup && _.config?.detail_show_selected_type"
              class="si-data__set si-wd si-wd-half"
              [title]="'Content Type'"
              content="{{ _.content?.subGroup }}"
            ></si-data-output>
            <si-data-output
              *ngIf="!!_.config?.detail_show_length && _.content?.videoLength"
              class="si_data__set si-wd si-wd-fourth"
              [title]="'Duration'"
            >
              {{ _.content?.videoLength }}
            </si-data-output>
            <si-data-output
              *ngIf="!!_.config?.detail_show_series && !!_.content?.categories?.length > 0"
              class="si_data__set si-wd si-wd-fourth"
              [title]="'Series'"
            >
              {{ _.content?.categories?.[0]?.name ?? _.content?.categories?.[1]?.name ?? 'N/A' }}
            </si-data-output>
            <si-data-output
              *ngIf="_.content?.standardAttributes?.revisionStatus && _.config?.detail_show_standard_status"
              class="si-data__set si-wd si-wd-fourth"
              [title]="'Status'"
              content="{{ _.content?.standardAttributes?.revisionStatus }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.language && _.config?.detail_show_language"
              class="si-data__set si-wd si-wd-fourth"
              [title]="'Language'"
              content="{{ _.content?.language }}"
            ></si-data-output>
            <si-data-output
              *ngIf="_.content?.country && _.config?.detail_show_country"
              class="si-data__set si-wd si-wd-half"
              [title]="'Country'"
              content="{{ _.content?.country }}"
            ></si-data-output>
          </div>
          <div class="si-data si-data--meta">
            <ng-container *ngIf="_.content?.hasAccess && fulltextMediaUrl; else preview">
              <iframe width="100%" height="350px" scrolling="no" frameborder="no" [src]="fulltextMediaUrl"></iframe>
            </ng-container>

            <ng-template #preview>
              <ng-container *ngIf="previewMediaUrl">
                <ng-container *ngIf="!_.content?.previewMediaUrl.includes('youtube')">
                  <iframe width="100%" height="350px" scrolling="no" frameborder="no" [src]="previewMediaUrl"></iframe>
                </ng-container>
                <ng-container *ngIf="_.content?.previewMediaUrl.includes('youtube')">
                  <iframe
                    width="100%"
                    height="350px"
                    [src]="previewMediaUrl"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
                    allowfullscreen
                  ></iframe>
                </ng-container>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="
          _.config?.detail_show_editorial_board &&
          (_.journalBrxDetail?.editorInChief || _.journalBrxDetail?.editorialBoard)
        "
        class="si-expanel si-expanel--impact mat-elevation-z"
        [expanded]="true"
      >
        <mat-expansion-panel-header class="si-expanel__h">
          <mat-panel-title class="si-title">
            <strong>Editorial Board</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="si-expanel__c">
          <div class="si-data si-data--grid">
            <si-data-output
              class="si-data__set si-wd si-wd-full"
              title="Editor in Chief"
              [content]="_.journalBrxDetail?.editorInChief"
            ></si-data-output>
          </div>
          <dl class="si-dataout si-wd si-wd-full">
            <dt class="si-dataout__h">
              <div class="si-dataout__h__title">Editorial Board</div>
            </dt>
            <dd class="si-dataout__c">
              <si-data-output
                class="si-data__set si-wd si-wd-full"
                [content]="_.journalBrxDetail?.editorialBoard"
              ></si-data-output>
            </dd>
          </dl>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <ng-container *ngIf="false">
      <div class="si-content__detail">
        <si-data-output class="si-data si-data--grid si-wd-full">
          <a
            mat-button
            class="si-button--link"
            href=""
            matTooltip="Provide Feedback"
            aria-label="Provide Feedback"
            target="_blank"
          >
            <mat-icon class="si-icon" color="primary" aria-hidden="true">comment</mat-icon>
            <span class="si-for--primary si-impact">Provide Feedback</span>
          </a>
        </si-data-output>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
