import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emToMark',
})
export class EmToMarkPipe implements PipeTransform {
  transform(value: string): string {
    return value?.replace(/<em>/g, '<mark class="si-mark si-mark--term">').replace(/<\/em>/g, '</mark>');
  }
}
