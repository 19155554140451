import { Observable } from 'rxjs';
import { IRemoteConfigService } from './remote-config.model';
import { EnterpriseMenu } from './enterprise-menu.model';

export enum SaeEnvironment {
  localhost = 'localhost',
  dev = 'dev',
  test = 'test',
  prod = 'prod',
  beta = 'beta',
}

/**
 * the JSON file generated at the following location during deployment:
 * apps/{project-name}/src/config.json
 * @export
 * @interface ConfigJSON
 * @typedef {ConfigJSON}
 */
export interface ConfigJSON {
  environment: SaeEnvironment;
  version?: string;
}

/**
 *
 * The base Configuration model describing both:
 * 1. the legacy Remote Config 'config' objects in Firebase, AND
 * 2. the JSON we will keep in Proteus for each app and environment.
 * This should ideally only contain properties common to all apps in the monorepo.
 * @export
 * @interface SaeEnvironmentConfig
 * @typedef {SaeEnvironmentConfig}
 */
export interface SaeEnvironmentConfig {
  auth?: {
    realm: string;
    clientId: string;
    url: string;
    bearerExcludedUrls?: Array<
      | string
      | {
          url: string;
          httpMethods?: Array<'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH'>;
        }
    >;
  };
  maintenance?: boolean;
  metrics?: {
    useApm: boolean;
    apm: string;
    apmServiceName: string;
    apmUserInteractions: boolean;
  };
  services?: {
    apiRootUrl?: string; // TODO: whether we keep this in proteus or in code is TBD. (We don't want to duplicate these all over like we did with firebase)
    cmsUrl?: string; // used by BRX platform apps as well as non-platform apps that have some BRX integration
  };
  sessionExpiry?: {
    useSessionExpiry: boolean;
    expiryWarnSeconds: number;
  };
  // these two properties used to be sibling-level to config in Firebase, now everything will be contained in one config object!
  featureFlags?: { [key: string]: boolean };
  enterpriseMenu?: EnterpriseMenu;
}

/**
 * This interface will be the base for all versions of EnvironmentConfigService within the monorepo.
 * Note it extends the legacy IRemoteConfigService, so implementations will include the old RC methods and properties.
 * This will provide backwards compatibility for shared code that expects the RemoteConfigService.
 * Applications will provide an EnvironmentConfigService implementation on the REMOTE_CONFIG_TOKEN
 * until all applications have been migrated to the new service.
 * @export
 * @interface IEnvironmentConfigService
 * @typedef {IEnvironmentConfigService}
 * @template {SaeEnvironmentConfig} [TConfig=SaeEnvironmentConfig]
 * @extends {Omit<IRemoteConfigService, 'initAndFetchConfig'>}
 */
export interface IEnvironmentConfigService extends Omit<IRemoteConfigService, 'initAndFetchConfig'> {
  initialize<TConfig extends SaeEnvironmentConfig = SaeEnvironmentConfig>(
    currentEnvironment: SaeEnvironment,
    hostURL?: string
  ): Promise<TConfig>; // replaces initAndFetchConfig()
  envConfig<TConfig extends SaeEnvironmentConfig = SaeEnvironmentConfig>(): TConfig;
  envConfig$<TConfig extends SaeEnvironmentConfig = SaeEnvironmentConfig>(): Observable<TConfig>;
  hostURL: string;
  hostURL$: Observable<string>;
}
