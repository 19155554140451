<mat-card
  class="si-card si-card--basic si-card--process"
  [class.si-state--processing]="type === 'processing'"
  [class.si-state--error]="type === 'error'"
  [class.si-state--success]="type === 'success'"
>
  <button
    *ngIf="options.length || action"
    class="si-button--wrap"
    mat-button
    [matMenuTriggerFor]="optMenu"
    #menuTrigger="matMenuTrigger"
    (click)="$event.stopPropagation(); $event.preventDefault()"
  >
    <ng-container *ngTemplateOutlet="cardBody"></ng-container>
  </button>
  <ng-container *ngIf="!options.length && !action">
    <ng-container *ngTemplateOutlet="cardBody"></ng-container>
  </ng-container>
  <span class="si-expanse"></span>
  <ng-content></ng-content>
  <button
    *ngIf="action || actionIcon"
    mat-flat-button
    class="si-button si-button--secondary"
    (click)="actionOnClick($event)"
  >
    <mat-icon
      *ngIf="actionIcon && actionIconVisible"
      color="primary"
      aria-hidden="true"
      class="si-icon"
      [class.si-state--negative]="actionType === 'error'"
      [class.si-state--activated]="actionType === 'processing'"
      [class.si-state--positive]="actionType === 'success'"
      [class.si-state--inaccessible]="actionType === 'inaccessible'"
    >
      {{ actionIcon }}
    </mat-icon>
    <span *ngIf="action">{{ action }}</span>
  </button>
  <mat-menu #optMenu="matMenu" class="si-menu">
    <button
      *ngFor="let option of options"
      mat-menu-item
      class="si-button--detailed"
      (click)="optionOnClick($event, option.action)"
    >
      <mat-icon
        *ngIf="option.icon"
        color="primary"
        aria-hidden="true"
        class="si-icon"
        [class.si-state--negative]="materialIconType === 'error'"
        [class.si-state--activated]="materialIconType === 'processing'"
        [class.si-state--positive]="materialIconType === 'success'"
      >
        {{ option.icon }}
      </mat-icon>
      <span>{{ option.name }}</span>
    </button>
    <ng-container *ngIf="action">
      <mat-divider class="si-divider"></mat-divider>
      <button *ngIf="action" mat-menu-item class="si-button--detailed" (click)="optionOnClick($event, action)">
        <mat-icon *ngIf="actionIcon" color="primary" aria-hidden="true">
          {{ actionIcon }}
        </mat-icon>
        <span>{{ action }}</span>
      </button>
    </ng-container>
  </mat-menu>
</mat-card>

<ng-template #cardBody>
  <mat-card-header class="si-card__h" [class.si-contains--avatar]="materialIcon">
    <div *ngIf="materialIcon" mat-card-avatar class="si-avatar">
      <mat-icon
        *ngIf="materialIcon"
        class="si-icon"
        [class.si-state--negative]="type === 'error'"
        [class.si-state--activated]="type === 'processing'"
        [class.si-state--positive]="type === 'success'"
      >
        {{ materialIcon }}
      </mat-icon>
    </div>
    <mat-card-title *ngIf="titleText" class="si-card__title">
      {{ titleText }}
    </mat-card-title>
    <mat-card-subtitle *ngIf="subTitleText" class="si-card__subtitle">
      {{ subTitleText }}
    </mat-card-subtitle>
  </mat-card-header>
</ng-template>
