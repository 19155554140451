import { DomPortal } from '@angular/cdk/portal';
import { Component, Inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { PreAppView, ViewTemplate } from '@sae/models';
import { ISessionService, SESSION_TOKEN } from '@sae/services';
import { BaseTemplateComponent } from '../base-template.component';

@Component({
  selector: 'si-pre-app-template',
  templateUrl: './pre-app-template.component.html',
  styleUrls: ['./pre-app-template.component.scss'],
})
export class PreAppTemplateComponent extends BaseTemplateComponent implements OnChanges {
  @Input() projectedContent!: PreAppView;

  mainContentPortal: DomPortal<TemplateRef<unknown>>;

  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    super(sessionService);
  }
  ngOnChanges(s: SimpleChanges): void {
    if (s?.projectedContent?.currentValue) {
      this.mainContentPortal = new DomPortal(this.projectedContent.mainContent);
    }
  }
}

@Component({ template: '', host: { 'collision-id': 'PreAppViewBase' } })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PreAppViewBase implements PreAppView {
  @ViewChild('mainContent') mainContent: TemplateRef<unknown>;
  constructor(@Inject(SESSION_TOKEN) protected readonly sessionService: ISessionService) {
    this.sessionService.setActiveViewTemplate(ViewTemplate.PreApp);
  }
}
