import { Inject, Injectable } from '@angular/core';
import { NG_ENTITY_SERVICE_CONFIG, NgEntityServiceGlobalConfig } from '@datorama/akita-ng-entity-service';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Api } from '../../../api';
import { UserV2 as User } from '@sae/models';
import { Stores } from '../../../stores';
import { Observable, of } from 'rxjs';
import { SaeHttpResponse } from '@sae/models';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { SubscriptionAdministrators } from '../../modules/detail/models/content.model';
import { SubscriptionSession, SubscriptionsService } from './subscriptions.service';

export interface UserState {
  currentUser: User;
  subscriptionAdminMap: Record<string, boolean>;
  isUserAdminOnSub: boolean;
  adminSubscriptions: SubscriptionSession[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: Stores.user })
export class UserStore extends Store<UserState> {
  constructor() {
    super({ currentUser: null, subscriptionAdminMap: {}, isUserAdminOnSub: undefined, adminSubscriptions: [] });
  }
}

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }
}

export interface SvcGetConfig {
  useCache?: boolean;
  skipWrite?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl: string;

  private usersUrl: string;
  private subUrl: string;

  constructor(
    protected readonly query: UserQuery,
    protected readonly store: UserStore,
    @Inject(NG_ENTITY_SERVICE_CONFIG) private ngEntityServiceGlobalConfig: NgEntityServiceGlobalConfig,
    private httpClient: HttpClient,
    private authService: AuthService,
    private subscriptionsService: SubscriptionsService
  ) {
    this.baseUrl = this.ngEntityServiceGlobalConfig.baseUrl;
    this.usersUrl = `${this.baseUrl}/${Api.usersGroups.url}/users`;
    this.subUrl = `${this.baseUrl}/${Api.subscriptions.url}`;
  }

  public currentUser$: Observable<User> = this.query.select((s) => s.currentUser);
  public adminUserSubList$: Observable<SubscriptionSession[]> = this.query.select((s) => s.adminSubscriptions);
  public isUserAdminonSub$: Observable<boolean> = this.query.select((s) => s.isUserAdminOnSub);

  //gets current user's details and stores it in currentUser state item
  public getCurrentUser(useCache = false): Observable<User> {
    const userId = this.query.getValue()?.currentUser?.id ?? this.authService.getProfile()?.id;
    if (!userId) {
      return of(null);
    }

    if (useCache) {
      const user = this.query.getValue().currentUser;
      if (user && user.id === userId) {
        return of(user);
      }
    }

    return this.getUser(userId).pipe(
      tap((user) => {
        this.store.update({ currentUser: user });
      })
    );
  }

  public getUser(userId: string): Observable<User> {
    return this.httpClient.get<SaeHttpResponse<User>>(`${this.usersUrl}/${userId}`).pipe(
      map((r) => {
        return r?.results?.[0] ?? null;
      }),
      catchError(() => of(null))
    );
  }

  // TODO: this is (probably) temporary until we have a service call that will tell us
  // what the current user's roles are on a subscription. Right now it somewhat inefficiently
  // gets a list of all the admins for a subscription and sees if the current user is one
  // of them.
  /**
   * Checks whether current user is an admin on the specified sub. If user is not logged
   * in to personal account, it will return false.
   * @param subscriptionId subscription id (uuid) to check
   * @returns whether current user is an admin on the specified sub
   */
  public isCurrentUserAdminOnSub(subscriptionId: string): Observable<boolean> {
    const subAdminCache = this.query.getValue().subscriptionAdminMap[subscriptionId];
    if (subAdminCache !== undefined) {
      return of(subAdminCache);
    }
    return this.getCurrentUser(true).pipe(
      concatMap((user) => {
        if (!user) {
          return of(null);
        }
        return this.httpClient
          .get<SaeHttpResponse<SubscriptionAdministrators>>(`${this.subUrl}/${subscriptionId}/administrators`)
          .pipe(map((resp) => ({ user: user, response: resp })));
      }),
      map((adminResp) => {
        if (!adminResp) {
          return false;
        }
        const isAdmin = !!adminResp.response.results?.find((admin) => admin.user.id === adminResp.user.id);
        const map = { ...this.query.getValue().subscriptionAdminMap };
        map[subscriptionId] = isAdmin;
        this.store.update({ subscriptionAdminMap: map });
        return !!adminResp.response.results?.find((admin) => admin.user.id === adminResp.user.id);
      })
    );
  }

  public isCurrentUserAdminOnSubV2(): Observable<boolean> {
    if (!this.authService.isLoggedIn()) {
      return of(false);
    }
    const subAdminCache = this.query.getValue().isUserAdminOnSub;
    if (subAdminCache !== undefined) {
      return of(subAdminCache);
    }
    return this.httpClient.get(this.subUrl).pipe(
      map((subs) => {
        const isUserAdminOnSub = subs['results']?.length > 0;
        this.store.update({ isUserAdminOnSub, adminSubscriptions: subs['results'] });
        return isUserAdminOnSub;
      }),
      catchError(() => {
        this.store.update({ isUserAdminOnSub: false, adminSubscriptions: [] });

        return of(false);
      })
    );
  }
}
