import { NgModule } from '@angular/core';
import { MathDirective } from '../directives/math.directive';
import { MathService } from '../services/math.service';

@NgModule({
  declarations: [MathDirective],
  exports: [MathDirective],
  providers: [MathService],
})
export class MathModule {}
