<div class="si-card__wrap">
  <ng-container *ngIf="appLink || hrefLink">
    <ng-container *ngIf="appLink">
      <a
        [routerLink]="appLink"
        [disableRipple]="disabledRipple"
        class="si-a--unusual si-component si-button si-button--inline"
        mat-button
      >
        <ng-container *ngTemplateOutlet="cardContentTemplate"></ng-container>
      </a>
    </ng-container>
    <ng-container *ngIf="!appLink">
      <a
        [href]="hrefLink"
        [disableRipple]="disabledRipple"
        rel="noopener"
        target="_blank"
        class="si-a--unusual si-component si-button si-button--inline"
        mat-button
      >
        <ng-container *ngTemplateOutlet="cardContentTemplate"></ng-container>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!appLink && !hrefLink">
    <ng-container *ngTemplateOutlet="cardContentTemplate"></ng-container>
  </ng-container>
  <div class="si-card--general-status__content"><ng-content></ng-content></div>
</div>

<ng-template #cardContentTemplate>
  <mat-card
    class="si-card si-card--basic si-card--general-status si-card--columns"
    [class.mat-elevation-z2]="shadow"
    [class.mat-elevation-z]="!shadow"
    [class.si-state--selected]="selected"
    [class.si-card--general-status__divider]="!shadow && divider"
  >
    <mat-card-header class="si-card__h">
      <mat-card-title *ngIf="tagline" class="si-card__title">
        <div class="si-cellgroup__wrap">
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full">
              <div class="si-cell__c si-tight--vert si-card--general-status__tagline">{{ tagline }}</div>
            </div>
          </div>
        </div>
      </mat-card-title>
      <div
        mat-card-avatar
        class="si-card__avatar si-type--program si-visible--beyond-small-tablet"
        [class.si-unusual]="tagline"
        *ngIf="avatarText"
      >
        {{ avatarText }}
      </div>
      <div
        mat-card-avatar
        class="si-avatar"
        [class.si-unusual]="tagline"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        *ngIf="!avatarText"
      >
        <ng-container *ngIf="!toggleableSticky && !toggled && !showToggleButton">
          <mat-icon
            color="primary"
            class="si-icon"
            [class.si-state--negative]="iconStyle === 'negative'"
            [class.si-state--positive]="iconStyle === 'positive'"
            [class.si-state--activated]="iconStyle === 'activated'"
            [class.si-state--highlight]="iconStyle === 'highlight'"
            [class.si-state--inaccessible]="iconStyle === 'inaccessible'"
            [class.si-state--locked]="iconStyle === 'locked'"
            [attr.data-analytics]="analyticsIdIcon"
          >
            {{ icon }}
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="toggleableSticky || toggled || showToggleButton">
          <button
            mat-icon-button
            class="si-button si-button--icon si-button--row"
            [matTooltip]="!toggled ? 'Select Row' : 'Unselect Row'"
            [attr.aria-label]="!toggled ? 'Select Row' : 'Unselect Row'"
            (click)="onToggleClick($event)"
          >
            <mat-icon aria-hidden="true" *ngIf="!toggled">radio_button_unchecked</mat-icon>
            <mat-icon aria-hidden="true" *ngIf="toggled">check_circle</mat-icon>
          </button>
        </ng-container>
      </div>
      <mat-card-title *ngIf="chips && !chipsInlineWithSubtitle" class="si-card__title">
        <div [class.si-cellgroup__wrap]="!tight">
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full">
              <ng-container
                *ngTemplateOutlet="chipsTemplate; context: { chips: chips, menu: chipsMenu }"
              ></ng-container>
            </div>
          </div>
        </div>
      </mat-card-title>
      <mat-card-title *ngIf="title" class="si-card__title">
        <div [class.si-cellgroup__wrap]="!tight">
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full">
              <div class="si-cell__c si-tight--vert">
                <strong [class.si-link]="titleLinked">{{ title }}</strong>
                <span *ngFor="let subtitle of titleAdditional" class="si-additional">
                  <span class="si-labelset" [class.si-em]="subtitle.em">
                    <span class="si-label" *ngIf="subtitle.label">{{ subtitle.label }}</span>
                    <span class="si-labelset__value" *ngIf="subtitle.value">{{ subtitle.value }}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </mat-card-title>
      <mat-card-title class="si-card__title" *ngIf="cornerTagLeft || cornerTagRight">
        <div [class.si-cellgroup__wrap]="!tight">
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full">
              <div class="si-cell__c si-tight--vert si-card--general-status__corner-labels">
                <div>
                  <span class="si-labelset">
                    <span class="si-label">{{ cornerTagLeft ?? '' }}</span>
                  </span>
                </div>
                <div>
                  <span class="si-labelset">
                    <span class="si-label">{{ cornerTagRight ?? '' }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-title>
      <mat-card-title *ngIf="subtitle" class="si-card__title">
        <div [class.si-cellgroup__wrap]="!tight">
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full">
              <ng-container *ngIf="chips && chipsInlineWithSubtitle; else standardSubtitleDisplay">
                <ng-container
                  *ngTemplateOutlet="chipsTemplate; context: { chips: chips, menu: chipsMenu, subtitle: subtitle }"
                ></ng-container>
              </ng-container>
              <ng-template #standardSubtitleDisplay>
                <div class="si-cell__c si-tight--vert">
                  {{ subtitle }}
                  <span *ngIf="subtitleAdditional" class="si-additional">{{ subtitleAdditional }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </mat-card-title>
      <mat-card-subtitle class="si-card__subtitle" *ngFor="let subtitleLine of subtitleLines">
        <div [class.si-cellgroup__wrap]="!tight">
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full">
              <div class="si-cell__c si-tight--vert">
                <span *ngFor="let subtitle of subtitleLine; let isFirst = first" [class.si-additional]="!isFirst">
                  <span class="si-labelset">
                    <span *ngIf="subtitle.label" class="si-label">{{ subtitle.label }}</span>
                    <span *ngIf="subtitle.value" class="si-labelset__value">{{ subtitle.value }}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </mat-card-subtitle>
      <mat-card-subtitle class="si-card__subtitle" *ngIf="showPreChipsContent">
        <div>
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full" #preChipsContent>
              <ng-content select="[preChipsContent]"></ng-content>
            </div>
          </div>
        </div>
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="subChips" class="si-card__subtitle">
        <div [class.si-cellgroup__wrap]="!tight">
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full">
              <ng-container
                *ngTemplateOutlet="chipsTemplate; context: { chips: subChips, menu: subChipsMenu }"
              ></ng-container>
            </div>
          </div>
        </div>
      </mat-card-subtitle>
      <mat-card-subtitle class="si-card__subtitle" *ngIf="showCardBottomContent">
        <div>
          <div class="si-cellgroup">
            <div class="si-cell si-wd si-wd-full" #cardBottomContent>
              <ng-content select="[cardBottomContent]"></ng-content>
            </div>
          </div>
        </div>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</ng-template>

<ng-template #chipsTemplate let-chips="chips" let-menu="menu" let-subtitle="subtitle">
  <div class="si-cell__c si-tight--vert si-card--general-status__chips">
    <ng-container *ngIf="useSeoChips">
      <si-seo-chiplist
        class="si-component"
        [ariaLabel]="ariaLabel"
        [isMinor]="true"
        [isTopic]="chipsIsTopic"
        [chips]="chips"
        [chipsMenu]="menu"
        (chipClicked)="chipClicked($event)"
        (click)="$event.stopPropagation(); $event.preventDefault()"
        (optionSelected)="onOptionSelected($event)"
        (mouseenter)="disabledRipple = true"
        (mouseleave)="disabledRipple = false"
      >
        <ng-container *ngIf="subtitle" beforeChipsContent>
          <div class="si-card--general-status__chips-subtitle">{{ subtitle }}</div>
        </ng-container>
        <ng-content select="[afterChipsContent]"></ng-content>
      </si-seo-chiplist>
    </ng-container>
    <ng-container *ngIf="!useSeoChips">
      <mat-chip-list class="si-chiplist" [attr.aria-label]="ariaLabel">
        <ng-container *ngIf="subtitle">{{ subtitle }}</ng-container>
        <ng-container *ngIf="menu">
          <ng-container *ngFor="let chip of chips">
            <mat-chip
              [matMenuTriggerFor]="optionsMenu"
              class="si-chip si-chip--minor {{ chip.classes }}"
              (click)="$event.stopPropagation(); $event.preventDefault()"
              (mouseenter)="disabledRipple = true"
              (mouseleave)="disabledRipple = false"
            >
              <span class="si-ellipsed">{{ chip.label }}</span>
            </mat-chip>
            <mat-menu #optionsMenu="matMenu" class="si-menu">
              <si-more-menu
                [options]="menu"
                [onlyRenderOptions]="true"
                (optionSelected)="onMatChipOptionSelected($event, chip.label)"
              ></si-more-menu>
            </mat-menu>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!menu">
          <mat-chip
            *ngFor="let chip of chips"
            class="si-chip si-chip--minor {{ chip.classes }}"
            (click)="$event.stopPropagation(); $event.preventDefault(); matChipClicked(chip.label)"
            (mouseenter)="disabledRipple = true"
            (mouseleave)="disabledRipple = false"
          >
            <span class="si-ellipsed">{{ chip.label }}</span>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </ng-container>
  </div>
</ng-template>
