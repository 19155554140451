import { Component, Input, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { PersonTagInfo } from '@sae/models';

@Component({
  selector: 'si-person-card-tag',
  templateUrl: './person-card-tag.component.html',
  styles: [
    `
      .si-menu-card.mat-menu-panel {
        position: fixed !important;
      }
    `,
  ],
})
export class PersonCardTagComponent {
  @Input() personType: string;
  @Input() personTagInfo: PersonTagInfo;
  @Input() menuLocation: 'before' | 'after' = 'before';
  @Input() enableMenu: boolean;

  @ViewChild('personDetails') matMenu: MatMenu;
  @ViewChild(MatMenuTrigger) cardButton: MatMenuTrigger;

  setMenuState(isOpen: boolean): void {
    if (!isOpen) {
      this.cardButton.closeMenu();
    }
  }
}
