<ng-template #template>
  <ng-container
    *ngIf="{
      fragment: fragment$ | async,
      config: config$ | async,
      content: content$ | async,
      relatedResults: relatedResults$ | async,
      routeLoading: routeLoading$ | async,
      returnUrl: returnUrl$ | async,
      subSessions: subSessions$ | async,
      detailFeatureContent: detailFeatureContent$ | async,
      journalBrowse: journalBrowse$ | async,
      journalBrxDetail: journalBrxDetail$ | async
    } as _"
  >
    <div class="si-content">
      <div *ngIf="!_.routeLoading" class="si-content__h si-content__h--detail">
        <mobi-masthead
          [content]="_.content"
          [returnUrl]="_.returnUrl"
          class="si-takeover__c__main si-impact"
        ></mobi-masthead>
        <nav mat-tab-nav-bar class="si-tab si-offset--h">
          <a
            mat-tab-link
            [routerLink]="['./']"
            [active]="_.fragment === null"
            data-analytics="detail-page-description-tab"
          >
            {{ _.config?.detail_description_tab_title }}
          </a>
          <a
            *ngIf="_.config?.detail_show_view"
            mat-tab-link
            [routerLink]="['./']"
            fragment="view"
            [active]="_.fragment?.includes('view')"
            data-analytics="detail-page-view-tab"
          >
            View
          </a>
          <a
            *ngIf="!!_.content?.feature2d3d && _.config?.detail_show_2d_3d"
            mat-tab-link
            [routerLink]="['./']"
            fragment="2d-3d"
            [active]="_.fragment === '2d-3d'"
            data-analytics="detail-page-2d3d-tab"
          >
            2D/3D
          </a>
          <a
            *ngIf="!!_.detailFeatureContent?.dataSets?.length && _.config?.detail_show_data_sets"
            mat-tab-link
            [routerLink]="['./']"
            fragment="data-sets"
            [active]="_.fragment === 'data-sets'"
            data-analytics="detail-page-data-sets-tab"
          >
            Data Sets
          </a>
          <a
            *ngIf="!!_.content?.references?.length && _.config?.detail_show_references"
            mat-tab-link
            [routerLink]="['./']"
            fragment="references"
            [active]="_.fragment === 'references'"
            data-analytics="detail-page-references-tab"
          >
            References
          </a>
          <a
            *ngIf="_.config?.detail_show_browse"
            mat-tab-link
            [routerLink]="['./']"
            fragment="browse"
            [active]="_.fragment?.includes('browse')"
            data-analytics="detail-page-browse-tab"
          >
            Browse
          </a>
          <a
            *ngIf="!!_.content?.outline && _.config?.detail_show_course_outline"
            mat-tab-link
            [routerLink]="['./']"
            fragment="course-outlines"
            [active]="_.fragment === 'course-outlines'"
            data-analytics="detail-page-course-outlines-tab"
          >
            Course Outline
          </a>
          <a
            *ngIf="
              (!!_.content?.materials || !!_.content?.requirements) && _.config?.detail_show_materials_and_requirements
            "
            mat-tab-link
            [routerLink]="['./']"
            fragment="materials-and-requirements"
            [active]="_.fragment === 'materials-and-requirements'"
            data-analytics="detail-page-materials-and-requirements-tab"
          >
            Materials & Requirements
          </a>
          <a
            *ngIf="!!_.content?.instructorBiography && _.config?.detail_show_instructors"
            mat-tab-link
            [routerLink]="['./']"
            fragment="instructors"
            [active]="_.fragment === 'instructors'"
            data-analytics="detail-page-instructors-tab"
          >
            Instructors
          </a>

          <a
            *ngIf="_.config?.detail_show_authoring && _.journalBrxDetail?.authorResources"
            mat-tab-link
            [routerLink]="['./']"
            fragment="authoring"
            [active]="_.fragment === 'authoring'"
            data-analaytics="detail-page-authoring-tab"
          >
            Authoring
          </a>
          <a
            *ngIf="_.config?.detail_show_subscriptions && _.journalBrxDetail?.subscriptionOptions"
            mat-tab-link
            [routerLink]="['./']"
            fragment="subscriptions"
            [active]="_.fragment === 'subscriptions'"
            data-analaytics="detail-page-subscriptions-tab"
          >
            Subscriptions
          </a>
          <a
            *ngIf="_.relatedResults?.length && _.config?.detail_show_related"
            mat-tab-link
            [routerLink]="['./']"
            fragment="related"
            [active]="_.fragment === 'related'"
            data-analytics="detail-page-related-tab"
          >
            Related
          </a>
          <a
            *ngIf="_.content?.citation && _.config?.detail_show_citation"
            mat-tab-link
            [routerLink]="['./']"
            fragment="citation"
            [active]="_.fragment === 'citation'"
            data-analaytics="detail-page-citations-tab"
          >
            Citation
          </a>
          <a
            *ngIf="_.config?.detail_show_submit_manuscript && _.journalBrxDetail?.manuscriptUrl"
            [href]="_.journalBrxDetail?.manuscriptUrl"
            class="si-a--unusual si-submit-link si-visible--beyond-bp-12"
            target="_blank"
            data-analaytics="detail-page-submit-manuscript-link"
          >
            Submit Manuscript
          </a>
        </nav>
      </div>
      <div
        class="si-content__c si-follows--tab si-content__c si-follows--tab"
        [class.si-content__c--reader]="_.fragment?.includes('view')"
      >
        <ng-container *ngIf="!_.routeLoading; else loading">
          <mobi-detail-description *ngIf="_.fragment === null"></mobi-detail-description>
          <mobi-detail-view
            *ngIf="_.fragment?.includes('view') && _.config?.detail_show_view"
            [content]="_.content"
          ></mobi-detail-view>
          <mobi-detail-data-sets
            *ngIf="_.fragment === 'data-sets' && _.config?.detail_show_data_sets"
            [dataSets]="_.detailFeatureContent?.dataSets"
            [pagination]="_.detailFeatureContent?.pagination"
            [accessProperties]="_.content?.accessProperties"
            [contentId]="_.content?.id"
            [subSessions]="_.subSessions"
          ></mobi-detail-data-sets>
          <mobi-detail-references
            *ngIf="_.fragment === 'references' && _.config?.detail_show_references"
          ></mobi-detail-references>
          <mobi-detail-related *ngIf="_.fragment === 'related' && _.config?.detail_show_related"></mobi-detail-related>
          <mobi-detail-citation
            *ngIf="_.fragment === 'citation' && _.config?.detail_show_citation"
          ></mobi-detail-citation>
          <mobi-detail-2d3d
            *ngIf="_.fragment === '2d-3d' && _.config?.detail_show_2d_3d"
            [contentId]="_.content?.id"
            [_2d3dUrl]="_.content?.partsConfiguratorUrl"
          ></mobi-detail-2d3d>
          <mobi-detail-browse
            *ngIf="_.fragment?.includes('browse') && _.config?.detail_show_browse"
          ></mobi-detail-browse>
          <mobi-detail-course-outline
            *ngIf="_.fragment === 'course-outlines' && _.config?.detail_show_course_outline"
          ></mobi-detail-course-outline>
          <mobi-detail-materials-and-requirements
            *ngIf="_.fragment === 'materials-and-requirements' && _.config?.detail_show_materials_and_requirements"
          ></mobi-detail-materials-and-requirements>
          <mobi-detail-instructors
            *ngIf="_.fragment === 'instructors' && _.config?.detail_show_instructors"
          ></mobi-detail-instructors>
          <mobi-detail-subscriptions
            *ngIf="_.fragment === 'subscriptions' && _.config?.detail_show_subscriptions"
          ></mobi-detail-subscriptions>
          <mobi-detail-journal-authoring
            *ngIf="_.fragment === 'authoring' && _.config?.detail_show_authoring"
          ></mobi-detail-journal-authoring>
        </ng-container>
        <ng-template #loading>
          <div class="si-spinner__container">
            <mat-spinner class="si-spinner"></mat-spinner>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-template>
