<ng-container
  *ngIf="{
    isToolPanelCollapsed: isToolPanelCollapsed$ | async,
    config: config$ | async
  } as _"
>
  <div #headerContent>
    <mobi-detail-header></mobi-detail-header>
  </div>
  <div #detailContent class="si-col si-col--detail" cdkScrollable>
    <div libDetectBreakpointWidth>
      <mobi-detail-main-view></mobi-detail-main-view>
    </div>
  </div>
  <div
    *ngIf="_.config?.panel_enabled"
    #toolContent
    class="si-col si-col--aux si-thin"
    [class.si-visible--below-medium-tablet]="_.isToolPanelCollapsed"
  >
    <mobi-detail-panel></mobi-detail-panel>
  </div>
</ng-container>
