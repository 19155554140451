import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentConfigService, REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../env.config';
import { Api } from '../../../api';
import { SaeHttpResponse } from '@sae/models';
import { Invitation } from '../models/api-models';
import { Observable, map, switchMap } from 'rxjs';
import { Group } from '../models/groups.model';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(
    private http: HttpClient,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: EnvironmentConfigService
  ) {}

  getInvites(params: {
    applicationCode?: string;
    anchor?: string;
    status?: string[];
    emailAddress?: string;
    type?: string;
    sortBy?: 'modifiedDate' | 'status' | 'emailAddress';
    orderBy?: 'asc' | 'desc';
    offset?: number;
    limit?: number;
  }): Observable<SaeHttpResponse<Invitation>> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services.apiRootUrl}/${
      Api.invitation.url
    }/invites`;
    return this.http.get<SaeHttpResponse<Invitation>>(url, { params });
  }

  patchInvite(id, invitation: Partial<Invitation>): Observable<Invitation> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services.apiRootUrl}/${
      Api.invitation.url
    }/invites/${id}`;

    return this.http.patch<SaeHttpResponse<Invitation>>(url, invitation).pipe(map((resp) => resp.results[0]));
  }

  postInvite(invitation: Invitation): Observable<Invitation> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services.apiRootUrl}/${
      Api.invitation.url
    }/invites`;

    return this.http.post<SaeHttpResponse<Invitation>>(url, invitation).pipe(map((resp) => resp.results[0]));
  }

  getInvite(invitationId: string): Observable<Invitation> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services.apiRootUrl}/${
      Api.invitation.url
    }/invites/${invitationId}`;
    return this.http.get<SaeHttpResponse<Invitation>>(url).pipe(map((invitation) => invitation?.results[0]));
  }
  getInvitationGroupDetail(groupId: string): Observable<Group> {
    const url = `${this.envConfigService.envConfig<MobilusProteusConfig>().services.apiRootUrl}/${
      Api.usersGroups.url
    }/groups/${groupId}`;
    return this.http.get<SaeHttpResponse<Group>>(url).pipe(map((invitation) => invitation?.results[0]));
  }
}
