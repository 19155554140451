import { Inject, Injectable, InjectionToken } from '@angular/core';
import { getAll, RemoteConfig, Value } from '@angular/fire/remote-config';
import { Query, Store } from '@datorama/akita';
import { Observable } from 'rxjs';
import { StoresRegistry, StoresToken } from './../api-registry';

/**
 * @deprecated
 * This service depends on Firebase and should no longer be used.
 * Please use Proteus and the EnvironmentConfigService for your feature flagging needs.
 * https://fullsight.atlassian.net/wiki/spaces/DR/pages/327876627/Proteus+Onboarding+for+Angular+Apps
 * @export
 * @interface IFeatureFlagService
 * @typedef {IFeatureFlagService}
 */
export interface IFeatureFlagService {
  all$: Observable<FeatureFlagState>;
  getFeatureFlagByName(name: string): boolean;
  setFeatureFlags(flags: FeatureFlagState): void; // Used by proteus-app-init, replaces extractFeatureFlagsToStore
  extractFeatureFlagsToStore(): Promise<void>; // Deprecated - requires Firebase, remove after all apps are migrated to Proteus
}

//base classes for akita objects
@Injectable({ providedIn: 'root' })
export class FeatureFlagQuery<TState extends FeatureFlagState> extends Query<TState> {
  constructor(protected store: FeatureFlagStore<TState>) {
    super(store);
  }
}

export interface FeatureFlagState {
  [key: string]: boolean;
}

export class FeatureFlagStore<TState extends FeatureFlagState> extends Store<TState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry, derivedState?: TState) {
    const initialState = derivedState ?? (FeatureFlagStore.createInitialState() as TState);
    super(initialState, { name: storesRegistry.featureFlags });
  }
  static createInitialState(): FeatureFlagState {
    return {};
  }
}

// base class for service - apps may extend this themselves if absolutely needed. not directly injectable.
export class FeatureFlagCoreBase<TState extends FeatureFlagState> implements IFeatureFlagService {
  public all$ = this.query.select();
  constructor(
    protected readonly remoteConfig: RemoteConfig,
    protected readonly store: FeatureFlagStore<TState>,
    protected readonly query: FeatureFlagQuery<TState>
  ) {}

  public getFeatureFlagByName(name: string): boolean {
    return this.query.getValue()[name];
  }

  /**
   * @deprecated
   * Firebase integration is deprecated, please use Proteus
   * This method is replaced by setFeatureFlags()
   * @public
   * @async
   * @returns {Promise<void>}
   */
  public async extractFeatureFlagsToStore(): Promise<void> {
    const all = getAll(this.remoteConfig);
    const bools = Object.keys(all)
      .filter((key) => this.isBoolean(all[key] as Value))
      .map((key: string) => {
        const result: { [key: string]: boolean } = {};
        result[key] = all[key].asBoolean();
        return result;
      })
      .reduce((state, result) => {
        return { ...state, ...result };
      }, {});
    this.store.update({ ...bools } as Partial<TState>);
  }

  public setFeatureFlags(flags: FeatureFlagState): void {
    this.store.update({ ...flags } as Partial<TState>);
  }

  protected isBoolean(value: Value): boolean {
    return value.asString() === 'true' || value.asString() === 'false';
  }
}

// default implementations of store and query
@Injectable({ providedIn: 'root' })
export class DefaultFeatureFlagStore extends FeatureFlagStore<FeatureFlagState> {
  constructor(@Inject(StoresToken) storesRegistry: StoresRegistry) {
    super(storesRegistry, DefaultFeatureFlagStore.createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class DefaultFeatureFlagQuery extends FeatureFlagQuery<FeatureFlagState> {
  constructor(protected store: DefaultFeatureFlagStore) {
    super(store);
  }
}

/**
 * @deprecated
 * This service depends on Firebase and should no longer be used.
 * Please use Proteus and the EnvironmentConfigService for your feature flagging needs.
 * https://fullsight.atlassian.net/wiki/spaces/DR/pages/327876627/Proteus+Onboarding+for+Angular+Apps
 * @export
 * @class FeatureFlagCoreService
 * @typedef {FeatureFlagCoreService}
 * @extends {FeatureFlagCoreBase<FeatureFlagState>}
 * @implements {IFeatureFlagService}
 */
@Injectable({ providedIn: 'root' })
export class FeatureFlagCoreService extends FeatureFlagCoreBase<FeatureFlagState> implements IFeatureFlagService {
  constructor(
    protected readonly remoteConfig: RemoteConfig,
    protected readonly store: DefaultFeatureFlagStore,
    protected readonly query: DefaultFeatureFlagQuery
  ) {
    super(remoteConfig, store, query);
  }
}

/**
 * @deprecated
 * FeatureFlagService depends on Firebase and should no longer be used.
 * Please use Proteus and the EnvironmentConfigService for your feature flagging needs.
 * https://fullsight.atlassian.net/wiki/spaces/DR/pages/327876627/Proteus+Onboarding+for+Angular+Apps
 * @type {*}
 */
export const FEATURE_FLAG_TOKEN = new InjectionToken<IFeatureFlagService>('Feature Flag Token');
