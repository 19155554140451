import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';

@Component({
  selector: 'si-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: [],
})
export class SortMenuComponent {
  @Input() icon = 'sort_by_alpha';
  @Input() options!: string[];
  @Input() sortBy!: string;
  @Input() sortDir!: SortDirection;
  @Input() showRefreshButton!: boolean;
  @Output() sortChanged = new EventEmitter<Sort>();
  @Output() refreshClicked = new EventEmitter<void>();

  changeActive(sel?: string): void {
    let newDir = this.sortDir;
    // if selection is null, user pressed the toggle button.
    // if sel is the same as existing sortby, user reselected same item.
    if (!sel || sel === this.sortBy) {
      // either way, toggle the direction
      newDir = this.sortDir === 'asc' ? 'desc' : 'asc';
    }
    const newSort: Sort = {
      active: sel ? sel : this.sortBy,
      direction: newDir,
    };
    this.sortChanged.emit(newSort);
  }

  onRefreshClicked(): void {
    this.refreshClicked.emit();
  }
}
