import { ChangeDetectorRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AllowedFileTypes, createResource, Resource, ResourceType, UploadSubmission } from '@sae/models';
import { delimit, hasExtension, noWhitespaceValidator, stripProtocolPrefix, validUrl } from '../../shared-component-utilities';

export interface ResourceDialogData {
  dialogType: string;
  dialogTitle: string;
  dialogIcon: string;
  anchorId?: string;
  resource?: Resource;
  resourceType?: ResourceType;
}

// this component is responsible for the dialog and form controls for new resource creation (file, folder, and link types)
@Component({
  selector: 'si-resource-dialog',
  templateUrl: './resource-dialog.component.html',
})
export class ResourceDialogComponent {
  @Output() uploadSubmitted = new EventEmitter<UploadSubmission>();
  @Output() folderSubmitted = new EventEmitter<Resource>();
  @Output() linkSubmitted = new EventEmitter<{ name: string; url: string }>();

  addFolderForm: FormGroup;
  addFileForm: FormGroup;
  addLinkForm: FormGroup;
  addSurveyForm: FormGroup;
  formSubmitted = false;
  fileUploading = false;
  file: File;
  private readonly validFileTypes = AllowedFileTypes;
  public delimitedFileTypes: string;
  private readonly fakePath = 'C:\\fakepath\\';
  private tempFileName = '';

  constructor(
    public dialogRef: MatDialogRef<ResourceDialogComponent>,
    private cd: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData,
  ) {
    this.addFolderForm = this.formBuilder.group({
      name: ['', [Validators.required, noWhitespaceValidator]],
    });
    this.addFileForm = this.formBuilder.group({
      file: [null, null],
      fileInput: [this.tempFileName, [Validators.required]],
      filePath: ['', null],
      name: ['', [Validators.required, noWhitespaceValidator]],
    });
    this.addLinkForm = this.formBuilder.group({
      url: [
        data.resource && data.resource.metadata && data.resource.metadata.resourceLink ? stripProtocolPrefix(data.resource.metadata.resourceLink) : '',
        [Validators.required, validUrl(true)],
      ],
      name: [(data.resource && data.resource.name) ?? '', [Validators.required, noWhitespaceValidator]],
    });
    this.addSurveyForm = this.formBuilder.group({
      title: ['', [Validators.required, noWhitespaceValidator]],
      description: ['', [Validators.required]],
      startDate: [''],
      endDate: [''],
      secret: false,
    });
    this.delimitedFileTypes = delimit(AllowedFileTypes, ', ');
  }

  public onUploadStarted(): void {
    this.fileUploading = true;
    this.formSubmitted = true;
  }

  public onUploadFinished(): void {
    this.fileUploading = false;
    this.formSubmitted = false;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public cancel(): void {
    this.dialogRef.close('cancel');
  }

  private resetFileInput(): void {
    this.addFileForm.patchValue({ file: '', name: '' });
    this.file = null;
  }

  public formatUrl(control: AbstractControl): void {
    control.setValue(stripProtocolPrefix((control.value as string).trim()));
  }

  public onFileInputChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target.files.length) {
      return this.resetFileInput();
    }
    const file = target.files[0];
    if (!file) {
      return;
    }
    if (file.size >= 250 * 1024 * 1024) {
      this.snackBar.open('Sorry, the file you are attempting to upload is too large. Please try again', null, { duration: 3000 });
      return this.resetFileInput();
    }

    if (!hasExtension(file, this.validFileTypes)) {
      this.snackBar.open('Sorry, the file you are attempting to upload is not permitted. Please try again', null, { duration: 3000 });
      return this.resetFileInput();
    }

    this.addFileForm.patchValue({
      name: file.name.replace(this.fakePath, ''),
      fileInput: file ? file.name : '',
    });

    this.file = file;
    this.addFileForm.controls.file.markAsDirty();
    this.addFileForm.controls.file.markAsTouched();
    this.addFileForm.controls.file.updateValueAndValidity({ onlySelf: true });
    this.cd.markForCheck(); // what happens if we don't?
  }

  public addFile(): void {
    if (!this.file) {
      this.snackBar.open('Please select a file', 'DISMISS', { duration: 3000 });
      return;
    }
    const resourceName = (this.addFileForm.value['name'] as string).trim();
    if (!resourceName.length) {
      this.snackBar.open('Please enter name for the file', 'DISMISS', { duration: 3000 });
      return;
    }
    const upload: UploadSubmission = { fileName: this.addFileForm.value.file as string, resourceName, file: this.file };
    this.uploadSubmitted.emit(upload);
  }

  public addLink(): void {
    const url = (this.addLinkForm.value['url'] as string).trim();
    if (!url) {
      this.snackBar.open('Please enter a URL', 'DISMISS', { duration: 3000 });
      return;
    }
    const name = (this.addLinkForm.value['name'] as string).trim();
    if (!name) {
      this.snackBar.open('Please enter a name', 'DISMISS', { duration: 3000 });
      return;
    }
    this.formSubmitted = true;
    this.linkSubmitted.emit({ name, url });
  }

  public addFolder(): void {
    const resourceName = (this.addFolderForm.value['name'] as string).trim();
    if (!resourceName.length) {
      this.snackBar.open('Please enter a name for the folder', 'DISMISS', { duration: 3000 });
      return;
    }
    this.formSubmitted = true;
    const resource: Resource = createResource(null);
    resource.name = resourceName;
    resource.resourceType = ResourceType.FOLDER;
    resource.anchorId = this.data.anchorId;
    this.folderSubmitted.emit(resource);
  }
}
