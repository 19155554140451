import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, catchError, finalize, of } from 'rxjs';
import { Api } from '../../../../api';
import { DEFAULT_META_DOWNLOAD_ERROR, MetaLabelItem, MetaValueLabel } from '../../models/resource.model';
import { AuthService } from '../../state/auth.service';
import { IEnvironmentConfigService } from '@sae/base';
import { REMOTE_CONFIG_TOKEN } from '@sae/services';
import { MobilusProteusConfig } from '../../../env.config';

@UntilDestroy()
@Component({
  templateUrl: './export-meta-dialog.component.html',
  styleUrls: [],
})
export class ExportMetaDialogComponent implements OnInit {
  public exportMetaForm: FormArray;
  disableAnimations = true;
  addAlert = false;
  dialogType;
  dialogTitle = '';
  dialogIcon = '';
  cid: number;
  private setLoading: Subject<boolean> = new Subject<boolean>();
  loading$: Observable<boolean> = this.setLoading.asObservable();
  private setError: Subject<string> = new Subject<string>();
  error$: Observable<string> = this.setError.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogTitle;
      dialogIcon;
      dialogType?;
      addAlert?;
      disableAnimations?;
      cid;
      dataItems: MetaValueLabel[];
    },
    private dialogRef: MatDialogRef<ExportMetaDialogComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private httpClient: HttpClient,
    @Inject(REMOTE_CONFIG_TOKEN) private envConfigService: IEnvironmentConfigService
  ) {
    this.dialogTitle = data.dialogTitle;
    this.dialogIcon = data.dialogIcon;
    this.cid = data.cid;
    const itemArray = data.dataItems.map(
      (item: MetaValueLabel) => new FormControl({ label: item.label, apiTitle: item.apiLabel, value: true })
    );
    this.exportMetaForm = this.formBuilder.array(itemArray);
  }

  ngOnInit(): void {
    this.disableAnimations = false;
  }

  close(): void {
    this.dialogRef.close();
  }

  retriveMeta(selectedItems: MetaLabelItem[]): void {
    const buffer = [];
    const fromObject = { contentIds: [this.cid] };
    selectedItems.forEach((item) => (fromObject[`${item.apiTitle}`] = item.value));
    const params = new HttpParams({ fromObject });
    const headers = new HttpHeaders().set(
      'Accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    this.httpClient
      .get(
        `${this.envConfigService.envConfig<MobilusProteusConfig>().services?.apiRootUrl}/${Api.content.url}/metadata`,
        {
          headers,
          params,
          observe: 'response',
          responseType: 'arraybuffer',
        }
      )
      .pipe(
        untilDestroyed(this),
        catchError((error: HttpErrorResponse) => {
          if (error.status !== 200) {
            this.setError.next(DEFAULT_META_DOWNLOAD_ERROR);
            throw error;
          } else {
            return of(null);
          }
        }),
        finalize(() => this.setLoading.next(false))
      )
      .subscribe({
        next: (blob) => {
          buffer.push(blob);
        },
        error: (error: HttpErrorResponse) => error,
        complete: () => {
          this.blobToFile(
            buffer,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'metadataexport.xlsx'
          );
          this.dialogRef.close();
        },
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blobToFile(data: any, type: string, fileName: string): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data?.[0].body], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  processAction(): void {
    this.setLoading.next(true);
    const formArrayValue = this.exportMetaForm.getRawValue();
    const selectedItems: MetaLabelItem[] = formArrayValue.filter((item: MetaLabelItem) => item.value);
    this.retriveMeta(selectedItems);
  }
}
