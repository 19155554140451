import { Component, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject, map } from 'rxjs';
import { Breadcrumbs } from '../../../../../shared/models/common-interfaces';
import { ContentService, JournalBrowseCategoryNodes } from '../../../../../shared/state/content.service';
import { JournalCategoryMetadata } from '../../panels/journal-panel/journal-panel.component';
import { JournalBrowseCategory } from 'apps/mobilus/src/app/shared/state/content.store';
import { ActivatedRoute, Router } from '@angular/router';
@UntilDestroy()
@Component({
  selector: 'mobi-detail-browse',
  templateUrl: 'detail-browse.component.html',
})
export class DetailBrowseComponent implements OnInit {
  public pageNum$ = this.contentService.journalBrowsePageNum$;
  public pageSize$ = this.contentService.journalBrowsePageSize$;
  public results$ = this.contentService.journalBrowseResults$;
  public sortBy$ = this.contentService.journalBrowseSortBy$;
  public sortDir$ = this.contentService.journalBrowseSortDir$;
  public loading$ = this.contentService.journalBrowseLoading$;
  public total$ = this.contentService.journalBrowseTotal$;
  public category$ = this.contentService.journalBrowseCategory$;
  public name$ = this.contentService.journalBrowseCategory$.pipe(
    map((category) => JournalCategoryMetadata[category]?.name)
  );
  public description$ = this.contentService.journalBrowseCategory$.pipe(
    map((category) => JournalCategoryMetadata[category]?.content)
  );
  public breadcrumbs$ = this.contentService.journalBrowseCategory$.pipe(
    map((category) => JournalCategoryMetadata[category]?.breadcrumbs)
  );

  public refresh$ = new Subject<void>();
  public content$ = this.contentService.content$;

  showSummary = true;
  public pageSizeOptions = [25, 50, 100];
  searchTerm: string;

  constructor(private contentService: ContentService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (!this.route.snapshot.fragment.includes('volume=')) {
      this.loadJournalBrowseResults();
    } else {
      const volumeFragment = this.route.snapshot.fragment.split('?')[1];
      this.parseVolumeFragment(volumeFragment);
    }
  }

  parseVolumeFragment(fragment: string): void {
    const volume = fragment.split('&')[0]?.split('=')[1];
    const issue = fragment.split('&')[1]?.split('=')[1];
    let journalBrowseCategoryNodes = [];

    if (volume === 'e-first-articles') {
      journalBrowseCategoryNodes = JournalBrowseCategoryNodes.map((node) => {
        if (node.name === 'e-First Articles') {
          return { ...node, isSelected: true };
        }
        return node;
      });
      this.contentService.setJournalBrowseCategory('E-FIRST');
    } else if (volume === 'free-articles') {
      journalBrowseCategoryNodes = JournalBrowseCategoryNodes.map((node) => {
        if (node.name === 'Free Articles') {
          return { ...node, isSelected: true };
        }
        return node;
      });
      this.contentService.setJournalBrowseCategory('FREE');
    } else if (volume === 'open-access-articles') {
      journalBrowseCategoryNodes = JournalBrowseCategoryNodes.map((node) => {
        if (node.name === 'Open Access Articles') {
          return { ...node, isSelected: true };
        }
        return node;
      });
      this.contentService.setJournalBrowseCategory('OPEN ACCESS');
    } else {
      this.contentService.setJournalBrowseCategory('VOLUME ISSUE');

      //here we are getting the year from volume and issue for bread crumb and setting the selected volue/issue for the ournal panel

      const volPubYear = JournalBrowseCategoryNodes.find((node) => node['volume'] === +volume)?.name;

      const breadcrumbs = [
        { title: 'All', buttonMode: true },
        {
          title: volPubYear,
          buttonMode: true,
          volume: volume,
          id: `volume${volume}`,
        },
      ];
      if (issue) {
        breadcrumbs.push({
          title: `Issue${issue}`,
          id: `volume${volume}issue${issue}`,
        } as any);
      }

      JournalCategoryMetadata['VOLUME ISSUE'] = {
        breadcrumbs,
      };

      journalBrowseCategoryNodes = JournalBrowseCategoryNodes.map((node) => {
        if (node['volume'] === +volume) {
          if (issue) {
            return {
              ...node,
              isExpanded: true,

              children: node.children.map((issueNode) => {
                if (issueNode['issue'] === +issue) {
                  return { ...issueNode, isSelected: true };
                }
                return issueNode;
              }),
            };
          }

          return {
            ...node,
            isExpanded: true,
            isSelected: true,
          };
        }
        return node;
      });
    }
    // This is necessary to force the UI to update to deselect the tree nodes
    setTimeout(() => {
      this.contentService.setJournalBrowseCategoryNodes(journalBrowseCategoryNodes);
    }, 0);
    this.loadJournalBrowseResults(+volume, +issue);
  }

  loadJournalBrowseResults(selectedVolumeNumber?: number, selectedIssueNumber?: number): void {
    this.contentService.getJournalBrowseResults(selectedVolumeNumber, selectedIssueNumber).subscribe();
  }

  selectedOption(e: 'View Short' | 'View Summary'): void {
    this.showSummary = e === 'View Summary' ? true : false;
  }

  handlePage(pageEvent: PageEvent): void {
    this.contentService.setJournalBrowsePageNum(pageEvent.pageIndex);
    this.contentService.setJournalBrowsePageSize(pageEvent.pageSize);
    this.loadJournalBrowseResults();
  }

  handleSort(sort: Sort): void {
    this.contentService.setJournalBrowseSortBy(sort.active);
    this.contentService.setJournalBrowseSortDir(sort.direction);
    this.contentService.setJournalBrowsePageNum(0);
    this.loadJournalBrowseResults();
  }

  handleQuery(query: string, journalBrowseCategory: JournalBrowseCategory, breadcrumbs: Breadcrumbs[]): void {
    let selectedVolumeNumber: number, selectedIssueNumber: number;

    if (journalBrowseCategory === 'VOLUME ISSUE') {
      const currentBreadCrumb = breadcrumbs[breadcrumbs.length - 1];
      if (currentBreadCrumb?.title?.includes('Volume')) {
        const selectedVolume = JournalBrowseCategoryNodes.find((node) => node.id === currentBreadCrumb?.id);
        selectedVolumeNumber = selectedVolume['volume'];
      } else if (currentBreadCrumb?.title?.includes('Issue')) {
        const selectedVolume = JournalBrowseCategoryNodes.find((node) => {
          if (node?.children) {
            const selectedIssueNode = node?.children.find((issue) => issue.id === currentBreadCrumb?.id);
            if (selectedIssueNode) {
              selectedIssueNumber = selectedIssueNode['issue'];
            }
            return !!selectedIssueNumber;
          }
          return false;
        });
        selectedVolumeNumber = selectedVolume['volume'];
      }
    }

    this.contentService.setJournalBrowseSearchTerm(query);
    this.contentService.setJournalBrowsePageNum(0);

    this.loadJournalBrowseResults(selectedVolumeNumber, selectedIssueNumber);
  }

  onCrumbClicked(breadCrumb: Breadcrumbs): void {
    const urlWithoutHash = this.router.url.split('#')[0];

    if (breadCrumb.title === 'All') {
      this.contentService.setJournalBrowseCategory('NONE');
      this.contentService.setJournalBrowseCategoryNodes([]);
      // This is necessary to force the UI to update to deselect the tree nodes
      setTimeout(() => {
        this.contentService.setJournalBrowseCategoryNodes(JournalBrowseCategoryNodes);
      }, 0);
      this.contentService.getJournalBrowseResults().subscribe();
      this.router.navigate([urlWithoutHash], { replaceUrl: true, fragment: `browse` });
    } else if (breadCrumb?.title?.includes('Volume')) {
      const crumbClickedVolumeNumber = breadCrumb['volume'];
      this.contentService.setJournalBrowseCategory('NONE');
      JournalCategoryMetadata['VOLUME ISSUE'] = {
        breadcrumbs: [{ title: 'All', buttonMode: true }, { title: breadCrumb.title }],
      };
      this.contentService.setJournalBrowseCategoryNodes([]);
      // This is necessary to force the UI to update to deselect the tree nodes
      setTimeout(() => {
        const journalBrowseCategoryNodes = JournalBrowseCategoryNodes.map((node) => {
          if (node.id === breadCrumb['id']) {
            return { ...node, isSelected: true, isExpanded: true };
          }
          return node;
        });
        this.contentService.setJournalBrowseCategoryNodes(journalBrowseCategoryNodes);
      }, 0);
      this.router.navigate([urlWithoutHash], {
        replaceUrl: true,
        fragment: `browse?volume=${crumbClickedVolumeNumber}`,
      });
      this.contentService.setJournalBrowseCategory('VOLUME ISSUE');
      this.contentService.getJournalBrowseResults(crumbClickedVolumeNumber).subscribe();
    }
  }
}
