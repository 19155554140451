<mat-toolbar
  class="si-toolbar si-toolbar--actions"
  [class.si-toolbar--actions__minified]="minifiedMode"
  [class.mat-elevation-z2]="!minifiedMode"
  [class.si-unusual]="negativeMargin"
>
  <div class="si-control si-wd-control">
    <button
      *ngIf="!selectAllChecked"
      mat-icon-button
      class="si-button si-button--icon si-button--row"
      title="Select All"
      (click)="selectAll()"
    >
      <mat-icon aria-hidden="true">radio_button_unchecked</mat-icon>
    </button>
    <button
      *ngIf="selectAllChecked"
      mat-icon-button
      class="si-button si-button--icon si-button--row si-state--selected"
      title="Unselect All"
      (click)="unselectAll()"
    >
      <mat-icon aria-hidden="true">check_circle</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="!minifiedMode">
    <span class="si-toolbar__c">Selected:</span>
    <button
      *ngFor="let action of actionOptions"
      mat-icon-button
      class="si-button si-button--icon"
      [matTooltip]="action.name"
      [attr.aria-label]="action.name"
      (click)="selectAction(action.name)"
    >
      <mat-icon color="primary" [class]="action.iconClass" aria-hidden="true">{{ action.icon }}</mat-icon>
    </button>
    <span class="si-expanse"></span>
    <si-more-menu *ngIf="moreMenu" [options]="actionOptions" (optionSelected)="selectAction($event)"></si-more-menu>
  </ng-container>
</mat-toolbar>
