import { Injectable } from '@angular/core';
import { HttpMethod, NgEntityService } from '@datorama/akita-ng-entity-service';

import { Observable, map } from 'rxjs';
import { UserProfileStore } from './user-profile.store';
import { UserProfileQuery } from './user-profile.query';
import { UserProfile } from '../../../../models/src/lib/user-profile.model';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({ providedIn: 'root' })
export class UserProfileService extends NgEntityService<UserProfile> {
  profileEntities$ = this.query.selectAll();

  constructor(public readonly store: UserProfileStore, public readonly query: UserProfileQuery) {
    super(store);
  }

  public getUserProfileForId(id: string, mask?: boolean): Observable<UserProfile> {
    return this.get<UserProfile>({
      url: `${this.baseUrl}/v2/users-groups/users/${id}?useSettingsMask=${mask}`,
      mapResponseFn: (res: UserProfile) => {
        return res;
      },
    });
  }

  public updateProfile(id: string, change: Partial<UserProfile>): Observable<UserProfile> {
    return this.update<UserProfile>(id, change, {
      url: `${this.baseUrl}/v2/users-groups/users/${id}`,
      method: HttpMethod.PATCH,

      mapResponseFn: (res: UserProfile) => {
        return res;
      },
    });
  }
  public updateIdentityProfile(
    id: string,
    KEYCLOAK_URL: string,
    realm: string,
    change: KeycloakProfile,
    profile: KeycloakProfile
  ): Observable<KeycloakProfile> {
    const userProfile = { ...profile, id, ...change };
    return this.getHttp()
      .post<KeycloakProfile>(`${KEYCLOAK_URL}realms/${realm}/account`, userProfile)
      .pipe(map((_) => userProfile));
  }

  getCountriesList(): Observable<any> {
    return this.getHttp().get(
      `${this.baseUrl}/v1/isocountrysubdivision/countries?sortField=shortName&sortDir=asc&offset=0&limit=500`
    );
  }
}
