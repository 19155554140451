import {
  AfterViewInit,
  AfterContentChecked,
  Component,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { ButtonRole } from '../part-button/part-button.component';
import { overrideInputs } from '@sae/base';

// Used to enable keyboard navigation of a list of options for chips, radio buttons, menus, etc.

export type ListboxRole = 'group' | 'listbox' | 'menu' | 'radiogroup' | 'tablist' | 'toolbar' | null;
export const ListboxNavAttribute = 'tabtext';

export interface PartUtilListboxConfig {
  childRole?: ButtonRole;
  id?: string;
  label?: string;
  orientation?: 'horizontal' | 'vertical' | null;
  role?: ListboxRole;
  menuOpen?: boolean;
  selectedIndex?: number;
  tooltip?: string;
}

@Component({
  selector: 'fs-part-util-listbox',
  styleUrls: ['part-util-listbox.component.scss'],
  templateUrl: './part-util-listbox.component.html',
  standalone: true,
})
export class PartUtilListboxComponent implements OnChanges, AfterViewInit, AfterContentChecked {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /////////////////////////////////////////////////////////////////////////////////////
  // NOTE: Enables programmatic configuration of component inputs exposed by the model.
  @Input() objConfig: PartUtilListboxConfig;
  /////////////////////////////////////////////////////////////////////////////////////

  @Input() childRole: ButtonRole = 'option';
  @Input() id: string;
  @Input() label: string; // Required for display
  @Input() orientation: 'horizontal' | 'vertical' | null = 'vertical';
  @Input() role: ListboxRole;
  @Input() menuOpen = false; // Optional for use within a menu
  @Input() selectedIndex = 0; // Optional
  @Input() tooltip: string; // Optional

  @ViewChild('listbox') listbox: ElementRef;

  hasChildren = false; // set to false if the listbox has no children
  multiSelectable: boolean;
  _selectedIndex = 0;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (this.listbox.nativeElement.contains(document.activeElement)) {
      this.manageChildren(event);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objConfig']) {
      overrideInputs(this, this.objConfig);
    }

    if (this.hasChildren && this.role && this.role !== 'radiogroup') {
      if (this.role === 'group') {
        this.multiSelectable = true;
        // eslint-disable-next-line @nx/workspace-no-reassign-inputs
        this.orientation = null;
      } else {
        this.multiSelectable = false;
      }
    } else {
      // eslint-disable-next-line @nx/workspace-no-reassign-inputs
      this.orientation = null;
    }

    setTimeout(() => {
      this.manageChildren();
    }, 100);
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.manageChildren();
  }

  onClick($event: MouseEvent): void {
    this.manageChildren($event);
  }

  onFocus(): void {
    const children: HTMLElement[] = this.listbox?.nativeElement.querySelectorAll('[role="' + this.childRole + '"]');
    this._selectedIndex = this.selectedIndex > -1 ? this.selectedIndex : 0; // keeps the UX consistent
    children[this._selectedIndex].focus();
  }

  manageChildren(event?: KeyboardEvent | MouseEvent): void {
    const children: HTMLElement[] = this.listbox?.nativeElement.querySelectorAll('[role="' + this.childRole + '"]');

    if (children?.length > 0) {
      this.hasChildren = true;
      // If the listbox has children, then the children are the options
      if (event) {
        if (event instanceof KeyboardEvent) {
          if (event.key === 'Enter' || event.key === ' ') {
            children[this._selectedIndex].focus();
          } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
            event.preventDefault();
            this._selectedIndex = this._selectedIndex < children.length - 1 ? this._selectedIndex + 1 : 0;
            children[this._selectedIndex].focus();
          } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
            event.preventDefault();
            this._selectedIndex = this._selectedIndex > 0 ? this._selectedIndex - 1 : children.length - 1;
            children[this._selectedIndex].focus();
          } else if (event.key === 'Home') {
            event.preventDefault();
            this._selectedIndex = 0;
            children[this._selectedIndex].focus();
          } else if (event.key === 'End') {
            event.preventDefault();
            this._selectedIndex = children.length - 1;
            children[this._selectedIndex].focus();
          } else if (event.key.match(/^[a-zA-Z0-9]$/)) {
            // If an alphanumeric key is pressed, we will attempt to focus the "next" item that starts with that character via a custom attribute value on that element (defined by ListboxNavAttribute)
            const key = event.key.toLowerCase();
            let index = Array.from(children).findIndex((child) => {
              return child.getAttribute(ListboxNavAttribute)?.toLowerCase().startsWith(key);
            });

            if (index !== -1) {
              // If the currently selected item starts with the key pressed, find the next item that starts with the same letter allowing wrap around, if one exists focus it
              if (children[this._selectedIndex].getAttribute(ListboxNavAttribute)?.toLowerCase().startsWith(key)) {
                let nextIndex = -1;

                for (let i = this._selectedIndex + 1; i < children.length; i++) {
                  if (children[i].getAttribute(ListboxNavAttribute)?.toLowerCase().startsWith(key)) {
                    nextIndex = i;
                    break;
                  }
                }

                if (nextIndex === -1) {
                  for (let i = 0; i < this._selectedIndex; i++) {
                    if (children[i].getAttribute(ListboxNavAttribute)?.toLowerCase().startsWith(key)) {
                      nextIndex = i;
                      break;
                    }
                  }
                }

                if (nextIndex !== -1) {
                  index = nextIndex;
                }
              }

              this._selectedIndex = index;
              children[this._selectedIndex].focus();
            }
          }
        } else {
          // Listbox was clicked
          this._selectedIndex = this.selectedIndex > -1 ? this.selectedIndex : 0;
          children[this._selectedIndex].focus();
        }
      }

      if (!this.role && this.menuOpen) {
        children[this._selectedIndex].focus();
      }
    } else {
      this.hasChildren = false; // Disables the accessibility signals on the listbox if it has no children
    }
  }
}
