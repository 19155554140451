import { Component, OnInit, Input, Output, EventEmitter, IterableDiffers, DoCheck } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

export interface EnhancedTreeNode {
  id?: string;
  name: string;
  children?: EnhancedTreeNode[];
  isSelected?: boolean;
  isExpanded?: boolean;
  url?: string;
}

export interface EnhancedTreeFlatNode {
  id?: string;
  name: string;
  level: number;
  expandable: boolean;
  isSelected?: boolean;
  isExpanded?: boolean;
  url?: string;
}

@Component({
  selector: 'si-enhanced-tree',
  templateUrl: './enhanced-tree.component.html',
})
export class EnhancedTreeComponent implements OnInit, DoCheck {
  @Input() treeNodes: EnhancedTreeNode[] = []; // Optional but an empty node array creates a blank component, takes an array of EnhancedTreeNode objects
  @Input() expandOnNodeClick = true; // Optional, true by default, if true clicking on a node will expand itself if it contains children.
  @Input() linkMode = false; // Optional, false by default, if true turns the button text into a direct anchor link using the node's url property for the href attribute which becomes required for this to be enabled

  @Output() nodeClick = new EventEmitter<EnhancedTreeFlatNode>(); // Event that gets triggered when the node itself is clicked (not to be confused with the toggle button)
  @Output() nodeToggle = new EventEmitter<EnhancedTreeFlatNode>(); // Event that gets triggered when the node expansion state is toggled by clicking the toggle button

  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  iterableDiffer: any = null;

  public treeControl = new FlatTreeControl<EnhancedTreeFlatNode>(
    (node: EnhancedTreeFlatNode) => node.level,
    (node: EnhancedTreeFlatNode) => node.expandable
  );

  public treeFlattener = new MatTreeFlattener<EnhancedTreeNode, EnhancedTreeFlatNode>(
    this.transformer,
    (node: EnhancedTreeFlatNode) => node.level,
    (node: EnhancedTreeFlatNode) => node.expandable,
    (node: EnhancedTreeNode) => node.children
  );
  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  public transformer(node: EnhancedTreeNode, level: number): EnhancedTreeFlatNode {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      level: level,
      isSelected: node.isSelected ?? false,
      isExpanded: node.isExpanded ?? false,
      url: node.url ?? null,
    };
  }

  ngOnInit() {
    this.compileNodes();
  }

  ngDoCheck() {
    const nodesChanged = this.iterableDiffer.diff(this.treeNodes);

    if (nodesChanged) {
      this.compileNodes();
    }
  }

  compileNodes() {
    this.dataSource.data = this.treeNodes;

    // Automatically expand any nodes that are defined as expanded in the initial definition state
    for (const dataNode of this.treeControl.dataNodes) {
      if (dataNode.isExpanded) {
        this.treeControl.expand(dataNode);
      }
    }
  }

  hasChild = (_: number, node: EnhancedTreeFlatNode) => node.expandable;

  nodeClicked(node: EnhancedTreeFlatNode) {
    // First mark all nodes as unseleted before setting this node as selected to ensure only one has a selected state at any given point
    for (const dataNode of this.treeControl.dataNodes) {
      dataNode.isSelected = false;
    }

    node.isSelected = true;

    // If the node has children and expandOnNodeClick option is set, toggle the node state accordingly
    if (this.expandOnNodeClick && node.expandable) {
      if (this.treeControl.isExpanded(node)) {
        this.treeControl.collapse(node);
        node.isExpanded = false;
      } else {
        this.treeControl.expand(node);
        node.isExpanded = true;
      }
    }

    this.nodeClick.emit(node);
  }

  nodeToggled(node: EnhancedTreeFlatNode) {
    node.isExpanded = this.treeControl.isExpanded(node);

    this.nodeToggle.emit(node);
  }
}
