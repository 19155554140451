import { Component, EventEmitter, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface ConfirmDialogData {
  message: string;
  yesButtonText: string;
  noButtonText: string;
}

@Component({
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  message: string;
  yesButtonText: string;
  noButtonText: string;
  yesClicked = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogData,
    private readonly dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    this.message = this.data.message;
    this.yesButtonText = this.data.yesButtonText;
    this.noButtonText = this.data.noButtonText;
  }

  noClick(): void {
    this.dialogRef.close(false);
  }

  yesClick(): void {
    this.yesClicked.emit(); // not really needed...
    this.dialogRef.close(true); // because this value will be emitted by afterClosed()
  }

  // cancel (x) will emit undefined
}
